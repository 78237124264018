import {
    RollupQuery,
    SystemEntity,
    ViewTile,
    ViewTileCreate,
    ViewTileUpdate,
} from '@wdx/clmi/api-models';
import { createAction, props } from '@ngrx/store';

export const getAll = createAction('[View Tiles] Get All');
export const getAllSuccess = createAction(
    '[View Tiles] Get All Success',
    props<{
        viewTiles: ViewTile[];
    }>()
);
export const getAllFailure = createAction(
    '[View Tiles] Get All Failure',
    props<{
        error: Error;
    }>()
);

export const getForId = createAction(
    '[View Tiles] Get For Id',
    props<{
        id: string;
    }>()
);
export const getForIdSuccess = createAction(
    '[View Tiles] Get For Id Success',
    props<{
        id: string;
        viewTile: ViewTile;
    }>()
);
export const getForIdFailure = createAction(
    '[View Tiles] Get For Id Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const createViewTile = createAction(
    '[View Tiles] Create View Tile',
    props<{
        create: ViewTileCreate;
    }>()
);
export const createViewTileSuccess = createAction(
    '[View Tiles] Create View Tile Success',
    props<{
        viewTile: ViewTile;
    }>()
);
export const createViewTileFailure = createAction(
    '[View Tiles] Create View Tile Failure',
    props<{
        create: ViewTileCreate;
        error: Error;
    }>()
);

export const updateViewTile = createAction(
    '[View Tiles] Update View Tile',
    props<{
        id: string;
        update: ViewTileUpdate;
    }>()
);
export const updateViewTileSuccess = createAction(
    '[View Tiles] Update View Tile Success',
    props<{
        viewTile: ViewTile;
    }>()
);
export const updateViewTileFailure = createAction(
    '[View Tiles] Update View Tile Failure',
    props<{
        id: string;
        update: ViewTileUpdate;
        error: Error;
    }>()
);

export const deleteViewTile = createAction(
    '[View Tiles] Delete View Tile',
    props<{
        id: string;
    }>()
);
export const deleteViewTileSuccess = createAction(
    '[View Tiles] Delete View Tile Success',
    props<{
        id: string;
    }>()
);
export const deleteViewTileFailure = createAction(
    '[View Tiles] Delete View Tile Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const getRollup = createAction(
    '[View Tiles] Get Rollup',
    props<{
        id: string;
        rollupQuery: RollupQuery;
        entity: SystemEntity;
    }>()
);
export const getRollupSuccess = createAction(
    '[View Tiles] Get Rollup Success',
    props<{
        id: string;
        results: any;
    }>()
);
export const getRollupFailure = createAction(
    '[View Tiles] Get Rollup Failure',
    props<{
        id: string;
        error: Error;
    }>()
);
