import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
@Component({
    selector: 'wdx-counter',
    templateUrl: './wdx-counter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxCounterComponent {
    @Input() count?: number;
    @Input() isLoading?: boolean;
    @Input() hasError?: boolean;
}
