import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxIconStackComponent } from './wdx-icon-stack.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxIconStackComponent],
    exports: [WdxIconStackComponent],
})
export class WdxIconStackModule {}
