import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import {
    TitleCasePipe,
    TranslateEntityPipe,
    TranslatePipe,
} from '@wdx/shared/utils';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomHighlightTextModule } from '../../atoms/atom-highlight-text/atom-highlight-text.module';
import { MoleculeLifecycleStageTagComponent } from './molecule-lifecycle-stage-tag.component';

@NgModule({
    imports: [
        AtomHighlightTextModule,
        CommonModule,
        PipesModule,
        TranslateEntityPipe,
        TranslatePipe,
        WdxIconModule,
        TitleCasePipe,
        NgbPopover,
    ],
    declarations: [MoleculeLifecycleStageTagComponent],
    exports: [MoleculeLifecycleStageTagComponent],
})
export class MoleculeLifecycleStageTagModule {}
