<button
    role="checkbox"
    [wdxButton]="checked ? checkedStyle : WdxButtonStyle.None"
    [attr.value]="value"
    [attr.disabled]="disabled || null"
    [wdxButtonSize]="size"
    [attr.checked]="checked || null"
>
    <wdx-icon *ngIf="icon" [icon]="icon" class="me-2"></wdx-icon>
    {{ label }}
</button>
