import { createSelector } from '@ngrx/store';
import * as kanbanStagesReducer from './kanban-stages.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    kanbanStages: kanbanStagesReducer.State;
}): kanbanStagesReducer.State => state.kanbanStages;

/**
 * Reducer property selectors
 */
export const getIsLoadingList = createSelector(
    getState,
    (state: kanbanStagesReducer.State, props: any) =>
        state.kanbanStages[props.kanbanTypeId]?.isLoadingList
);
export const getHasLoadingListError = createSelector(
    getState,
    (state: kanbanStagesReducer.State, props: any) =>
        state.kanbanStages[props.kanbanTypeId]?.hasLoadingListError
);
export const getList = createSelector(
    getState,
    (state: kanbanStagesReducer.State, props: any) =>
        state.kanbanStages[props.kanbanTypeId]?.list
);
