import {
    SystemEntityEffects,
    UserEffects,
} from '@wdx/clmi/api-services/services';
import {
    AvatarInfoEffects,
    DashboardsEffects,
    EventEffects,
    FilterEffects,
    ImportEntitiesEffects,
    LicenseEffects,
    OperationsEffects,
    OxfordRiskEffects,
    PersonalSettingsEffects,
    RegionsEffects,
    SystemEffects,
} from '@wdx/clmi/api-services/state';
import { SignalREffects } from 'ngrx-signalr-core';
import { MailingInstructionsEffects } from '../../shared/features/mailing-instructions/state/mailing-instructions.effects';
import { AccessEffects } from '../../state/access/access.effects';
import { ActivitiesEffects } from '../../state/activities/activities.effects';
import { AdminRolesEffects } from '../../state/admin-roles/admin-roles.effects';
import { AmlReportsEffects } from '../../state/aml-reports/aml-reports.effects';
import { AnswersEffects } from '../../state/answers/answers.effects';
import { AppsEffects } from '../../state/apps/apps.effects';
import { ArticlesEffects } from '../../state/articles/articles.effects';
import { AssignEffects } from '../../state/assign/assign.effects';
import { AuditsEffects } from '../../state/audits/audits.effects';
import { AuthenticationEffects } from '../../state/authentication/authentication.effects';
import { AvatarFileUploadEffects } from '../../state/avatar-file-upload/avatar-file-upload.effects';
import { AvatarProfileFileUploadEffects } from '../../state/avatar-profile-file-upload/avatar-profile-file-upload.effects';
import { BatchListEffects } from '../../state/batch-list/batch-list.effects';
import { BatchMessageEffects } from '../../state/batch-message/batch-message.effects';
import { CaseTypesEffects } from '../../state/case-types/case-types.effects';
import { CasesEffects } from '../../state/cases/cases.effects';
import { ChatsEffects } from '../../state/chats/chats.effects';
import { ClientProductsEffects } from '../../state/client-products/client-products.effects';
import { ClientsEffects } from '../../state/clients/clients.effects';
import { CommentsEffects } from '../../state/comments/comments.effects';
import { ConnectionsEffects } from '../../state/connections/connections.effects';
import { ContactPointsEffects } from '../../state/contact-points/contact-points.effects';
import { CountriesEffects } from '../../state/countries/countries.effects';
import { CurrenciesEffects } from '../../state/currencies/currencies.effects';
import { DocumentChecklistEffects } from '../../state/document-checklist/document-checklist.effects';
import { DocumentRequirementTypesEffects } from '../../state/document-requirement-types/document-requirement-types.effects';
import { DocumentTemplatesEffects } from '../../state/document-templates/document-templates.effects';
import { DocumentTypesEffects } from '../../state/document-types/document-types.effects';
import { DocumentsEffects } from '../../state/documents/documents.effects';
import { DuplicatesEffects } from '../../state/duplicates/duplicates.effects';
import { DuplicationCheckEffects } from '../../state/duplication-check/duplication-check.effects';
import { EmailEffects } from '../../state/email/email.effects';
import { EngagementsEffects } from '../../state/engagements/engagements.effects';
import { ExportsEffects } from '../../state/exports/exports.effects';
import { FavouritesEffects } from '../../state/favourites/favourites.effects';
import { FeedsEffects } from '../../state/feeds/feeds.effects';
import { GlobalSearchEffects } from '../../state/global-search/global-search.effects';
import { GlobalEffects } from '../../state/global/global.effects';
import { GoalTypesEffects } from '../../state/goal-types/goal-types.effects';
import { HttpEffects } from '../../state/http/http.effects';
import { IdentityVerificationEffects } from '../../state/identity-verification/identity-verification.effects';
import { InvestmentAccountsEffects } from '../../state/investment-accounts/investment-accounts.effects';
import { InvestmentDataEffects } from '../../state/investment-data/investment-data.effects';
import { InvestorRiskProfileEffects } from '../../state/investor-risk-profile/investor-risk-profile.effects';
import { KanbanStagesEffects } from '../../state/kanban-stages/kanban-stages.effects';
import { LeadsEffects } from '../../state/leads/leads.effects';
import { ListsEffects } from '../../state/lists/lists.effects';
import { LookupsEffects } from '../../state/lookups/lookups.effects';
import { NotesEffects } from '../../state/notes/notes.effects';
import { NotificationsEffects } from '../../state/notifications/notifications.effects';
import { NudgeTypesEffects } from '../../state/nudge-types/nudge-types.effects';
import { NudgesEffects } from '../../state/nudges/nudges.effects';
import { OpportunitiesEffects } from '../../state/opportunities/opportunities.effects';
import { OrganisationClientTypesEffects } from '../../state/organisation-client-types/organisation-client-types.effects';
import { OrganisationsEffects } from '../../state/organisations/organisations.effects';
import { OutcomesEffects } from '../../state/outcomes/outcomes.effects';
import { PartiesEffects } from '../../state/parties/parties.effects';
import { PartyRoleEffects } from '../../state/party-role/party-role.effects';
import { PeopleEffects } from '../../state/people/people.effects';
import { PersonalConnectionsEffects } from '../../state/personal-connections/personal-connections.effects';
import { PortfolioEffects } from '../../state/portfolio/portfolio.effects';
import { ProcessTypesEffects } from '../../state/process-types/process-types.effects';
import { ProcessesEffects } from '../../state/processes/processes.effects';
import { ProductTypesEffects } from '../../state/product-types/product-types.effects';
import { ProfessionalConnectionsEffects } from '../../state/professional-connections/professional-connections.effects';
import { ProspectsEffects } from '../../state/prospects/prospects.effects';
import { QueuesEffects } from '../../state/queues/queues.effects';
import { RecentRecordsEffects } from '../../state/recent-records/recent-records.effects';
import { RelatedClientsEffects } from '../../state/related-clients/related-clients.effects';
import { RelatedRecordsEffects } from '../../state/related-records/related-records.effects';
import { RelationshipsEffects } from '../../state/relationships/relationships.effects';
import { RemindersEffects } from '../../state/reminders/reminders.effects';
import { RolesEffects } from '../../state/roles/roles.effects';
import { SelectOptionsEffects } from '../../state/select-options/select-options.effects';
import { SignalRGlobalEffects } from '../../state/signalr/signalr.effects';
import { SuggestedPartiesEffects } from '../../state/suggested-parties/suggested-parties.effects';
import { TagsEffects } from '../../state/tags/tags.effects';
import { TeamEffects } from '../../state/team/team.effects';
import { ViewsEffects } from '../../state/views/views.effects';
import { WebHooksEffects } from '../../state/web-hooks/web-hooks.effects';
import { WorkItemsEffects } from '../../state/work-items/work-items.effects';
import { AmlEffects } from '../aml/aml.effects';
import { EnvelopeEffects } from '../envelope/envelope.effects';
import { MarketingEffects } from '../marketing/marketing.effects';
import { SystemEventsEffects } from '../system-events/system-events.effects';
import { ViewTileEffects } from '../viewtile/viewtile.effects';

export const globalEffects = [
    AccessEffects,
    ActivitiesEffects,
    AdminRolesEffects,
    AmlEffects,
    AmlReportsEffects,
    AnswersEffects,
    AppsEffects,
    ArticlesEffects,
    AssignEffects,
    AuditsEffects,
    AuthenticationEffects,
    AvatarFileUploadEffects,
    AvatarInfoEffects,
    AvatarProfileFileUploadEffects,
    BatchListEffects,
    BatchMessageEffects,
    CasesEffects,
    CaseTypesEffects,
    ChatsEffects,
    ClientProductsEffects,
    ClientsEffects,
    CommentsEffects,
    ConnectionsEffects,
    ContactPointsEffects,
    CountriesEffects,
    CurrenciesEffects,
    DashboardsEffects,
    DocumentChecklistEffects,
    DocumentRequirementTypesEffects,
    DocumentsEffects,
    DocumentTemplatesEffects,
    DocumentTypesEffects,
    DuplicatesEffects,
    DuplicationCheckEffects,
    EmailEffects,
    EngagementsEffects,
    EnvelopeEffects,
    ExportsEffects,
    FavouritesEffects,
    FeedsEffects,
    FilterEffects,
    GlobalEffects,
    GlobalSearchEffects,
    GoalTypesEffects,
    HttpEffects,
    InvestmentAccountsEffects,
    InvestmentDataEffects,
    InvestorRiskProfileEffects,
    KanbanStagesEffects,
    LeadsEffects,
    ListsEffects,
    LookupsEffects,
    MailingInstructionsEffects,
    MarketingEffects,
    NotesEffects,
    NotificationsEffects,
    NudgesEffects,
    NudgeTypesEffects,
    OperationsEffects,
    OpportunitiesEffects,
    OrganisationClientTypesEffects,
    OrganisationsEffects,
    OutcomesEffects,
    OxfordRiskEffects,
    PartiesEffects,
    PartyRoleEffects,
    PeopleEffects,
    PersonalConnectionsEffects,
    PersonalSettingsEffects,
    PortfolioEffects,
    ProcessesEffects,
    ProcessTypesEffects,
    ProductTypesEffects,
    ProfessionalConnectionsEffects,
    ProspectsEffects,
    QueuesEffects,
    RecentRecordsEffects,
    RelatedClientsEffects,
    RelatedRecordsEffects,
    RelationshipsEffects,
    RemindersEffects,
    RolesEffects,
    SelectOptionsEffects,
    SignalREffects,
    SignalRGlobalEffects,
    SuggestedPartiesEffects,
    SystemEffects,
    SystemEventsEffects,
    TagsEffects,
    TeamEffects,
    UserEffects,
    ViewsEffects,
    ViewTileEffects,
    WebHooksEffects,
    WorkItemsEffects,
    IdentityVerificationEffects,
    SystemEntityEffects,
    RegionsEffects,
    ImportEntitiesEffects,
    LicenseEffects,
    EventEffects,
];
