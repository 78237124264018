export enum StageCode {
    Won = 'won',
    Lost = 'lost',
}

export interface ActiveKanbanStage {
    stageCode: string;
    stageIndex: number;
}

export type OpportunityContextualTypes =
    | 'client'
    | 'party'
    | 'intermediary'
    | 'adviser';
