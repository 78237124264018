import { ENVIRONMENT_URLS } from '../constants/environment-urls.constants';
import { Environment } from '../models/environment.model';

export class EnvironmentService {
    static currentOrigin(): string {
        return location.origin;
    }

    static currentEnvironment(): Environment {
        return Object.keys(ENVIRONMENT_URLS).find(
            (key) =>
                ENVIRONMENT_URLS[key] === EnvironmentService.currentOrigin()
        ) as Environment;
    }
}
