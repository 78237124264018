import { Action, createReducer, on } from '@ngrx/store';
import {
    IdentityCheck,
    IdentityCheckReview,
    KeyStatResult,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { IdentityCheckReviewExt } from '../../models/identity-check.model';
import * as identityVerificationActions from './identity-verification.actions';

export interface State {
    keystats?: CrudStateObject<KeyStatResult>;
    checkDetail?: CrudStateObject<IdentityCheck>;
    idVerificationCheckDetail?: CrudStateObject<IdentityCheck>;
    checkReviews?: CrudStateObject<IdentityCheckReviewExt>;
    checkReviewsAdditional?: CrudStateObject<IdentityCheckReview>;
    checkedHistory?: CrudStateObject<IdentityCheck>;
    refreshHeader?: boolean;
}

export const initialState: State = {
    keystats: {},
    checkDetail: {},
    idVerificationCheckDetail: {},
    checkReviews: {},
    checkReviewsAdditional: {},
    checkedHistory: {},
    refreshHeader: false,
};

const reducerSetup = createReducer(
    initialState,

    on(
        identityVerificationActions.getKeystats,
        (state, props): State => ({
            ...state,
            keystats: {
                ...state.keystats,
                [props.identityCheckId]: {
                    ...(state.keystats[props.identityCheckId] ||
                        ({} as CrudState<KeyStatResult>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getKeystatsSuccess,
        (state, props): State => ({
            ...state,
            keystats: {
                ...state.keystats,
                [props.identityCheckId]: {
                    ...state.keystats[props.identityCheckId],
                    list: props.keystats,
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getKeystatsError,
        (state, props): State => ({
            ...state,
            keystats: {
                ...state.keystats,
                [props.identityCheckId]: {
                    ...state.keystats[props.identityCheckId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckDetail,
        (state, props): State => ({
            ...state,
            checkDetail: {
                ...state.checkDetail,
                [props?.partyId]: {
                    ...(state.checkDetail[props?.partyId] ||
                        ({} as CrudState<IdentityCheck>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckDetailSuccess,
        (state, props): State => ({
            ...state,
            checkDetail: {
                ...state.checkDetail,
                [props?.partyId]: {
                    ...state.checkDetail[props?.partyId],
                    single: props.checkDetail,
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckDetailError,
        (state, props): State => ({
            ...state,
            checkDetail: {
                ...state.checkDetail,
                [props.partyId]: {
                    ...state.checkDetail[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getIdVerificationCheckDetail,
        (state, props): State => ({
            ...state,
            idVerificationCheckDetail: {
                ...state.idVerificationCheckDetail,
                [props?.idVerificationId]: {
                    ...(state.idVerificationCheckDetail[
                        props?.idVerificationId
                    ] || ({} as CrudState<IdentityCheck>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getIdVerificationCheckDetailSuccess,
        (state, props): State => ({
            ...state,
            idVerificationCheckDetail: {
                ...state.idVerificationCheckDetail,
                [props?.idVerificationId]: {
                    ...state.idVerificationCheckDetail[props?.idVerificationId],
                    single: props.idVerificationCheckDetail,
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getIdVerificationCheckDetailError,
        (state, props): State => ({
            ...state,
            idVerificationCheckDetail: {
                ...state.idVerificationCheckDetail,
                [props.idVerificationId]: {
                    ...state.idVerificationCheckDetail[props.idVerificationId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckReviews,
        (state, props): State => ({
            ...state,
            checkReviews: {
                ...state.checkReviews,
                [props.id]: {
                    ...(state.checkReviews[props.id] ||
                        ({} as CrudState<IdentityCheckReview>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckReviewsSuccess,
        (state, props): State => ({
            ...state,
            checkReviews: {
                ...state.checkReviews,
                [props.id]: {
                    ...state.checkReviews[props.id],
                    list: props.checkReviews,
                    isLoadingList: false,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckReviewsError,
        (state, props): State => ({
            ...state,
            checkReviews: {
                ...state.checkReviews,
                [props.id]: {
                    ...state.checkReviews[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        identityVerificationActions.updateReviewStatus,
        (state, props): State => {
            const listIndex = state.checkReviews[
                props.identityCheckId
            ].list.findIndex((list) => list.id === props.identityCheckReviewId);

            const LIST = [...state.checkReviews[props.identityCheckId].list];
            LIST[listIndex] = {
                ...LIST[listIndex],
                isLoading: true,
            };

            return {
                ...state,
                checkReviews: {
                    ...state.checkReviews,
                    [props.identityCheckId]: {
                        list: [...LIST],
                        isLoadingList: false,
                        hasLoadingListError: false,
                    },
                },
            };
        }
    ),

    on(
        identityVerificationActions.updateReviewStatusSuccess,
        (state, props): State => {
            const listIndex = state.checkReviews[
                props.identityCheckId
            ].list.findIndex((list) => list.id === props.identityCheckReviewId);

            const LIST = [...state.checkReviews[props.identityCheckId].list];
            LIST[listIndex] = {
                ...LIST[listIndex],
                reviewStatus: props.reviewStatus,
                isLoading: false,
            };

            return {
                ...state,
                checkReviews: {
                    ...state.checkReviews,
                    [props.identityCheckId]: {
                        list: [...LIST],
                        isLoadingList: false,
                        hasLoadingListError: false,
                    },
                },
            };
        }
    ),

    on(
        identityVerificationActions.getCheckReviewsAdditional,
        (state, props): State => ({
            ...state,
            checkReviewsAdditional: {
                ...state.checkReviewsAdditional,
                [props.identityCheckId]: {
                    ...(state.checkReviewsAdditional[props.identityCheckId] ||
                        ({} as CrudState<IdentityCheckReview>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckReviewsAdditionalSuccess,
        (state, props): State => ({
            ...state,
            checkReviewsAdditional: {
                ...state.checkReviewsAdditional,
                [props.identityCheckId]: {
                    ...state.checkReviewsAdditional[props.identityCheckId],
                    list: props.checkReviewsAdditional,
                    isLoadingList: false,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckReviewsAdditionalError,
        (state, props): State => ({
            ...state,
            checkReviewsAdditional: {
                ...state.checkReviewsAdditional,
                [props.identityCheckId]: {
                    ...state.checkReviewsAdditional[props.identityCheckId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        identityVerificationActions.refreshHeaderSuccess,
        (state, props): State => ({
            ...state,
            refreshHeader: props.isSuccess,
        })
    ),

    on(
        identityVerificationActions.refreshHeaderError,
        (state): State => ({
            ...state,
            refreshHeader: false,
        })
    ),

    on(
        identityVerificationActions.getCheckedHistorySuccess,
        (state, props): State => ({
            ...state,
            checkedHistory: {
                ...state.checkedHistory,
                [props.partyId]: {
                    ...state.checkedHistory[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.checkedHistory,
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckedHistoryError,
        (state, props): State => ({
            ...state,
            checkedHistory: {
                ...state.checkedHistory,
                [props.partyId]: {
                    ...state.checkedHistory[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                    list: [],
                },
            },
        })
    ),

    on(
        identityVerificationActions.getCheckedHistory,
        (state, props): State => ({
            ...state,
            checkedHistory: {
                ...state.checkedHistory,
                [props.partyId]: {
                    ...(state.checkedHistory[props.partyId] ||
                        ({} as CrudState<IdentityCheck>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
