import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    inject,
} from '@angular/core';

@Component({
    selector: 'wdx-accordion-panel',
    templateUrl: './wdx-accordion-panel.component.html',
})
export class WdxAccordionPanelComponent {
    @Input() open = false;
    @Input() disabled = false;
    @Input() removePadding = false;
    @Input() bodyClass = '';
    @Input() headerClass = '';

    @Output() toggle: EventEmitter<boolean> = new EventEmitter();

    elementRef = inject(ElementRef);

    toggleAccordion(): void {
        this.toggle.emit(this.open);
    }
}
