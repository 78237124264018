<li
    class="list-group-item d-flex align-items-center"
    *ngIf="views?.length"
    [title]="title"
>
    <h6>{{ title }}</h6>
</li>

<button
    *ngFor="let view of views; trackBy: trackByViewId; let i = index"
    class="list-group-item list-group-item-action justify-content-between align-items-center py-0"
    type="button"
    [attr.data-cy]="'filter-' + view?.name | translateTokenisedString"
    [title]="view?.name | translateTokenisedString"
    [class.active]="view?.id === (filterQueryService?.view$ | ngrxPush)?.id"
    [class.d-none]="i >= records && !showMore"
    [class.d-flex]="i < records || showMore"
    [attr.aria-current]="
        view?.id === (filterQueryService?.view$ | ngrxPush)?.id
    "
>
    <div
        class="d-flex w-100 py-3"
        [innerHtml]="view?.name | translateTokenisedString | safeHtml"
        (click)="updateSelectedView(view)"
    ></div>

    <div class="d-flex">
        <atom-icon-button
            *ngIf="viewsType === personal"
            class="ms-2"
            size="sm"
            icon="trash-alt"
            [title]="'Delete ' + view?.name"
            (onClick)="deletePersonalView(view)"
            [attr.data-cy]="'delete-item-filter-' + view?.name"
        ></atom-icon-button>

        <atom-icon-button
            *ngIf="FEATURE_FLAG_CUSTOM_VIEWS | hasFeature"
            class="ms-2"
            size="sm"
            [title]="
                viewsType === personal
                    ? 'Edit ' + view?.name
                    : 'Clone ' + view?.name
            "
            [icon]="viewsType === personal ? ICON_EDIT : ICON_CLONE"
            (click)="toggleQueryBuilder(view)"
        ></atom-icon-button>
    </div>
</button>

<section *ngIf="views?.length > records">
    <button
        class="list-group-item list-group-item-action see-more"
        type="button"
        (click)="toggle()"
    >
        <span *ngIf="!showMore"> {{ MENU_SEEMORE | translate }} </span>
        <span *ngIf="showMore">{{ MENU_SEE_LESS | translate }}</span>
    </button>
</section>
