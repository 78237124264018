import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { ExportParams } from '../../shared/features/exports/models/exports.model';

@Injectable()
export class ExportsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    doExport(params: ExportParams) {
        const filterType = params?.queryType;

        const urlFragments = [
            this.config.getConfiguration().API_BASE,
            'query',
            filterType.toLowerCase(),
            'export',
        ];
        if (params.entityId) {
            urlFragments.push(params.entityId);
        }
        return this.http.post(urlFragments.join('/'), params.payload);
    }
}
