import { createAction, props } from '@ngrx/store';
import { PartyRoleAccess } from '@wdx/clmi/api-models';

export const getForParty = createAction(
    '[Access] Get For Party',
    props<{
        partyId: string;
    }>()
);

export const getForPartySuccess = createAction(
    '[Access] Get For Party Success',
    props<{
        partyId: string;
        access: PartyRoleAccess[];
    }>()
);

export const getForPartyFailure = createAction(
    '[Access] Get For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);
