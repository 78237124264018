import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconContainerComponent } from './wdx-icon-container.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxIconContainerComponent],
    exports: [WdxIconContainerComponent],
})
export class WdxIconContainerModule {}
