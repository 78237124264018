import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FileIndex } from '@wdx/clmi/api-models';
import { of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import * as filesActions from './avatar-file-upload.actions';
import { AvatarFileUploadService } from './avatar-file-upload.service';

@Injectable()
export class AvatarFileUploadEffects {
    private actions$ = inject(Actions);
    private filesService = inject(AvatarFileUploadService);

    uploadFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filesActions.uploadFile),
            switchMap((action) =>
                this.filesService.uploadFile(action.partyId, action.file).pipe(
                    takeUntil(
                        this.actions$.pipe(
                            ofType(filesActions.uploadFileCancel)
                        )
                    ),
                    map((event) => {
                        switch (event.type) {
                            case HttpEventType.Sent: {
                                return filesActions.uploadFileStarted({
                                    id: action.id,
                                });
                            }
                            case HttpEventType.UploadProgress: {
                                return filesActions.uploadFileProgress({
                                    id: action.id,
                                    progress: Math.round(
                                        (100 * event.loaded) / event.total
                                    ),
                                });
                            }
                            case HttpEventType.ResponseHeader:
                            case HttpEventType.DownloadProgress: {
                                return filesActions.uploadFileDownloading({
                                    id: action.id,
                                });
                            }
                            case HttpEventType.Response: {
                                const body = (event as HttpResponse<any>)
                                    .body as FileIndex[];

                                const genericFileIndex: FileIndex = {
                                    fileIndex: '',
                                    contentType: action.file.type,
                                    name: action.file.name,
                                    sizeInBytes: action.file.size,
                                };

                                if (event.status === 200) {
                                    return filesActions.uploadFileComplete({
                                        id: action.id,
                                        fileIndex: body
                                            ? body[0]
                                            : genericFileIndex,
                                    });
                                } else {
                                    return filesActions.uploadFileFailure({
                                        id: action.id,
                                        error: event.statusText,
                                    });
                                }
                            }
                        }
                    }),
                    catchError((error) => {
                        return of(
                            filesActions.uploadFileFailure({
                                id: action.id,
                                error,
                            })
                        );
                    })
                )
            )
        )
    );

    uploadFileComplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filesActions.uploadFileComplete),
            map((actions) =>
                filesActions.uploadFileCompleteSuccess({
                    id: actions.id,
                    fileIndex: actions.fileIndex,
                })
            )
        )
    );
}
