import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeTileCarouselComponent } from './molecule-tile-carousel.component';
import { AtomIconButtonModule } from '../../atoms/atom-icon-button/atom-icon-button.module';

@NgModule({
    imports: [AtomIconButtonModule, CommonModule],
    declarations: [MoleculeTileCarouselComponent],
    exports: [MoleculeTileCarouselComponent],
})
export class MoleculeTileCarouselModule {}
