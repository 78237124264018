import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { WdxAvatarModule } from '@wdx/shared/components/wdx-avatar';
import { WdxFeatureSvgModule } from '@wdx/shared/components/wdx-feature-svg';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';
import {
    BooleanPipe,
    CrudStatus,
    RandomStringPipe,
    TranslatePipe,
    TRANSLATION_FORM_NO_HISTORY,
    UTILS_ICON_CHEVRON_UP,
    UTILS_ICON_DROPDOWN,
    WdxDateFormat,
    WdxDateTimePipe,
} from '@wdx/shared/utils';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_timeLine from 'highcharts/modules/timeline';
import { WdxAuditChildHasChangedPipe } from './pipes/wdx-audit-child-has-changed/wdx-audit-child-has-changed.pipe';
import { WdxAuditRowComponent } from './wdx-audit-row/wdx-audit-row.component';
import { WdxAuditValueComponent } from './wdx-audit-value';
import {
    WdxAuditInfo,
    WdxAuditTimeline,
    WdxAuditType,
} from './wdx-audit.interface';

HC_timeLine(Highcharts);

@Component({
    selector: 'wdx-audit',
    standalone: true,
    templateUrl: './wdx-audit.component.html',
    styleUrls: ['./wdx-audit.component.scss'],
    imports: [
        CommonModule,
        WdxAuditValueComponent,
        WdxAvatarModule,
        WdxIconModule,
        BooleanPipe,
        WdxDateTimePipe,
        WdxAuditRowComponent,
        HighchartsChartModule,
        WdxIsLoadingModule,
        RandomStringPipe,
        WdxAuditChildHasChangedPipe,
        WdxFeatureSvgModule,
        TranslatePipe,
    ],
})
export class WdxAuditComponent implements OnChanges {
    @Input() info: WdxAuditInfo[] | undefined = [];
    @Input() timeline: WdxAuditTimeline[] | undefined = [];
    @Input() beforeId!: string | undefined;
    @Input() afterId!: string | undefined;
    @Input() crudStatus!: CrudStatus | undefined;
    @Input() title!: string;
    @Input() lastUpdated!: string | undefined;
    @Input() avatarTmpl!: TemplateRef<unknown>;

    @Output() wdxDateChange = new EventEmitter<WdxAuditTimeline>();

    @HostBinding('class') hostClasses = 'd-flex flex-column overflow-hidden';

    private _timelineChart!: ElementRef;
    get timelineChart(): ElementRef {
        return this._timelineChart;
    }
    @ViewChild('timelineChart', { read: ElementRef }) set timelineChart(
        timelineChart
    ) {
        this._timelineChart = timelineChart;
        this.updateColors();
    }

    public readonly Highcharts: typeof Highcharts = Highcharts;
    public readonly WDX_DATE_FORMAT = WdxDateFormat.AbsoluteDateTime;
    public readonly ICON_ARROW_DOWN = UTILS_ICON_DROPDOWN;
    public readonly ICON_ARROW_UP = UTILS_ICON_CHEVRON_UP;
    public readonly CrudStatus = CrudStatus;
    public readonly AuditType = WdxAuditType;
    public chartOptions!: Highcharts.Options;
    public collapsedStates: Record<string, boolean> = {};
    public pointStyles: Record<string, string> = {};

    private readonly CLASS_PREFIX = 'wdx-audit-point';
    private readonly CLASS_PREFIX_MODIFIER = `${this.CLASS_PREFIX}--`;
    protected readonly BASE_COLOR = 'var(--bs-body-bg-strong)';

    readonly FORM_NO_HISTORY = TRANSLATION_FORM_NO_HISTORY;

    private renderer = inject(Renderer2);

    ngOnChanges(changes: SimpleChanges): void {
        const timelineChange = changes['timeline'];
        const beforeId = changes['beforeId'];
        const afterId = changes['afterId'];
        if (
            timelineChange &&
            timelineChange.previousValue !== timelineChange.currentValue
        ) {
            this.chartOptions = {
                chart: {
                    zooming: {
                        type: 'x',
                    },
                    type: 'timeline',
                },
                xAxis: {
                    type: 'datetime',
                    visible: false,
                },
                yAxis: {
                    gridLineWidth: 1,
                    title: {
                        text: '',
                    },
                    labels: {
                        enabled: false,
                    },
                },
                tooltip: {
                    enabled: false,
                },
                legend: {
                    enabled: false,
                },
                title: {
                    text: this.title,
                    align: 'left',
                },
                subtitle: {
                    text: this.lastUpdated,
                    align: 'left',
                },
                series: [
                    {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: this.changeDateHighChartEvent.bind(this),
                            },
                        },
                        marker: {
                            symbol: 'circle',
                        },
                        data: this.timeline?.map((timelineItem) => ({
                            ...timelineItem,
                            colorIndex: 0,
                            className: this.idToClassName(timelineItem.name),
                            dataLabels: {
                                allowOverlap: false,
                                format: `<h4>{point.label}</h4><br /><span class="fw-bold">{point.date}</span>`,
                                className: this.idToClassName(
                                    timelineItem.name
                                ),
                                padding: 10,
                            },
                        })),
                        type: 'timeline',
                    },
                ],
            };
        }
        if (
            (beforeId && beforeId.previousValue !== beforeId.currentValue) ||
            (afterId && afterId.previousValue !== afterId.currentValue)
        ) {
            this.updateStyles();
        }
    }

    private idToClassName(id: string): string {
        return `${this.CLASS_PREFIX} ${this.CLASS_PREFIX_MODIFIER}${id}`;
    }

    updateColors(): void {
        const elements = this.timelineChart?.nativeElement?.querySelectorAll(
            `.${this.CLASS_PREFIX}`
        );
        if (elements?.length) {
            elements.forEach((el: Element | any) => {
                const id = [...el.classList].find((cls) =>
                    cls.includes(`${this.CLASS_PREFIX_MODIFIER}`)
                );
                this.renderer.setStyle(
                    el,
                    'fill',
                    `var(--${id}, ${this.BASE_COLOR})`
                );
                this.renderer.setStyle(
                    el,
                    'stroke',
                    `var(--${id}, ${this.BASE_COLOR})`
                );
            });
        }
    }

    updateStyles(): void {
        this.pointStyles = {
            [`--${this.CLASS_PREFIX_MODIFIER}${this.beforeId}`]:
                'var(--bs-danger-bg-accessible)',
            [`--${this.CLASS_PREFIX_MODIFIER}${this.afterId}`]:
                'var(--bs-success-bg-accessible)',
        };
    }

    toggleCollapseChild(id: string): void {
        this.collapsedStates[id] = !this.collapsedStates[id];
    }

    changeDateHighChartEvent(data: any): void {
        const selectedTimelinePoint = this.timeline?.find(
            (timelineItem) => timelineItem.name === data.point.options.name
        );
        if (selectedTimelinePoint) {
            this.wdxDateChange.emit(selectedTimelinePoint);
        }
    }
}
