import { Action, createReducer, on } from '@ngrx/store';
import * as globalActions from './global.actions';

export interface State {
    appIsInitialised: boolean;
}

export const initialState: State = {
    appIsInitialised: false,
};

const reducerSetup = createReducer(
    initialState,

    on(
        globalActions.initialiseApp,
        (state): State => ({
            ...state,
            appIsInitialised: false,
        })
    ),

    on(
        globalActions.appInitialised,
        (state): State => ({
            ...state,
            appIsInitialised: true,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
