import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { AtomAsyncButtonComponent } from './atom-async-button.component';

@NgModule({
    imports: [CommonModule, WdxSpinnerModule],
    declarations: [AtomAsyncButtonComponent],
    exports: [AtomAsyncButtonComponent],
})
export class AtomAsyncButtonModule {}
