import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    inject,
} from '@angular/core';
import { BaseInputClass } from '../../../../../classes/base-input-class';
import { FileIndex } from '@wdx/clmi/api-models';
import { ExtendedFileIndex } from '../../../../../models/file-details.model';
import { UploaderType } from '../../../../../models/uploader-type.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
    FilePreview,
    FileUploadPackage,
    FileUploadStatus,
    fileDownloadActions,
    filesActions,
    filesSelectors,
    filesReducer,
} from '@wdx/shared/infrastructure/file-io';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-input-upload',
    templateUrl: './molecule-input-upload.component.html',
})
export class MoleculeInputUploadComponent
    extends BaseInputClass
    implements OnInit, OnDestroy
{
    @Input() type: UploaderType;
    private fileStore$ = inject(Store<filesReducer.FileStoreSlice>);

    fileUploadStatus$: Observable<FileUploadStatus>;
    fileUploadProgress$: Observable<number>;
    fileIndex$: Observable<FileIndex>;
    filePreview$: Observable<FilePreview>;

    valueFileIndex: FileIndex;

    constructor(elementRef: ElementRef) {
        super();

        this.patchInjectedItems({
            elementRef,
        });
    }

    ngOnInit(): void {
        if (this.value) {
            this.displayPreview(this.value);
        }

        this.valueSubject.pipe(take(1)).subscribe((value) => {
            if (value) {
                this.displayPreview(value);
            }
        });
    }

    displayPreview(value: FileIndex[]) {
        const fileIndex = (value as ExtendedFileIndex[])[0];

        this.valueFileIndex = fileIndex;
    }

    onFileAdded(fileUploadPackage: FileUploadPackage): void {
        this.store$.dispatch(
            filesActions.uploadFile({
                id: fileUploadPackage.id,
                file: fileUploadPackage.file,
            })
        );

        this.fileUploadStatus$ = this.fileStore$.select(
            filesSelectors.getFileUploadStatus,
            { id: fileUploadPackage.id }
        );
        this.fileUploadProgress$ = this.fileStore$.select(
            filesSelectors.getFileUploadProgress,
            { id: fileUploadPackage.id }
        );
        this.fileIndex$ = this.fileStore$.select(
            filesSelectors.getFileUploadFileIndex,
            { id: fileUploadPackage.id }
        );

        this.fileIndex$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((fileIndex) => {
                if (!fileIndex) {
                    return;
                }
                this.displayPreview([fileIndex]);
                this.updateValue([fileIndex]);
            });
    }

    onRemoveFile(fileUploadPackage: FileUploadPackage): void {
        this.onClear();
        this.valueFileIndex = undefined;
        if (fileUploadPackage) {
            this.store$.dispatch(
                filesActions.uploadFileReset({ id: fileUploadPackage.id })
            );
        }
    }

    onDownloadFile(fileIndex: FileIndex): void {
        this.store$.dispatch(
            fileDownloadActions.getFileDownload({
                fileIndex: fileIndex.fileIndex,
                fileName: fileIndex.name,
            })
        );
    }

    ngOnDestroy(): void {
        if (this.value) {
            this.store$.dispatch(
                fileDownloadActions.destroyFilePreviewSuccess({
                    fileIndex: this.value[0].fileIndex,
                })
            );
        }
    }
}
