import { Action, createReducer, on } from '@ngrx/store';
import { Process, SystemEntity } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import { NgRxService } from '../../services/ngrx.service';
import * as processesActions from './processes.actions';

export interface State {
    processes: CrudStateObject<Process>;
}

export const initialState: State = {
    processes: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        processesActions.getProcessesForCase,
        (state, props): State => ({
            ...state,
            processes: {
                ...state.processes,
                [NgRxService.chunkId(SystemEntity.Case, props)]: {
                    ...(state.processes[
                        NgRxService.chunkId(SystemEntity.Case, props)
                    ] || ({} as CrudState<Process>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        processesActions.getProcessesForCaseSuccess,
        (state, props): State => ({
            ...state,
            processes: {
                ...state.processes,
                [NgRxService.chunkId(SystemEntity.Case, props)]: {
                    ...state.processes[
                        NgRxService.chunkId(SystemEntity.Case, props)
                    ],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.processes,
                },
            },
        })
    ),

    on(
        processesActions.getProcessesForCaseFailure,
        (state, props): State => ({
            ...state,
            processes: {
                ...state.processes,
                [NgRxService.chunkId(SystemEntity.Case, props)]: {
                    ...state.processes[
                        NgRxService.chunkId(SystemEntity.Case, props)
                    ],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        processesActions.getProcess,
        (state, props): State => ({
            ...state,
            processes: {
                ...state.processes,
                [props.processId]: {
                    ...(state.processes[props.processId] ||
                        ({} as CrudState<Process>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        processesActions.getProcessSuccess,
        (state, props): State => ({
            ...state,
            processes: {
                ...state.processes,
                [props.process.id]: {
                    ...state.processes[props.process.id],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.process,
                },
            },
        })
    ),

    on(
        processesActions.getProcessFailure,
        (state, props): State => ({
            ...state,
            processes: {
                ...state.processes,
                [props.processId]: {
                    ...state.processes[props.processId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        processesActions.createProcess,
        (state): State => ({
            ...state,
            processes: {
                ...state.processes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.processes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Process>)),
                    isCreating: true,
                    hasCreatingError: false,
                },
            },
        })
    ),

    on(
        processesActions.createProcessSuccess,
        (state, props): State => ({
            ...state,
            processes: {
                ...state.processes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.processes[GLOBAL_STATE_INDEX_ID],
                    isCreating: false,
                    hasCreatingError: false,
                    single: props.process,
                },
            },
        })
    ),

    on(
        processesActions.createProcessFailure,
        (state): State => ({
            ...state,
            processes: {
                ...state.processes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.processes[GLOBAL_STATE_INDEX_ID],
                    isCreating: false,
                    hasCreatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
