import { Action, createReducer, on } from '@ngrx/store';
import { ProcessOutcome } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { CrudStatus } from '@wdx/shared/utils';
import * as outcomesActions from './outcomes.actions';

export interface State {
    workItemOutcomes: CrudStateObject<ProcessOutcome>;
}

export const initialState: State = {
    workItemOutcomes: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        outcomesActions.setOutcomeForWorkItem,
        (state, props): State => ({
            ...state,
            workItemOutcomes: {
                ...state.workItemOutcomes,
                [props.workItemId]: {
                    ...(state.workItemOutcomes[props.workItemId] ||
                        ({} as CrudState<ProcessOutcome>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                    status: CrudStatus.Loading,
                },
            },
        })
    ),

    on(
        outcomesActions.setOutcomeForWorkItemSuccess,
        (state, props): State => ({
            ...state,
            workItemOutcomes: {
                ...state.workItemOutcomes,
                [props.workItemId]: {
                    ...state.workItemOutcomes[props.workItemId],
                    isUpdating: false,
                    hasUpdatingError: false,
                    status: CrudStatus.Success,
                },
            },
        })
    ),

    on(
        outcomesActions.setOutcomeForWorkItemFailure,
        (state, props): State => ({
            ...state,
            workItemOutcomes: {
                ...state.workItemOutcomes,
                [props.workItemId]: {
                    ...state.workItemOutcomes[props.workItemId],
                    isUpdating: false,
                    hasUpdatingError: true,
                    status: CrudStatus.Error,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
