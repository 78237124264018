import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TenantsService } from '@wdx/shared/utils';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import * as rootReducer from '../../../state/_setup/reducers';
import * as authenticationActions from '../../../state/authentication/authentication.actions';
import * as globalActions from '../../../state/global/global.actions';
import { GuardUserInfoService } from '../../services/guard-user-info/guard-user-info.service';

@Injectable({
    providedIn: 'root',
})
/**
 * Guard that is run once on the top level Welcome component
 */
export class WelcomeGuard implements CanActivate {
    private guardUserInfoService = inject(GuardUserInfoService);

    constructor(
        private store$: Store<rootReducer.State>,
        private router: Router,
        private authenticationService: AuthenticationService,
        private tenantsService: TenantsService
    ) {}

    /**
     * The Welcome page is the default page of the app.
     *
     * - If the user is already authenticated then redirect immediately to the app.
     * - If 'log-out' queryparam is on the route the user has requested to logout
     */
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const logout = route.queryParamMap.get('log-out');
        const error = route.queryParamMap.get('error');
        const returnToUrl = route.queryParamMap.get('return_to');

        return this.isAuthenticated$.pipe(
            map((isAuthenticated) => {
                if (isAuthenticated && !logout && !error) {
                    if (returnToUrl) {
                        this.authenticationService.returnTo(returnToUrl);
                    } else {
                        return { result: false, startApp: true };
                    }
                    return { result: false, startApp: false };
                }
                if (logout) {
                    this.tenantsService.clearChangeTenantCode();
                    this.store$.dispatch(authenticationActions.logout());
                }
                this.store$.dispatch(
                    globalActions.initialiseApp({ isAuthenticated: false })
                );
                return { result: true, startApp: false };
            }),
            switchMap(({ result, startApp }) => {
                if (startApp) {
                    return this.guardUserInfoService
                        .setTenantAndGetUser(route.params?.['tenant'])
                        .pipe(
                            map(() => {
                                this.router.navigate(
                                    this.tenantsService.TENANT_CODE
                                );

                                return result;
                            })
                        );
                }
                return of(result);
            })
        );
    }

    get isAuthenticated$() {
        return this.authenticationService.auth0Service.isAuthenticated$;
    }
}
