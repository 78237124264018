import { SystemEntity } from '@wdx/clmi/api-models';
import { EXTERNAL_TASK_FORM_ID } from '@wdx/shared/utils';
import { ICON_ADD } from '../../constants/icons.constants';
import { ActionButton } from '../../models/action-button.model';
import { ActionButtonMode } from '../../models/action-buttons-mode.model';
import { Privilege } from '../../models/privilege.model';

export const EXTERNAL_TASK_DATA = {
    viewType: SystemEntity.ExternalTask,
    searchPlaceholder: 'Search',
    searchTypePlural: '',
    searchType: '',
};

export const ADD_EXTERNAL_TASK_ACTION_BTN: ActionButton = {
    mode: ActionButtonMode.IconButton,
    icon: ICON_ADD.icon,
    formSetup: {
        formId: EXTERNAL_TASK_FORM_ID,
        initialisationParams: {},
    },
    cySelector: 'btn-add-external-tasks',
    privileges: [Privilege.CreateActivity],
};
