import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    Renderer2,
    inject,
} from '@angular/core';

// This is for this issue
// https://github.com/angular/angular/issues/48350
@Directive({
    selector: '[wdxUtilsDisabled]',
    standalone: true,
})
export class WdxDisabledDirective implements AfterViewInit {
    _disable!: boolean;
    @Input('wdxUtilsDisabled') set _(value: boolean) {
        this._disable = value;
        this.ngAfterViewInit();
    }

    private elementRef = inject(ElementRef);
    private renderer = inject(Renderer2);

    ngAfterViewInit() {
        if (this._disable) {
            this.renderer.setAttribute(
                this.elementRef.nativeElement,
                'disabled',
                'true'
            );
        } else {
            this.renderer.removeAttribute(
                this.elementRef.nativeElement,
                'disabled'
            );
        }
    }
}
