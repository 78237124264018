import { Action, createReducer, on } from '@ngrx/store';
import { Lead } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { FAVOURITES_INDEX_ID } from '../../constants/state.constants';
import * as leadsActions from './leads.actions';

export interface State {
    leads: CrudStateObject<Lead>;
}

export const initialState: State = {
    leads: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        leadsActions.getLead,
        (state, props): State => ({
            ...state,
            leads: {
                ...state.leads,
                [props.leadId]: {
                    ...(state.leads[props.leadId] || ({} as CrudState<Lead>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        leadsActions.getLeadSuccess,
        (state, props): State => ({
            ...state,
            leads: {
                ...state.leads,
                [props.lead.id]: {
                    ...state.leads[props.lead.id],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.lead,
                },
            },
        })
    ),

    on(
        leadsActions.getLeadFailure,
        (state, props): State => ({
            ...state,
            leads: {
                ...state.leads,
                [props.leadId]: {
                    ...state.leads[props.leadId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        leadsActions.getFavourites,
        (state): State => ({
            ...state,
            leads: {
                ...state.leads,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.leads[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Lead>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        leadsActions.getFavouritesSuccess,
        (state, props): State => ({
            ...state,
            leads: {
                ...state.leads,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.leads[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Lead>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.favourites,
                },
            },
        })
    ),

    on(
        leadsActions.getFavouritesFailure,
        (state): State => ({
            ...state,
            leads: {
                ...state.leads,
                [FAVOURITES_INDEX_ID]: {
                    ...(state.leads[FAVOURITES_INDEX_ID] ||
                        ({} as CrudState<Lead>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
