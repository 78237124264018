import { Pipe, PipeTransform } from '@angular/core';
import { RoutableSystemEntity } from '@wdx/clmi/utils/models';
import { EntityRouteService } from '@wdx/clmi/utils/services';

@Pipe({
    name: 'entityRoute',
    standalone: true,
})
export class EntityRoutePipe implements PipeTransform {
    constructor(private entityRouteService: EntityRouteService) {}
    transform(
        entityType: RoutableSystemEntity,
        entityId?: string | number,
        regardingId?: string | number,
        subEntityType?: RoutableSystemEntity,
        subEntityId?: string | number
    ): string[] | null {
        return this.entityRouteService.routeForEntity(
            entityType,
            entityId,
            regardingId,
            subEntityType,
            subEntityId
        );
    }
}
