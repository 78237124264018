import { SystemEntity } from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { KeyValueObject } from '@wdx/shared/utils';
import {
    ICON_DOWNLOAD,
    ICON_FILE_IMPORT,
} from '../../../constants/icons.constants';

export const IMPORT_VAL = 'import';
export const DOWNLOAD_VAL = 'download';
export const DOWNLOAD_GUIDANCE_VAL = 'download-guidance';

export const IMPORT_ENTITY_FEATURE_FLAGS_MAP: KeyValueObject<FeatureFlag> = {
    [SystemEntity.Import]: FeatureFlag.Imports,
};
export const IMPORT_ENTITIES_MODAL_ID = 'import-data"';

export const IMPORT_ENTITIES_DOWNLOAD_MODAL_ID = 'download-template';

export const IMPORT = [
    {
        icon: ICON_FILE_IMPORT.icon,
        label: 'Import',
        translationKey: 'LABEL_IMPORT',
        value: IMPORT_VAL,
    },
    {
        icon: ICON_DOWNLOAD.icon,
        label: 'Download Template',
        translationKey: 'LABEL_DOWNLOAD_IMPORT_TEMPLATE',
        value: DOWNLOAD_VAL,
    },
    {
        icon: ICON_DOWNLOAD.icon,
        label: 'Download Guidance',
        translationKey: 'LABEL_DOWNLOAD_IMPORT_GUIDANCE',
        value: DOWNLOAD_GUIDANCE_VAL,
    },
];
