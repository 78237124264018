import { Component, Input } from '@angular/core';
import { ArticleStatusType, FileIndex } from '@wdx/clmi/api-models';
import { Avatar } from '@wdx/clmi/utils/models';
import { DisplayDatePipe, WdxThemeColor } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-article-summary',
    templateUrl: './molecule-article-summary.component.html',
})
export class MoleculeArticleSummaryComponent {
    @Input() avatar: Avatar;
    @Input() publishDate: Date;
    @Input() set status(status: ArticleStatusType) {
        this._status = status;
        switch (status) {
            case ArticleStatusType.Draft:
                this.icon = 'file-edit';
                this.label = 'Draft';
                this.themeColor = 'warning';
                break;
            case ArticleStatusType.Inactive:
                this.icon = 'archive';
                this.label = 'Inactive';
                this.themeColor = 'info';
                break;
            case ArticleStatusType.Published:
                this.icon = 'book-open';
                this.label = `Published on ${new DisplayDatePipe().transform(
                    this.publishDate
                )}`;
                this.themeColor = 'success';
        }
    }
    get status(): ArticleStatusType {
        return this._status;
    }
    _status: ArticleStatusType;
    @Input() files: FileIndex[];
    @Input() isInternal: boolean;

    icon: string;
    label: string;
    themeColor: WdxThemeColor;

    readonly ARTICLE_STATUS_TYPE = ArticleStatusType;
}
