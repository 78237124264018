import { createAction, props } from '@ngrx/store';
import { KanbanStage, KanbanType } from '@wdx/clmi/api-models';
export const getForKanbanType = createAction(
    '[Kanban Stages] Get For KanbanType',
    props<{
        kanbanTypeId: KanbanType;
    }>()
);

export const getForKanbanTypeSuccess = createAction(
    '[Kanban Stages] Get For KanbanType Success',
    props<{
        kanbanTypeId: string;
        kanbanStages: KanbanStage[];
    }>()
);

export const getForKanbanTypeFailure = createAction(
    '[Kanban Stages] Get For KanbanType Failure',
    props<{
        kanbanTypeId: string;
        error: Error;
    }>()
);
