import { createAction, props } from '@ngrx/store';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { BatchMessage } from '../../models/batch.model';

export const getAll = createAction(
    '[Interface Console] Get All Batch Messages'
);

export const getAllSuccess = createAction(
    '[Interface Console] Get All Batch Messages Success',
    props<{
        batchMessageList: PaginatedApiResponse<BatchMessage>;
    }>()
);

export const getAllFailure = createAction(
    '[Interface Console] Get All Batch Messages Failure',
    props<{
        error: Error;
    }>()
);

export const getBatchMessageById = createAction(
    '[Interface Console] Get Batch Message Detail By Id',
    props<{
        batchMessageId: string;
    }>()
);

export const getBatchMessageByIdSuccess = createAction(
    '[Interface Console] Get Batch Message Detail By Id Success',
    props<{
        batchMessageId: string;
        batchMessage: PaginatedApiResponse<BatchMessage>;
    }>()
);

export const getBatchMessageByIdFailure = createAction(
    '[Interface Console] Get Batch Message Detail By Id Failure',
    props<{
        batchMessageId: string;
        error: Error;
    }>()
);

export const searchBatchMessage = createAction(
    '[Interface Console] Search Batch Message',
    props<{
        reset?: boolean;
        filter: any;
        bactchId: string;
    }>()
);

export const searchBatchMessageSuccess = createAction(
    '[Interface Console] Search Batch Message Success',
    props<{
        batchMessageList: PaginatedApiResponse<BatchMessage>;
        bactchId: string;
    }>()
);

export const searchBatchMessageFailure = createAction(
    '[Interface Console] Search Batch Message Failure',
    props<{ error: Error; bactchId: string }>()
);

export const deleteBatchMessageRow = createAction(
    '[Interface Console] Delete Batch Message',
    props<{
        id: string;
    }>()
);

export const deleteBatchMessageRowSuccess = createAction(
    '[Interface Console] Delete Batch Message Success',
    props<{
        id: string;
    }>()
);

export const deleteBatchMessageRowFailure = createAction(
    '[Interface Console] Delete Batch Message Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const deleteMultipleMessage = createAction(
    '[Interface Console] Delete Multiple Message',
    props<{
        ids: string[];
    }>()
);

export const deleteMultipleMessageSuccess = createAction(
    '[Interface Console] Delete Multiple Message Success',
    props<{
        ids: string[];
    }>()
);

export const deleteMultipleMessageFailure = createAction(
    '[Interface Console] Delete Multiple Message Failure',
    props<{
        ids: string[];
        error: Error;
    }>()
);

export const emitMessageBatchId = createAction(
    '[Interface Console] Emit Message Batch Id',
    props<{
        id: string;
    }>()
);

export const getSingleBatchMessage = createAction(
    '[Interface Console] Get Single Batch Message',
    props<{
        id: string;
    }>()
);

export const getSingleBatchMessageSuccess = createAction(
    '[Interface Console] Get Single Batch Message Success',
    props<{
        id: string;
        batchMessage: BatchMessage;
    }>()
);

export const getSingleBatchMessageFailure = createAction(
    '[Interface Console] Get Single Batch Message Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const replay = createAction(
    '[Interface Console] Mark Message For Replay',
    props<{ id: string }>()
);

export const replaySuccess = createAction(
    '[Interface Console] Mark Message For Replay Success ',
    props<{ id: string; batchMessage: BatchMessage }>()
);

export const replayFailure = createAction(
    '[Interface Console] Mark Message For Replay Failure ',
    props<{ id: string; error: Error }>()
);

export const replayMultiple = createAction(
    '[Interface Console] Mark Message For Replay Multiple',
    props<{ ids: string[] }>()
);

export const replayMultipleSuccess = createAction(
    '[Interface Console] Mark Message For Replay Multiple Success',
    props<{ ids: string[] }>()
);

export const replayMultipleFailure = createAction(
    '[Interface Console] Mark Message For Replay Multiple Failure',
    props<{ ids: string[]; error: Error }>()
);
