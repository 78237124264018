import { Injectable } from '@angular/core';
import {
    DataLockLevel,
    FormLock,
    IHasPrivilege,
    Privilege,
} from '@wdx/shared/utils';
import { take } from 'rxjs/operators';

@Injectable()
export class FormLockService {
    hasOverrideLockPrivilege!: boolean;

    /**
     * When service initialised globally set 'hasOverrideLockPrivilege' as this is a user level property
     */
    constructor(private privilegesService: IHasPrivilege) {
        this.privilegesService
            .hasPrivilege$(Privilege.OverrideDataLock)
            .pipe(take(1))
            .subscribe(
                (hasOverrideLockPrivilege) =>
                    (this.hasOverrideLockPrivilege = hasOverrideLockPrivilege)
            );
    }

    /**
     * Returns true if form lock status should be overriden with relevant privilege
     */
    shouldOverrideFormLock(lock: FormLock) {
        return lock?.isOverridable && this.hasOverrideLockPrivilege;
    }

    /**
     * Returns true if form should be in full lock state
     */
    hasFullFormLock(lock: FormLock, checkOverridePrivilege = true) {
        if (checkOverridePrivilege && this.shouldOverrideFormLock(lock)) {
            return false;
        }
        return lock?.dataLockLevel === DataLockLevel.Full;
    }

    /**
     * Returns true if form has partial lock
     */
    hasPartialFormLock(lock: FormLock, checkOverridePrivilege = true) {
        if (checkOverridePrivilege && this.shouldOverrideFormLock(lock)) {
            return false;
        }
        return lock?.dataLockLevel === DataLockLevel.Partial;
    }

    /**
     * Returns true if individual field should be in lock state
     */
    fieldIsLocked(
        fieldName: string,
        lock: FormLock,
        checkOverridePrivilege = true
    ) {
        if (this.hasFullFormLock(lock, checkOverridePrivilege)) {
            return true;
        }
        return (
            lock?.dataLockLevel === DataLockLevel.Partial &&
            lock?.fields?.includes(fieldName)
        );
    }
}
