import { Injectable } from '@angular/core';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BREAKPOINTS } from '../../../constants/breakpoints.constants';

@Injectable({ providedIn: 'root' })
export class ResizeService extends WdxDestroyClass {
    resize$: Observable<Event>;
    activeBreakpoint$ = new BehaviorSubject(0);

    constructor() {
        super();
        this.resize$ = fromEvent(window, 'resize');
        this.resize$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((event: any) => {
                this.updateBreakpoint(event?.target?.innerWidth);
            });
        this.getInitialBreakpoint();
    }

    private getInitialBreakpoint(): void {
        this.updateBreakpoint(window.innerWidth);
    }

    private updateBreakpoint(windowWidth: number): void {
        const widths = Object.values(BREAKPOINTS) as number[];
        const breakpoint = widths.find((current, index) => {
            const next = (widths[index + 1] || -1) as number;
            if (next > -1) {
                return windowWidth >= current && windowWidth < next;
            }
            return true;
        });
        if (breakpoint !== this.activeBreakpoint$.value) {
            this.activeBreakpoint$.next(breakpoint);
        }
    }
}
