import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[click-prevent-default]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ClickPreventDefault {
    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        event.preventDefault();
    }
}
