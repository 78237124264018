import { Action, createReducer, on } from '@ngrx/store';
import { AppStatus } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as appsActions from './apps.actions';

export interface State {
    apps: CrudStateObject<AppStatus>;
}

export const initialState: State = {
    apps: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        appsActions.getAppStatus,
        (state, props): State => ({
            ...state,
            apps: {
                ...state.apps,
                [props.appId]: {
                    ...(state.apps[props.appId] ||
                        ({} as CrudState<AppStatus>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        appsActions.getAppStatusSuccess,
        (state, props): State => ({
            ...state,
            apps: {
                ...state.apps,
                [props.appId]: {
                    ...state.apps[props.appId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.app,
                },
            },
        })
    ),

    on(
        appsActions.getAppStatusFailure,
        (state, props): State => ({
            ...state,
            apps: {
                ...state.apps,
                [props.appId]: {
                    ...state.apps[props.appId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        appsActions.confirm,
        (state, props): State => ({
            ...state,
            apps: {
                ...state.apps,
                [props.appId]: {
                    ...(state.apps[props.appId] ||
                        ({} as CrudState<AppStatus>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        appsActions.confirmSuccess,
        (state, props): State => ({
            ...state,
            apps: {
                ...state.apps,
                [props.appId]: {
                    ...state.apps[props.appId],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        appsActions.confirmFailure,
        (state, props): State => ({
            ...state,
            apps: {
                ...state.apps,
                [props.appId]: {
                    ...state.apps[props.appId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
