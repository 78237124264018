import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AtomHighlightTextComponent } from './atom-highlight-text.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [AtomHighlightTextComponent],
    exports: [AtomHighlightTextComponent],
})
export class AtomHighlightTextModule {}
