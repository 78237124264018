<molecule-action-button
    *ngIf="actionButton?.dropdownMenu?.length"
    [actionButton]="actionButton"
    [size]="size"
    (selectMenuItem)="onDropdownOptionClicked($event)"
>
</molecule-action-button>

<clmi-import-entities-modal
    [modalTitle]="modalTitle"
></clmi-import-entities-modal>
<clmi-import-entities-download-modal
    [modalTitle]="modalTitle"
    [actionType]="actionType"
></clmi-import-entities-download-modal>
