import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as kanbanStagesActions from './kanban-stages.actions';
import { KanbanStagesService } from './kanban-stages.service';

@Injectable()
export class KanbanStagesEffects {
    private actions$ = inject(Actions);
    private kanbanStagesService = inject(KanbanStagesService);

    getForKanbanType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(kanbanStagesActions.getForKanbanType),
            mergeMap((action) =>
                this.kanbanStagesService
                    .getForKanbanType(action.kanbanTypeId)
                    .pipe(
                        map((kanbanStages) =>
                            kanbanStagesActions.getForKanbanTypeSuccess({
                                kanbanTypeId: action.kanbanTypeId,
                                kanbanStages,
                            })
                        ),
                        catchError((error) =>
                            of(
                                kanbanStagesActions.getForKanbanTypeFailure({
                                    kanbanTypeId: action.kanbanTypeId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
