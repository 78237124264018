import { createAction, props } from '@ngrx/store';
import { AuditEvent } from '@wdx/clmi/api-models';

export const getAudits = createAction(
    '[Audits] Get Audits',
    props<{
        recordType: string;
        recordId: string;
    }>()
);

export const getAuditsSuccess = createAction(
    '[Audits] Get Audits Success',
    props<{
        recordType: string;
        recordId: string;
        audits: AuditEvent[];
    }>()
);

export const getAuditsFailure = createAction(
    '[Audits] Get Audits Failure',
    props<{
        recordType: string;
        recordId: string;
        error: Error;
    }>()
);
