import { createAction, props } from '@ngrx/store';
import { FilterDefinition, Query } from '@wdx/clmi/api-models';
import {
    PaginatedApiResponse,
    QueryType,
    SelectType,
} from '@wdx/clmi/api-services/models';
import { PaginationOptions } from '@wdx/clmi/api-services/services';

const PREFIX = '[Filter]';

export const getFilterResultsMergeMap = createAction(
    `${PREFIX} Get For Filter Results mergeMap`,
    props<{
        queryType: QueryType;
        selectType: SelectType;
        paginationOptions: PaginationOptions;
        filters: Query;
    }>()
);

export const getFilterResults = createAction(
    `${PREFIX} Get For Filter Results`,
    props<{
        queryType: QueryType;
        selectType: SelectType;
        paginationOptions: PaginationOptions;
        filters: Query;
    }>()
);

export const getFilterResultsSuccess = createAction(
    `${PREFIX} Get For Filter Results Success`,
    props<{
        payload: PaginatedApiResponse<any>;
        selectType: SelectType;
    }>()
);

export const getFilterResultsFailure = createAction(
    `${PREFIX} Get For Filter Results Failure`,
    props<{
        selectType: SelectType;
        error: Error;
    }>()
);

export const getFilterDefinition = createAction(
    `${PREFIX} Get Filter Definition`,
    props<{
        queryType: QueryType;
    }>()
);

export const getFilterDefinitionSuccess = createAction(
    `${PREFIX} Get Filter Definition Success`,
    props<{
        payload: FilterDefinition;
        queryType: QueryType;
    }>()
);

export const getFilterDefinitionFailure = createAction(
    `${PREFIX} Get Filter Definition Failure`,
    props<{
        queryType: QueryType;
        error: Error;
    }>()
);
