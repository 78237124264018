import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxInputTextDirective } from './wdx-input-text.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxInputTextDirective],
    exports: [WdxInputTextDirective],
})
export class WdxInputTextModule {}
