<div
    class="navbar bg-body text-body"
    [class.filter-bar--inline]="inlineStyle"
    [class.no-action-buttons-margin]="noActionButtonsMargin"
>
    <div class="container-fluid">
        <ng-content select="[prefixActionButtons]"></ng-content>
        <ng-container *ngIf="sortMenu">
            <ng-container *ngIf="useSortToggleMenu; else sortByListMenu">
                <molecule-sort-menu
                    class="me-auto"
                    [sortMenu]="sortMenu"
                    [filterHandler]="filterHandler"
                ></molecule-sort-menu>
            </ng-container>

            <ng-template #sortByListMenu>
                <h6>{{ SORT_BY | translate }}</h6>
                <div class="ms-2 flex-grow-1">
                    <molecule-context-menu-dropdown
                        btnClass="p-0"
                        [menu]="sortMenu"
                        [placement]="[
                            'bottom-left',
                            'bottom-right',
                            'left-top'
                        ]"
                        [defaultSelectFirst]="true"
                        (singleItemSelected)="onSortByAction($event)"
                        parentContainer="body"
                    ></molecule-context-menu-dropdown>
                </div>
            </ng-template>
        </ng-container>
        <atom-filter-button
            *ngIf="showFilters"
            class="d-block"
            cySelector="btn-filter"
            [class.ms-2]="!inlineStyle"
            [class.divider-start]="inlineStyle"
            [modalId]="filtersModalId"
        ></atom-filter-button>
        <wdx-toggle-button
            *ngIf="showFavourite"
            data-cy="btn-favourite"
            [class.ms-2]="!inlineStyle"
            [class.divider-start]="inlineStyle"
            [toggle]="favouriteFilterToggle"
            (wdxOnChange)="onSetIsFavourite($event)"
        ></wdx-toggle-button>
        <div class="ms-auto">
            <ng-content select=".action-buttons"></ng-content>
        </div>
    </div>
</div>
