import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UTILS_ICON_ADD } from '@wdx/shared/utils';
import { LayoutAndDefinitionEntry } from '../../../models';

@Component({
    selector: 'wdx-ff-sub-form-header',
    templateUrl: './sub-form-header.component.html',
})
export class FormSubFormHeaderComponent {
    @Input() amount!: number;
    @Input() set elementDefinition(value: LayoutAndDefinitionEntry) {
        this.definition = value;
        if (value?.min) {
            this.validationErrorMessage = value.max
                ? `Requires ${value.min} - ${value.max} entries`
                : `Requires atleast ${value.min} entries`;
        }
    }
    @Input() disabled!: boolean;
    @Input() showValidation = false;

    @Output() addToArray = new EventEmitter();

    public validationErrorMessage = '';
    public definition!: LayoutAndDefinitionEntry;

    readonly addIcon = UTILS_ICON_ADD;

    onAddToArrayClicked(): void {
        this.addToArray.emit();
    }
}
