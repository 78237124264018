import { createAction, props } from '@ngrx/store';

export const setOutcomeForWorkItem = createAction(
    '[Outcomes] Set Outcome For Work Item',
    props<{
        outcomeId: string;
        workItemId: string;
        comment?: string;
    }>()
);

export const setOutcomeForWorkItemSuccess = createAction(
    '[Outcomes] Set Outcome For Work Item Success',
    props<{
        outcomeId: string;
        workItemId: string;
        message: string;
    }>()
);

export const setOutcomeForWorkItemFailure = createAction(
    '[Outcomes] Set Outcome For Work Item Failure',
    props<{
        outcomeId: string;
        workItemId: string;
        error: Error;
    }>()
);

export const setShowRequireComment = createAction(
    '[Outcomes] Show Required Comment',
    props<{
        outcomeId: string;
        workItemId: string;
    }>()
);
