import { ActivatedRouteSnapshot } from '@angular/router';
import { QueryExpressionExt } from '../../../../../models/filter-type-types.model';
import { DEEP_COPY_FUNCTION } from '../../../../../shared/helpers/array-helpers/array-helpers';

export const CONFIG_CONTEXTUAL = (
    contextual: QueryExpressionExt[],
    route: ActivatedRouteSnapshot
): QueryExpressionExt[] => {
    const EXPRESSION_VALUE = [];
    const CONTEXTUAL = Array.isArray(contextual)
        ? DEEP_COPY_FUNCTION(contextual)
        : [];

    if (!route) {
        return EXPRESSION_VALUE;
    }

    CONTEXTUAL.map((expression, index) => {
        Object.keys(expression.values[0]).forEach((key) => {
            const STARTS_WITH = expression.values[0][key]?.startsWith(':');
            if (STARTS_WITH) {
                const KEYS = expression.values[0][key].split(':');
                KEYS.splice(0, 1);
                EXPRESSION_VALUE.push({
                    index,
                    key,
                    params: KEYS,
                });
            }
        });
    });

    EXPRESSION_VALUE.forEach((value) => {
        let val = null;

        value.params.forEach((value, index) => {
            val = index === 0 ? route[value] : val[value];
        });

        CONTEXTUAL[value.index].values[0][value.key] = val;
    });

    return CONTEXTUAL;
};
