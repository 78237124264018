import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Region } from '@wdx/clmi/api-models';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as regionsActions from './regions.actions';
import { RegionsService } from '@wdx/clmi/api-services/services';

@Injectable()
export class RegionsEffects {
    private actions$ = inject(Actions);
    private regionsService = inject(RegionsService);

    getRegions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(regionsActions.getRegions),
            switchMap(() =>
                this.regionsService.getRegions().pipe(
                    map((regions) => {
                        return regionsActions.getRegionsSuccess({
                            regions,
                        });
                    }),
                    catchError((error) =>
                        of(
                            regionsActions.getRegionsFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    disableRegion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(regionsActions.disableRegion),
            switchMap((action) =>
                this.regionsService
                    .disableRegion(action.id, action.status)
                    .pipe(
                        map((updatedRegion: Region) =>
                            regionsActions.disableRegionSuccess({
                                id: action.id,
                                status: action.status,
                                updatedRegion,
                            })
                        ),
                        catchError(() =>
                            of(
                                regionsActions.disableRegionFailure({
                                    id: action.id,
                                })
                            )
                        )
                    )
            )
        )
    );
}
