import { NgModule } from '@angular/core';
import { LookupAvatarsPipe } from './lookup-avatars/lookup-avatars.pipe';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';

@NgModule({
    declarations: [LookupAvatarsPipe],
    imports: [PartyToAvatarPipe],
    exports: [LookupAvatarsPipe],
    providers: [LookupAvatarsPipe],
})
export class FormSummaryPipesModule {}
