import { Icon } from '@wdx/shared/utils';

export type MessageType = 'danger' | 'info' | 'success' | 'warning';

export interface MessageDetails {
    heading?: string;
    message: string;
    subMessage?: string;
    actionType?: MessageActions;
    actionText?: string;
}

export class Message {
    public id: string;
    public type: MessageType = 'danger';
    public heading: string;
    public message: string;
    public subMessage: string;
    public actionType?: MessageActions;
    public actionText?: string;
    public icon: Icon;

    constructor(message: Message) {
        Object.assign(this, message);
    }
}

export enum MessageActions {
    Logout = 'Logout',
}
