import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PushModule } from '@ngrx/component';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxInfoCardModule } from '@wdx/shared/components/wdx-info-card';
import { MoleculeActionButtonModule } from '../../../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeArticleSummaryModule } from '../../../../../components/molecules/molecule-article-summary/molecule-article-summary.module';
import { MoleculeEngagementsModule } from '../../../../../components/molecules/molecule-engagements/molecule-engagements.module';
import { DirectivesModule } from '../../../../../directives/directives.module';
import { PipesModule } from '../../../../../pipes/pipes.module';
import { MoleculeArticleCardComponent } from './molecule-article-card.component';
import { FunctionPipe } from '@wdx/shared/utils';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeArticleSummaryModule,
        MoleculeEngagementsModule,
        PipesModule,
        PushModule,
        RouterModule,
        WdxInfoCardModule,
        WdxIconModule,
        FunctionPipe,
    ],
    declarations: [MoleculeArticleCardComponent],
    exports: [MoleculeArticleCardComponent],
})
export class MoleculeArticleCardModule {}
