import { Injectable, ViewContainerRef } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/api-models';
import { Subject, take, takeUntil } from 'rxjs';
import { ModalManagementService } from '../../../../../../services/modal-management.service';
import { ImportFileUploaderComponent } from '../../../import-file-uploader.component';
import { IMPORT_FORM_MODAL } from '../../constants';

@Injectable({
    providedIn: 'root',
})
export class ImportFileUploaderService {
    contentContainer: ViewContainerRef;
    destroyed$ = new Subject<boolean>();

    constructor(private modalManagementService: ModalManagementService) {}

    openModal(entityType: SystemEntity): void {
        const importFileUploaderComponent =
            this.contentContainer.createComponent(ImportFileUploaderComponent);
        const importFileUploaderInstance = importFileUploaderComponent.instance;

        importFileUploaderInstance.entityType = entityType;

        importFileUploaderInstance.clmiAfterViewInit
            .pipe(take(1))
            .subscribe((_) => {
                this.modalManagementService.openModalWithId(IMPORT_FORM_MODAL);
            });

        importFileUploaderInstance.clmiOnClosed
            .pipe(takeUntil(this.destroyed$))
            .subscribe((_) => {
                this.contentContainer.clear();

                this.destroyed$.next(true);
                this.destroyed$.complete();
            });
    }
}
