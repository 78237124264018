import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { NavItem } from '@wdx/shared/utils';

@Component({
    selector: 'clmi-filter-table-action-button-template',
    templateUrl: './action-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTableActionButtonTemplateComponent {
    @Input() data: any;

    @Output() toggleChange = new EventEmitter<any>();
    @Output() menuItemSelected = new EventEmitter<any>();

    onToggleChange(event: MouseEvent): void {
        this.toggleChange.emit(event);
    }

    onMenuItemSelected(navItem: NavItem): void {
        this.menuItemSelected.emit(navItem);
    }
}
