import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { RecentRecordSummary } from '@wdx/clmi/api-models';

@Injectable()
export class RecentRecordsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getUserRecentRecords(): Observable<RecentRecordSummary[]> {
        return this.http.get<RecentRecordSummary[]>(
            `${this.config.getConfiguration().API_BASE}/user/me/recents`
        );
    }

    updateRecentlyViewedRecord(
        entityType: string,
        recordId: string
    ): Observable<RecentRecordSummary> {
        // eslint-disable-next-line max-len
        return this.http.patch<RecentRecordSummary>(
            `${
                this.config.getConfiguration().API_BASE
            }/useraction/view/entity/${entityType}/${recordId}`,
            {}
        );
    }
}
