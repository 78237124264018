import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatchedDuplicate, SystemEntity } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class DuplicatesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getDuplicates(
        entity: SystemEntity,
        paginationOptions: PaginationOptions
    ): Observable<PaginatedApiResponse<MatchedDuplicate>> {
        return this.http.get<PaginatedApiResponse<MatchedDuplicate>>(
            `${this.config.getConfiguration().API_BASE}/${entity}/duplicates`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    runDuplicateCheck(entity: SystemEntity): Observable<MatchedDuplicate> {
        return this.http.put<MatchedDuplicate>(
            `${this.config.getConfiguration().API_BASE}/${entity}/duplicates`,
            {}
        );
    }

    markNotDuplicates(duplicateId: string): Observable<MatchedDuplicate> {
        return this.http.post<MatchedDuplicate>(
            `${
                this.config.getConfiguration().API_BASE
            }/duplicate/${duplicateId}/notduplicate`,
            {}
        );
    }

    mergeDuplicates(
        duplicateId: string,
        targetId: string
    ): Observable<MatchedDuplicate> {
        return this.http.post<MatchedDuplicate>(
            `${
                this.config.getConfiguration().API_BASE
            }/duplicate/${duplicateId}/merge/${targetId}`,
            {}
        );
    }
}
