<molecule-info-card-search
    [mode]="INFO_CARD_SEARCH_MODE.Link"
    [infoCards]="
        caseTypes
            | fn
                : caseTypesAsInfoCardData
                : activityType
                : entityType
                : activityId
                : partyId
                : partyRoleId
                : clientId
                : opportunityId
                : caseId
                : nudgeId
    "
    [isLoading]="caseTypesIsLoading"
    [hasError]="caseTypesHasError"
    [clientSideSearch]="true"
></molecule-info-card-search>
