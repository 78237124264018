import { Component, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-widget-full-width-button',
    templateUrl: './atom-widget-full-width-button.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'widget-full-width-button' },
})
export class AtomWidgetFullWidthButtonComponent implements OnInit {
    @Input() position: 'footer' | 'inline' = 'footer';
    @Input() showChevron = true;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.renderer.addClass(
            this.elementRef.nativeElement,
            `widget-full-width-button--${this.position}`
        );
    }
}
