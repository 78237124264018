<div class="px-4 z-3" data-cy="wdx-dialog-container">
    <div class="d-flex align-items-center">
        <h5
            class="flex-grow-1 text-center mt-5 mb-4 ms-5"
            data-cy="wdx-dialog-title"
            [textContent]="title"
        ></h5>

        <button
            class="px-2 py-1"
            type="button"
            aria-label="Close"
            data-cy="wdx-dialog-dismiss-button"
            wdxIconButton
            [wdxButton]="WdxButtonStyle.Link"
            (click)="dismissClicked()"
        >
            <wdx-icon icon="times"></wdx-icon>
        </button>
    </div>

    <div
        class="d-flex align-items-center justify-content-center pb-3 mb-3"
        [class.border-bottom]="dialogStyling.horizontalRule"
        [class.pt-5]="!defaultButtons"
    >
        <div class="dialog-body m-3">
            <ng-content></ng-content>
        </div>
    </div>

    <div
        *ngIf="defaultButtons"
        class="dialog-footer d-flex align-items-center justify-content-center mt-4 mb-5"
    >
        <button
            class="me-2"
            data-cy="wdx-dialog-cancel-button"
            [wdxButton]="cancelButtonStyle"
            (click)="cancelButtonClicked()"
        >
            {{ cancelButtonLabel }}
        </button>

        <button
            data-cy="wdx-dialog-action-button"
            [disabled]="(wdxDialogService.disableButtons$ | ngrxPush) || null"
            [wdxButton]="actionButtonStyle"
            (click)="actionClick()"
        >
            {{ actionButtonLabel }}
        </button>
    </div>
</div>
