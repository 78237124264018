import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { ConfigService } from '../../../services/config.service';

@Injectable({
    providedIn: 'root',
})
export class ConditionalGuard implements CanActivate {
    constructor(
        private authGuard: AuthGuard,
        private configService: ConfigService
    ) {}
    canActivate(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): any {
        //bypass guard here
        if (this.configService?.config.TestMode) {
            return true;
        }
        // call your guard here
        return this.authGuard.canActivate(_route, _state);
    }
}
