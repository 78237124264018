import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommentService } from '@wdx/clmi/api-services/services';
import { CrudStatus } from '@wdx/shared/utils';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import * as chatActions from './chat.actions';
import * as chatReducers from './chat.reducer';
import * as chatSelectors from './chat.selectors';

@Injectable()
export class ChatEffects {
    private actions$ = inject(Actions);

    loadChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(chatActions.loadChat),
            switchMap((action) =>
                this.commentService
                    .getComments(action.entityType, action.entityId)
                    .pipe(
                        map((response) =>
                            chatActions.loadChatSuccess({
                                entityId: action.entityId,
                                entityType: action.entityType,
                                comments: response.results || [],
                            })
                        ),
                        catchError((error) => {
                            return of(
                                chatActions.loadChatFailure({
                                    entityId: action.entityId,
                                    entityType: action.entityType,
                                    error,
                                })
                            );
                        })
                    )
            )
        )
    );

    createComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(chatActions.createComment),
            concatLatestFrom((action) =>
                this.store.select(chatSelectors.selectChat(action))
            ),
            mergeMap(([action, chat]) => {
                const chatAlreadyLoaded = chat?.status === CrudStatus.Updating;

                return this.commentService
                    .createComment(
                        action.entityType,
                        action.entityId,
                        action.commentCreate
                    )
                    .pipe(
                        map((comment) => {
                            if (chatAlreadyLoaded) {
                                return chatActions.createCommentSuccess({
                                    entityId: action.entityId,
                                    entityType: action.entityType,
                                    comment,
                                    resolvesId: action.commentCreate.resolvesId,
                                });
                            }
                            return chatActions.loadChat({
                                entityId: action.entityId,
                                entityType: action.entityType,
                            });
                        }),
                        catchError((error) => {
                            console.error('Error', error);
                            return of(
                                chatActions.createCommentFailure({
                                    entityId: action.entityId,
                                    entityType: action.entityType,
                                    error,
                                })
                            );
                        })
                    );
            })
        )
    );

    constructor(
        private commentService: CommentService,
        private store: Store<chatReducers.ChatState>
    ) {}
}
