<atom-modal-wrapper
    [modalId]="DUPLICATION_DETECTION_MODAL_ID"
    [modalTemplate]="duplicationDetectionModal"
>
    <ng-template #duplicationDetectionModal>
        <organism-modal [modalTitle]="'Duplication Detection'">
            <molecule-info-card-check-duplications
                [duplicates]="payload"
                [entityId]="entityId"
                [entityType]="entityType"
                [formId]="formId"
                [formData]="formData"
            >
            </molecule-info-card-check-duplications>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
