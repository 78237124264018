import {
    AfterContentInit,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import { Subject, startWith, takeUntil } from 'rxjs';
import { WdxAccordionPanelComponent } from '../panel/wdx-accordion-panel.component';

@Component({
    selector: 'wdx-accordion',
    templateUrl: './wdx-accordion.component.html',
})
export class WdxAccordionComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(WdxAccordionPanelComponent)
    panels!: QueryList<WdxAccordionPanelComponent>;

    @Input() flush?: boolean;
    @Input() widget?: boolean;

    private destroyed$ = new Subject<boolean>();

    ngAfterContentInit() {
        this.listenToPanelsToggle();
    }

    listenToPanelsToggle(): void {
        this.panels.changes
            .pipe(startWith(this.panels), takeUntil(this.destroyed$))
            .subscribe((panels) => {
                panels
                    ?.toArray()
                    ?.forEach((panel: WdxAccordionPanelComponent) => {
                        panel.toggle
                            .pipe(
                                takeUntil(this.panels.changes),
                                takeUntil(this.destroyed$)
                            )
                            .subscribe(() => {
                                this.togglePanel(panel);
                            });
                    });
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    togglePanel(panel: WdxAccordionPanelComponent): void {
        panel.open = !panel.open;
    }

    openPanel(panel: WdxAccordionPanelComponent, open = true): void {
        panel.open = open;
    }

    openAll(open = true): void {
        this.panels.forEach((panel) => this.openPanel(panel, open));
    }
}
