<ng-container
    *ngIf="
        (!!formControlNameOrIndex || formControlNameOrIndex === 0) &&
        controlContainer.control
    "
    [formGroup]="controlContainer.control"
>
    <ng-select
        class="w-100"
        bindLabel="label"
        bindValue="value"
        data-cy="dropdown-select"
        [placeholder]="placeholder"
        [formControlName]="formControlNameOrIndex"
        [clearable]="false"
        [multiple]="multiple"
        [items]="options"
        [searchable]="searchable"
    ></ng-select>
</ng-container>
