import { Pipe, PipeTransform } from '@angular/core';
import { RoleTypeEnum } from '../models/role-type.model';
import { RoleManagementService } from '../services/role-management.service';

@Pipe({ name: 'hasRole' })
export class HasRolePipe implements PipeTransform {
    transform(value: RoleTypeEnum): boolean {
        return RoleManagementService.roles.some((role) => role.code === value);
    }
}
