import { Injectable, TemplateRef, inject } from '@angular/core';
import { NgbToastConfig } from '@ng-bootstrap/ng-bootstrap';
import {
    Severity,
    UTILS_ICON_DANGER,
    UTILS_ICON_SUCCESS,
    UTILS_ICON_WARNING,
    WdxThemeColor,
} from '@wdx/shared/utils';
import { Toast, ToastIcon } from './wdx-toast.model';

@Injectable({ providedIn: 'root' })
export class WdxToastService {
    toasts: Toast[] = [];

    private config = inject(NgbToastConfig);

    constructor() {
        this.config.animation = true;
    }

    show(options: Partial<Toast>) {
        if (options.closeOthers) {
            this.removeAll();
        }

        const MERGE_OPTIONS: Toast = {
            body: [],
            autohide: true,
            icon: '',
            header: '',
            severity: Severity.None,
            isTemplate: false,
            ...options,
        };
        MERGE_OPTIONS.icon = this.setIconWithSeverity(MERGE_OPTIONS.severity);
        MERGE_OPTIONS.themeColor = this.setThemeColor(MERGE_OPTIONS.severity);
        MERGE_OPTIONS.isTemplate = this.isTemplate(MERGE_OPTIONS.body);

        this.toasts.push({ ...MERGE_OPTIONS });
    }

    remove(toast: Toast): void {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }

    clear(): void {
        this.toasts.splice(0, this.toasts.length);
    }

    removeAll(): void {
        this.toasts = [];
    }

    setIconWithSeverity(severity: Severity): ToastIcon {
        switch (severity) {
            case Severity.Success:
                return UTILS_ICON_SUCCESS;
            case Severity.Warning:
                return UTILS_ICON_WARNING;
            case Severity.Danger:
                return UTILS_ICON_DANGER;
            default:
                return '';
        }
    }

    setThemeColor(severity: Severity): WdxThemeColor {
        switch (severity) {
            case Severity.Success:
                return 'success-light';
            case Severity.Warning:
                return 'warning-light';
            case Severity.Danger:
                return 'danger-light';
            default:
                return 'success-light';
        }
    }

    isTemplate(toastBody: string[] | TemplateRef<HTMLElement>): boolean {
        return toastBody instanceof TemplateRef;
    }
}
