import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxSwitchComponent } from './wdx-switch.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxSwitchComponent],
    exports: [WdxSwitchComponent],
})
export class WdxSwitchModule {}
