import { createSelector } from '@ngrx/store';
import { PersonalSettingsState } from '.';
import { PERSONAL_SETTINGS_FEATURE_KEY } from './personal-settings.reducer';

export const getState = (state: {
    [PERSONAL_SETTINGS_FEATURE_KEY]: PersonalSettingsState;
}): PersonalSettingsState => state.personalSettings;

export const personalSettingsStatus = createSelector(
    getState,
    (state: PersonalSettingsState) => state.status
);

export const personalSettings = createSelector(
    getState,
    (state: PersonalSettingsState) => state.personalSettings
);
