import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureStatus, RoleType } from '@wdx/clmi/api-models';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class RolesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getAll(): Observable<RoleType[]> {
        return this.http.get<RoleType[]>(
            `${this.config.getConfiguration().API_BASE}/role`
        );
    }

    getByRoleType(roleType: string): Observable<RoleType> {
        return this.http.get<RoleType>(
            `${this.config.getConfiguration().API_BASE}/admin/role/${roleType}`
        );
    }

    getForClientProduct(clientProductId: string): Observable<RoleType[]> {
        return this.http.get<RoleType[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/clientproduct/${clientProductId}/roles`
        );
    }

    toogleRoleStatus(code: string, status: FeatureStatus) {
        return this.http.patch<RoleType>(
            `${
                this.config.getConfiguration().API_BASE
            }/Admin/role/${code}/status/${status}`,
            {}
        );
    }
}
