import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as appsActions from './apps.actions';
import { AppsService } from './apps.service';

@Injectable()
export class AppsEffects {
    private actions$ = inject(Actions);
    private appsService = inject(AppsService);

    getAppStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appsActions.getAppStatus),
            mergeMap((action) =>
                this.appsService.getAppStatus(action.appId).pipe(
                    map((app) =>
                        appsActions.getAppStatusSuccess({
                            appId: action.appId,
                            app,
                        })
                    ),
                    catchError((error) =>
                        of(
                            appsActions.getAppStatusFailure({
                                appId: action.appId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    confirm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appsActions.confirm),
            switchMap((action) =>
                this.appsService.confirm(action.appId).pipe(
                    map(() =>
                        appsActions.confirmSuccess({ appId: action.appId })
                    ),
                    catchError((error) =>
                        of(
                            appsActions.confirmFailure({
                                appId: action.appId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
