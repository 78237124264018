import { Action, createReducer, on } from '@ngrx/store';
import { Dashboard } from '@wdx/clmi/api-models';
import { CrudState } from '@wdx/clmi/api-services/models';
import { CrudStatus, setCrudStatus } from '@wdx/shared/utils';
import * as dashboardsActions from './dashboards.actions';

export const DASHBOARDS_FEATURE_KEY = 'dashboard';

export interface State {
    dashboards?: CrudState<Dashboard>;
    selected?: Dashboard;
    menuHidden?: boolean;
    configuration?: {
        configuration?: Record<string, any>;
        status?: CrudStatus;
        statusString?: string;
    };
}

export const initialState: State = {
    dashboards: {},
    menuHidden: false,
    configuration: {
        configuration: undefined,
        ...setCrudStatus(CrudStatus.Initial),
    },
};

const reducerSetup = createReducer(
    initialState,

    on(
        dashboardsActions.getDashboards,
        (state): State => ({
            ...state,
            dashboards: {
                isLoadingList: true,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.getDashboardsSuccess,
        (state, props): State => ({
            ...state,
            dashboards: {
                list: props.dashboards,
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.getDashboardsFailure,
        (state): State => ({
            ...state,
            dashboards: {
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        dashboardsActions.getDashboardsConfiguration,
        (state): State => ({
            ...state,
            configuration: {
                ...(state.configuration ?? {}),
                ...setCrudStatus(CrudStatus.Loading),
            },
        })
    ),

    on(
        dashboardsActions.getDashboardsConfigurationSuccess,
        (state, props): State => ({
            ...state,
            configuration: {
                ...(state.configuration ?? {}),
                ...setCrudStatus(CrudStatus.Success),
                configuration: props.configuration,
            },
        })
    ),

    on(
        dashboardsActions.getDashboardsConfigurationFailure,
        (state): State => ({
            ...state,
            configuration: {
                ...(state.configuration ?? {}),
                ...setCrudStatus(CrudStatus.Error),
            },
        })
    ),

    on(
        dashboardsActions.createDashboard,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards?.list,
                isLoadingList: false,
                isUpdating: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.createDashboardSuccess,
        (state, props): State => ({
            ...state,
            dashboards: {
                list: [props.dashboard, ...(state.dashboards?.list || [])],
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.createDashboardFailure,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards?.list,
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
                hasUpdatingError: true,
            },
        })
    ),

    on(
        dashboardsActions.updateDashboard,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards?.list,
                isLoadingList: false,
                isUpdating: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.updateDashboardSuccess,
        (state, props): State => ({
            ...state,
            dashboards: {
                list: state.dashboards?.list?.map((dashboard) => {
                    if (dashboard.id === props.dashboard.id) {
                        return props.dashboard;
                    }
                    return dashboard;
                }),
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.updateDashboardFailure,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards?.list,
                isLoadingList: false,
                isUpdating: false,
                hasUpdatingError: true,
            },
        })
    ),

    on(
        dashboardsActions.deleteDashboard,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards?.list,
                isLoadingList: false,
                isUpdating: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.deleteDashboardSuccess,
        (state, props): State => ({
            ...state,
            dashboards: {
                list: state.dashboards?.list?.filter(
                    (dashboard) => dashboard.id !== props.dashboardId
                ),
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        dashboardsActions.deleteDashboardFailure,
        (state): State => ({
            ...state,
            dashboards: {
                list: state.dashboards?.list,
                isLoadingList: false,
                isUpdating: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(dashboardsActions.unshareDashboard, (state, props): State => {
        if (props.meIsOwner) {
            return state;
        }
        return {
            ...state,
            dashboards: {
                list: state.dashboards?.list?.filter(
                    (dashboard) => dashboard.id !== props.dashboardId
                ),
            },
            selected:
                state.selected?.id === props.dashboardId
                    ? undefined
                    : state.selected,
        };
    }),

    on(
        dashboardsActions.selectDashboard,
        (state, props): State => ({
            ...state,
            selected: props.dashboard,
        })
    ),

    on(
        dashboardsActions.hideMenu,
        (state, props): State => ({
            ...state,
            menuHidden: props.hidden,
        })
    ),

    on(
        dashboardsActions.toggleMenu,
        (state): State => ({
            ...state,
            menuHidden: !state.menuHidden,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
