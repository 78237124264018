import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { skip, takeUntil } from 'rxjs/operators';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { ExtendedFieldDefinitionOption } from '../../../models';
import { IFormDynamicData } from '../../../interfaces';
import { FieldDefinitionOption } from '@wdx/shared/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'wdx-ff-checkboxes-control',
    templateUrl: './form-checkboxes-control.component.html',
})
export class FormCheckboxesControlComponent
    extends BaseWdxFormControlClass
    implements OnInit
{
    public fieldDefOptions$ = this.options$ as BehaviorSubject<
        FieldDefinitionOption[]
    >;

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit(): void {
        this.fieldDefOptions$
            .pipe(takeUntil(this.destroyed$), skip(1))
            .subscribe((options) => {
                const FORM_DATA = this.formControl?.value
                    ? // eslint-disable-next-line no-unsafe-optional-chaining
                      [...this.formControl?.value]
                    : null;
                const items: any[] = [];
                const arrayRes: any[] = [];

                if (FORM_DATA) {
                    options.map((item) => {
                        items.push(item.value);
                    });

                    FORM_DATA.map((selectedOption) => {
                        if (items.includes(selectedOption)) {
                            arrayRes.push(selectedOption);
                        }
                    });

                    this.formControl?.setValue(arrayRes);
                }
            });
    }

    trackByFn(index: number, option: ExtendedFieldDefinitionOption) {
        return option.value;
    }

    onCheckboxChange(checked: boolean, value: any): void {
        const CHECKBOX_DATA = this.formControl?.value
            ? [...this.formControl.value]
            : [];
        let arrayRes = [];

        if (checked) {
            CHECKBOX_DATA.push(value);
            arrayRes = CHECKBOX_DATA;
        }

        if (!checked) {
            CHECKBOX_DATA.filter((item) => item !== value);
            arrayRes = [...CHECKBOX_DATA.filter((item) => item !== value)];
        }

        this.formControl?.setValue(arrayRes);
    }
}
