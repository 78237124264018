import { createAction, props } from '@ngrx/store';
import { Currency } from '@wdx/clmi/api-models';

export const getCurrencies = createAction('[Currencies] Get Currencies');

export const getCurrenciesSuccess = createAction(
    '[Currencies] Get Currencies Success',
    props<{
        currencies: Currency[];
    }>()
);

export const getCurrenciesFailure = createAction(
    '[Currencies] Get Currencies Failure',
    props<{
        error: Error;
    }>()
);
