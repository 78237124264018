import { createAction, props } from '@ngrx/store';
import {
    Activity,
    ActivityChat,
    Article,
    ArticleCreate,
    ArticleLinkedRecord,
    ArticleStatusType,
    Case,
    Client,
    FileIndex,
    HistoryEntry,
    PartyStub,
    Person,
} from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const create = createAction(
    '[Articles] Create',
    props<{
        articleCreateData: ArticleCreate;
    }>()
);

export const createSuccess = createAction(
    '[Articles] Create Success',
    props<{
        article: Article;
    }>()
);

export const createFailure = createAction(
    '[Articles] Create Failure',
    props<{
        error: Error;
    }>()
);

export const update = createAction(
    '[Articles] Update',
    props<{
        articleUpdateData: Article;
    }>()
);

export const updateSuccess = createAction(
    '[Articles] Update Success',
    props<{
        article: Article;
    }>()
);

export const updateFailure = createAction(
    '[Articles] Update Failure',
    props<{
        error: Error;
    }>()
);

export const parse = createAction(
    '[Articles] Parse',
    props<{
        articleParseData: any;
    }>()
);

export const parseSuccess = createAction(
    '[Articles] Parse Success',
    props<{
        article: Article;
    }>()
);

export const parseFailure = createAction(
    '[Articles] Parse Failure',
    props<{
        error: Error;
    }>()
);

export const getFavourites = createAction('[Articles] Get Favourites');

export const getFavouritesSuccess = createAction(
    '[Articles] Get Favourites Success',
    props<{
        favourites: Article[];
    }>()
);

export const getFavouritesFailure = createAction(
    '[Articles] Get Favourites Failure',
    props<{
        error: Error;
    }>()
);

export const getForId = createAction(
    '[Articles] Get For ID',
    props<{
        articleId: string;
    }>()
);

export const getForIdSuccess = createAction(
    '[Articles] Get For ID Success',
    props<{
        articleId: string;
        article: Article;
    }>()
);

export const getForIdFailure = createAction(
    '[Articles] Get For ID Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const getTargettedForId = createAction(
    '[Articles] Get Targetted For ID',
    props<{
        articleId: string;
    }>()
);

export const getTargettedForIdSuccess = createAction(
    '[Articles] Get Targetted For ID Success',
    props<{
        articleId: string;
        people: Person[];
    }>()
);

export const getTargettedForIdFailure = createAction(
    '[Articles] Get Targetted For ID Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const getViewedForId = createAction(
    '[Articles] Get Viewed For ID',
    props<{
        articleId: string;
    }>()
);

export const getViewedForIdSuccess = createAction(
    '[Articles] Get Viewed For ID Success',
    props<{
        articleId: string;
        people: Person[];
    }>()
);

export const getViewedForIdFailure = createAction(
    '[Articles] Get Viewed For ID Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const getHistoryForId = createAction(
    '[Articles] Get History For Id',
    props<{
        articleId: string;
        reset: boolean;
    }>()
);

export const getHistoryForIdSuccess = createAction(
    '[Articles] Get History For Id Success',
    props<{
        history: PaginatedApiResponse<HistoryEntry>;
        articleId: string;
    }>()
);

export const getHistoryForIdFailure = createAction(
    '[Articles] Get History For Id Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const getRecentArticles = createAction('[Articles] Get Recent Articles');

export const getRecentArticlesSuccess = createAction(
    '[Articles] Get Recent Articles Success',
    props<{
        articles: Article[];
    }>()
);

export const getRecentArticlesFailure = createAction(
    '[Articles] Get Recent Articles Failure',
    props<{
        error: Error;
    }>()
);

export const getFeedClientsForId = createAction(
    '[Articles] Get Feed Clients For ID',
    props<{
        articleId: string;
    }>()
);

export const getFeedClientsForIdSuccess = createAction(
    '[Articles] Get Feed Clients For ID Success',
    props<{
        articleId: string;
        clients: Client[];
    }>()
);

export const getFeedClientsForIdFailure = createAction(
    '[Articles] Get Feed Clients For ID Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const linkImages = createAction(
    '[Articles] Link Images',
    props<{
        articleId: string;
        fileIndexes: FileIndex[];
    }>()
);

export const linkImagesSuccess = createAction('[Articles] Link Images Success');

export const linkImagesFailure = createAction(
    '[Articles] Link Images Failure',
    props<{
        error: Error;
    }>()
);

export const linkedRecords = createAction(
    '[Articles] Linked Records',
    props<{
        articleId: string;
    }>()
);

export const linkedRecordsSuccess = createAction(
    '[Articles] Linked Records Success',
    props<{
        articleId: string;
        records: ArticleLinkedRecord[];
    }>()
);

export const linkedRecordsFailure = createAction(
    '[Articles] Linked Records Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const linkedActivities = createAction(
    '[Articles] Linked Activities',
    props<{
        articleId: string;
    }>()
);

export const linkedActivitiesSuccess = createAction(
    '[Articles] Linked Activities Success',
    props<{
        articleId: string;
        activities: Activity[];
    }>()
);

export const linkedActivitiesFailure = createAction(
    '[Articles] Linked Activities Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const favourited = createAction(
    '[Articles] Get Favourited',
    props<{
        articleId: string;
        reset: boolean;
    }>()
);

export const favouritedSuccess = createAction(
    '[Articles] Get Favourited Success',
    props<{
        favourited: PaginatedApiResponse<PartyStub>;
        articleId: string;
    }>()
);

export const favouritedFailure = createAction(
    '[Articles] Get Favourited Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const linkedCases = createAction(
    '[Articles] Linked Cases',
    props<{
        articleId: string;
    }>()
);

export const linkedCasesSuccess = createAction(
    '[Articles] Linked Cases Success',
    props<{
        articleId: string;
        cases: Case[];
    }>()
);

export const linkedCasesFailure = createAction(
    '[Articles] Linked Cases Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const getCommentsForId = createAction(
    '[Articles] Get Comments For ID',
    props<{
        articleId: string;
    }>()
);

export const getCommentsForIdSuccess = createAction(
    '[Articles] Get Comments For ID Success',
    props<{
        articleId: string;
        comments: ActivityChat[];
    }>()
);

export const getCommentsForIdFailure = createAction(
    '[Articles] Get Comments For ID Failure',
    props<{
        articleId: string;
        error: Error;
    }>()
);

export const linkFiles = createAction(
    '[Articles] Link Files',
    props<{
        articleId: string;
        fileIndexes: FileIndex[];
    }>()
);

export const linkFilesSuccess = createAction('[Articles] Link Files Success');

export const linkFilesFailure = createAction(
    '[Articles] Link Files Failure',
    props<{
        error: Error;
    }>()
);

export const setStatus = createAction(
    '[Articles] Set Status',
    props<{
        articleId: string;
        status: ArticleStatusType;
    }>()
);

export const setStatusSuccess = createAction('[Articles] Set Status Success');

export const setStatusFailure = createAction(
    '[Articles] Set Status Failure',
    props<{
        error: Error;
    }>()
);
