import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsAccessibilityModule from 'highcharts/modules/accessibility';
import HighchartsBulletModule from 'highcharts/modules/bullet';
import HighchartsExportDataModule from 'highcharts/modules/export-data';
import HighchartsExportingModule from 'highcharts/modules/exporting';
import HighchartsNoDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsTimelineModule from 'highcharts/modules/timeline';
import HighchartsWordCloudModule from 'highcharts/modules/wordcloud';
import { DEFAULT_HIGHCHARTS_OPTIONS } from './constants/default-highcharts-options.static';

@Injectable({ providedIn: 'root' })
export class ChartsConfigService {
    set(): void {
        // Apply plugins
        HighchartsAccessibilityModule(Highcharts);
        HighchartsExportingModule(Highcharts);
        HighchartsExportDataModule(Highcharts);
        HighchartsNoDataModule(Highcharts);
        HighchartsTimelineModule(Highcharts);
        HighchartsBulletModule(Highcharts);
        HighchartsWordCloudModule(Highcharts);

        // Set global options
        Highcharts.setOptions(DEFAULT_HIGHCHARTS_OPTIONS);
    }
}
