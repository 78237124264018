import {
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AtomTabComponent } from '../../../../components/atoms/atom-tab/atom-tab.component';
import { TabIndexActivated } from '../../../../models/tab.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-tabs',
    templateUrl: './molecule-tabs.component.html',
})
export class MoleculeTabsComponent implements OnInit {
    @ContentChildren(AtomTabComponent) tabs: QueryList<AtomTabComponent>;

    @Input() name: string;
    @Input() trackInUrl = true;
    @Input() hasBottomBorder: boolean;
    @Input() cySelector: string;

    @Output() tabIndexActivated = new EventEmitter<TabIndexActivated>();

    activeTabIndex$: Observable<number>;

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        if (this.trackInUrl) {
            this.activeTabIndex$ = this.route.queryParams.pipe(
                map((param) => parseInt(param[`tab-${this.name}`])),
                filter((param) => Boolean(param))
            );
        }
    }

    activateTab(tab: TabIndexActivated) {
        if (this.trackInUrl) {
            const queryParams: Params = {
                [`tab-${this.name}`]: tab.index,
            };

            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge',
                replaceUrl: true,
            });
        }

        this.tabIndexActivated.emit({
            index: tab.index,
            clickEvent: tab.clickEvent,
        });
    }
}
