import { Component, Input } from '@angular/core';
import { Avatar } from '@wdx/clmi/utils/models';
import { Highlight, Icon, fadeInOutAnimation } from '@wdx/shared/utils';
import {
    ICON_MISSING,
    ICON_PERSON_SOLID,
    ICON_QUESTION,
} from '../../../constants/icons.constants';

/**
 * @deprecated This component has been deprecated and replaced by `WdxAvatarComponent`
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-avatar',
    templateUrl: './atom-avatar.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'position-relative' },
    animations: [fadeInOutAnimation],
})
export class AtomAvatarComponent {
    @Input() avatar: Avatar;
    @Input() size: string;
    @Input() editModalId: string;
    @Input() highlightBorder: Highlight;

    getInitials(text: string): string {
        const words = text
            .split(' ')
            .map((word) => word.replace(/\W/g, ''))
            .filter((word, index, array) => {
                return (
                    Boolean(word) && (index === 0 || index === array.length - 1)
                );
            });

        const initials = words
            .map((name) => name.substring(0, 1).toUpperCase())
            .join('');

        return initials;
    }

    getAvatarIcon(avatar: Avatar): Icon | string {
        if (!avatar) {
            return ICON_MISSING.icon;
        }
        if (avatar.isUnknown) {
            return ICON_QUESTION.icon;
        }
        if (avatar.icon) {
            return avatar.icon;
        }
        return ICON_PERSON_SOLID.icon;
    }
}
