import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';

@Injectable()
export class ImportEntitiesService extends BaseApiService {
    getImportEntities(): Observable<string[]> {
        return this.get<string[]>(`import/type`);
    }
}
