import { Action, createReducer, on } from '@ngrx/store';
import { AuditEvent } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as auditsActions from './audits.actions';

export interface State {
    audits: CrudStateObject<AuditEvent>;
}

export const initialState: State = {
    audits: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        auditsActions.getAudits,
        (state): State => ({
            ...state,
            audits: {
                ...state.audits,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.audits[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<AuditEvent>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        auditsActions.getAuditsSuccess,
        (state, props): State => ({
            ...state,
            audits: {
                ...state.audits,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.audits[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.audits,
                },
            },
        })
    ),

    on(
        auditsActions.getAuditsFailure,
        (state): State => ({
            ...state,
            audits: {
                ...state.audits,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.audits[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
