import { Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Directive()
export abstract class WdxBaseInputControlsValueAccessorClass
    implements ControlValueAccessor
{
    val = null;
    touched = false;
    disabled = false;

    onChange: (...args: any[]) => any = () => undefined;
    onTouched: (...args: any[]) => any = () => undefined;

    registerOnChange(onChange: (...args: any[]) => any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: (...args: any[]) => any): void {
        this.onTouched = onTouched;
    }

    writeValue(value: any): void {
        this.val = value;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}
