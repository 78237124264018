import { inject, Injectable } from '@angular/core';
import { HttpTransportType, IHttpConnectionOptions } from '@microsoft/signalr';
import { SIGNAL_R_HUB_NAME, TenantsService } from '@wdx/shared/utils';
import { ISignalRHub, findHub } from 'ngrx-signalr-core';
import { AuthenticationService } from './authentication.service';
import { ConfigService } from './config.service';

@Injectable()
export class SignalRService {
    private tenantsService = inject(TenantsService);
    constructor(
        private config: ConfigService,
        private authentication: AuthenticationService
    ) {}

    getConfig(): {
        hubName: string;
        url: string;
        options?: IHttpConnectionOptions;
    } {
        const config = this.config.getConfiguration();
        return {
            hubName: SIGNAL_R_HUB_NAME,
            url: `${config.API_BASE.substring(
                0,
                config.API_BASE.lastIndexOf('/api')
            )}/${SIGNAL_R_HUB_NAME}?tenant=${this.tenantsService.tenantCode}`,
            options: {
                accessTokenFactory: () =>
                    this.authentication
                        .getTokenSilently$()
                        .toPromise() as Promise<string>,
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            },
        };
    }

    getHub(): ISignalRHub {
        return findHub(this.getConfig());
    }
}
