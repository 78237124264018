import { createAction, props } from '@ngrx/store';
import { Event, KeyStatResult, Opportunity } from '@wdx/clmi/api-models';

export const getEventById = createAction(
    '[Event] Get Event By Id',
    props<{
        eventId: string;
    }>()
);

export const getEventByIdSuccess = createAction(
    '[Event] Get Event By Id Success',
    props<{
        eventId: string;
        event: Event;
    }>()
);

export const getEventByIdFailure = createAction(
    '[Event] Get Event By Id Failure',
    props<{
        eventId: string;
        error: Error;
    }>()
);

export const getOpportunitiesById = createAction(
    '[Event] Get Opportunities By Id',
    props<{
        eventId: string;
    }>()
);

export const getOpportunitiesByIdSuccess = createAction(
    '[Event] Get Opportunities By Id Success',
    props<{
        eventId: string;
        opportunities: Opportunity[];
    }>()
);

export const getOpportunitiesByIdFailure = createAction(
    '[Event] Get Opportunities By Id Failure',
    props<{
        eventId: string;
        error: Error;
    }>()
);

export const getKeyStats = createAction(
    '[Event] Get Key Stats',
    props<{
        eventId: string;
    }>()
);

export const getKeyStatsSuccess = createAction(
    '[Event] Get Key Stats Success',
    props<{
        eventId: string;
        keyStats: KeyStatResult[];
    }>()
);

export const getKeyStatsFailure = createAction(
    '[Event] Get Key Stats Failure',
    props<{
        eventId: string;
        error: Error;
    }>()
);
