import { Injectable, inject } from '@angular/core';
import { WdxDialogService } from '@wdx/shared/components/wdx-dialog';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { CLMiWorkItemCommentComponent } from './work-item-comment.component';
import { ICommentForm } from './work-item-comment.interface';

@Injectable({
    providedIn: 'root',
})
export class WorkItemDialogService {
    private wdxDialogService = inject(WdxDialogService);
    private _formValue!: Partial<ICommentForm>;
    private _submitForm$ = new Subject<boolean>();

    get formValue(): Partial<ICommentForm> {
        return this._formValue;
    }

    get submitForm$(): Observable<boolean> {
        return this._submitForm$.asObservable();
    }

    updateFormValue(val: Partial<ICommentForm>): void {
        this._formValue = { ...val };
    }

    submitForm(): void {
        this._submitForm$.next(true);
    }

    open() {
        this.wdxDialogService.open(CLMiWorkItemCommentComponent);
    }
}
