import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    List,
    ListMember,
    ListType,
    LookupFieldResult,
    LookupSourceType,
    Party,
    PartyRole,
    Query,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class ListsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    addList(list: List): Observable<List> {
        return this.http.post<List>(
            `${this.config.getConfiguration().API_BASE}/list`,
            list
        );
    }

    addPartiesToList(
        list: List,
        parties: Party[],
        lookupSourceType?: LookupSourceType
    ): Observable<List> {
        const partyStubs = parties.map((party) => ({
            id: party.id,
            lookupType: lookupSourceType,
        }));
        return this.http.post<List>(
            `${this.config.getConfiguration().API_BASE}/list/${list.id}/member`,
            partyStubs
        );
    }

    addLookupResultsToList(
        list: List,
        lookups: LookupFieldResult[]
    ): Observable<List> {
        return this.http.post<List>(
            `${this.config.getConfiguration().API_BASE}/list/${list.id}/member`,
            lookups
        );
    }

    deleteListForId(listId: string): Observable<List> {
        return this.http.delete<List>(
            `${this.config.getConfiguration().API_BASE}/list/${listId}`
        );
    }

    deleteMemberFromList(listId: string, memberId: string): Observable<List> {
        return this.http.delete<List>(
            `${
                this.config.getConfiguration().API_BASE
            }/list/${listId}/member/${memberId}`
        );
    }

    getLists(): Observable<List[]> {
        return this.http.get<List[]>(
            `${this.config.getConfiguration().API_BASE}/list`
        );
    }

    getListForId(listId: string): Observable<List> {
        return this.http.get<List>(
            `${this.config.getConfiguration().API_BASE}/list/${listId}`
        );
    }

    getMembersForId(listId: string): Observable<ListMember[]> {
        return this.http.get<ListMember[]>(
            `${this.config.getConfiguration().API_BASE}/list/${listId}/members`
        );
    }

    getPartiesForId(listId: string): Observable<Party[]> {
        return this.http.get<Party[]>(
            `${this.config.getConfiguration().API_BASE}/list/${listId}/parties`
        );
    }

    getPartyRolesForId(listId: string): Observable<PartyRole[]> {
        return this.http.get<PartyRole[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/list/${listId}/partyRoles`
        );
    }

    deleteEntityFromList(
        listId: string,
        entityType: SystemEntity,
        entityId: string
    ): Observable<List> {
        return this.http.delete<List>(
            `${
                this.config.getConfiguration().API_BASE
            }/list/${listId}/member/${entityType}/${entityId}`
        );
    }

    addListResultsWithQuery(
        listId: string,
        entityName: string,
        query: Query
    ): Observable<any> {
        return this.http.post<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/list/${listId}/query/${entityName}`,
            query
        );
    }

    getListsByType(listType: ListType): Observable<List[]> {
        return this.http.get<List[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/list/type/${listType}`
        );
    }
}
