<wdx-info-card class="w-100" [flush]="true">
    <div wdxInfoCardLeading>
        <ng-container *ngIf="!hasImage && fileIndex">
            <wdx-icon-container
                *ngIf="
                    $any(fileIndex?.contentType) | fileTypeAsIcon as fileType
                "
                class="bg-icon file-type-{{ $any(fileType).fileType }}"
                size="md"
            >
                <wdx-icon [icon]="$any(fileType).icon"></wdx-icon>
            </wdx-icon-container>
        </ng-container>

        <ng-container *ngIf="hasImage">
            <div class="avatar avatar-lg" *ngIf="image">
                <div
                    class="avatar-image"
                    [style.background-image]="image"
                ></div>
            </div>
        </ng-container>
    </div>
    <div wdxInfoCardLabel>{{ label ?? fileIndex?.name }}</div>
    <div wdxInfoCardSub>
        {{ subtitle ?? (fileIndex?.sizeInBytes | fileSize) }}
    </div>
    <div wdxInfoCardTrailing>
        <wdx-icon-button
            class="ms-2"
            size="sm"
            wdxStyle="outline-primary"
            (wdxOnClick)="downloadFile()"
            ><wdx-icon [icon]="ICON_DOWNLOAD"></wdx-icon
        ></wdx-icon-button>
        <ng-content></ng-content>
    </div>
</wdx-info-card>
