import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemeComponentClass } from '@wdx/shared/components/abstract-classes';

@Component({
    selector: 'wdx-alert',
    templateUrl: './wdx-alert.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxAlertComponent extends ThemeComponentClass {
    override themeClassPrefix = 'alert';
    @Input() allowFlex = true;
}
