import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomHighlightCircleModule } from '../atom-highlight-circle/atom-highlight-circle.module';
import { AtomSeverityIndicatorsComponent } from './atom-severity-indicators.component';

@NgModule({
    imports: [AtomHighlightCircleModule, CommonModule, PipesModule],
    declarations: [AtomSeverityIndicatorsComponent],
    exports: [AtomSeverityIndicatorsComponent],
})
export class AtomSeverityIndicatorsModule {}
