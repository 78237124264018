import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as suggestedPartiesActions from './suggested-parties.actions';
import { SuggestedPartiesService } from './suggested-parties.service';

@Injectable()
export class SuggestedPartiesEffects {
    private actions$ = inject(Actions);
    private suggestedPartiesService = inject(SuggestedPartiesService);

    getForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(suggestedPartiesActions.getForParty),
            mergeMap((action) =>
                this.suggestedPartiesService.getForParty(action.partyId).pipe(
                    map((suggestedParties) =>
                        suggestedPartiesActions.getForPartySuccess({
                            partyId: action.partyId,
                            suggestedParties,
                        })
                    ),
                    catchError((error) =>
                        of(
                            suggestedPartiesActions.getForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
