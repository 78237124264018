import { Injectable } from '@angular/core';
import * as exportsActions from '../../../../state/exports/exports.actions';
import * as rootReducer from '../../../../state/_setup/reducers';
import { Store } from '@ngrx/store';
import { ExportParams } from '../models/exports.model';

@Injectable({
    providedIn: 'root',
})
export class ExportsFacadeService {
    constructor(private store$: Store<rootReducer.State>) {}

    doExport(params: ExportParams) {
        this.store$.dispatch(
            exportsActions.doExport({
                queryType: params.queryType,
                payload: params.payload,
                entityId: params.entityId,
            })
        );
    }
}
