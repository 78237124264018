import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[clmiInfiniteScroll]',
})
export class InfiniteScrollDirective {
    @Input() clmiInfiniteScroll = true;

    @Output() scrolled = new EventEmitter();

    debouncer = new Subject<void>();

    @HostListener('scroll')
    scrolling() {
        const { scrollTop, scrollHeight, offsetHeight } =
            this.element.nativeElement;
        const distanceFromBottom = scrollHeight - offsetHeight - scrollTop;

        if (distanceFromBottom <= 100) {
            this.debouncer.next();
        }
    }

    constructor(private element: ElementRef) {
        this.debouncer
            .pipe(debounceTime(500))
            .subscribe(() => this.scrolled.emit());
    }
}
