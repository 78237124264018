import {
    ChangeDetectionStrategy,
    Component,
    inject,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { AccessVisibility, SystemEntity } from '@wdx/clmi/api-models';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import { KeyValueObject, WdxDestroyClass } from '@wdx/shared/utils';
import { takeUntil } from 'rxjs/operators';
import { Table } from '../../../models/table.model';
import { EntityQueryParamPipe } from '../../../pipes/entity-query-param.pipe';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { ModalManagementService } from '../../../services/modal-management.service';
import { AccessVisibilityFacade } from './shared/services/access-visibility.facade';

@Component({
    selector: 'clmi-access-visibility',
    templateUrl: './access-visibility.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessVisibilityComponent
    extends WdxDestroyClass
    implements OnInit
{
    modalId: string;

    accessVisibility: AccessVisibility[];
    isLoading: boolean;
    hasError: boolean;

    accessVisibilityTable: Table;
    searchResultsTable: Table;

    @ViewChild('nameCell', { read: TemplateRef }) nameCell: TemplateRef<any>;
    @ViewChild('reasonCell', { read: TemplateRef })
    reasonCell: TemplateRef<any>;

    private entityRouteService = inject(EntityRouteService);

    constructor(
        private facade: AccessVisibilityFacade,
        private modalManagementService: ModalManagementService
    ) {
        super();
    }

    ngOnInit(): void {
        this.modalId = new RandomStringPipe().transform();
        this.facade.accessVisibility$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((accessVisibility) => {
                this.accessVisibility = accessVisibility;
                this.accessVisibilityTable = this.createTable(accessVisibility);
            });
        this.facade.isLoading$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isLoading) => (this.isLoading = isLoading));
        this.facade.hasError$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((hasError) => (this.hasError = hasError));
    }

    open(id: string, entityType: SystemEntity): void {
        this.facade.getAccessVisibility(id, entityType);
        this.modalManagementService.openModalWithId(this.modalId);
    }

    close(): void {
        this.modalManagementService.closeActiveModal();
    }

    onSearch(results: AccessVisibility[]): void {
        this.searchResultsTable = results && this.createTable(results);
    }

    private createTable(accessVisibility: AccessVisibility[]): Table {
        return (
            accessVisibility && {
                headers: [
                    {
                        value: 'Name',
                    },
                    {
                        value: 'Reason',
                    },
                ],
                rows: accessVisibility.map((row) => ({
                    values: [
                        {
                            templateRef: this.nameCell,
                            data: {
                                avatars: [
                                    new PartyToAvatarPipe().transform(
                                        row.accessor
                                    ),
                                ],
                                name: row.accessor.name,
                                routerLink:
                                    this.entityRouteService.routeForEntity(
                                        SystemEntity[row.accessor?.partyType],
                                        row.accessor?.id
                                    ),
                                queryParams:
                                    new EntityQueryParamPipe().transform(
                                        SystemEntity[row.accessor?.partyType]
                                    ),
                            },
                        },
                        {
                            templateRef: this.reasonCell,
                            data: {
                                reasons: [...row.reasons]
                                    .sort((a, b) => {
                                        const reasonA = a.reason.toUpperCase();
                                        const reasonB = b.reason.toUpperCase();
                                        return reasonA < reasonB
                                            ? -1
                                            : reasonA > reasonB
                                            ? 1
                                            : 0;
                                    })
                                    .reduce((accumulator, reason) => {
                                        const key = `${reason.reason}`;
                                        return (accumulator = {
                                            ...accumulator,
                                            [key]: [
                                                ...(accumulator[key] || []),
                                                reason.displayMessage.value,
                                            ],
                                        });
                                    }, {} as KeyValueObject),
                            },
                        },
                    ],
                })),
            }
        );
    }
}
