export enum WdxButtonStyle {
    Primary = 'primary',
    OutlinePrimary = 'outline-primary',
    Link = 'link',
    Inline = 'inline',
    Danger = 'danger',
    None = 'none',
    Success = 'success',
}

export enum WdxButtonSize {
    Normal = 'normal',
    Lg = 'lg',
    Sm = 'sm',
}
