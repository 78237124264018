import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { View } from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import {
    TRANSLATION_CREATE_NEW,
    TRANSLATION_ENTITY_SAVEDFILTER_PLURAL,
    TRANSLATION_FILTER_PERSONAL_VIEW,
    TRANSLATION_FILTER_SYSTEM_VIEW,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { takeUntil } from 'rxjs/operators';
import { ICON_USER_COG } from '../../../../constants/icons.constants';
import { FormFiltersConfig, FormFiltersEvent } from '../form-filters.component';
import {
    FilterQueryService,
    FilterViewFacadeService,
} from '../shared/services';
import { QuerySectionsFacadeService } from './../shared/services/query-sections-facade/query-sections-facade.service';

@Component({
    selector: 'clmi-simple-view',
    templateUrl: './simple-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleFilterViewComponent extends WdxDestroyClass {
    @Input() config: FormFiltersConfig = {};
    @Output() formFiltersEvent = new EventEmitter<FormFiltersEvent>();

    personalViews: View[] = [];
    readonly ICON_USER_COG = ICON_USER_COG.icon;
    readonly FEATURE_FLAG_CUSTOM_VIEWS = FeatureFlag.CustomViews;
    readonly CREATE_NEW = TRANSLATION_CREATE_NEW;
    readonly ENTITY_SAVEDFILTER_PLURAL = TRANSLATION_ENTITY_SAVEDFILTER_PLURAL;
    readonly FILTER_PERSONAL_VIEW = TRANSLATION_FILTER_PERSONAL_VIEW;
    readonly FILTER_SYSTEM_VIEW = TRANSLATION_FILTER_SYSTEM_VIEW;

    constructor(
        public filterViewFacadeService: FilterViewFacadeService,
        private querySectionsFacadeService: QuerySectionsFacadeService,
        private filterQueryService: FilterQueryService
    ) {
        super();
        this.filterViewFacadeService.viewsPersonal$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((personalViews) => {
                this.personalViews = personalViews;
            });
        this.filterQueryService.updateTableHeader$.next({ isFavourite: false });
    }

    toggleQueryBuilder(): void {
        this.querySectionsFacadeService.toggleQueryBuilder();
        this.filterQueryService.inBuilder = true;
    }
}
