import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxAlertComponent } from './wdx-alert.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxAlertComponent],
    exports: [WdxAlertComponent],
})
export class WdxAlertModule {}
