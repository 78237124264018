import { Component } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import { ExtendedFieldDefinitionOption } from '../../../models';
import { IFormDynamicData } from '../../../interfaces';
import { FieldDefinitionOption } from '@wdx/shared/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'wdx-ff-radio-buttons-control',
    templateUrl: './form-radio-buttons-control.component.html',
})
export class FormRadioButtonsControlComponent extends BaseWdxFormControlClass {
    public fieldDefOptions$ = this.options$ as BehaviorSubject<
        FieldDefinitionOption[]
    >;

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData
    ) {
        super(controlContainer, dynamicDataService);
    }

    trackByFn(index: number, option: ExtendedFieldDefinitionOption) {
        return option.value;
    }
}
