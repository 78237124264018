<div
    class="async-loader-container"
    [ngClass]="{
        'spinner-container': throbberContainerStyle && isLoading,
        'd-block h-100': !isLoading
    }"
>
    <ng-container *ngIf="!isForInfinityScroll">
        <ng-container
            *ngTemplateOutlet="
                spinner;
                context: { overlay: true, infinity: false }
            "
        ></ng-container>
    </ng-container>

    <ng-container
        *ngIf="
            isForInfinityScroll || (!isLoading && !hasError) || overlayContent
        "
    >
        <div
            *ngIf="
                spinnerPosition === 'top' &&
                isForInfinityScroll &&
                (isLoading || hasError)
            "
            class="d-block position-relative"
            [class.h-100]="isInitialLoad"
        >
            <ng-container
                *ngTemplateOutlet="
                    spinner;
                    context: { overlay: false, infinity: true }
                "
            ></ng-container>
        </div>
        <ng-content></ng-content>
        <div
            *ngIf="
                spinnerPosition === 'bottom' &&
                isForInfinityScroll &&
                (isLoading || hasError)
            "
            class="d-block position-relative"
            [class.h-100]="isInitialLoad"
        >
            <ng-container
                *ngTemplateOutlet="
                    spinner;
                    context: { overlay: false, infinity: true }
                "
            ></ng-container>
        </div>
    </ng-container>
</div>

<ng-template #spinner let-overlay="overlay" let-infinity="infinity">
    <div *ngIf="isLoading || hasError">
        <ng-container *ngIf="isLoading">
            <div
                *ngIf="overlay && overlayContent"
                class="spinner-overlay"
            ></div>
            <wdx-spinner
                [class.absolute-center]="!infinity"
                [class.infinity-spinner]="infinity"
                [class.initial-load]="isInitialLoad"
                [class.top-spinner]="spinnerPosition === 'top'"
            ></wdx-spinner>
        </ng-container>
        <h6 *ngIf="hasError">{{ SOMETHING_WENT_WRONG | translate }}</h6>
    </div>
</ng-template>
