import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { TranslatePipe } from '@wdx/shared/utils';
import { AtomAsyncDataComponent } from './atom-async-data.component';

@NgModule({
    imports: [CommonModule, WdxSpinnerModule, TranslatePipe],
    declarations: [AtomAsyncDataComponent],
    exports: [AtomAsyncDataComponent],
})
export class AtomAsyncDataModule {}
