import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WdxBaseInputLabelIdClass } from '@wdx/shared/components/abstract-classes';
import {
    WdxButtonSize,
    WdxButtonStyle,
} from '@wdx/shared/components/wdx-button';

@Component({
    selector: 'wdx-radio-button',
    templateUrl: './wdx-radio-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxRadioButtonComponent extends WdxBaseInputLabelIdClass {
    @Input() label?: string;
    @Input() icon?: string;
    @Input() checked?: boolean;
    @Input() disabled = false;
    @Input() checkedStyle = WdxButtonStyle.Primary;
    @Input() value = '';
    @Input() size: WdxButtonSize = WdxButtonSize.Normal;

    public readonly WdxButtonStyle = WdxButtonStyle;
}
