<div class="d-flex align-items-center justify-content-end">
    <wdx-icon
        [class.invisible]="!data?.isMine"
        icon="user-tie"
        icon="lock"
        iconStyle="fas"
    ></wdx-icon>

    <molecule-avatar
        *ngIf="data?.avatar"
        class="ms-2"
        size="sm"
        data-cy="action-button-cell-avatar"
        [avatar]="data?.avatar"
    ></molecule-avatar>

    <wdx-toggle-button
        *ngIf="!data?.hideFavourite"
        class="ms-2"
        size="sm"
        data-cy="action-button-cell-favourite"
        [toggle]="data?.isFavourite"
        (wdxOnChange)="onToggleChange($event)"
    ></wdx-toggle-button>

    <molecule-action-button
        class="ms-2"
        data-cy="action-button-cell-action-menu"
        [actionButton]="data?.contextMenu"
        (selectMenuItem)="onMenuItemSelected($event)"
    ></molecule-action-button>
</div>
