<organism-cards-list
    [cardType]="cardType"
    [searchType]="searchType"
    [searchTypePlural]="searchTypePlural"
    [activityCards]="
        activities | fn : activitiesAsToDoItems : entityRouteService
    "
    [totalRecordCount]="totalRecordCount"
    [cardsPerRow]="activitiesPerRow"
    [filterHandler]="filterHandler"
    [filtersModalId]="filtersModalId"
    [formFilterName]="INBOX_FILTER_FORM"
    [showFilters]="showFilters"
    [showFilterBar]="showFilterBar"
    [showSearchBar]="showSearchBar"
    [showFavourite]="showFavourite"
    [limit]="limit"
    [scrollMode]="scrollMode"
    [addWidgetContainerPadding]="addWidgetContainerPadding"
    (search)="onSearch($event)"
    (infinityScrollFired)="onInfinityScrollFired()"
>
    <div class="organism-cards-list-action-button">
        <molecule-action-button
            size="md"
            [actionButton]="activityCreateActionButton"
        ></molecule-action-button>
    </div>
</organism-cards-list>
