import { Pipe, PipeTransform } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/api-models';

@Pipe({ name: 'includeContactFilterTypes' })
export class IncludeContactFilterTypesPipe implements PipeTransform {
    transform(filterType: SystemEntity): boolean {
        return [
            SystemEntity.Lead,
            SystemEntity.Person,
            SystemEntity.Adviser,
            SystemEntity.Client,
            SystemEntity.Organisation,
            SystemEntity.Intermediary,
        ].includes(filterType);
    }
}
