import { CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    precision: 2,
    prefix: '',
    suffix: '',
    thousands: ',',
    nullable: true,
    min: undefined,
    max: undefined,
    inputMode: CurrencyMaskInputMode.NATURAL,
};

export const currencyMaskNumberConfigOverride: Partial<CurrencyMaskConfig> = {
    thousands: ',',
    decimal: '',
    precision: 0,
};
