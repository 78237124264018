import { createAction, props } from '@ngrx/store';
import {
    PaginatedApiResponse,
    QueryType,
    SelectType,
} from '@wdx/clmi/api-services/models';
import { PaginationOptions } from '@wdx/clmi/api-services/services';

const PREFIX = '[Filter]';

export const updateFilterResults = createAction(
    `${PREFIX} Update Filter Results`,
    props<{
        queryType: QueryType;
        selectType: SelectType;
        paginationOptions: PaginationOptions;
        filters: any;
    }>()
);

export const updateFilterResultsSuccess = createAction(
    `${PREFIX} Update Filter Results Success`,
    props<{
        payload: PaginatedApiResponse<any>;
        selectType: SelectType;
    }>()
);

export const updateFilterResultsFailure = createAction(
    `${PREFIX} Update Filter Results Failure`,
    props<{
        error: Error;
        selectType: SelectType;
    }>()
);

export const resetFilterResults = createAction(
    `${PREFIX} Reset Filter Results`,
    props<{
        selectType: SelectType;
    }>()
);

export const updateAnyFilterProperty = createAction(
    `${PREFIX} Update Any Filter Property`,
    props<{
        payload: { [key: string]: any };
        id: string;
        filterType: string;
    }>()
);
