<div
    class="grid audit bg-body"
    data-cy="wdx-audit-row-grid"
    [class.audit--dividerX1]="!header"
    [class.audit--dividerX2]="header"
>
    <div
        class="g-col-3"
        data-cy="wdx-audit-row-label"
        [class.audit__body]="!header"
        [class.audit__header]="header"
    >
        <div class="d-flex flex-column h-100 justify-content-center">
            <ng-content select="[wdxAuditRowLabel]"></ng-content>
        </div>
    </div>

    <div
        class="g-col-3 audit--active"
        data-cy="wdx-audit-row-before"
        [class.audit__body]="!header"
        [class.audit__header]="header"
        [class.audit--changed-before]="dataChanged"
        [class.fw-bold]="dataChanged"
    >
        <ng-content select="[wdxAuditRowBefore]"></ng-content>
    </div>

    <div
        class="g-col-3 audit--active"
        data-cy="wdx-audit-row-after"
        [class.audit__body]="!header"
        [class.audit__header]="header"
        [class.audit--changed-after]="dataChanged"
        [class.fw-bold]="dataChanged"
    >
        <ng-content select="[wdxAuditRowAfter]"></ng-content>
    </div>

    <div
        class="g-col-2"
        data-cy="wdx-audit-row-date"
        [class.audit__body]="!header"
        [class.audit__header]="header"
    >
        <div class="d-flex flex-column h-100 justify-content-center">
            <ng-content select="[wdxAuditRowDate]"></ng-content>
        </div>
    </div>

    <div
        class="g-col-auto"
        data-cy="wdx-audit-row-user"
        [class.audit__body]="!header"
        [class.audit__header]="header"
    >
        <div class="d-flex flex-column h-100 justify-content-center">
            <ng-content select="[wdxAuditRowUser]"></ng-content>
        </div>
    </div>
</div>
