import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { GoalType } from '@wdx/clmi/api-models';

@Injectable()
export class GoalTypesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getAll(): Observable<GoalType[]> {
        return this.http.get<GoalType[]>(
            `${this.config.getConfiguration().API_BASE}/goaltype`,
            {}
        );
    }
}
