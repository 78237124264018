import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxCheckboxTileComponent } from './wdx-checkbox-tile.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxCheckboxTileComponent],
    exports: [WdxCheckboxTileComponent],
})
export class WdxCheckboxTileModule {}
