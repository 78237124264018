import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';
import { PersonalSettingsSegment } from './personal-settings-url-segment';

@Injectable({ providedIn: 'root' })
export class PersonalSettingsService extends BaseApiService {
    getSettings(): Observable<Record<string, any>> {
        return this.get<Record<string, any>>(
            `${PersonalSettingsSegment.USER}/${PersonalSettingsSegment.ME}/${PersonalSettingsSegment.SETTINGS}`
        );
    }

    putSettings(
        settings: Record<string, any>
    ): Observable<Record<string, any>> {
        return this.put<Record<string, any>>(
            `${PersonalSettingsSegment.USER}/${PersonalSettingsSegment.ME}/${PersonalSettingsSegment.SETTINGS}`,
            settings
        );
    }
}
