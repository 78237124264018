import * as authenticationActions from '../../state/authentication/authentication.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface State {
    lastActive?: Date;
}

export const initialState: State = {
    lastActive: null,
};

const reducerSetup = createReducer(
    initialState,

    on(
        authenticationActions.userActivityAction,
        (state): State => ({
            ...state,
            lastActive: new Date(),
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
