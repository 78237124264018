import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { NavItem, TRANSLATION_SELECT_LABEL } from '@wdx/shared/utils';
import { DropdownButtonStyle } from '../../../../models/dropdown-button-style.model';
import { RandomStringPipe } from '../../../../pipes/random-string.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-context-menu-dropdown-dumb',
    templateUrl: './molecule-context-menu-dropdown-dumb.component.html',
})
export class MoleculeContextMenuDropdownDumbComponent implements OnInit {
    @Input() set menu(menu: NavItem[]) {
        this._menu = menu;
        this.menuHasLinkedMenu = menu?.some(
            (menuItem) =>
                menuItem.linkedMenu$?.value?.length ||
                menuItem.linkedMenu?.length
        );
    }
    get menu(): NavItem[] {
        return this._menu;
    }
    @Input() menuTitle: string;
    @Input() buttonStyle: DropdownButtonStyle;
    @Input() placement: string;
    @Input() btnClass: string;
    @Input() altText: string;
    @Input() isMulti: boolean;
    @Input() autoClose: boolean | 'outside' | 'inside';
    @Input() hasSearch: boolean;
    @Input() highlightSelected: boolean;
    @Input() size: string;
    @Input() parentContainer: string;
    @Input() hideDropdownChevron: boolean;
    @Input() set selectedMenuItems(menuItems: NavItem[]) {
        this._selectedMenuItems = menuItems;
        this.selectedMenuItemValues = menuItems.map(
            (menuItem) => menuItem.value
        );
    }
    get selectedMenuItems(): NavItem[] {
        return this._selectedMenuItems;
    }
    @Input() disabled: boolean;
    @Input() isLoading: boolean;
    @Input() cySelector: string;
    @Input() showButton: boolean;

    @Output() singleItemSelected = new EventEmitter<NavItem>();
    @Output() multipleItemsSelected = new EventEmitter<NavItem[]>();
    @Output() dropdownToggled = new EventEmitter<boolean>();

    @ViewChild(NgbDropdown) dropdown: NgbDropdown;

    ctxId: string;
    selectedMenuItemValues: string[];
    menuHasLinkedMenu: boolean;

    _menu: NavItem[];
    _selectedMenuItems: NavItem[];

    readonly DROPDOWN_BUTTON_STYLE = DropdownButtonStyle;
    readonly SELECT = TRANSLATION_SELECT_LABEL;

    ngOnInit() {
        this.ctxId = new RandomStringPipe().transform();
    }

    onSingleItemSelected(navItem: NavItem): void {
        this.singleItemSelected.emit(navItem);
        this.closeMenuIfLinked();
    }

    onMultipleItemsSelected(navItems: NavItem[]): void {
        this.multipleItemsSelected.emit(navItems);
        this.closeMenuIfLinked();
    }

    closeMenuIfLinked(): void {
        if (this.menuHasLinkedMenu) {
            this.dropdown.close();
        }
    }

    onClick(event: Event) {
        if (!this.disabled) {
            this.dropdown.toggle();
            this.dropdownToggled.emit(this.dropdown.isOpen());
            event.stopPropagation();
        }
    }
}
