import { Action, createReducer, on } from '@ngrx/store';
import { LookupFieldResult } from '@wdx/clmi/api-models';
import { CrudStateObject } from '@wdx/clmi/api-services/models';
import * as lookupsActions from './lookups.actions';

export interface State {
    lookups: CrudStateObject<LookupFieldResult>;
    currentlookupId?: string;
}

export const initialState: State = {
    lookups: {},
    currentlookupId: '',
};

const reducerSetup = createReducer(
    initialState,

    on(
        lookupsActions.getLookup,
        lookupsActions.getContextualLookup,
        (state, props): State => ({
            ...state,
            lookups: {
                ...state.lookups,
                [props.lookupId]: {
                    ...(state.lookups[props.lookupId] ||
                        ({} as CrudStateObject<LookupFieldResult>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        lookupsActions.getLookupSuccess,
        lookupsActions.getContextualLookupSuccess,
        (state, props): State => ({
            ...state,
            lookups: {
                ...state.lookups,
                [props.lookupId]: {
                    ...state.lookups[props.lookupId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.results,
                },
            },
        })
    ),

    on(
        lookupsActions.getLookupFailure,
        lookupsActions.getContextualLookupFailure,
        (state, props): State => ({
            ...state,
            lookups: {
                ...state.lookups,
                [props.lookupId]: {
                    ...state.lookups[props.lookupId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        lookupsActions.clearLookup,
        (state, props): State => ({
            ...state,
            lookups: {
                ...state.lookups,
                [props.lookupId]: {
                    ...(state.lookups[props.lookupId] ||
                        ({} as CrudStateObject<LookupFieldResult>)),
                    list: [],
                },
            },
        })
    ),

    on(
        lookupsActions.setCurrentLookupId,
        (state, props): State => ({
            ...state,
            currentlookupId: props.lookupId,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
