import { Pipe, PipeTransform } from '@angular/core';
import { WdxAuditInfo } from '../../wdx-audit.interface';

@Pipe({ name: 'childHasChanged', standalone: true })
export class WdxAuditChildHasChangedPipe implements PipeTransform {
    transform(auditInfo: WdxAuditInfo): boolean {
        if (auditInfo?.hasChanged) {
            return true;
        }
        if (!auditInfo?.children?.length) {
            return false;
        }
        const childHasChanged = (info: WdxAuditInfo): boolean => {
            if (!info?.children?.length) {
                return false;
            }
            return info.children.reduce((hasChanged, child) => {
                if (hasChanged || child.hasChanged) {
                    hasChanged = true;
                }
                if (!hasChanged && child.children?.length) {
                    hasChanged = childHasChanged(child);
                }
                return hasChanged;
            }, false);
        };
        return childHasChanged(auditInfo);
    }
}
