import { createSelector } from '@ngrx/store';
import * as currenciesReducer from './currencies.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    currencies: currenciesReducer.State;
}): currenciesReducer.State => state.currencies;

/**
 * Reducer property selectors
 */
export const getIsLoadingList = createSelector(
    getState,
    (state: currenciesReducer.State, props: any) =>
        state.currencies[props.id]?.isLoadingList
);
export const getHasLoadingListError = createSelector(
    getState,
    (state: currenciesReducer.State, props: any) =>
        state.currencies[props.id]?.hasLoadingListError
);
export const getList = createSelector(
    getState,
    (state: currenciesReducer.State, props: any) =>
        state.currencies[props.id]?.list
);
