import { createFeature, createReducer, on } from '@ngrx/store';
import { FormDataHistory, Team, TeamMember } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as teamActions from '../../state/team/team.actions';

export interface State {
    team: CrudStateObject<Team>;
    history: CrudStateObject<FormDataHistory>;
}

export const initialState: State = {
    team: {},
    history: {},
};

export const teamFeature = createFeature({
    name: 'team',
    reducer: createReducer(
        initialState,
        on(
            teamActions.getForId,
            (state, props): State => ({
                ...state,
                team: {
                    ...state.team,
                    [props.teamId]: {
                        ...(state.team[props.teamId] ||
                            ({} as CrudState<Team>)),
                        isLoadingSingle: true,
                        hasLoadingSingleError: false,
                    },
                },
            })
        ),

        on(
            teamActions.getForIdSuccess,
            (state, props): State => ({
                ...state,
                team: {
                    ...state.team,
                    [props.team.id]: {
                        ...state.team[props.team.id],
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                        single: props.team,
                    },
                },
            })
        ),

        on(
            teamActions.getForIdFailure,
            (state, props): State => ({
                ...state,
                team: {
                    ...state.team,
                    [props.teamId]: {
                        ...state.team[props.teamId],
                        isLoadingSingle: false,
                        hasLoadingSingleError: true,
                    },
                },
            })
        ),

        on(
            teamActions.getTeamMembersForId,
            (state, props): State => ({
                ...state,
                team: {
                    ...state.team,
                    [props.teamId]: {
                        ...(state.team[props.teamId] ||
                            ({} as CrudState<Team>)),
                        isLoadingList: true,
                        hasLoadingListError: false,
                    },
                },
            })
        ),

        on(
            teamActions.getTeamMembersForIdSuccess,
            (state, props): State => ({
                ...state,
                team: {
                    ...state.team,
                    [props.teamId]: {
                        ...(state.team[props.teamId] ||
                            ({} as CrudState<TeamMember>)),
                        isLoadingList: false,
                        hasLoadingListError: false,
                        list: props.teamMembers,
                    },
                },
            })
        ),

        on(
            teamActions.getTeamMembersForIdFailure,
            (state, props): State => ({
                ...state,
                team: {
                    ...state.team,
                    [props.teamId]: {
                        ...(state.team[props.teamId] ||
                            ({} as CrudState<Team>)),
                        isLoadingList: false,
                        hasLoadingListError: true,
                    },
                },
            })
        ),

        on(
            teamActions.getHistoryForId,
            (state, props): State => ({
                ...state,
                history: {
                    ...state.history,
                    [props.teamId]: {
                        ...(state.history[props.teamId] ||
                            ({} as CrudState<FormDataHistory>)),
                        isLoadingList: true,
                        hasLoadingListError: false,
                    },
                },
            })
        ),

        on(
            teamActions.getHistoryForIdSuccess,
            (state, props): State => ({
                ...state,
                history: {
                    ...state.history,
                    [props.teamId]: {
                        ...state.history[props.teamId],
                        isLoadingList: false,
                        hasLoadingListError: false,
                        list: props.history,
                    },
                },
            })
        ),

        on(
            teamActions.getHistoryForIdFailure,
            (state, props): State => ({
                ...state,
                history: {
                    ...state.history,
                    [props.teamId]: {
                        ...state.history[props.teamId],
                        isLoadingList: false,
                        hasLoadingListError: true,
                    },
                },
            })
        )
    ),
});

export const {
    name, // feature name
    reducer, // feature reducer
    selectTeamState, // feature selector
    selectTeam, // selector for `team` property
    selectHistory, // selector for `history property
} = teamFeature;
