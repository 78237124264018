<div class="dashboard dashboard-container">
    <div
        class="grid dashboard-grid dashboard-grid-filters h-100"
        [class.collapsed]="
            (filterQueryService?.isFilterHidden$ | ngrxPush) || isWidgetView
        "
    >
        <div class="filters-column h-100 overflow-hidden">
            <div class="d-block h-100 widget">
                <clmi-form-filters class="d-block h-100"></clmi-form-filters>
            </div>
        </div>

        <div class="d-block h-100 overflow-hidden">
            <ng-content></ng-content>
        </div>
    </div>
</div>
