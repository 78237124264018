import {
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { WdxIsLoadingComponent } from './components/wdx-is-loading/wdx-is-loading.component';
import { SPINNER_CONTAINER_MIN_HEIGHT } from './constants/wdx-is-loading.static';
import { WdxLoadingType } from './types/wdx-loading-type.type';

@Directive({
    selector: '[wdxIsLoading]',
})
export class WdxIsLoadingDirective {
    @Input() set wdxIsLoading(isLoading: boolean | undefined) {
        this._isLoading = Boolean(isLoading);
        this.updateIsLoading();
    }
    get wdxIsLoading(): boolean {
        return Boolean(this._isLoading);
    }
    _isLoading?: boolean;

    @Input() set hasError(error: boolean) {
        this._hasError = error;
        this.updateIsLoading();
    }
    get hasError(): boolean {
        return Boolean(this._hasError);
    }
    _hasError?: boolean;

    @Input() set loadingType(type: WdxLoadingType) {
        if (type) {
            this._loadingType = type;
        }
        this.updateIsLoading();
    }
    get loadingType(): WdxLoadingType {
        return this._loadingType;
    }
    _loadingType: WdxLoadingType = 'spinner';

    @Input() set loadingOverlay(overlay: boolean) {
        this._overlay = overlay;
        this.updateIsLoading();
    }
    get loadingOverlay(): boolean {
        return Boolean(this._overlay);
    }
    _overlay?: boolean;

    private loadSpinner?: ComponentRef<WdxIsLoadingComponent>;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {}

    private updateIsLoading(): void {
        if (this.wdxIsLoading || this.hasError) {
            this.updateHostStyles();
            this.createLoadSpinner();
        } else {
            this.clearHostStyles();
            this.destroyLoadSpinner();
        }
    }

    private updateHostStyles(): void {
        if (this.loadingOverlay) {
            this.showHost();
            this.positionHost();
            this.setHostHeight();
            this.appendLoadSpinnerAsChild();
        } else {
            this.unpositionHost();
            this.hideHost();
        }
    }

    private clearHostStyles(): void {
        if (this.loadingOverlay) {
            this.unpositionHost();
            this.removeHostHeight();
        } else {
            this.showHost();
        }
    }

    private positionHost(): void {
        const elementPosition = window
            .getComputedStyle(this.elementRef.nativeElement)
            .getPropertyValue('position');
        if (elementPosition === 'static') {
            this.renderer.setStyle(
                this.elementRef.nativeElement,
                'position',
                'relative',
                1
            );
        }
    }

    private unpositionHost(): void {
        this.renderer.removeStyle(this.elementRef.nativeElement, 'position');
    }

    private hideHost(): void {
        this.renderer.setStyle(
            this.elementRef.nativeElement,
            'display',
            'none',
            1
        );
    }

    private showHost(): void {
        this.renderer.removeStyle(this.elementRef.nativeElement, 'display');
    }

    private setHostHeight(): void {
        this.renderer.setStyle(
            this.elementRef.nativeElement,
            'min-height',
            SPINNER_CONTAINER_MIN_HEIGHT,
            1
        );
    }

    private removeHostHeight(): void {
        this.renderer.removeStyle(this.elementRef.nativeElement, 'min-height');
    }

    private createLoadSpinner(): void {
        if (!this.loadSpinner) {
            this.loadSpinner = this.viewContainerRef.createComponent(
                WdxIsLoadingComponent
            );
        }
        this.loadSpinner.setInput('hasError', this.hasError);
        this.loadSpinner.setInput('type', this.loadingType);
        this.loadSpinner.setInput('overlay', this.loadingOverlay);
    }

    private appendLoadSpinnerAsChild(): void {
        if (!this.loadSpinner) {
            this.createLoadSpinner();
        }
        this.elementRef.nativeElement.appendChild(
            this.loadSpinner?.location.nativeElement
        );
    }

    private destroyLoadSpinner(): void {
        this.viewContainerRef.clear();
        this.loadSpinner = undefined;
    }
}
