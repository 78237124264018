<div class="check-tile">
    <input
        type="checkbox"
        [attr.data-cy]="'input-toggle-' + value"
        [value]="value"
        [id]="inputId"
        [checked]="checked"
        [disabled]="disabled"
        (change)="$event.stopPropagation()"
        (click)="onToggle($event)"
    />
    <label [for]="inputId">
        <div class="bullet"></div>
        <div class="tile-icon">
            <ng-content></ng-content>
        </div>
        <div class="tile-label">{{ label }}</div>
    </label>
</div>
