import { Pipe, PipeTransform } from '@angular/core';
import { ReviewStatusType } from '@wdx/clmi/api-models';
import {
    ICON_BAN,
    ICON_CHECK_CIRCLE,
    ICON_HOURGLASS_START,
} from '../constants/icons.constants';
import { CheckReviewSummary } from '../models/check-review-summary.model';
import { Icon } from '@wdx/shared/utils';

@Pipe({ name: 'reviewStatusType' })
export class ReviewStatusIconPipe implements PipeTransform {
    transform(identityCheckReview: CheckReviewSummary): Icon {
        if (identityCheckReview.reviewStatus === ReviewStatusType.Approved) {
            return ICON_CHECK_CIRCLE.icon;
        }

        if (identityCheckReview.reviewStatus === ReviewStatusType.Rejected) {
            return ICON_BAN.icon;
        }

        if (identityCheckReview.reviewStatus === ReviewStatusType.Pending) {
            return ICON_HOURGLASS_START;
        }

        return ICON_BAN.icon;
    }
}
