import { createAction, props } from '@ngrx/store';
import { PartyRoleRelationship } from '@wdx/clmi/api-models';

export const getForParty = createAction(
    '[Professional Connections] Get Professional Connections',
    props<{
        partyId: string;
    }>()
);

export const getForPartySuccess = createAction(
    '[Professional Connections] Get Professional Connections Success',
    props<{
        partyId: string;
        relationships: PartyRoleRelationship[];
    }>()
);

export const getForPartyFailure = createAction(
    '[Professional Connections] Get Professional Connections Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);
