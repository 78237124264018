import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MoleculeButtonGroupComponent } from './molecule-button-group.component';
import { AtomIconButtonModule } from '../../atoms/atom-icon-button/atom-icon-button.module';
import { DirectivesModule } from '../../../directives/directives.module';

@NgModule({
    imports: [
        AtomIconButtonModule,
        CommonModule,
        DirectivesModule,
        RouterModule,
    ],
    declarations: [MoleculeButtonGroupComponent],
    exports: [MoleculeButtonGroupComponent],
})
export class MoleculeButtonGroupModule {}
