import { createAction, props } from '@ngrx/store';
import { ReminderCreate } from '@wdx/clmi/api-models';

export const create = createAction(
    '[Reminders] Create',
    props<{
        reminderCreateData: ReminderCreate;
    }>()
);

export const createSuccess = createAction(
    '[Reminders] Create Success',
    props<{
        reminder: ReminderCreate;
    }>()
);

export const createFailure = createAction(
    '[Reminders] Create Failure',
    props<{
        error: Error;
    }>()
);

export const deleteReminder = createAction(
    '[Reminders] Delete',
    props<{
        reminderId: string;
    }>()
);

export const deleteSuccess = createAction(
    '[Reminders] Delete Success',
    props<{
        reminderId: string;
    }>()
);

export const deleteFailure = createAction(
    '[Reminders] Delete Failure',
    props<{
        error: Error;
    }>()
);
