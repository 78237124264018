import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Campaign, CampaignCreate } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';

@Injectable()
export class MarketingService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getCampaigns(): Observable<Campaign[]> {
        return this.http.get<Campaign[]>(
            `${this.config.getConfiguration().API_BASE}/campaign`
        );
    }

    createCampaign(campaign: CampaignCreate): Observable<Campaign> {
        return this.http.post<CampaignCreate>(
            `${this.config.getConfiguration().API_BASE}/campaign`,
            campaign
        );
    }

    getSingleCampaign(campaignId: string): Observable<Campaign>
    {
        return this.http.get<Campaign>(
            `${this.config.getConfiguration().API_BASE}/campaign/${campaignId}`
        );
    }
}