import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as recentRecordsActions from './recent-records.actions';
import { RecentRecordsService } from './recent-records.service';

@Injectable()
export class RecentRecordsEffects {
    private actions$ = inject(Actions);
    private recentRecordsService = inject(RecentRecordsService);

    getUserRecentRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(recentRecordsActions.getUserRecentRecords),
            switchMap(() =>
                this.recentRecordsService.getUserRecentRecords().pipe(
                    map((recentRecords) =>
                        recentRecordsActions.getUserRecentRecordsSuccess({
                            recentRecords,
                        })
                    ),
                    catchError((error) =>
                        of(
                            recentRecordsActions.getUserRecentRecordsFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateRecentlyViewedRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(recentRecordsActions.updateRecentlyViewedRecord),
            mergeMap((action) =>
                this.recentRecordsService
                    .updateRecentlyViewedRecord(
                        action.entityType,
                        action.recordId
                    )
                    .pipe(
                        map((recentRecord) =>
                            recentRecordsActions.updateRecentlyViewedRecordSuccess(
                                { recentRecord }
                            )
                        ),
                        catchError((error) =>
                            of(
                                recentRecordsActions.updateRecentlyViewedRecordFailure(
                                    { error }
                                )
                            )
                        )
                    )
            )
        )
    );
}
