import { Injectable } from '@angular/core';
import { AnswersFacadeService } from '../../../../../../../state/answers/answers-facade/answers-facade.service';
import { Answer, FilterFieldDefinition } from '@wdx/clmi/api-models';
import { map, Observable, take } from 'rxjs';
import { IFilterDataProvider } from '../../../interfaces/filter-data-provider.interface';

@Injectable()
export class FilterAnswerDataService implements IFilterDataProvider {
    constructor(private afService: AnswersFacadeService) {}

    getData$(definition: FilterFieldDefinition): Observable<Answer[]> {
        return this.afService.getAnswer$(definition.answerSetCode).pipe(
            take(1),
            map((answers: Answer[]) =>
                answers.filter((answer) => !answer.isDisabled)
            )
        );
    }
}
