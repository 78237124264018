import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { takeUntil } from 'rxjs/operators';
import { Privilege } from '../models/privilege.model';
import { PrivilegesService } from '../shared/services/privileges/privileges.service';

@Directive({ selector: '[hasPrivilege]' })
export class HasPrivilegeDirective extends WdxDestroyClass implements OnInit {
    @Input() hasPrivilege: Privilege | Privilege[];
    @Input() hasPrivilegeElse: TemplateRef<any>;

    constructor(
        private privilegesService: PrivilegesService,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.hasPrivilege || !this.hasPrivilege?.length) {
            this.updateView(true);
        } else {
            const privileges = Array.isArray(this.hasPrivilege)
                ? this.hasPrivilege
                : [this.hasPrivilege];
            this.privilegesService
                .hasPrivilege$(...privileges)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((hasPrivilege) => this.updateView(hasPrivilege));
        }
    }

    private updateView(hasPrivilege: boolean) {
        this.viewContainerRef.clear();
        if (hasPrivilege || this.hasPrivilegeElse) {
            this.viewContainerRef.createEmbeddedView(
                hasPrivilege ? this.templateRef : this.hasPrivilegeElse
            );
        }
    }
}
