import {
    ChangeDetectionStrategy,
    Component,
    Input,
    inject,
} from '@angular/core';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { Observable, takeUntil, tap } from 'rxjs';
import { ModalManagementService } from '../../../../services/modal-management.service';
import { ImportFileUploaderService } from '../../../features/import-file-uploader';
import { IMPORT_ENTITIES_MODAL_ID } from '../import-entities.constant';
import { ImportEntitiesFacadeService } from '../services/import-entities-facade.service';

@Component({
    selector: 'clmi-import-entities-modal',
    templateUrl: './import-entities-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportEntitiesModalComponent extends WdxDestroyClass {
    private importEntitiesFacadeService = inject(ImportEntitiesFacadeService);
    private importFileUploaderService = inject(ImportFileUploaderService);
    private modalManagementService = inject(ModalManagementService);

    importEntities$: Observable<string[]>;
    importEntitiesIsLoading$: Observable<boolean>;
    importEntitiesHasError$: Observable<boolean>;

    @Input() modalTitle: string;

    readonly IMPORT_ENTITIES_MODAL_ID = IMPORT_ENTITIES_MODAL_ID;

    fetchData() {
        this.importEntitiesFacadeService.setImportEntitiesList();
        this.importEntities$ =
            this.importEntitiesFacadeService.importEntities$.pipe(
                takeUntil(this.destroyed$),
                tap(
                    (entities) =>
                        !entities &&
                        this.importEntitiesFacadeService.getImportEntities()
                )
            );
        this.importEntitiesIsLoading$ =
            this.importEntitiesFacadeService.importEntitiesIsLoading$;
        this.importEntitiesHasError$ =
            this.importEntitiesFacadeService.importEntitiesHasError$;
    }
    onModalClosed(): void {
        this.modalManagementService.closeActiveModal();
    }

    onImport(entity): void {
        this.importFileUploaderService.openModal(entity);
    }
}
