import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WdxSize } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-close',
    templateUrl: './atom-close.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'rounded-circle' },
})
export class AtomCloseComponent {
    @Input() size: WdxSize = 'md';
    @Input() btnClass = 'btn';
    @Input() cySelector: string;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click: EventEmitter<boolean> = new EventEmitter<boolean>();

    hasClicked($event: any) {
        $event.stopPropagation();
        this.click.emit(true);
    }
}
