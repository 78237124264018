<span
    *ngIf="lifecycleStage"
    class="badge d-flex align-items-center"
    data-cy="lifecycle-stage-tag"
    container="body"
    triggers="mouseenter:mouseleave"
    [ngClass]="lifecycleStage | lifecycleStageToCSSClass"
    [openDelay]="POPOVER_OPEN_DELAY"
    [ngbPopover]="popoverText"
    [disablePopover]="!popoverText"
>
    <wdx-icon *ngIf="icon" class="me-1" [scale]="1" [icon]="icon"></wdx-icon>
    <div data-cy="lifecycle-stage-status">
        {{ lifecycleStageTranslation | translate | titleCase }}
    </div>
</span>
