import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/api-models';
import { CrudStatus } from '@wdx/shared/utils';
import * as reducer from './mailing-instructions.reducer';

export const selectMailingInstructionsState =
    createFeatureSelector<reducer.State>(reducer.mailingInstructionsStore);

export const selectStatus = createSelector(
    selectMailingInstructionsState,
    (state) => state.status
);

export const selectAllMailingInstructionByEntityId = (
    entityType: SystemEntity,
    entityId: string
) =>
    createSelector(selectMailingInstructionsState, (state) =>
        state.entityId === entityId &&
        state.entityType === entityType &&
        state.status === CrudStatus.Success
            ? reducer.selectAllMailingInstructions(state)
            : []
    );

export const selectMailingInstructionsByEntityIds = (
    entityType: SystemEntity,
    entityId: string,
    ids: string[]
) =>
    createSelector(selectMailingInstructionsState, (state) =>
        state.entityId === entityId &&
        state.entityType === entityType &&
        state.status === CrudStatus.Success
            ? ids.map((id) => reducer.selectMailingInstructionsById(state)[id])
            : []
    );
