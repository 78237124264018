import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pluralString' })
export class PluralStringPipe implements PipeTransform {
    transform(
        singularWord: string,
        count: number,
        pluralisedWord?: string
    ): string {
        return count === 1
            ? singularWord
            : pluralisedWord || `${singularWord}s`;
    }
}
