<clmi-view-shell>
    <widget-table
        class="h-100"
        hasMobileFilter="true"
        hasFilterSearch="true"
        [searchType]="searchType"
        [searchTypePlural]="searchTypePlural"
        [searchPlaceholder]="searchPlaceholder"
        [tablePaging]="paging"
        [filterHandler]="filterHandler"
        [isLoading]="loading$ | ngrxPush"
        [hasError]="hasError$ | ngrxPush"
        [table]="table"
        [displayMode]="TABLE_DISPLAY_MODE.ResponsiveCards"
        [searchBarActionButtons]="actionButtons$ | ngrxPush"
        [legend]="myCoverageLegend"
        [resetTableData]="false"
        [canSelectRows]="(entityActions$ | ngrxPush)?.length"
        [multiselectBannerButtons]="actionsButtons$ | ngrxPush"
        (pageChanged)="onPageChanged($event)"
        (actionButtonClicked)="onActionButtonClicked($event)"
        (multiselectActionButtonClicked)="onMultiselect($event)"
    >
        <span prefixActionButtons class="me-2 d-none d-xl-inline-flex">
            <atom-icon-button
                btnClass="btn"
                icon="filter"
                (click)="toggle()"
            ></atom-icon-button>
        </span>
    </widget-table>
</clmi-view-shell>

<ng-template #responsiveCardTemplate let-data="data">
    <div
        class="card card-body card-clickable rounded-0 border-top-0 border-start-0 border-end-0 border d-flex flex-row align-items-stretch"
        role="button"
        [routerLink]="data.infoCard?.routerLinkData"
    >
        <div class="flex-grow-1 d-flex align-items-center text-truncate">
            <molecule-avatar
                class="avatar-single pointer avatar-group-border-transparent"
                size="lg"
                [avatar]="data.infoCard?.avatars[0]"
                [isAvatarInfoEnabled]="
                    data.infoCard?.avatars[0]?.isAvatarInfoEnabled
                "
            ></molecule-avatar>
            <div class="divider-start text-truncate">
                <molecule-info-card
                    class="d-block mb-1"
                    [primaryInfo]="data.infoCard.primaryInfo"
                    [routerLinkData]="data.infoCard.routerLinkData"
                    [secondaryInfo]="data.infoCard.secondaryInfo"
                    [idList]="data.infoCard?.idList"
                ></molecule-info-card>
                <ng-container *ngIf="data.clientLifecycle">
                    <ng-container
                        *ngTemplateOutlet="
                            lifecycleStageCell;
                            context: {
                                data: data.clientLifecycle
                            }
                        "
                    ></ng-container
                ></ng-container>
            </div>
        </div>
        <div class="d-flex flex-column justify-content-between flex-shrink-0">
            <wdx-toggle-button
                size="sm"
                [toggle]="data.actionButton?.isFavourite"
                (wdxOnChange)="
                    onToggleFavourite(
                        $event,
                        data.actionButton?.id,
                        data.actionButton?.contactType
                    )
                "
            ></wdx-toggle-button>

            <molecule-action-button
                [actionButton]="data.actionButton?.contextMenu"
                (selectMenuItem)="
                    onActionButtonMenuItemSelected(
                        $event,
                        data.actionButton?.id,
                        data.actionButton?.partyId,
                        data.actionButton?.party,
                        data.actionButton?.entityType
                    )
                "
            ></molecule-action-button>
        </div>
    </div>
</ng-template>

<ng-template #infoCardCell let-data="data">
    <molecule-info-card
        size="lg"
        [primaryInfo]="data?.primaryInfo"
        [routerLinkData]="data?.routerLinkData"
        [secondaryInfo]="data?.secondaryInfo"
        [tertiaryInfo]="data?.tertiaryInfo"
        [idList]="data?.idList"
        [avatars]="data?.avatars"
        [avatarIconDivider]="true"
    ></molecule-info-card>
</ng-template>

<ng-template #lifecycleStageCell let-data="data">
    <div class="d-flex">
        <molecule-lifecycle-stage-tag
            [lifecycleStage]="data"
        ></molecule-lifecycle-stage-tag>
    </div>
</ng-template>

<ng-template #badgeCell let-data="data">
    <wdx-badge [themeColor]="data.themeColor">
        <div class="d-flex align-items-center">
            <wdx-icon class="me-1" [icon]="data.icon"></wdx-icon>
            <span>
                {{ data.label }}
            </span>
        </div>
    </wdx-badge>
</ng-template>

<ng-template #actionButtonCell let-data="data">
    <div class="d-flex align-items-center justify-content-end">
        <wdx-icon
            [class.invisible]="!data?.isMine"
            icon="user-tie"
            iconStyle="fas"
        ></wdx-icon>

        <molecule-avatar
            *ngIf="data?.avatar"
            class="ms-2"
            size="sm"
            data-cy="action-button-cell-avatar"
            [avatar]="data?.avatar"
        ></molecule-avatar>

        <wdx-toggle-button
            class="ms-2"
            size="sm"
            data-cy="action-button-cell-favourite"
            [toggle]="data?.isFavourite"
            (wdxOnChange)="
                onToggleFavourite($event, data?.id, data?.contactType)
            "
        ></wdx-toggle-button>

        <molecule-action-button
            class="ms-2"
            data-cy="action-button-cell-action-menu"
            [actionButton]="data?.contextMenu"
            (selectMenuItem)="
                onActionButtonMenuItemSelected(
                    $event,
                    data?.id,
                    data?.partyId,
                    data?.party,
                    data?.entityType
                )
            "
        ></molecule-action-button>
    </div>
</ng-template>

<atom-modal-wrapper modalId="queryListsModal" [modalTemplate]="listsModal">
    <ng-template #listsModal>
        <organism-modal modalTitle="Add to List">
            <molecule-info-card-search
                [mode]="INFO_CARD_SEARCH_MODE.Select"
                [infoCards]="lists$ | ngrxPush"
                [isLoading]="listsIsLoading$ | ngrxPush"
                [hasError]="listsHasError$ | ngrxPush"
                [clientSideSearch]="true"
                (valueChanged)="onAddToList($event)"
            ></molecule-info-card-search>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
