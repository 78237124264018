import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as batchListActions from './batch-list.actions';
import { BatchListService } from './batch-list.service';

@Injectable()
export class BatchListEffects {
    private actions$ = inject(Actions);
    private batchListService = inject(BatchListService);

    getBatchFileById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(batchListActions.getBatchFileById),
            mergeMap((action) =>
                this.batchListService.getImportFile(action.batchId).pipe(
                    map((batch) =>
                        batchListActions.getBatchFileByIdSuccess({
                            batch: batch,
                            batchId: action.batchId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            batchListActions.getBatchFileByIdFailure({
                                batchId: action.batchId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteBatch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(batchListActions.deleteBatch),
            mergeMap((action) =>
                this.batchListService.deleteBatch(action.id).pipe(
                    map(() =>
                        batchListActions.deleteBatchSuccess({
                            id: action.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            batchListActions.deleteBatchFailure({
                                id: action.id,
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );
}
