import { Action, createReducer, on } from '@ngrx/store';
import { Organisation } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as organisationsActions from './organisations.actions';

export interface State {
    organisations: CrudStateObject<Organisation>;
    organisation: CrudState<Organisation>;
}

export const initialState: State = {
    organisations: {},
    organisation: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        organisationsActions.getForParty,
        (state, props): State => ({
            ...state,
            organisations: {
                ...state.organisations,
                [props.partyId]: {
                    ...(state.organisations[props.partyId] ||
                        ({} as CrudState<Organisation>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        organisationsActions.getForPartySuccess,
        (state, props): State => ({
            ...state,
            organisations: {
                ...state.organisations,
                [props.partyId]: {
                    ...state.organisations[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.organisation,
                },
            },
        })
    ),

    on(
        organisationsActions.getForPartyFailure,
        (state, props): State => ({
            ...state,
            organisations: {
                ...state.organisations,
                [props.partyId]: {
                    ...state.organisations[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        organisationsActions.create,
        (state): State => ({
            ...state,
            organisation: {
                ...state.organisation,
                isCreating: true,
                hasCreatingError: false,
            },
        })
    ),

    on(
        organisationsActions.createSuccess,
        (state): State => ({
            ...state,
            organisation: {
                ...state.organisation,
                isCreating: false,
            },
        })
    ),

    on(
        organisationsActions.createFailure,
        (state): State => ({
            ...state,
            organisation: {
                ...state.organisation,
                isCreating: false,
                hasCreatingError: true,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
