import { Injectable, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfigService } from '../../../services/config.service';
import * as rootReducer from '../../../state/_setup/reducers';
import * as globalActions from '../../../state/global/global.actions';

@Injectable({
    providedIn: 'root',
})

/**
 * Guard that is run once on the top level App component
 */
export class TenantAccessGuard implements CanActivate {
    private router = inject(Router);
    private authenticationService = inject(AuthenticationService);
    private configService = inject(ConfigService);
    private store$ = inject(Store<rootReducer.State>);

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.isAuthenticated$.pipe(
            switchMap((isAuthenticated) => {
                /**
                 * Set isLoggedIn status on AuthenticationService
                 */
                this.authenticationService.loggedIn = isAuthenticated;

                /**
                 * If user not authenticated redirect to welcome page and restrict access to app
                 */
                if (!isAuthenticated) {
                    this.router.navigate(['/'], {
                        queryParams: {
                            ['return_to']: state.url,
                        },
                    });

                    return of(false);
                }

                this.store$.dispatch(
                    globalActions.initialiseApp({
                        isAuthenticated: false,
                    })
                );

                return of(true);
            })
        );
    }

    get isAuthenticated$() {
        return this.configService?.config?.TestMode
            ? of(true)
            : this.authenticationService.auth0Service.isAuthenticated$;
    }
}
