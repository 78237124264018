import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as connectionsActions from './connections.actions';
import { ConnectionsService } from './connections.service';

@Injectable()
export class ConnectionsEffects {
    private actions$ = inject(Actions);
    private connectionsService = inject(ConnectionsService);

    getForPartyRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(connectionsActions.getForPartyRole),
            mergeMap((action) =>
                this.connectionsService
                    .getForPartyRole(action.partyRoleId)
                    .pipe(
                        map((relationships) =>
                            connectionsActions.getForPartyRoleSuccess({
                                partyRoleId: action.partyRoleId,
                                relationships,
                            })
                        ),
                        catchError((error) =>
                            of(
                                connectionsActions.getForPartyRoleFailure({
                                    partyRoleId: action.partyRoleId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
