<div class="info-card-container">
    <wdx-info-card *ngFor="let infoCard of infoCards">
        <div *ngIf="infoCard.icon" wdxInfoCardLeading wdxInfoCardSeparator>
            <wdx-icon
                [icon]="infoCard.icon"
                class="icon--image icon-container d-flex justify-content-center align-items-center rounded-circle bg-icon icon-container-size-md"
            >
            </wdx-icon>
        </div>
        <div wdxInfoCardLabel>{{ infoCard.value }}</div>
        <div wdxInfoCardSub>
            {{ infoCard.label | translateTokenisedString }}
        </div>
    </wdx-info-card>
</div>
