import { Injectable, OnDestroy, inject } from '@angular/core';
import {
    FormFrameworkEvent,
    FormStaticService,
    IFormExternalActions,
} from '@wdx/shared/infrastructure/form-framework';
import { ModalManagementService } from '../../../../services/modal-management.service';
import { OpenInNewTabService } from '../../../../shared/services/open-new-tab/open-in-new-tab.service';
import { SystemEntity, UserActionEntity } from '@wdx/clmi/api-models';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class FormExternalActionsService
    extends IFormExternalActions
    implements OnDestroy
{
    cleanup$: Subject<boolean>;

    private modalManagementService = inject(ModalManagementService);
    private ointService = inject(OpenInNewTabService);
    private formStaticService: FormStaticService;
    modalInstanceId: any;

    /**
     * For external triggering of form save, not curently used by CLMi, to implement do this:
     *
     * private triggerSaveSubject = new Subject<void>();
     * private formSavedSubject = new Subject<void>();
     *
     * public getTriggerSave$(): Observable<void> {
     *   return this.triggerSaveSubject.asObservable();
     * }
     *
     *
     * public triggerSave(): void {
     *    this.triggerSaveSubject.next();
     * }
     */
    public getTriggerSave$(): undefined {
        return undefined;
    }

    public triggerSave(): void {
        return undefined;
    }

    public init(formStaticService: FormStaticService): void {
        this.formStaticService = formStaticService;
    }

    public cleanup(): void {
        this.cleanup$.next(true);
        this.cleanup$.complete();
    }

    public closeSpawnedForms(event?: FormFrameworkEvent): void {
        this.modalManagementService.closeActiveModal(event);
    }

    public handleQuickCreate(): void {
        this.modalManagementService.activeModalClosed$
            .pipe(
                takeUntil(this.cleanup$),
                filter((modalId) => this.modalInstanceId === modalId)
            )
            .subscribe(() => this.formStaticService.form.reset());
    }

    public handleOpenInNewTab(entity: string, id?: string): void {
        this.ointService.openInNewTab(
            SystemEntity[entity] || UserActionEntity[entity],
            id
        );
    }

    ngOnDestroy(): void {
        this.cleanup$.next(true);
        this.cleanup$.complete();
    }
}
