import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as rootReducer from './../../state/_setup/reducers';
import * as feedsActions from './feeds.actions';
import { FeedsService } from './feeds.service';

@Injectable()
export class FeedsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private feedsService = inject(FeedsService);

    getForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(feedsActions.getForClientProduct),
            switchMap((action) =>
                this.feedsService
                    .getForClientProduct(action.clientProductId)
                    .pipe(
                        map((feeds) =>
                            feedsActions.getForClientProductSuccess({
                                feeds,
                                clientProductId: action.clientProductId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                feedsActions.getForClientProductFailure({
                                    clientProductId: action.clientProductId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getFeedsForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(feedsActions.getFeedsForClient),
            switchMap((action) =>
                this.feedsService.getFeedsForClient(action.clientId).pipe(
                    map((clientFeeds) =>
                        feedsActions.getFeedsForClientSuccess({
                            clientFeeds,
                            clientId: action.clientId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            feedsActions.getFeedsForClientFailure({
                                clientId: action.clientId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
