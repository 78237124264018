import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeCardFooterComponent } from './molecule-card-footer.component';

@NgModule({
    imports: [CommonModule, PipesModule],
    declarations: [MoleculeCardFooterComponent],
    exports: [MoleculeCardFooterComponent],
})
export class MoleculeCardFooterModule {}
