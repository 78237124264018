import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SystemApiService } from '@wdx/clmi/api-services/services';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as systemActions from './system.actions';

@Injectable()
export class SystemEffects {
    private actions$ = inject(Actions);
    private systemApiService = inject(SystemApiService);

    getVersion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(systemActions.getVersion),
            switchMap(() =>
                this.systemApiService.getVersion().pipe(
                    map((version) =>
                        systemActions.getVersionSuccess({ version })
                    ),
                    catchError((error) =>
                        of(
                            systemActions.getVersionFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getBuild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(systemActions.getBuild),
            switchMap(() =>
                this.systemApiService.getBuild().pipe(
                    map((build) => systemActions.getBuildSuccess({ build })),
                    catchError((error) =>
                        of(
                            systemActions.getBuildFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getAppLanguages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(systemActions.getAppLanguages),
            switchMap(() =>
                this.systemApiService.getAppLanguages().pipe(
                    map((applicationLanguages) =>
                        systemActions.getAppLanguagesSuccess({
                            applicationLanguages,
                        })
                    ),
                    catchError((error) =>
                        of(systemActions.getAppLanguagesFailure({ error }))
                    )
                )
            )
        )
    );
}
