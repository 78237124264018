import { Injectable } from '@angular/core';
import { DateTime, LocaleOptions } from 'luxon';
import { WdxDateFormat, WdxDateOptions } from './date-time.model';

/**
 * This services is a reusable date service.
 * It has a dependency on Luxon
 */
@Injectable({
    providedIn: 'root',
})
export class WdxDateTimeService {
    /**
     * This method converts a date isoString to a luxon date object and sets the locale
     *
     * @returns string
     *
     * @param date - This needs to be a date isoString eg '2022-02-16T00:00:00Z' or a valid JS Date object
     * @param locale - This is the isoCode for locale eg. 'en-GB', 'en-US'
     */
    convertDate(date: string | Date, locale: string): DateTime {
        const LUXON_DATE = this.getLuxonDate(date);
        return locale ? LUXON_DATE.setLocale(locale) : LUXON_DATE;
    }

    /**
     * This method should converts a date isoString to a human readable date depended on the locale and format set eg. 16 Feb 2022
     *
     * @returns string
     *
     * @param date - This needs to be a date isoString eg '2022-02-16T00:00:00Z'
     * @param options - WdxDateOptions interface
     */
    convertDateToViewFriendlyFormat(
        date: string | Date,
        options?: WdxDateOptions
    ): string {
        const USER_LOCAL = options?.locale as string;

        const userFormat = options?.format || WdxDateFormat.AbsoluteDate;

        const LUXON_DATE = this.convertDate(date, USER_LOCAL);

        return LUXON_DATE.isValid
            ? this.getFriendlyDate(LUXON_DATE, userFormat)
            : '';
    }

    /**
     * Returns milliseconds of difference between two dates
     * Ex: date1 - date2 = 30000
     * @param date1 - datetime 1
     * @param date2 - datetime 2
     */
    subtractDateTime(date1: Date, date2: Date) {
        return Math.abs(date1.getTime() - date2.getTime());
    }

    /**
     * Returns friendly format from a given format type
     * @param dt
     * @param userFormat
     * Returns based on
     */
    private getFriendlyDate(dt: DateTime, userFormat: WdxDateFormat) {
        switch (userFormat) {
            case WdxDateFormat.AbsoluteDate:
                return dt.toLocaleString(DateTime.DATE_MED);

            case WdxDateFormat.AbsoluteDateTime:
                return dt.toLocaleString(DateTime.DATETIME_MED);

            case WdxDateFormat.RelativeDate:
                return this.getRelativeDate(dt);

            case WdxDateFormat.RelativeDateTime:
                return this.getRelativeDate(dt, true);

            case WdxDateFormat.TimeOnly:
                return dt.toLocaleString(DateTime.TIME_SIMPLE);
        }
    }

    /**
     * Returns Luxon date object from a valid ISO string or date object.
     * @param date
     */
    private getLuxonDate(date: string | Date) {
        return date instanceof Date
            ? DateTime.fromJSDate(date)
            : DateTime.fromISO(date);
    }

    /**
     * A customise formatter to return relative dates
     * @param dt
     */
    private getRelativeDate(dt: DateTime, includeTime = false) {
        if (this.displayRelativeLabel(dt)) {
            return dt
                .toLocaleParts({
                    day: 'numeric',
                    ...(includeTime && this.getTimeOptions()),
                })
                .map((part) =>
                    part.type === 'day'
                        ? dt
                              ?.toRelativeCalendar()
                              ?.replace(/\b(\w)/g, (s) => s.toUpperCase())
                        : part.value
                )
                .join('');
        }

        if (this.isSameYear(dt)) {
            return dt.toLocaleString({
                day: 'numeric',
                month: 'short',
                ...(includeTime && this.getTimeOptions()),
            });
        }
        return dt.toLocaleString({
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            ...(includeTime && this.getTimeOptions()),
        });
    }

    /**
     * Returns true if current day is relative 'today'
     * @param dt
     */
    private displayRelativeLabel(dt: DateTime): boolean {
        const diffDays = DateTime.local().diff(dt, 'days').days;

        // today
        const isToday = dt.toISODate() === DateTime.local().toISODate();

        // tomrrow (filters next month)
        const isTommorrow =
            diffDays > 0 &&
            diffDays < 1 &&
            DateTime.local().plus({ days: 1 }).month === dt.month;

        // yesterday (filters last month)
        const isYesterday =
            diffDays > -1 &&
            diffDays < 0 &&
            DateTime.local().minus({ days: 1 }).month === dt.month;

        return isToday || isYesterday || isTommorrow;
    }

    /**
     * Returns true if current date falls in same year
     * @param dt
     */
    private isSameYear(dt: DateTime): boolean {
        return DateTime.now().year === dt.year;
    }

    /**
     * Returns the required relative time options
     */
    private getTimeOptions() {
        return {
            hour: 'numeric',
            minute: 'numeric',
        } as LocaleOptions;
    }
}
