<organism-case-template-search-dumb
    [activityType]="activityType"
    [entityType]="entityType"
    [activityId]="activityId"
    [clientId]="clientId"
    [opportunityId]="opportunityId"
    [partyId]="partyId"
    [partyRoleId]="partyRoleId"
    [caseId]="caseId"
    [nudgeId]="nudgeId"
    [caseTypes]="caseTypes$ | ngrxPush"
    [caseTypesIsLoading]="caseTypesIsLoading$ | ngrxPush"
    [caseTypesHasError]="caseTypesHasError$ | ngrxPush"
></organism-case-template-search-dumb>
