import { Action, createReducer, on } from '@ngrx/store';
import { FilePreview } from '../../models/document-data.model';
import * as fileDownloadActions from './file-download.actions';
import { CrudStateObject } from '@wdx/shared/utils';

export const FEATURE_KEY = 'fileDownload';

export type FileDownloadStoreSlice = {
    [FEATURE_KEY]: State;
};

export interface State {
    filePreview: CrudStateObject<FilePreview>;
}

export const initialState: State = {
    filePreview: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        fileDownloadActions.getFilePreview,
        (state, props): State => ({
            ...state,
            filePreview: {
                ...state.filePreview,
                [props.fileIndex.fileIndex as string]: {
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(fileDownloadActions.getFilePreviewSuccess, (state, props): State => {
        return {
            ...state,
            filePreview: {
                ...state.filePreview,
                [props.fileIndex.fileIndex as string]: {
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: {
                        fileType: props.fileType,
                        fileUrl: props.fileUrl,
                        fileName: props.fileIndex.name,
                    } as FilePreview,
                },
            },
        };
    }),

    on(
        fileDownloadActions.getFilePreviewFailure,
        (state, props): State => ({
            ...state,
            filePreview: {
                ...state.filePreview,
                [props.fileIndex.fileIndex as string]: {
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(fileDownloadActions.destroyFilePreviewSuccess, (state, props): State => {
        return {
            ...state,
            filePreview: {
                ...state.filePreview,
                [props.fileIndex]: {
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: undefined,
                },
            },
        };
    })
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
