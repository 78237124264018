import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxStopPropagationDirective } from '@wdx/shared/components/wdx-stop-propagation';
import { WdxAccordionComponent } from './container/wdx-accordion.component';
import { WdxAccordionPanelComponent } from './panel/wdx-accordion-panel.component';

@NgModule({
    declarations: [WdxAccordionComponent, WdxAccordionPanelComponent],
    imports: [CommonModule, WdxStopPropagationDirective],
    exports: [WdxAccordionComponent, WdxAccordionPanelComponent],
})
export class WdxAccordionModule {}
