import {
    Directive,
    inject,
    Input,
    OnChanges,
    Type,
    ViewContainerRef,
} from '@angular/core';

@Directive({
    selector: '[wdxUtilsComponentRenderer]',
    standalone: true,
})
export class WdxComponentRendererDirective implements OnChanges {
    @Input() wdxUtilsComponentRenderer!:
        | {
              component: Type<any>;
              inputs?: Record<string, unknown>;
          }
        | undefined;

    private viewContainerRef = inject(ViewContainerRef);

    ngOnChanges(): void {
        if (this.wdxUtilsComponentRenderer) {
            const { component, inputs } = this.wdxUtilsComponentRenderer;
            this.viewContainerRef.clear();
            const componentRef =
                this.viewContainerRef.createComponent(component);
            if (inputs) {
                Object.entries(inputs).forEach(([key, value]) => {
                    componentRef.instance[key] = value;
                });
            }
        }
    }
}
