import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { FunctionPipe } from '@wdx/shared/utils';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomFeatureSvgModule } from '../../../components/atoms/atom-feature-svg/atom-feature-svg.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeActivityCardModule } from '../../../components/molecules/molecule-activity-card/molecule-activity-card.module';
import { MoleculeCaseCardModule } from '../../../components/molecules/molecule-case-card/molecule-case-card.module';
import { MoleculeDataListWrapperModule } from '../../../components/molecules/molecule-data-list-wrapper/molecule-data-list-wrapper.module';
import { MoleculeFilterBarModule } from '../../../components/molecules/molecule-filter-bar/molecule-filter-bar.module';
import { MoleculeLegendModule } from '../../../components/molecules/molecule-legend/molecule-legend.module';
import { MoleculeOpportunityCardModule } from '../../../components/molecules/molecule-opportunity-card/molecule-opportunity-card.module';
import { MoleculeSearchAndFilterBarModule } from '../../../components/molecules/molecule-search-and-filter-bar/molecule-search-and-filter-bar.module';
import { MoleculeTileCarouselModule } from '../../../components/molecules/molecule-tile-carousel/molecule-tile-carousel.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { FormFiltersModule } from '../../../shared/features/form-filters';
import { OrganismCardsListComponent } from './organism-cards-list.component';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomFeatureSvgModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        FormFiltersModule,
        MoleculeActivityCardModule,
        MoleculeCaseCardModule,
        MoleculeDataListWrapperModule,
        MoleculeFilterBarModule,
        MoleculeLegendModule,
        MoleculeOpportunityCardModule,
        MoleculeSearchAndFilterBarModule,
        MoleculeTileCarouselModule,
        NgbCarouselModule,
        OrganismModalModule,
        FunctionPipe,
    ],
    declarations: [OrganismCardsListComponent],
    exports: [OrganismCardsListComponent],
})
export class OrganismCardsListModule {}
