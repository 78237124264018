import { Component, Input } from '@angular/core';
import { POPOVER_OPEN_DELAY } from '../../../constants/popover.constants';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-dropdown-button',
    templateUrl: './atom-dropdown-button.component.html',
})
export class AtomDropdownButtonComponent {
    @Input() btnClass = 'btn-ghost';
    @Input() disabled: boolean;
    @Input() hideDropdownChevron: boolean;
    @Input() altText: string;
    @Input() cySelector: string;

    readonly POPOVER_OPEN_DELAY = POPOVER_OPEN_DELAY;
}
