<div class="input-wrapper">
    <div class="d-flex align-items-center form-label">
        <div
            *ngIf="legend || label"
            class="input-wrapper__label d-flex align-items-baseline"
        >
            <wdx-icon
                *ngIf="locked"
                data-cy="locked-icon"
                class="text-muted me-2"
                icon="lock"
                iconStyle="fas"
            ></wdx-icon>
            <i
                *ngIf="guidanceText"
                class="icon guidance-icon me-2 icon-shift-up fal fa-question-circle fa-1x text-muted"
                role="button"
                container="body"
                [ngbPopover]="popContent"
            ></i>
            <wdx-icon
                *ngIf="isSensitive"
                data-cy="locked-sensitive"
                class="text-muted me-2 pointer"
                icon="shield-check"
                iconStyle="fal"
                ngbPopover="This field is sensitive"
                container="body"
            ></wdx-icon>
            <legend *ngIf="legend" class="h6">
                <wdx-label
                    [label]="legend"
                    [required]="isRequired"
                    [labelForId]="labelForId || ''"
                ></wdx-label>
            </legend>
            <wdx-label
                *ngIf="label"
                [label]="label"
                [required]="isRequired"
                [labelForId]="labelForId || ''"
            ></wdx-label>
        </div>
        <div class="flex-grow-1 d-flex align-items-center justify-content-end">
            <ng-content select="[formFieldActionButton]"></ng-content>
        </div>
    </div>
    <div
        class="input-wrapper__content"
        [class.has-warning]="warningMessage"
        [class.is-invalid]="validationErrorMessage"
        [attr.data-cy]="'card-' + label"
    >
        <ng-content></ng-content>
        <div
            *ngIf="validationErrorMessage"
            class="input-wrapper__validation text-danger small mt-1"
            data-cy="validationErrorMessage"
        >
            {{ validationErrorMessage }}
        </div>

        <div
            *ngIf="!validationErrorMessage && warningMessage"
            class="input-wrapper__validation text-warning small mt-1"
            data-cy="warningMessage"
        >
            {{ warningMessage }}
        </div>
    </div>
</div>

<ng-template #popContent>
    <div [innerHtml]="guidanceText | markdownToHtml | safeHtml"></div>
</ng-template>
