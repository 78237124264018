import { createSelector } from '@ngrx/store';
import * as engagementBarReducer from './engagement-bar.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    engagementBar: engagementBarReducer.State;
}): engagementBarReducer.State => state.engagementBar;

/**
 * Reducer property selectors
 */
export const getIsVisible = createSelector(
    getState,
    (state) => state.isVisible
);
export const getActivityId = createSelector(
    getState,
    (state) => state.activityId
);
export const getParty = createSelector(getState, (state) => state.party);
export const getEngagementState = createSelector(
    getState,
    (state) => state.engagementState
);
