import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FeatureStatus } from '@wdx/clmi/api-models';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { RoleManagementService } from '../../services/role-management.service';
import * as rolesActions from './roles.actions';
import { RolesService } from './roles.service';

@Injectable()
export class RolesEffects {
    private actions$ = inject(Actions);
    private rolesService = inject(RolesService);

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rolesActions.getAll),
            mergeMap(() =>
                this.rolesService.getAll().pipe(
                    map((roles) => {
                        RoleManagementService.roles = roles;

                        return rolesActions.getAllSuccess({ roles });
                    }),
                    catchError((error) =>
                        of(rolesActions.getAllFailure({ error }))
                    )
                )
            )
        )
    );

    getByRoleType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rolesActions.getByRoleType),
            mergeMap((action) =>
                this.rolesService.getByRoleType(action.roleType).pipe(
                    map((role) =>
                        rolesActions.getByRoleTypeSuccess({
                            roleType: action.roleType,
                            role,
                        })
                    ),
                    catchError((error) =>
                        of(
                            rolesActions.getByRoleTypeFailure({
                                roleType: action.roleType,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rolesActions.getForClientProduct),
            switchMap((action) =>
                this.rolesService
                    .getForClientProduct(action.clientProductId)
                    .pipe(
                        map((roles) =>
                            rolesActions.getForClientProductSuccess({
                                roles,
                                clientProductId: action.clientProductId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                rolesActions.getForClientProductFailure({
                                    clientProductId: action.clientProductId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    enable$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rolesActions.enableRoleStatus),
            mergeMap((action) =>
                this.rolesService
                    .toogleRoleStatus(action.code, FeatureStatus.Enabled)
                    .pipe(
                        map(() =>
                            rolesActions.enableRoleStatusSuccess({
                                code: action.code,
                            })
                        ),
                        catchError((error) =>
                            of(
                                rolesActions.enableRoleStatusFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    avaible$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rolesActions.availableRoleStatus),
            mergeMap((action) =>
                this.rolesService
                    .toogleRoleStatus(action.code, FeatureStatus.Available)
                    .pipe(
                        map(() =>
                            rolesActions.availableRoleStatusSuccess({
                                code: action.code,
                            })
                        ),
                        catchError((error) =>
                            of(
                                rolesActions.availableRoleStatusFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
