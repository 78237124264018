import { Pipe, PipeTransform } from '@angular/core';
import { ArticleListItem } from '../features/articles/shared/models/article-list-item.model';
import { TextToNumberPipe } from '@wdx/shared/utils';

@Pipe({ name: 'articleImageUrl' })
export class ArticleImageUrlPipe implements PipeTransform {
    transform(article: ArticleListItem): string {
        if (!article) {
            return;
        }

        const availableImages = 27;

        return (
            article.imageUrl ||
            `assets/images/article/${new TextToNumberPipe().transform(
                article.name,
                availableImages
            )}.jpg`
        );
    }
}
