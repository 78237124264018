import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    Severity,
    TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY_ASSIGNED,
    TranslationsService,
} from '@wdx/shared/utils';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as assignActions from './assign.actions';
import { AssignService } from './assign.service';

@Injectable()
export class AssignEffects {
    private actions$ = inject(Actions);
    private assignService = inject(AssignService);
    private toastService = inject(WdxToastService);
    private translationsService = inject(TranslationsService);

    assignTo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(assignActions.assignTo),
            switchMap((action) =>
                this.assignService
                    .assignTo(
                        action.entityType,
                        action.entityId,
                        action.partyId
                    )
                    .pipe(
                        map(() => assignActions.assignToSuccess(action)),
                        catchError((error) =>
                            of(
                                assignActions.assignToFailure({
                                    entityId: action.entityId,
                                    partyId: action.partyId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    assignToSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(assignActions.assignToSuccess),
                tap((action) => {
                    this.toastService.show({
                        body: [
                            `${
                                action.entityType
                            } ${this.translationsService.getTranslationByKey(
                                TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY_ASSIGNED
                            )}`,
                        ],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );

    bulkAssignTo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(assignActions.bulkAssignTo),
            switchMap((action) =>
                this.assignService
                    .bulkAssignTo(
                        action.entityType,
                        action.entityIds,
                        action.partyId
                    )
                    .pipe(
                        map(() => assignActions.bulkAssignToSuccess(action)),
                        catchError((error) =>
                            of(
                                assignActions.bulkAssignToFailure({
                                    entityIds: action.entityIds,
                                    partyId: action.partyId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    bulkAssignToSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(assignActions.bulkAssignToSuccess),
                tap((action) => {
                    this.toastService.show({
                        body: [
                            `${action.entityIds.length} ${action.entityType}s successfully assigned`,
                        ],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );
}
