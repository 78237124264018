import { Injectable } from '@angular/core';
import {
    FormArray,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
} from '@angular/forms';
import { StringifyComparePipe, Trigger } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
    IFormControlData,
    IFormControlNameObject,
} from '../../models/reactive-dynamic.model';

@Injectable()
export class FormControlService {
    /**
     *  This has been created to split a string to find information like array name, index number and form control name
     *
     * @param {String} name This is the string that potential has the array name, index number and form control name
     * @return {IFormControlNameObject}
     */
    stringToFormControlObject(name: string): IFormControlNameObject {
        const NAME_ARR = name.split('.');
        const LEVEL_1 = NAME_ARR[0];
        const LEVEL_2 = NAME_ARR[1];

        const NAME_OBJECT: IFormControlNameObject = {
            Level1ControlName: LEVEL_1,
            Level1HasSubForm: false,
        };

        if (LEVEL_2) {
            NAME_OBJECT.Level2ControlName = LEVEL_2;
            NAME_OBJECT.Level1HasSubForm = true;
        }

        const regex = /\[\d+\]/gi;
        const ARR_INDEX_STR = LEVEL_1.match(regex);

        if (ARR_INDEX_STR) {
            NAME_OBJECT.Level1ControlName = LEVEL_1.replace(
                ARR_INDEX_STR[0],
                ''
            );
            NAME_OBJECT.Level1SubFormIndex = parseInt(
                ARR_INDEX_STR[0].replace('[', '').replace(']', ''),
                10
            );
        }

        return NAME_OBJECT;
    }

    /**
     *
     * @param {FormArray} formArray - The FormArray control from angular form controls
     * @param {number} formArrayIndex - This is the index that needs to be selected from the FormArray
     * @param {string} formControlName - This is the name of the control that needs to be returned
     * @returns {UntypedFormControl} - FormControl that is going to be returned
     */
    getFormArrayIndexFormControl(
        formArray: FormArray,
        formArrayIndex: number,
        formControlName: string
    ): UntypedFormControl {
        return (formArray.controls.at(formArrayIndex) as FormGroup).get(
            formControlName as string
        ) as UntypedFormControl;
    }

    /**
     *
     * @param {FormGroup} form This is the angular FormGroup
     * @param {string} name This is the name of the form control e.g 'firstName', 'arrayName.hair'
     * @param {number} indexes  This is the index of the items in the form array. This is optional
     *
     * @returns {IFormControlData[]} This returns an array of IFormControlData
     */
    getFormControlData(
        form: FormGroup,
        name: string,
        indexes?: number[]
    ): IFormControlData[] {
        const FORM_CONTROLS: {
            index?: number;
            formControl: UntypedFormControl;
        }[] = [];
        const TRIGGERS = this.stringToFormControlObject(name);
        const IS_ARRAY_TRIGGER = TRIGGERS.Level1HasSubForm;
        const FORM_CONTROLS_GET = form?.get(TRIGGERS.Level1ControlName);

        if (!FORM_CONTROLS_GET) {
            return [];
        }

        if (IS_ARRAY_TRIGGER) {
            const FORM_ARRAY = FORM_CONTROLS_GET as UntypedFormArray;

            const INDEXES = indexes?.length;

            const MAX = INDEXES ? INDEXES : FORM_ARRAY.controls?.length;
            let count = 0;

            while (count < MAX) {
                const INDEX = INDEXES ? indexes[count] : count;

                if (FORM_ARRAY.at(INDEX)) {
                    FORM_CONTROLS.push({
                        index: INDEX,
                        formControl: this.getFormArrayIndexFormControl(
                            FORM_ARRAY,
                            INDEX,
                            TRIGGERS.Level2ControlName as string
                        ),
                    });
                }

                count = count + 1;
            }
        }

        if (!IS_ARRAY_TRIGGER) {
            if (FORM_CONTROLS) {
                FORM_CONTROLS.push({
                    formControl: FORM_CONTROLS_GET as UntypedFormControl,
                });
            }
        }

        return FORM_CONTROLS;
    }

    /**
     *
     * @param {Trigger} trigger This is the trigger object from the form conditions
     * @param {IFormControlData} FORM_CONTROL_DATA This is an object of IFormControlData that as  form control witch will be watched for changes
     * @param {(trigger: Trigger, formControlData: IFormControlData)} fn This is a function that gets called when the form control emits a change. The 2 properties above are passed to the function
     * @param {Observable<boolean>} destroyed$ - This is observable to complete the subscription
     */
    setValueChangeListener(
        trigger: Trigger,
        FORM_CONTROL_DATA: IFormControlData,
        fn: (trigger: Trigger, formControlData: IFormControlData) => void,
        destroyed$: Observable<boolean>
    ): void {
        FORM_CONTROL_DATA.formControl.valueChanges
            .pipe(
                distinctUntilChanged((x, y) =>
                    new StringifyComparePipe().transform(x, y)
                ),
                takeUntil(destroyed$),
                debounceTime(0)
            )
            .subscribe((_) => {
                fn(trigger, FORM_CONTROL_DATA);
            });
    }
}
