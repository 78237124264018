import { Router } from '@angular/router';
import { SystemApiService } from '@wdx/clmi/api-services/services';
import { LocalStorageService } from '@wdx/shared/utils';
import { SystemSettingsService } from '../shared/services';
import { AuthenticationServiceE2e } from './authentication.e2e.service';
import { AuthenticationService } from './authentication.service';
import { ConfigService } from './config.service';

export function AuthenticationFactory(
    router: Router,
    configService: ConfigService,
    localStorageService: LocalStorageService,
    systemSettingsService: SystemSettingsService,
    systemApiService: SystemApiService
) {
    if (configService?.config.TestMode) {
        return new AuthenticationServiceE2e(localStorageService);
    }

    return new AuthenticationService(
        configService,
        router,
        localStorageService,
        systemSettingsService,
        systemApiService
    );
}
