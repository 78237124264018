import { Pipe, PipeTransform } from '@angular/core';
import { ClientLifecycleStage } from '@wdx/clmi/api-models';

@Pipe({ name: 'lifecycleStageToCSSClass' })
export class LifecycleStageToCSSClass implements PipeTransform {
    transform(lifecycleStage: ClientLifecycleStage, light?: boolean): string {
        if (!lifecycleStage) {
            return '';
        }

        return `lifecycle-stage-${lifecycleStage.toString().toLowerCase()}${
            light ? '-light' : ''
        }`;
    }
}
