import { Directive, Input } from '@angular/core';
import { FormInputData } from '@wdx/shared/infrastructure/form-framework';

@Directive()
export class BaseReactiveFormAdapterClass {
    @Input() isMulti: boolean;
    @Input() formInputData: FormInputData;
    @Input() validationErrorMessage: string;

    initialValue: any;
    onChange: (...args: any[]) => any = () => undefined;
    onTouched = () => undefined;

    set value(val: any) {
        this.initialValue = val;
        this.onChange(this.initialValue);
        this.onTouched();
    }

    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    onValueChanged(value: any): void {
        this.value = value || null;
    }

    onRemove(item: any): void {
        this.initialValue = this.initialValue.filter(
            (cacheValue) => cacheValue !== item
        );

        this.onChange(this.initialValue);
    }
}
