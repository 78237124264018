<clmi-message></clmi-message>

<ng-container *ngIf="isAppInitialised">
    <wdx-toast
        class="d-block toast-container z-index-999"
        aria-live="polite"
        aria-atomic="true"
    ></wdx-toast>

    <organism-engagement-bar *ngIf="isAuthenticated"></organism-engagement-bar>

    <organism-dynamic-form-modal
        modalId="dynamic-form"
    ></organism-dynamic-form-modal>

    <organism-info-card-check-duplications-modal></organism-info-card-check-duplications-modal>

    <clmi-access-visibility #accessVisibility></clmi-access-visibility>

    <clmi-comments-modal #comments></clmi-comments-modal>

    <router-outlet></router-outlet>
</ng-container>

<molecule-loader-overlay *ngIf="!isAppInitialised"></molecule-loader-overlay>
