import { Component, Input } from '@angular/core';

@Component({
    selector: 'wdx-form-field',
    templateUrl: './wdx-form-field.component.html',
})
export class WdxFormFieldComponent {
    @Input() locked?: boolean;
    @Input() label? = '';
    @Input() labelForId? = '';
    @Input() legend? = '';
    @Input() guidanceText? = '';
    @Input() isRequired?: boolean;
    @Input() isSensitive?: boolean;
    @Input() validationErrorMessage: null | undefined | string = '';
    @Input() warningMessage: null | undefined | string = '';
}
