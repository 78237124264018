import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AuthenticationService } from './services/authentication.service';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { BaseComponentClass } from './classes/base-component.class';
import { AccessVisibilityComponent } from './shared/features/access-visibility/access-visibility.component';
import { AccessVisibilityService } from './shared/features/access-visibility/shared/services/access-visibility.service';
import { CommentsService } from './shared/features/comments';
import * as globalActions from './state/global/global.actions';
import * as rootReducer from './state/_setup/reducers';
import { CommentsModalComponent } from './shared/features/comments/comments-modal/comments-modal.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent extends BaseComponentClass implements OnInit {
    isAppInitialised = false;
    isAuthenticated = false;

    @ViewChild('accessVisibility', { read: AccessVisibilityComponent })
    set accessVisibility(component: AccessVisibilityComponent) {
        this._accessVisibility = component;
        this.accessVisibilityService.setComponent(component);
    }
    get accessVisibility(): AccessVisibilityComponent {
        return this._accessVisibility;
    }
    _accessVisibility: AccessVisibilityComponent;

    @ViewChild('comments', { read: CommentsModalComponent })
    set commentsModal(component: CommentsModalComponent) {
        this._commentsModal = component;
        this.commentsService.setComponent(component);
    }
    get commentsModal(): CommentsModalComponent {
        return this._commentsModal;
    }
    _commentsModal: CommentsModalComponent;

    constructor(
        actions$: Actions,
        store$: Store<rootReducer.State>,

        private accessVisibilityService: AccessVisibilityService,
        private commentsService: CommentsService,
        private readonly authService: AuthService,
        private readonly authenticationService: AuthenticationService
    ) {
        super();

        this.authenticationService.auth0Service = this.authService;

        this.patchInjectedItems({
            actions$,
            store$,
        });
    }

    ngOnInit() {
        this.authenticationService.auth0Service.isAuthenticated$.subscribe(
            (isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
            }
        );

        this.listenTo(globalActions.appInitialised).subscribe(() => {
            this.isAppInitialised = true;
        });
    }

    isAppInitialising(isAppInitialised: boolean): boolean {
        return !isAppInitialised;
    }
}
