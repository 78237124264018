import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
    MEDIA_BREAKPOINT_LG,
    MEDIA_BREAKPOINT_SM,
} from '../../../../constants/breakpoints.constants';

import { Article } from '@wdx/clmi/api-models';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-article-preview-dumb',
    templateUrl: './molecule-article-preview-dumb.component.html',
})
export class MoleculeArticlePreviewDumbComponent implements OnInit {
    @Input() content: string;
    @Input() article: Article;

    maxContentCharacters: number;
    contentCharactersShort = 280;
    contentCharactersMed = 350;
    contentCharactersLong = 500;

    @HostListener('window:resize') onResize() {
        this.updateContentCharacters();
    }

    ngOnInit() {
        this.updateContentCharacters();
    }

    updateContentCharacters() {
        if (window.innerWidth >= MEDIA_BREAKPOINT_LG) {
            this.maxContentCharacters = this.contentCharactersLong;
        } else if (window.innerWidth >= MEDIA_BREAKPOINT_SM) {
            this.maxContentCharacters = this.contentCharactersMed;
        } else {
            this.maxContentCharacters = this.contentCharactersShort;
        }
    }
}
