import { createAction, props } from '@ngrx/store';
import {
    Message,
    MessageDetails,
} from '../../shared/features/message/models/message.model';

export const addErrorMessage = createAction(
    '[Message] Add Error message',
    props<{
        message: MessageDetails;
    }>()
);

export const addWarningMessage = createAction(
    '[Message] Add Warning message',
    props<{
        message: MessageDetails;
    }>()
);

export const addInfoMessage = createAction(
    '[Message] Add Info message',
    props<{
        message: MessageDetails;
    }>()
);

export const addSuccessMessage = createAction(
    '[Message] Add Success message',
    props<{
        message: MessageDetails;
    }>()
);

export const removeMessage = createAction(
    '[Message] Remove message',
    props<{
        message: Message;
    }>()
);

export const removeAllMessages = createAction('[Message] Remove All messages');
