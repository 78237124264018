import { Action } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Severity } from '../constants';
import { ActionButtonMode } from './action-button';
import { FormSetup } from './form-setup.model';
import { Icon } from './icon.model';
import { Privilege } from './privilege.model';
import { SecurityFlag } from './security-flag.model';
import { EntityPermissionType, FeatureFlagValue } from './shared-api-models';

export interface NavItem<T = any> {
    id?: string;
    label?: string;
    subLabels?: string[];
    value?: T;
    selected?: boolean;
    icon?: Icon | string;
    iconSeverity?: Severity;
    severityIndicators?: Severity[];
    routerLink?: string[];
    queryParams?: { [k: string]: any };
    subMenu?: NavItem[];
    linkedMenu?: NavItem[];
    linkedMenu$?: BehaviorSubject<NavItem[]>;
    backButton?: boolean;
    callback?: any;
    data?: any;
    formSetup?: FormSetup;
    modalId?: string;
    disabled?: boolean;
    isInitialValue?: boolean;
    action?: Action;
    permission?: EntityPermissionType;
    notPermission?: EntityPermissionType;
    privilege?: Privilege;
    feature?: FeatureFlagValue;
    mode?: ActionButtonMode;
    cySelector?: string;
    cyValue?: string;
    externalUrl?: string;
    disabledBy?: SecurityFlag[];
    translationKey?: string;
    hidden?: boolean;
}

export enum MenuItemAction {
    AddToEnvelope,
    AddToList,
    Approve,
    AssignTo,
    AssignToMe,
    Cancel,
    Chat,
    Complete,
    CreateCase,
    CreateList,
    DelegateAccess,
    Delete,
    Deactivate,
    Edit,
    ExportDetail,
    ExportSummary,
    File,
    GenerateDocument,
    InactivateRecord,
    Meeting,
    MoveToStage,
    OpenInNewTab,
    Outcome,
    PhoneCall,
    Preview,
    Print,
    ReactivateRecord,
    Reject,
    RemoveFromList,
    Save,
    SaveAs,
    SeeMore,
    Select,
    SendInvite,
    UnLink,
    ViewContact,
    ViewInKanban,
    Visibility,
    MergePerson,
    MergeOrganisation,
}
