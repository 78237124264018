import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { AtomTabComponent } from './atom-tab.component';

@NgModule({
    imports: [CommonModule, RouterModule, WdxIconModule],
    declarations: [AtomTabComponent],
    exports: [AtomTabComponent],
})
export class AtomTabModule {}
