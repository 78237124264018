import { Pipe, PipeTransform } from '@angular/core';
import {
    EnumTranslationTypes,
    TranslationsService,
} from '../../services/translations';

@Pipe({
    name: 'translateEnum',
    standalone: true,
})
export class TranslateEnumPipe implements PipeTransform {
    constructor(private translationsService: TranslationsService) {}

    transform(value: string, type: EnumTranslationTypes): string {
        if (!value) {
            return '';
        }

        return this.translationsService.getTranslatedEnumType(value, type);
    }
}
