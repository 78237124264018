<div
    class="d-flex align-items-center"
    [attr.data-cy]="'subform-' + sectionLayoutDefinitionName"
>
    <ng-container
        *ngIf="!CUSTOM_SUMMARY_TYPES.includes($any(titleField?.fieldType))"
    >
        <div
            *ngIf="
                titleValue &&
                    titleField?.selectSource === SELECT_API_SOURCE.Country;
                else defaultIcon
            "
            class="icon-container d-flex justify-content-center align-items-center rounded-circle bg-icon icon-container-size-md"
        >
            <span class="flag flag-{{ titleValue | lowercase }}"></span>
        </div>
    </ng-container>
    <div #avatarTarget></div>
    <ng-template wdxFFSubformControlHost></ng-template>
    <ng-container *ngIf="avatarDefaultIcon">
        <ng-container *ngTemplateOutlet="defaultIcon"></ng-container>
    </ng-container>

    <div class="d-block position-relative flex-grow-1 px-2">
        <h6 data-cy="subform-info-title" [innerHtml]="title$ | ngrxPush"></h6>
        <div class="d-flex align-items-center">
            <ng-container *ngIf="subtitles$ | ngrxPush; let subtitles">
                <div
                    *ngFor="let subtitle of subtitles; let last = last"
                    class="small divider--short"
                    [class.divider-end]="!last"
                >
                    {{ subtitle }}
                </div>
            </ng-container>
            <div *ngIf="isPrimaryField" class="ms-1">
                <wdx-badge class="small text-truncate" themeColor="primary">
                    Primary
                </wdx-badge>
            </div>
        </div>
    </div>
</div>

<ng-template #defaultIcon>
    <wdx-icon
        class="bg-icon icon-container d-flex justify-content-center align-items-center rounded-circle bg-icon icon-container-size-md"
        icon="arrow-alt-right"
    >
    </wdx-icon>
</ng-template>
