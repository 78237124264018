import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
    forwardRef,
    inject,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
} from '@angular/forms';
import {
    WdxButtonModule,
    WdxButtonSize,
    WdxButtonStyle,
} from '@wdx/shared/components/wdx-button';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { Subject } from 'rxjs/internal/Subject';

// https://github.com/josdejong/svelte-jsoneditor
import {
    Content,
    JSONContent,
    JSONEditor,
    Mode,
    TextContent,
    createAjvValidator,
} from 'vanilla-jsoneditor';

/**
 * For more details on the json editor visit
 * https://github.com/josdejong/svelte-jsoneditor
 */
@Component({
    selector: 'wdx-json-editor',
    templateUrl: './wdx-json-editor.component.html',
    styleUrls: ['./wdx-json-editor.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        WdxButtonModule,
        WdxIconModule,
        WdxIconContainerModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref no-use-before-declare
            useExisting: forwardRef(() => WdxJsonViewerComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => WdxJsonViewerComponent),
            multi: true,
        },
    ],
})
export class WdxJsonViewerComponent
    implements AfterViewInit, ControlValueAccessor, Validator
{
    val = null;
    touched = false;
    disabled = false;
    errors: any;
    WDX_BUTTON_SIZE = WdxButtonSize;
    WDX_BUTTON_STYLE = WdxButtonStyle;
    @Input() schema: { [key: string | number]: any } | undefined;
    @Input() customSerialiser?: (value?: TextContent) => any;
    @Input() customDeSerialiser?: (
        value?: JSONContent,
    ) => JSONContent | undefined;

    isFullscreen$ = new Subject<boolean>();
    private changeDetectorRef = inject(ChangeDetectorRef);

    @ViewChild('jsonEditorContainer', { static: true })
    jsonEditorContainer!: ElementRef;
    @ViewChild('jsonEditorWrapper', { static: true })
    jsonEditorWrapper!: ElementRef;
    editor!: JSONEditor;

    private data: JSONContent | undefined = undefined;

    ngAfterViewInit(): void {
        let validator;
        if (this.schema) {
            validator = createAjvValidator({
                schema: this.schema,
            });
        }

        this.editor = new JSONEditor({
            target: this.jsonEditorContainer.nativeElement,
            props: {
                content: this.customDeSerialiser
                    ? this.customDeSerialiser(this.data)
                    : this.data,
                mainMenuBar: true,
                navigationBar: true,
                mode: Mode.text,
                onChange: (updatedContent, _, { contentErrors }) => {
                    this.errors = contentErrors;

                    this.onInput(updatedContent);

                    this.changeDetectorRef.detectChanges;
                },
                validator,
            },
        });
    }

    onChange: (...args: any[]) => any = () => undefined;
    onTouched: (...args: any[]) => any = () => undefined;

    registerOnChange(onChange: (...args: any[]) => any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: (...args: any[]) => any): void {
        this.onTouched = onTouched;
    }

    onInput(value: any): void {
        if (this.customSerialiser) {
            this.onChange(this.customSerialiser(value));
            return;
        }
        this.val = value && (value.text ? JSON.parse(value.text) : value.json);
        this.onChange(this.val);
    }

    writeValue(value: any): void {
        this.val = value;
        this.data = value
            ? {
                  json: value,
                  text: undefined,
              }
            : undefined;
        const json = this.customDeSerialiser
            ? (this.customDeSerialiser(this.data) as JSONContent)
            : (this.data as Content);
        this.editor?.update({ json });
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    validate(): { [key: string]: any } | null {
        if (this.errors) {
            return { invalidJson: true };
        }

        return null;
    }

    fullscreen(): void {
        if (!document.fullscreenElement) {
            this.jsonEditorWrapper.nativeElement
                .requestFullscreen()
                .then(() => {
                    this.isFullscreen$.next(true);
                })
                .catch((err: any) => {
                    alert(
                        `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
                    );
                });
        } else {
            this.isFullscreen$.next(false);
            document.exitFullscreen();
        }
    }
}
