<widget-opportunities-list
    [widgetTitle]="'ENTITY_OPPORTUNITY_PLURAL' | translate"
    data-cy="widget-opportunities-list"
    [opportunitiesPerRow]="cardsPerRow"
    [opportunities]="relatedOpportunities$ | ngrxPush"
    [totalRecordCount]="(relatedOpportunities$ | ngrxPush)?.length"
    [isLoading]="relatedOpportunitiesIsLoading$ | ngrxPush"
    [hasError]="relatedOpportunitiesHasError$ | ngrxPush"
    [spokeButton]="{
        label: ('SEE_ALL_OPPORTUNITIES_BTN' | translate)
    }"
    [showOpportunitiesListActionsButtons]="true"
    [showDeleteLinkMenuItem]="true"
    [scrollMode]="SCROLL_MODE.Responsive"
    [linkToEntityType]="entityType"
    [linkToEntityId]="entityId"
>
    <div class="opportunities-list-action-buttons d-flex align-items-center">
        <molecule-action-button
            data-cy="btn-add-opportunity"
            [actionButton]="createOpportunityActionButton"
        ></molecule-action-button>
    </div>
</widget-opportunities-list>

<molecule-accordion
    name="suggestedOpportunityAccordion"
    [panels]="suggestedOpportunitiesAccordionPanels$ | ngrxPush"
    [flush]="true"
></molecule-accordion>

<ng-template #suggestedOpportunitiesContentTemplate>
    <div class="accordion-content">
        <organism-opportunities-list
            noDataSVGSize="xs"
            [noDataMessage]="NO_DATA_MESSAGE | translate"
            [opportunities]="suggestedOpportunities$ | ngrxPush"
            [opportunitiesPerRow]="cardsPerRow"
            [totalRecordCount]="(suggestedOpportunities$ | ngrxPush)?.length"
            [isLoading]="suggestedOpportunitiesIsLoading$ | ngrxPush"
            [showFilterBar]="false"
            [showSearchBar]="false"
            [showFavourite]="false"
            [isSuggestedCardList]="true"
            [scrollMode]="SCROLL_MODE.Responsive"
            [linkToEntityType]="entityType"
            [linkToEntityId]="entityId"
        ></organism-opportunities-list>
    </div>
</ng-template>
