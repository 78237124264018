import { Action, createReducer, on } from '@ngrx/store';
import { Feed } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as feedsActions from './feeds.actions';

export interface State {
    feeds?: CrudStateObject<Feed>;
    clientFeeds?: CrudStateObject<Feed>;
}

export const initialState: State = {
    feeds: {},
    clientFeeds: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        feedsActions.getForClientProduct,
        (state, props): State => ({
            ...state,
            feeds: {
                ...state.feeds,
                [props.clientProductId]: {
                    ...(state.feeds[props.clientProductId] ||
                        ({} as CrudState<Feed>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        feedsActions.getForClientProductSuccess,
        (state, props): State => ({
            ...state,
            feeds: {
                ...state.feeds,
                [props.clientProductId]: {
                    ...state.feeds[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.feeds,
                },
            },
        })
    ),

    on(
        feedsActions.getForClientProductFailure,
        (state, props): State => ({
            ...state,
            feeds: {
                ...state.feeds,
                [props.clientProductId]: {
                    ...state.feeds[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        feedsActions.getFeedsForClient,
        (state, props): State => ({
            ...state,
            clientFeeds: {
                ...state.clientFeeds,
                [props.clientId]: {
                    ...(state.clientFeeds[props.clientId] ||
                        ({} as CrudState<Feed>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        feedsActions.getFeedsForClientSuccess,
        (state, props): State => ({
            ...state,
            clientFeeds: {
                ...state.clientFeeds,
                [props.clientId]: {
                    ...state.clientFeeds[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.clientFeeds,
                },
            },
        })
    ),

    on(
        feedsActions.getFeedsForClientFailure,
        (state, props): State => ({
            ...state,
            clientFeeds: {
                ...state.clientFeeds,
                [props.clientId]: {
                    ...state.clientFeeds[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
