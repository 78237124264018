import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeArticlePreviewComponent } from './molecule-article-preview/molecule-article-preview.component';
import { MoleculeArticlePreviewDumbComponent } from './molecule-article-preview-dumb/molecule-article-preview-dumb.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
    imports: [CommonModule, PipesModule],
    declarations: [
        MoleculeArticlePreviewComponent,
        MoleculeArticlePreviewDumbComponent,
    ],
    exports: [
        MoleculeArticlePreviewComponent,
        MoleculeArticlePreviewDumbComponent,
    ],
})
export class MoleculeArticlePreviewModule {}
