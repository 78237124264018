import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Message,
    MessageActions,
} from '../../../shared/features/message/models/message.model';
import { MessageFacade } from '../../../shared/features/message/shared/services/message.facade';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
    selector: 'clmi-message',
    templateUrl: './message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnInit {
    messages$: Observable<Message[]>;

    readonly messageActions = MessageActions;

    constructor(
        private messageFacade: MessageFacade,
        readonly authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.messages$ = this.messageFacade.messages$;
    }

    close(message: Message): void {
        this.messageFacade.removeMessage(message);
    }

    action(messageActions: MessageActions): void {
        this.getAction(messageActions);
    }

    getAction(type: MessageActions): void {
        const actions = {
            Logout: () => {
                this.messageFacade.removeAllMessages();
                this.authenticationService.logout();

                return;
            },
            Default: () => {
                return;
            },
        };
        return (actions[type] || actions['default'])();
    }
}
