import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { DirectivesModule } from '../../../directives/directives.module';
import { AtomIconButtonComponent } from './atom-icon-button.component';

@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        RouterModule,
        DirectivesModule,
        WdxIconModule,
        WdxIconContainerModule,
    ],
    declarations: [AtomIconButtonComponent],
    exports: [AtomIconButtonComponent],
})
export class AtomIconButtonModule {}
