import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeInfoCardModule } from '../../../components/molecules/molecule-info-card/molecule-info-card.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { OrganismTableModule } from '../../../components/organisms/organism-table/organism-table.module';
import { ClientSideSearchModule } from '../../../shared/components/client-side-search';
import { AccessVisibilityComponent } from './access-visibility.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../../pipes/pipes.module';
import { InterpolationDisplayModule } from '../../../shared/components/interpolation-display';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ClientSideSearchModule,
        MoleculeInfoCardModule,
        AtomModalWrapperModule,
        OrganismModalModule,
        OrganismTableModule,
        PipesModule,
        InterpolationDisplayModule,
    ],
    declarations: [AccessVisibilityComponent],
    exports: [AccessVisibilityComponent],
})
export class AccessVisibilityModule {}
