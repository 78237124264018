import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'wdx-label',
    templateUrl: './wdx-label.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxLabelComponent {
    @Input() label = '';
    @Input() labelForId = '';
    @Input() required?: boolean;
    @Input() status?: 'muted';
}
