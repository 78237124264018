import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'thousandsSeparators' })
export class ThousandsSeparatorsPipe implements PipeTransform {
    transform(value: string | number): string {
        if (!value && value !== 0) {
            return '';
        }

        const numbers = value.toString().split('.', 2)[0].split('').reverse();
        const decimals = value.toString().split('.', 2)[1];

        const withCommas = numbers
            .map((num, index) => {
                return `${num}${index % 3 === 0 && index !== 0 ? ',' : ''}`;
            })
            .reverse()
            .join('');

        return `${withCommas}${decimals ? '.' + decimals : ''}`;
    }
}
