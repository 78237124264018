export enum SystemUrlSegment {
    System = 'system',
    AppLanguage = 'appLanguage',
    Feature = 'feature',
    Menu = 'menu',
    Setting = 'setting',
    Theme = 'theme',
    TokeniseUrl = 'tokeniseurl',
    Translations = 'translations',
    Version = 'version',
    Sitemap = 'sitemap',
}
