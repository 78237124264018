import { Injectable } from '@angular/core';
import * as answersActions from '../../../state/answers/answers.actions';
import * as answersSelectors from '../../../state/answers/answers.selectors';
import * as rootReducer from '../../../state/_setup/reducers';
import { select, Store } from '@ngrx/store';
import { Answer } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class AnswersFacadeService {
    constructor(private store$: Store<rootReducer.State>) {}

    getAnswer$(code): Observable<Answer[]> {
        return this.store$.pipe(
            select(answersSelectors.getAnswers, {
                code,
            }),
            tap((answer) => {
                if (!answer) {
                    this.store$.dispatch(answersActions.getAnswers({ code }));
                }
            }),
            filter((answer) => !!answer)
        );
    }
}
