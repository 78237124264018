import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxStopPropagationDirective } from '@wdx/shared/components/wdx-stop-propagation';
import { AtomAvatarModule } from '../../atoms/atom-avatar/atom-avatar.module';
import { MoleculeAvatarInfoModule } from '../molecule-avatar-info/molecule-avatar-info.module';
import { MoleculeAvatarComponent } from './molecule-avatar.component';

@NgModule({
    imports: [
        AtomAvatarModule,
        CommonModule,
        MoleculeAvatarInfoModule,
        NgbPopoverModule,
        WdxStopPropagationDirective,
    ],
    declarations: [MoleculeAvatarComponent],
    exports: [MoleculeAvatarComponent],
})
export class MoleculeAvatarModule {}
