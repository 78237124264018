import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeInfoCardCheckDuplicationsModule } from '../../../components/molecules/molecule-info-card-check-duplications/molecule-info-card-check-duplications.module';
import { MoleculeInfoCardModule } from '../../../components/molecules/molecule-info-card/molecule-info-card.module';
import { MoleculeInputSearchModule } from '../../../components/molecules/molecule-input-search/molecule-input-search.module';
import { MoleculeInputWrapperModule } from '../../../components/molecules/molecule-input-wrapper/molecule-input-wrapper.module';
import { OrganismExtendedInfoCardListModule } from '../../../components/organisms/organism-extended-info-card-list/organism-extended-info-card-list.module';
import { OrganismInfoCardCheckDuplicationsModalComponent } from '../../../components/organisms/organism-info-card-check-duplications-modal/organism-info-card-check-duplications-modal.component';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomIconButtonModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        MoleculeActionButtonModule,
        MoleculeInfoCardCheckDuplicationsModule,
        MoleculeInfoCardModule,
        MoleculeInputSearchModule,
        MoleculeInputWrapperModule,
        OrganismExtendedInfoCardListModule,
        OrganismModalModule,
        PipesModule,
    ],
    declarations: [OrganismInfoCardCheckDuplicationsModalComponent],
    exports: [OrganismInfoCardCheckDuplicationsModalComponent],
})
export class OrganismInfoCardCheckDuplicationsModalModule {}
