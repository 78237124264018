import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkItem, SystemEntity } from '@wdx/clmi/api-models';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class WorkItemsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getWorkItemsForSystemEntity(
        systemEntity: SystemEntity,
        systemEntityId: string
    ): Observable<WorkItem[]> {
        return this.http.get<WorkItem[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${systemEntity.toLowerCase()}/${systemEntityId}/workitem`
        );
    }

    getWorkItem(workItemId: string): Observable<WorkItem> {
        return this.http.get<WorkItem>(
            `${this.config.getConfiguration().API_BASE}/workitem/${workItemId}`
        );
    }

    setWorkItemDueDate(workItemId: string, date: Date): Observable<WorkItem> {
        return this.http.patch<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${workItemId}/duedate`,
            {
                DueDate: date.toISOString(),
            }
        );
    }
}
