import { createAction, props } from '@ngrx/store';
import { GlobalSearchResult } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import {
    EntityFiltersResult,
    GlobalSearchRequest,
} from '../../models/global-search.model';
import { PaginationOptions } from '../../models/pagination-options';

export const getPage = createAction(
    '[Global Search] Get Page',
    props<{
        searchRequest?: GlobalSearchRequest;
        paginationOptions?: PaginationOptions;
    }>()
);

export const getPageSuccess = createAction(
    '[Global Search] Get Page Success',
    props<{
        searchResults: PaginatedApiResponse<GlobalSearchResult>;
        pageNumber: number;
    }>()
);

export const getPageFailure = createAction(
    '[Global Search] Get Page Failure',
    props<{
        error: Error;
    }>()
);

export const getEntityFilters = createAction(
    '[Global Search] Get Entity Filters'
);

export const getEntityFiltersSuccess = createAction(
    '[Global Search] Get Entity Filters Success',
    props<{
        entityFilters: EntityFiltersResult[];
    }>()
);

export const getEntityFiltersFailure = createAction(
    '[Global Search] Get Entity Filters Failure',
    props<{
        error: Error;
    }>()
);

export const resetResults = createAction('[Global Search] Reset Results');
