import { createAction, props } from '@ngrx/store';

import {
    ISystemEntityPermissions,
    ISystemEntityPermissionsError,
    ISystemEntityPermissionsList,
} from './system-entity.interface';

export const systemEntityActionPermission = createAction(
    '[System Entity] Permission',
    props<ISystemEntityPermissions>()
);

export const systemEntityActionPermissionSuccess = createAction(
    '[System Entity] Permission Success',
    props<ISystemEntityPermissionsList>()
);

export const systemEntityActionPermissionFailure = createAction(
    '[System Entity] Permission Failure',
    props<ISystemEntityPermissionsError>()
);
