import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PAGE_SIZE } from '@wdx/shared/utils';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from '../../state/_setup/reducers';
import * as duplicatesActions from './duplicates.actions';
import * as duplicatesSelectors from './duplicates.selectors';
import { DuplicatesService } from './duplicates.service';

@Injectable()
export class DuplicatesEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private duplicatesService = inject(DuplicatesService);

    getDuplicates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(duplicatesActions.getDuplicates),
            withLatestFrom(
                this.store$.select(
                    duplicatesSelectors.getDuplicatesInfinityPaging,
                    { id: GLOBAL_STATE_INDEX_ID }
                )
            ),
            mergeMap(([action, paging]) =>
                this.duplicatesService
                    .getDuplicates(action.entity, {
                        pageNumber: action.reset ? 1 : (paging?.page || 0) + 1,
                        pageSize: PAGE_SIZE,
                    })
                    .pipe(
                        map((duplicates) =>
                            duplicatesActions.getDuplicatesSuccess({
                                duplicates,
                                entity: action.entity,
                            })
                        ),
                        catchError((error) =>
                            of(
                                duplicatesActions.getDuplicatesFailure({
                                    error,
                                    entity: action.entity,
                                })
                            )
                        )
                    )
            )
        )
    );

    runDuplicateCheck$ = createEffect(() =>
        this.actions$.pipe(
            ofType(duplicatesActions.runDuplicateCheck),
            switchMap((action) =>
                this.duplicatesService.runDuplicateCheck(action.entity).pipe(
                    map(() =>
                        duplicatesActions.runDuplicateCheckRunning({
                            entity: action.entity,
                        })
                    ),
                    catchError((error) =>
                        of(
                            duplicatesActions.runDuplicateCheckFailure({
                                entity: action.entity,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    markNotDuplicates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(duplicatesActions.markNotDuplicates),
            switchMap((action) =>
                this.duplicatesService
                    .markNotDuplicates(action.duplicateId)
                    .pipe(
                        map(() =>
                            duplicatesActions.markNotDuplicatesSuccess({
                                duplicateId: action.duplicateId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                duplicatesActions.markNotDuplicatesFailure({
                                    error,
                                    duplicateId: action.duplicateId,
                                })
                            )
                        )
                    )
            )
        )
    );

    mergeDuplicates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(duplicatesActions.mergeDuplicates),
            switchMap((action) =>
                this.duplicatesService
                    .mergeDuplicates(action.duplicateId, action.targetId)
                    .pipe(
                        map(() =>
                            duplicatesActions.mergeDuplicatesSuccess({
                                duplicateId: action.duplicateId,
                                targetId: action.targetId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                duplicatesActions.mergeDuplicatesFailure({
                                    error,
                                    duplicateId: action.duplicateId,
                                    targetId: action.targetId,
                                })
                            )
                        )
                    )
            )
        )
    );
}
