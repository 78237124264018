<div class="d-flex">
    <input
        class="ml-3"
        wdxInputText
        type="number"
        step="any"
        min="0"
        [attr.data-cy]="cySelector"
        [value]="millions"
        (change)="displayValueChanged($event)"
    />
    <div class="p-2">Million</div>
</div>
