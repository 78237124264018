import showdown from 'showdown';

export const TARGET_LINK = 'targetlink';
export const MARKDOWN_TRANSFORM_PATTERN = [
    {
        type: 'lang',
        // eslint-disable-next-line no-useless-escape
        regex: /\[((?:\[[^\]]*]|[^\[\]])*)]\([ \t]*<?(.*?(?:\(.*?\).*?)?)>?[ \t]*((['"])(.*?)\4[ \t]*)?\)\{\:target=(["'])(.*)\6}/g,
        replace: (
            wholematch: any,
            linkText: any,
            url: any,
            a: any,
            b: any,
            title: any,
            c: any,
            target: any
        ) => {
            let result = '<a href="' + url + '"';

            if (typeof title != 'undefined' && title !== '' && title !== null) {
                title = title.replace(/"/g, '&quot;');
                title = showdown.helper['escapeCharacters'](title, '*_', false);
                result += ' title="' + title + '"';
            }

            if (
                typeof target != 'undefined' &&
                target !== '' &&
                target !== null
            ) {
                result += ' target="' + target + '"';
            }

            result += '>' + linkText + '</a>';
            return result;
        },
    },
];
