import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WdxAvatarContainerComponent } from './wdx-avatar-container/wdx-avatar-container.component';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxAvatarComponent } from './wdx-avatar/wdx-avatar.component';
import { TextToHslPipe, TitleCasePipe } from '@wdx/shared/utils';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarPopupContentComponent } from './wdx-avatar-popup-content/wdx-avatar-popup-content.component';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';

@NgModule({
    declarations: [
        WdxAvatarContainerComponent,
        WdxAvatarComponent,
        AvatarPopupContentComponent,
    ],
    imports: [
        CommonModule,
        NgbPopoverModule,
        WdxIconModule,
        WdxIconContainerModule,
        WdxIconButtonModule,
        TextToHslPipe,
        WdxBadgeModule,
        TitleCasePipe,
    ],
    exports: [
        WdxAvatarContainerComponent,
        WdxAvatarComponent,
        AvatarPopupContentComponent,
    ],
})
export class WdxAvatarModule {}
