import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    ICON_ADD,
    ICON_LIKE_BASE,
    ICON_LINK,
    ICON_UNLINK,
} from '../../../../constants/icons.constants';

import { FileUploadPackage } from '@wdx/shared/infrastructure/file-io';
import { NavItem } from '@wdx/shared/utils';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { DropdownButtonStyle } from '../../../../models/dropdown-button-style.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-action-button-dumb',
    templateUrl: './molecule-action-button-dumb.component.html',
})
export class MoleculeActionButtonDumbComponent {
    @Input() actionButton: ActionButton;
    @Input() btnClass: string;
    @Input() size: string;
    @Input() parentContainer: string;
    @Input() hideDropdownChevron: boolean;
    @Input() dropdownButtonStyle: DropdownButtonStyle =
        DropdownButtonStyle.Label;
    @Input() dropdownPlacement: string[];
    @Input() dropdownMenuSize: string;
    @Input() isDisabled: boolean;
    @Input() isLoading: boolean;
    @Input() isVisible: boolean;
    @Input() dropdownTemplateId: string;
    @Input() dropdownMenu: NavItem[];
    @Input() dropdownAutoClose: boolean | 'outside' | 'inside';
    @Input() resetButtonsFlag: boolean;
    @Input() highlightSelectedMenuItem: boolean;

    @Output() selectMenuItem = new EventEmitter<NavItem>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClick = new EventEmitter<boolean>();
    @Output() fileSelected = new EventEmitter<FileUploadPackage>();
    @Output() toggled = new EventEmitter<boolean>();

    readonly ICON_LINK = ICON_LINK.icon;
    readonly ICON_UNLINK = ICON_UNLINK.icon;
    readonly ICON_LIKE = ICON_LIKE_BASE.icon;
    readonly ICON_ADD = ICON_ADD.icon;

    readonly ACTION_BUTTONS_MODE = ActionButtonMode;
    readonly DEFAULT_DROPDOWN_BUTTON_STYLE = DropdownButtonStyle.Label;

    menuItemSelected(navItem: NavItem) {
        this.selectMenuItem.emit(navItem);
    }

    onFileSelected(uploadEvent: any): void {
        this.fileSelected.emit(uploadEvent);
    }

    onDropdownToggled(isOpen: boolean) {
        this.toggled.emit(isOpen);
    }

    onActionButtonClicked(toggle?: boolean) {
        this.onClick.emit(toggle);
    }
}
