import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    Renderer2,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Directive()
export abstract class WdxBaseInputLabelIdClass implements AfterViewInit {
    @Input() set labelForId(val: string) {
        this._label = val;
    }
    _value?: string;
    _label = 'label';
    prefix = 'radio-tile';

    idFor = uuidv4();

    constructor(private elRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        if (this.elRef) {
            const INPUT = this.elRef.nativeElement.querySelector('input');
            if (INPUT) {
                this.renderer.setAttribute(INPUT, 'id', this.idFor);
            }
        }
    }
}
