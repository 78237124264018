import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { BOOTSTRAP_COLUMNS_MAP } from '../constants/bootstrap-columns.constants';
import { BootstrapResponsiveColumns } from '../models/bootstrap-columns.model';
import { ResponsiveColumnsOptions } from '../models/responsive-columns-options.model';

@Directive({
    selector: '[responsiveColumns]',
})
export class ResponsiveColumnsDirective implements OnInit {
    @Input() set responsiveColumns(
        responsiveColumns: ResponsiveColumnsOptions
    ) {
        this.removeClasses();
        this._responsiveColumns = responsiveColumns;
        this.updateClasses();
    }
    get responsiveColumns(): ResponsiveColumnsOptions {
        return this._responsiveColumns;
    }
    _responsiveColumns: ResponsiveColumnsOptions;

    @Input() set disableResponsiveColumns(disable: boolean) {
        this._disableResponsiveColumns = disable;
        this.updateClasses();
    }
    get disableResponsiveColumns(): boolean {
        return this._disableResponsiveColumns;
    }
    _disableResponsiveColumns: boolean;

    classPrefix = 'g-col-';
    collapsedClassPrefix = 'g-collapsed-col-';

    classes: string[];

    defaultOptions: ResponsiveColumnsOptions = {
        columns: {
            xxl: 4,
            xl: 3,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
        },
        collapsedNavColumns: {
            xxl: 4,
            xl: 3,
            lg: 2,
            md: 2,
            sm: 1,
            xs: 1,
        },
    };

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    ngOnInit(): void {
        this.updateClasses();
    }

    private updateClasses(): void {
        this.setClasses();
        if (this.disableResponsiveColumns) {
            this.removeClasses();
        } else {
            this.addClasses();
        }
    }

    private setClasses() {
        let classes: string[];
        const options: ResponsiveColumnsOptions = {
            ...this.defaultOptions,
            ...this.responsiveColumns,
        };
        if (options.staticColumns) {
            classes = [
                `${this.classPrefix}${
                    BOOTSTRAP_COLUMNS_MAP[options.staticColumns]
                }`,
            ];
        } else {
            classes = [
                ...this.columnsToClasses(options.columns, this.classPrefix),
                ...this.columnsToClasses(
                    options.collapsedNavColumns,
                    this.collapsedClassPrefix
                ),
            ];
        }
        this.classes = classes;
    }

    private addClasses(): void {
        this.classes?.forEach((colClass) => {
            this.renderer.addClass(this.elementRef.nativeElement, colClass);
        });
    }

    private removeClasses(): void {
        this.classes?.forEach((colClass) => {
            this.renderer.removeClass(this.elementRef.nativeElement, colClass);
        });
    }

    private columnsToClasses(
        columns: BootstrapResponsiveColumns,
        prefix: string
    ): string[] {
        return Object.keys(columns).map((breakpoint) => {
            return `${prefix}${breakpoint === 'xs' ? '' : breakpoint + '-'}${
                BOOTSTRAP_COLUMNS_MAP[columns[breakpoint]]
            }`;
        });
    }
}
