import { Pipe, PipeTransform } from '@angular/core';
import { FileIndex } from '../../models';
import { FileSizePipe } from './file-size.pipe';

/**
 * Converts fileIndex into a full file name in the convention 'Name (size)'
 */
@Pipe({ name: 'fileName', standalone: true })
export class FileNamePipe implements PipeTransform {
    transform(fileIndex: FileIndex): string {
        return `${fileIndex?.name || ''} (${new FileSizePipe().transform(
            fileIndex?.sizeInBytes
        )})`;
    }
}
