import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxFeatureSvgComponent } from './wdx-feature-svg.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxFeatureSvgComponent],
    exports: [WdxFeatureSvgComponent],
})
export class WdxFeatureSvgModule {}
