import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxLabelModule } from '@wdx/shared/components/wdx-label';
import { WdxMarkdownToHtmlPipe, WdxSafeHtmlPipe } from '@wdx/shared/utils';
import { WdxFormFieldComponent } from './wdx-form-field.component';

@NgModule({
    imports: [
        CommonModule,
        WdxLabelModule,
        WdxIconModule,
        NgbPopoverModule,
        WdxSafeHtmlPipe,
        WdxMarkdownToHtmlPipe,
    ],
    declarations: [WdxFormFieldComponent],
    exports: [WdxFormFieldComponent],
})
export class WdxFormFieldModule {}
