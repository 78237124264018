import { Injectable } from '@angular/core';
import { Action, ActionReducer } from '@ngrx/store';
import { GenericWithId } from '../../../models/generics';
import { CrudStateObject } from '../../models/crud-state-object.model';
import { CrudStateType } from '../../models/crud-state-type.model';
import { CrudState } from '../../models/crud-state.model';
import { PaginatedApiResponse } from '../../models/paginated-api-response.model';

@Injectable()
export class WdxReducerClass<State> {
    /**
     * The initial state
     */
    reducerSetup?: ActionReducer<State, Action>;

    /**
     * Returns either the input CrudState argument, or a new CrudState if it is falsy
     */
    setCrudState<T>(state: CrudState<T>): CrudState<T> {
        return state || ({} as CrudState<T>);
    }

    /**
     * Returns either the input CrudStateObject argument, or a new CrudStateObject if it is falsy
     */
    setCrudStateObject<T>(state: CrudStateObject<T>): CrudStateObject<T> {
        return state || ({} as CrudStateObject<T>);
    }

    /**
     * Returns the input CrudStateObject argument with the fn argument executed on each CrudState parameter
     */
    updateCrudStateObject<T>(
        state: CrudStateObject<T>,
        fn: (crudState: any) => CrudState<T>
    ): CrudStateObject<T> {
        const _state = state;
        Object.keys(_state).forEach((id) => {
            _state[id] = fn(_state[id]);
        });
        return _state;
    }

    /**
     * Returns the input CrudStateObject argument with the fn argument executed on the CrudState with id
     */
    updateInCrudStateObject<T>(
        state: CrudStateObject<T>,
        id: string,
        fn: (crudState: any, props?: any) => CrudState<T>,
        ...props: any[]
    ): CrudStateObject<T> {
        if (state[id]) {
            return {
                ...state,
                [id]: fn(state[id], ...props),
            };
        } else {
            return state;
        }
    }

    /**
     * Returns the CrudState parameters for a single loading state
     */
    singleLoading<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isLoadingSingle: true,
            hasLoadingSingleError: false,
        };
    }

    /**
     * Returns the CrudState parameters for a single loading state with id within a CrudStateObject
     */
    singleForIdLoading<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return {
            ...this.setCrudStateObject(state),
            [id]: this.singleLoading(state[id]),
        };
    }

    /**
     * Returns the CrudState parameters for a single success state
     */
    singleSuccess<T>(state: CrudState<T>, single: T): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isLoadingSingle: false,
            hasLoadingSingleError: false,
            single,
        };
    }

    /**
     * Returns the CrudState parameters for a single success state with id within a CrudStateObject
     */
    singleForIdSuccess<T>(
        state: CrudStateObject<T>,
        id: string,
        single: T
    ): CrudStateObject<T> {
        return {
            ...this.setCrudStateObject(state),
            [id]: this.singleSuccess(state[id], single),
        };
    }

    /**
     * Returns the CrudState parameters for a single failure state
     */
    singleFailure<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isLoadingSingle: false,
            hasLoadingSingleError: true,
        };
    }

    /**
     * Returns the CrudState parameters for a single failure state with id within a CrudStateObject
     */
    singleForIdFailure<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return {
            ...this.setCrudStateObject(state),
            [id]: this.singleFailure(state[id]),
        };
    }

    /**
     * Returns the CrudState parameters for a list loading state
     */
    listLoading<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isLoadingList: true,
            hasLoadingListError: false,
        };
    }

    /**
     * Returns the CrudState parameters for a list loading state with id within a CrudStateObject
     */
    listForIdLoading<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return {
            ...this.setCrudStateObject(state),
            [id]: this.listLoading(state[id]),
        };
    }

    /**
     * Returns the CrudState parameters for a list success state
     */
    listSuccess<T>(state: CrudState<T>, list: T[]): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isLoadingList: false,
            hasLoadingListError: false,
            list,
        };
    }

    /**
     * Returns the CrudState parameters for a list success state with id within a CrudStateObject
     */
    listForIdSuccess<T>(
        state: CrudStateObject<T>,
        id: string,
        list: T[]
    ): CrudStateObject<T> {
        return {
            ...this.setCrudStateObject(state),
            [id]: this.listSuccess(state[id], list),
        };
    }

    /**
     * Returns the CrudState parameters for a list failure state
     */
    listFailure<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isLoadingList: false,
            hasLoadingListError: true,
        };
    }

    /**
     * Returns the CrudState parameters for a list failure state with id within a CrudStateObject
     */
    listForIdFailure<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return {
            ...this.setCrudStateObject(state),
            [id]: this.listFailure(state[id]),
        };
    }

    /**
     * Returns the CrudState parameters for a page loading state
     */
    pageLoading<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isLoadingPage: true,
            hasLoadingPageError: false,
        };
    }

    /**
     * Returns the CrudState parameters for a page loading state with id within a CrudStateObject
     */
    pageForIdLoading<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return {
            ...this.setCrudStateObject(state),
            [id]: this.pageLoading(state[id]),
        };
    }

    /**
     * Returns the CrudState parameters for a page success state
     */
    pageSuccess<T>(
        state: CrudState<T>,
        page: PaginatedApiResponse<T>
    ): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isLoadingPage: false,
            hasLoadingPageError: false,
            page,
        };
    }

    /**
     * Returns the CrudState parameters for a page success state with id within a CrudStateObject
     */
    pageForIdSuccess<T>(
        state: CrudStateObject<T>,
        id: string,
        page: PaginatedApiResponse<T>
    ): CrudStateObject<T> {
        return {
            ...this.setCrudStateObject(state),
            [id]: this.pageSuccess(state[id], page),
        };
    }

    /**
     * Returns the CrudState parameters for a page failure state
     */
    pageFailure<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isLoadingPage: false,
            hasLoadingPageError: true,
        };
    }

    /**
     * Returns the CrudState parameters for a page failure state with id within a CrudStateObject
     */
    pageForIdFailure<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return {
            ...this.setCrudStateObject(state),
            [id]: this.pageFailure(state[id]),
        };
    }

    /**
     * Returns the CrudState parameters for an updating state
     */
    updating<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isUpdating: true,
            hasUpdatingError: false,
        };
    }

    /**
     * Returns the CrudState parameters for an updating state with id within a CrudStateObject
     */
    updatingForId<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return this.updateInCrudStateObject<T>(
            state,
            id,
            this.updating.bind(this)
        );
    }

    /**
     * Returns the CrudState parameters for an updating success state
     */
    updatingSuccess<T>(
        state: CrudState<T>,
        type?: CrudStateType,
        replaceWith?: T
    ): CrudState<T> {
        return this.updatingDeletingSuccess(state, type, { replaceWith });
    }

    /**
     * Returns the CrudState parameters for an updating success state with id within a CrudStateObject
     */
    updatingForIdSuccess<T>(
        state: CrudStateObject<T>,
        id: string,
        type?: CrudStateType,
        replaceWith?: T
    ): CrudStateObject<T> {
        return this.updateInCrudStateObject<T>(
            state,
            id,
            this.updatingSuccess.bind(this),
            type,
            replaceWith
        );
    }

    /**
     * Returns the CrudState parameters for an updating failure state
     */
    updatingFailure<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isUpdating: false,
            hasUpdatingError: true,
        };
    }

    /**
     * Returns the CrudState parameters for an updating failure state with id within a CrudStateObject
     */
    updatingForIdFailure<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return this.updateInCrudStateObject<T>(
            state,
            id,
            this.updatingFailure.bind(this)
        );
    }

    /**
     * Returns the CrudState parameters for an deleting state
     */
    deleting<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isDeleting: true,
            hasDeletingError: false,
        };
    }

    /**
     * Returns the CrudState parameters for an deleting state with id within a CrudStateObject
     */
    deletingForId<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return this.updateInCrudStateObject<T>(
            state,
            id,
            this.deleting.bind(this)
        );
    }

    /**
     * Returns the CrudState parameters for an deleting success state
     */
    deletingSuccess<T>(
        state: CrudState<T>,
        deleteId: string,
        type?: CrudStateType
    ): CrudState<T> {
        return this.updatingDeletingSuccess(state, type, { deleteId });
    }

    /**
     * Returns the CrudState parameters for an deleting success state with id within a CrudStateObject
     */
    deletingForIdSuccess<T>(
        state: CrudStateObject<T>,
        id: string,
        deleteId?: string,
        type?: CrudStateType
    ): CrudStateObject<T> {
        return this.updateInCrudStateObject<T>(
            state,
            id,
            this.deletingSuccess.bind(this),
            deleteId,
            type
        );
    }

    /**
     * Returns the CrudState parameters for an deleting failure state
     */
    deletingFailure<T>(state: CrudState<T>): CrudState<T> {
        return {
            ...this.setCrudState<T>(state),
            isDeleting: false,
            hasDeletingError: true,
        };
    }

    /**
     * Returns the CrudState parameters for an deleting failure state with id within a CrudStateObject
     */
    deletingForIdFailure<T>(
        state: CrudStateObject<T>,
        id: string
    ): CrudStateObject<T> {
        return this.updateInCrudStateObject<T>(
            state,
            id,
            this.deletingFailure.bind(this)
        );
    }

    updatingDeletingSuccess<T>(
        state: CrudState<T>,
        type?: CrudStateType,
        props?: {
            replaceWith?: T;
            deleteId?: string;
        }
    ): CrudState<T> {
        const replaceWith = props?.replaceWith;
        const deleteId = props?.deleteId;
        let replace = {};
        if (replaceWith || deleteId) {
            switch (type) {
                case 'list':
                    // eslint-disable-next-line no-case-declarations
                    const list = this.modifyList(
                        state.list as GenericWithId[],
                        {
                            replaceWith: replaceWith as
                                | GenericWithId
                                | undefined,
                            deleteId,
                        }
                    );
                    replace = { list };
                    break;
                case 'page':
                    // eslint-disable-next-line no-case-declarations
                    const results = this.modifyList(
                        state.page?.results as GenericWithId[],
                        {
                            replaceWith: replaceWith as
                                | GenericWithId
                                | undefined,
                            deleteId,
                        }
                    );
                    replace = { page: { paging: state.page?.paging, results } };
                    break;
                case 'single':
                default:
                    replace = { single: deleteId ? null : replaceWith };
            }
        }
        return {
            ...this.setCrudState<T>(state),
            ...replace,
            isUpdating: false,
            hasUpdatingError: false,
            isDeleting: false,
            hasDeletingError: false,
        };
    }

    /**
     * If an object with an id has the matching id to an object within a list then replace it, else append it
     */
    modifyList<T extends GenericWithId>(
        list: T[],
        props: {
            replaceWith?: T;
            deleteId?: string;
        }
    ): T[] {
        const replaceWith = props?.replaceWith;
        const deleteId = props?.deleteId;
        if (!list?.length) {
            return [];
        }
        if (!props || (!replaceWith && !deleteId)) {
            return list;
        }
        let _list = [...list];
        const index = list.findIndex(
            (value) => value.id === replaceWith?.id || deleteId
        );
        if (index > -1) {
            if (replaceWith) {
                _list?.splice(index, 1, replaceWith);
            } else if (deleteId) {
                _list?.splice(index, 1);
            }
        } else {
            if (replaceWith) {
                _list = [..._list, replaceWith];
            }
        }
        return _list;
    }

    /**
     * Convert an array of objects into a CrudStateObject. Objects must contain an 'id' parameter.
     */
    arrayToCrudStateObject<T extends GenericWithId>(
        arr: T[],
        isLoading = false,
        hasError = false
    ): CrudStateObject<T> {
        return arr.reduce((accumulator, item) => {
            return {
                ...accumulator,
                [item.id as string]: {
                    single: item,
                    isLoadingSingle: isLoading,
                    hasLoadingSingleError: hasError,
                },
            } as CrudStateObject<T>;
        }, {} as CrudStateObject<T>);
    }
}
