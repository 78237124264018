import { Injectable } from '@angular/core';
import { ExtendedFieldDefinitionOption } from '../../../../../../../models/extended-field-definition-option.model';
import { ReactiveFormDynamicDataService } from '../../../../../../../shared/services/dynamic-data/dynamic-data.service';
import { FilterFieldDefinition, Tag } from '@wdx/clmi/api-models';
import { filter, forkJoin, map, Observable, take } from 'rxjs';
import { FilterDataItem } from '../../../interfaces/filter-data-item.interface';
import { IFilterDataProvider } from '../../../interfaces/filter-data-provider.interface';

@Injectable()
export class FilterTagsDataService implements IFilterDataProvider {
    constructor(private dynamicDataService: ReactiveFormDynamicDataService) {}

    getData$(
        definition: FilterFieldDefinition
    ): Observable<FilterDataItem[] | ExtendedFieldDefinitionOption[]> {
        if (definition?.selectSource && !definition?.tagCategories?.length) {
            return this.dynamicDataService
                .getSelectApiSourceOptions(definition.selectSource)
                .pipe(take(1));
        }

        if (definition?.tagCategories?.length) {
            const tagCategoryObservables = definition.tagCategories.map(
                (tagCategory) => this.getTags(tagCategory)
            );

            const tagCategoriesObservables = forkJoin(tagCategoryObservables);

            return tagCategoriesObservables.pipe(
                filter((tagCategories) => Boolean(tagCategories)),
                map((tagCategories) => {
                    const TAG_CATEGORIES = [];
                    tagCategories.map((tagCategory) =>
                        TAG_CATEGORIES.push(...tagCategory)
                    );

                    return TAG_CATEGORIES;
                })
            );
        }
    }

    getTags(tagCategory): Observable<any> {
        return this.dynamicDataService.getTagsForCategory(tagCategory).pipe(
            filter((res) => Boolean(res)),
            take(1),
            map((tags: Tag[]) =>
                tags.map((t: any) => ({
                    label: t.tag,
                    value: t.id,
                }))
            )
        );
    }
}
