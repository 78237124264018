import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomIconLetterComponent } from './atom-icon-letter.component';

@NgModule({
    imports: [CommonModule],
    declarations: [AtomIconLetterComponent],
    exports: [AtomIconLetterComponent],
})
export class AtomIconLetterModule {}
