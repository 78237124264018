import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { MoleculeLoaderOverlayComponent } from './molecule-loader-overlay.component';

@NgModule({
    imports: [CommonModule, WdxSpinnerModule],
    declarations: [MoleculeLoaderOverlayComponent],
    exports: [MoleculeLoaderOverlayComponent],
})
export class MoleculeLoaderOverlayModule {}
