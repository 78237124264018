import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as tagsActions from './tags.actions';
import { TagsService } from './tags.service';

@Injectable()
export class TagsEffects {
    private actions$ = inject(Actions);
    private tagsService = inject(TagsService);

    getForRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.getForRecord),
            mergeMap((action) =>
                this.tagsService
                    .getForRecord(action.entity, action.recordId)
                    .pipe(
                        map((tags) =>
                            tagsActions.getForRecordSuccess({
                                recordId: action.recordId,
                                entity: action.entity,
                                tags,
                            })
                        ),
                        catchError((error) =>
                            of(
                                tagsActions.getForRecordFailure({
                                    recordId: action.recordId,
                                    entity: action.entity,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getCategoriesForSystemEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.getCategoriesForSystemEntity),
            mergeMap((action) =>
                this.tagsService
                    .getCategoriesForSystemEntity(action.systemEntity)
                    .pipe(
                        map((categories) =>
                            tagsActions.getCategoriesForSystemEntitySuccess({
                                systemEntity: action.systemEntity,
                                categories,
                            })
                        ),
                        catchError((error) =>
                            of(
                                tagsActions.getCategoriesForSystemEntityFailure(
                                    {
                                        systemEntity: action.systemEntity,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getForCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.getForCategory),
            mergeMap((action) =>
                this.tagsService.getForCategory(action.category).pipe(
                    map((tags) =>
                        tagsActions.getForCategorySuccess({
                            category: action.category,
                            tags,
                        })
                    ),
                    catchError((error) =>
                        of(
                            tagsActions.getForCategoryFailure({
                                category: action.category,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    createForRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.createForRecord),
            mergeMap((action) =>
                this.tagsService
                    .createForRecord(
                        action.entity,
                        action.recordId,
                        action.category,
                        action.tagNames
                    )
                    .pipe(
                        map((tags) =>
                            tagsActions.createForRecordSuccess({
                                entity: action.entity,
                                recordId: action.recordId,
                                tags: tags,
                            })
                        ),
                        catchError((error) =>
                            of(
                                tagsActions.createForRecordFailure({
                                    entity: action.entity,
                                    recordId: action.recordId,
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteForRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.deleteForRecord),
            mergeMap((action) =>
                this.tagsService
                    .deleteForRecord(
                        action.entity,
                        action.recordId,
                        action.category,
                        action.tagNames
                    )
                    .pipe(
                        map((tags) =>
                            tagsActions.deleteForRecordSuccess({
                                entity: action.entity,
                                recordId: action.recordId,
                                tags: tags,
                            })
                        ),
                        catchError((error) =>
                            of(
                                tagsActions.deleteForRecordFailure({
                                    entity: action.entity,
                                    recordId: action.recordId,
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.deleteTag),
            mergeMap((action) =>
                this.tagsService.delete(action.tagName).pipe(
                    map(() =>
                        tagsActions.deleteSuccess({
                            tagName: action.tagName,
                        })
                    ),
                    catchError((error) =>
                        of(
                            tagsActions.deleteFailure({
                                tagName: action.tagName,
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    getForCategoryAndRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.getForCategoryAndRecord),
            mergeMap((action) =>
                this.tagsService
                    .getForCategoryAndRecord(
                        action.categoryId,
                        action.entity,
                        action.entityId
                    )
                    .pipe(
                        map((tags) =>
                            tagsActions.getForCategoryAndRecordSuccess({
                                tags,
                                categoryId: action.categoryId,
                                entity: action.entity,
                                entityId: action.entityId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                tagsActions.getForCategoryAndRecordFailure({
                                    categoryId: action.categoryId,
                                    entity: action.entity,
                                    entityId: action.entityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getAllTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.getAllTags),
            switchMap(() =>
                this.tagsService.getAllTags().pipe(
                    map((tagCategories) =>
                        tagsActions.getAllTagsSuccess({
                            tagCategories,
                        })
                    ),
                    catchError((error) =>
                        of(
                            tagsActions.getAllTagsFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    disableTag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.disableTag),
            switchMap((action) =>
                this.tagsService
                    .disableTag(action.tagId, action.isDisabled)
                    .pipe(
                        map(() =>
                            tagsActions.disableTagSuccess({
                                tagId: action.tagId,
                                isDisabled: action.isDisabled,
                            })
                        ),
                        catchError((error) =>
                            of(tagsActions.disableTagFailure({ error }))
                        )
                    )
            )
        )
    );
}
