import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { userSelectors } from '@wdx/clmi/api-services/services';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { RoleType, RoleTypeEnum } from '../../../models/role-type.model';
import * as rootReducer from '../../../state/_setup/reducers';

@Injectable({
    providedIn: 'root',
})
export class RolesService extends WdxDestroyClass {
    constructor(private store$: Store<rootReducer.State>) {
        super();
    }

    hasRole$(...role: RoleType[]): Observable<boolean> {
        return this.store$.select(userSelectors.getRolesSelector).pipe(
            takeUntil(this.destroyed$),
            filter((roles) => !!roles),
            map((roles) => {
                return (
                    roles.some((r) => role.includes(r.role.code)) ||
                    roles.some(
                        (r) => r.role.code === RoleTypeEnum.SystemAdministrator
                    )
                );
            })
        );
    }
}
