import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SystemEntity } from '@wdx/clmi/api-models';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as workItemsActions from './work-items.actions';
import { WorkItemsService } from './work-items.service';

@Injectable()
export class WorkItemsEffects {
    private actions$ = inject(Actions);
    private workItemsService = inject(WorkItemsService);

    getWorkItemsForProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(workItemsActions.getWorkItemsForProcess),
            switchMap((action) =>
                this.workItemsService
                    .getWorkItemsForSystemEntity(
                        SystemEntity.Process,
                        action.processId
                    )
                    .pipe(
                        map((processWorkItems) =>
                            workItemsActions.getWorkItemsForProcessSuccess({
                                workItems: processWorkItems,
                                processId: action.processId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                workItemsActions.getWorkItemsForProcessFailure({
                                    processId: action.processId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getWorkItemsForCase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(workItemsActions.getWorkItemsForCase),
            switchMap((action) =>
                this.workItemsService
                    .getWorkItemsForSystemEntity(
                        SystemEntity.Case,
                        action.caseId
                    )
                    .pipe(
                        map((processWorkItems) =>
                            workItemsActions.getWorkItemsForCaseSuccess({
                                workItems: processWorkItems,
                                caseId: action.caseId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                workItemsActions.getWorkItemsForCaseFailure({
                                    caseId: action.caseId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getWorkItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(workItemsActions.getWorkItem),
            mergeMap((action) =>
                this.workItemsService.getWorkItem(action.workItemId).pipe(
                    map((workItem) =>
                        workItemsActions.getWorkItemSuccess({
                            workItemId: action.workItemId,
                            workItem,
                        })
                    ),
                    catchError((error) =>
                        of(
                            workItemsActions.getWorkItemFailure({
                                workItemId: action.workItemId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
