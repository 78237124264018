import { Type } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Avatar } from '@wdx/shared/components/wdx-avatar';
import { Highlight, Party, PartyStub, WdxSize } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { ReactiveFormLayoutAndDefinition } from '../models';

export interface IWrappedFormComponentConfig {
    component: Type<any>;
    inputs: {
        formInputData: ReactiveFormLayoutAndDefinition;
        formData?: any;
        formContext?: any;
    } & Record<string, unknown>;
}

export interface IWrappedAvatarComponentConfig {
    component: Type<any>;
    inputs: {
        avatar?: Avatar;
        size?: WdxSize;
        highlightBorder?: Highlight;
        isAvatarInfoEnabled?: boolean;
        editModalId?: string;
    };
}

export abstract class IWrappedFormComponentProvider {
    public readonly hideErrorMessages!: boolean;
    public abstract getComponent$(
        formElement: ReactiveFormLayoutAndDefinition,
        formContext: any,
        formData: UntypedFormGroup
    ): Observable<{ component: Type<any>; inputs: Record<string, unknown> }>;
    public abstract getAvatarComponent$(data: Party | PartyStub): Observable<{
        component: Type<any>;
        inputs: Record<string, unknown>;
    }>;
}

export type SubFormHeaderSubLevelType = [
    string | null,
    string | null,
    string | null
];
