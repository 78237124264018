export enum Highlight {
    Warning = 'warning',
    Danger = 'danger',
    Success = 'success',
    Info = 'info',
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Highlight = 'highlight',
}

export interface HighlightWithShade {
    highlight: Highlight;
    shade: number;
}
