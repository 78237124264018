import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxSpinnerComponent } from './wdx-spinner.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxSpinnerComponent],
    exports: [WdxSpinnerComponent],
})
export class WdxSpinnerModule {}
