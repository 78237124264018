import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeInfoCardSearchModule } from '../../../components/molecules/molecule-info-card-search/molecule-info-card-search.module';
import { OrganismCaseTemplateSearchComponentDumb } from './components/organism-case-template-search-dumb/organism-case-template-search-dumb.component';
import { OrganismCaseTemplateSearchComponent } from './containers/organism-case-template-search/organism-case-template-search.component';
import { FunctionPipe } from '@wdx/shared/utils';
import { PushModule } from '@ngrx/component';

@NgModule({
    imports: [
        CommonModule,
        MoleculeInfoCardSearchModule,
        PushModule,
        FunctionPipe,
    ],
    declarations: [
        OrganismCaseTemplateSearchComponent,
        OrganismCaseTemplateSearchComponentDumb,
    ],
    exports: [
        OrganismCaseTemplateSearchComponent,
        OrganismCaseTemplateSearchComponentDumb,
    ],
})
export class OrganismCaseTemplateSearchModule {}
