import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as contactPointsActions from './contact-points.actions';
import { ContactPointsService } from './contact-points.service';

@Injectable()
export class ContactPointsEffects {
    private actions$ = inject(Actions);
    private contactPointsService = inject(ContactPointsService);

    getForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(contactPointsActions.getForParty),
            mergeMap((action) =>
                this.contactPointsService.getForParty(action.partyId).pipe(
                    map((contactPoints) =>
                        contactPointsActions.getForPartySuccess({
                            partyId: action.partyId,
                            contactPoints,
                        })
                    ),
                    catchError((error) =>
                        of(
                            contactPointsActions.getForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
