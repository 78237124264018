/**
 *
 * @param arr | This is the array of elements to be updated.
 * @param index | This is the index of the element to be updated
 * @param prop | This the the property to be updated and it new value
 * @returns | Returns a new array with the updates
 *
 * @example UPDATE_ITEM_CREATE_NEW_ARRAY([{ name: 'foo', value: 'bar' }, { name: 'foo 1', value: 'bar 1' }], 1, {name: 'foo updated 1', value: 'bar updated 1'})
 */
export const UPDATE_ITEM_CREATE_NEW_ARRAY = <T>(
    arr: T[],
    index: number,
    prop: { [key: string]: any }
): T[] => {
    if (!arr) {
        return [];
    }

    if (typeof index !== 'number' || typeof prop === 'undefined') {
        return [...arr];
    }

    return [
        ...arr.slice(0, index),
        {
            ...arr[index],
            ...prop,
        },
        ...arr.slice(index + 1),
    ];
};

/**
 *
 * @param arr | This is the array of elements to be updated.
 * @param index | This is the index of the element to be removed
 *
 * @returns | Returns a new array with the updates
 *
 * @example DELETE_ITEM_RETURN_NEW_ARRAY([{ name: 'foo', value: 'bar' }, { name: 'foo 1', value: 'bar 1' }], 1)
 */
export const DELETE_ITEM_RETURN_NEW_ARRAY = <T>(
    arr: T[],
    index: number
): T[] => {
    if (!arr) {
        return [];
    }

    if (typeof index !== 'number') {
        return [...arr];
    }

    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

/**
 *
 * @param inObject | This is the deep/nested array to be cloned.
 *
 * @returns | Returns a new array copy
 *
 * @example DEEP_COPY_FUNCTION([{ name: 'foo', value: 'bar' }, { name: 'foo 1', value: 'bar 1' }])
 */
export const DEEP_COPY_FUNCTION = (inObject) => {
    let value;
    let key;

    if (typeof inObject !== 'object' || inObject === null) {
        return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    const outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
        value = inObject[key];

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = DEEP_COPY_FUNCTION(value);
    }

    return outObject;
};
