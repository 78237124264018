import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as clientProductsActions from './client-products.actions';
import { ClientProductsService } from './client-products.service';

@Injectable()
export class ClientProductsEffects {
    private actions$ = inject(Actions);
    private clientProductsService = inject(ClientProductsService);

    getForPartyRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.getForPartyRole),
            mergeMap((action) =>
                this.clientProductsService
                    .getForPartyRole(action.partyRoleId)
                    .pipe(
                        map((clientProducts) =>
                            clientProductsActions.getForPartyRoleSuccess({
                                partyRoleId: action.partyRoleId,
                                clientProducts,
                            })
                        ),
                        catchError((error) =>
                            of(
                                clientProductsActions.getForPartyRoleFailure({
                                    partyRoleId: action.partyRoleId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.getForParty),
            mergeMap((action) =>
                this.clientProductsService.getForParty(action.partyId).pipe(
                    map((clientProducts) =>
                        clientProductsActions.getForPartySuccess({
                            partyId: action.partyId,
                            clientProducts,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientProductsActions.getForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.getForClient),
            switchMap((action) =>
                this.clientProductsService.getForClient(action.clientId).pipe(
                    map((clientProducts) =>
                        clientProductsActions.getForClientSuccess({
                            clientProducts,
                            clientId: action.clientId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientProductsActions.getForClientFailure({
                                clientId: action.clientId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.getForId),
            mergeMap((action) =>
                this.clientProductsService
                    .getForId(action.clientProductId)
                    .pipe(
                        map((clientProduct) =>
                            clientProductsActions.getForIdSuccess({
                                clientProductId: action.clientProductId,
                                clientProduct,
                            })
                        ),
                        catchError((error) =>
                            of(
                                clientProductsActions.getForIdFailure({
                                    clientProductId: action.clientProductId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getCasesForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.getCasesForId),
            mergeMap((action) =>
                this.clientProductsService
                    .getCasesForId(action.clientProductId)
                    .pipe(
                        map((cases) =>
                            clientProductsActions.getCasesForIdSuccess({
                                clientProductId: action.clientProductId,
                                cases,
                            })
                        ),
                        catchError((error) =>
                            of(
                                clientProductsActions.getCasesForIdFailure({
                                    clientProductId: action.clientProductId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getVisibilityForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.getVisibilityForId),
            mergeMap((action) =>
                this.clientProductsService.getVisibilityForId(action.id).pipe(
                    map((visibility) =>
                        clientProductsActions.getVisibilityForIdSuccess({
                            id: action.id,
                            visibility,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientProductsActions.getVisibilityForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getMembersForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.getMembersForId),
            mergeMap((action) =>
                this.clientProductsService.getMemberForId(action.id).pipe(
                    map((members) =>
                        clientProductsActions.getMembersForIdSuccess({
                            id: action.id,
                            members,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientProductsActions.getMembersIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getKeyStatsForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.getKeyStatsForId),
            mergeMap((action) =>
                this.clientProductsService.getKeyStatsForId(action.id).pipe(
                    map((keyStats) =>
                        clientProductsActions.getKeyStatsForIdSuccess({
                            id: action.id,
                            keyStats,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientProductsActions.getKeyStatsForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.deleteForId),
            mergeMap((action) =>
                this.clientProductsService.deleteForId(action.productId).pipe(
                    map(() =>
                        clientProductsActions.deleteForIdSuccess({
                            clientId: action.clientId,
                            productId: action.productId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientProductsActions.deleteForIdFailure({
                                clientId: action.clientId,
                                productId: action.productId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteTeamMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.deleteTeamMember),
            mergeMap((action) =>
                this.clientProductsService
                    .deleteTeamMember(action.id, action.memberId)
                    .pipe(
                        map((_) =>
                            clientProductsActions.deleteTeamMemberSuccess({
                                id: action.id,
                                memberId: action.memberId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                clientProductsActions.deleteTeamMemberFailure({
                                    id: action.id,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getOperationForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clientProductsActions.getOperationForId),
            mergeMap((action) =>
                this.clientProductsService.getOperationForId(action.id).pipe(
                    map((operations) =>
                        clientProductsActions.getOperationForIdSuccess({
                            id: action.id,
                            operations,
                        })
                    ),
                    catchError((error) =>
                        of(
                            clientProductsActions.getOperationForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
