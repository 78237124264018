import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[wdxStopPropagation]',
    standalone: true,
})
export class WdxStopPropagationDirective {
    @HostListener('click', ['$event'])
    public onClick(event: Event): void {
        event.stopPropagation();
    }
}
