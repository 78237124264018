import { Action, createReducer, on } from '@ngrx/store';
import {
    CrudState,
    CrudStateObject,
    PostalAddress,
    PostalAddressLookup,
    PostalAddressLookupResult,
} from '@wdx/shared/utils';
import * as addressesActions from './addresses.actions';

export const FEATURE_KEY = 'addresses';

export interface State {
    addressLookups?: CrudStateObject<PostalAddressLookupResult>;
    addresses?: CrudStateObject<PostalAddress>;
}

export const initialState: State = {
    addressLookups: {},
    addresses: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        addressesActions.getAddresses,
        (state, props): State => ({
            ...state,
            addressLookups: {
                ...state.addressLookups,
                [props.fieldId]: {
                    ...(state.addressLookups?.[props.fieldId] ||
                        ({} as CrudState<PostalAddressLookup>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        addressesActions.getAddressesSuccess,
        (state, props): State => ({
            ...state,
            addressLookups: {
                ...state.addressLookups,
                [props.fieldId]: {
                    ...state.addressLookups?.[props.fieldId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.addresses,
                },
            },
        })
    ),

    on(
        addressesActions.getAddressesFailure,
        (state, props): State => ({
            ...state,
            addressLookups: {
                ...state.addressLookups,
                [props.fieldId]: {
                    ...state.addressLookups?.[props.fieldId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        addressesActions.getAddress,
        (state, props): State => ({
            ...state,
            addresses: {
                ...state.addresses,
                [props.fieldId]: {
                    ...(state.addresses?.[props.fieldId] ||
                        ({} as CrudState<PostalAddress>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        addressesActions.getAddressSuccess,
        (state, props): State => ({
            ...state,
            addresses: {
                ...state.addresses,
                [props.fieldId]: {
                    ...state.addresses?.[props.fieldId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.address,
                },
            },
        })
    ),

    on(
        addressesActions.getAddressFailure,
        (state, props): State => ({
            ...state,
            addresses: {
                ...state.addresses,
                [props.fieldId]: {
                    ...state.addresses?.[props.fieldId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        addressesActions.reset,
        (state, props): State => ({
            ...state,
            addressLookups: {
                ...state.addressLookups,
                [props.fieldId]: {} as CrudState<PostalAddressLookup>,
            },
            addresses: {
                ...state.addresses,
                [props.fieldId]: {} as CrudState<PostalAddress>,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
