import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncatedSearch' })
export class TruncatedSearchPipe implements PipeTransform {
    transform(searchText: string, targetText: string): boolean {
        if (!searchText || !targetText) {
            return true;
        }

        const formattedSearchText = searchText.toLowerCase().replace(/\s/g, '');

        const formattedTargetText = targetText.toLowerCase().replace(/\s/g, '');

        const charactersToFind = formattedSearchText.split('');

        const foundAllCharacters =
            formattedTargetText.split('').reduce((accumulator, character) => {
                return character === accumulator[0]
                    ? accumulator.slice(1)
                    : accumulator;
            }, charactersToFind).length === 0;

        return foundAllCharacters;
    }
}
