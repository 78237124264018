import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FunctionPipe } from '@wdx/shared/utils';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomCloseModule } from '../../atoms/atom-close/atom-close.module';
import { AtomIconButtonModule } from '../../atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeActionButtonModule } from '../molecule-action-button/molecule-action-button.module';
import { MoleculeInputSearchModule } from '../molecule-input-search/molecule-input-search.module';
import { MoleculeSearchBarComponent } from './molecule-search-bar.component';

@NgModule({
    imports: [
        AtomCloseModule,
        AtomIconButtonModule,
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeInputSearchModule,
        PipesModule,
        RouterModule,
        FunctionPipe,
    ],
    declarations: [MoleculeSearchBarComponent],
    exports: [MoleculeSearchBarComponent],
})
export class MoleculeSearchBarModule {}
