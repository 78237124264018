import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'wdx-icon-stack',
    templateUrl: './wdx-icon-stack.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxIconStackComponent {
    @Input() size = '';

    get faSize(): string {
        return this.size ? `fa-${this.size}` : '';
    }
}
