<div class="d-flex bg-body text-body" [class.border-bottom]="hasBottomBorder">
    <ul class="nav nav-tabs flex-grow-1">
        <li *ngFor="let tab of tabs; let tabNumber = index" class="nav-item">
            <atom-tab
                [active]="activeTabIndex === tabNumber"
                [label]="tab.label | translateTokenisedString"
                [cySelector]="tab.cySelector"
                [cyValue]="tab.cyValue"
                (click)="onActivate(tabNumber, true)"
            ></atom-tab>
        </li>
    </ul>
    <div class="flex-shrink-0 d-flex align-items-center">
        <ng-content select=".action-buttons" data-cy="btn-menu"></ng-content>
    </div>
</div>

<ng-container
    *ngIf="tabContentTemplate"
    [ngTemplateOutlet]="tabContentTemplate"
></ng-container>
