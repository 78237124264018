import { inject, Injectable } from '@angular/core';

import { WdxAuditValue } from '@wdx/shared/components/wdx-audit';
import {
    Action,
    FieldDefinition,
    FormConditions,
    FormDataResult,
    FormDefinition,
    FormElementLayoutDefinition,
    FormFieldType,
    FormFunctionResult,
    FormSectionLayoutDefinition,
    TitleCasePipe,
    Trigger,
    WdxDateFormat,
    WdxDateTimeService,
    WdxNumberService,
} from '@wdx/shared/utils';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import showdown from 'showdown';
import { DynamicFormsService } from '../../+state/dynamic-forms';
import { AddressFormatService } from '../../components/form-controls/form-address-control/services/address-format.service';
import {
    ADDRESS_SUMMARY_TYPES,
    CURRENCY_SUMMARY_TYPES,
    DATE_RANGE_SUMMARY_TYPES,
    DATE_SUMMARY_TYPES,
    DECIMAL_SUMMARY_TYPES,
    INTEGER_SUMMARY_TYPES,
    LOOKUP_SUMMARY_TYPES,
    OPTIONS_SUMMARY_TYPES,
    PHONE_SUMMARY_TYPES,
    RICH_TEXT_SUMMARY_TYPES,
    TEXT_TYPES,
} from '../../constants/summary-types';
import { SubFormHeaderSubLevelType } from '../../interfaces';
import { IFormDynamicData } from '../../interfaces/form-dynamic-data.interface';
import { ExtendedFieldDefinitionOption } from '../../models';
import { FormSummaryValueOptions } from '../../models/form-summary-value.model';
import { FormSummaryHistoricService } from '../../services';
import { FormContextualDataService } from '../form-contextual-data';
import { FormTriggersService } from '../form-validation';
import { TelephoneService } from '../telephone';

@Injectable()
export class FormSummaryService {
    private wdxDateTimeService = inject(WdxDateTimeService);
    private wdxNumberService = inject(WdxNumberService);
    private dynamicDataService = inject(IFormDynamicData);
    private dynamicFormsService = inject(DynamicFormsService);
    private telephoneService = inject(TelephoneService);
    private formContextualDataService = inject(FormContextualDataService);
    private formSummaryHistoricService = inject(FormSummaryHistoricService);
    private addressFormatService = inject(AddressFormatService);
    private formTriggersService = inject(FormTriggersService);

    getLayoutAndDefinitionsWithConditions(
        definition: FormDefinition,
        layoutAndDefinitions: FormSectionLayoutDefinition[],
        formFunctionResults: FormFunctionResult[],
        formData: FormDataResult,
        subFormData: Record<string, any>
    ) {
        const conditions = definition.conditions;
        const data = {
            layoutAndDefinitionsWithConditions: [] as any[],
            subFormData: { ...subFormData },
        };

        const actionSources = this.getActionSources(
            conditions,
            formFunctionResults,
            formData,
            layoutAndDefinitions
        );

        data.layoutAndDefinitionsWithConditions = layoutAndDefinitions?.map(
            (sectionLayoutAndDefinition) => {
                const updateSection = actionSources?.updateSections?.find(
                    (updateSection: any) =>
                        updateSection.name === sectionLayoutAndDefinition.name
                );
                return {
                    ...sectionLayoutAndDefinition,
                    isHidden:
                        sectionLayoutAndDefinition.isHidden ||
                        sectionLayoutAndDefinition?.elementLayoutDefinitions?.every(
                            (def: FieldDefinition) => Boolean(def.summaryLevel)
                        ),
                    elementLayoutDefinitions:
                        sectionLayoutAndDefinition?.elementLayoutDefinitions?.map(
                            (elementLayoutDefinition: any) => {
                                let result = elementLayoutDefinition;
                                const isFieldTypeArray =
                                    elementLayoutDefinition.fieldType ===
                                    FormFieldType.Array;

                                const schemaIndex =
                                    definition?.schema?.findIndex(
                                        (schemaItem) =>
                                            schemaItem.name ===
                                            elementLayoutDefinition.name
                                    );
                                if (schemaIndex === -1) {
                                    return {
                                        ...elementLayoutDefinition,
                                        isHidden: true,
                                    };
                                }

                                if (isFieldTypeArray) {
                                    const DEFAULT_ELEMENT_DEFINITION = [
                                        ...(elementLayoutDefinition
                                            ?.sectionLayoutDefinitions[0]
                                            ?.elementLayoutDefinitions as []),
                                    ];

                                    const MAX =
                                        formData?.data[
                                            elementLayoutDefinition.name
                                        ]?.length;

                                    for (let i = 0; i < MAX; i++) {
                                        if (
                                            !data.subFormData?.[
                                                elementLayoutDefinition.name
                                            ]
                                        ) {
                                            data.subFormData = {
                                                ...data.subFormData,
                                                [elementLayoutDefinition.name]:
                                                    {
                                                        [i]: DEFAULT_ELEMENT_DEFINITION,
                                                    },
                                            };
                                        } else {
                                            data.subFormData[
                                                elementLayoutDefinition.name
                                            ] = {
                                                ...data.subFormData[
                                                    elementLayoutDefinition.name
                                                ],
                                                [i]: DEFAULT_ELEMENT_DEFINITION,
                                            };
                                        }
                                    }
                                }

                                if (actionSources?.updateFields) {
                                    const updateFieldIndex =
                                        actionSources.updateFields
                                            .map(
                                                (updateField: any) =>
                                                    updateField.name
                                            )
                                            .lastIndexOf(
                                                elementLayoutDefinition.name
                                            );

                                    if (updateFieldIndex !== -1) {
                                        let updateField =
                                            actionSources?.updateFields[
                                                updateFieldIndex
                                            ];

                                        if (updateField?.children) {
                                            updateField = {
                                                ...updateField,
                                                ...elementLayoutDefinition,
                                            };

                                            const UPDATE_DATA: any =
                                                actionSources.updateFields.filter(
                                                    (updateField: any) =>
                                                        updateField.name ===
                                                        elementLayoutDefinition.name
                                                );

                                            const update = (
                                                acc: any,
                                                cur: any
                                            ) => {
                                                const ITEMS = cur.children;

                                                ITEMS?.forEach(
                                                    (item: any, i: number) => {
                                                        item.map(
                                                            (field: any) => {
                                                                const DATA =
                                                                    acc[i] ||
                                                                    (acc[i] =
                                                                        []);
                                                                const FIELD_INDEX =
                                                                    DATA.findIndex(
                                                                        (
                                                                            fieldData: any
                                                                        ) =>
                                                                            fieldData.name ===
                                                                            field.name
                                                                    );

                                                                if (
                                                                    FIELD_INDEX >=
                                                                    0
                                                                ) {
                                                                    DATA[
                                                                        FIELD_INDEX
                                                                    ] = {
                                                                        ...DATA[
                                                                            FIELD_INDEX
                                                                        ],
                                                                        ...field,
                                                                    };
                                                                } else {
                                                                    DATA.push(
                                                                        field
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    }
                                                );

                                                return acc;
                                            };

                                            const sumWithInitial =
                                                UPDATE_DATA.reduce(
                                                    (acc: any, cur: any) =>
                                                        update(acc, cur),
                                                    {}
                                                );

                                            const SUB_FORM = {
                                                ...data.subFormData[
                                                    elementLayoutDefinition.name
                                                ],
                                            };

                                            Object.keys(sumWithInitial).forEach(
                                                (rowIndex) => {
                                                    const ROW_INFO =
                                                        sumWithInitial[
                                                            rowIndex
                                                        ];

                                                    ROW_INFO.forEach(
                                                        (updateItem: any) => {
                                                            const itemToUpdateIndex =
                                                                SUB_FORM[
                                                                    rowIndex
                                                                ].findIndex(
                                                                    (
                                                                        subFormDataItem: any
                                                                    ) =>
                                                                        subFormDataItem.name ===
                                                                        updateItem.name
                                                                );

                                                            const SUB_FORM_ARRAY =
                                                                [
                                                                    ...SUB_FORM[
                                                                        rowIndex
                                                                    ],
                                                                ];

                                                            SUB_FORM_ARRAY[
                                                                itemToUpdateIndex
                                                            ] = {
                                                                ...SUB_FORM_ARRAY[
                                                                    itemToUpdateIndex
                                                                ],
                                                                ...(updateItem
                                                                    .options
                                                                    ?.length >
                                                                    0 && {
                                                                    options:
                                                                        updateItem.options,
                                                                }),
                                                                isHidden:
                                                                    updateItem.isHidden,
                                                                isDisabled:
                                                                    updateItem.isDisabled,
                                                                isRequired:
                                                                    updateItem.isRequired,
                                                            };

                                                            SUB_FORM[rowIndex] =
                                                                SUB_FORM_ARRAY;

                                                            data.subFormData = {
                                                                ...data.subFormData,
                                                                [elementLayoutDefinition.name]:
                                                                    SUB_FORM,
                                                            };
                                                        }
                                                    );
                                                }
                                            );

                                            result = {
                                                ...result,
                                            };
                                        }

                                        if (!updateField?.children) {
                                            result = {
                                                ...result,
                                                ...(updateField.options
                                                    ?.length > 0 && {
                                                    options:
                                                        updateField.options,
                                                }),
                                                isHidden: updateField.isHidden,
                                                isDisabled:
                                                    updateField.isDisabled,
                                                isRequired:
                                                    updateField.isRequired,
                                            };
                                        }
                                    }
                                }

                                if (actionSources?.updateLabels) {
                                    const updateLabelIndex =
                                        actionSources.updateLabels
                                            .map((field: any) => field.name)
                                            .lastIndexOf(
                                                elementLayoutDefinition.name
                                            );
                                    if (updateLabelIndex !== -1) {
                                        const updateLabel =
                                            actionSources?.updateLabels[
                                                updateLabelIndex
                                            ];
                                        result = {
                                            ...result,
                                            label: updateLabel.label,
                                        };
                                    }
                                }

                                return result;
                            }
                        ),
                    ...(updateSection && { ...updateSection }),
                };
            }
        );

        return data;
    }

    getActionSources(
        conditions: FormConditions | undefined,
        formFunctionResults: FormFunctionResult[],
        formData: FormDataResult,
        layoutAndDefinitions?: FormSectionLayoutDefinition[]
    ): any {
        const updateFields = formFunctionResults.reduce(
            (prev: any[], current: any) => {
                const fields = Object.values(current?.schemaChanges).map(
                    (field: any) => {
                        return {
                            name: field.name,
                            isRequired: field.isRequired || false,
                            options: field.options || [],
                        };
                    }
                );
                return [...prev, ...fields];
            },
            []
        );

        const baseActions = {
            updateFields,
            updateLabels: [],
            updateSections: [],
        };

        if (!formData) {
            return baseActions;
        }

        const { data, context } = formData;

        return conditions?.triggers?.reduce(
            (prev: any, current: any) => {
                const USE_CONTEXT =
                    this.formTriggersService.useContext(current);

                const NAMES = current.field.split('.');

                const fieldValue =
                    USE_CONTEXT && context
                        ? this.formTriggersService.getContextTriggerValue(
                              current,
                              context
                          )
                        : NAMES.length > 1
                        ? data[NAMES[0]]
                        : data[current.field];

                return {
                    updateFields: [
                        ...prev.updateFields,
                        ...this.getActions(
                            current,
                            fieldValue,
                            'updateFields',
                            layoutAndDefinitions
                        ),
                    ],
                    updateLabels: [
                        ...prev.updateLabels,
                        ...this.getActions(current, fieldValue, 'updateLabels'),
                    ],
                    updateSections: [
                        ...prev.updateSections,
                        ...this.getActions(
                            current,
                            fieldValue,
                            'updateSections'
                        ),
                    ],
                };
            },
            { ...baseActions }
        );
    }

    getActions(
        current: Trigger,
        fieldValue: any,
        action: keyof Action,
        layoutAndDefinitions?: FormSectionLayoutDefinition[]
    ) {
        const NAMES = current?.field?.split('.');
        let data = [];
        const children: any[] = [];

        if (
            action !== 'updateSections' &&
            NAMES &&
            NAMES?.length > 1 &&
            Array.isArray(fieldValue) &&
            fieldValue?.length
        ) {
            const FIRST_NAME = NAMES[0];
            const SECOND_NAME = NAMES[1];
            let elementLayoutInfo: FormElementLayoutDefinition | undefined;

            layoutAndDefinitions?.forEach((layoutAndDefinition) => {
                layoutAndDefinition.elementLayoutDefinitions?.forEach(
                    (elementLayoutDefinition) => {
                        if (FIRST_NAME === elementLayoutDefinition.name) {
                            elementLayoutInfo = elementLayoutDefinition;
                        }
                    }
                );
            });

            fieldValue?.map((item, index) => {
                const child_data = this.checkTriggerStateToUse(
                    current,
                    item[SECOND_NAME],
                    action
                );

                child_data.forEach((child: any) => {
                    if (child?.name) {
                        const UPDATE_FIELD_NAME = child.name.split('.');

                        child = {
                            ...child,
                            name: UPDATE_FIELD_NAME[1],
                        };

                        (children[index] || (children[index] = [])).push(child);
                    }
                });
            });

            if (elementLayoutInfo) {
                data.push({
                    ...elementLayoutInfo,
                    name: FIRST_NAME ?? elementLayoutInfo.name,
                    children,
                });
            }
        } else {
            data = this.checkTriggerStateToUse(current, fieldValue, action);
        }

        return data;
    }

    checkTriggerStateToUse(
        current: Trigger,
        fieldValue: any,
        action: keyof Action
    ): any[] {
        if (!current?.is) {
            return [];
        }

        return current.is.reduce((_: any[], is) => {
            const isValid = this.formTriggersService.is(is, fieldValue);
            const thenAction = is.then?.[action] ?? [];
            const elseAction = is.else?.[action] ?? [];
            return isValid ? [..._, ...thenAction] : [..._, ...elseAction];
        }, []);
    }

    getFormData(
        formId: string,
        entityId: string,
        definition: FormDefinition,
        customEndpoint?: string,
        appId?: string
    ) {
        if (
            this.formSummaryHistoricService.loadHistoricData &&
            this.formSummaryHistoricService.formUuid
        ) {
            return this.dynamicFormsService.getAppFormDataHistorical(
                formId,
                entityId,
                this.formSummaryHistoricService.formUuid
            );
        }

        if (customEndpoint) {
            return this.dynamicFormsService.getFormDataForCustomEndpoint(
                customEndpoint
            );
        }

        return appId
            ? this.dynamicFormsService.getAppFormData(appId)
            : this.dynamicFormsService.getFormData(
                  formId,
                  entityId,
                  definition.endpointPath
              );
    }

    getInitialisationFunctions(
        formId: string,
        definition: FormDefinition,
        formData: any
    ) {
        if (definition.conditions?.Initialisation?.functions?.length) {
            return forkJoin(
                definition.conditions?.Initialisation?.functions?.map((fn) =>
                    this.getApiFunctionTrigger(
                        formId,
                        fn.name as string,
                        formData.data
                    )
                )
            ).pipe(
                map((formFunctionResults: FormFunctionResult[]) => ({
                    formData,
                    formFunctionResults: formFunctionResults.filter(
                        (formFunctionResult) => Boolean(formFunctionResult)
                    ),
                }))
            );
        }
        return of({ formData, formFunctionResults: [] });
    }

    getApiFunctionTrigger(
        formId: string,
        functionName: string,
        formData: any
    ): Observable<FormFunctionResult> {
        return this.dynamicFormsService.getFormFunctionResult(formId, {
            functionName,
            formData,
        });
    }

    getStringValue(
        fieldValue: any,
        fieldDefinition: FieldDefinition,
        options?: FormSummaryValueOptions,
        parentData?: any,
        formSchema?: FieldDefinition[],
        getAuditValue?: boolean
    ): Observable<string | WdxAuditValue | null> {
        const stringValue$ = () =>
            this.getStringValue(
                fieldValue,
                fieldDefinition,
                options,
                parentData,
                formSchema,
                false
            ) as Observable<string | null>;
        const stringValueAsLabel$ = () =>
            stringValue$().pipe(
                map((label) => ({ label: label ?? '', value: fieldValue }))
            );

        if (!fieldDefinition) {
            return of(null);
        }

        if (TEXT_TYPES.includes(fieldDefinition.fieldType as FormFieldType)) {
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            return of(fieldValue);
        }

        if (
            DECIMAL_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            return this.dynamicDataService
                .getMeLocale()
                .pipe(
                    map((locale) =>
                        this.wdxNumberService.formatDecimal(
                            fieldValue,
                            locale.name as string
                        )
                    )
                );
        }

        if (
            INTEGER_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            return this.dynamicDataService
                .getMeLocale()
                .pipe(
                    map((locale) =>
                        this.wdxNumberService.formatNumber(
                            fieldValue,
                            locale.name
                        )
                    )
                );
        }

        if (
            CURRENCY_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            return this.dynamicDataService.getMeLocale().pipe(
                map((locale) =>
                    this.wdxNumberService.formatCurrency(fieldValue?.amount, {
                        locale: locale.name,
                        currencyCode: fieldValue?.isoCode,
                        groupSeparator:
                            locale.numberFormat?.numberGroupSeparator,
                        decimalSeparator:
                            locale.numberFormat?.numberDecimalSeparator,
                    })
                )
            );
        }

        if (
            PHONE_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            if (!fieldValue) {
                return of(null);
            }
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            return this.telephoneService.fieldValueToTelephonePattern$(
                fieldValue
            );
        }

        if (
            DATE_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            return this.dynamicDataService.getMeLocale().pipe(
                map((locale) =>
                    this.wdxDateTimeService.convertDateToViewFriendlyFormat(
                        fieldValue,
                        {
                            format:
                                fieldDefinition.fieldType ===
                                FormFieldType.DateTime
                                    ? WdxDateFormat.AbsoluteDateTime
                                    : WdxDateFormat.AbsoluteDate,

                            locale: locale.name,
                        }
                    )
                )
            );
        }

        if (
            DATE_RANGE_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            if (getAuditValue) {
                return stringValue$().pipe(
                    map((label) => ({
                        label: label || '',
                        badge: fieldValue?.isAllDay && {
                            label: 'All day',
                        },
                        value: fieldValue,
                    }))
                );
            }

            const start = this.dynamicDataService.getMeLocale().pipe(
                map((locale) =>
                    this.wdxDateTimeService.convertDateToViewFriendlyFormat(
                        fieldValue?.start,
                        {
                            format:
                                fieldDefinition.fieldType ===
                                FormFieldType.DateTimeRange
                                    ? WdxDateFormat.AbsoluteDateTime
                                    : WdxDateFormat.AbsoluteDate,

                            locale: locale.name,
                        }
                    )
                )
            );

            const end = this.dynamicDataService.getMeLocale().pipe(
                map((locale) =>
                    this.wdxDateTimeService.convertDateToViewFriendlyFormat(
                        fieldValue?.end,
                        {
                            format:
                                fieldDefinition.fieldType ===
                                FormFieldType.DateTimeRange
                                    ? WdxDateFormat.AbsoluteDateTime
                                    : WdxDateFormat.AbsoluteDate,

                            locale: locale.name,
                        }
                    )
                )
            );

            return combineLatest([start, end]).pipe(
                map(([start, end]) => (end ? `${start} - ${end}` : start))
            );
        }

        if (
            OPTIONS_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            return this.getOptionsValue(
                fieldValue,
                fieldDefinition,
                options,
                parentData,
                formSchema
            ) as Observable<string>;
        }

        if (
            LOOKUP_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            const fieldIsArray = Array.isArray(fieldValue);
            if (getAuditValue) {
                return stringValue$().pipe(
                    map((label) => ({
                        label: label || '',
                        subtitle: fieldIsArray
                            ? fieldValue[0]?.description ??
                              fieldValue[0]?.lookupType
                            : fieldValue?.description ?? fieldValue?.lookupType,
                        value: fieldValue,
                    }))
                );
            }
            return of(fieldIsArray ? fieldValue[0]?.name : fieldValue?.name);
        }

        if (
            ADDRESS_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            const address =
                fieldValue && Object.keys(fieldValue).length
                    ? fieldValue
                    : parentData?.postalAddresses?.find(
                          (address: any) =>
                              address.addressType &&
                              address.addressType ===
                                  options?.formData?.addressType
                      )?.address;
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            return this.addressFormatService
                .getAddress$(address, true)
                .pipe(map((item) => item.join(', ')));
        }

        if (fieldDefinition.fieldType === FormFieldType.Boolean) {
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            return of(fieldValue ? 'Checked' : '');
        }

        /**
         * Rich text fields will either be markdown (as saved from rich text ui) or html (for example email content)
         * If HTML is returned strip out images
         */
        if (
            RICH_TEXT_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            if (getAuditValue) {
                return stringValueAsLabel$();
            }
            const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
            return isHTML(fieldValue)
                ? of(fieldValue.replace(/<img[^>]*>/g, ''))
                : of(new showdown.Converter().makeHtml(fieldValue));
        }

        if (getAuditValue) {
            return stringValueAsLabel$();
        }
        return of(fieldValue);
    }

    /**
     * Returns string value from an Option definition
     */
    getOptionsValue(
        fieldValue: any,
        fieldDefinition: FieldDefinition,
        options?: FormSummaryValueOptions,
        parentData?: any,
        formSchema?: FieldDefinition[]
    ): Observable<string | undefined> {
        if (fieldDefinition.selectSource) {
            const contextualData =
                this.formContextualDataService.getContextualData(
                    fieldDefinition.contextualValues as string[],
                    options?.formData,
                    parentData,
                    formSchema,
                    fieldDefinition.contextualType
                );

            let optionsProvider: Observable<ExtendedFieldDefinitionOption[]>;

            if (
                contextualData.lookups?.length ||
                contextualData.context?.length
            ) {
                const { context, lookups } = contextualData;
                optionsProvider =
                    this.dynamicDataService.getContextSelectApiSourceOptions(
                        fieldDefinition.selectSource,
                        lookups,
                        context
                    );
            } else {
                const contextualValues =
                    this.formContextualDataService.getContextualResults(
                        contextualData
                    );
                optionsProvider =
                    this.dynamicDataService.getSelectApiSourceOptions(
                        fieldDefinition.selectSource,
                        {
                            ...(contextualValues?.length > 0 && {
                                parent: contextualValues.join(','),
                            }),
                        }
                    );
            }

            return optionsProvider.pipe(
                map((fieldOptions) => {
                    if (Array.isArray(fieldValue)) {
                        return fieldOptions
                            .filter((option) =>
                                fieldValue
                                    .map((fieldValue) =>
                                        fieldValue.toLowerCase()
                                    )
                                    .includes(option.value?.toLowerCase())
                            )
                            .map((options) => options.label)
                            .join(', ');
                    }
                    const matchedOption = fieldOptions.find((option) =>
                        this.dynamicDataService.compareOptionValue(
                            option.value,
                            fieldValue
                        )
                    );
                    if (matchedOption?.label) {
                        if (
                            fieldDefinition.fieldType ===
                                FormFieldType.Hierarchical &&
                            matchedOption?.value
                        ) {
                            return `${matchedOption.value} - ${matchedOption.label}`;
                        }
                        return matchedOption.label;
                    }
                    return;
                })
            );
        }

        if (fieldDefinition.options?.length) {
            const fieldValueArray = Array.isArray(fieldValue)
                ? fieldValue
                : [fieldValue];
            return of(
                fieldValueArray
                    .map(
                        (value) =>
                            fieldDefinition.options?.find((option) =>
                                this.dynamicDataService.compareOptionValue(
                                    option.value,
                                    value
                                )
                            )?.label
                    )
                    .join(', ')
            );
        }

        return of(new TitleCasePipe().transform(fieldValue));
    }

    /**
     *
     * @param {SubFormHeaderSubLevelType} subtitles - These are the sub titles to be used
     * @param {any} fieldValue - This is the address control value
     * @param {FieldDefinition} fieldDefinition - This is the FieldDefinition
     * @param {string} fieldName - This is the name of the FieldDefinition
     *
     * @returns {SubFormHeaderSubLevelType}
     */
    overrideSubTitle(
        subtitles: SubFormHeaderSubLevelType,
        fieldValue: any,
        fieldDefinition: FieldDefinition,
        fieldName: string
    ): SubFormHeaderSubLevelType {
        const IS_ADDRESS = ADDRESS_SUMMARY_TYPES.includes(
            fieldDefinition?.fieldType as FormFieldType
        );
        let addressType;

        if (IS_ADDRESS) {
            addressType = fieldDefinition?.options?.find(
                (option) => option.value === fieldValue[fieldName]
            );
        }

        if (!IS_ADDRESS || !addressType) {
            return subtitles;
        }

        const SUB_TITLE = [...subtitles];
        SUB_TITLE[0] = addressType?.label as string;

        return SUB_TITLE as SubFormHeaderSubLevelType;
    }
}
