<ng-container
    *ngIf="
        formElement?.elementType === FORM_ELEMENT_TYPE.Field &&
        (formElement?.fieldType === FORM_FIELD_TYPE.DateRange ||
            formElement?.fieldType === FORM_FIELD_TYPE.DateTimeRange)
    "
>
    <ng-container [formGroup]="$any(controlContainer?.control)">
        <wdx-form-field
            [label]="formElement?.label | translateTokenisedString"
            [guidanceText]="
                formElement?.guidanceText | translateTokenisedString
            "
            [isSensitive]="formElement?.isSensitive"
            [isRequired]="formElement?.isRequired"
            [validationErrorMessage]="
                formControl?.touched
                    ? (formControl?.errors
                      | validationErrorMessage : formElement)
                    : ''
            "
            [warningMessage]="warningText"
            *ngIf="hideEnd !== undefined"
        >
            <div
                [class.grid]="!hideEnd"
                [wdxInputDateRange]="{ hideEnd }"
                [formGroupName]="$any(formElement?.name)"
                *ngIf="
                    formElement?.fieldType === FORM_FIELD_TYPE.DateRange
                        ? 'date'
                        : 'datetime-local' as type
                "
            >
                <div
                    class="input-date"
                    ngClass="hideEnd ? '' : 'g-col-12 g-col-sm-6'"
                >
                    <div class="input-wrapper">
                        <div class="input-wrapper__content ms-0">
                            <input
                                wdxInputText
                                wdxInputDate
                                [attr.type]="type"
                                formControlName="start"
                                [attr.data-cy]="
                                    formElement?.fieldType ===
                                    FORM_FIELD_TYPE.DateRange
                                        ? 'input-date-start'
                                        : 'input-datetime-start'
                                "
                                max="9999-12-31"
                                [attr.required]="formElement.isRequired"
                                [wdxUtilsDisabled]="
                                    isDisabled ||
                                    PENDING_CHANGES_LOCK_FIELD.includes(
                                        $any(formElement.pendingChange?.status)
                                    )
                                "
                            />
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="!hideEnd"
                    class="input-date"
                    ngClass="hideEnd ? '' : 'g-col-12 g-col-sm-6'"
                >
                    <div class="input-wrapper">
                        <div class="input-wrapper__content ms-0">
                            <input
                                wdxInputText
                                wdxInputDate
                                [attr.type]="type"
                                formControlName="end"
                                [attr.data-cy]="
                                    formElement?.fieldType ===
                                    FORM_FIELD_TYPE.DateRange
                                        ? 'input-date-end'
                                        : 'input-datetime-end'
                                "
                                max="9999-12-31"
                                [attr.required]="formElement.isRequired"
                                [wdxUtilsDisabled]="
                                    isDisabled ||
                                    PENDING_CHANGES_LOCK_FIELD.includes(
                                        $any(formElement.pendingChange?.status)
                                    )
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </wdx-form-field>
    </ng-container>
</ng-container>
