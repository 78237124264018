import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[wdxListGroup]',
})
export class ListGroupDirective {
    @Input() wdxListGroupFlush = false;

    @HostBinding('class') hostClasses = 'list-group';
    @HostBinding('class.list-group-flush') get flushClass() {
        return this.wdxListGroupFlush;
    }
}
