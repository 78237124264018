import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    patch(slug: string[]): Observable<any> {
        return this.http.patch<any>(
            `${this.config.getConfiguration().API_BASE}/${slug.join('/')}`,
            {}
        );
    }
}
