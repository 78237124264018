import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeFilterBarModule } from '../../../components/molecules/molecule-filter-bar/molecule-filter-bar.module';
import { MoleculeSearchAndFilterBarModule } from '../../../components/molecules/molecule-search-and-filter-bar/molecule-search-and-filter-bar.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { MoleculeDataListWrapperComponent } from './molecule-data-list-wrapper.component';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeFilterBarModule,
        MoleculeSearchAndFilterBarModule,
        OrganismModalModule,
    ],
    declarations: [MoleculeDataListWrapperComponent],
    exports: [MoleculeDataListWrapperComponent],
})
export class MoleculeDataListWrapperModule {}
