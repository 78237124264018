import { CompletedStatusType, SlaStatusType } from '@wdx/clmi/api-models';
import {
    Highlight,
    KeyValueObject,
    Severity,
    WdxThemeColor,
} from '@wdx/shared/utils';
import { NULL, UNDEFINED } from '../constants/null.constants';

export const SLA_STATUS_HIGHLIGHT_MAP = {
    [NULL]: Highlight.Info,
    [UNDEFINED]: Highlight.Info,
    [SlaStatusType.Approaching]: Highlight.Warning,
    [SlaStatusType.Breached]: Highlight.Danger,
    [SlaStatusType.Within]: Highlight.Info,
    [SlaStatusType.None]: Highlight.Info,
};

export const SLA_STATUS_THEME_MAP: KeyValueObject<WdxThemeColor> = {
    [NULL]: 'info',
    [UNDEFINED]: 'info',
    [SlaStatusType.Approaching]: 'warning',
    [SlaStatusType.Breached]: 'danger',
    [SlaStatusType.Within]: 'info',
    [SlaStatusType.None]: 'info',
};

export const COMPLETED_STATUS_SEVERITY_MAP = {
    [NULL]: Severity.None,
    [UNDEFINED]: Severity.None,
    [CompletedStatusType.Cancelled]: Severity.None,
    [CompletedStatusType.CompletedOnTime]: Severity.Success,
    [CompletedStatusType.CompletedOverdue]: Severity.Success,
    [CompletedStatusType.DueLater]: Severity.Info,
    [CompletedStatusType.DueSoon]: Severity.Warning,
    [CompletedStatusType.HasIssue]: Severity.Danger,
    [CompletedStatusType.Inactive]: Severity.None,
    [CompletedStatusType.NoDeadline]: Severity.Info,
    [CompletedStatusType.Overdue]: Severity.Danger,
};

export const COMPLETED_STATUS_ICON_MAP = {
    [NULL]: Severity.None,
    [UNDEFINED]: Severity.None,
    [CompletedStatusType.Cancelled]: 'times-circle',
    [CompletedStatusType.CompletedOnTime]: 'check-circle',
    [CompletedStatusType.CompletedOverdue]: 'check-circle',
    [CompletedStatusType.DueLater]: 'circle',
    [CompletedStatusType.DueSoon]: 'circle',
    [CompletedStatusType.HasIssue]: 'circle',
    [CompletedStatusType.Inactive]: 'circle',
    [CompletedStatusType.NoDeadline]: 'check-circle',
    [CompletedStatusType.Overdue]: 'circle',
};

export const CASE_COMPLETED_STATUS_ICON_MAP = {
    [NULL]: Severity.None,
    [UNDEFINED]: Severity.None,
    [CompletedStatusType.Cancelled]: 'times-circle',
    [CompletedStatusType.CompletedOnTime]: 'check-circle',
    [CompletedStatusType.CompletedOverdue]: 'check-circle',
    [CompletedStatusType.DueLater]: 'alarm-clock',
    [CompletedStatusType.DueSoon]: 'alarm-clock',
    [CompletedStatusType.HasIssue]: 'alarm-clock',
    [CompletedStatusType.Inactive]: 'alarm-clock',
    [CompletedStatusType.NoDeadline]: 'check-circle',
    [CompletedStatusType.Overdue]: 'alarm-clock',
};
