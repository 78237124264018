import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SignalRService } from '../../services/signalr.service';
import * as rootReducer from './../../state/_setup/reducers';
import * as queuesActions from './queues.actions';
import { QueuesService } from './queues.service';

@Injectable()
export class QueuesEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private queuesService = inject(QueuesService);
    private signalRService = inject(SignalRService);

    assign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(queuesActions.assign),
            mergeMap((action) =>
                this.queuesService
                    .assign(action.entityType, action.entityId, action.partyId)
                    .pipe(
                        map(() =>
                            queuesActions.assignSuccess({
                                entityType: action.entityType,
                                entityId: action.entityId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                queuesActions.assignFailure({
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
