import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccessVisibility, SystemEntity } from '@wdx/clmi/api-models';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as activitiesActions from '../../../../../state/activities/activities.actions';
import * as activitiesSelectors from '../../../../../state/activities/activities.selectors';
import * as casesActions from '../../../../../state/cases/cases.actions';
import * as casesSelectors from '../../../../../state/cases/cases.selectors';
import * as clientProductsActions from '../../../../../state/client-products/client-products.actions';
import * as clientProductsSelectors from '../../../../../state/client-products/client-products.selectors';
import * as opportunitiesActions from '../../../../../state/opportunities/opportunities.actions';
import * as opportunitiesSelectors from '../../../../../state/opportunities/opportunities.selectors';
import * as partiesActions from '../../../../../state/parties/parties.actions';
import * as partiesSelectors from '../../../../../state/parties/parties.selectors';
import * as partyRoleActions from '../../../../../state/party-role/party-role.actions';
import * as partyRoleSelectors from '../../../../../state/party-role/party-role.selectors';

@Injectable({
    providedIn: 'root',
})
export class AccessVisibilityFacade extends WdxDestroyClass {
    accessVisibility$ = new Subject<AccessVisibility[]>();
    isLoading$ = new Subject<boolean>();
    hasError$ = new Subject<boolean>();

    private _accessVisibility$: Observable<AccessVisibility[]>;
    private _isLoading$: Observable<boolean>;
    private _hasError$: Observable<boolean>;

    constructor(private store$: Store) {
        super();
    }

    getAccessVisibility(id: string, entityType: SystemEntity): void {
        switch (entityType) {
            case SystemEntity.Activity:
                this.getForActivity(id);
                break;
            case SystemEntity.Case:
                this.getForCase(id);
                break;
            case SystemEntity.BankAccount:
            case SystemEntity.ClientProduct:
            case SystemEntity.InvestmentAccount:
            case SystemEntity.Portfolio:
                this.getForClientProduct(id);
                break;
            case SystemEntity.Opportunity:
                this.getForOpportunity(id);
                break;
            case SystemEntity.Organisation:
            case SystemEntity.Party:
            case SystemEntity.Person:
                this.getForParty(id);
                break;
            case SystemEntity.Adviser:
            case SystemEntity.Client:
            case SystemEntity.Intermediary:
            case SystemEntity.Lead:
            case SystemEntity.PartyRole:
                this.getForPartyRole(id);
                break;
            default:
                console.warn(
                    `Visibility modal for ${entityType} has not yet been implemented`
                );
        }
        this.update();
    }

    private update(): void {
        this._accessVisibility$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((action) => {
                this.accessVisibility$.next(action);
            });
        this._isLoading$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((action) => {
                this.isLoading$.next(action);
            });
        this._hasError$.pipe(takeUntil(this.destroyed$)).subscribe((action) => {
            this.hasError$.next(action);
        });
    }

    private getForActivity(id: string): void {
        this._accessVisibility$ = this.store$.select(
            activitiesSelectors.getVisibilityForId,
            { id }
        );
        this._isLoading$ = this.store$.select(
            activitiesSelectors.getVisibilityForIdIsLoadingList,
            { id }
        );
        this._hasError$ = this.store$.select(
            activitiesSelectors.getVisibilityForIdHasLoadingListError,
            { id }
        );
        this.store$.dispatch(activitiesActions.getVisibilityForId({ id }));
    }

    private getForCase(id: string): void {
        this._accessVisibility$ = this.store$.select(
            casesSelectors.getVisibilityForId,
            { id }
        );
        this._isLoading$ = this.store$.select(
            casesSelectors.getVisibilityForIdIsLoadingList,
            { id }
        );
        this._hasError$ = this.store$.select(
            casesSelectors.getVisibilityForIdHasLoadingListError,
            { id }
        );
        this.store$.dispatch(casesActions.getVisibilityForId({ id }));
    }

    private getForClientProduct(id: string): void {
        this._accessVisibility$ = this.store$.select(
            clientProductsSelectors.getVisibilityForId,
            { id }
        );
        this._isLoading$ = this.store$.select(
            clientProductsSelectors.getVisibilityForIdIsLoadingList,
            { id }
        );
        this._hasError$ = this.store$.select(
            clientProductsSelectors.getVisibilityForIdHasLoadingListError,
            { id }
        );
        this.store$.dispatch(clientProductsActions.getVisibilityForId({ id }));
    }

    private getForOpportunity(id: string): void {
        this._accessVisibility$ = this.store$.select(
            opportunitiesSelectors.getVisibilityForId,
            { id }
        );
        this._isLoading$ = this.store$.select(
            opportunitiesSelectors.getVisibilityForIdIsLoadingList,
            { id }
        );
        this._hasError$ = this.store$.select(
            opportunitiesSelectors.getVisibilityForIdHasLoadingListError,
            { id }
        );
        this.store$.dispatch(
            opportunitiesActions.getVisibilityForId({
                id,
            })
        );
    }

    private getForParty(id: string): void {
        this._accessVisibility$ = this.store$.select(
            partiesSelectors.getVisibilityForParty,
            { id }
        );
        this._isLoading$ = this.store$.select(
            partiesSelectors.getVisibilityForPartyIsLoadingList,
            { id }
        );
        this._hasError$ = this.store$.select(
            partiesSelectors.getVisibilityForPartyHasLoadingListError,
            { id }
        );
        this.store$.dispatch(
            partiesActions.getVisibilityForParty({
                partyId: id,
            })
        );
    }

    private getForPartyRole(id: string): void {
        this._accessVisibility$ = this.store$.select(
            partyRoleSelectors.getVisibilityForId,
            { id }
        );
        this._isLoading$ = this.store$.select(
            partyRoleSelectors.getVisibilityForIdIsLoadingList,
            { id }
        );
        this._hasError$ = this.store$.select(
            partyRoleSelectors.getVisibilityForIdHasLoadingListError,
            { id }
        );
        this.store$.dispatch(partyRoleActions.getVisibilityForId({ id }));
    }
}
