import { Location } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import {
    LookupSourceType,
    PartyAvatarInfo,
    PartyType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { Avatar, AvatarInfo } from '@wdx/clmi/utils/models';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import {
    DisplayDatePipe,
    MEETING_FORM_ID,
    PERSON_FORM_ID,
    PHONECALL_FORM_ID,
    STATUS_THEMECOLOR_MAP,
    TitleCasePipe,
    TranslationsService,
} from '@wdx/shared/utils';
import { TASK_BUTTON } from '../../../../../constants/activity.constants';
import {
    ICON_APPOINTMENT,
    ICON_PHONECALL,
} from '../../../../../constants/icons.constants';
import { ActionButton } from '../../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../../models/action-buttons-mode.model';
import { ButtonType } from '../../../../../models/button-type.model';
import { Button } from '../../../../../models/button.model';
import { EntityQueryParamPipe } from '../../../../../pipes/entity-query-param.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-avatar-info-dumb',
    templateUrl: './molecule-avatar-info-dumb.component.html',
})
export class MoleculeAvatarInfoDumbComponent {
    @Input() avatar: Avatar;
    @Input() partyAvatarInfo: PartyAvatarInfo;
    @Input() partyAvatarInfoIsLoading: boolean;
    @Input() partyAvatarInfoHasLoadingError: boolean;
    @Input() editModalId: string;

    addUnknownActionButton: ActionButton = {
        mode: ActionButtonMode.AddButton,
        formSetup: {
            formId: PERSON_FORM_ID,
        },
    };

    readonly PARTY_TYPE = PartyType;
    readonly LookupSourceType = LookupSourceType;
    readonly STATUS_THEMECOLOR_MAP = STATUS_THEMECOLOR_MAP;

    protected entityRouteService = inject(EntityRouteService);
    protected translationsService = inject(TranslationsService);

    constructor(public location: Location) {}

    getAvatarInfo(
        avatar: Avatar,
        partyAvatarInfo: PartyAvatarInfo,
        location: Location,
        entityRouteService: EntityRouteService
    ): AvatarInfo {
        if (avatar.partyType || avatar.entityType) {
            return {
                name: partyAvatarInfo?.name,
                status: partyAvatarInfo?.status,
                info: avatar.partyType && getAvatarInfoStatus(partyAvatarInfo),
                phones: partyAvatarInfo?.phoneAddresses?.map(
                    (phone) => phone.name
                ),
                emails: partyAvatarInfo?.emailAddresses?.map(
                    (email) => email.name
                ),
                routerLink: entityRouteService.routeForEntity(
                    SystemEntity[partyAvatarInfo?.partyType],
                    partyAvatarInfo?.id
                ),
                queryParams: new EntityQueryParamPipe().transform(
                    SystemEntity[partyAvatarInfo?.partyType]
                ),
            };
        }

        const entity = avatar.lookupType && SystemEntity[avatar.lookupType];
        const ROUTE =
            entity &&
            entityRouteService.routeForEntity(entity, avatar?.partyId);

        return {
            name: avatar.text,
            info: avatar?.lookupType ? avatar?.lookupType : SystemEntity.Client,
            routerLink: ROUTE,
        };

        function getAvatarInfoStatus(partyAvatarInfo: PartyAvatarInfo): string {
            switch (partyAvatarInfo?.partyType) {
                case PartyType.Person:
                case PartyType.Organisation:
                    return partyAvatarInfo.partyType;
                case PartyType.User:
                    return partyAvatarInfo.jobTitle;
                case PartyType.Construct:
                    return [
                        'Joint',
                        new TitleCasePipe()
                            .transform(
                                partyAvatarInfo?.clientStatus?.clientStatus
                            )
                            .toLowerCase(),
                        ...(partyAvatarInfo?.clientStatus?.inceptionDate
                            ? ['since']
                            : []),
                        new DisplayDatePipe().transform(
                            partyAvatarInfo?.clientStatus?.inceptionDate,
                            { format: 'yyyy' }
                        ),
                    ].join(' ');
                case PartyType.Team:
                    return undefined;
                default:
                    return 'Prospect';
            }
        }
    }

    avatarAsAvatarButtons(avatar: Avatar): Button[] {
        let FILE = 'fileUnderPartyId';

        if (!avatar.partyType) {
            FILE = 'fileUnderId';
        }

        const CALL = {
            label: 'Call',
            icon: ICON_PHONECALL.icon,
            type: ButtonType.icon,
            formSetup: {
                formId: PHONECALL_FORM_ID,
                initialisationParams: {
                    partyId: avatar?.formPartyId,
                },
            },
        };

        const MEETING = {
            label: 'Appointment',
            icon: ICON_APPOINTMENT.icon,
            type: ButtonType.icon,
            formSetup: {
                formId: MEETING_FORM_ID,
                initialisationParams: {
                    partyId: avatar?.formPartyId,
                },
            },
        };

        const TASK = {
            ...TASK_BUTTON,
            formSetup: {
                ...TASK_BUTTON.formSetup,
                initialisationParams: {
                    [FILE]: avatar?.partyId,
                },
            },
            tooltip: this.translationsService.getTranslationByKey(
                'CREATE_TASK_TOOLTIP'
            ),
        };

        if (avatar?.fileUnderId) {
            CALL.formSetup.initialisationParams[FILE] = avatar?.fileUnderId;
            MEETING.formSetup.initialisationParams[FILE] = avatar?.fileUnderId;
        }

        return [CALL, MEETING, TASK];
    }
}
