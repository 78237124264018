<atom-async-data [isLoading]="isCreating$ | ngrxPush" [overlayContent]="true">
    <div class="d-flex align-items-center justify-content-between mb-3">
        <h6>
            <span
                [class.selectable-comments]="showIssues"
                (click)="showAll()"
                >{{ count | i18nPlural : commentCount }}</span
            >

            <span *ngIf="issueCountMessage">
                |
                <span
                    class="text-danger"
                    [class.selectable-comments]="!showIssues"
                    (click)="showOnlyIssues()"
                    >{{ issueCountMessage }}</span
                ></span
            >
        </h6>

        <wdx-icon-button
            wdxStyle="primary"
            size="sm"
            data-cy="btn-add-comment"
            [disabled]="addNewComment$ | ngrxPush"
            (wdxOnClick)="toggleComments(true)"
        >
            <wdx-icon [icon]="ICON_ADD"></wdx-icon>
        </wdx-icon-button>
    </div>

    <ng-container *ngIf="addNewComment$ | ngrxPush as addNewComment">
        <clmi-comment-card
            [stylingConfig]="{
                inverted: facade.invertedStyling,
                inputCard: true
            }"
            class="d-block mb-2"
        >
            <ng-container *ngIf="me$ | ngrxPush as me">
                <atom-avatar
                    class="rounded-circle border border-width-md border-gray-200 d-inline-block me-1"
                    size="md"
                    [avatar]="me | partyToAvatar"
                ></atom-avatar>

                <h6>{{ me?.name }}</h6>
            </ng-container>

            <wdx-icon-button action (wdxOnClick)="reset()">
                <wdx-icon icon="xmark"></wdx-icon>
            </wdx-icon-button>

            <div comments class="position-relative">
                <clmi-comment-input-box
                    [dynamicHeight]="dynamicHeight"
                    [placeholder]="placeholder"
                    [enableAppMentions]="isWorkItem"
                    [commentOverride]="commentOverride"
                    (commentUpdated)="commentUpdated($event)"
                ></clmi-comment-input-box>
            </div>

            <div
                meta
                class="d-flex align-items-center mt-2"
                [class.justify-content-end]="resolveIssueId"
                [class.justify-content-between]="!resolveIssueId"
            >
                <div *ngIf="!resolveIssueId" class="issue-check">
                    <wdx-checkbox
                        data-cy="chk-report-issue"
                        label="Report Issue"
                        [checked]="reportIssue"
                        (isChecked)="reportIssue = $event"
                    ></wdx-checkbox>
                </div>

                <wdx-icon-button
                    data-cy="btn-send-comment"
                    [disabled]="!commentCreate?.content"
                    (wdxOnClick)="onCommentAdded()"
                >
                    <wdx-icon icon="paper-plane" iconStyle="fas"></wdx-icon>
                </wdx-icon-button>
            </div>
        </clmi-comment-card>
    </ng-container>
</atom-async-data>
