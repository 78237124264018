import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeInputWrapperModule } from '../molecule-input-wrapper/molecule-input-wrapper.module';
import { MoleculeInputGroupComponent } from './molecule-input-group.component';

@NgModule({
    imports: [CommonModule, MoleculeInputWrapperModule],
    declarations: [MoleculeInputGroupComponent],
    exports: [MoleculeInputGroupComponent],
})
export class MoleculeInputGroupModule {}
