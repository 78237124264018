<molecule-widget-container
    [widgetTitle]="widgetTitle"
    [widgetSize]="widgetSize"
    [spokeButton]="spokeButton"
    [isLoading]="isLoading"
    [hasError]="hasError"
    [count]="totalRecordCount"
    [hideCount]="hideCount"
    [numberToCompareToLimit]="cases?.length"
    [limit]="limit"
    [showNoDataMessage]="!cases?.length"
    [views]="views"
    (viewSelected)="onViewSelected($event)"
    (spokeButtonClicked)="onSpokeButtonClicked()"
>
    <div
        *ngIf="showCaseListActionsButtons"
        class="widget-header-action-buttons"
    >
        <ng-content select.cases-list-action-buttons></ng-content>
    </div>

    <organism-cases-list
        class="d-block h-100"
        [isLoading]="isLoading"
        [cases]="cases"
        [casesPerRow]="casesPerRow"
        [showFilterBar]="false"
        [showSearchBar]="false"
        [showNoDataSVG]="false"
        [showLegend]="showLegend"
        [limit]="limit"
        [totalRecordCount]="cases?.length"
        [scrollMode]="scrollMode"
        [linkToEntityType]="linkToEntityType"
        [linkToEntityId]="linkToEntityId"
    ></organism-cases-list>
</molecule-widget-container>

<atom-modal-wrapper size="xl" [modalId]="modalId" [modalTemplate]="casesModal">
    <ng-template #casesModal>
        <organism-modal modalTitle="Related Cases">
            <organism-cases-list
                class="d-block h-100"
                [isLoading]="isLoading"
                [cases]="cases"
                [showFilters]="false"
                [showSearchBar]="false"
                [showFavourite]="false"
                [filterHandler]="false"
                [showFilterBar]="false"
                [totalRecordCount]="totalRecordCount"
                [showLegend]="showLegend"
                (infinityScrollFired)="onInfinityScrollFired()"
            ></organism-cases-list>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
