import { createAction, props } from '@ngrx/store';
import { ActivityChat } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { SearchActivityChat } from '../../models/chat-message.model';
import { PaginationOptions } from '../../models/pagination-options';

export const getForActivity = createAction(
    '[Chats] Get for Activity',
    props<{
        activityId: string;
        paginationOptions: PaginationOptions;
        reset: boolean;
        pageNumber?: number;
    }>()
);

export const getForActivitySuccess = createAction(
    '[Chats] Get for Activity Success',
    props<{
        activityId: string;
        chats: PaginatedApiResponse<ActivityChat>;
    }>()
);

export const getForActivityFailure = createAction(
    '[Chats] Get for Activity Failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);

export const searchChat = createAction(
    '[Chats] Search chat',
    props<{
        activityId: string;
        reset: boolean;
        search: string;
        paginationOptions: PaginationOptions;
    }>()
);

export const searchChatSuccess = createAction(
    '[Chats] Search chat success',
    props<{
        activityId: string;
        chats: PaginatedApiResponse<SearchActivityChat>;
    }>()
);

export const searchChatFailure = createAction(
    '[Chats] Search chat failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);

export const goToSearchResult = createAction(
    '[Chats] Go To Chat Result',
    props<{
        chatId: string;
        pageNumber: number;
    }>()
);

export const saveIsLastPage = createAction(
    '[Chats] Save Is Last Page',
    props<{
        isLastPage: boolean;
    }>()
);

export const saveLoadedBottomPage = createAction(
    '[Chats] Save Loaded Bottom Page',
    props<{
        pageNumber: number;
    }>()
);

export const saveScrollDirection = createAction(
    '[Chats] Save Scroll Direction',
    props<{
        scrollDirection: string;
    }>()
);
