import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as chatsActions from './chats.actions';
import { ChatsService } from './chats.service';

@Injectable()
export class ChatsEffects {
    private actions$ = inject(Actions);
    private chatsService = inject(ChatsService);

    getForActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(chatsActions.getForActivity),
            mergeMap((action) =>
                this.chatsService
                    .getForActivity(action.activityId, {
                        ...action.paginationOptions,
                        pageNumber:
                            action.pageNumber ||
                            (action.paginationOptions
                                ? action.paginationOptions.pageNumber
                                : null),
                    })
                    .pipe(
                        map((chats) =>
                            chatsActions.getForActivitySuccess({
                                activityId: action.activityId,
                                chats,
                            })
                        ),
                        catchError((error) =>
                            of(
                                chatsActions.getForActivityFailure({
                                    activityId: action.activityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    searchChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(chatsActions.searchChat),
            mergeMap((action) =>
                this.chatsService
                    .searchChat(
                        action.activityId,
                        {
                            ...action.paginationOptions,
                            pageNumber: action.paginationOptions
                                ? action.paginationOptions.pageNumber + 1
                                : null,
                        },
                        action.search
                    )
                    .pipe(
                        map((chats) =>
                            chatsActions.searchChatSuccess({
                                activityId: action.activityId,
                                chats,
                            })
                        ),
                        catchError((error) =>
                            of(
                                chatsActions.searchChatFailure({
                                    activityId: action.activityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
