import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeActionButtonModule } from '../../molecules/molecule-action-button/molecule-action-button.module';
import { OrganismCardsListModule } from '../organism-cards-list/organism-cards-list.module';
import { OrganismActivitiesListComponent } from './organism-activities-list.component';
import { FunctionPipe } from '@wdx/shared/utils';

@NgModule({
    imports: [
        CommonModule,
        MoleculeActionButtonModule,
        OrganismCardsListModule,
        FunctionPipe,
    ],
    declarations: [OrganismActivitiesListComponent],
    exports: [OrganismActivitiesListComponent],
})
export class OrganismActivitiesListModule {}
