import { createAction, props } from '@ngrx/store';
import { CsvFile } from '@wdx/clmi/api-models';

export const getBatchFileById = createAction(
    '[Interface Console] Get Batch File By Id',
    props<{
        batchId: string;
    }>()
);

export const getBatchFileByIdSuccess = createAction(
    '[Interface Console] Get Batch File By Id Success',
    props<{
        batchId: string;
        batch: CsvFile;
    }>()
);

export const getBatchFileByIdFailure = createAction(
    '[Interface Console] Get Batch File By Id Failure',
    props<{
        batchId: string;
        error: Error;
    }>()
);

export const deleteBatch = createAction(
    '[Interface Console] Delete Batch',
    props<{
        id: string;
    }>()
);

export const deleteBatchSuccess = createAction(
    '[Interface Console] Delete Batch Success',
    props<{
        id: string;
    }>()
);

export const deleteBatchFailure = createAction(
    '[Interface Console] Delete Batch Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const emitBatchId = createAction(
    '[Interface Console] Emit Batch Id',
    props<{
        id: string;
    }>()
);

export const emitBatchName = createAction(
    '[Interface Console] Emit Batch Name',
    props<{
        name: string;
    }>()
);
