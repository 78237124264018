// This has been set up as a simple numeric enum so that you are able to determine
// a rough order of expected use.
// For example, if you want to trigger a method on any status that comes before Updating
// you can write 'status < CrudStatus.Updating'
// Therefore, if you need to add something to this enum, then know that order is
// important and must be considered

export enum CrudStatus {
    Initial,
    Created,
    Loading,
    Updating,
    Success,
    Error,
}
