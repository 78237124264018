<div
    class="input-summary h-100 border-bottom pb-1"
    data-cy="summary-container"
    [class.border-warning]="isPending"
>
    <div class="form-label d-flex align-items-baseline">
        <wdx-icon
            *ngIf="isSensitive"
            class="text-muted me-2 pointer"
            ngbPopover="This field is sensitive"
            container="body"
            [icon]="ICON_SENSITIVE"
        ></wdx-icon>
        <span [innerHtml]="label | markdownToHtml | safeHtml"></span>
        <sup *ngIf="isRequired">
            <wdx-icon
                class="ms-1 text-danger icon-container-size-fit"
                [icon]="ICON_REQUIRED"
            ></wdx-icon>
        </sup>
    </div>
    <div
        class="input-wrapper__content ms-0 fw-bolder"
        [attr.data-cy]="cySelector"
    >
        <ng-content></ng-content>
    </div>
</div>

<div
    *ngIf="isPending"
    class="text-warning"
    data-cy="summary-text-warning"
    [textContent]="PENDING_CHANGES_TEXT"
></div>
