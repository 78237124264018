import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as nudgeTypesActions from './nudge-types.actions';
import { NudgeTypesService } from './nudge-types.service';

@Injectable()
export class NudgeTypesEffects {
    private actions$ = inject(Actions);
    private nudgeTypesService = inject(NudgeTypesService);

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(nudgeTypesActions.getAll),
            switchMap(() =>
                this.nudgeTypesService.getAll().pipe(
                    map((nudgeTypes) =>
                        nudgeTypesActions.getAllSuccess({ nudgeTypes })
                    ),
                    catchError((error) =>
                        of(nudgeTypesActions.getAllFailure({ error }))
                    )
                )
            )
        )
    );
}
