import { createSelector } from '@ngrx/store';
import * as notificationsReducer from './notifications.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    notifications: notificationsReducer.State;
}): notificationsReducer.State => state.notifications;

/**
 * Reducer property selectors
 */
export const getNotificationsStatus = createSelector(
    getState,
    (state: notificationsReducer.State) => state.notifications?.status
);
export const getNotificationsPaging = createSelector(
    getState,
    (state: notificationsReducer.State) => state.notifications?.record?.paging
);
export const getNotifications = createSelector(
    getState,
    (state: notificationsReducer.State) => state.notifications?.record?.results
);
export const getUnreadNotifications = createSelector(
    getState,
    (state: notificationsReducer.State) => state.unreadNotifications
);

export const getNotificationsForView = createSelector(
    getState,
    (state: notificationsReducer.State) =>
        state.paginatedViewResponse?.record?.results
);
export const getNotificationsPagingForView = createSelector(
    getState,
    (state: notificationsReducer.State) =>
        state.paginatedViewResponse?.record?.paging
);
export const getNotificationsForViewStatus = createSelector(
    getState,
    (state: notificationsReducer.State) => state.paginatedViewResponse?.status
);
