import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FileIndex } from '@wdx/clmi/api-models';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    Severity,
    TRANSLATION_TOAST_MESSAGE_DOC_SUCCESSFULLY_DELETED,
    TranslationsService,
} from '@wdx/shared/utils';
import { of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from './../../state/_setup/reducers';
import * as documentsActions from './documents.actions';
import * as documentsSelectors from './documents.selectors';
import { DocumentsService } from './documents.service';

@Injectable()
export class DocumentsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private documentsService = inject(DocumentsService);
    private toastService = inject(WdxToastService);
    private translationsService = inject(TranslationsService);

    getDocumentsForClientProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentsActions.getDocumentsForClientProduct),
            withLatestFrom(
                this.store$.select(
                    documentsSelectors.getClientProductDocumentsInfinityPaging,
                    { id: GLOBAL_STATE_INDEX_ID }
                )
            ),
            switchMap(([action, paging]) =>
                this.documentsService
                    .getDocumentsForClientProduct(
                        {
                            pageNumber: action.reset
                                ? 1
                                : (paging?.page || 0) + 1,
                        },
                        action.clientProductId
                    )
                    .pipe(
                        map((clientProductDocuments) =>
                            documentsActions.getDocumentsForClientProductSuccess(
                                {
                                    clientProductDocuments,
                                    clientProductId: action.clientProductId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                documentsActions.getDocumentsForClientProductFailure(
                                    {
                                        clientProductId: action.clientProductId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getDocumentsForEntityId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentsActions.getDocumentsForEntityId),
            switchMap(({ entityType, entityId }) =>
                this.documentsService
                    .getDocumentsForEntityId(entityType, entityId)
                    .pipe(
                        map((documents) =>
                            documentsActions.getDocumentsForEntityIdSuccess({
                                entityType: entityType,
                                entityId: entityId,
                                documents,
                            })
                        ),
                        catchError((error) =>
                            of(
                                documentsActions.getDocumentsForEntityIdFailure(
                                    {
                                        entityType,
                                        entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentsActions.deleteDocument),
            mergeMap((action) =>
                this.documentsService.deleteDocument(action.documentId).pipe(
                    map(() =>
                        documentsActions.deleteDocumentSuccess({
                            documentId: action.documentId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            documentsActions.deleteDocumentFailure({
                                documentId: action.documentId,
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteDocumentSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(documentsActions.deleteDocumentSuccess),
                tap(() => {
                    this.toastService.show({
                        body: [
                            this.translationsService.getTranslationByKey(
                                TRANSLATION_TOAST_MESSAGE_DOC_SUCCESSFULLY_DELETED
                            ),
                        ],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );

    dissociate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentsActions.dissociateDocument),
            mergeMap((action) =>
                this.documentsService
                    .dissociateDocument(action.id, action.regarding)
                    .pipe(
                        map(() =>
                            documentsActions.dissociateDocumentSuccess({
                                id: action.id,
                            })
                        ),
                        catchError((error) =>
                            of(
                                documentsActions.dissociateDocumentFailure({
                                    id: action.id,
                                    error: error,
                                })
                            )
                        )
                    )
            )
        )
    );

    uploadDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(documentsActions.uploadDocument),
            switchMap((action) =>
                this.documentsService
                    .uploadFile(
                        action.id,
                        action.entityId,
                        action.entityType,
                        action.file
                    )
                    .pipe(
                        filter(
                            (event) => event.type === HttpEventType.Response
                        ),
                        map((event: HttpResponse<any>) => {
                            const body = event?.body as FileIndex[];

                            const genericFileIndex: FileIndex = {
                                fileIndex: '',
                                contentType: action.file.type,
                                name: action.file.name,
                                sizeInBytes: action.file.size,
                            };

                            return event.status === 200
                                ? documentsActions.uploadDocumentComplete({
                                      id: action.id,
                                      entityId: action.entityId,
                                      fileIndex: body
                                          ? body[0]
                                          : genericFileIndex,
                                  })
                                : documentsActions.uploadDocumentFailure({
                                      id: action.id,
                                      entityId: action.entityId,
                                      error: event.statusText,
                                  });
                        }),
                        catchError((error) =>
                            of(
                                documentsActions.uploadDocumentFailure({
                                    id: action.id,
                                    entityId: action.entityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
