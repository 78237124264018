export enum MIMETypeToFileFormat {
    'application/pdf' = 'pdf',
    'application/msword' = 'word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'word',
    'application/vnd.ms-powerpoint' = 'powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation' = 'powerpoint',
    'application/vnd.ms-excel' = 'excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'excel',
    'image/gif' = 'image',
    'image/jpeg' = 'image',
    'image/png' = 'image',
    'image/svg+xml' = 'image',
    'text/csv' = 'csv',
}
