import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'kebabCase' })
export class KebabCasePipe implements PipeTransform {
    transform(value: string): string {
        const flattenedWords = value
            .match(/[A-Z]/g)
            .reduce(
                (accumulator, match) => accumulator.replace(match, `-${match}`),
                value
            );

        const words = flattenedWords.split(/[^a-zA-Z]+/g);

        return words
            .map((word) => word.toLowerCase())
            .map((word) => word.trim())
            .filter((word) => Boolean(word))
            .join('-');
    }
}
