import { Injectable } from '@angular/core';
import { ApiConfig } from './api-config.model';

@Injectable({ providedIn: 'root' })
export class ApiConfigService {
    private config!: ApiConfig;

    set(config: ApiConfig): void {
        this.config = config;
    }

    get(): ApiConfig {
        return this.config;
    }
}
