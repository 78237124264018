import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IdentityVerificationService } from '@wdx/clmi/api-services/services';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as identityVerificationActions from './identity-verification.actions';

@Injectable()
export class IdentityVerificationEffects {
    private actions$ = inject(Actions);
    private identityVerificationService = inject(IdentityVerificationService);

    getKeystats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityVerificationActions.getKeystats),
            mergeMap((action) =>
                this.identityVerificationService
                    .getKeystats(action.identityCheckId)
                    .pipe(
                        map((keystats) =>
                            identityVerificationActions.getKeystatsSuccess({
                                keystats,
                                identityCheckId: action.identityCheckId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                identityVerificationActions.getKeystatsError({
                                    error,
                                    identityCheckId: action.identityCheckId,
                                })
                            )
                        )
                    )
            )
        )
    );

    getIdVerificationCheckDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityVerificationActions.getIdVerificationCheckDetail),
            mergeMap((action) =>
                this.identityVerificationService
                    .getIdVerificationCheckDetail(action.idVerificationId)
                    .pipe(
                        map((idVerificationCheckDetail) =>
                            identityVerificationActions.getIdVerificationCheckDetailSuccess(
                                {
                                    idVerificationCheckDetail,
                                    idVerificationId: action.idVerificationId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                identityVerificationActions.getIdVerificationCheckDetailError(
                                    {
                                        error,
                                        idVerificationId:
                                            action.idVerificationId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getCheckDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityVerificationActions.getCheckDetail),
            mergeMap((action) =>
                this.identityVerificationService
                    .getCheckDetail(action.partyId)
                    .pipe(
                        map((checkDetail) =>
                            identityVerificationActions.getCheckDetailSuccess({
                                checkDetail,
                                partyId: action.partyId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                identityVerificationActions.getCheckDetailError(
                                    {
                                        error,
                                        partyId: action.partyId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    refreshHeader$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityVerificationActions.refreshHeader),
            mergeMap((action) =>
                this.identityVerificationService
                    .refreshHeader(action.identityCheckId)
                    .pipe(
                        map(() =>
                            identityVerificationActions.refreshHeaderSuccess({
                                isSuccess: true,
                            })
                        ),
                        catchError((error) =>
                            of(
                                identityVerificationActions.refreshHeaderError({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getCheckReviews$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityVerificationActions.getCheckReviews),
            mergeMap((action) =>
                this.identityVerificationService
                    .getCheckReviews(action.id)
                    .pipe(
                        map((checkReviews) =>
                            identityVerificationActions.getCheckReviewsSuccess({
                                checkReviews,
                                id: action.id,
                            })
                        ),
                        catchError((error) =>
                            of(
                                identityVerificationActions.getCheckReviewsError(
                                    {
                                        error,
                                        id: action.id,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getCheckReviewsAdditional$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityVerificationActions.getCheckReviewsAdditional),
            mergeMap((action) =>
                this.identityVerificationService
                    .getCheckReviewsAdditional(action.identityCheckId)
                    .pipe(
                        map((checkReviewsAdditional) =>
                            identityVerificationActions.getCheckReviewsAdditionalSuccess(
                                {
                                    checkReviewsAdditional,
                                    identityCheckId: action.identityCheckId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                identityVerificationActions.getCheckReviewsAdditionalError(
                                    {
                                        error,
                                        identityCheckId: action.identityCheckId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getCheckedHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityVerificationActions.getCheckedHistory),
            mergeMap((action) =>
                this.identityVerificationService
                    .getCheckedHistory(action.partyId)
                    .pipe(
                        map((checkedHistory) =>
                            identityVerificationActions.getCheckedHistorySuccess(
                                {
                                    checkedHistory,
                                    partyId: action.partyId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                identityVerificationActions.getCheckedHistoryError(
                                    {
                                        error,
                                        partyId: action.partyId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
