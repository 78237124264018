import { FileIndex, FormSetup } from '@wdx/shared/utils';
import { Observable } from 'rxjs';

export interface CreatedDocumentInfo {
    id: string;
    name: string;
    fileIndex: FileIndex;
}

export abstract class IFormDocumentHandler {
    abstract deleteDocument(id: string, name: string): void;
    abstract editDocument(formSetup: FormSetup): void;
    abstract addDocument(formSetup: FormSetup): void;
    abstract deleteDocumentSuccess$(): Observable<string>;
    abstract createDocumentSuccess$(): Observable<CreatedDocumentInfo>;
}
