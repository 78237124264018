import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { AmlReport } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';

@Injectable()
export class AmlService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getAllForId(id: string): Observable<AmlReport[]> {
        return this.http.get<AmlReport[]>(
            `${this.config.getConfiguration().API_BASE}/aml/app/${id}/all`
        );
    }

    getLatestForId(id: string): Observable<AmlReport> {
        return this.http.get<AmlReport>(
            `${this.config.getConfiguration().API_BASE}/aml/app/${id}`
        );
    }

    patchNewCheckForId(id: string): Observable<AmlReport> {
        return this.http.patch<AmlReport>(
            `${this.config.getConfiguration().API_BASE}/aml/app/${id}`,
            {}
        );
    }
}
