import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxMarkdownToHtmlPipe, WdxSafeHtmlPipe } from '@wdx/shared/utils';
import { MoleculeSummaryFieldComponent } from './molecule-summary-field.component';

@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        WdxIconModule,
        WdxMarkdownToHtmlPipe,
        WdxSafeHtmlPipe,
    ],
    declarations: [MoleculeSummaryFieldComponent],
    exports: [MoleculeSummaryFieldComponent],
})
export class MoleculeSummaryFieldModule {}
