import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lead } from '@wdx/clmi/api-models';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class LeadsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getLead(leadId: string): Observable<Lead> {
        return this.http.get<Lead>(
            `${this.config.getConfiguration().API_BASE}/lead/${leadId}`
        );
    }

    getFavourites(): Observable<Lead[]> {
        return this.http.get<Lead[]>(
            `${this.config.getConfiguration().API_BASE}/lead/favourite`
        );
    }
}
