import { Pipe, PipeTransform } from '@angular/core';
import { Party } from '@wdx/clmi/api-models';
import { Avatar } from '@wdx/clmi/utils/models';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';

@Pipe({ name: 'lookupAvatars' })
export class LookupAvatarsPipe implements PipeTransform {
    transform(value: Party): Avatar[] {
        if (!value) {
            return [];
        }
        const lookups = Array.isArray(value) ? value : [value];
        return lookups
            .map((value) =>
                new PartyToAvatarPipe().transform(
                    value.party ? value.party : value
                )
            )
            .map((value) => ({
                ...value,
                isAvatarInfoEnabled: Boolean(value.partyType),
            }));
    }
}
