import { Component, Input } from '@angular/core';
import { Avatar } from '@wdx/clmi/utils/models';
import { WdxSize } from '@wdx/shared/utils';
import { BaseComponentClass } from '../../../../classes/base-component.class';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-avatar-group-container',
    templateUrl: './molecule-avatar-group-container.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-flex align-items-center' },
})
export class MoleculeAvatarGroupContainerComponent extends BaseComponentClass {
    @Input() avatars: Avatar[];
    @Input() size: WdxSize = 'md';
    @Input() limit = 3;
    @Input() fixedWidth: boolean;
    @Input() forceGroup: boolean;
    @Input() editModalId: string;
}
