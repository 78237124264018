import { Action, createReducer, on } from '@ngrx/store';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as duplicationCheckActions from '../../state/duplication-check/duplication-check.actions';

export interface State {
    duplicates: CrudStateObject<any>;
    saveFormDetails: {
        form: {
            formId: string;
            entityId: string;
            entityType: string;
            formData: any;
        };
    };
}

export const initialState: State = {
    duplicates: {},
    saveFormDetails: {
        form: {
            formId: '',
            entityId: '',
            entityType: '',
            formData: {},
        },
    },
};

const reducerSetup = createReducer(
    initialState,

    on(
        duplicationCheckActions.openDuplicatesModal,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.formId]: {
                    ...(state.duplicates[props.formId] ||
                        ({} as CrudState<any>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.payload,
                },
            },
            saveFormDetails: {
                ...state.saveFormDetails,
                [props.formId]: {
                    ...state.saveFormDetails[props.formId],
                    isLoading: false,
                    hasLoadingSingleError: false,
                    form: {
                        formId: props.formId,
                        entityId: props.entityId,
                        entityType: props.entityType,
                        formData: props.formData,
                    },
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
