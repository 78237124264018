import { Action, createReducer, on } from '@ngrx/store';
import {
    PartyRoleRelationship,
    RelationshipTypeSimple,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { RelationshipWrapper } from '../../models/relationship-wrapper.model';
import * as relationshipsActions from './relationships.actions';

export interface State {
    relationships?: CrudStateObject<RelationshipWrapper>;
    familyRelationships?: CrudStateObject<PartyRoleRelationship>;
    applicableFamilyRoles?: CrudStateObject<RelationshipTypeSimple>;
}

export const initialState: State = {
    relationships: {},
    familyRelationships: {},
    applicableFamilyRoles: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        relationshipsActions.getForPartyRole,
        (state, props): State => ({
            ...state,
            relationships: {
                ...state.relationships,
                [props.partyRoleId]: {
                    ...(state.relationships[props.partyRoleId] ||
                        ({} as CrudState<RelationshipWrapper>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        relationshipsActions.getForPartyRoleSuccess,
        (state, props): State => ({
            ...state,
            relationships: {
                ...state.relationships,
                [props.partyRoleId]: {
                    ...state.relationships[props.partyRoleId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.relationshipWrapper,
                },
            },
        })
    ),

    on(
        relationshipsActions.getForPartyRoleFailure,
        (state, props): State => ({
            ...state,
            relationships: {
                ...state.relationships,
                [props.partyRoleId]: {
                    ...state.relationships[props.partyRoleId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        relationshipsActions.getFamilyForClient,
        (state, props): State => ({
            ...state,
            familyRelationships: {
                ...state.familyRelationships,
                [props.clientId]: {
                    ...(state.familyRelationships[props.clientId] ||
                        ({} as CrudState<PartyRoleRelationship>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        relationshipsActions.getFamilyForClientSuccess,
        (state, props): State => ({
            ...state,
            familyRelationships: {
                ...state.familyRelationships,
                [props.clientId]: {
                    ...state.familyRelationships[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.familyRelationships,
                },
            },
        })
    ),

    on(
        relationshipsActions.getFamilyForClientFailure,
        (state, props): State => ({
            ...state,
            familyRelationships: {
                ...state.familyRelationships,
                [props.clientId]: {
                    ...state.familyRelationships[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        relationshipsActions.getApplicableFamilyRoles,
        (state, props): State => ({
            ...state,
            applicableFamilyRoles: {
                ...state.applicableFamilyRoles,
                [props.clientId]: {
                    ...(state.applicableFamilyRoles[props.clientId] ||
                        ({} as CrudState<RelationshipTypeSimple>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        relationshipsActions.getApplicableFamilyRolesSuccess,
        (state, props): State => ({
            ...state,
            applicableFamilyRoles: {
                ...state.applicableFamilyRoles,
                [props.clientId]: {
                    ...state.applicableFamilyRoles[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.applicableFamilyRoles,
                },
            },
        })
    ),

    on(
        relationshipsActions.getApplicableFamilyRolesFailure,
        (state, props): State => ({
            ...state,
            applicableFamilyRoles: {
                ...state.applicableFamilyRoles,
                [props.clientId]: {
                    ...state.applicableFamilyRoles[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
