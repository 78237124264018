import { createAction, props } from '@ngrx/store';
import { Answer, AnswerSet } from '@wdx/clmi/api-models';

export const getAnswerSets = createAction('[Answers] Get Answer Sets');

export const getAnswerSetsSuccess = createAction(
    '[Answers] Get Answer Sets Success',
    props<{
        answerSetList: AnswerSet[];
    }>()
);

export const getAnswerSetsFailure = createAction(
    '[Answers] Get Answer Sets Failure'
);

export const filterAnswerSets = createAction(
    '[Answers] Filter Answer Sets',
    props<{
        searchText: string;
    }>()
);

export const getAnswers = createAction(
    '[Answers] Get Answers',
    props<{
        code: string;
    }>()
);

export const getAnswersSuccess = createAction(
    '[Answers] Get Answers Success',
    props<{
        code: string;
        answers: Answer[];
    }>()
);

export const getAnswersFailure = createAction(
    '[Answers] Get Answers Failure',
    props<{
        code: string;
    }>()
);

export const reorderAnswers = createAction(
    '[Answers] Reorder Answers',
    props<{
        code: string;
        answers: Answer[];
    }>()
);

export const reorderAnswersSuccess = createAction(
    '[Answers] Reorder Answers Success',
    props<{
        code: string;
        answers: Answer[];
    }>()
);

export const reorderAnswersFailure = createAction(
    '[Answers] Reorder Answers Failure',
    props<{
        code: string;
    }>()
);

export const disableAnswer = createAction(
    '[Answers] Disable Answer',
    props<{
        id: string;
        isDisabled: boolean;
    }>()
);

export const disableAnswerSuccess = createAction(
    '[Answers] Disable Answer Success',
    props<{
        id: string;
        isDisabled: boolean;
    }>()
);

export const disableAnswerFailure = createAction(
    '[Answers] Disable Answer Failure',
    props<{
        id: string;
    }>()
);

export const setActiveAnswerSetCode = createAction(
    '[Answers] Set Active AnswerSet Code',
    props<{
        code: string;
    }>()
);

export const updateAnswerSuccess = createAction(
    '[Answers] Update Answer Success',
    props<{
        id: string;
        code: string;
        answer: Answer;
    }>()
);

export const createAnswerSuccess = createAction(
    '[Answers] Create Answer Success',
    props<{
        code: string;
        answer: Answer;
    }>()
);

export const resetFilter = createAction('[Answers] Reset Filter');
