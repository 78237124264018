import { Action, createReducer, on } from '@ngrx/store';
import { GlobalSearchResult } from '@wdx/clmi/api-models';
import { PaginatedPagedApiResponse } from '@wdx/clmi/api-services/models';
import { EntityFiltersResult } from '../../models/global-search.model';
import * as globalSearchActions from './global-search.actions';

export interface State {
    isLoadingPage?: boolean;
    hasLoadingPageError?: boolean;
    searchResults?: PaginatedPagedApiResponse<GlobalSearchResult>;
    entityFilters?: EntityFiltersResult[];
}

export const initialState: State = {};

const reducerSetup = createReducer(
    initialState,

    on(
        globalSearchActions.getPage,
        (state): State => ({
            ...state,
            isLoadingPage: true,
            hasLoadingPageError: false,
        })
    ),

    on(
        globalSearchActions.getPageSuccess,
        (state, props): State => ({
            ...state,
            isLoadingPage: false,
            searchResults: {
                ...state.searchResults,
                pages: {
                    ...state.searchResults?.pages,
                    [props.pageNumber]: props.searchResults.results,
                },
                paging: props.searchResults.paging,
            },
        })
    ),

    on(
        globalSearchActions.getPageFailure,
        (state): State => ({
            ...state,
            isLoadingPage: false,
            hasLoadingPageError: true,
        })
    ),

    on(
        globalSearchActions.getEntityFiltersSuccess,
        (state, props): State => ({
            ...state,
            entityFilters: props.entityFilters,
        })
    ),

    on(
        globalSearchActions.resetResults,
        (state): State => ({
            ...state,
            isLoadingPage: false,
            hasLoadingPageError: false,
            searchResults: null,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
