import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CrudStatus, FormDataResult } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import * as formHistoryInstanceActions from './form-history-instance.actions';
import * as formHistoryInstanceSelectors from './form-history-instance.selectors';

@Injectable({ providedIn: 'root' })
export class FormHistoryInstanceFacade {
    private store$ = inject(Store);

    formHistoryInstance$(
        formId: string,
        entityId: string,
        instanceId: string
    ): Observable<FormDataResult | null> {
        return this.store$.select(
            formHistoryInstanceSelectors.selectFormHistoryInstance(
                formId,
                entityId,
                instanceId
            )
        );
    }

    status$(
        formId: string,
        entityId: string,
        instanceId: string
    ): Observable<CrudStatus | null> {
        return this.store$.select(
            formHistoryInstanceSelectors.selectFormHistoryInstanceStatus(
                formId,
                entityId,
                instanceId
            )
        );
    }

    loadFormHistoryInstance(
        formId: string,
        entityId: string,
        instanceId: string
    ): void {
        this.store$.dispatch(
            formHistoryInstanceActions.loadFormHistoryInstance({
                formId,
                entityId,
                instanceId,
            })
        );
    }
}
