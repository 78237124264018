import { createAction, props } from '@ngrx/store';

export const deleteNote = createAction(
    '[Notes] Delete',
    props<{
        noteId: string;
    }>()
);

export const deleteSuccess = createAction(
    '[Notes] Delete Success',
    props<{
        noteId: string;
    }>()
);

export const deleteFailure = createAction(
    '[Notes] Delete Failure',
    props<{
        noteId: string;
        error: Error;
    }>()
);
