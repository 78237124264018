<button
    class="btn btn-icon"
    data-cy="wdx-icon-button"
    [class.overflow-visible]="overflowVisible"
    [class.position-relative]="positionRelative"
    [class.btn-outline-primary]="wdxStyle === 'outline-primary'"
    [class.btn-primary]="wdxStyle === 'primary'"
    [class.btn-light]="wdxStyle === 'light'"
    [class.disabled]="disabled"
    [ngClass]="severity && 'btn-' + severity"
    [disabled]="disabled"
    (click)="onClick($event)"
>
    <wdx-icon-container [size]="size">
        <ng-content></ng-content>
    </wdx-icon-container>
</button>
