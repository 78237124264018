import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';

import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ActivityCommentCreate, ActivityType } from '@wdx/clmi/api-models';
import { WdxButtonStyle } from '@wdx/shared/components/wdx-button';

import { UserFacadeService } from '@wdx/clmi/api-services/services';
import { WdxDestroyClass } from '@wdx/shared/utils';
import {
    ICON_ADD,
    ICON_CLOSE,
    ICON_COMMENT,
    ICON_COMMENT_ISSUE,
} from '../../../../constants/icons.constants';
import * as commentsActions from '../../../../state/comments/comments.actions';
import { TriggerChar } from '../shared/constants/mention.constant';
import { AppMentionsService } from '../shared/services/app-mention/app-mention.service';
import { CommentsFacade } from '../shared/services/comments.facade';
import { MentionService } from '../shared/services/mention/mention.service';

@Component({
    selector: 'clmi-comment-input',
    templateUrl: './comment-input.component.html',
    styleUrls: ['./comment-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MentionService, AppMentionsService],
})
export class CommentInputComponent extends WdxDestroyClass implements OnInit {
    public facade = inject(CommentsFacade);
    private mentionService = inject(MentionService);
    private appMentionsService = inject(AppMentionsService);
    private userFacadeService = inject(UserFacadeService);

    get isWorkItem(): boolean {
        return Boolean(this.facade.activityType === ActivityType.WorkItem);
    }

    private _resolveIssueId = null;
    @Input() set resolveIssueId(value: string | null) {
        if (value) {
            this.reportIssue = false;
            if (!this.addNewComment$.value) {
                this.addNewComment$.next(true);
            }
        }
        this._resolveIssueId = value ? value : null;
    }
    get resolveIssueId(): string | null {
        return this._resolveIssueId;
    }
    @Input() set closeComment(timeStamp: number) {
        if (timeStamp) {
            this.addNewComment$.next(false);
        }
    }

    @Input() count = 0;
    private _issueCount;
    @Input() set issueCount(value: number) {
        this._issueCount = value;
        this.issueCountMessage = value ? `${value} with issues` : '';
    }
    @Input() dynamicHeight = false;
    @Output() showIssuesOnly = new EventEmitter<boolean>();
    @Output() createCommentClicked = new EventEmitter<void>();

    isCreating$: Observable<boolean>;

    commentCreate: ActivityCommentCreate;
    showIssues = false;
    issueCountMessage = '';
    commentOverride = '';
    reportIssue = false;
    resolveIssue = false;
    placeholder: string;
    wdxButton = WdxButtonStyle;
    addNewComment$ = new BehaviorSubject(false);
    me$ = this.userFacadeService.getMe$();
    close = ICON_CLOSE;

    mentionConfig$ = new BehaviorSubject({
        mentions: [
            this.mentionService.mentionsConfig,
            this.appMentionsService.appMentionConfig,
        ],
    });
    public commentCount: { [k: string]: string } = {
        '=0': 'No comments',
        '=1': '1 comment',
        other: '# comments',
    };

    readonly ICON_COMMENT = ICON_COMMENT.icon;
    readonly ICON_ADD = ICON_ADD.icon;
    readonly ICON_COMMENT_ISSUE = ICON_COMMENT_ISSUE.icon;

    constructor(private actionListener$: ActionsSubject) {
        super();
        this.isCreating$ = this.facade.isCreating$;
    }

    ngOnInit(): void {
        this.reset();

        this.updatePlaceholder();

        this.actionListener$
            .pipe(
                takeUntil(this.destroyed$),
                ofType(commentsActions.createSuccess)
            )
            .subscribe(() => {
                this.reset();
            });

        this.facade.appMention$
            .pipe(
                takeUntil(this.destroyed$),
                filter((res) => Boolean(res))
            )
            .subscribe({
                next: (appMention) => {
                    if (this.resolveIssueId) {
                        return;
                    }
                    let content = '';
                    if (appMention?.triggerChar === TriggerChar.Hash) {
                        content = `#${appMention.displayName}`;
                        this.appMentionsService.appMentions = [appMention];
                    }
                    this.commentOverride = content;
                },
            });
    }

    onCommentAdded(): void {
        if (!this.commentCreate?.content) {
            return;
        }

        this.facade.dispatchCommentData({
            ...this.commentCreate,
            reportIssue: this.reportIssue,
            resolveIssueId: this.resolveIssueId,
        });
        this.reportIssue = false;
        this.createCommentClicked.emit();
        this.showAll();
    }

    reset(): void {
        this.toggleComments(false);

        this.commentCreate = null;
        this.mentionService.mentions = [];
        this.appMentionsService.appMentions = [];
        this.reportIssue = false;
        this.resolveIssue = false;
    }

    updatePlaceholder(): void {
        let hash = '';

        if (this.facade.activityType === ActivityType.WorkItem) {
            hash = ` or #mention apps`;
        }

        this.placeholder = `@mention${hash} and comment`;
    }

    toggleComments(showComments: boolean): void {
        if (showComments) {
            this.facade.addCommentTrigger$.next(showComments);
        }

        this.addNewComment$.next(showComments);
    }

    showAll() {
        if (!this._issueCount) {
            return;
        }
        this.showIssues = false;
        this.showIssuesOnly.emit(false);
    }

    showOnlyIssues() {
        this.showIssues = true;
        this.showIssuesOnly.emit(true);
    }

    commentUpdated(commentCreate: ActivityCommentCreate) {
        this.commentCreate = commentCreate;
    }
}
