import { createAction, props } from '@ngrx/store';

export const patch = createAction(
    '[Http Patch]',
    props<{
        slug: string[];
    }>()
);

export const patchSuccess = createAction(
    '[Http Patch Success]',
    props<{
        slug: string[];
    }>()
);

export const patchFailure = createAction(
    '[Http Patch Failure]',
    props<{
        error: Error;
    }>()
);
