<molecule-data-list-wrapper
    [searchType]="searchType"
    [searchTypePlural]="searchTypePlural"
    [searchPlaceholder]="searchPlaceholder"
    [isLoading]="isLoading"
    [hasError]="hasError"
    [isForInfinityScroll]="true"
    [limit]="limit"
    [filterHandler]="filterHandler"
    [filtersModalId]="filtersModalId"
    [sortMenu]="sortMenu"
    [useSortToggleMenu]="useSortToggleMenu"
    [totalRecordCount]="totalRecordCount"
    [showFilters]="showFilters"
    [showFilterBar]="showFilterBar"
    [showSearchBar]="showSearchBar"
    [showFavourite]="showFavourite"
    [cySelector]="cySelector"
    [favouriteFilterToggle]="favouriteFilterToggle"
    (infinityScrollFired)="onInfinityScrollFired()"
    (click)="onNavigateCloseActiveModal()"
>
    <ng-content prefixActionButtons select="[prefixActionButtons]"></ng-content>

    <div class="data-list-action-button">
        <ng-content select=".organism-cards-list-action-button"></ng-content>
    </div>
    <div
        *ngIf="cardType === CARD_TYPE.Case && showLegend"
        class="data-list-header-content mb-2 d-none d-lg-block"
    >
        <molecule-legend [items]="legendItems"></molecule-legend>
    </div>
    <div
        class="data-list-content max-h-100"
        [class.widget-container]="addWidgetContainerPadding"
    >
        <ng-container
            *ngIf="
                caseCards
                    | fn
                        : getCards
                        : opportunityCards
                        : activityCards
                        : cardType;
                let cards
            "
        >
            <ng-container *ngIf="totalRecordCount || isLoading; else noData">
                <ng-container [ngSwitch]="scrollMode">
                    <ng-container *ngSwitchCase="SCROLL_MODE.Carousel">
                        <ngb-carousel class="w-100 h-100" [interval]="false">
                            <ng-container
                                *ngFor="
                                    let card of cards;
                                    trackBy: trackByCardId
                                "
                            >
                                <ng-template ngbSlide>
                                    <ng-container
                                        *ngTemplateOutlet="
                                            cardTypeSwitch;
                                            context: { card: card }
                                        "
                                    ></ng-container>
                                </ng-template>
                            </ng-container>
                        </ngb-carousel>
                    </ng-container>

                    <ng-container *ngSwitchCase="SCROLL_MODE.Horizontal">
                        <ng-container
                            *ngTemplateOutlet="horizontalScroll"
                        ></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="SCROLL_MODE.Responsive">
                        <div class="d-none d-xl-block h-100">
                            <ng-container
                                *ngTemplateOutlet="defaultScroll"
                            ></ng-container>
                        </div>
                        <div class="d-block d-xl-none h-100">
                            <ng-container
                                *ngTemplateOutlet="horizontalScroll"
                            ></ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <ng-container
                            *ngTemplateOutlet="defaultScroll"
                        ></ng-container>
                    </ng-container>
                </ng-container>

                <ng-template #defaultScroll>
                    <div class="max-h-100 grid">
                        <div
                            *ngFor="let card of cards; trackBy: trackByCardId"
                            class="data-list-content__card-container g-col"
                            [class.suggested-card]="isSuggestedCardList"
                            [responsiveColumns]="{ staticColumns: cardsPerRow }"
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    cardTypeSwitch;
                                    context: { card: card }
                                "
                            ></ng-container>
                        </div>
                    </div>
                </ng-template>

                <ng-template #horizontalScroll>
                    <molecule-tile-carousel
                        class="d-block h-100"
                        [tiles]="tileCarouselCards"
                    >
                        <div
                            #tileCarouselCard
                            *ngFor="let card of cards; trackBy: trackByCardId"
                            class="data-list-content__card-container data-list-content__card-container--set-width"
                            [class.suggested-card]="isSuggestedCardList"
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    cardTypeSwitch;
                                    context: { card: card }
                                "
                            ></ng-container>
                        </div>
                    </molecule-tile-carousel>
                </ng-template>
            </ng-container>

            <ng-template #noData>
                <div
                    *ngIf="showNoDataSVG"
                    class="d-flex h-100 align-items-center justify-content-center"
                >
                    <atom-feature-svg
                        [svg]="FEATURE_SVG.NoData"
                        [size]="noDataSVGSize"
                    >
                        {{ noDataMessage }}
                    </atom-feature-svg>
                </div>
            </ng-template>
        </ng-container>
    </div>
</molecule-data-list-wrapper>

<ng-template #cardTypeSwitch let-card="card">
    <ng-container [ngSwitch]="cardType">
        <molecule-case-card
            *ngSwitchCase="CARD_TYPE.Case"
            class="data-list-content__card d-block h-100"
            [caseCardItem]="card"
        ></molecule-case-card>
        <molecule-opportunity-card
            *ngSwitchCase="CARD_TYPE.Opportunity"
            class="data-list-content__card d-block h-100"
            [opportunity]="card"
            [fullCardLink]="true"
        ></molecule-opportunity-card>
        <molecule-activity-card
            *ngSwitchCase="CARD_TYPE.Activity"
            class="data-list-content__card d-block h-100"
            [activityCard]="card"
        ></molecule-activity-card>
    </ng-container>
</ng-template>

<atom-modal-wrapper
    *ngIf="filtersModalId"
    [modalId]="filtersModalId"
    [modalTemplate]="filtersModal"
>
    <ng-template #filtersModal>
        <organism-modal modalTitle="Filter">
            <clmi-form-filters
                *ngIf="hasFilterSearch"
                class="h-100 w-100"
            ></clmi-form-filters>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
