import { createAction, props } from '@ngrx/store';
import { RecentRecordSummary } from '@wdx/clmi/api-models';

export const getUserRecentRecords = createAction(
    '[Recent Records] Get User Recent Records'
);

export const getUserRecentRecordsSuccess = createAction(
    '[Recent Records] Get User Recent Records Success',
    props<{
        recentRecords: RecentRecordSummary[];
    }>()
);

export const getUserRecentRecordsFailure = createAction(
    '[Recent Records] Get User Recent Records Failure',
    props<{
        error: Error;
    }>()
);

export const updateRecentlyViewedRecord = createAction(
    '[Recent Records] Update Recently Viewed Record',
    props<{
        entityType: string;
        recordId: string;
    }>()
);

export const updateRecentlyViewedRecordSuccess = createAction(
    '[Recent Records] Update Recently Viewed Record Success',
    props<{
        recentRecord: RecentRecordSummary;
    }>()
);

export const updateRecentlyViewedRecordFailure = createAction(
    '[Recent Records] Update Recently Viewed Record Failure',
    props<{
        error: Error;
    }>()
);
