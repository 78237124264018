import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DocumentTemplate,
    StatusType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { saveAs } from 'file-saver';
import * as luxon from 'luxon';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class DocumentTemplatesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getDocumentTemplates(params: {
        entityType?: SystemEntity;
        documentTypeCode?: string;
    }): Observable<DocumentTemplate[]> {
        return this.http.get<DocumentTemplate[]>(
            `${this.config.getConfiguration().API_BASE}/documenttemplate`,
            {
                params: {
                    ...(params.entityType
                        ? { entityType: params.entityType }
                        : {}),
                    ...(params.documentTypeCode
                        ? { documentTypeCode: params.documentTypeCode }
                        : {}),
                },
            }
        );
    }

    generateDocument(id: string, entityId: string) {
        return this.http
            .get<Blob>(
                `${
                    this.config.getConfiguration().API_BASE
                }/DocumentTemplate/${id}/generate/${entityId}`,
                {
                    observe: 'response',
                    responseType: 'blob' as 'json',
                }
            )
            .pipe(tap((response) => this.download(response)));
    }

    download(response: HttpResponse<Blob>) {
        const contentDisposition: string = response.headers.get(
            'content-disposition'
        );
        const filename: string[] = contentDisposition?.match(
            // eslint-disable-next-line no-useless-backreference
            /filename[^;=\n]*=['"](.*?\2|[^;\n]*)([.]\w*)/
        );
        const dateNow: string =
            '_' +
            luxon.DateTime.fromJSDate(new Date()).toLocaleString(
                DateTime.DATE_SHORT
            );
        const contentType: string = response.headers.get('content-type');
        const blob: Blob = new Blob([response.body], { type: contentType });

        saveAs(blob, filename[1] + dateNow + filename[2]);
    }

    setDocumentStatus(
        id: string,
        status: StatusType
    ): Observable<DocumentTemplate> {
        return this.http.patch<DocumentTemplate>(
            `${
                this.config.getConfiguration().API_BASE
            }/DocumentTemplate/${id}/status/${status}`,
            {}
        );
    }

    deleteDocumentTemplate(id: string): Observable<DocumentTemplate> {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/DocumentTemplate/${id}`
        );
    }

    generateDocumentTemplate(
        entityId: string,
        entityType: SystemEntity,
        templateId: string
    ): Observable<Document> {
        return this.http.patch<Document>(
            `${
                this.config.getConfiguration().API_BASE
            }/DocumentTemplate/${entityId}/${entityType}/generate/${templateId}`,
            {}
        );
    }
}
