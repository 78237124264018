import { Type } from '@angular/core';

export abstract class ISubformCustomHandler {
    public abstract getAvatarData(value: any): any;
    public abstract getAvatarComponent(): {
        component: Type<any>;
        inputs: Record<string, unknown>;
        avatarInput: string;
    };
}
