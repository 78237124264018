<div *ngIf="(messages$ | ngrxPush).length">
    <div *ngFor="let message of messages$ | ngrxPush">
        <ngb-alert
            #alert
            class="mb-0"
            [type]="message.type"
            [dismissible]="false"
            (closed)="close(message)"
        >
            <div class="alert" role="alert">
                <div class="d-flex align-items-start">
                    <wdx-icon
                        class="me-2"
                        scale="1.25"
                        [icon]="message.icon"
                    ></wdx-icon>
                    <h4 class="alert-heading">{{ message.heading }}</h4>
                </div>
                <p class="mb-0">{{ message.subMessage }}</p>
                <div *ngIf="message.actionType">
                    <hr />
                    <a
                        class="alert-link link pointer"
                        (click)="action(message.actionType)"
                    >
                        {{ message.actionText }}
                    </a>
                </div>
            </div>
        </ngb-alert>
    </div>
</div>
