<clmi-view-shell [isWidgetView]="isWidgetView">
    <widget-table
        class="h-100"
        [hasMobileFilter]="!isWidgetView"
        [hasFilterSearch]="!isWidgetView"
        [searchType]="searchType"
        [searchTypePlural]="searchTypePlural"
        [searchPlaceholder]="searchPlaceholder"
        [table]="table"
        [tablePaging]="paging"
        [filterHandler]="filterHandler"
        [isLoading]="loading$ | ngrxPush"
        [hasError]="hasError$ | ngrxPush"
        [resetTableData]="false"
        (pageChanged)="onPageChanged($event)"
    ></widget-table>
</clmi-view-shell>

<ng-template #responsiveCardTemplate let-data="data">
    <clmi-filter-table-responsive-card-template
        [data]="data"
        (wdxOnChange)="onToggleFavourite($event, data)"
        (menuItemSelected)="onActionButtonMenuItemSelected($event, data)"
    ></clmi-filter-table-responsive-card-template>
</ng-template>

<ng-template #infoCardCell let-data="data">
    <clmi-filter-table-info-card-template
        [data]="data"
    ></clmi-filter-table-info-card-template>
</ng-template>

<ng-template #lifecycleStageCell let-data="data">
    <clmi-filter-table-lifecycle-stage-template
        [data]="data"
    ></clmi-filter-table-lifecycle-stage-template>
</ng-template>

<ng-template #actionButtonCell let-data="data">
    <clmi-filter-table-action-button-template
        [data]="data"
        (wdxOnChange)="onToggleFavourite($event, data)"
        (menuItemSelected)="onActionButtonMenuItemSelected($event, data)"
    ></clmi-filter-table-action-button-template>
</ng-template>

<ng-template #badgeCell let-data="data">
    <wdx-badge [themeColor]="data.themeColor">{{
        data.label | titleCase
    }}</wdx-badge>
</ng-template>
