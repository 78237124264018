import { createAction, props } from '@ngrx/store';
import { PersonCreate } from '@wdx/clmi/api-models';

export const create = createAction(
    '[Prospect] Create',
    props<{
        prospectCreateData: PersonCreate;
    }>()
);

export const createSuccess = createAction(
    '[Prospect] Create Success',
    props<{
        prospect: PersonCreate;
    }>()
);

export const createFailure = createAction(
    '[Prospect] Create Failure',
    props<{
        error: Error;
    }>()
);
