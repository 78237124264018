import { Action, createReducer, on } from '@ngrx/store';
import { Campaign } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as marketingActions from './marketing.actions';

export interface State {
    campaigns?: CrudState<Campaign>;
    campaign?: CrudStateObject<Campaign>;
}

export const initialState: State = {
    campaigns: {},
    campaign: {}
};

const reducerSetup = createReducer(
    initialState,

    on(
        marketingActions.getCampaigns,
        (state): State => ({
            ...state,
            campaigns: {
                ...(state.campaigns || ({} as CrudState<Campaign>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        })
    ),
    on(
        marketingActions.getCampaignsSuccess,
        (state, props): State => ({
            ...state,
            campaigns: {
                ...(state.campaigns || ({} as CrudState<Campaign>)),
                isLoadingList: false,
                hasLoadingListError: false,
                list: props.campaigns,
            },
        })
    ),
    on(
        marketingActions.getCampaignsFailure,
        (state): State => ({
            ...state,
            campaigns: {
                ...(state.campaigns || ({} as CrudState<Campaign>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        marketingActions.createCampaign,
        (state): State => ({
            ...state,
            campaigns: {
                ...(state.campaigns || ({} as CrudState<Campaign>)),
                isCreating: true,
                hasCreatingError: false,
            },
        })
    ),
    on(
        marketingActions.createCampaignSuccess,
        (state, props): State => ({
            ...state,
            campaigns: {
                ...(state.campaigns || ({} as CrudState<Campaign>)),
                isCreating: false,
                hasCreatingError: false,
                list: addCampaignToList(state.campaigns.list, props.campaign),
            },
        })
    ),
    on(
        marketingActions.createCampaignFailure,
        (state): State => ({
            ...state,
            campaigns: {
                ...(state.campaigns || ({} as CrudState<Campaign>)),
                isCreating: false,
                hasCreatingError: true,
            },
        })
    ),
    on(
        marketingActions.getSingleCampaign,
        (state, props): State => ({
            ...state,
            campaign: {
                ...state.campaign,
                [props.campaignId]: {
                    ...(state.campaign[props.campaignId] || ({} as CrudState<Campaign>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        marketingActions.getSingleCampaignSuccess,
        (state, props): State => ({
            ...state,
            campaign: {
                ...state.campaign,
                [props.campaignId]: {
                    ...(state.campaign[props.campaignId] || ({} as CrudState<Campaign>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.campaign,
                },
            },
        })
    ),

    on(
        marketingActions.getSingleCampaignFailure,
        (state, props): State => ({
            ...state,
            campaign: {
                ...state.campaign,
                [props.campaignId]: {
                    ...(state.campaign[props.campaignId] || ({} as CrudState<Campaign>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),
    
);

function addCampaignToList(list: Campaign[], campaign: Campaign): Campaign[] {
    return list?.length ? [...list, campaign] : [campaign];
}

export const testFns = {
    addCampaignToList,
};

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
