import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    CHAT_FEATURE_KEY,
    ChatState,
    chatAdapter,
    getId,
} from './chat.reducer';
import { SystemEntity } from '@wdx/clmi/api-models';

// Lookup the 'Chat' feature state managed by NgRx
export const selectChatState =
    createFeatureSelector<ChatState>(CHAT_FEATURE_KEY);

const { selectEntities, selectAll } = chatAdapter.getSelectors();

export const selectAllChatEntities = createSelector(
    selectChatState,
    selectEntities
);
export const selectAllChats = createSelector(selectChatState, selectAll);

export const selectChat = (props: {
    entityId: string;
    entityType: SystemEntity;
}) =>
    createSelector(
        selectAllChatEntities,
        (chatEntities) => chatEntities[getId(props)]
    );

export const selectChatsOfType = (props: { entityType: SystemEntity }) =>
    createSelector(selectAllChats, (chatEntities) =>
        chatEntities.filter((chat) => chat.entityType === props.entityType)
    );
