import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from '@angular/core';

import { Icon, NavItem, Severity } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-context-menu',
    templateUrl: './molecule-context-menu.component.html',
})
export class MoleculeContextMenuComponent implements AfterViewInit, OnChanges {
    @Input() menu: NavItem[];
    @Input() highlightSelected = true;
    @Input() showCheckOnHighlight = true;
    @Input() isMulti: boolean;
    @Input() set initialValue(values: string[]) {
        const valuesArray = Array.isArray(values) ? values : [values];
        this.selectedMenuItemValue = valuesArray && valuesArray[0];
        this.selectedMenuItemValues = valuesArray;
    }
    @Input() hideIcons: boolean;
    @Input() ghostIcons: boolean;
    @Input() cySelector: string;

    @Output() singleItemSelected = new EventEmitter<NavItem>();
    @Output() multipleItemsSelected = new EventEmitter<NavItem[]>();

    activeMenus: NavItem[][] = [];

    selectedMenuItemValue: string;
    selectedMenuItemValues: string[] = [];

    readonly SEVERITY = Severity;

    ngAfterViewInit() {
        this.activeMenus = [this.menu];
    }

    ngOnChanges() {
        this.activeMenus = [this.menu];
    }

    onClick(item: NavItem) {
        if (item.callback) {
            item.callback();
        }

        if (this.highlightSelected) {
            this.selectItem(item);
        } else {
            this.singleItemSelected.emit(item);
        }
    }

    manageLinkedMenu(item: NavItem) {
        if (item.backButton) {
            this.activeMenus.splice(-1, 1);
        } else {
            this.activeMenus.push([
                {
                    label: item.label,
                    icon: 'chevron-left',
                    subMenu: item.linkedMenu$
                        ? item.linkedMenu$.value
                        : item.linkedMenu,
                    backButton: true,
                },
            ]);
        }
    }

    selectItem(navItem: NavItem) {
        if (this.isMulti) {
            const currentSelectedMenuItemIds =
                this.selectedMenuItemValues || [];

            this.selectedMenuItemValues = currentSelectedMenuItemIds.includes(
                navItem.value
            )
                ? currentSelectedMenuItemIds.filter(
                      (existingItem) => existingItem !== navItem.value
                  )
                : [...currentSelectedMenuItemIds, navItem.value];

            const menuItemsForSelectedMenuItemValues = this.menu.filter(
                (navItem) => this.selectedMenuItemValues.includes(navItem.value)
            );

            this.multipleItemsSelected.emit(menuItemsForSelectedMenuItemValues);
        } else {
            this.selectedMenuItemValue = navItem.value;

            this.singleItemSelected.emit(navItem);
        }
    }

    isSelected(
        navItem: NavItem,
        selectedMenuItemValues: string[],
        selectedMenuItemValue: string,
        isMulti: boolean
    ): boolean {
        if (isMulti) {
            return selectedMenuItemValues?.includes(navItem.value);
        } else {
            return (
                (navItem.value && navItem.value === selectedMenuItemValue) ||
                navItem.selected
            );
        }
    }

    clearSelection() {
        this.selectedMenuItemValue = null;
        this.selectedMenuItemValues = [];
    }

    trackByFn(icon: Icon) {
        return icon.stack;
    }
}
