import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    QueryList,
    ViewChild,
} from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-tile-carousel',
    templateUrl: './molecule-tile-carousel.component.html',
    styleUrls: ['./molecule-tile-carousel.component.scss'],
})
export class MoleculeTileCarouselComponent implements AfterViewInit {
    @Input() tiles: QueryList<any>;

    offset = 0;
    scrollMinReached = true;
    scrollMaxReached = false;

    @ViewChild('scrollContainer', { read: ElementRef })
    scrollContainer: ElementRef;

    ngAfterViewInit(): void {
        this.updateOffset();
    }

    setOffset(toLeft?: boolean) {
        const containerWidth = this.scrollContainer.nativeElement.offsetWidth;
        const tilesArray = this.tiles.toArray();
        const tilePositions =
            this.tiles.length &&
            tilesArray.map(
                (tile) => tile.nativeElement.offsetLeft - this.offset
            );
        const firstVisibleTileIndex = tilePositions.findIndex(
            (tilePosition) => tilePosition >= 0
        );

        let scrollToTileIndex = tilePositions.findIndex(
            (tilePosition) =>
                tilePosition >=
                tilePositions[firstVisibleTileIndex - (toLeft ? 1 : 0)] +
                    containerWidth * (toLeft ? -1 : 1)
        );

        if (scrollToTileIndex < 0) {
            scrollToTileIndex = tilePositions.length - 1;
        }

        this.offset += tilePositions[scrollToTileIndex];
        this.scrollContainer.nativeElement.scrollLeft = this.offset;
        this.updateMinMaxFlags();
    }

    left(): void {
        this.setOffset(true);
    }

    right(): void {
        this.setOffset();
    }

    updateMinMaxFlags(): void {
        const scrollLeft = this.scrollContainer.nativeElement.scrollLeft;
        const containerWidth = this.scrollContainer.nativeElement.offsetWidth;
        const scrollWidth = this.scrollContainer.nativeElement.scrollWidth;
        this.scrollMinReached = scrollLeft <= 0;
        this.scrollMaxReached = scrollLeft + containerWidth >= scrollWidth;
    }

    updateOffset(event?: Event): void {
        const target: any = event?.target;
        this.offset = target?.scrollLeft || 0;
        this.updateMinMaxFlags();
    }

    reset(): void {
        this.scrollContainer.nativeElement.scrollLeft = 0;
        this.offset = 0;
        this.scrollMinReached = true;
        this.scrollMaxReached = false;
    }
}
