import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { MoleculeTileMiniComponent } from './molecule-tile-mini.component';

@NgModule({
    imports: [CommonModule, RouterModule, WdxIconModule],
    declarations: [MoleculeTileMiniComponent],
    exports: [MoleculeTileMiniComponent],
})
export class MoleculeTileMiniModule {}
