<molecule-input-wrapper
    [formInputData]="formInputData"
    [formDisplayMode]="formDisplayMode"
    [formSummaryMode]="formSummaryMode"
    [inputOnly]="inputOnly"
    [validationErrorMessage]="validationErrorMessage"
    [hideGuidanceText]="hideGuidanceText"
    [value]="value"
    [useDefaultSummary]="false"
>
    <wdx-document-info
        *ngIf="fileIndex; else fileUpload"
        class="d-block mb-1 w-100"
        [fileIndex]="fileIndex"
    >
        <atom-icon-button
            *ngIf="!formInputData.isDisabled"
            class="ms-2"
            size="sm"
            icon="times"
            (onClick)="onRemoveFile()"
        ></atom-icon-button>
    </wdx-document-info>

    <ng-template #fileUpload>
        <ngx-dropzone
            #drop
            [accept]="acceptedFileTypes"
            [multiple]="false"
            [disableClick]="true"
            [disabled]="formInputData.isDisabled"
            (change)="onAddFiles($event)"
        >
            <ngx-dropzone-label
                *ngIf="
                    !fileIndex ||
                    (fileUploadPackage &&
                        fileUploadStatus !== FILE_UPLOAD_STATUS.Completed)
                "
            >
                <div *ngIf="!fileUploadPackage">
                    <div
                        *ngIf="!isAcceptedFileFormat"
                        class="danger-text form-section__body__blurb sub-3"
                    >
                        {{
                            formInputData.guidanceText ||
                                rejectedFileTypeMessage
                        }}
                    </div>
                    <div class="text-center body-text-medium">
                        <div>Drag and drop your file here</div>
                        <div>or</div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <atom-icon-button
                            class="mx-1"
                            icon="file-upload"
                            (click)="drop.showFileSelector()"
                        ></atom-icon-button>
                    </div>
                </div>

                <div
                    *ngIf="
                        fileUploadPackage &&
                        fileUploadStatus !== FILE_UPLOAD_STATUS.Completed
                    "
                    class="uploader__content--progress m-2"
                >
                    <div class="d-flex justify-content-between">
                        <ng-container
                            *ngIf="
                                fileUploadStatus !== FILE_UPLOAD_STATUS.Failed
                            "
                        >
                            <span
                                >Uploading:&nbsp;{{
                                    fileUploadPackage.file.name
                                }}</span
                            >
                        </ng-container>
                        <ng-container
                            *ngIf="
                                fileUploadStatus === FILE_UPLOAD_STATUS.Failed
                            "
                        >
                            <div>
                                Upload failed:&nbsp;{{
                                    fileUploadPackage.file.name
                                }}
                            </div>
                        </ng-container>
                    </div>

                    <ngb-progressbar
                        *ngIf="
                            fileUploadStatus | fn : statusStyling;
                            let barColor
                        "
                        [type]="barColor"
                        [textType]="barColor"
                        [value]="fileUploadProgress"
                    ></ngb-progressbar>

                    <div
                        *ngIf="fileUploadStatus === FILE_UPLOAD_STATUS.Failed"
                        class="d-flex align-items-center justify-content-center mt-4"
                    >
                        <button
                            class="btn btn-ghost me-1"
                            (click)="drop.showFileSelector()"
                        >
                            Retry
                        </button>
                        <button
                            class="btn btn-ghost ms-1"
                            (click)="onRemoveFile()"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </ngx-dropzone-label>
        </ngx-dropzone>
    </ng-template>
</molecule-input-wrapper>
