import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { CombinedContactPoint } from '../../models/combined-contact-point.model';

@Injectable()
export class ContactPointsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getForParty(partyId: string): Observable<CombinedContactPoint[]> {
        return this.http.get<CombinedContactPoint[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/contactpoint`
        );
    }
}
