import { createAction, props } from '@ngrx/store';
import { SelectFieldResult } from '@wdx/clmi/api-models';
import { ContextualDataContext } from '@wdx/clmi/api-services/models';

export const resetSelectOptions = createAction(
    '[Selects] Resets Select Options',
    props<{
        id: string;
    }>()
);

export const getSelectOptions = createAction(
    '[Selects] Get Select Options',
    props<{
        id: string;
    }>()
);

export const getSelectOptionsSuccess = createAction(
    '[Selects] Get Select Options Success',
    props<{
        id: string;
        results: SelectFieldResult[];
    }>()
);

export const getSelectOptionsFailure = createAction(
    '[Selects] Get Select Options Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const resetContextualSelect = createAction(
    '[Selects] Reset Contextual Select Options',
    props<{
        id: string;
    }>()
);

export const getContextualSelect = createAction(
    '[Selects] Get Contextual Select Options',
    props<{
        id: string;
        source: string;
        lookups: any[];
        context?: ContextualDataContext[];
    }>()
);

export const getContextualSelectOptionsSuccess = createAction(
    '[Selects] Get Contextual Select Options Success',
    props<{
        id: string;
        results: SelectFieldResult[];
    }>()
);

export const getContextualSelectOptionsFailure = createAction(
    '[Selects] Get Contextual Select Options Failure',
    props<{
        id: string;
        error: Error;
    }>()
);
