import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { Country } from '@wdx/clmi/api-models';

@Injectable()
export class CountriesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getCountries(): Observable<Country[]> {
        return this.http.get<Country[]>(
            `${this.config.getConfiguration().API_BASE}/country`
        );
    }
}
