import { createAction, props } from '@ngrx/store';
import {
    PartyRoleRelationship,
    RelationshipTypeSimple,
} from '@wdx/clmi/api-models';
import { RelationshipWrapper } from '../../models/relationship-wrapper.model';

export const getForPartyRole = createAction(
    '[Relationships] Get For Party Role',
    props<{
        partyRoleId: string;
    }>()
);

export const getForPartyRoleSuccess = createAction(
    '[Relationships] Get For Party Role Success',
    props<{
        partyRoleId: string;
        relationshipWrapper: RelationshipWrapper;
    }>()
);

export const getForPartyRoleFailure = createAction(
    '[Relationships] Get For Party Role Failure',
    props<{
        partyRoleId: string;
        error: Error;
    }>()
);

export const getFamilyForClient = createAction(
    '[Relationships] Get Family For Client',
    props<{
        clientId: string;
    }>()
);

export const getFamilyForClientSuccess = createAction(
    '[Relationships] Get Family For Client Success',
    props<{
        familyRelationships: PartyRoleRelationship[];
        clientId: string;
    }>()
);

export const getFamilyForClientFailure = createAction(
    '[Relationships] Get Family For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);

export const getApplicableFamilyRoles = createAction(
    '[Relationships] Get Applicable Family Roles',
    props<{
        clientId: string;
    }>()
);

export const getApplicableFamilyRolesSuccess = createAction(
    '[Relationships] Get Applicable Family Roles Success',
    props<{
        applicableFamilyRoles: RelationshipTypeSimple[];
        clientId: string;
    }>()
);

export const getApplicableFamilyRolesFailure = createAction(
    '[Relationships] Get Applicable Family Roles Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);
