import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'obfuscateText' })
export class obfuscateTextPipe implements PipeTransform {
    transform(originalText: string, replaceAllButLastCharsCount: number): any {
        if (!originalText || !replaceAllButLastCharsCount) {
            return originalText;
        }

        const regex = new RegExp(
            `(\\w| )(?=(\\w| ){${replaceAllButLastCharsCount}})`,
            'g'
        );

        return originalText.replace(regex, '*');
    }
}
