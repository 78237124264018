import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'wdx-checkbox-tile-group',
    templateUrl: './wdx-checkbox-tile-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'input-check-tiles',
    },
})
export class WdxCheckboxTileGroupComponent {}
