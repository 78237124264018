import { createAction, props } from '@ngrx/store';
import { WebHook } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const LoadWebHooks = createAction(
    '[WebHooks] Load WebHooks',
    props<{
        reset: boolean;
    }>()
);

export const LoadWebHooksSuccess = createAction(
    '[WebHooks] Load WebHooks Success',
    props<{
        webHooks: PaginatedApiResponse<WebHook>;
    }>()
);

export const LoadWebHooksFailure = createAction(
    '[WebHooks] Load WebHooks Failure',
    props<{
        error: Error;
    }>()
);
