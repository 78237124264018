import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    FieldDefinition,
    UTILS_ICON_DANGER,
    UTILS_ICON_DELETE,
} from '@wdx/shared/utils';
import { GET_STATUS_PENDING_CHANGES } from '../../services/form-pending-changes/form-pending-changes.helper';

@Component({
    selector: 'wdx-ff-accordion-header',
    templateUrl: './form-accordion-header.component.html',
})
export class FormAccordionHeaderComponent {
    @Input() entityId!: string;
    @Input() place!: any;
    @Input() elementDefinition!: FieldDefinition;
    @Input() parentData!: any;
    @Input() formControlObj!: UntypedFormGroup;
    @Input() set panelInfo(val: string[]) {
        let found = false;
        val.forEach((item) => {
            const STR_ARR = item.split('-');
            if (`${STR_ARR[0]}-${this.place}` === item) {
                this.open = true;
                found = true;
            }
        });

        if (!found) {
            this.open = false;
        }
    }
    @Input() hasError = false;
    @Input() disabled = false;
    @Input() status!: string;

    @Output() removeItem = new EventEmitter();

    get statusInfo(): string {
        return (GET_STATUS_PENDING_CHANGES as Record<string, string>)[
            this.status
        ];
    }

    open!: boolean;
    readonly ICON_DANGER = UTILS_ICON_DANGER;
    readonly ICON_REMOVE = UTILS_ICON_DELETE;

    removeItemClicked(): void {
        this.removeItem.emit();
    }
}
