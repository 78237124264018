import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FileIndex } from '@wdx/clmi/api-models';
import { userActions } from '@wdx/clmi/api-services/services';
import { Observable } from 'rxjs';
import { BaseInputClass } from '../../../../../classes/base-input-class';
import { UploaderType } from '../../../../../models/uploader-type.model';
import * as rootReducer from '../../../../../state/_setup/reducers';
import * as fileUploadActions from '../../../../../state/avatar-profile-file-upload/avatar-profile-file-upload.actions';
import * as fileUploadSelectors from '../../../../../state/avatar-profile-file-upload/avatar-profile-file-upload.selectors';
import {
    FileUploadPackage,
    FileUploadStatus,
} from '@wdx/shared/infrastructure/file-io';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-input-profile-avatar-upload',
    templateUrl: './molecule-input-profile-avatar-upload.component.html',
})
export class MoleculeInputProfileAvatarUploadComponent
    extends BaseInputClass
    implements OnInit
{
    @Input() type: UploaderType;

    fileUploadStatus$: Observable<FileUploadStatus>;
    fileUploadProgress$: Observable<number>;
    fileIndex$: Observable<FileIndex>;
    fileUrl$: Observable<string>;

    valueFileIndex: FileIndex;

    constructor(
        actions$: Actions,
        elementRef: ElementRef,
        store$: Store<rootReducer.State>
    ) {
        super();

        this.patchInjectedItems({
            actions$,
            elementRef,
            store$,
        });
    }

    ngOnInit() {
        this.listenTo(fileUploadActions.uploadFileComplete).subscribe(() => {
            // reload avatar image
            this.store$.dispatch(userActions.getMeAction());
        });
    }

    onFileAdded(fileUploadPackage: FileUploadPackage): void {
        this.store$.dispatch(
            fileUploadActions.uploadFile({
                id: fileUploadPackage.id,
                file: fileUploadPackage.file,
            })
        );

        this.fileUploadStatus$ = this.store$.select(
            fileUploadSelectors.getFileUploadStatus,
            { id: fileUploadPackage.id }
        );
        this.fileUploadProgress$ = this.store$.select(
            fileUploadSelectors.getFileUploadProgress,
            { id: fileUploadPackage.id }
        );
        this.fileIndex$ = this.store$.select(
            fileUploadSelectors.getFileUploadFileIndex,
            { id: fileUploadPackage.id }
        );
    }

    onRemoveFile(fileUploadPackage: FileUploadPackage) {
        this.onClear();

        if (fileUploadPackage) {
            this.store$.dispatch(
                fileUploadActions.uploadFileReset({ id: fileUploadPackage.id })
            );
        }
    }
}
