<molecule-widget-container
    [widgetTitle]="widgetTitle"
    [widgetSize]="widgetSize"
    [spokeButton]="spokeButton"
    [isLoading]="isLoading"
    [hasError]="hasError"
    [count]="totalRecordCount"
    [hideCount]="hideCount"
    [numberToCompareToLimit]="opportunities?.length"
    [limit]="limit"
    [showNoDataMessage]="!opportunities?.length"
    [views]="views"
    (viewSelected)="onViewSelected($event)"
    (spokeButtonClicked)="onSpokeButtonClicked()"
>
    <div
        *ngIf="showOpportunitiesListActionsButtons"
        class="widget-header-action-buttons"
    >
        <ng-content select.opportunities-list-action-buttons></ng-content>
    </div>
    <organism-opportunities-list
        class="d-block h-100"
        [opportunities]="opportunities"
        [opportunitiesPerRow]="opportunitiesPerRow"
        [totalRecordCount]="
            totalRecordCount || opportunitiesPaging?.totalRecords
        "
        [isLoading]="isLoading"
        [showFilterBar]="false"
        [showSearchBar]="false"
        [showFavourite]="false"
        [showNoDataSVG]="false"
        [showDeleteLinkMenuItem]="showDeleteLinkMenuItem"
        [limit]="limit"
        [scrollMode]="scrollMode"
        [partyRoleId]="partyRoleId"
        [linkToEntityType]="linkToEntityType"
        [linkToEntityId]="linkToEntityId"
        (assignTo)="onAssignTo($event)"
    >
        <div class="widget-header-action-buttons">
            <ng-content select.cases-list-action-buttons></ng-content>
        </div>
    </organism-opportunities-list>
</molecule-widget-container>

<atom-modal-wrapper
    size="xl"
    [modalId]="modalId"
    [modalTemplate]="opportunitiesModal"
>
    <ng-template #opportunitiesModal>
        <organism-modal
            [modalTitle]="'ALL_OPPORTUNITIES_MODAL_TITLE' | translate"
            data-cy="modal-opportunities"
            (modalClosed)="onModalClosed()"
        >
            <organism-opportunities-list
                class="d-block h-100 m-4"
                [opportunities]="opportunities"
                [totalRecordCount]="totalRecordCount"
                [isLoading]="isLoading"
                [filterHandler]="filterHandler"
                [showFilterBar]="false"
                [showSearchBar]="false"
                [showFavourite]="false"
                [showDeleteLinkMenuItem]="showDeleteLinkMenuItem"
                [partyId]="partyId"
                [partyRoleId]="partyRoleId"
                [linkToEntityType]="linkToEntityType"
                [linkToEntityId]="linkToEntityId"
                (infinityScrollFired)="onInfinityScrollFired()"
                (onAssign)="onAssignTo($event)"
            ></organism-opportunities-list>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
