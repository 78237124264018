import { NgModule } from '@angular/core';
import { ActivityAsToDoItemPipe } from './activity-as-to-do-item.pipe';

const allMappers = [ActivityAsToDoItemPipe];
@NgModule({
    declarations: allMappers,
    exports: allMappers,
    providers: allMappers,
})
export class MappersModule {}
