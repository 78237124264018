import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileSize', standalone: true })
export class FileSizePipe implements PipeTransform {
    private readonly units: string[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    transform(bytes?: number): string {
        if (
            bytes === undefined ||
            bytes === null ||
            isNaN(parseFloat(String(bytes))) ||
            !isFinite(bytes)
        ) {
            return '?';
        }

        let unitIndex = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unitIndex++;
        }

        const unit = this.units[unitIndex];

        let precision: number;

        switch (unit) {
            case 'bytes':
            case 'KB':
                precision = 0;
                break;
            case 'MB':
            case 'GB':
                precision = 1;
                break;
            case 'TB':
            case 'PB':
                precision = 2;
                break;
            default:
                precision = 0;
        }

        return `${bytes.toFixed(precision)} ${unit}`;
    }
}
