import { Action, createReducer, on } from '@ngrx/store';
import { ActivityChat } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { SCROLL_DIRECTION } from '../../constants/inifinity-scroll-content.contants';
import { SearchActivityChat } from '../../models/chat-message.model';
import * as chatsActions from './chats.actions';

export interface State {
    chats: CrudStateObject<ActivityChat>;
    searchChats: CrudStateObject<SearchActivityChat>;
    goToChatResultId: string;
    goToChatPageNumber: number;
    isLastPage: boolean;
    loadedBottomPage: number;
    scrollDirection: string;
}

export const initialState: State = {
    chats: {},
    searchChats: {},
    goToChatResultId: '',
    goToChatPageNumber: 1,
    isLastPage: false,
    loadedBottomPage: 1,
    scrollDirection: SCROLL_DIRECTION.UP,
};

const reducerSetup = createReducer(
    initialState,

    on(chatsActions.getForActivity, (state, props): State => {
        return {
            ...state,
            chats: {
                ...state.chats,
                [props.activityId]: {
                    ...(state.chats[props.activityId] ||
                        ({} as CrudState<ActivityChat>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                    list: props.reset
                        ? []
                        : state.chats[props.activityId]?.list,
                    page: props.reset
                        ? null
                        : state.chats[props.activityId]?.page,
                },
            },
        };
    }),

    on(
        chatsActions.getForActivitySuccess,
        (state, props): State => ({
            ...state,
            chats: {
                ...state.chats,
                [props.activityId]: {
                    ...(state.chats[props.activityId] ||
                        ({} as CrudState<ActivityChat>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.chats.results,
                    page: props.chats,
                },
            },
        })
    ),

    on(
        chatsActions.getForActivityFailure,
        (state, props): State => ({
            ...state,
            chats: {
                ...state.chats,
                [props.activityId]: {
                    ...(state.chats[props.activityId] ||
                        ({} as CrudState<ActivityChat>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(chatsActions.searchChat, (state, props): State => {
        return {
            ...state,
            searchChats: {
                ...state.searchChats,
                [props.activityId]: {
                    ...(state.searchChats[props.activityId] ||
                        ({} as CrudState<SearchActivityChat>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                    list: props.reset
                        ? []
                        : state.searchChats[props.activityId]?.list,
                    page: props.reset
                        ? null
                        : state.searchChats[props.activityId]?.page,
                },
            },
        };
    }),

    on(
        chatsActions.searchChatSuccess,
        (state, props): State => ({
            ...state,
            searchChats: {
                ...state.searchChats,
                [props.activityId]: {
                    ...(state.searchChats[props.activityId] ||
                        ({} as CrudState<SearchActivityChat>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.chats.results,
                    page: props.chats,
                },
            },
        })
    ),

    on(
        chatsActions.searchChatFailure,
        (state, props): State => ({
            ...state,
            searchChats: {
                ...state.searchChats,
                [props.activityId]: {
                    ...(state.searchChats[props.activityId] ||
                        ({} as CrudState<SearchActivityChat>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(chatsActions.goToSearchResult, (state, props): State => {
        return {
            ...state,
            goToChatResultId: props.chatId,
            goToChatPageNumber: props.pageNumber,
        };
    }),

    on(chatsActions.saveIsLastPage, (state, props): State => {
        return {
            ...state,
            isLastPage: props.isLastPage,
        };
    }),

    on(chatsActions.saveLoadedBottomPage, (state, props): State => {
        return {
            ...state,
            loadedBottomPage: props.pageNumber,
        };
    }),

    on(chatsActions.saveScrollDirection, (state, props): State => {
        return {
            ...state,
            scrollDirection: props.scrollDirection,
        };
    })
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
