import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StatusType } from '@wdx/clmi/api-models';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    Severity,
    SignalrEvent,
    TRANSLATION_TOAST_MESSAGE_TEAM_MEMBER_REMOVED,
    TranslationsService,
} from '@wdx/shared/utils';
import { mergeMapHubToAction, signalrConnected } from 'ngrx-signalr-core';
import { merge, of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as rootReducer from '../../state/_setup/reducers';
import * as partiesSelector from '../../state/parties/parties.selectors';
import * as partiesActions from './parties.actions';
import { PartiesService } from './parties.service';

@Injectable()
export class PartiesEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private partiesService = inject(PartiesService);
    private toastService = inject(WdxToastService);
    private translationsService = inject(TranslationsService);

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.getForId),
            mergeMap((action) =>
                this.partiesService.getForId(action.partyId).pipe(
                    map((party) =>
                        partiesActions.getForIdSuccess({
                            partyId: action.partyId,
                            party,
                        })
                    ),
                    catchError((error) =>
                        of(
                            partiesActions.getForIdFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getKeyStatsForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.getKeyStatsForParty),
            mergeMap((action) =>
                this.partiesService.getKeyStatsForParty(action.partyId).pipe(
                    map((keyStats) =>
                        partiesActions.getKeyStatsForPartySuccess({
                            partyId: action.partyId,
                            keyStatsForParty: keyStats,
                        })
                    ),
                    catchError((error) =>
                        of(
                            partiesActions.getKeyStatsForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getHeatMapForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.getHeatMapForParty),
            switchMap((action) =>
                this.partiesService.getHeatMapForParty(action.partyId).pipe(
                    map((heatMap) =>
                        partiesActions.getHeatMapForPartySuccess({
                            partyId: action.partyId,
                            heatMap,
                        })
                    ),
                    catchError((error) =>
                        of(
                            partiesActions.getHeatMapForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getTeamMembersForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.getTeamMembersForParty),
            mergeMap((action) =>
                this.partiesService.getTeamMembersForParty(action.partyId).pipe(
                    map((teamMembers) =>
                        partiesActions.getTeamMembersForPartySuccess({
                            partyId: action.partyId,
                            teamMembersForParty: teamMembers,
                        })
                    ),
                    catchError((error) =>
                        of(
                            partiesActions.getTeamMembersForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getTeamMembersForPartyRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.getTeamMembersForPartyRole),
            mergeMap((action) =>
                this.partiesService
                    .getTeamMembersForPartyRole(action.partyRoleId)
                    .pipe(
                        map((teamMembers) =>
                            partiesActions.getTeamMembersForPartyRoleSuccess({
                                partyRoleId: action.partyRoleId,
                                teamMembersForPartyRole: teamMembers,
                            })
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.getTeamMembersForPartyRoleFailure(
                                    {
                                        partyRoleId: action.partyRoleId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteTeamMemberForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.deleteTeamMemberForParty),
            mergeMap((action) =>
                this.partiesService
                    .deleteTeamMemberForParty(action.partyId, action.memberId)
                    .pipe(
                        map(() =>
                            partiesActions.deleteTeamMemberForPartySuccess({
                                partyId: action.partyId,
                                memberId: action.memberId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.deleteTeamMemberForPartyFailure({
                                    partyId: action.partyId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteTeamMemberForPartyRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.deleteTeamMemberForPartyRole),
            mergeMap((action) =>
                this.partiesService
                    .deleteTeamMemberForPartyRole(
                        action.partyRoleId,
                        action.memberId
                    )
                    .pipe(
                        map(() =>
                            partiesActions.deleteTeamMemberForPartyRoleSuccess({
                                partyRoleId: action.partyRoleId,
                                memberId: action.memberId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.deleteTeamMemberForPartyRoleFailure(
                                    {
                                        partyRoleId: action.partyRoleId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteTeamMemberForPartySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(partiesActions.deleteTeamMemberForPartySuccess),
                tap(() => {
                    this.toastService.show({
                        body: [
                            this.translationsService.getTranslationByKey(
                                TRANSLATION_TOAST_MESSAGE_TEAM_MEMBER_REMOVED
                            ),
                        ],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );

    deleteTeamMemberForPartyRoleSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(partiesActions.deleteTeamMemberForPartyRoleSuccess),
                tap(() => {
                    this.toastService.show({
                        body: [
                            this.translationsService.getTranslationByKey(
                                TRANSLATION_TOAST_MESSAGE_TEAM_MEMBER_REMOVED
                            ),
                        ],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );

    promotePartyToLead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.promotePartyToLead),
            switchMap((action) =>
                this.partiesService.promotePartyToLead(action.partyId).pipe(
                    map((party) =>
                        partiesActions.promotePartyToLeadSuccess({
                            partyId: party.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            partiesActions.promotePartyToLeadFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    toggleStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.toggleStatus),
            map((action) =>
                action.newValue
                    ? partiesActions.activateParty({ partyId: action.partyId })
                    : partiesActions.inactivateParty({
                          partyId: action.partyId,
                      })
            )
        )
    );

    activate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.activateParty),
            mergeMap((action) =>
                this.partiesService
                    .setPartyStatus(action.partyId, StatusType.Active)
                    .pipe(
                        map(() =>
                            partiesActions.activatePartySuccess({
                                partyId: action.partyId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.activatePartyFailure({
                                    partyId: action.partyId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    inactivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.inactivateParty),
            mergeMap((action) =>
                this.partiesService
                    .setPartyStatus(action.partyId, StatusType.Inactive)
                    .pipe(
                        map(() =>
                            partiesActions.inactivatePartySuccess({
                                partyId: action.partyId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.inactivatePartyFailure({
                                    partyId: action.partyId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    resendInvite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.resendInvite),
            mergeMap((action) =>
                this.partiesService.resendInvite(action.partyId).pipe(
                    map(() =>
                        partiesActions.resendInviteSuccess({
                            partyId: action.partyId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            partiesActions.resendInviteFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    setPrimaryTeamMemberForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.setPrimaryTeamMemberForParty),
            mergeMap((action) =>
                this.partiesService
                    .setPrimaryTeamMemberForParty(
                        action.partyId,
                        action.teamMemberId
                    )
                    .pipe(
                        map(() =>
                            partiesActions.setPrimaryTeamMemberForPartySuccess({
                                partyId: action.partyId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.setPrimaryTeamMemberForPartyFailure(
                                    {
                                        partyId: action.partyId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    setPrimaryTeamMemberForPartyRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.setPrimaryTeamMemberForPartyRole),
            mergeMap((action) =>
                this.partiesService
                    .setPrimaryTeamMemberForPartyRole(
                        action.partyRoleId,
                        action.teamMemberId
                    )
                    .pipe(
                        map(() =>
                            partiesActions.setPrimaryTeamMemberForPartyRoleSuccess(
                                {
                                    partyRoleId: action.partyRoleId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.setPrimaryTeamMemberForPartyRoleFailure(
                                    {
                                        partyRoleId: action.partyRoleId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getVisibilityForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.getVisibilityForParty),
            mergeMap((action) =>
                this.partiesService.getVisibilityForParty(action.partyId).pipe(
                    map((visibility) =>
                        partiesActions.getVisibilityForPartySuccess({
                            partyId: action.partyId,
                            visibility,
                        })
                    ),
                    catchError((error) =>
                        of(
                            partiesActions.getVisibilityForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getAllTasksForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.getAllTasksForParty),
            withLatestFrom(
                this.store$.select(
                    partiesSelector.getTasksForPartyInfinityPaging,
                    {
                        partyId: GLOBAL_STATE_INDEX_ID,
                    }
                )
            ),
            switchMap(([action, paging]) =>
                this.partiesService
                    .getAllTasksForParty(
                        {
                            pageNumber: (paging?.page || 0) + 1,
                        },
                        action.partyId
                    )
                    .pipe(
                        map((tasks) =>
                            partiesActions.getAllTasksForPartySuccess({
                                tasks,
                                partyId: action.partyId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.getAllTasksForPartyFailure({
                                    partyId: action.partyId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    listenToPartyUpdatedEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    hub.on(SignalrEvent.PartyUpdated).pipe(
                        map((id: string) =>
                            partiesActions.partyUpdatedSuccess({
                                partyId: id,
                            })
                        )
                    )
                )
            )
        )
    );

    listenToPartyCasesUpdatedEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    hub.on(SignalrEvent.PartyCasesUpdated).pipe(
                        map((id: string) =>
                            partiesActions.partyCasesUpdatedSuccess({
                                partyId: id,
                            })
                        )
                    )
                )
            )
        )
    );

    getForPartyOrganisationConnections$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.getForPartyOrganisationConnections),
            mergeMap((action) =>
                this.partiesService
                    .getForPartyOrganisationConnections(action.partyId)
                    .pipe(
                        map((relationships) =>
                            partiesActions.getOrganisationConnectionsForPartySuccess(
                                {
                                    partyId: action.partyId,
                                    relationships,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.getOrganisationConnectionsForPartyFailure(
                                    {
                                        partyId: action.partyId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getMembershipListsForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partiesActions.getMembershipListsForEntity),
            switchMap((action) =>
                this.partiesService
                    .getMembershipListsForEntity(
                        action.entityId,
                        action.entityType
                    )
                    .pipe(
                        map((lists) =>
                            partiesActions.getMembershipListsForEntitySuccess({
                                lists,
                                entityId: action.entityId,
                                entityType: action.entityType,
                            })
                        ),
                        catchError((error) =>
                            of(
                                partiesActions.getMembershipListsForEntityFailure(
                                    {
                                        error,
                                        entityId: action.entityId,
                                        entityType: action.entityType,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
