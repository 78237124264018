import { Component, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import { View } from '@wdx/clmi/api-models';
import { NavItem } from '@wdx/shared/utils';
import { BaseWidgetContainerClass } from '../../../classes/base-widget-container.class';
import { FilterHandler } from '../../../classes/filter-handler.class';
import { SpokeButton } from '../../../models/spoke-button.model';
import { FeatureSvg } from '../../../models/svg.model';
import { ViewsAsMenuItemsPipe } from '../../../pipes/views-as-menu-items.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-widget-container',
    templateUrl: './molecule-widget-container.component.html',
    styleUrls: ['./molecule-widget-container.component.scss'],
})
export class MoleculeWidgetContainerComponent extends BaseWidgetContainerClass {
    private viewsAsMenuItemsPipe = inject(ViewsAsMenuItemsPipe);

    @Input() numberToCompareToLimit: number;
    @Input() disableOverflowClasses: boolean;
    @Input() set views(v: View[]) {
        this._views = v;
        if (v?.length) {
            this.viewsMenu = this.viewsAsMenuItemsPipe.transform(v);
        }
    }
    get views(): View[] {
        return this._views;
    }
    @Input() filterHandler: FilterHandler;
    @Input() sortMenu: NavItem[];

    _views: View[];
    viewsMenu: NavItem[];

    readonly NO_DATA_SVG = FeatureSvg.NoData;

    constructor(elementRef: ElementRef, renderer: Renderer2) {
        super();
        this.patchInjectedItems({
            elementRef,
            renderer,
        });
    }

    hideSpokeButton(
        spokeButton: SpokeButton,
        numberToCompareToLimit: number,
        limit: number
    ) {
        if (numberToCompareToLimit && limit) {
            return numberToCompareToLimit <= limit;
        }
        return spokeButton.isHidden;
    }
}
