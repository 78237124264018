<wdx-badge [themeColor]="themeColor">
    <div class="d-flex align-items-center">
        <molecule-avatar
            *ngIf="avatar"
            class="me-2"
            size="sm"
            [avatar]="avatar"
        ></molecule-avatar>
        <wdx-icon icon="paperclip" scale="1"></wdx-icon>
        <span class="ms-1 me-2">{{ files?.length ? files.length : 0 }}</span>
        <wdx-icon class="me-1" scale="1" [icon]="icon"></wdx-icon>

        <span>
            {{ label }}
        </span>
    </div>
</wdx-badge>
