import { createAction, props } from '@ngrx/store';
import { License } from '@wdx/clmi/api-models';

export const getLicense = createAction('[License] Get License');
export const getLicenseSuccess = createAction(
    '[License] Get License Success',
    props<{
        license: License;
    }>()
);
export const getLicenseFailure = createAction(
    '[License] Get License Failure',
    props<{
        error: Error;
    }>()
);
