import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml', standalone: true })
export class WdxSafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, option?: 'resourceUrl') {
        if (option === 'resourceUrl') {
            return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        }

        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
