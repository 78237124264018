import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LicenseService } from '@wdx/clmi/api-services/services';
import { catchError, map, of, switchMap } from 'rxjs';
import * as licenseActions from './license.actions';

@Injectable()
export class LicenseEffects {
    private actions$ = inject(Actions);
    private licenseService = inject(LicenseService);

    getLicense$ = createEffect(() =>
        this.actions$.pipe(
            ofType(licenseActions.getLicense),
            switchMap(() =>
                this.licenseService.getLicense().pipe(
                    map((license) =>
                        licenseActions.getLicenseSuccess({
                            license,
                        })
                    ),
                    catchError((error) =>
                        of(
                            licenseActions.getLicenseFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
