import { Action, createReducer, on } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import {
    Message,
    MessageActions,
} from '../../shared/features/message/models/message.model';
import * as messagesAction from './message.actions';

import {
    ICON_DANGER,
    ICON_INFO,
    ICON_SUCCESS,
    ICON_WARNING,
} from '../../constants/icons.constants';

export interface State {
    messages: Message[];
}

export const initialState: State = {
    messages: [],
};

const reducerSetup = createReducer(
    initialState,

    on(
        messagesAction.addErrorMessage,
        (state, props): State => ({
            ...state,
            messages: [
                ...state.messages,
                new Message({
                    id: uuidv4(),
                    type: 'danger',
                    heading: props.message.heading || 'Something went wrong!',
                    message: props.message.message,
                    subMessage: props.message.subMessage,
                    actionType:
                        props.message?.actionType || MessageActions.Logout,
                    actionText:
                        props.message?.actionText || 'Back to Login page',
                    icon: ICON_DANGER.icon,
                }),
            ],
        })
    ),

    on(
        messagesAction.addWarningMessage,
        (state, props): State => ({
            ...state,
            messages: [
                ...state.messages,
                new Message({
                    id: uuidv4(),
                    type: 'warning',
                    heading: props.message.heading,
                    message: props.message.message,
                    subMessage: props.message.subMessage,
                    actionType: props.message?.actionType,
                    actionText: props.message?.actionText,
                    icon: ICON_WARNING.icon,
                }),
            ],
        })
    ),

    on(
        messagesAction.addInfoMessage,
        (state, props): State => ({
            ...state,
            messages: [
                ...state.messages,
                new Message({
                    id: uuidv4(),
                    type: 'info',
                    heading: props.message.heading,
                    message: props.message.message,
                    subMessage: props.message.subMessage,
                    actionType: props.message?.actionType,
                    actionText: props.message?.actionText,
                    icon: ICON_INFO.icon,
                }),
            ],
        })
    ),

    on(
        messagesAction.addSuccessMessage,
        (state, props): State => ({
            ...state,
            messages: [
                ...state.messages,
                new Message({
                    id: uuidv4(),
                    type: 'success',
                    heading: props.message.heading,
                    message: props.message.message,
                    subMessage: props.message.subMessage,
                    actionType: props.message?.actionType,
                    actionText: props.message?.actionText,
                    icon: ICON_SUCCESS.icon,
                }),
            ],
        })
    ),

    on(messagesAction.removeMessage, (state, props): State => {
        const messages: Message[] = state.messages.filter(
            (message: Message) => message.id !== props.message.id
        );
        return {
            ...state,
            messages,
        };
    }),

    on(messagesAction.removeAllMessages, (state): State => {
        return {
            ...state,
            messages: [],
        };
    })
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
