<molecule-input-upload-dumb
    [formInputData]="formInputData"
    [formDisplayMode]="formDisplayMode"
    [formSummaryMode]="formSummaryMode"
    [inputOnly]="inputOnly"
    [type]="uploadType"
    [fileUploadStatus]="fileUploadStatus$ | ngrxPush"
    [fileUploadProgress]="fileUploadProgress$ | ngrxPush"
    [fileIndex]="(fileIndex$ | ngrxPush) || valueFileIndex"
    [fileUrl]="fileUrl$ | ngrxPush"
    (fileAdded)="onFileAdded($event)"
    (removeFile)="onRemoveFile($event)"
></molecule-input-upload-dumb>
