import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'textSearch' })
export class TextSearchPipe implements PipeTransform {
    transform(searchText: string, target: any): boolean {
        if (!searchText || !target) {
            return true;
        }

        return this.valuePassesTextSearch(searchText, target);
    }

    private valuePassesTextSearch(searchText: string, value: any): boolean {
        if (!value) {
            return false;
        }
        switch (typeof value) {
            case 'string':
                return value.toLowerCase().includes(searchText.toLowerCase());

            case 'number':
                return String(value)
                    .toLowerCase()
                    .includes(searchText.toLowerCase());

            case 'object':
                return Array.isArray(value)
                    ? value.some((childValue) =>
                          this.valuePassesTextSearch(searchText, childValue)
                      )
                    : Object.values(value).some((childValue) =>
                          this.valuePassesTextSearch(searchText, childValue)
                      );

            default:
                return false;
        }
    }
}
