import { KeyValueObject } from '@wdx/shared/utils';

export const MEDIA_BREAKPOINT_XS = 0;
export const MEDIA_BREAKPOINT_SM = 425;
export const MEDIA_BREAKPOINT_MD = 768;
export const MEDIA_BREAKPOINT_LG = 1024;
export const MEDIA_BREAKPOINT_XL = 1440;
export const MEDIA_BREAKPOINT_XXL = 2560;

export const BREAKPOINTS: KeyValueObject<number> = {
    xs: MEDIA_BREAKPOINT_XS,
    sm: MEDIA_BREAKPOINT_SM,
    md: MEDIA_BREAKPOINT_MD,
    lg: MEDIA_BREAKPOINT_LG,
    xl: MEDIA_BREAKPOINT_XL,
    xxl: MEDIA_BREAKPOINT_XXL,
};
