import { createSelector } from '@ngrx/store';
import * as caseTypesReducer from './case-types.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    caseTypes: caseTypesReducer.State;
}): caseTypesReducer.State => state.caseTypes;

/**
 * Reducer property selectors
 */
export const getIsLoadingList = createSelector(
    getState,
    (state) => state.isLoadingList
);
export const getHasLoadingListError = createSelector(
    getState,
    (state) => state.hasLoadingListError
);
export const getList = createSelector(getState, (state) => state.list);
