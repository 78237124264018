import { Action, createReducer, on } from '@ngrx/store';
import { SignalrEvent } from '@wdx/shared/utils';
import { newSystemEvent, newSystemSignalrEvent } from './system-events.actions';

export const SYSTEM_EVENTS_FEATURE_KEY = 'systemEvents';

export interface SystemEvent {
    sourceAction: Action;
    timeStamp: number;
}

export interface SystemSignalrEvent {
    signalrEvent: SignalrEvent;
    timeStamp: number;
    data?: any;
}

export interface State {
    events: SystemEvent[];
    signalrEvents: SystemSignalrEvent[];
}

export const initialState: State = {
    events: [],
    signalrEvents: [],
};

const reducerSetup = createReducer(
    initialState,

    on(
        newSystemEvent,
        (state, props): State => ({
            ...state,
            events: [...state.events, props.event],
        })
    ),

    on(
        newSystemSignalrEvent,
        (state, props): State => ({
            ...state,
            signalrEvents: [...state.signalrEvents, props.event],
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
