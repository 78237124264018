import { Avatar } from '@wdx/shared/components/wdx-avatar';
import { Badge } from '@wdx/shared/components/wdx-badge';

export interface WdxAuditInfo {
    label: string;
    type: WdxAuditType;
    beforeValue?: WdxAuditValue;
    updateValue?: WdxAuditValue;
    dateOfChange?: string | Date;
    user?: Avatar;
    children?: WdxAuditInfo[];
    hasChanged?: boolean;
}

export interface WdxAuditValue {
    label: string;
    value?: string;
    subtitle?: string;
    badge?: Badge;
}

export enum WdxAuditType {
    Single = 'Single',
    Array = 'Array',
    SubForm = 'SubForm',
    Section = 'Section',
    Index = 'Index',
}

export interface WdxAuditTimeline {
    x: number;
    name: string;
    label: string;
}
