import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PushModule } from '@ngrx/component';
import { WdxCheckboxModule } from '@wdx/shared/components/wdx-checkbox';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconStackModule } from '@wdx/shared/components/wdx-icon-stack';
import { WdxPaginationModule } from '@wdx/shared/components/wdx-pagination';
import { WdxSwitchModule } from '@wdx/shared/components/wdx-switch';
import { FunctionPipe, SeverityPipe, TranslatePipe } from '@wdx/shared/utils';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomFeatureSvgModule } from '../../../components/atoms/atom-feature-svg/atom-feature-svg.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeAvatarGroupModule } from '../../../components/molecules/molecule-avatar-group/molecule-avatar-group.module';
import { MoleculeContextMenuDropdownModule } from '../../../components/molecules/molecule-context-menu-dropdown/molecule-context-menu-dropdown.module';
import { MoleculeLegendModule } from '../../../components/molecules/molecule-legend/molecule-legend.module';
import { MoleculeMultiselectBannerModule } from '../../../components/molecules/molecule-multiselect-banner/molecule-multiselect-banner.module';
import { MoleculeSearchAndFilterBarModule } from '../../../components/molecules/molecule-search-and-filter-bar/molecule-search-and-filter-bar.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { OrganismTableComponent } from './organism-table.component';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomFeatureSvgModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        MoleculeActionButtonModule,
        MoleculeAvatarGroupModule,
        MoleculeContextMenuDropdownModule,
        MoleculeLegendModule,
        MoleculeMultiselectBannerModule,
        MoleculeSearchAndFilterBarModule,
        PipesModule,
        PushModule,
        RouterModule,
        WdxCheckboxModule,
        WdxSwitchModule,
        WdxIconStackModule,
        WdxIconModule,
        WdxPaginationModule,
        SeverityPipe,
        FunctionPipe,
        TranslatePipe,
    ],
    declarations: [OrganismTableComponent],
    exports: [OrganismTableComponent],
})
export class OrganismTableModule {}
