import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as processTypesActions from './process-types.actions';
import { ProcessTypesService } from './process-types.service';

@Injectable()
export class ProcessTypesEffects {
    private actions$ = inject(Actions);
    private processTypesService = inject(ProcessTypesService);

    getProcessTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(processTypesActions.getProcessTypes),
            switchMap(() =>
                this.processTypesService.getProcessTypes().pipe(
                    map((processTypes) =>
                        processTypesActions.getProcessTypesSuccess({
                            processTypes,
                        })
                    ),
                    catchError((error) =>
                        of(
                            processTypesActions.getProcessTypesFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getProcessType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(processTypesActions.getProcessType),
            switchMap((action) =>
                this.processTypesService
                    .getProcessType(action.processTypeId)
                    .pipe(
                        map((processType) =>
                            processTypesActions.getProcessTypeSuccess({
                                processType,
                            })
                        ),
                        catchError((error) =>
                            of(
                                processTypesActions.getProcessTypeFailure({
                                    error,
                                    processTypeId: action.processTypeId,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateProcessType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(processTypesActions.updateProcessType),
            switchMap((action) =>
                this.processTypesService
                    .updateProcessType(action.processType)
                    .pipe(
                        map((processType) =>
                            processTypesActions.updateProcessTypeSuccess({
                                processType,
                            })
                        ),
                        catchError((error) =>
                            of(
                                processTypesActions.updateProcessTypeFailure({
                                    error,
                                    processType: action.processType,
                                })
                            )
                        )
                    )
            )
        )
    );
}
