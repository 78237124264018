import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class EncodedQueryParamsService {
    constructor(private router: Router, private route: ActivatedRoute) {}

    applyEncodedQueryParameters(id: string, params: any): void {
        const encodedParams = params && btoa(JSON.stringify(params));

        this.router.navigateByUrl(
            this.router.createUrlTree([], {
                queryParams: { [id]: encodedParams },
                queryParamsHandling: 'merge',
                preserveFragment: true,
            })
        );
    }

    getDecodedQueryParameters(id: string): any {
        const paramValue = this.route.snapshot.queryParams[id];

        const decodedParams = paramValue && JSON.parse(atob(paramValue));

        return decodedParams;
    }
}
