import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rbgaToHexPipe' })
export class RgbaToHexPipe implements PipeTransform {
    transform(rgba: string): string {
        return (
            rgba &&
            `#${rgba
                .match(
                    /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/
                )
                .slice(1)
                .map((n, i) =>
                    (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
                        .toString(16)
                        .padStart(2, '0')
                        .replace('NaN', '')
                )
                .join('')}`
        );
    }
}
