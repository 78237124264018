import { Injectable } from '@angular/core';
import { PaginationOptions } from './pagination-options';

@Injectable()
export class ApiPaginationService {
    mapPaginationOptions(paginationOptions: PaginationOptions): {
        [key: string]: string;
    } {
        return {
            ...(paginationOptions?.pageNumber
                ? { 'x-clmi-pagenumber': String(paginationOptions.pageNumber) }
                : {}),
            ...(paginationOptions?.pageSize
                ? { 'x-clmi-pagesize': String(paginationOptions.pageSize) }
                : {}),
            ...(paginationOptions?.sortField
                ? { 'x-clmi-sortfield': String(paginationOptions.sortField) }
                : {}),
            ...(paginationOptions?.sortDescending
                ? {
                      'x-clmi-sortdescending': String(
                          paginationOptions.sortDescending
                      ),
                  }
                : {}),
        };
    }
}
