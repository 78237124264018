import { Injectable } from '@angular/core';
import { LocalStorageKey } from './local-storage-key.model';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    getBooleanKey(key: LocalStorageKey): boolean {
        return Boolean(localStorage.getItem(key));
    }

    setBooleanKey(key: LocalStorageKey, value: boolean) {
        if (value) {
            window.localStorage.setItem(key, 'true');
        } else {
            localStorage.removeItem(key);
        }
    }

    getStringKey(key: string): string | null {
        return localStorage.getItem(key);
    }

    setStringKey(key: string, value?: string | null) {
        if (value) {
            window.localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }
}
