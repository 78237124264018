<div
    data-cy="wdx-audit-value-label"
    [class.fw-bold]="subtitle || badge"
    [innerHtml]="label | translateTokenisedString | markdownToHtml | safeHtml"
></div>

<div class="d-flex">
    <div
        *ngIf="subtitle"
        data-cy="wdx-audit-value-subtitle"
        [class.me-2]="subtitle"
    >
        {{ subtitle }}
    </div>

    <wdx-badge
        *ngIf="badge"
        data-cy="wdx-audit-value-badge"
        [themeColor]="badge.themeColor ?? null"
    >
        {{ badge.label }}
    </wdx-badge>
</div>
