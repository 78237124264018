import { Component, Input } from '@angular/core';
import { NavItem, TRANSLATION_SORT_BY } from '@wdx/shared/utils';
import { FilterHandler } from '../../../classes/filter-handler.class';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-filter-bar',
    templateUrl: './molecule-filter-bar.component.html',
    styleUrls: ['./molecule-filter-bar.component.scss'],
})
export class MoleculeFilterBarComponent {
    @Input() showFilters = true;
    @Input() showFavourite: boolean;
    @Input() sortMenu: NavItem[];
    @Input() useSortToggleMenu: boolean;
    @Input() filtersModalId: string;
    @Input() filterHandler: FilterHandler;
    @Input() inlineStyle: boolean;
    @Input() noActionButtonsMargin: boolean;
    @Input() favouriteFilterToggle: boolean;

    readonly SORT_BY = TRANSLATION_SORT_BY;

    onSortByAction(navItem: NavItem) {
        if (this.filterHandler) {
            this.filterHandler.setSort(navItem.value, navItem.isInitialValue);
        }
    }

    onSetIsFavourite(isFavourite: boolean) {
        this.filterHandler.setIsFavourite(isFavourite);
    }
}
