import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@wdx/shared/utils';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { AtomDropdownButtonModule } from '../../atoms/atom-dropdown-button/atom-dropdown-button.module';
import { MoleculeContextMenuModule } from '../molecule-context-menu/molecule-context-menu.module';
import { MoleculeInputSearchModule } from '../molecule-input-search/molecule-input-search.module';
import { MoleculeContextMenuDropdownDumbComponent } from './molecule-context-menu-dropdown-dumb/molecule-context-menu-dropdown-dumb.component';
import { MoleculeContextMenuDropdownComponent } from './molecule-context-menu-dropdown/molecule-context-menu-dropdown.component';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomDropdownButtonModule,
        CommonModule,
        DirectivesModule,
        MoleculeContextMenuModule,
        MoleculeInputSearchModule,
        NgbDropdownModule,
        TranslatePipe,
    ],
    declarations: [
        MoleculeContextMenuDropdownComponent,
        MoleculeContextMenuDropdownDumbComponent,
    ],
    exports: [MoleculeContextMenuDropdownComponent],
})
export class MoleculeContextMenuDropdownModule {}
