import { createAction, props } from '@ngrx/store';
import { UserActionEntity } from '@wdx/clmi/api-models';

export const toggleFavourite = createAction(
    '[Favourites] Toggle Favourite',
    props<{
        id: string;
        entity: UserActionEntity;
        newValue: boolean;
        reload?: boolean;
    }>()
);

export const favourite = createAction(
    '[Favourites] Favourite',
    props<{
        id: string;
        entity: UserActionEntity;
        reload?: boolean;
    }>()
);

export const favouriteSuccess = createAction(
    '[Favourites] Favourite Success',
    props<{
        id: string;
        entity: UserActionEntity;
        reload?: boolean;
    }>()
);

export const favouriteFailure = createAction(
    '[Favourites] Favourite Failure',
    props<{
        id: string;
        entity: UserActionEntity;
        error: Error;
    }>()
);

export const unfavourite = createAction(
    '[Favourites] Unfavourite',
    props<{
        entity: UserActionEntity;
        id: string;
        reload?: boolean;
    }>()
);

export const unfavouriteSuccess = createAction(
    '[Favourites] Unfavourite Success',
    props<{
        id: string;
        entity: UserActionEntity;
        reload?: boolean;
    }>()
);

export const unfavouriteFailure = createAction(
    '[Favourites] Unfavourite Failure',
    props<{
        id: string;
        entity: UserActionEntity;
        error: Error;
    }>()
);
