import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MEDIA_BREAKPOINT_SM } from '../../../constants/breakpoints.constants';
import { LegendItem } from '../../../models/legend-item.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-legend',
    templateUrl: './molecule-legend.component.html',
})
export class MoleculeLegendComponent implements OnInit {
    @Input() label: string;
    @Input() items: LegendItem[];
    @Input() isVertical: boolean;
    @Input() hasContainer = true;
    @Input() responsiveOrientation = true;

    _isVertical: boolean;

    readonly BREAKPOINT = MEDIA_BREAKPOINT_SM;

    @HostListener('window:resize') onResize() {
        if (this.responsiveOrientation) {
            this.setOrientation(window.innerWidth <= this.BREAKPOINT);
        }
    }

    ngOnInit() {
        this._isVertical = this.isVertical;
        this.onResize();
    }

    setOrientation(vertical: boolean) {
        if (vertical) {
            this.isVertical = true;
        } else {
            this.isVertical = this._isVertical;
        }
    }
}
