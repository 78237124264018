<wdx-audit
    class="h-100 overflow-hidden"
    [title]="title"
    [lastUpdated]="lastUpdated$ | ngrxPush"
    [timeline]="timeline$ | ngrxPush"
    [beforeId]="beforeId$ | ngrxPush"
    [afterId]="afterId$ | ngrxPush"
    [info]="info$ | ngrxPush"
    [crudStatus]="status$ | ngrxPush"
    [avatarTmpl]="avatarTmpl"
    (wdxDateChange)="onDateChange($event)"
></wdx-audit>

<ng-template #avatarTmpl>
    <div [wdxUtilsComponentRenderer]="avatar$ | ngrxPush"></div>
</ng-template>
