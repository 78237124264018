import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-check-box',
    templateUrl: './atom-check-box.component.html',
})
export class AtomCheckBoxComponent {
    @Input() checked: boolean;
    @Output() isChecked: EventEmitter<boolean> = new EventEmitter<boolean>();

    toggleCheck() {
        this.checked = !this.checked;
        this.isChecked.emit(this.checked);
    }
}
