import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'randomString', standalone: true })
export class RandomStringPipe implements PipeTransform {
    transform(length = 20): string {
        return Array.from({ length })
            .map(() => String.fromCharCode(Math.random() * 26 + 65))
            .join('');
    }
}
