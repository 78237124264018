import { APP_CHANGE_OF_CIRCUMSTANCE_LABEL } from './apps.constants';
import { LISTS } from './lists.constants';
import { NETWORK_GRAPH } from './network.constants';
import { PORTFOLIO_360 } from './portfolio.constants';

export enum QUICK_LINKS_KEYS {
    CampaignEngagements = 'CampaignEngagements',
    Cases = 'Cases',
    ClientInsights = 'ClientInsights',
    ClientProducts = 'ClientProducts',
    Clients = 'Clients',
    Documents = 'Documents',
    Employees = 'Employees',
    ExternalTasks = 'ExternalTasks',
    Feed = 'Feed',
    FormFramework = 'formFramework',
    Inbox = 'Inbox',
    KYCStatus = 'KYCStatus',
    Lists = 'Lists',
    Modal = 'modal',
    NetworkGraph = 'NetworkGraph',
    Notes = 'Notes',
    Opportunities = 'Opportunities',
    Portfolio360 = 'Portfolio360',
    Profile = 'Profile',
    Tasks = 'Tasks',
    PendingChanges = 'PendingChanges',
}

const QUICK_LINKS_MAPPING = {
    [QUICK_LINKS_KEYS.Cases]: {
        id: 'casesModal',
    },
    [QUICK_LINKS_KEYS.Clients]: {
        data: {
            name: 'Clients',
            type: QUICK_LINKS_KEYS.Clients,
        },
    },
    [QUICK_LINKS_KEYS.Employees]: {
        data: {
            name: 'Employees',
            type: QUICK_LINKS_KEYS.Employees,
        },
    },
    [QUICK_LINKS_KEYS.Opportunities]: {
        id: 'opportunitiesModal',
    },
    [QUICK_LINKS_KEYS.Feed]: {
        id: 'feedModal',
    },
    [QUICK_LINKS_KEYS.ExternalTasks]: {
        data: {
            name: 'External Tasks',
            type: QUICK_LINKS_KEYS.ExternalTasks,
        },
    },
    [QUICK_LINKS_KEYS.Tasks]: {
        data: {
            name: 'Tasks',
            type: QUICK_LINKS_KEYS.Tasks,
        },
    },
    [QUICK_LINKS_KEYS.Documents]: {
        data: {
            name: 'Documents',
            type: QUICK_LINKS_KEYS.Documents,
        },
    },
    [QUICK_LINKS_KEYS.Notes]: {
        id: 'all-notes',
    },
    [QUICK_LINKS_KEYS.KYCStatus]: {
        data: {
            name: `KYC Status`,
            type: QUICK_LINKS_KEYS.KYCStatus,
        },
    },
    [QUICK_LINKS_KEYS.Lists]: {
        data: {
            name: LISTS,
            type: QUICK_LINKS_KEYS.Lists,
        },
    },
    [QUICK_LINKS_KEYS.Inbox]: {
        data: {
            name: 'Inbox',
            type: QUICK_LINKS_KEYS.Inbox,
        },
    },
    [QUICK_LINKS_KEYS.Portfolio360]: {
        data: {
            name: PORTFOLIO_360,
            type: QUICK_LINKS_KEYS.Portfolio360,
        },
    },
    [QUICK_LINKS_KEYS.ClientProducts]: {
        data: {
            name: 'Client Products',
            type: QUICK_LINKS_KEYS.ClientProducts,
        },
    },
    [QUICK_LINKS_KEYS.CampaignEngagements]: {
        data: {
            name: 'Campaign Engagement',
            type: QUICK_LINKS_KEYS.CampaignEngagements,
        },
    },
    [QUICK_LINKS_KEYS.ClientInsights]: {
        data: {
            name: 'Client Insights',
            type: QUICK_LINKS_KEYS.ClientInsights,
        },
    },
    [QUICK_LINKS_KEYS.NetworkGraph]: {
        data: {
            name: NETWORK_GRAPH,
            type: QUICK_LINKS_KEYS.NetworkGraph,
        },
    },
    [QUICK_LINKS_KEYS.PendingChanges]: {
        data: {
            name: APP_CHANGE_OF_CIRCUMSTANCE_LABEL,
            type: QUICK_LINKS_KEYS.PendingChanges,
        },
    },
};

export const QUICK_LINKS_CLIENTS = { ...QUICK_LINKS_MAPPING };
