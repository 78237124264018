import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ViewTile } from '@wdx/clmi/api-models';
import { QueryApiService } from '@wdx/clmi/api-services/services';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as rootReducer from '../_setup/reducers';
import * as viewTileActions from './viewtile.actions';
import { ViewTileService } from './viewtile.service';

@Injectable()
export class ViewTileEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private viewTileService = inject(ViewTileService);
    private queryApiService = inject(QueryApiService);

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewTileActions.getAll),
            mergeMap(() =>
                this.viewTileService.getAll().pipe(
                    map((viewTiles: ViewTile[]) =>
                        viewTileActions.getAllSuccess({ viewTiles })
                    ),
                    catchError((error: Error) =>
                        of(viewTileActions.getAllFailure({ error }))
                    )
                )
            )
        )
    );

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewTileActions.getForId),
            switchMap((action) =>
                this.viewTileService.getForId(action.id).pipe(
                    map((viewTile: ViewTile) =>
                        viewTileActions.getForIdSuccess({
                            viewTile,
                            id: action.id,
                        })
                    ),
                    catchError((error: Error) =>
                        of(
                            viewTileActions.getForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    createViewTile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewTileActions.createViewTile),
            switchMap((action) =>
                this.viewTileService.create(action.create).pipe(
                    map((viewTile: ViewTile) =>
                        viewTileActions.createViewTileSuccess({ viewTile })
                    ),
                    catchError((error: Error) =>
                        of(
                            viewTileActions.createViewTileFailure({
                                create: action.create,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    updateViewTile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewTileActions.updateViewTile),
            mergeMap((action) =>
                this.viewTileService.update(action.id, action.update).pipe(
                    map((viewTile: ViewTile) =>
                        viewTileActions.updateViewTileSuccess({ viewTile })
                    ),
                    catchError((error: Error) =>
                        of(
                            viewTileActions.updateViewTileFailure({
                                id: action.id,
                                update: action.update,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteViewTile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewTileActions.deleteViewTile),
            switchMap((action) =>
                this.viewTileService.delete(action.id).pipe(
                    map(() =>
                        viewTileActions.deleteViewTileSuccess({ id: action.id })
                    ),
                    catchError((error: Error) =>
                        of(
                            viewTileActions.deleteViewTileFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getRollup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewTileActions.getRollup),
            mergeMap((action) =>
                this.queryApiService
                    .getRollup(action.entity, action.rollupQuery)
                    .pipe(
                        map((results) =>
                            viewTileActions.getRollupSuccess({
                                results,
                                id: action.id,
                            })
                        ),
                        catchError((error: Error) =>
                            of(
                                viewTileActions.getRollupFailure({
                                    error,
                                    id: action.id,
                                })
                            )
                        )
                    )
            )
        )
    );
}
