<section class="widget h-100" *ngIf="!isAccordion">
    <ng-container
        *ngTemplateOutlet="heading; context: { cssClasses: 'widget-header' }"
    >
    </ng-container>

    <ng-container *ngTemplateOutlet="content"> </ng-container>
</section>

<wdx-accordion
    *ngIf="isAccordion"
    data-cy="widget-team-member"
    [attr.data-cy-value]="getTmService?.membersCount$ | ngrxPush"
    [widget]="true"
>
    <wdx-accordion-panel [open]="(getTmService?.membersCount$ | ngrxPush) > 0">
        <ng-container
            *ngTemplateOutlet="heading; context: { cssClasses: 'mb-0' }"
        >
        </ng-container>

        <div wdxAccordionContent>
            <ng-container *ngTemplateOutlet="content"> </ng-container>
        </div>
    </wdx-accordion-panel>
</wdx-accordion>

<ng-template #heading let-cssClasses="cssClasses">
    <h5 [attr.class]="cssClasses">
        {{ ENTITY_TEAM_SINGULAR | translate }}
        (<wdx-counter
            [count]="getTmService?.membersCount$ | ngrxPush"
            [hasError]="getTmService?.teamMembersHasError$ | ngrxPush"
            [isLoading]="getTmService?.teamMembersIsLoading$ | ngrxPush"
        ></wdx-counter
        >)
    </h5>
</ng-template>

<ng-template #content>
    <atom-async-data
        class="d-block h-100"
        [isLoading]="
            (getTmService?.teamMembersIsLoading$ | ngrxPush) ||
            (getTmService.addingTeamMembersIsLoading$ | ngrxPush)
        "
        [hasError]="getTmService?.teamMembersHasError$ | ngrxPush"
    >
        <section class="d-flex flex-column h-100">
            <div class="widget widget-body flex-grow-1 overflow-hidden">
                <section
                    owner
                    class="mb-3"
                    data-cy="section-owner"
                    *ngIf="getTmService.teamOwner$ | ngrxPush"
                >
                    <div class="d-flex">
                        <molecule-info-card
                            [primaryInfo]="
                                (getTmService.teamOwner$ | ngrxPush)
                                    ?.primaryInfo
                            "
                            [secondaryInfo]="
                                (getTmService.teamOwner$ | ngrxPush)
                                    ?.secondaryInfo
                            "
                            [tertiaryInfo]="
                                (getTmService.teamOwner$ | ngrxPush)
                                    ?.tertiaryInfo
                            "
                            [avatars]="
                                (getTmService.teamOwner$ | ngrxPush)?.avatars
                            "
                            [routerLinkData]="
                                (getTmService.teamOwner$ | ngrxPush)?.routerLink
                            "
                        ></molecule-info-card>

                        <molecule-action-button
                            *ngIf="!readonlyView"
                            class="ms-2"
                            [actionButton]="
                                getTmService?.assignActionBtn$ | ngrxPush
                            "
                            (onClick)="openLookupAssignTo()"
                        ></molecule-action-button>
                    </div>
                </section>

                <section team data-cy="section-team-members">
                    <div class="d-flex justify-content-between">
                        <h5>{{ ENUM_TABTYPE_TEAMMEMBERS | translate }}</h5>

                        <button
                            *ngIf="!readonlyView && useLookupAddMember"
                            data-cy="btn-add-team-member"
                            wdxButton
                            [wdxIconButton]="true"
                            (click)="openAddNewMemberModal()"
                        >
                            <wdx-icon-container size="sm">
                                <wdx-icon [icon]="ICON_ADD"></wdx-icon>
                            </wdx-icon-container>
                        </button>

                        <molecule-action-button
                            *ngIf="!readonlyView && !useLookupAddMember"
                            data-cy="btn-add-team-member"
                            [actionButton]="getTmService?.actionBtn$ | ngrxPush"
                        ></molecule-action-button>
                    </div>

                    <hr />

                    <div
                        class="grid"
                        *ngIf="
                            !(getTmService?.teamMembersIsLoading$ | ngrxPush) &&
                            (getTmService?.teamMembers$ | ngrxPush)?.length
                        "
                    >
                        <molecule-info-card
                            *ngFor="
                                let team of getTmService.teamMembers$
                                    | ngrxPush;
                                trackBy: trackByPartyTeamMemberId
                            "
                            class="g-col-6"
                            [primaryInfo]="team?.infoCard?.primaryInfo"
                            [secondaryInfo]="team?.infoCard?.secondaryInfo"
                            [tertiaryInfo]="team?.infoCard?.tertiaryInfo"
                            [routerLinkData]="team?.infoCard?.routerLink"
                            [avatars]="team?.infoCard?.avatars"
                            [fillHorizontalSpace]="false"
                        >
                            <div
                                *ngIf="team?.actionButton"
                                class="custom-actions"
                            >
                                <molecule-action-button
                                    *ngIf="!readonlyView"
                                    [actionButton]="team?.actionButton"
                                    data-cy="team-action-button"
                                ></molecule-action-button>
                            </div>
                        </molecule-info-card>
                    </div>
                    <div
                        *ngIf="
                            !(getTmService.teamMembers$ | ngrxPush)?.length &&
                            !(getTmService?.teamMembersIsLoading$ | ngrxPush) &&
                            !(getTmService?.teamMembersHasError$ | ngrxPush)
                        "
                        class="d-flex h-100 justify-content-center align-items-center"
                    >
                        <atom-feature-svg size="xs" [svg]="NO_DATA_SVG">
                            {{ SVG_TEXT_NO_TEAM_MEMBERS | translate }}
                        </atom-feature-svg>
                    </div>
                </section>
            </div>
        </section>
    </atom-async-data>
</ng-template>

<ng-container #lookupContainer></ng-container>
