import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as organisationClientTypesActions from './organisation-client-types.actions';
import { OrganisationClientTypesService } from './organisation-client-types.service';

@Injectable()
export class OrganisationClientTypesEffects {
    private actions$ = inject(Actions);
    private organisationClientTypesService = inject(
        OrganisationClientTypesService
    );

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(organisationClientTypesActions.getAll),
            switchMap(() =>
                this.organisationClientTypesService.getAll().pipe(
                    map((organisationClientTypes) =>
                        organisationClientTypesActions.getAllSuccess({
                            organisationClientTypes,
                        })
                    ),
                    catchError((error) =>
                        of(
                            organisationClientTypesActions.getAllFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
