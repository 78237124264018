<div class="alert mb-0 d-flex align-items-center">
    <ng-container
        *ngTemplateOutlet="
            engagement;
            context: {
                icon: ICON_LINK,
                count: links,
                text: 'Links',
                tabIndex: 0
            }
        "
    >
    </ng-container>
    <div class="divider-end divider--short"></div>
    <ng-container
        *ngTemplateOutlet="
            engagement;
            context: {
                icon: ICON_LIKE,
                count: likes,
                text: 'Likes',
                tabIndex: 1
            }
        "
    >
    </ng-container>
</div>

<ng-template
    #engagement
    let-icon="icon"
    let-count="count"
    let-text="text"
    let-tabIndex="tabIndex"
>
    <div
        class="engagements__item d-flex align-items-center justify-content-center flex-grow-1"
        [class.pointer]="hasInfoModal"
        [modalId]="hasInfoModal ? instanceId : null"
        [attr.data-cy]="'action-open-' + text"
        (click)="onGetLinksAndLikes(tabIndex)"
    >
        <wdx-icon
            class="me-1"
            [scale]="1"
            [iconClass]="count ? 'text-primary' : 'text-muted'"
            [icon]="icon"
            [fixedWidth]="true"
        ></wdx-icon>
        <h5 class="text-primary">
            {{ count || 0
            }}<span *ngIf="responsiveShowText" class="small text-primary"
                >&nbsp;{{ text }}</span
            >
        </h5>
    </div>
</ng-template>

<atom-modal-wrapper
    *ngIf="hasInfoModal"
    [modalId]="instanceId"
    [modalTemplate]="engagementsInfoModal"
>
    <ng-template #engagementsInfoModal>
        <organism-modal
            data-cy="modal-engagements-info"
            [modalTitle]="infoModalTitle | truncateText : 50"
        >
            <molecule-tabs class="d-block h-100 tabs-bg-muted" [name]="TABS_ID">
                <atom-tab
                    label="Linked Records"
                    cySelector="tab-linked-record"
                    [icon]="ICON_LINK"
                    [content]="linkedRecordsTab"
                ></atom-tab>
                <atom-tab
                    label="Liked By"
                    cySelector="tab-liked"
                    [icon]="ICON_LIKE"
                    [content]="likedByTab"
                ></atom-tab>
            </molecule-tabs>
            <ng-template #linkedRecordsTab>
                <atom-async-data
                    [isLoading]="
                        linkedActivitiesIsLoading || linkedCasesIsLoading
                    "
                    [hasError]="linkedActivitiesHasError || linkedCasesHasError"
                >
                    <div
                        *ngIf="
                            linkedActivities
                                | fn
                                    : casesAndActivitiesAsInfoCards
                                    : linkedCases;
                            let allLinked
                        "
                        class="h-100 overflow-auto"
                    >
                        <ng-container *ngFor="let linked of allLinked">
                            <ng-container
                                *ngTemplateOutlet="
                                    resultInfoCard;
                                    context: {
                                        data: linked
                                    }
                                "
                            ></ng-container>
                        </ng-container>
                    </div>
                </atom-async-data>
            </ng-template>
            <ng-template #likedByTab>
                <atom-async-data
                    [isLoading]="likedByIsLoading"
                    [hasError]="likedByHasError"
                >
                    <div
                        *ngIf="likedBy | fn : likedByAsInfoCards; let allLiked"
                        class="h-100 overflow-auto"
                    >
                        <ng-container *ngFor="let liked of allLiked">
                            <ng-container
                                *ngTemplateOutlet="
                                    resultInfoCard;
                                    context: {
                                        data: liked
                                    }
                                "
                            ></ng-container>
                        </ng-container>
                    </div>
                </atom-async-data>
            </ng-template>
            <ng-template #resultInfoCard let-data="data">
                <molecule-info-card
                    class="d-block p-3 border-light border-bottom"
                    [primaryInfo]="data.primaryInfo"
                    [routerLinkData]="data.routerLink"
                    [secondaryInfo]="data.secondaryInfo"
                    [avatars]="data.avatars"
                    [icon]="data.icon"
                ></molecule-info-card>
            </ng-template>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
