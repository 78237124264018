import { createSelector } from '@ngrx/store';
import * as fileUploadReducer from './avatar-file-upload.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    avatarFileUpload: fileUploadReducer.State;
}): fileUploadReducer.State => state.avatarFileUpload;

/**
 * Reducer property selectors
 */
export const getFileUploadStatus = createSelector(
    getState,
    (state: fileUploadReducer.State, props: any) =>
        state.avatarUploads[props.id]?.uploadStatus
);
export const getFileUploadProgress = createSelector(
    getState,
    (state: fileUploadReducer.State, props: any) =>
        state.avatarUploads[props.id]?.progress
);
export const getFileUploadFileIndex = createSelector(
    getState,
    (state: fileUploadReducer.State, props: any) =>
        state.avatarUploads[props.id]?.fileIndex
);
export const getFileUploadError = createSelector(
    getState,
    (state: fileUploadReducer.State, props: any) =>
        state.avatarUploads[props.id]?.error
);
