import { createSelector } from '@ngrx/store';
import { SystemEntityActionProps } from '../../models/system-entity-action-props.model';
import { systemEntityStateId } from '../_helpers/system-entity-action-id-compiler';
import * as relatedRecordsReducer from './related-records.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    relatedRecords: relatedRecordsReducer.State;
}): relatedRecordsReducer.State => state.relatedRecords;

export const getActivitiesIsLoadingList = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.activities[systemEntityStateId(props)]?.isLoadingList
);
export const getActivitiesHasLoadingListError = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.activities[systemEntityStateId(props)]?.hasLoadingListError
);
export const getActivities = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.activities[systemEntityStateId(props)]?.list
);

export const getArticlesIsLoadingList = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.articles[systemEntityStateId(props)]?.isLoadingList
);
export const getArticlesHasLoadingListError = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.articles[systemEntityStateId(props)]?.hasLoadingListError
);
export const getArticles = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.articles[systemEntityStateId(props)]?.list
);

export const getCasesIsLoadingList = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.cases[systemEntityStateId(props)]?.isLoadingList
);
export const getCasesHasLoadingListError = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.cases[systemEntityStateId(props)]?.hasLoadingListError
);
export const getCases = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.cases[systemEntityStateId(props)]?.list
);

export const getChildCasesIsLoadingList = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.childCases[systemEntityStateId(props)]?.isLoadingList
);
export const getChildCasesHasLoadingListError = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.childCases[systemEntityStateId(props)]?.hasLoadingListError
);
export const getChildCases = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.childCases[systemEntityStateId(props)]?.list
);

export const getOpportunitiesIsLoadingList = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.opportunities[systemEntityStateId(props)]?.isLoadingList
);
export const getOpportunitiesHasLoadingListError = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.opportunities[systemEntityStateId(props)]?.hasLoadingListError
);
export const getOpportunities = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.opportunities[systemEntityStateId(props)]?.list
);

export const getSuggestedActivityArticlesIsLoadingList = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.suggestedArticles[systemEntityStateId(props)]?.isLoadingList
);
export const getSuggestedActivityArticlesHasLoadingListError = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.suggestedArticles[systemEntityStateId(props)]?.hasLoadingListError
);
export const getSuggestedActivityArticles = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.suggestedArticles[systemEntityStateId(props)]?.list
);

export const getSuggestedActivityCasesIsLoadingList = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.suggestedCases[systemEntityStateId(props)]?.isLoadingList
);
export const getSuggestedActivityCasesHasLoadingListError = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.suggestedCases[systemEntityStateId(props)]?.hasLoadingListError
);
export const getSuggestedActivityCases = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.suggestedCases[systemEntityStateId(props)]?.list
);

export const getSuggestedActivityOpportunitiesIsLoadingList = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.suggestedOpportunities[systemEntityStateId(props)]?.isLoadingList
);
export const getSuggestedActivityOpportunitiesHasLoadingListError =
    createSelector(
        getState,
        (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
            state.suggestedOpportunities[systemEntityStateId(props)]
                ?.hasLoadingListError
    );
export const getSuggestedActivityOpportunities = createSelector(
    getState,
    (state: relatedRecordsReducer.State, props: SystemEntityActionProps) =>
        state.suggestedOpportunities[systemEntityStateId(props)]?.list
);

export const getCreateActivityArticleLinkIsCreating = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.articles).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.articles[id].isUpdating,
            }),
            {}
        )
);

export const getCreateActivityArticleLinkHasError = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.articles).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.articles[id].hasUpdatingError,
            }),
            {}
        )
);

export const getCreateActivityCaseLinkIsCreating = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.cases).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.cases[id].isUpdating,
            }),
            {}
        )
);

export const getCreateActivityCaseLinkHasError = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.cases).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.cases[id].hasUpdatingError,
            }),
            {}
        )
);

export const getCreateActivityOpportunityLinkIsCreating = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.opportunities).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.opportunities[id].isUpdating,
            }),
            {}
        )
);

export const getCreateActivityOpportunityLinkHasError = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.opportunities).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.opportunities[id].hasUpdatingError,
            }),
            {}
        )
);

export const getDeleteActivityArticleLinkIsDeleting = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.articles).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.articles[id].isDeleting,
            }),
            {}
        )
);

export const getDeleteActivityArticleLinkHasDeletingError = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.articles).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.articles[id].hasDeletingError,
            }),
            {}
        )
);

export const getDeleteActivityCaseLinkIsDeleting = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.cases).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.cases[id].isDeleting,
            }),
            {}
        )
);

export const getDeleteActivityCaseLinkHasDeletingError = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.cases).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.cases[id].hasDeletingError,
            }),
            {}
        )
);

export const getDeleteActivityOpportunityLinkIsDeleting = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.opportunities).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.opportunities[id].isDeleting,
            }),
            {}
        )
);

export const getDeleteActivityOpportunityLinkHasDeletingError = createSelector(
    getState,
    (state: relatedRecordsReducer.State) =>
        Object.keys(state.opportunities).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.opportunities[id].hasDeletingError,
            }),
            {}
        )
);
