import { SystemEntity } from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';

export class NgRxService {
    static chunkId(systemEntity: SystemEntity, props: KeyValueObject): string {
        const idKeys = Object.keys(props).filter(
            (key) => key.slice(-2).toLowerCase() === 'id'
        );

        const concatenatedValues = [
            systemEntity,
            idKeys.map((key) => props[key].replace(/\W/g, '')).join('_'),
        ].join('_');

        return concatenatedValues;
    }
}
