<div
    class="modal-banner modal-padding modal-padding--banner flex-shrink-0 d-flex justify-content-end"
>
    <atom-async-button
        btnClass="btn btn-primary"
        data-cy="btn-ignore-and-save"
        [isLoading]="isSaving"
        (onClick)="onIgnoreDuplicates()"
    >
        <span class="action-button-standard">Ignore and Save</span>
    </atom-async-button>
</div>

<div class="modal-padding modal-padding--body flex-grow-1 overflow-y-auto">
    <atom-async-data [isLoading]="isLoading" [hasError]="hasError">
        <ng-container *ngIf="duplicates && duplicates?.length">
            <organism-extended-info-card-list
                class="text-truncate"
                [extendedInfoCards]="
                    duplicates | fn: duplicatesAsExtendedInfoCards
                "
                [listOnly]="false"
                [showSearchBar]="false"
            ></organism-extended-info-card-list>
        </ng-container>
    </atom-async-data>
</div>
