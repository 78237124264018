import * as messagesReducer from '../../state/message/message.reducer';
import { createSelector } from '@ngrx/store';

export const getState = (state: {
    messages: messagesReducer.State;
}): messagesReducer.State => state.messages;

export const getMessages = createSelector(
    getState,
    (state: messagesReducer.State) => state.messages
);
