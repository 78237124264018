import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as countriesActions from './countries.actions';
import { CountriesService } from './countries.service';

@Injectable()
export class CountriesEffects {
    private actions$ = inject(Actions);
    private countriesService = inject(CountriesService);

    getCountries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(countriesActions.getCountries),
            switchMap(() =>
                this.countriesService.getCountries().pipe(
                    map((countries) =>
                        countriesActions.getCountriesSuccess({ countries })
                    ),
                    catchError((error) =>
                        of(
                            countriesActions.getCountriesFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
