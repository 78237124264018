export enum FeatureSvg {
    AccessDenied,
    AppNoGoals,
    AppNoFamily,
    AppNoProducts,
    AppNoSourceOfWealth,
    ClientCharity,
    ClientCorporate,
    ClientIndividual,
    ClientJoint,
    ClientTrust,
    DashboardNoAccess,
    EmptyFolder,
    InboxEmpty,
    NoChatData,
    NoData,
    NoListData,
    PageNotFound,
    SelectDataFromMenu,
    Workspace,
    Comments,
}
