import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { ProcessType } from '@wdx/clmi/api-models';

@Injectable()
export class ProcessTypesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getProcessTypes(): Observable<ProcessType[]> {
        return this.http.get<ProcessType[]>(
            `${this.config.getConfiguration().API_BASE}/processtype`
        );
    }

    getProcessType(processTypeId: string): Observable<ProcessType> {
        return this.http.get<ProcessType>(
            `${
                this.config.getConfiguration().API_BASE
            }/processtype/${processTypeId}`
        );
    }

    updateProcessType(processType: ProcessType): Observable<ProcessType> {
        return this.http.put<ProcessType>(
            `${this.config.getConfiguration().API_BASE}/processtype/${
                processType.code
            }`,
            processType
        );
    }
}
