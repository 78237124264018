import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import * as marketingActions from './marketing.actions';
import { MarketingService } from './marketing.service';

@Injectable()
export class MarketingEffects {
    private actions$ = inject(Actions);
    private marketingService = inject(MarketingService);

    getCampaigns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(marketingActions.getCampaigns),
            switchMap(() =>
                this.marketingService.getCampaigns().pipe(
                    map((campaigns) =>
                        marketingActions.getCampaignsSuccess({ campaigns })
                    ),
                    catchError((error) =>
                        of(marketingActions.getCampaignsFailure({ error }))
                    )
                )
            )
        )
    );

    createCampaign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(marketingActions.createCampaign),
            switchMap((action) =>
                this.marketingService.createCampaign(action.create).pipe(
                    map((campaign) =>
                        marketingActions.createCampaignSuccess({ campaign })
                    ),
                    catchError((error) =>
                        of(marketingActions.createCampaignFailure({ error }))
                    )
                )
            )
        )
    );

    getSingleCampaign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(marketingActions.getSingleCampaign),
            mergeMap((action) =>
                this.marketingService.getSingleCampaign(action.campaignId).pipe(
                    map((campaign) =>
                        marketingActions.getSingleCampaignSuccess({
                            campaign: campaign,
                            campaignId: action.campaignId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            marketingActions.getSingleCampaignFailure({
                                campaignId: action.campaignId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
