import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { FormInputData } from '@wdx/shared/infrastructure/form-framework';
import { TextSearchPipe } from '../../../pipes/text-search.pipe';

@Component({
    selector: 'clmi-client-side-search',
    templateUrl: './client-side-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSideSearchComponent {
    @Input() searchArray: any[];
    @Input() formInputData: FormInputData;
    @Input() autoFocus: boolean;

    @Output() valueChanged = new EventEmitter<any[]>();

    onSearch(searchText: string | number): void {
        const searchResults =
            searchText &&
            searchText !== '' &&
            this.searchArray.filter((visibility) => {
                return new TextSearchPipe().transform(
                    String(searchText),
                    visibility
                );
            });
        this.valueChanged.emit(searchResults);
    }
}
