import { createAction, props } from '@ngrx/store';
import {
    AccessVisibility,
    ActivityTask,
    HeatMap,
    KeyStatResult,
    List,
    Party,
    PartyRoleRelationship,
    PartyRoleTeamMember as PartyTeamMember,
    StatusType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';

export const getForId = createAction(
    '[Parties] Get For ID',
    props<{
        partyId: string;
    }>()
);

export const getForIdSuccess = createAction(
    '[Parties] Get For ID Success',
    props<{
        partyId: string;
        party: Party;
    }>()
);

export const getForIdFailure = createAction(
    '[Parties] Get For ID Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const getKeyStatsForParty = createAction(
    '[Parties] Get Key Stats for Party',
    props<{
        partyId: string;
    }>()
);

export const getKeyStatsForPartySuccess = createAction(
    '[Parties] Get Key Stats for Party Success',
    props<{
        keyStatsForParty: KeyStatResult[];
        partyId: string;
    }>()
);

export const getKeyStatsForPartyFailure = createAction(
    '[Parties] Get Key Stats for Party Failure',
    props<{
        error: Error;
        partyId: string;
    }>()
);

export const getHeatMapForParty = createAction(
    '[Parties] Get HeatMap For Party',
    props<{
        partyId: string;
    }>()
);

export const getHeatMapForPartySuccess = createAction(
    '[Parties] Get HeatMap For Party Success',
    props<{
        partyId: string;
        heatMap: HeatMap;
    }>()
);

export const getHeatMapForPartyFailure = createAction(
    '[Parties] Get HeatMap For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const getTeamMembersForParty = createAction(
    '[Parties] Get Team Members For Party',
    props<{
        partyId: string;
    }>()
);

export const getTeamMembersForPartySuccess = createAction(
    '[Parties] Get Team Members For Party Success',
    props<{
        teamMembersForParty: PartyTeamMember[];
        partyId: string;
    }>()
);

export const getTeamMembersForPartyFailure = createAction(
    '[Parties] Get Team Members For Party Failure',
    props<{
        error: Error;
        partyId: string;
    }>()
);

export const getTeamMembersForPartyRole = createAction(
    '[Parties] Get Team Members For Party Role',
    props<{
        partyRoleId: string;
    }>()
);

export const getTeamMembersForPartyRoleSuccess = createAction(
    '[Parties] Get Team Members For Party Role Success',
    props<{
        teamMembersForPartyRole: PartyTeamMember[];
        partyRoleId: string;
    }>()
);

export const getTeamMembersForPartyRoleFailure = createAction(
    '[Parties] Get Team Members For Party Role Failure',
    props<{
        error: Error;
        partyRoleId: string;
    }>()
);

export const deleteTeamMemberForParty = createAction(
    '[Parties] Delete Team Member For Party',
    props<{
        partyId: string;
        memberId: string;
    }>()
);

export const deleteTeamMemberForPartySuccess = createAction(
    '[Parties] Delete Team Member Success',
    props<{
        partyId: string;
        memberId: string;
    }>()
);

export const deleteTeamMemberForPartyFailure = createAction(
    '[Parties] Delete Team Member Failure',
    props<{
        error: Error;
        partyId: string;
    }>()
);

export const deleteTeamMemberForPartyRole = createAction(
    '[Parties] Delete Team Member For Party Role',
    props<{
        partyRoleId: string;
        memberId: string;
    }>()
);

export const deleteTeamMemberForPartyRoleSuccess = createAction(
    '[Parties] Delete Team Member Role Success',
    props<{
        partyRoleId: string;
        memberId: string;
    }>()
);

export const toggleStatus = createAction(
    '[Parties] Toggle Status',
    props<{
        partyId: string;
        newValue?: boolean;
    }>()
);

export const activateParty = createAction(
    '[Parties] Activate Party',
    props<{
        partyId: string;
        status?: StatusType;
    }>()
);

export const activatePartySuccess = createAction(
    '[Parties] Activate Party Success',
    props<{
        partyId: string;
        status?: StatusType;
    }>()
);

export const activatePartyFailure = createAction(
    '[Parties] Activate Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const inactivateParty = createAction(
    '[Parties] Inactivate Party',
    props<{
        partyId: string;
        status?: StatusType.Inactive;
    }>()
);

export const inactivatePartySuccess = createAction(
    '[Parties] Inactivate Party Success',
    props<{
        partyId: string;
        status?: StatusType.Inactive;
    }>()
);

export const inactivatePartyFailure = createAction(
    '[Parties] Inactivate Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const deleteTeamMemberForPartyRoleFailure = createAction(
    '[Parties] Delete Team Member Role Failure',
    props<{
        error: Error;
        partyRoleId: string;
    }>()
);

export const promotePartyToLead = createAction(
    '[Parties] Promote Party To Lead',
    props<{
        partyId: string;
    }>()
);

export const promotePartyToLeadSuccess = createAction(
    '[Parties] Promote Party To Lead Success',
    props<{
        partyId: string;
    }>()
);

export const promotePartyToLeadFailure = createAction(
    '[Parties] Promote Party To Lead Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const resendInvite = createAction(
    '[User] Resend Invite',
    props<{
        partyId: string;
    }>()
);

export const resendInviteSuccess = createAction(
    '[User] Resend Invite Success',
    props<{
        partyId: string;
    }>()
);

export const resendInviteFailure = createAction(
    '[User] Resend Invite Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const setPrimaryTeamMemberForParty = createAction(
    '[Parties] Set Primary Team Member For Party',
    props<{
        partyId: string;
        teamMemberId: string;
    }>()
);

export const setPrimaryTeamMemberForPartySuccess = createAction(
    '[Parties] Set Primary Team Member For Party Success',
    props<{
        partyId: string;
    }>()
);

export const setPrimaryTeamMemberForPartyFailure = createAction(
    '[Parties] Set Primary Team Member For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const setPrimaryTeamMemberForPartyRole = createAction(
    '[Parties] Set Primary Team Member For Party Role',
    props<{
        partyRoleId: string;
        teamMemberId: string;
    }>()
);

export const setPrimaryTeamMemberForPartyRoleSuccess = createAction(
    '[Parties] Set Primary Team Member For Party Role Success',
    props<{
        partyRoleId: string;
    }>()
);

export const setPrimaryTeamMemberForPartyRoleFailure = createAction(
    '[Parties] Set Primary Team Member For Party Role Failure',
    props<{
        partyRoleId: string;
        error: Error;
    }>()
);

export const getVisibilityForParty = createAction(
    '[Parties] Get Visibility For Party',
    props<{
        partyId: string;
    }>()
);

export const getVisibilityForPartySuccess = createAction(
    '[Parties] Get Visibility For Party Success',
    props<{
        partyId: string;
        visibility: AccessVisibility[];
    }>()
);

export const getVisibilityForPartyFailure = createAction(
    '[Parties] Get Visibility For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const partyUpdatedSuccess = createAction(
    '[Parties] Party Updated Success',
    props<{
        partyId: string;
    }>()
);

export const partyCasesUpdatedSuccess = createAction(
    '[Parties] Party Cases Updated Success',
    props<{
        partyId: string;
    }>()
);

export const getAllTasksForParty = createAction(
    '[Parties] Get All Tasks For A Party',
    props<{
        partyId: string;
    }>()
);

export const getAllTasksForPartySuccess = createAction(
    '[Parties] Get All Tasks For A Party Success',
    props<{
        partyId: string;
        tasks: PaginatedApiResponse<ActivityTask>;
    }>()
);

export const getAllTasksForPartyFailure = createAction(
    '[Parties] Get All Tasks For A Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const getForPartyOrganisationConnections = createAction(
    '[Organisation Connections] Get Organisation Connections',
    props<{
        partyId: string;
    }>()
);

export const getOrganisationConnectionsForPartySuccess = createAction(
    '[Organisation Connections] Get Organisation Connections Success',
    props<{
        partyId: string;
        relationships: PartyRoleRelationship[];
    }>()
);

export const getOrganisationConnectionsForPartyFailure = createAction(
    '[Organisation Connections] Get Organisation Connections Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const getMembershipListsForEntity = createAction(
    '[Parties] Get Lists For Entity',
    props<{
        entityId: string;
        entityType: SystemEntity;
    }>()
);

export const getMembershipListsForEntitySuccess = createAction(
    '[Parties] Get Lists For Entity Success',
    props<{
        entityId: string;
        entityType: SystemEntity;
        lists: List[];
    }>()
);

export const getMembershipListsForEntityFailure = createAction(
    '[Parties] Get Lists For Entity Failure',
    props<{
        entityId: string;
        entityType: SystemEntity;
        error: Error;
    }>()
);
