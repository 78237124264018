import { Location } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SystemEntity, UserActionEntity } from '@wdx/clmi/api-models';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import { RoutableSystemEntity } from '../models/system-entity-action-props.model';

@Pipe({ name: 'entityRouteOpenNewTab' })
export class EntityRouteOpenNewTabPipe implements PipeTransform {
    constructor(private entityRouteService: EntityRouteService) {}

    transform(
        entityType: SystemEntity | UserActionEntity,
        router: Router,
        route: ActivatedRoute,
        location: Location,
        entityId?: string | number,
        regardingId?: string | number,
        queryParams?: Params,
        subEntityType?: RoutableSystemEntity,
        subEntityId?: string | number
    ): void {
        const routeWithParams =
            this.entityRouteService.routeWithParamsForEntity(
                entityType,
                entityId,
                regardingId,
                subEntityType,
                subEntityId
            );
        const url = router.serializeUrl(
            router.createUrlTree(routeWithParams.routerLink, {
                relativeTo: route,
                queryParams: {
                    ...(routeWithParams.queryParams || {}),
                    ...(queryParams || {}),
                },
            })
        );

        url && window.open(location.prepareExternalUrl(url));
    }
}
