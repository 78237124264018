import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AtomCloseComponent } from './atom-close.component';
import { AtomIconButtonModule } from '../atom-icon-button/atom-icon-button.module';

@NgModule({
    imports: [AtomIconButtonModule, CommonModule, RouterModule],
    declarations: [AtomCloseComponent],
    exports: [AtomCloseComponent],
})
export class AtomCloseModule {}
