import { Action, createReducer, on } from '@ngrx/store';
import {
    AccessVisibility,
    Case,
    ClientProduct,
    EntityOperations,
    KeyStatResult,
    PartyRoleTeamMember,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as clientProductsActions from './client-products.actions';

export interface State {
    clientProductsForPartyRole?: CrudStateObject<ClientProduct>;
    clientProductsForParty?: CrudStateObject<ClientProduct>;
    cases?: CrudStateObject<Case>;
    visibility?: CrudStateObject<AccessVisibility>;
    members?: CrudStateObject<PartyRoleTeamMember>;
    keyStats?: CrudStateObject<KeyStatResult>;
    operations?: CrudStateObject<EntityOperations>;
}

export const initialState: State = {
    clientProductsForPartyRole: {},
    clientProductsForParty: {},
    cases: {},
    visibility: {},
    members: {},
    keyStats: {},
    operations: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        clientProductsActions.getForPartyRole,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.partyRoleId]: {
                    ...(state.clientProductsForPartyRole[props.partyRoleId] ||
                        ({} as CrudState<ClientProduct>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForPartyRoleSuccess,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.partyRoleId]: {
                    ...state.clientProductsForPartyRole[props.partyRoleId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.clientProducts,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForPartyRoleFailure,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.partyRoleId]: {
                    ...state.clientProductsForPartyRole[props.partyRoleId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForParty,
        (state, props): State => ({
            ...state,
            clientProductsForParty: {
                ...state.clientProductsForParty,
                [props.partyId]: {
                    ...(state.clientProductsForParty[props.partyId] ||
                        ({} as CrudState<ClientProduct>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForPartySuccess,
        (state, props): State => ({
            ...state,
            clientProductsForParty: {
                ...state.clientProductsForParty,
                [props.partyId]: {
                    ...state.clientProductsForParty[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.clientProducts,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForPartyFailure,
        (state, props): State => ({
            ...state,
            clientProductsForParty: {
                ...state.clientProductsForParty,
                [props.partyId]: {
                    ...state.clientProductsForParty[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForClient,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.clientId]: {
                    ...(state.clientProductsForPartyRole[props.clientId] ||
                        ({} as CrudState<ClientProduct>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForClientSuccess,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.clientId]: {
                    ...state.clientProductsForPartyRole[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.clientProducts,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForClientFailure,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.clientId]: {
                    ...state.clientProductsForPartyRole[props.clientId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForId,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.clientProductId]: {
                    ...(state.clientProductsForPartyRole[
                        props.clientProductId
                    ] || ({} as CrudState<ClientProduct>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForIdSuccess,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.clientProductId]: {
                    ...state.clientProductsForPartyRole[props.clientProductId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.clientProduct,
                },
            },
        })
    ),

    on(
        clientProductsActions.getForIdFailure,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.clientProductId]: {
                    ...state.clientProductsForPartyRole[props.clientProductId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        clientProductsActions.getCasesForId,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.clientProductId]: {
                    ...(state.cases[props.clientProductId] ||
                        ({} as CrudState<Case>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        clientProductsActions.getCasesForIdSuccess,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.clientProductId]: {
                    ...state.cases[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.cases,
                },
            },
        })
    ),

    on(
        clientProductsActions.getCasesForIdFailure,
        (state, props): State => ({
            ...state,
            cases: {
                ...state.cases,
                [props.clientProductId]: {
                    ...state.cases[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        clientProductsActions.getVisibilityForId,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...(state.visibility[props.id] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        clientProductsActions.getVisibilityForIdSuccess,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.visibility,
                },
            },
        })
    ),

    on(
        clientProductsActions.getVisibilityForIdFailure,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        clientProductsActions.getMembersForId,
        (state, props): State => ({
            ...state,
            members: {
                ...state.visibility,
                [props.id]: {
                    ...(state.members[props.id] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        clientProductsActions.getMembersForIdSuccess,
        (state, props): State => ({
            ...state,
            members: {
                ...state.members,
                [props.id]: {
                    ...state.members[props.id],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.members,
                },
            },
        })
    ),

    on(
        clientProductsActions.getMembersIdFailure,
        (state, props): State => ({
            ...state,
            members: {
                ...state.members,
                [props.id]: {
                    ...state.members[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        clientProductsActions.getKeyStatsForId,
        (state, props): State => ({
            ...state,
            keyStats: {
                ...state.keyStats,
                [props.id]: {
                    ...(state.keyStats[props.id] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        clientProductsActions.getKeyStatsForIdSuccess,
        (state, props): State => ({
            ...state,
            keyStats: {
                ...state.keyStats,
                [props.id]: {
                    ...state.keyStats[props.id],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.keyStats,
                },
            },
        })
    ),

    on(
        clientProductsActions.getKeyStatsForIdFailure,
        (state, props): State => ({
            ...state,
            keyStats: {
                ...state.keyStats,
                [props.id]: {
                    ...state.keyStats[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        clientProductsActions.getOperationForId,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.id]: {
                    ...(state.operations[props.id] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),
    on(
        clientProductsActions.getOperationForIdSuccess,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.id]: {
                    ...state.operations[props.id],
                    single: props.operations,
                    isLoadingList: false,
                    hasLoadingListError: false,
                },
            },
        })
    ),
    on(
        clientProductsActions.getOperationForIdFailure,
        (state, props): State => ({
            ...state,
            operations: {
                ...state.operations,
                [props.id]: {
                    ...state.operations[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),
    on(
        clientProductsActions.deleteForId,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...(state.clientProductsForPartyRole ||
                    ({} as CrudStateObject<ClientProduct>)),
                [props.clientId]: {
                    ...(state.clientProductsForPartyRole[props.clientId] ||
                        ({} as CrudState<ClientProduct>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        })
    ),
    on(
        clientProductsActions.deleteForIdSuccess,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.clientId]: {
                    ...state.clientProductsForPartyRole[props.clientId],
                    isDeleting: false,
                    hasDeletingError: false,
                    list: state.clientProductsForPartyRole[
                        props.clientId
                    ].list?.filter(
                        (clientProduct) => clientProduct.id !== props.productId
                    ),
                },
            },
        })
    ),
    on(
        clientProductsActions.deleteForIdFailure,
        (state, props): State => ({
            ...state,
            clientProductsForPartyRole: {
                ...state.clientProductsForPartyRole,
                [props.clientId]: {
                    ...state.clientProductsForPartyRole[props.clientId],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
