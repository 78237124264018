import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { KeyValueObject } from '@wdx/shared/utils';
import { Subject } from 'rxjs';
import { MoleculeOffcanvasComponent } from '../components/molecules/molecule-offcanvas/molecule-offcanvas.component';
import { OffcanvasPanelSize } from '../models/offcanvas-panel-size.model';
import { RandomStringPipe } from '../pipes/random-string.pipe';
import { afterLifecyleEvents } from '../shared/helpers';

@Injectable()
export class OffcanvasManagementService {
    private componentStore: KeyValueObject<MoleculeOffcanvasComponent>;
    private store: KeyValueObject<NgbOffcanvasRef> = {};
    private paramStore: KeyValueObject = {};
    private activeInstanceIds: string[] = [];

    latestInstanceParams$ = new Subject<KeyValueObject>();

    constructor(
        private offcanvasService: NgbOffcanvas,
        private router: Router
    ) {}

    register(offcanvasComponent: MoleculeOffcanvasComponent): void {
        if (Object.keys(this.store).includes(offcanvasComponent.canvasId)) {
            console.error(
                `offcanvas id: ${offcanvasComponent.canvasId} has already been registered`
            );
            return;
        }

        this.componentStore = {
            ...this.componentStore,
            [offcanvasComponent.canvasId]: offcanvasComponent,
        };
    }

    deregister(id: string): void {
        delete this.componentStore[id];
        this.componentStore = { ...this.componentStore };
    }

    openWithComponent(
        offcanvasComponent: MoleculeOffcanvasComponent,
        params?: KeyValueObject
    ): NgbOffcanvasRef {
        const instanceId = new RandomStringPipe().transform();
        const offcanvasRef = offcanvasComponent.open(params);
        this.componentStore = {
            ...this.componentStore,
            [offcanvasComponent.canvasId]: offcanvasComponent,
        };
        this.store = {
            ...this.store,
            [instanceId]: offcanvasRef,
        };
        this.paramStore = {
            ...this.paramStore,
            [instanceId]: params,
        };

        this.activeInstanceIds = [...this.activeInstanceIds, instanceId];

        return offcanvasRef;
    }

    openWithId(id: string, params?: KeyValueObject): NgbOffcanvasRef {
        const instanceId = new RandomStringPipe().transform();
        const offcanvasParams = {
            id,
            instanceId,
            ...(params ? { offcanvasParams: params } : {}),
        };
        const component = this.componentStore[id];
        const offcanvasReference = this.offcanvasService.open(
            component.content,
            {
                position: component.position,
                panelClass: this.panelClass(params?.size || component.size),
            }
        );

        this.store = {
            ...this.store,
            [instanceId]: offcanvasReference,
        };
        this.paramStore = {
            ...this.paramStore,
            [instanceId]: offcanvasParams,
        };

        this.setRouting(params);

        this.activeInstanceIds = [...this.activeInstanceIds, instanceId];

        this.latestInstanceParams$.next(offcanvasParams);

        component.canvas = offcanvasReference;
        return offcanvasReference;
    }

    getActiveCanvasParams(): KeyValueObject {
        return (
            this.activeInstanceIds?.length &&
            this.paramStore[
                this.activeInstanceIds[this.activeInstanceIds.length - 1]
            ].offcanvasParams
        );
    }

    closeActive(): void {
        if (!this.activeInstanceIds?.length) {
            return;
        }
        const lastActiveInstanceId = this.activeInstanceIds.pop();
        this.setRouting(null);
        this.store[lastActiveInstanceId].close();
    }

    panelClass(size: OffcanvasPanelSize): string {
        if (!size) {
            return;
        }
        return `offcanvas-${size}`;
    }

    setRouting(params?: KeyValueObject): void {
        if (!this.offcanvasService.hasOpenOffcanvas()) {
            afterLifecyleEvents(() => {
                this.router.navigateByUrl(
                    this.router.createUrlTree([], {
                        queryParams: {
                            offcanvas: params && btoa(JSON.stringify(params)),
                        },
                        queryParamsHandling: 'merge',
                        preserveFragment: true,
                    })
                );
            });
        }
    }
}
