import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { AtomCommentStatusComponent } from './atom-comment-status.component';

@NgModule({
    imports: [CommonModule, WdxIconModule],
    declarations: [AtomCommentStatusComponent],
    exports: [AtomCommentStatusComponent],
})
export class AtomCommentStatusModule {}
