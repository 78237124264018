import { Injectable, inject } from '@angular/core';
import { ExtendedFieldDefinitionOption } from '../../../../../../../models/extended-field-definition-option.model';

import { FilterFieldDefinition, PossibleValue } from '@wdx/clmi/api-models';
import { TranslationsService } from '@wdx/shared/utils';
import { Observable, of } from 'rxjs';
import { FilterDataItem } from '../../../interfaces';
import { IFilterDataProvider } from '../../../interfaces/filter-data-provider.interface';

@Injectable()
export class FilterPossibleValuesDataService implements IFilterDataProvider {
    private translationsService = inject(TranslationsService);

    getData$(
        definition: FilterFieldDefinition,
        options: PossibleValue[]
    ): Observable<FilterDataItem[] | ExtendedFieldDefinitionOption[]> {
        const mappedOptions = options.map(({ name, value }) => ({
            label: name?.key
                ? this.translationsService.getTranslationByKey(name.key)
                : name?.value || value,
            value: value,
        }));
        return of(mappedOptions);
    }
}
