import { Environment } from '../models/environment.model';

export const DEV_URL = 'https://dev.clmi-dev.io';
export const LOCAL_URL = 'http://localhost:54801';
export const TEST_URL = 'https://test.clmi-dev.io';

export const ENVIRONMENT_URLS: { [key in Environment]: string } = {
    [Environment.Dev]: DEV_URL,
    [Environment.Local]: LOCAL_URL,
    [Environment.Test]: TEST_URL,
};
