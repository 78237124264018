import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AtomAsyncButtonModule } from '../../../components/atoms/atom-async-button/atom-async-button.module';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { OrganismExtendedInfoCardListModule } from '../../../components/organisms/organism-extended-info-card-list/organism-extended-info-card-list.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { AtomAsyncDataModule } from '../../atoms/atom-async-data/atom-async-data.module';
import { MoleculeInfoCardModule } from '../molecule-info-card/molecule-info-card.module';
import { MoleculeInputSearchModule } from '../molecule-input-search/molecule-input-search.module';
import { MoleculeInputWrapperModule } from '../molecule-input-wrapper/molecule-input-wrapper.module';
import { MoleculeInfoCardCheckDuplicationsComponent } from './molecule-info-card-check-duplications.component';
import { FunctionPipe } from '@wdx/shared/utils';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        MoleculeInfoCardModule,
        MoleculeInputSearchModule,
        MoleculeInputWrapperModule,
        AtomIconButtonModule,
        MoleculeActionButtonModule,
        OrganismExtendedInfoCardListModule,
        AtomAsyncButtonModule,
        FunctionPipe,
    ],
    declarations: [MoleculeInfoCardCheckDuplicationsComponent],
    exports: [MoleculeInfoCardCheckDuplicationsComponent],
})
export class MoleculeInfoCardCheckDuplicationsModule {}
