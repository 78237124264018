import { Action, createReducer, on } from '@ngrx/store';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as emailActions from './email.actions';

export interface State {
    emailContent: CrudStateObject<string>;
}

export const initialState: State = {
    emailContent: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        emailActions.getEmailContentForActivity,
        (state, props): State => ({
            ...state,
            emailContent: {
                ...state.emailContent,
                [props.activityId]: {
                    ...(state.emailContent[props.activityId] ||
                        ({} as CrudState<string>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        emailActions.getEmailContentForActivitySuccess,
        (state, props): State => ({
            ...state,
            emailContent: {
                ...state.emailContent,
                [props.activityId]: {
                    ...(state.emailContent[props.activityId] ||
                        ({} as CrudState<string>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.emailContent,
                },
            },
        })
    ),

    on(
        emailActions.getEmailContentForActivityFailure,
        (state, props): State => ({
            ...state,
            emailContent: {
                ...state.emailContent,
                [props.activityId]: {
                    ...(state.emailContent[props.activityId] ||
                        ({} as CrudState<string>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
