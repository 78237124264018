import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { SystemEntityApiService } from '../system-entity.service';
import {
    systemEntityActionPermission,
    systemEntityActionPermissionFailure,
    systemEntityActionPermissionSuccess,
} from './system-entity.actions';

@Injectable()
export class SystemEntityEffects {
    private actions$ = inject(Actions);
    private systemEntityApiService = inject(SystemEntityApiService);

    systemEntityActionPermission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(systemEntityActionPermission),
            mergeMap((props) =>
                this.systemEntityApiService
                    .getPermission(props.systemEntity, props.entityId)
                    .pipe(
                        map((permission) =>
                            systemEntityActionPermissionSuccess({
                                systemEntity: props.systemEntity,
                                entityId: props.entityId,
                                permission,
                            })
                        ),
                        catchError((error) =>
                            of(
                                systemEntityActionPermissionFailure({
                                    systemEntity: props.systemEntity,
                                    entityId: props.entityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
