import { Pipe, PipeTransform } from '@angular/core';
import { IdentityMap } from '@wdx/clmi/api-models';
import { IdList } from '../models/id-list.model';

@Pipe({ name: 'identitiesToIdList' })
export class IdentitiesToIdListPipe implements PipeTransform {
    transform(identities: IdentityMap[]): IdList {
        if (!identities?.length) {
            return [];
        }

        return identities.map((identity) => ({
            name: identity.source.name,
            id: identity.identity,
            isLocked: identity.isLocked,
        }));
    }
}
