import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxMarkdownToHtmlPipe, WdxSafeHtmlPipe } from '@wdx/shared/utils';
import { WdxCheckboxComponent } from './wdx-checkbox.component';

@NgModule({
    imports: [CommonModule, WdxSafeHtmlPipe, WdxMarkdownToHtmlPipe],
    declarations: [WdxCheckboxComponent],
    exports: [WdxCheckboxComponent],
})
export class WdxCheckboxModule {}
