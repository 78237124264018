import { Injectable, ViewContainerRef } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { Action, ActionsSubject } from '@ngrx/store';
import { LookupFieldResult, SystemEntity } from '@wdx/clmi/api-models';
import { NavItem } from '@wdx/shared/utils';
import { Observable, Subject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { MoleculeInputLookupComponent } from '../../../../components/molecules/molecule-input-lookup/containers/molecule-input-lookup/molecule-input-lookup.component';
import {
    ASSIGN_TO_LOOKUP_DATA,
    ASSIGN_TO_LOOKUP_DATA_USER_TEAM,
    ASSIGN_TO_USER_LOOKUP_DATA,
} from '../../../../constants/assign.constants';
import { ASSIGN_TO_MENU_ITEM } from '../../../../constants/menu.constants';
import { ModalManagementService } from '../../../../services/modal-management.service';
import { AssignFacadeService } from '../../../../state/assign/assign-facade.service';
import * as assignActions from '../../../../state/assign/assign.actions';

@Injectable({
    providedIn: 'root',
})
export class AssignToService {
    /**
     * The reference to the lookup container. Note this is initialised in base AppRoute component.
     */
    assignLookupContainer: ViewContainerRef;
    assignee$ = new Subject<LookupFieldResult>();
    isContextual = false;
    isTeam = false;

    constructor(
        private assignFacadeService: AssignFacadeService,
        private actionsSubject$: ActionsSubject,
        private modalManagementService: ModalManagementService
    ) {}

    /**
     * Returns 'Assign to' menu item, with actions included
     * @param entityType
     * @param entityId
     */
    getAssignToMenuItem(entityType: SystemEntity, entityId: string): NavItem {
        return {
            ...ASSIGN_TO_MENU_ITEM,
            cySelector: `option-assignTo-${entityId}`,
            callback: () => this.openAssignToModal(entityType, entityId),
        };
    }

    /**
     * Opens the 'Assign to' lookup modal
     * - dynamically creates and injects lookup component into container reference
     * - opens 'Assign to' lookup modal
     * - dispatches assignTo action on success and
     * - cleans container on success and close
     * - isContextual - if a entity type and entity id is available then it should use the LookupSourceType.Assignee as the lookup source
     * - isTeam - if a entity type is SystemEntity.Team then it should use the LookupSourceType.User as the lookup source
     * @param entityType
     * @param entityId - optional, provide for a single entity to be assigned, do not provide if bulkEntityIds is providded
     * @param bulkEntityIds  - optional, assign multiple entities, overrides entityId value
     */
    openAssignToModal(
        entityType: SystemEntity,
        entityId?: string,
        bulkEntityIds?: string[]
    ): void {
        let bulkMode = false;
        if (bulkEntityIds && bulkEntityIds.length) {
            entityId = bulkEntityIds[0];
            bulkMode = true;
        }
        this.isContextual = Boolean(entityType && entityId);
        this.isTeam = Boolean(entityType === SystemEntity.Team);
        const lookupComponent = this.assignLookupContainer.createComponent(
            MoleculeInputLookupComponent
        );
        const lookupInstance = lookupComponent.instance;

        const formInputData = this.isContextual
            ? this.isTeam
                ? ASSIGN_TO_USER_LOOKUP_DATA
                : ASSIGN_TO_LOOKUP_DATA
            : ASSIGN_TO_LOOKUP_DATA_USER_TEAM;

        lookupInstance.showReadout = false;
        lookupInstance.formInputData = formInputData;
        lookupInstance.formInputData.entityType = entityType;
        lookupInstance.formInputData.entityId = entityId;
        lookupInstance.initialValue = '';
        lookupInstance.showNoResultsMessage = true;

        lookupInstance.modalRegistered.pipe(delay(0)).subscribe((modalId) => {
            this.modalManagementService.openModalWithId(modalId);
            if (this.isContextual && !this.isTeam) {
                lookupInstance.dispatchGetLook({
                    searchText: '',
                    lookupSourceDefinition: formInputData.lookupSources[0],
                });
            }
        });

        lookupInstance.valueChanged.pipe(delay(0)).subscribe((value) => {
            if (entityType && entityId) {
                if (bulkMode) {
                    this.assignFacadeService.bulkAssignToEvent({
                        entityType,
                        entityIds: bulkEntityIds,
                        partyId: value.id,
                    });
                } else {
                    this.assignFacadeService.assignToEvent({
                        entityType,
                        entityId,
                        partyId: value.id,
                    });
                }
            } else {
                this.assignee$.next(value);
            }

            this.assignLookupContainer.clear();
        });

        lookupInstance.closed.pipe(delay(0)).subscribe(() => {
            this.assignLookupContainer.clear();
        });
    }

    /**
     * An BehaviorSubject that emits action payload on `assignToSuccess`
     * @param entityType Filter action based on entity, or emit to always trigger
     */
    onAssignToSuccess$(entityType?: SystemEntity): Observable<Action> {
        return this.actionsSubject$.pipe(
            ofType(assignActions.assignToSuccess),
            filter((action) =>
                entityType ? action.entityType === entityType : true
            )
        );
    }
}
