<div class="form__validation info-bold mb-2 flex-shrink-0">
    <div class="d-flex align-items-center">
        <wdx-icon-container size="sm">
            <wdx-icon
                class="form__validation__icon icon-shift-up"
                icon="lock"
                iconStyle="fas"
            ></wdx-icon>
        </wdx-icon-container>

        <div class="form-lock-message">
            {{ formData.lock?.displayText?.value }}&nbsp;
        </div>
        <div *ngIf="shouldOverride" class="d-flex align-items-center">
            <wdx-icon-container size="sm">
                <wdx-icon
                    class="form__validation__icon icon-shift-up"
                    icon="info-circle"
                    iconStyle="fas"
                >
                </wdx-icon
            ></wdx-icon-container>

            <div class="fw-bold">You have override lock privileges.</div>
        </div>
    </div>
</div>
