import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    SelectOptionsState,
    SELECT_OPTIONS_FEATURE_KEY,
    selectsAdapter,
} from './select-options.reducer';

export const selectSelectsState = createFeatureSelector<SelectOptionsState>(
    SELECT_OPTIONS_FEATURE_KEY
);

const { selectEntities, selectAll } = selectsAdapter.getSelectors();

export const selectAllSelectsEntities = createSelector(
    selectSelectsState,
    selectEntities
);
export const selectAllSelects = createSelector(selectSelectsState, selectAll);

export const selectOne = (props: { id: string }) =>
    createSelector(selectAllSelectsEntities, (entities) => entities[props.id]);
