<div class="form-check" [class.input-checkbox--invert]="invertedStyle">
    <input
        class="form-check-input"
        type="checkbox"
        [attr.data-cy]="'input-toggle-' + value"
        [value]="value"
        [id]="inputId"
        [checked]="checked"
        [indeterminate]="indeterminate"
        [disabled]="disabled"
        (change)="$event.stopPropagation()"
        (click)="onToggle($event)"
    />
    <label
        [for]="inputId"
        [innerHtml]="label | markdownToHtml | safeHtml"
    ></label>
</div>
