import { Injectable, inject } from '@angular/core';
import { Locale } from '@wdx/clmi/api-models';
import { Observable, tap } from 'rxjs';
import { LocaleApiService } from './locale.service';

interface Locales {
    [key: string]: Locale;
}

@Injectable({
    providedIn: 'root',
})
export class LocaleFacadeService {
    private localeApiService = inject(LocaleApiService);

    private _locales: Locales = {};

    get locales(): Locales {
        return this._locales;
    }

    /**
     *
     * @param {string} name - should be similar to 'en-GB'
     * @returns {Locale} - looks at the Locales object to find matching key
     */
    getLocale(name: string): Locale {
        return this._locales[name];
    }

    /**
     * This method also converts the array of Locale to and object on Locale
     *
     * @returns {Observable<Locale[]>} - return data from the network
     */
    loadLocales$(): Observable<Locale[]> {
        return this.localeApiService.getLocales$().pipe(
            tap((res) => {
                this._locales = res.reduce((acc: Locales, val: Locale) => {
                    acc[val.name as string] = val;

                    return acc;
                }, {});
            })
        );
    }
}
