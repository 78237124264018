import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IGetConfig,
    PostalAddress,
    PostalAddressLookup,
} from '@wdx/shared/utils';
import { Observable } from 'rxjs';

@Injectable()
export class AddressesService {
    constructor(private http: HttpClient, private config: IGetConfig) {}

    getAddresses(searchText: string): Observable<PostalAddressLookup[]> {
        return this.http.get<PostalAddressLookup[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/postaladdress/lookup?searchText=${searchText}`
        );
    }

    getAddress(addressId: string): Observable<PostalAddress> {
        return this.http.get<PostalAddress>(
            `${
                this.config.getConfiguration().API_BASE
            }/postaladdress/lookup/${addressId}`
        );
    }
}
