import { TriggerChar } from '../../../features/comments/shared/constants/mention.constant';

export const escapeParenthesis = (text: string): string => {
    if (!text) {
        return;
    }
    return text.replace(/\(/g, '\\(').replace(/\)/g, '\\)');
};

export const replaceString = (
    name: string,
    triggerChar: TriggerChar
): RegExp => {
    return new RegExp(`${triggerChar}${escapeParenthesis(name)}`, 'g');
};
