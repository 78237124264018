import { TypeGuard } from '@wdx/shared/utils';
import { ConfigCellType } from './config-cell-type.model';
import { CubeConfig } from './cube-config.model';
import { CustomConfig } from './custom-config.model';
import { TilesConfig } from './tiles-config.model';

export type CubeDashboardConfigCell = CubeConfig | TilesConfig | CustomConfig;

export const isCubeCell: TypeGuard<CubeDashboardConfigCell, CubeConfig> = (
    cell: CubeDashboardConfigCell
): cell is CubeConfig => cell.cellType === ConfigCellType.Cube;
export const isTilesCell: TypeGuard<CubeDashboardConfigCell, TilesConfig> = (
    cell: CubeDashboardConfigCell
): cell is TilesConfig => cell.cellType === ConfigCellType.Rollups;
export const isCustomCell: TypeGuard<CubeDashboardConfigCell, CustomConfig> = (
    cell: CubeDashboardConfigCell
): cell is CustomConfig => cell.cellType === ConfigCellType.Custom;
