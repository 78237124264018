import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as addressesActions from './addresses.actions';
import { AddressesService } from './addresses.service';

@Injectable()
export class AddressesEffects {
    private actions$ = inject(Actions);
    private addressesService = inject(AddressesService);

    getAddresses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addressesActions.getAddresses),
            switchMap((action) =>
                this.addressesService.getAddresses(action.searchText).pipe(
                    map((addresses) =>
                        addressesActions.getAddressesSuccess({
                            addresses,
                            fieldId: action.fieldId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            addressesActions.getAddressesFailure({
                                error,
                                fieldId: action.fieldId,
                            })
                        )
                    )
                )
            )
        )
    );

    getAddress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addressesActions.getAddress),
            switchMap((action) =>
                this.addressesService.getAddress(action.addressId).pipe(
                    map((address) =>
                        addressesActions.getAddressSuccess({
                            address,
                            fieldId: action.fieldId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            addressesActions.getAddressFailure({
                                addressId: action.addressId,
                                error,
                                fieldId: action.fieldId,
                            })
                        )
                    )
                )
            )
        )
    );
}
