import { Injectable } from '@angular/core';
import { SystemSettings } from '@wdx/clmi/api-services/services';

@Injectable({
    providedIn: 'root',
})
export class SystemSettingsService {
    private settings!: SystemSettings;

    /**
     * Sets the settings data from api (primarily from app guard)
     */
    set(settings: SystemSettings) {
        this.settings = settings;
    }

    /**
     * Return the settings object
     */
    get(): SystemSettings {
        return this.settings;
    }
}
