import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core';
import { WdxSize, WdxThemeColor } from '@wdx/shared/utils';

@Component({
    selector: 'wdx-icon-container',
    templateUrl: './wdx-icon-container.component.html',
    styleUrls: ['./wdx-icon-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxIconContainerComponent implements OnInit {
    @Input() set setBackgroundColor(colour: string) {
        this.updateBackground(colour);
        this._defaultBackgroundColor = true;
    }

    @Input() set size(size: WdxSize) {
        this._size = size;
        this.updateHostClass();
    }
    get size(): WdxSize {
        return this._size as WdxSize;
    }
    private _size?: WdxSize;

    @Input() set themeColor(color: WdxThemeColor) {
        this._themeColor = color;
        this.updateHostClass();
    }
    get themeColor(): WdxThemeColor {
        return this._themeColor as WdxThemeColor;
    }
    _themeColor?: WdxThemeColor;

    @HostBinding('class') get hostClass() {
        return this.hostClasses;
    }

    @HostBinding('style.backgroundColor') get style() {
        return this._style;
    }

    @HostBinding('class.bg-icon')
    get defaultBackgroundColor() {
        return this._defaultBackgroundColor;
    }

    hostClasses = '';
    _style = '';
    _defaultBackgroundColor = false;

    ngOnInit(): void {
        this.updateHostClass();
    }

    private updateHostClass(): void {
        const iconContainer = 'icon-container';
        const size = this.size ? ` icon-container-size-${this.size}` : null;
        const color = this.themeColor
            ? ` bg-${this.themeColor} text-${this.themeColor}-contrast`
            : null;
        this.hostClasses = `${iconContainer}${size ?? ''}${color ?? ''}`;
    }

    private updateBackground(colour: string) {
        this._style = colour ? colour : '';
    }
}
