import { Action, createReducer, on } from '@ngrx/store';
import { Country } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as countriesActions from './countries.actions';

export interface State {
    countries: CrudStateObject<Country>;
}

export const initialState: State = {
    countries: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        countriesActions.getCountries,
        (state): State => ({
            ...state,
            countries: {
                ...state.countries,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.countries[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Country>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        countriesActions.getCountriesSuccess,
        (state, props): State => ({
            ...state,
            countries: {
                ...state.countries,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.countries[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.countries,
                },
            },
        })
    ),

    on(
        countriesActions.getCountriesFailure,
        (state): State => ({
            ...state,
            countries: {
                ...state.countries,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.countries[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
