import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMapHubToAction, signalrConnected } from 'ngrx-signalr-core';
import { merge, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    take,
    withLatestFrom,
} from 'rxjs/operators';

import { ActivityComment } from '@wdx/clmi/api-models';
import { UserFacadeService } from '@wdx/clmi/api-services/services';
import { SignalrEvent } from '@wdx/shared/utils';
import { COMMENTS_PAGE_SIZE } from '../../constants/comments.constants';
import * as rootReducer from './../../state/_setup/reducers';
import * as commentsActions from './comments.actions';
import * as commentsSelectors from './comments.selectors';
import { CommentsService } from './comments.service';

@Injectable()
export class CommentsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private commentsService = inject(CommentsService);
    private userFacadeService = inject(UserFacadeService);

    private me$ = this.userFacadeService.getMe$();

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commentsActions.create),
            mergeMap((action) =>
                this.commentsService
                    .create(action.activityId, action.commentCreateData)
                    .pipe(
                        map((comment) =>
                            commentsActions.createSuccess({
                                activityId: action.activityId,
                                comment,
                                resolveIssueId:
                                    action.commentCreateData.resolveIssueId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                commentsActions.createFailure({
                                    activityId: action.activityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commentsActions.getPage),
            switchMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store$.select(
                            commentsSelectors.getInfinityPaging,
                            { id: action.activityId }
                        )
                    ),
                    switchMap(([action, paging]) =>
                        this.commentsService
                            .getPage(
                                {
                                    pageNumber: action.reset
                                        ? 1
                                        : action.page ||
                                          (paging?.page || 0) + 1,
                                    pageSize: action.pageSize,
                                },
                                action.activityId
                            )
                            .pipe(
                                map((comments) =>
                                    commentsActions.getPageSuccess({
                                        activityId: action.activityId,
                                        comments,
                                        reset:
                                            action.reset || action.page === 1,
                                    })
                                ),
                                catchError((error) =>
                                    of(
                                        commentsActions.getPageFailure({
                                            activityId: action.activityId,
                                            error,
                                        })
                                    )
                                )
                            )
                    )
                )
            )
        )
    );

    getPageSilently$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commentsActions.getCommentsSilently),
            switchMap((action) =>
                of(action).pipe(
                    switchMap((action) =>
                        this.commentsService
                            .getPage(
                                {
                                    pageNumber: 1,
                                    pageSize: COMMENTS_PAGE_SIZE,
                                },
                                action.activityId
                            )
                            .pipe(
                                map((comments) =>
                                    commentsActions.getCommentsSilentlySuccess({
                                        activityId: action.activityId,
                                        comments,
                                    })
                                ),
                                catchError((error) =>
                                    of(
                                        commentsActions.getPageFailure({
                                            activityId: action.activityId,
                                            error,
                                        })
                                    )
                                )
                            )
                    )
                )
            )
        )
    );

    listenToEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    hub.on(SignalrEvent.ActivityCommentAdded).pipe(
                        switchMap((activityComment: ActivityComment) =>
                            this.me$.pipe(
                                filter(
                                    (me) => me.id !== activityComment?.party?.id
                                ),
                                map(() => activityComment)
                            )
                        ),
                        switchMap((activityComment) =>
                            this.store$
                                .select(
                                    commentsSelectors.getCommentById,
                                    activityComment?.activitySummary?.id
                                )
                                .pipe(
                                    filter((activities) => Boolean(activities)),
                                    take(1),
                                    map(() => {
                                        return commentsActions.getCommentsSilently(
                                            {
                                                activityId:
                                                    activityComment
                                                        ?.activitySummary?.id,
                                            }
                                        );
                                    })
                                )
                        )
                    )
                )
            )
        )
    );
}
