import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    FORM_HISTORY_INSTANCE_FEATURE_KEY,
    formHistoryInstanceAdapter,
    FormHistoryInstanceState,
    selectFormHistoryInstanceId,
} from './form-history-instance.reducer';

export const selectFormHistoryInstanceState =
    createFeatureSelector<FormHistoryInstanceState>(
        FORM_HISTORY_INSTANCE_FEATURE_KEY
    );

const { selectIds, selectEntities, selectTotal } =
    formHistoryInstanceAdapter.getSelectors();

export const selectFormHistoryInstanceIds = createSelector(
    selectFormHistoryInstanceState,
    selectIds
);
export const selectFormHistoryInstanceEntities = createSelector(
    selectFormHistoryInstanceState,
    selectEntities
);
export const selectFormHistoryInstanceTotal = createSelector(
    selectFormHistoryInstanceState,
    selectTotal
);

export const selectFormHistoryInstanceEntity = (
    formId: string,
    entityId: string,
    instanceId: string
) =>
    createSelector(
        selectFormHistoryInstanceEntities,
        (formHistoryInstanceEntities) =>
            formId &&
            entityId &&
            instanceId &&
            formHistoryInstanceEntities &&
            formHistoryInstanceEntities[
                selectFormHistoryInstanceId({ formId, entityId, instanceId })
            ]
    );

export const selectFormHistoryInstanceStatus = (
    formId: string,
    entityId: string,
    instanceId: string
) =>
    createSelector(
        selectFormHistoryInstanceEntity(formId, entityId, instanceId),
        (formHistoryInstanceEntity) =>
            formHistoryInstanceEntity ? formHistoryInstanceEntity.status : null
    );

export const selectFormHistoryInstance = (
    formId: string,
    entityId: string,
    instanceId: string
) =>
    createSelector(
        selectFormHistoryInstanceEntity(formId, entityId, instanceId),
        (formHistoryInstanceEntity) =>
            formHistoryInstanceEntity
                ? formHistoryInstanceEntity.formHistoryInstance
                : null
    );
