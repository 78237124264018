import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'textToNumber', standalone: true })
export class TextToNumberPipe implements PipeTransform {
    transform(value: string, max?: number): number | undefined {
        if (!value) {
            return;
        }

        const num = value.split('').reduce((accumulator, character) => {
            if (accumulator === Infinity) {
                accumulator = 1;
            }
            return accumulator * character.charCodeAt(0);
        }, 1);

        return max ? num % max : num;
    }
}
