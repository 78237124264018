import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SystemEntity } from '@wdx/clmi/api-models';
import { operationsActions } from '@wdx/clmi/api-services/state';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WorkItemDialogService } from '../../shared/features/work-item-comment';
import * as outcomesActions from './outcomes.actions';
import { OutcomesService } from './outcomes.service';

@Injectable()
export class OutcomesEffects {
    private actions$ = inject(Actions);
    private outcomesService = inject(OutcomesService);
    private workItemDialogService = inject(WorkItemDialogService);

    setOutcomeForWorkItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(outcomesActions.setOutcomeForWorkItem),
            switchMap((action) =>
                this.outcomesService
                    .setOutcomeForWorkItem(
                        action.outcomeId,
                        action.workItemId,
                        action?.comment
                    )
                    .pipe(
                        map((response) =>
                            outcomesActions.setOutcomeForWorkItemSuccess({
                                outcomeId: action.outcomeId,
                                workItemId: action.workItemId,
                                message: response.message,
                            })
                        ),
                        catchError((error) => {
                            if (
                                error.status === 422 &&
                                error.errorCode === 'RequiresComment'
                            ) {
                                return of(
                                    outcomesActions.setShowRequireComment({
                                        outcomeId: action.outcomeId,
                                        workItemId: action.workItemId,
                                    }),
                                    outcomesActions.setOutcomeForWorkItemFailure(
                                        {
                                            outcomeId: action.outcomeId,
                                            workItemId: action.workItemId,
                                            error,
                                        }
                                    )
                                );
                            } else {
                                return of(
                                    outcomesActions.setOutcomeForWorkItemFailure(
                                        {
                                            outcomeId: action.outcomeId,
                                            workItemId: action.workItemId,
                                            error,
                                        }
                                    )
                                );
                            }
                        })
                    )
            )
        )
    );

    setShowRequireComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(outcomesActions.setShowRequireComment),
            switchMap((action) => {
                this.workItemDialogService.open();

                return this.workItemDialogService.submitForm$.pipe(
                    map(() =>
                        outcomesActions.setOutcomeForWorkItem({
                            outcomeId: action.outcomeId,
                            workItemId: action.workItemId,
                            comment: `${this.workItemDialogService?.formValue?.comment} - [${this.workItemDialogService?.formValue?.reason}]`,
                        })
                    )
                );
            })
        )
    );

    setOutcomeForWorkItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(outcomesActions.setOutcomeForWorkItemSuccess),
            map((action) =>
                operationsActions.getOperationsForId({
                    entityType: SystemEntity.Activity,
                    entityId: action.workItemId,
                })
            )
        )
    );
}
