<ngb-toast
    *ngFor="let toast of wdxToastService.toasts"
    [autohide]="toast.autohide"
    [header]="toast.header"
    [ngClass]="toast.severity && 'toast-' + toast.severity"
    [delay]="toast.delay || 5000"
    (hidden)="wdxToastService.remove(toast)"
    (mouseenter)="toast.autohide = false"
    (mouseleave)="toast.autohide = true"
>
    <div class="d-flex align-items-center">
        <div class="flex-grow-1">
            <ng-container *ngIf="toast.isTemplate; else text">
                <ng-container
                    *ngTemplateOutlet="$any(toast).body"
                ></ng-container>
            </ng-container>

            <ng-template #text>
                <div class="d-flex align-items-center">
                    <ng-container *ngIf="!toast.header">
                        <wdx-spinner
                            *ngIf="toast.throbber"
                            class="toast-throbber d-block position-relative me-2"
                            [themeColor]="toast.themeColor!"
                            [small]="true"
                        ></wdx-spinner>

                        <wdx-icon
                            *ngIf="!toast.throbber && toast.severity"
                            class="icon-shift-down align-self-start me-1"
                            [icon]="toast.icon"
                            [fixedWidth]="true"
                        ></wdx-icon>
                    </ng-container>

                    <div>
                        <ng-container *ngFor="let line of $any(toast).body">
                            <a
                                *ngIf="toast.bodyRouterLink"
                                class="text-decoration-underline"
                                data-cy="wdx-toast-link"
                                [routerLink]="toast.bodyRouterLink"
                                [queryParams]="toast.bodyQueryParams"
                            >
                                {{ line }}
                            </a>

                            <div
                                *ngIf="!toast.bodyRouterLink"
                                data-cy="wdx-toast-text"
                                [innerHTML]="
                                    line
                                        | translateTokenisedString
                                        | markdownToHtml
                                        | safeHtml
                                "
                            ></div>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
        </div>

        <div
            *ngIf="!toast.hideCloseOnBody && !toast.header"
            class="flex-shrink-0 align-self-start"
        >
            <wdx-icon-button
                class="ms-4"
                data-cy="wdx-toast-close-btn"
                aria-label="Close"
                [severity]="toast.severity"
                (wdxOnClick)="wdxToastService.remove(toast)"
            >
                <wdx-icon icon="times"></wdx-icon>
            </wdx-icon-button>
        </div>
    </div>
</ngb-toast>
