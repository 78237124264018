import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { GenericWithId } from '../../models/generics';

/**
 * @deprecated This should not be used
 */
@Injectable({
    providedIn: 'root',
})
export class WdxUtilitiesService {
    /**
     * Returns value within an array. If value is already an array, then it returns the value unmutated.
     * @param {any} value - The value to be transformed
     */
    transformToArray(value: any): any[] {
        return Array.isArray(value) ? value : [value];
    }

    /**
     * Returns an observable combining the latest from a set of boolean observable values
     * @param {Observable<boolean>} bools - Array of boolean observables to subscribe to
     */
    combineBools$(bools: Observable<boolean>[]): Observable<boolean> {
        return new Observable<boolean>((subscriber) => {
            combineLatest(bools).subscribe((results) =>
                subscriber.next(this.combineBools(results))
            );
        });
    }

    /**
     * Returns true if any of the bools args are true; returns false otherwise
     * @param {boolean[]} bools - Array of booleans to compare
     */
    combineBools(bools: boolean[]): boolean {
        return bools.reduce((reducer, bool) => bool || reducer, false);
    }

    /**
     * Update an object within an array. Object must contain an 'id' parameter.
     * @param {T[]} arr - Array containing object
     * @param {T} item - Item containing id matching one within the arr argument to be updated
     */
    updateObjectInArray<T extends GenericWithId>(arr: T[], item: T): T[] {
        if (!arr?.length) {
            return [];
        }
        const index = arr.findIndex((arrayItem) => arrayItem.id === item.id);
        if (index > -1) {
            const updatedArray = [...arr];
            updatedArray[index] = item;
            return updatedArray;
        }
        return [...arr, item];
    }

    /**
     * Delete an object from an array.
     * @param {T[]} arr - Array containing object (must have 'id' parameter)
     * @param {string} id - Id of object to be deleted
     */
    deleteObjectFromArray<T extends GenericWithId>(arr: T[], id: string): T[] {
        const updatedArray = [...arr];
        const index = updatedArray.findIndex(
            (arrayItem) => arrayItem.id === id
        );
        if (index > -1) {
            updatedArray.splice(index, 1);
        }
        return updatedArray;
    }

    /**
     * Returns true if a value is null or undefined (ie. NOT just falsy)
     * @param {any} value
     */
    isNullOrUndefined(value: any): boolean {
        return value === null || value === undefined;
    }
}
