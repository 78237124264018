import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_HANDLED_ERROR_CODES } from '../constants/http.constants';
import { ErrorEvent } from '../models/error.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorManagementService } from '../shared/services/error-management/error-management.service';
import { API_BYPASS_ERROR_HANDLER_HEADER_NAME } from '@wdx/shared/utils';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
    constructor(private errorManagementService: ErrorManagementService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: ErrorEvent) => {
                if (
                    !this.bypassErrorHandler(req, error) &&
                    !APP_HANDLED_ERROR_CODES.includes(Number(error?.status))
                ) {
                    this.errorManagementService.handleError(req, error);
                }
                throw error;
            })
        );
    }

    private bypassErrorHandler(
        req: HttpRequest<any>,
        error: ErrorEvent
    ): boolean {
        const bypassHeader = req.headers.get(
            API_BYPASS_ERROR_HANDLER_HEADER_NAME
        );
        if (bypassHeader === null) {
            return false;
        }
        const bypassRegex = new RegExp(bypassHeader);
        return bypassRegex.test(error.status?.toString());
    }
}
