import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseComponentClass } from '../../../../../classes/base-component.class';
// eslint-disable-next-line max-len
import { ActivityType, CaseType, SystemEntity } from '@wdx/clmi/api-models';
import * as rootReducer from '../../../../../state/_setup/reducers';
import * as caseTypesActions from '../../../../../state/case-types/case-types.actions';
import * as caseTypesSelectors from '../../../../../state/case-types/case-types.selectors';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-case-template-search',
    templateUrl: './organism-case-template-search.component.html',
})
export class OrganismCaseTemplateSearchComponent
    extends BaseComponentClass
    implements OnInit
{
    @Input() activityType: ActivityType;
    @Input() activityId: string;
    @Input() clientId: string;
    @Input() opportunityId: string;
    @Input() partyId: string;
    @Input() partyRoleId: string;
    @Input() caseId: string;
    @Input() nudgeId: string;
    @Input() entityType: SystemEntity;

    caseTypes$: Observable<CaseType[]>;
    caseTypesIsLoading$: Observable<boolean>;
    caseTypesHasError$: Observable<boolean>;

    constructor(store$: Store<rootReducer.State>) {
        super();

        this.patchInjectedItems({ store$ });

        this.caseTypes$ = this.store$.select(caseTypesSelectors.getList);
        this.caseTypesIsLoading$ = this.store$.select(
            caseTypesSelectors.getIsLoadingList
        );
        this.caseTypesHasError$ = this.store$.select(
            caseTypesSelectors.getHasLoadingListError
        );
    }

    ngOnInit() {
        this.store$.dispatch(
            caseTypesActions.getAll({
                isActive: true,
                entityType: this.entityType,
            })
        );
    }
}
