export enum Severity {
    Danger = 'danger',
    Success = 'success',
    Warning = 'warning',
    Info = 'info',
    None = '',
}

export enum SeverityStyle {
    Default = 'default',
    Bold = 'bold',
    Text = 'text',
}
