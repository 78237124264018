import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemEntity } from '@wdx/clmi/api-models';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class QueuesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    assign(
        entityType: SystemEntity,
        entityId: string,
        partyId: string
    ): Observable<any> {
        return this.http.patch<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/assign/${partyId}`,
            {}
        );
    }
}
