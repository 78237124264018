import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as amlReportsActions from './aml-reports.actions';
import { AmlReportsService } from './aml-reports.service';

@Injectable()
export class AmlReportsEffects {
    private actions$ = inject(Actions);
    private amlReportsService = inject(AmlReportsService);

    getAmlReportForAppStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(amlReportsActions.getAmlReportForAppStatus),
            switchMap((action) =>
                this.amlReportsService
                    .getAmlReportForAppStatus(action.appStatusId)
                    .pipe(
                        map((amlReport) =>
                            amlReportsActions.getAmlReportForAppStatusSuccess({
                                appStatusId: action.appStatusId,
                                amlReport,
                            })
                        ),
                        catchError((error) =>
                            of(
                                amlReportsActions.getAmlReportForAppStatusFailure(
                                    {
                                        appStatusId: action.appStatusId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    runAmlReportForAppStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(amlReportsActions.runAmlReportForAppStatus),
            switchMap((action) =>
                this.amlReportsService
                    .runAmlReportForAppStatus(
                        action.appStatusId,
                        action.invitationType
                    )
                    .pipe(
                        map((amlReport) =>
                            amlReportsActions.runAmlReportForAppStatusSuccess({
                                appStatusId: action.appStatusId,
                                amlReport,
                            })
                        ),
                        catchError((error) =>
                            of(
                                amlReportsActions.runAmlReportForAppStatusFailure(
                                    {
                                        appStatusId: action.appStatusId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    updateAmlHitForAppStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(amlReportsActions.updateAmlHitForAppStatus),
            switchMap((action) =>
                this.amlReportsService
                    .updateAmlHitForAppStatus(action.amlHit)
                    .pipe(
                        map(() =>
                            amlReportsActions.updateAmlHitForAppStatusSuccess({
                                amlHit: action.amlHit,
                            })
                        ),
                        catchError((error) =>
                            of(
                                amlReportsActions.updateAmlHitForAppStatusFailure(
                                    {
                                        amlHit: action.amlHit,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
