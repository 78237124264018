import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as rootReducer from '../_setup/reducers';
import * as emailActions from './email.actions';
import { EmailService } from './email.service';

@Injectable()
export class EmailEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private emailService = inject(EmailService);

    getEmailContentForActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(emailActions.getEmailContentForActivity),
            mergeMap((action) =>
                this.emailService
                    .getEmailContentForActivity(action.activityId)
                    .pipe(
                        map((emailContent) =>
                            emailActions.getEmailContentForActivitySuccess({
                                activityId: action.activityId,
                                emailContent,
                            })
                        ),
                        catchError((error) =>
                            of(
                                emailActions.getEmailContentForActivityFailure({
                                    activityId: action.activityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
