import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import * as messagesActions from '../../state/message/message.actions';
import * as authenticationActions from './authentication.actions';

@Injectable()
export class AuthenticationEffects {
    private actions$ = inject(Actions);
    private authenticationService = inject(AuthenticationService);

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authenticationActions.logout),
            map(() => messagesActions.removeAllMessages()),
            tap(() => this.authenticationService.logout())
        )
    );
}
