<div class="input-wrapper">
    <ng-container *ngIf="!inputOnly">
        <div
            *ngIf="formInputData?.label || forceShowLabel"
            class="input-wrapper__label d-flex align-items-baseline"
            [ngClass]="{
                'w-50 compensate-padding-right': labelIsHalfWidth
            }"
        >
            <wdx-icon
                *ngIf="formInputData?.isLocked"
                class="text-muted me-2"
                icon="lock"
                iconStyle="fas"
            ></wdx-icon>
            <atom-guidance-text-icon
                *ngIf="formInputData?.guidanceText"
                class="d-block me-2"
                [guidanceText]="formInputData.guidanceText"
            ></atom-guidance-text-icon>
            <div class="form-label d-flex flex-grow-1 flex-lg-grow-0 me-1">
                {{ formInputData.label | translateTokenisedString }}
                <sup class="ms-1 me-4" *ngIf="formInputData.isRequired">
                    <wdx-icon class="text-danger" icon="asterisk"></wdx-icon>
                </sup>
            </div>
            <ng-container *ngIf="formDisplayMode !== FORM_DISPLAY_MODE.Summary">
                <ng-content select=".action-buttons"></ng-content>
            </ng-container>
        </div>
    </ng-container>
    <div
        class="input-wrapper__content"
        [class.ml-0]="!formInputData?.label || hideGuidanceText"
        [attr.data-cy]="'card-' + formInputData?.label"
    >
        <ng-content></ng-content>

        <div *ngIf="!inputOnly" class="invalid-feedback">
            {{ validationErrorMessage || '&nbsp;' }}
        </div>
    </div>
</div>
