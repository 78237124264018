import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { View } from '@wdx/clmi/api-models';
import { BaseApiService } from '../base-api.service';
import { ViewUrlSegment } from './view-url-segment';
import { ApiConfigService } from '../api-config.service';

@Injectable({ providedIn: 'root' })
export class ViewApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getAll(): Observable<View[]> {
        return this.get<View[]>(`${ViewUrlSegment.View}`);
    }

    getForEntity(entityType: string, typeCode?: string): Observable<View[]> {
        const queryString = typeCode ? `?typeCode=${typeCode}` : '';
        return this.get<View[]>(
            `${ViewUrlSegment.View}/${entityType}${queryString}`
        );
    }

    getSingleForEntityType(
        viewId: string,
        entityType: string
    ): Observable<View> {
        return this.get<View>(`${ViewUrlSegment.View}/${entityType}/${viewId}`);
    }

    create(entityType: string, view: View): Observable<View> {
        return this.post<View, any>(
            `${ViewUrlSegment.View}/${entityType}`,
            view
        );
    }

    update(viewId: string, entityType: string, view: View): Observable<View> {
        return this.put<View>(
            `${ViewUrlSegment.View}/${entityType}/${viewId}`,
            view
        );
    }

    deleteView(viewId: string, entityType: string): Observable<any> {
        return this.delete(`${ViewUrlSegment.View}/${entityType}/${viewId}`);
    }

    share(
        viewId: string,
        entityType: string,
        partyId: string
    ): Observable<object> {
        return this.patch(
            `${ViewUrlSegment.View}/${entityType}/${viewId}/${ViewUrlSegment.Share}/${partyId}`,
            {}
        );
    }
}
