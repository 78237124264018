import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Portfolio } from '@wdx/clmi/api-models';
import { ConfigService } from '../../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class PortfolioService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    /**
     *
     * @param portfolioId | This is the portfolio id
     * @returns Observable<Portfolio>
     */
    getPortfolio(portfolioId: string): Observable<Portfolio> {
        return this.http.get<Portfolio>(
            `${
                this.config.getConfiguration().API_BASE
            }/portfolio/${portfolioId}`
        );
    }
}
