import { Directive, HostBinding, Input, OnInit } from '@angular/core';

import { WdxButtonSize, WdxButtonStyle } from './wdx-button.interface';

@Directive({
    selector:
        'button[wdxButton], a[wdxButton], button[wdxIconButton], a[wdxIconButton]',
})
export class WdxButtonDirective implements OnInit {
    /**
     * sets the button type
     */
    @Input() wdxButton: WdxButtonStyle | '' = '';

    /**
     * Specify the size of the button
     */
    @Input() wdxButtonSize: WdxButtonSize = WdxButtonSize.Normal;

    /**
     * Update styling for buttons that contain only an icon
     */
    @Input() wdxIconButton!: boolean | string;

    // a whole lot of HostBindings ... this way we don't have to touch the elementRef directly
    @HostBinding('class.btn') get baseClass() {
        return true;
    }

    @HostBinding('class.btn-primary') get primaryButton() {
        return this.wdxButton === WdxButtonStyle.Primary;
    }

    @HostBinding('class.btn-danger') get dangerButton() {
        return this.wdxButton === WdxButtonStyle.Danger;
    }

    @HostBinding('class.btn-outline-primary') get outlinePrimaryButton() {
        return this.wdxButton === WdxButtonStyle.OutlinePrimary;
    }

    @HostBinding('class.btn-link') get link() {
        return this.wdxButton === WdxButtonStyle.Link;
    }

    @HostBinding('class.btn-inline') get inline() {
        return this.wdxButton === WdxButtonStyle.Inline;
    }

    @HostBinding('class.btn-success') get success() {
        return this.wdxButton === WdxButtonStyle.Success;
    }

    @HostBinding('class.btn-sm') get smallSize() {
        return this.wdxButtonSize === WdxButtonSize.Sm;
    }

    @HostBinding('class.btn-lg') get largeSize() {
        return this.wdxButtonSize === WdxButtonSize.Lg;
    }

    @HostBinding('class.btn-icon') get iconBtn() {
        return this.wdxIconButton;
    }

    @HostBinding('class.text-decoration-none') get linkTextDecoration() {
        return this.wdxIconButton;
    }

    ngOnInit(): void {
        this.setButton();
        this.setIconButton();
    }

    setButton(): void {
        if (!this.wdxButton) {
            this.wdxButton = WdxButtonStyle.OutlinePrimary;
        }
    }

    setIconButton(): void {
        this.wdxIconButton =
            Boolean(this.wdxIconButton) || this.wdxIconButton === '';
    }
}
