import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxInputTimeDirective } from './wdx-input-time.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxInputTimeDirective],
    exports: [WdxInputTimeDirective],
})
export class WdxInputTimeModule {}
