import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const MILLION = 1000000;

@Component({
    selector: 'clmi-filter-currency',
    templateUrl: './filter-currency.component.html',
    styleUrls: ['./filter-currency.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FilterCurrencyComponent),
        },
    ],
})
export class FilterCurrencyComponent implements ControlValueAccessor {
    @Input() cySelector?: string;

    public millions;

    touched = false;

    disabled = false;

    onChange = (value: number) => value;

    onTouched = () => undefined;

    writeValue(value: number): void {
        this.millions = value ? value / MILLION : value;
    }
    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    displayValueChanged(event: Event) {
        const value = Number.parseFloat(event.target['value']) || 0;
        this.millions = value;
        this.onChange(this.millions * MILLION);
    }
}
