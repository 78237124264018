import {
    Directive,
    ElementRef,
    HostBinding,
    Optional,
    Renderer2,
    Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: 'input[wdxInputText],select[wdxInputText],textarea[wdxInputText]',
    /* eslint-disable @angular-eslint/no-host-metadata-property */
    host: {
        class: 'form-control',
    },
})
export class WdxInputTextDirective {
    @HostBinding('class.is-invalid') get isInvalidClass() {
        return this.control?.touched && this.control?.status === 'INVALID';
    }

    constructor(
        @Optional() @Self() public control: NgControl,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.renderer.setAttribute(
            this.elementRef.nativeElement,
            'autocomplete',
            'noop'
        );
    }
}
