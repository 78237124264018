import { Pipe, PipeTransform } from '@angular/core';
import { Severity } from './severity.model';

@Pipe({ name: 'severity', standalone: true })
export class SeverityPipe implements PipeTransform {
    transform(
        severity: Severity = Severity.Info,
        property?: Array<'bg' | 'text' | 'border'>,
        modifier?:
            | 'light'
            | 'dark'
            | 'contrast'
            | 'light-contrast'
            | 'dark-contrast'
    ): string | null {
        if (!severity) {
            return null;
        }
        if (property?.length) {
            return property
                .map((prop) => this.setClass(severity, prop, modifier))
                .join(' ');
        }
        return this.setClass(severity, undefined, modifier);
    }

    private setClass(
        severity: Severity,
        property?: string,
        modifier?: string
    ): string {
        return `${property ? property + '-' : ''}${severity.valueOf()}${
            modifier ? '-' + modifier : ''
        }`;
    }
}
