import { KeyValueObject } from '../../models/key-value-object';

const CLMI_DARK_THEME_SVG: KeyValueObject<string> = {
    'svg-primary': 'primary-light',
    'svg-primary-fill': 'primary',
    'svg-secondary': 'secondary',
    'svg-secondary-fill': 'secondary-dark',
    'svg-bg': 'body-bg-muted',
};

export const CLMI_DARK_THEME_VARS: KeyValueObject<string> = {
    ...CLMI_DARK_THEME_SVG,
};
