import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeAvatarModule } from '../molecule-avatar/molecule-avatar.module';
import { MoleculeArticleSummaryComponent } from './molecule-article-summary.component';

@NgModule({
    imports: [
        CommonModule,
        MoleculeAvatarModule,
        PipesModule,
        WdxBadgeModule,
        WdxIconModule,
    ],
    declarations: [MoleculeArticleSummaryComponent],
    exports: [MoleculeArticleSummaryComponent],
})
export class MoleculeArticleSummaryModule {}
