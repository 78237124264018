<ng-container
    *ngIf="
        formElement?.fieldType === FORM_FIELD_TYPE.Address;
        else controlMapper
    "
    [formGroup]="$any(controlContainer?.control)"
>
    <wdx-ff-address-control
        [formElement]="formElement"
    ></wdx-ff-address-control>
</ng-container>

<ng-template #controlMapper>
    <wdx-ff-controls-mapper
        [formElement]="formElement"
        [formContext]="formContext"
    ></wdx-ff-controls-mapper>
</ng-template>
