import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification, Query } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class NotificationsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getPage(
        paginationOptions: PaginationOptions
    ): Observable<PaginatedApiResponse<Notification>> {
        return this.http.get<PaginatedApiResponse<Notification>>(
            `${this.config.getConfiguration().API_BASE}/user/me/notification`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    getForView(
        filters: Query,
        paginationOptions: PaginationOptions
    ): Observable<PaginatedApiResponse<Notification>> {
        return this.http.post<PaginatedApiResponse<Notification>>(
            `${this.config.getConfiguration().API_BASE}/query/notification`,
            filters,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    markAsRead(notifications: Notification[]): Observable<void> {
        const ids = notifications?.map((notification) => notification.id);
        return this.http.patch<void>(
            `${this.config.getConfiguration().API_BASE}/notification/read`,
            ids
        );
    }

    markAllAsRead(): Observable<void> {
        return this.http.patch<void>(
            `${this.config.getConfiguration().API_BASE}/user/me/notification`,
            {}
        );
    }
}
