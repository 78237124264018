import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AtomFeatureSvgComponent } from './atom-feature-svg.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [AtomFeatureSvgComponent],
    exports: [AtomFeatureSvgComponent],
})
export class AtomFeatureSvgModule {}
