import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AtomWidgetFullWidthButtonComponent } from './atom-widget-full-width-button.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [AtomWidgetFullWidthButtonComponent],
    exports: [AtomWidgetFullWidthButtonComponent],
})
export class AtomWidgetFullWidthButtonModule {}
