import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '../../services/translations';

@Pipe({
    name: 'translate',
    standalone: true,
})
export class TranslatePipe implements PipeTransform {
    constructor(private translationsService: TranslationsService) {}

    transform(value: string, fallback = ''): string {
        if (!value) {
            return fallback;
        }
        return (
            this.translationsService.getTranslationByKey(
                value?.toUpperCase()
            ) || fallback
        );
    }
}
