export type RoleType = RoleTypeEnum | string;

export enum RoleTypeEnum {
    Accountant = 'ACC',
    Adviser = 'ADV',
    Assistant = 'ASS',
    Association = 'ASC',
    AuthorisedSignatory = 'AUT',
    BeneficialOwner = 'BOW',
    Beneficiary = 'BEN',
    BusinessDevelopment = 'BIZDEV',
    BusinessPartner = 'BPT',
    BusinessRiskManager = 'BSM',
    CentralFile = 'CNTF',
    Charity = 'CHR',
    Collective = 'COL',
    CollectiveAccountHolder = 'CAH',
    ContactClientTeamManager = 'CCTM',
    Company = 'COM',
    Competitor = 'CPT',
    ControllingPerson = 'CTR',
    Corporate = 'COR',
    CustomerServices = 'CS',
    Exporter = 'EXP',
    ComplianceOfficer = 'CMP',
    Correspondent = 'CRS',
    Council = 'COU',
    Director = 'DIR',
    Employee = 'EMP',
    ExternalUser = 'EU',
    FinancialPlanner = 'FP',
    Founder = 'FND',
    Foundation = 'FOU',
    Individual = 'IND',
    InsurancePolicyHolder = 'POY',
    Intermediary = 'INT',
    InvestmentManager = 'IM',
    Introducer = 'ITD',
    Joint = 'JNT',
    JointAccountHolder = 'JAH',
    Lawyer = 'LAW',
    LegalRepresentative = 'REP',
    LegalOfficer = 'LGL',
    Member = 'MEM',
    OperationsOfficer = 'OPS',
    PartnerAssociate = 'PAS',
    PayerOfInsurancePremium = 'PAY',
    Person = 'SELF',
    PowerOfAttorney = 'POA',
    ProductManager = 'PRDM',
    ProductProvider = 'PRP',
    Professional = 'PRO',
    ProfessionalFirm = 'FIR',
    Protector = 'PRT',
    QualityAssuranceOfficer = 'QA',
    ReadOnlyUser = 'ROU',
    RelationshipManager = 'RM',
    RightofInformation = 'ROI',
    Settlor = 'SET',
    Shareholder = 'SHR',
    Solicitor = 'SOL',
    SystemAdministrator = 'SA',
    SystemUser = 'USR',
    TaxOfficer = 'TAX',
    Team = 'TEA',
    TenantAdministrator = 'TENADM',
    ThirdParty = 'THR',
    Trust = 'TRU',
    Trustee = 'TRT',
    Usufructuary = 'USU',
    Vendor = 'VND',
}
