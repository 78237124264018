import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityComment, ActivityCommentCreate } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class CommentsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    create(
        activityId: string,
        commentCreateData: ActivityCommentCreate
    ): Observable<ActivityComment> {
        return this.http.post<ActivityComment>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/comment`,
            commentCreateData
        );
    }

    getPage(
        paginationOptions: PaginationOptions,
        activityId: string
    ): Observable<PaginatedApiResponse<ActivityComment>> {
        return this.http.get<PaginatedApiResponse<ActivityComment>>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/comment`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }
}
