import { Directive, Input, OnInit } from '@angular/core';
import {
    WdxDestroyClass,
    WdxSystemColor,
    WdxThemeColor,
    WdxThemeColorSuffix,
} from '@wdx/shared/utils';

@Directive()
export abstract class ThemeComponentClass
    extends WdxDestroyClass
    implements OnInit
{
    /**
     *  Theme color that follows bootstrap theme names plus -light and -dark suffixes',
     */
    @Input() set themeColor(themeColor: WdxThemeColor | null) {
        this._themeColor = themeColor;
        this.setThemeClass();
        this.setThemeVariables();
    }
    get themeColor(): WdxThemeColor | null {
        return this._themeColor;
    }
    _themeColor!: WdxThemeColor | null;

    @Input() set systemColor(systemColor: WdxSystemColor) {
        this._systemColor = systemColor;
        this.setSystemColor();
    }
    get systemColor(): WdxSystemColor {
        return this._systemColor as WdxSystemColor;
    }
    _systemColor?: WdxSystemColor;

    /**
     * Theme suffix
     */
    @Input() suffix?: WdxThemeColorSuffix;

    themeClassPrefix?: string;
    themeColorDefault!: WdxThemeColor;
    themeClass?: string | null;

    themeVar?: string;
    themeVarLight?: string;
    themeVarDark?: string;
    themeVarContrast?: string;

    ngOnInit(): void {
        if (this.themeColorDefault) {
            this.setThemeClass();
            this.setThemeVariables();
        }
    }

    setThemeClass(): void {
        const clr = this.themeColor || this.themeColorDefault || null;
        if (clr === 'none') {
            this.themeClass = null;
        } else {
            this.themeClass = this.setClass(
                clr,
                this.themeClassPrefix,
                this.suffix
            );
        }
    }

    setThemeVariables(): void {
        const prefix = '--bs-';
        let baseVar: string;
        if (this.themeClass) {
            const base = (this.themeColor || this.themeColorDefault).split(
                '-'
            )[0];
            baseVar = `${prefix}${base}`;
            this.themeVar = `var(${baseVar})`;
            this.themeVarLight = `var(${baseVar}-light)`;
            this.themeVarDark = `var(${baseVar}-dark)`;
            this.themeVarContrast = `var(${baseVar}-contrast)`;
        } else {
            baseVar = `${prefix}body-bg`;
            this.themeVar = `var(${baseVar})`;
            this.themeVarLight = `var(${baseVar}-muted)`;
            this.themeVarDark = `var(${baseVar}-strong)`;
            this.themeVarContrast = `var(${prefix}body-color)`;
        }
    }

    setSystemColor(): void {
        if (this.themeColor) {
            this.setThemeClass();
        } else {
            this.themeClass = this.systemColor
                ? `bg-${this.systemColor} text-${this.systemColor}-contrast`
                : '';
        }
    }

    private setClass(
        color: WdxThemeColor | null,
        prefix?: string,
        suffix?: string
    ): string {
        return color
            ? `${prefix ? prefix + '-' : ''}${color}${
                  suffix ? '-' + suffix : ''
              }`
            : '';
    }
}
