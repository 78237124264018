import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { BASE_ANIMATION_DURATION_MILLISECONDS } from '../constants/animation';

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
    state('in', style({ opacity: 1 })),

    transition(':enter', [
        style({ opacity: 0 }),
        animate(BASE_ANIMATION_DURATION_MILLISECONDS),
    ]),

    transition(
        ':leave',
        animate(BASE_ANIMATION_DURATION_MILLISECONDS, style({ opacity: 0 }))
    ),
]);
