<div class="radio-tile">
    <ng-content></ng-content>
    <label [for]="idFor">
        <div class="bullet"></div>
        <div class="tile-icon">
            <ng-content select="[icon]"></ng-content>
        </div>
        <div
            class="tile-label align-items-center flex-column justify-content-center tile-label"
            data-cy="radio-tile-label"
        >
            {{ label }}
            <small *ngIf="subLabel" data-cy="radio-tile-sub-label">
                {{ subLabel }}
            </small>
        </div>
    </label>
</div>
