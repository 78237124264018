import { Injectable, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { SystemApiService } from '@wdx/clmi/api-services/services';
import { ROUTE_PREFIX } from '@wdx/shared/utils';
import { Observable, of, map, switchMap, take, tap } from 'rxjs';
import { AdministratorService } from '../../../shared/services/administrator';
import { NavigationService } from '../../services';

@Injectable({
    providedIn: 'root',
})
export class AdministratorGuard {
    canActivate(
        administratorService: AdministratorService,
        router: Router,
        navigationService: NavigationService,
        systemApiService: SystemApiService
    ): Observable<boolean | UrlTree> {
        return administratorService.isTenantAdminstrator$().pipe(
            switchMap((isAdministrator) => {
                if (!isAdministrator) {
                    return of(router.createUrlTree(ROUTE_PREFIX));
                }

                return navigationService.adminNavMenu$.pipe(
                    take(1),
                    switchMap((adminSiteMap) => {
                        if (adminSiteMap) {
                            return of(true);
                        }
                        return systemApiService.getAdminSiteMap().pipe(
                            tap((sitemap) => {
                                navigationService.setAdminSiteMap(sitemap);
                            }),
                            map(() => true)
                        );
                    })
                );
            })
        );
    }
}

export const canActivateAdministrator: CanActivateFn = (
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
) =>
    inject(AdministratorGuard).canActivate(
        inject(AdministratorService),
        inject(Router),
        inject(NavigationService),
        inject(SystemApiService)
    );
