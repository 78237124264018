import { Highlight } from '@wdx/shared/utils';
import { EngagementState } from '../models/engagement-state.model';

export const ENGAGEMENT_STATE_HIGHLIGHT_MAP = {
    [EngagementState.Started]: Highlight.Success,
    [EngagementState.Ended]: Highlight.Danger,
};

export const ENGAGEMENT_STATE_STATUS_MAP = {
    [EngagementState.Started]: 'Live',
    [EngagementState.Ended]: 'Ended',
};
