import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'clmi-filter-table-lifecycle-stage-template',
    templateUrl: './lifecycle-stage.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTableLifecycleStageTemplateComponent {
    @Input() data: any;
}
