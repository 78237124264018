<div class="d-flex flex-column h-100">
    <molecule-filter-bar
        *ngIf="showFilterBar"
        class="d-block mb-1 flex-shrink-0"
        [filtersModalId]="filtersModalId"
        [sortMenu]="sortMenu"
        [useSortToggleMenu]="useSortToggleMenu"
        [showFilters]="showFilters"
        [showFavourite]="showFavourite"
        [filterHandler]="filterHandler"
        [favouriteFilterToggle]="favouriteFilterToggle"
    >
        <div class="action-buttons">
            <ng-content select=".data-list-filter-bar-buttons"></ng-content>
        </div>
    </molecule-filter-bar>
    <div class="widget widget-body flex-grow-1 overflow-hidden">
        <div class="h-100 d-flex flex-column overflow-hidden min-h-0">
            <div *ngIf="showSearchBar">
                <molecule-search-and-filter-bar
                    class="data-list-wrapper__search-and-filter-bar flex-grow-1"
                    [searchType]="searchType"
                    [searchTypePlural]="searchTypePlural"
                    [placeholder]="searchPlaceholder"
                    [isLoading]="isLoading"
                    [totalRecordCount]="totalRecordCount"
                    [filterHandler]="filterHandler"
                    [hasFilterSearch]="hasFilterSearch"
                    [hasMobileFilter]="hasMobileFilter"
                    [inputSearchText]="filterHandler?.compiledPayload?.search"
                    (search)="onSearch($event)"
                >
                    <ng-content
                        prefixActionButtons
                        select="[prefixActionButtons]"
                    ></ng-content>
                    <ng-container actionButtons>
                        <ng-content
                            select=".data-list-action-button"
                        ></ng-content>
                    </ng-container>
                </molecule-search-and-filter-bar>
                <hr
                    class="data-list-wrapper__search-and-filter-bar-separator mt-0"
                />
            </div>

            <ng-content select=".data-list-header-content"></ng-content>

            <div
                class="d-block flex-grow-1 overflow-auto"
                clmiInfiniteScroll
                (scrolled)="onInfinityScrollFired()"
                [attr.data-cy]="cySelector"
            >
                <atom-async-data
                    [isLoading]="isLoading"
                    [isForInfinityScroll]="true"
                >
                    <div class="d-block" [class.h-100]="!isLoading">
                        <ng-content select=".data-list-content"></ng-content>
                    </div>
                </atom-async-data>
            </div>
        </div>
    </div>
</div>
