import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { filter, takeUntil } from 'rxjs/operators';
import { AtomTabComponent } from '../../../../components/atoms/atom-tab/atom-tab.component';
import { TabIndexActivated } from '../../../../models/tab.model';
import { TabService } from '../../../../shared/services/tab';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-tabs-dumb',
    templateUrl: './molecule-tabs-dumb.component.html',
})
export class MoleculeTabsDumbComponent
    extends WdxDestroyClass
    implements OnInit, OnChanges
{
    @Input() activeTabIndex = 0;
    @Input() tabs: QueryList<AtomTabComponent>;
    @Input() hasBottomBorder: boolean;

    @Output() activateTab = new EventEmitter<TabIndexActivated>();

    tabContentTemplate: TemplateRef<any>;

    constructor(private tabService: TabService) {
        super();
    }

    ngOnInit(): void {
        this.onActivate(this.activeTabIndex || 0);

        this.tabs.changes.subscribe(() => {
            this.onActivate(this.activeTabIndex || 0);
        });

        this.tabService.tabIndex$
            .pipe(
                takeUntil(this.destroyed$),
                filter((index) => Boolean(typeof index === 'number'))
            )
            .subscribe((index: number) => {
                this.onActivate(index);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeTabIndex !== undefined) {
            this.setTabPane(this.activeTabIndex);
        }
    }

    onActivate(index: number, clickEvent = false) {
        this.activeTabIndex = index;
        this.activateTab.emit({
            index: index,
            clickEvent,
        });
        this.setTabPane(index);
    }

    setTabPane(index: number) {
        this.activeTabIndex = index;
        const activeTab = this.tabs.toArray()[this.activeTabIndex];
        this.tabContentTemplate = activeTab?.content;
    }
}
