import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class EmailService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getEmailContentForActivity(activityId: string): Observable<string> {
        return this.http.get(
            `${
                this.config.getConfiguration().API_BASE
            }/email/${activityId}/content`,
            {
                responseType: 'text',
            }
        );
    }
}
