import { Injectable } from '@angular/core';
import { ExtendedFieldDefinitionOption } from '../../../../../../../models/extended-field-definition-option.model';
import { ReactiveFormDynamicDataService } from '../../../../../../../shared/services/dynamic-data/dynamic-data.service';
import { FilterFieldDefinition } from '@wdx/clmi/api-models';
import { Observable, take } from 'rxjs';
import { FilterDataItem } from '../../../interfaces';
import { IFilterDataProvider } from '../../../interfaces/filter-data-provider.interface';

@Injectable()
export class FilterDynamicDataService implements IFilterDataProvider {
    constructor(private dynamicDataService: ReactiveFormDynamicDataService) {}
    getData$(
        definition: FilterFieldDefinition
    ): Observable<FilterDataItem[] | ExtendedFieldDefinitionOption[]> {
        return this.dynamicDataService
            .getSelectApiSourceOptions(definition.selectSource)
            .pipe(take(1));
    }
}
