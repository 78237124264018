import { KeyValueObject } from '@wdx/shared/utils';

export enum FormFrameworkEventType {
    Dirty,
    SaveStart,
    SaveFailed,
    SaveClicked,
    SaveSuccess,
    FormLoaded,
    FormValueChanged,
}

export interface FormFrameworkEvent {
    type: FormFrameworkEventType;
    action?: { dirty?: boolean; formValue?: KeyValueObject; valid?: boolean };
}

export class FormFrameworkFormValueChangedEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.FormValueChanged;
    action: { dirty: boolean; formValue: KeyValueObject; valid: boolean };

    constructor(dirty: boolean, valid: boolean, formValue: KeyValueObject) {
        this.action = { dirty, formValue, valid };
    }
}

export class FormFrameworkSaveStartEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.SaveStart;
}

export class FormFrameworkSaveFailedEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.SaveFailed;
}

export class FormFrameworkSaveSuccessEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.SaveSuccess;
}

export class FormFrameworkLoadedEvent implements FormFrameworkEvent {
    type = FormFrameworkEventType.FormLoaded;
}
