import { Action, createReducer, on } from '@ngrx/store';
import {
    CrudState,
    CrudStateObject,
    FormDataResult,
    FormDefinition,
    FormFunctionResult,
    FormTemplate,
    FormTemplateInstance,
    GLOBAL_STATE_INDEX_ID,
} from '@wdx/shared/utils';
import * as dynamicFormsActions from './dynamic-forms.actions';

export const FEATURE_KEY = 'dynamicForms';

export interface State {
    formDefinitions?: CrudStateObject<FormDefinition>;
    formData?: CrudStateObject<FormDataResult>;
    formFunctionResults?: CrudStateObject<FormFunctionResult>;
    formTemplates?: CrudStateObject<FormTemplate>;
    formTemplateInstances?: CrudStateObject<FormTemplateInstance>;
}

export const initialState: State = {
    formDefinitions: {},
    formData: {},
    formFunctionResults: {},
    formTemplates: {},
    formTemplateInstances: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        dynamicFormsActions.getFormDefinitions,
        (state): State => ({
            ...state,
            formDefinitions: {
                ...state.formDefinitions,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.formDefinitions?.[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<FormDefinition>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormDefinitionsSuccess,
        (state, props): State => ({
            ...state,
            formDefinitions: {
                ...state.formDefinitions,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.formDefinitions?.[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<FormDefinition>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.formDefinitions,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormDefinitionsFailure,
        (state): State => ({
            ...state,
            formDefinitions: {
                ...state.formDefinitions,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.formDefinitions?.[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<FormDefinition>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.resetFormDefinitions,
        (state): State => ({
            ...state,
            formDefinitions: {},
        })
    ),

    on(
        dynamicFormsActions.getFormDefinition,
        (state, props): State => ({
            ...state,
            formDefinitions: {
                ...state.formDefinitions,
                [props.formId]: {
                    ...(state.formDefinitions?.[props.formId] ||
                        ({} as CrudState<FormDefinition>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormDefinitionSuccess,
        (state, props): State => ({
            ...state,
            formDefinitions: {
                ...state.formDefinitions,
                [props.formId]: {
                    ...(state.formDefinitions?.[props.formId] ||
                        ({} as CrudState<FormDefinition>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.formDefinition,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormDefinitionFailure,
        (state, props): State => ({
            ...state,
            formDefinitions: {
                ...state.formDefinitions,
                [props.formId]: {
                    ...(state.formDefinitions?.[props.formId] ||
                        ({} as CrudState<FormDefinition>)),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.resetFormData,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {} as CrudState<FormDataResult>,
            },
            ...(props.formTemplateEntityId
                ? {
                      formTemplateInstances: {
                          ...state.formTemplateInstances,
                          [props.formTemplateEntityId]:
                              {} as CrudState<FormTemplateInstance>,
                      },
                  }
                : {
                      formTemplateInstances: { ...state.formTemplateInstances },
                  }),
        })
    ),

    on(
        dynamicFormsActions.getFormData,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    [props.entityId]: {
                        isLoadingSingle: true,
                        hasLoadingSingleError: false,
                    },
                },
            },
        })
    ),

    on(
        dynamicFormsActions.setFormData,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    [props.entityId]: {
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                        single: props.formData,
                    },
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormDataSuccess,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    [props.entityId]: {
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                        single: props.formData,
                    },
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormDataFailure,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    [props.entityId]: {
                        isLoadingSingle: false,
                        hasLoadingSingleError: true,
                    },
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormInitialisationData,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormInitialisationDataSuccess,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.formData,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormInitialisationDataFailure,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormFunctionResult,
        (state, props): State => ({
            ...state,
            formFunctionResults: {
                ...state.formFunctionResults,
                [props.formId]: {
                    ...(state.formFunctionResults?.[props.formId] || {}),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormFunctionResultSuccess,
        (state, props): State => ({
            ...state,
            formFunctionResults: {
                ...state.formFunctionResults,
                [props.formId]: {
                    ...(state.formFunctionResults?.[props.formId] || {}),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.formFunctionResult,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormFunctionResultFailure,
        (state, props): State => ({
            ...state,
            formFunctionResults: {
                ...state.formFunctionResults,
                [props.formId]: {
                    ...(state.formFunctionResults?.[props.formId] || {}),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormTemplates,
        (state, props): State => ({
            ...state,
            formTemplates: {
                ...state.formTemplates,
                [props.entityId]: {
                    ...(state.formTemplates?.[props.entityId] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormTemplatesSuccess,
        (state, props): State => ({
            ...state,
            formTemplates: {
                ...state.formTemplates,
                [props.entityId]: {
                    ...(state.formTemplates?.[props.entityId] || {}),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.formTemplates,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormTemplatesFailure,
        (state, props): State => ({
            ...state,
            formTemplates: {
                ...state.formTemplates,
                [props.entityId]: {
                    ...(state.formTemplates?.[props.entityId] || {}),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormTemplateInstance,
        (state, props): State => ({
            ...state,
            formTemplateInstances: {
                ...state.formTemplateInstances,
                [props.entityId]: {
                    ...(state.formTemplateInstances?.[props.entityId] || {}),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormTemplateInstanceSuccess,
        (state, props): State => ({
            ...state,
            formTemplateInstances: {
                ...state.formTemplateInstances,
                [props.entityId]: {
                    ...(state.formTemplateInstances?.[props.entityId] || {}),
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.formTemplateInstance,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.getFormTemplateInstanceFailure,
        (state, props): State => ({
            ...state,
            formTemplateInstances: {
                ...state.formTemplateInstances,
                [props.entityId]: {
                    ...(state.formTemplateInstances?.[props.entityId] || {}),
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.createNonStandardFormData,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isCreating: true,
                    hasCreatingError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.createNonStandardFormDataSuccess,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isCreating: false,
                    hasCreatingError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.createNonStandardFormDataFailure,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isCreating: false,
                    hasCreatingError: true,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.updateNonStandardFormData,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.updateNonStandardFormDataSuccess,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.updateNonStandardFormDataFailure,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.updateStandardFormData,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.updateStandardFormDataSuccess,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.updateStandardFormDataFailure,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.putCustomEndpointFormData,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.putCustomEndpointFormDataSuccess,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        dynamicFormsActions.putCustomEndpointFormDataFailure,
        (state, props): State => ({
            ...state,
            formData: {
                ...state.formData,
                [props.formId]: {
                    ...(state.formData?.[props.formId] || {}),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action): State {
    return reducerSetup(state, action);
}
