import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as auditsActions from './audits.actions';
import { AuditsService } from './audits.service';

@Injectable()
export class AuditsEffects {
    private actions$ = inject(Actions);
    private auditsService = inject(AuditsService);

    getAudits$ = createEffect(() =>
        this.actions$.pipe(
            ofType(auditsActions.getAudits),
            switchMap((action) =>
                this.auditsService
                    .getAudits(action.recordType, action.recordId)
                    .pipe(
                        map((audits) =>
                            auditsActions.getAuditsSuccess({
                                recordType: action.recordType,
                                recordId: action.recordId,
                                audits,
                            })
                        ),
                        catchError((error) =>
                            of(
                                auditsActions.getAuditsFailure({
                                    recordType: action.recordType,
                                    recordId: action.recordId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
