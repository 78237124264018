<ng-container [ngSwitch]="type">
    <div
        *ngSwitchCase="'spinner'"
        class="spinner-container"
        [class.spinner-container--overlay]="overlay"
        [style.minHeight]="MIN_HEIGHT"
    >
        <div class="absolute-center d-flex flex-column align-items-center">
            <wdx-spinner></wdx-spinner>
            <h6 *ngIf="hasError" class="mt-1">
                {{ SOMETHING_WENT_WRONG | translate }}
            </h6>
        </div>
    </div>
    <span *ngSwitchCase="'dot'" class="dot-loader"></span>
</ng-container>
