import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    Article,
    EntityPermissionType,
    SystemEntity,
} from '@wdx/clmi/api-models';

import { FeatureSvg } from '../../../../models/svg.model';

import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { TRANSLATION_NO_RECENT_RECORDS } from '@wdx/shared/utils';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { AccordionPanel } from '../../../../models/accordion-panel.model';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { ListStyle } from '../../../../models/list.model';
import { SystemEntityActionProps } from '../../../../models/system-entity-action-props.model';
import * as favouritesActions from '../../../../state/favourites/favourites.actions';
import * as relatedRecordsActions from '../../../../state/related-records/related-records.actions';
import { RelatedRecordsFacadeService } from '../related-records-facade.service';

@Component({
    selector: 'clmi-related-articles',
    templateUrl: './related-articles.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedArticlesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() entityId: string;
    @Input() entityType: SystemEntity;
    @Input() cardsPerRow: number;
    @Input() isInactiveRecord: boolean;

    @ViewChild('suggestedArticlesContentTemplate')
    suggestedArticlesContentTemplate: TemplateRef<any>;

    relatedArticles$: Observable<Article[]>;
    relatedArticlesIsLoading$: Observable<boolean>;
    relatedArticlesHasError$: Observable<boolean>;

    suggestedArticles$: Observable<Article[]>;
    suggestedArticlesIsLoading$: Observable<boolean>;
    suggestedArticlesHasError$: Observable<boolean>;
    suggestedArticlesAccordionPanels$: Observable<AccordionPanel[]>;

    destroyed$ = new Subject<boolean>();

    readonly LIST_STYLE = ListStyle;
    readonly SEARCH_ARTICLES_MODAL_ID = 'search-articles-modal';
    readonly NO_DATA_SVG = FeatureSvg.NoData;
    readonly NO_DATA_MESSAGE = TRANSLATION_NO_RECENT_RECORDS;

    articlesSearchActionButton: ActionButton = {
        mode: ActionButtonMode.IconButton,
        cySelector: 'action-find-articles',
        icon: 'search',
        id: 'search-articles',
        modalId: this.SEARCH_ARTICLES_MODAL_ID,
    };

    constructor(
        private relatedRecordsFacadeService: RelatedRecordsFacadeService,
        private actionsSubject$: ActionsSubject
    ) {}

    ngOnInit(): void {
        if (!this.isInactiveRecord) {
            this.articlesSearchActionButton.permission =
                EntityPermissionType.Edit;
        }

        this.actionsSubject$
            .pipe(
                ofType(
                    relatedRecordsActions.createLinkToArticleForEntitySuccess,
                    relatedRecordsActions.deleteLinkToArticleForEntitySuccess
                ),
                takeUntil(this.destroyed$)
            )
            .subscribe(() => {
                const props = {
                    entityId: this.entityId,
                    entityType: this.entityType,
                };

                this.relatedRecordsFacadeService.loadRelatedArticles(props);
                this.relatedRecordsFacadeService.loadSuggestedArticles(props);
            });

        this.actionsSubject$
            .pipe(
                ofType(
                    favouritesActions.favouriteSuccess,
                    favouritesActions.unfavouriteSuccess
                ),
                takeUntil(this.destroyed$)
            )
            .subscribe((action: any) => {
                const props = {
                    entityId: this.entityId,
                    entityType: this.entityType,
                };

                if (action.entity === SystemEntity.Article) {
                    this.relatedRecordsFacadeService.loadRelatedArticles(props);
                    this.relatedRecordsFacadeService.loadSuggestedArticles(
                        props
                    );
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const entityId = this.entityId ?? changes.entityId.currentValue;
        const entityType = this.entityType ?? changes.entityType.currentValue;

        if (entityId && entityType) {
            const props = {
                entityId,
                entityType,
            };

            this.setRelatedArticles(props);
            this.setSuggestedArticles(props);
        }
    }

    setRelatedArticles(props: SystemEntityActionProps): void {
        this.relatedArticles$ =
            this.relatedRecordsFacadeService.getRelatedArticles$(props);

        this.relatedArticlesIsLoading$ =
            this.relatedRecordsFacadeService.getRelatedArticlesIsLoading$(
                props
            );

        this.relatedArticlesHasError$ =
            this.relatedRecordsFacadeService.getRelatedArticlesHasError$(props);
    }

    setSuggestedArticles(props: SystemEntityActionProps): void {
        this.suggestedArticles$ =
            this.relatedRecordsFacadeService.getSuggestedArticles$(props);

        this.suggestedArticlesIsLoading$ =
            this.relatedRecordsFacadeService.getSuggestedArticlesIsLoading$(
                props
            );

        this.suggestedArticlesHasError$ =
            this.relatedRecordsFacadeService.getSuggestedArticlesHasError$(
                props
            );

        this.suggestedArticlesAccordionPanels$ = this.suggestedArticles$.pipe(
            debounceTime(0),
            map((suggestedArticles) => {
                return [
                    {
                        label: `Suggested Articles (${
                            suggestedArticles?.length || 0
                        })`,
                        template: this.suggestedArticlesContentTemplate,
                        guidanceText: `Shows articles viewed or used inside the last 3 days (that are not already linked).
                        Click the 'chain' icon to link this engagement to the article.`,
                    },
                ];
            })
        );
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
