import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomHighlightTextModule } from '../../atoms/atom-highlight-text/atom-highlight-text.module';
import { MoleculeActionButtonModule } from '../molecule-action-button/molecule-action-button.module';
import { MoleculeAvatarGroupModule } from '../molecule-avatar-group/molecule-avatar-group.module';
import { MoleculeCardFooterModule } from '../molecule-card-footer/molecule-card-footer.module';
import { MoleculeInfoCardModule } from '../molecule-info-card/molecule-info-card.module';
import { MoleculeActivityCardComponent } from './molecule-activity-card.component';
import { DisplayDatePipe } from '@wdx/shared/utils';

@NgModule({
    imports: [
        AtomHighlightTextModule,
        CommonModule,
        MoleculeActionButtonModule,
        MoleculeAvatarGroupModule,
        MoleculeCardFooterModule,
        MoleculeInfoCardModule,
        PipesModule,
        WdxIconModule,
        DisplayDatePipe,
    ],
    declarations: [MoleculeActivityCardComponent],
    exports: [MoleculeActivityCardComponent],
})
export class MoleculeActivityCardModule {}
