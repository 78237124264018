<div
    class="modal-header d-flex align-items-center"
    [ngClass]="headerClass"
    [attr.data-cy]="'modal-' + modalTitle"
    [title]="modalTitle"
>
    <ng-content select=".header"></ng-content>
    <h5 class="modal-title text-truncate">{{ modalTitle }}</h5>
    <button
        *ngIf="!hideClose"
        type="button"
        class="close"
        aria-label="Close"
        data-cy="btn-close"
        (click)="onClose()"
    >
        <i class="fal fa-times"></i>
    </button>
</div>
<div
    class="modal-body"
    [ngClass]="bodyClass"
    [attr.data-cy]="'modal-content-' + modalTitle"
>
    <ng-content></ng-content>
</div>
