import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormDataHistory } from '@wdx/clmi/api-models';
import { CrudStateObject } from '@wdx/clmi/api-services/models';
import * as teamReducer from '../../state/team/team.reducer';
import { teamFeature } from './team.reducer';

export const featureKey = 'team';

export const selectTeam = createFeatureSelector(featureKey);

/**
 * Reducer state selector
 */
export const getState = (state: {
    team: teamReducer.State;
}): teamReducer.State => state.team;

export const getForId = (id: string) =>
    createSelector(
        selectTeam,
        (state: teamReducer.State) => state.team[id]?.single
    );
export const getForIdIsLoading = (id: string) =>
    createSelector(
        selectTeam,
        (state: teamReducer.State) => state.team[id]?.isLoadingSingle
    );
export const getForIdHasLoadingError = (id: string) =>
    createSelector(
        selectTeam,
        (state: teamReducer.State) => state.team[id]?.hasLoadingSingleError
    );

export const getTeamMembersForId = createSelector(
    getState,
    (state: teamReducer.State, props: any) => state.team[props.id]?.list
);
export const getTeamMembersForIdIsLoading = createSelector(
    getState,
    (state: teamReducer.State, props: any) =>
        state.team[props.id]?.isLoadingList
);
export const getTeamMembersForIdHasLoadingError = createSelector(
    getState,
    (state: teamReducer.State, props: any) =>
        state.team[props.id]?.hasLoadingListError
);

export const getHistoryForIdIsLoading = (id: string) =>
    createSelector(
        teamFeature.selectHistory,
        (history: CrudStateObject<FormDataHistory>) =>
            history[id]?.isLoadingList
    );
export const getHistoryForIdHasLoadingError = (id: string) =>
    createSelector(
        teamFeature.selectHistory,
        (history: CrudStateObject<FormDataHistory>) =>
            history[id]?.hasLoadingListError
    );
export const getHistoryForId = (id: string) =>
    createSelector(
        teamFeature.selectHistory,
        (history: CrudStateObject<FormDataHistory>) => history[id]?.list
    );

/**
 * Reducer property selectors
 */
