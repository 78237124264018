<organism-engagement-bar-dumb
    [isVisible]="isVisible$ | ngrxPush"
    [activityId]="activityId$ | ngrxPush"
    [party]="party$ | ngrxPush"
    [engagementState]="engagementState$ | ngrxPush"
    [activity]="activity$ | ngrxPush"
    [workItem]="workItem$ | ngrxPush"
    [documentTypes]="documentTypes$ | ngrxPush"
    [relatedActivitiesCount]="relatedActivitiesCount$ | ngrxPush"
    [relatedArticlesCount]="relatedArticlesCount$ | ngrxPush"
    [relatedCasesCount]="relatedCasesCount$ | ngrxPush"
    [relatedOpportunitiesCount]="relatedOpportunitiesCount$ | ngrxPush"
    (completeClicked)="onCompleteClicked()"
    (closeClick)="onCloseClick()"
    (assignTo)="onAssignTo($event)"
    (outcomesClicked)="onOutcomesClicked($event)"
></organism-engagement-bar-dumb>
