import { Component, Input } from '@angular/core';
import { CommentStatus } from '../../../models/comment-status.model';
import { IconStyle } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-comment-status',
    templateUrl: './atom-comment-status.component.html',
})
export class AtomCommentStatusComponent {
    @Input() commentStatus: CommentStatus;
    @Input() longFormat: boolean;

    readonly ICON_STYLE = IconStyle;
}
