import { Pipe, PipeTransform } from '@angular/core';

import { WdxDateOptions } from './../../services/date-time/date-time.model';
import { WdxDateTimeService } from './../../services/date-time/date-time.service';

/**
 * This pipe uses the WdxDateTimeService to return a human readable wdx date style eg, '16 Feb 2022'
 *
 */
@Pipe({
    name: 'dateTime',
    standalone: true,
})
export class WdxDateTimePipe implements PipeTransform {
    constructor(private wdxTimeService: WdxDateTimeService) {}

    /**
     * Use this pipe in you views process the dates to the needed locale and format.
     *
     * @param value - This needs to be a date isoString eg. '2022-02-16T00:00:00Z'
     * @param options - WdxDateOptions
     *
     * @returns string
     */
    transform(
        value: string | Date | undefined,
        options?: WdxDateOptions
    ): string {
        if (!value) {
            return '';
        }

        return this.wdxTimeService.convertDateToViewFriendlyFormat(
            value,
            options
        );
    }
}
