import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../../directives/directives.module';
import { AtomInputNoneSelectedComponent } from './atom-input-none-selected.component';

@NgModule({
    imports: [CommonModule, DirectivesModule],
    declarations: [AtomInputNoneSelectedComponent],
    exports: [AtomInputNoneSelectedComponent],
})
export class AtomInputNoneSelectedModule {}
