import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import * as formHistoryInstanceActions from './form-history-instance.actions';
import { FormHistoryInstanceService } from './form-history-instance.service';

@Injectable()
export class FormHistoryInstanceEffects {
    private actions$ = inject(Actions);
    private formHistoryService = inject(FormHistoryInstanceService);

    getHistoryInstance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formHistoryInstanceActions.loadFormHistoryInstance),
            mergeMap(({ formId, entityId, instanceId }) =>
                this.formHistoryService
                    .getHistoryInstance(formId, entityId, instanceId)
                    .pipe(
                        map((formHistoryInstance) =>
                            formHistoryInstanceActions.loadFormHistoryInstanceSuccess(
                                {
                                    formId,
                                    entityId,
                                    instanceId,
                                    formHistoryInstance,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                formHistoryInstanceActions.loadFormHistoryInstanceFailure(
                                    {
                                        formId,
                                        entityId,
                                        instanceId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
