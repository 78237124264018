import { DataChangeActionType } from '@wdx/shared/utils';
import { IFormPendingChangeSubForm } from '../../components/pending-changes/pending-changes.component.interface';
import { PENDING_CHANGE_TEXT } from './form-pending-changes.interface';

export const GET_STATUS_PENDING_CHANGES = {
    [DataChangeActionType.Add]: `Add ${PENDING_CHANGE_TEXT}`,
    [DataChangeActionType.Remove]: `Delete ${PENDING_CHANGE_TEXT}`,
    [DataChangeActionType.Update]: `Update ${PENDING_CHANGE_TEXT}`,
};

export const PENDING_CHANGE_REG = new RegExp(/\[[0-9]+\]/);

export const PENDING_CHANGE_SPLIT = (field: string): string[] => {
    return field.replace('[', '.').replace(']', '').split('.');
};

export const UPDATE_PENDING_CHANGE_COUNTER = (
    pendingChangesSubFormCounter: IFormPendingChangeSubForm,
    name: string,
    type: DataChangeActionType,
    label?: string,
    index?: number
): IFormPendingChangeSubForm => {
    const PENDING_CHANGE_SUB_FORM = { ...pendingChangesSubFormCounter };

    if (!PENDING_CHANGE_SUB_FORM[name]) {
        PENDING_CHANGE_SUB_FORM[name] = {
            index: [],
            updating: 0,
            adding: 0,
            deleting: 0,
            label: '',
        };
    }

    const DATA = PENDING_CHANGE_SUB_FORM[name];
    if (!DATA.index?.includes(index as number)) {
        if (typeof index === 'number') {
            DATA?.index?.push(index as number);
        }

        if (DATA && label) {
            DATA['label'] = label;
        }

        if (DataChangeActionType.Update === type) {
            const VAL = DATA['updating'];
            DATA['updating'] = (VAL as number) + 1;
        }

        if (DataChangeActionType.Add === type) {
            const VAL = DATA['adding'];
            DATA['adding'] = (VAL as number) + 1;
        }

        if (DataChangeActionType.Remove === type) {
            const VAL = DATA['deleting'];
            DATA['deleting'] = (VAL as number) + 1;
        }
    }

    return PENDING_CHANGE_SUB_FORM;
};
