import { createAction, props } from '@ngrx/store';
import {
    IdentityCheck,
    IdentityCheckReview,
    KeyStatResult,
    ReviewStatusType,
} from '@wdx/clmi/api-models';

export const getKeystats = createAction(
    '[Identity Verification - Main Checks] Get Keystats',
    props<{
        identityCheckId: string;
    }>()
);

export const getKeystatsSuccess = createAction(
    '[Identity Verification - Main Checks] Get Keystats Success',
    props<{
        keystats: KeyStatResult[];
        identityCheckId: string;
    }>()
);

export const getKeystatsError = createAction(
    '[Identity Verification - Main Checks] Get Keystats Error',
    props<{
        error: Error;
        identityCheckId: string;
    }>()
);

export const getIdVerificationCheckDetail = createAction(
    '[Identity Verification - Main Checks] Get Id Verification Check Detail',
    props<{
        idVerificationId: string;
    }>()
);

export const getIdVerificationCheckDetailSuccess = createAction(
    '[Identity Verification - Main Checks] Get Id Verification Check Detail Success',
    props<{
        idVerificationCheckDetail: IdentityCheck;
        idVerificationId: string;
    }>()
);

export const getIdVerificationCheckDetailError = createAction(
    '[Identity Verification - Main Checks] Get Id Verification Check Detail Error',
    props<{
        error: Error;
        idVerificationId: string;
    }>()
);

export const getCheckDetail = createAction(
    '[Identity Verification - Main Checks] Get Check Detail',
    props<{
        partyId: string;
    }>()
);

export const getCheckDetailSuccess = createAction(
    '[Identity Verification - Main Checks] Get Check Detail Success',
    props<{
        checkDetail: IdentityCheck;
        partyId: string;
    }>()
);

export const getCheckDetailError = createAction(
    '[Identity Verification - Main Checks] Get Check Detail Error',
    props<{
        error: Error;
        partyId: string;
    }>()
);

export const getCheckReviews = createAction(
    '[Identity Verification - ID&V OCR comparison] Get Check Review',
    props<{
        id: string;
    }>()
);

export const getCheckReviewsSuccess = createAction(
    '[Identity Verification - ID&V OCR comparison] Get Check Review Success',
    props<{
        checkReviews: IdentityCheckReview[];
        id: string;
    }>()
);

export const getCheckReviewsError = createAction(
    '[Identity Verification - ID&V OCR comparison] Get Check Review Error',
    props<{
        error: Error;
        id: string;
    }>()
);

export const getCheckReviewsAdditional = createAction(
    '[Identity Verification - ID&V OCR comparison] Get Check Review Additional',
    props<{
        identityCheckId: string;
    }>()
);

export const getCheckReviewsAdditionalSuccess = createAction(
    '[Identity Verification - ID&V OCR comparison] Get Check Review Additional Success',
    props<{
        checkReviewsAdditional: IdentityCheckReview[];
        identityCheckId: string;
    }>()
);

export const getCheckReviewsAdditionalError = createAction(
    '[Identity Verification - ID&V OCR comparison] Get Check Review Additional Error',
    props<{
        error: Error;
        identityCheckId: string;
    }>()
);

export const refreshHeader = createAction(
    '[Identity Verification - Header] Refresh',
    props<{
        identityCheckId: string;
    }>()
);

export const refreshHeaderSuccess = createAction(
    '[Identity Verification - Header] Refresh Success',
    props<{
        isSuccess: boolean;
    }>()
);

export const refreshHeaderError = createAction(
    '[Identity Verification - Header] Refresh Error',
    props<{
        error: Error;
    }>()
);

export const getCheckedHistory = createAction(
    '[Identity Verification - Checked History] Get Check History List',
    props<{
        partyId: string;
    }>()
);

export const getCheckedHistorySuccess = createAction(
    '[Identity Verification - Checked History] Get Check History List Success',
    props<{
        checkedHistory: IdentityCheck[];
        partyId: string;
    }>()
);

export const getCheckedHistoryError = createAction(
    '[Identity Verification - Checked History] Get Check History List Error',
    props<{
        error: Error;
        partyId: string;
    }>()
);

export const updateReviewStatus = createAction(
    '[Identity Verification - Identity Checked Review Update] Update the check review status',
    props<{
        identityCheckId: string;
        identityCheckReviewId: string;
    }>()
);

export const updateReviewStatusSuccess = createAction(
    '[Identity Verification - Identity Checked Review Update] Update the check review status success',
    props<{
        identityCheckId: string;
        identityCheckReviewId: string;
        reviewStatus: ReviewStatusType;
    }>()
);
