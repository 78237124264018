<atom-modal-wrapper
    size="lg"
    [modalId]="modalId"
    [modalTemplate]="commentsModal"
>
    <ng-template #commentsModal>
        <organism-modal [modalTitle]="modalTitle">
            <clmi-comments
                class="d-block h-100 modal-padding"
                [entity]="{ id: entityId, type: entityType }"
                [invertedStyling]="false"
            ></clmi-comments>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
