import { createAction, props } from '@ngrx/store';
import { PostalAddress, PostalAddressLookup } from '@wdx/shared/utils';

export const getAddresses = createAction(
    '[Addresses] Get Addresses',
    props<{
        searchText: string;
        fieldId: string;
    }>()
);

export const getAddressesSuccess = createAction(
    '[Addresses] Get Addresses Success',
    props<{
        addresses: PostalAddressLookup[];
        fieldId: string;
    }>()
);

export const getAddressesFailure = createAction(
    '[Addresses] Get Addresses Failure',
    props<{
        fieldId: string;
        error: Error;
    }>()
);

export const getAddress = createAction(
    '[Addresses] Get Address',
    props<{
        addressId: string;
        fieldId: string;
    }>()
);

export const getAddressSuccess = createAction(
    '[Addresses] Get Address Success',
    props<{
        address: PostalAddress;
        fieldId: string;
    }>()
);

export const getAddressFailure = createAction(
    '[Addresses] Get Address Failure',
    props<{
        addressId: string;
        fieldId: string;
        error: Error;
    }>()
);

export const reset = createAction(
    '[Addresses] Reset',
    props<{
        fieldId: string;
    }>()
);
