import { stringHighlightHelper } from '../highlight/highlight.helper';

export const SEARCH_STRING = <T>(
    dataToSearch: T[],
    propertyToMatchKeyWord: string,
    propertyToUpdateValue: string,
    searchTerm: string
): T[] => {
    const MATCHED_ITEMS: T[] = [];

    if (!Array.isArray(dataToSearch)) {
        return MATCHED_ITEMS;
    }

    if (!propertyToMatchKeyWord || !propertyToUpdateValue || !searchTerm) {
        return dataToSearch;
    }

    dataToSearch?.filter((item) => {
        const HAS_MATCH = item[propertyToMatchKeyWord]
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase());

        if (HAS_MATCH) {
            item[propertyToUpdateValue] = stringHighlightHelper(
                item[propertyToMatchKeyWord],
                searchTerm
            );

            MATCHED_ITEMS.push(item);
        }
    });

    return MATCHED_ITEMS;
};

export const FILTER_ARRAY_BY_SEARCH_TERM = <T>(
    dataToSearch: T[],
    valueSelectorFunc: (T) => string,
    searchTerm: string
): T[] => {
    const MATCHED_ITEMS: T[] = [];

    if (!Array.isArray(dataToSearch)) {
        return MATCHED_ITEMS;
    }

    if (!valueSelectorFunc || !searchTerm) {
        return dataToSearch;
    }

    return dataToSearch?.filter((item) =>
        valueSelectorFunc(item)
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase())
    );
};
