import { Directive, HostListener, Input } from '@angular/core';
import { ModalManagementService } from '../services/modal-management.service';

@Directive({
    selector: '[modalId]',
})
export class ModalDirective {
    @Input() modalId: string;

    @HostListener('click') onClick() {
        this.open();
    }

    constructor(private modalManagementService: ModalManagementService) {}

    open(): void {
        if (this.modalId) {
            this.modalManagementService.openModalWithId(this.modalId);
        }
    }
}
