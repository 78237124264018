import { Action, createReducer, on } from '@ngrx/store';
import {
    List,
    ListMember,
    Party,
    PartyRole,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as listsActions from './lists.actions';

export interface State {
    lists?: CrudState<List>;
    members?: CrudStateObject<ListMember>;
    parties?: CrudStateObject<Party>;
    partyRoles?: CrudStateObject<PartyRole>;
}

export const initialState: State = {
    lists: {},
    members: {},
    parties: {},
    partyRoles: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        listsActions.addList,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        listsActions.addListSuccess,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: false,
                list: state.lists.list
                    ? state.lists.list.concat([props.list])
                    : [props.list],
            },
        })
    ),

    on(
        listsActions.addListFailure,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        listsActions.addPartiesToList,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isUpdating: true,
                hasUpdatingError: false,
            },
            parties: {
                ...(state.parties || ({} as CrudStateObject<Party>)),
                [props.list.id]: {
                    ...(state.parties[props.list.id] ||
                        ({} as CrudState<Party>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        listsActions.addPartiesToListSuccess,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isUpdating: false,
                hasUpdatingError: false,
                list: state.lists.list
                    ? (() => {
                          const propsList = { ...props.list };
                          const lists = [...state.lists.list];
                          const listIndex = lists.findIndex(
                              (list) => list.id === propsList.id
                          );
                          propsList.numberOfMembers += props.parties.length;
                          if (listIndex < 0) {
                              lists.push(propsList);
                          } else {
                              lists[listIndex] = { ...propsList };
                          }
                          return lists;
                      })()
                    : [
                          {
                              ...props.list,
                              numberOfMembers: props.list.numberOfMembers
                                  ? (props.list.numberOfMembers +=
                                        props.parties.length)
                                  : 1,
                          },
                      ],
            },
            parties: {
                ...(state.parties || ({} as CrudStateObject<Party>)),
                [props.list.id]: {
                    ...(state.parties[props.list.id] ||
                        ({} as CrudState<Party>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                    list: [
                        ...(() => {
                            const membersForListId = [
                                ...(state.parties[props.list.id]?.list || []),
                            ];
                            props.parties.forEach((party) => {
                                const listIndex = membersForListId.findIndex(
                                    (member) => member.id === party.id
                                );
                                if (listIndex < 0) {
                                    membersForListId.push(party);
                                } else {
                                    membersForListId[listIndex] = { ...party };
                                }
                            });
                            return membersForListId;
                        })(),
                    ],
                },
            },
        })
    ),

    on(
        listsActions.addPartiesToListFailure,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isUpdating: false,
                hasUpdatingError: true,
            },
            parties: {
                ...(state.parties || ({} as CrudStateObject<Party>)),
                [props.list.id]: {
                    ...(state.parties[props.list.id] ||
                        ({} as CrudState<Party>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        listsActions.addLookupResultsToList,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isUpdating: true,
                hasUpdatingError: false,
            },
            parties: {
                ...(state.parties || ({} as CrudStateObject<Party>)),
                [props.list.id]: {
                    ...(state.parties[props.list.id] ||
                        ({} as CrudState<Party>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        listsActions.addLookupResultsToListSuccess,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isUpdating: false,
                hasUpdatingError: false,
                list: state.lists.list
                    ? (() => {
                          const propsList = { ...props.list };
                          const lists = [...state.lists.list];
                          const listIndex = lists.findIndex(
                              (list) => list.id === propsList.id
                          );
                          propsList.numberOfMembers +=
                              props.lookupResults.length;
                          if (listIndex < 0) {
                              lists.push(propsList);
                          } else {
                              lists[listIndex] = { ...propsList };
                          }
                          return lists;
                      })()
                    : [
                          {
                              ...props.list,
                              numberOfMembers: (props.list.numberOfMembers +=
                                  props.lookupResults.length),
                          },
                      ],
            },
            parties: {
                ...(state.parties || ({} as CrudStateObject<Party>)),
                [props.list.id]: {
                    ...(state.parties[props.list.id] ||
                        ({} as CrudState<Party>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                    list: [
                        ...(() => {
                            const membersForListId = [
                                ...(state.parties[props.list.id]?.list || []),
                            ];
                            props.lookupResults.forEach((party) => {
                                const listIndex = membersForListId.findIndex(
                                    (member) => member.id === party.id
                                );
                                if (listIndex < 0) {
                                    membersForListId.push(party);
                                } else {
                                    membersForListId[listIndex] = { ...party };
                                }
                            });
                            return membersForListId;
                        })(),
                    ],
                },
            },
        })
    ),

    on(
        listsActions.addLookupResultsToListFailure,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isUpdating: false,
                hasUpdatingError: true,
            },
            parties: {
                ...(state.parties || ({} as CrudStateObject<Party>)),
                [props.list.id]: {
                    ...(state.parties[props.list.id] ||
                        ({} as CrudState<Party>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        listsActions.deleteListForId,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        listsActions.deleteListForIdSuccess,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: false,
                list: state.lists.list
                    ? (() => {
                          const lists = [...state.lists.list];
                          const listIndex = lists.findIndex(
                              (list) => list.id === props.listId
                          );
                          if (listIndex > -1) {
                              lists.splice(listIndex, 1);
                          }
                          return lists;
                      })()
                    : [],
            },
        })
    ),

    on(
        listsActions.deleteListForIdFailure,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        listsActions.getLists,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        listsActions.getListsSuccess,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: false,
                list: props.lists,
            },
        })
    ),

    on(
        listsActions.getListsFailure,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        listsActions.getListForId,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        listsActions.getListForIdSuccess,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: false,
                list: state.lists.list
                    ? (() => {
                          const lists = [...state.lists.list];
                          const listIndex = lists.findIndex(
                              (list) => list.id === props.list.id
                          );
                          if (listIndex > -1) {
                              lists.splice(listIndex, 1, props.list);
                          } else {
                              lists.push(props.list);
                          }
                          return lists;
                      })()
                    : [props.list],
            },
        })
    ),

    on(
        listsActions.getListForIdFailure,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    ),

    on(
        listsActions.getMembersForId,
        (state, props): State => ({
            ...state,
            members: {
                ...(state.members || ({} as CrudStateObject<ListMember>)),
                [props.listId]: {
                    ...(state.members[props.listId] ||
                        ({} as CrudState<ListMember>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        listsActions.getMembersForIdSuccess,
        (state, props): State => ({
            ...state,
            members: {
                ...(state.members || ({} as CrudStateObject<ListMember>)),
                [props.listId]: {
                    ...(state.members[props.listId] ||
                        ({} as CrudState<ListMember>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.members,
                },
            },
        })
    ),

    on(
        listsActions.getMembersForIdFailure,
        (state, props): State => ({
            ...state,
            members: {
                ...(state.members || ({} as CrudStateObject<ListMember>)),
                [props.listId]: {
                    ...(state.members[props.listId] ||
                        ({} as CrudState<ListMember>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        listsActions.getPartiesForId,
        (state, props): State => ({
            ...state,
            parties: {
                ...(state.parties || ({} as CrudStateObject<Party>)),
                [props.listId]: {
                    ...(state.parties[props.listId] ||
                        ({} as CrudState<Party>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        listsActions.getPartiesForIdSuccess,
        (state, props): State => ({
            ...state,
            parties: {
                ...(state.parties || ({} as CrudStateObject<Party>)),
                [props.listId]: {
                    ...(state.parties[props.listId] ||
                        ({} as CrudState<Party>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.parties,
                },
            },
        })
    ),

    on(
        listsActions.getPartiesForIdFailure,
        (state, props): State => ({
            ...state,
            parties: {
                ...(state.parties || ({} as CrudStateObject<Party>)),
                [props.listId]: {
                    ...(state.parties[props.listId] ||
                        ({} as CrudState<Party>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        listsActions.getPartyRolesForId,
        (state, props): State => ({
            ...state,
            partyRoles: {
                ...(state.partyRoles || ({} as CrudStateObject<Party>)),
                [props.listId]: {
                    ...(state.partyRoles[props.listId] ||
                        ({} as CrudState<Party>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        listsActions.getPartyRolesForIdSuccess,
        (state, props): State => ({
            ...state,
            partyRoles: {
                ...(state.partyRoles || ({} as CrudStateObject<Party>)),
                [props.listId]: {
                    ...(state.partyRoles[props.listId] ||
                        ({} as CrudState<Party>)),
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.partyRoles,
                },
            },
        })
    ),

    on(
        listsActions.getPartyRolesForIdFailure,
        (state, props): State => ({
            ...state,
            partyRoles: {
                ...(state.partyRoles || ({} as CrudStateObject<Party>)),
                [props.listId]: {
                    ...(state.partyRoles[props.listId] ||
                        ({} as CrudState<Party>)),
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        listsActions.deleteEntityFromList,
        (state, props): State => ({
            ...state,
            members: {
                ...(state.members || ({} as CrudStateObject<ListMember>)),
                [props.listId]: {
                    ...(state.members[props.listId] ||
                        ({} as CrudState<ListMember>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        })
    ),

    on(
        listsActions.deleteEntityFromListSuccess,
        (state, props): State => ({
            ...state,
            members: {
                ...(state.members || ({} as CrudStateObject<ListMember>)),
                [props.listId]: {
                    ...(state.members[props.listId] ||
                        ({} as CrudState<ListMember>)),
                    isDeleting: false,
                    hasDeletingError: false,
                    list: state.members[props.listId].list?.filter((member) => {
                        let key: string;
                        if (props.entityType === SystemEntity.Party) {
                            key = 'party';
                        }
                        if (props.entityType === SystemEntity.PartyRole) {
                            key = 'partyRole';
                        }
                        return member[key]?.id !== props.entityId;
                    }),
                },
            },
        })
    ),

    on(
        listsActions.deleteEntityFromListFailure,
        (state, props): State => ({
            ...state,
            members: {
                ...(state.members || ({} as CrudStateObject<ListMember>)),
                [props.listId]: {
                    ...(state.members[props.listId] ||
                        ({} as CrudState<ListMember>)),
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        })
    ),
    on(
        listsActions.getListsByType,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        listsActions.getListByTypeSuccess,
        (state, props): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: false,
                list: props.lists.filter(l => l.type === props.listType),
            },
        })
    ),

    on(
        listsActions.getListByTypeFailure,
        (state): State => ({
            ...state,
            lists: {
                ...(state.lists || ({} as CrudState<List>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
