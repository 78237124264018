import { Component, Input } from '@angular/core';
import { ActivityType, CaseType } from '@wdx/clmi/api-models';
import { SystemEntity } from '@wdx/shared/utils';
import { InfoCardData } from '../../../../../models/info-card-data.model';
import { InfoCardSearchMode } from '../../../../../models/info-card-search-mode.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-case-template-search-dumb',
    templateUrl: './organism-case-template-search-dumb.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OrganismCaseTemplateSearchComponentDumb {
    @Input() activityType: ActivityType;
    @Input() activityId: string;
    @Input() clientId: string;
    @Input() partyId: string;
    @Input() partyRoleId: string;
    @Input() opportunityId: string;
    @Input() caseId: string;
    @Input() nudgeId: string;
    @Input() entityType: SystemEntity;

    @Input() caseTypes: CaseType[];
    @Input() caseTypesIsLoading: boolean;
    @Input() caseTypesHasError: boolean;

    readonly INFO_CARD_SEARCH_MODE = InfoCardSearchMode;

    caseTypesAsInfoCardData(
        caseTypes: CaseType[],
        activityType: ActivityType,
        entityType: SystemEntity,
        activityId: string,
        partyId: string,
        partyRoleId: string,
        clientId: string,
        opportunityId: string,
        caseId: string,
        nudgeId: string
    ): InfoCardData[] {
        return caseTypes?.map((caseType) => ({
            primaryInfo: caseType.name,
            id: caseType.code,
            formSetup: {
                formId: caseType.formName,
                initialisationParams: {
                    version: caseType.formVersion,
                    ...(activityType ? { activityType } : {}),
                    ...(entityType ? { entityType } : {}),
                    ...(activityId ? { activityId } : {}),
                    ...(partyId ? { partyId } : {}),
                    ...(partyRoleId ? { partyRoleId } : {}),
                    ...(clientId ? { clientId } : {}),
                    ...(opportunityId ? { opportunityId } : {}),
                    ...(caseId ? { caseId } : {}),
                    ...(nudgeId ? { nudgeId } : {}),
                    caseType: caseType.code,
                },
            },
            data: caseType,
        }));
    }
}
