import { Action, createReducer, on } from '@ngrx/store';
import { PartyRoleRelationship } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as connectionsActions from './connections.actions';

export interface State {
    connections: CrudStateObject<PartyRoleRelationship>;
}

export const initialState: State = {
    connections: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        connectionsActions.getForPartyRole,
        (state, props): State => ({
            ...state,
            connections: {
                ...state.connections,
                [props.partyRoleId]: {
                    ...(state.connections[props.partyRoleId] ||
                        ({} as CrudState<PartyRoleRelationship>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        connectionsActions.getForPartyRoleSuccess,
        (state, props): State => ({
            ...state,
            connections: {
                ...state.connections,
                [props.partyRoleId]: {
                    ...state.connections[props.partyRoleId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.relationships,
                },
            },
        })
    ),

    on(
        connectionsActions.getForPartyRoleFailure,
        (state, props): State => ({
            ...state,
            connections: {
                ...state.connections,
                [props.partyRoleId]: {
                    ...state.connections[props.partyRoleId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
