import { Component, ElementRef, OnInit } from '@angular/core';
import { DUPLICATION_DETECTION_MODAL_ID } from '../../../constants/modal.constants';
import { ModalManagementService } from '../../../services/modal-management.service';
import * as duplicationCheckActions from '../../../state/duplication-check/duplication-check.actions';
import * as rootReducer from '../../../state/_setup/reducers';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { BaseInputClass } from '../../../classes/base-input-class';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-info-card-check-duplications-modal',
    templateUrl: './organism-info-card-check-duplications-modal.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-flex flex-column h-100 overflow-hidden' },
})
export class OrganismInfoCardCheckDuplicationsModalComponent
    extends BaseInputClass
    implements OnInit
{
    formId: string;
    entityId: string;
    entityType: string;
    formData: any;
    payload: any[];

    readonly DUPLICATION_DETECTION_MODAL_ID = DUPLICATION_DETECTION_MODAL_ID;

    constructor(
        elementRef: ElementRef,
        store$: Store<rootReducer.State>,
        actions$: Actions,
        modalManagementService: ModalManagementService
    ) {
        super();

        this.patchInjectedItems({
            elementRef,
            store$,
            actions$,
            modalManagementService,
        });
    }

    ngOnInit() {
        this.listenTo(duplicationCheckActions.openDuplicatesModal)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((action: any) => {
                const { formId, entityId, entityType, payload, formData } =
                    action;

                this.formId = formId;
                this.entityId = entityId;
                this.entityType = entityType;
                this.formData = formData;
                this.payload = payload;
            });
    }
}
