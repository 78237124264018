<atom-icon-button
    btnClass="btn me-1"
    data-cy="btn-sort-menu"
    size="md"
    [icon]="isAscending ? ICON_SORT_ASC : ICON_SORT_DESC"
    (click)="toggle()"
></atom-icon-button>

<molecule-context-menu-dropdown
    [menu]="sortMenu"
    [placement]="['bottom-left', 'bottom-right', 'left-top']"
    [highlightSelected]="true"
    [defaultSelectFirst]="true"
    (singleItemSelected)="select($event)"
    parentContainer="body"
></molecule-context-menu-dropdown>
