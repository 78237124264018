import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import {
    Activity,
    ActivityStatusType,
    ActivityType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { ACTIVITY_ICON_MAP } from '@wdx/clmi/utils/constants';
import {
    Highlight,
    TRANSLATION_ACTIVITY_NOT_FILED,
    TRANSLATION_MORE_LABEL,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { DateObject, DateTime } from 'luxon';
import { Observable, takeUntil } from 'rxjs';
import { PermissionsService } from '../../../shared/services/permissions/permissions.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-activity-header',
    templateUrl: './molecule-activity-header.component.html',
})
export class MoleculeActivityHeaderComponent
    extends WdxDestroyClass
    implements OnInit
{
    @Input() set activity(activity: Activity) {
        this._activity = activity;
        if (activity) {
            this.setDate();
            this.updatePermissions();
        }
    }
    get activity(): Activity {
        return this._activity;
    }
    _activity: Activity;
    @Input() showResponsiveBackButton: boolean;

    @Output() backClicked = new EventEmitter<any>();
    @Output() dueDateSelected = new EventEmitter<Date>();
    @Output() dueTimeSelected = new EventEmitter<string>();

    readonly ACTIVITY_ICON_MAP = ACTIVITY_ICON_MAP;
    readonly HIGHLIGHT = Highlight;
    readonly ACTIVITY_STATUS_TYPE = ActivityStatusType;
    readonly ACTIVITY_TYPE = ActivityType;
    readonly NOT_FILED = TRANSLATION_ACTIVITY_NOT_FILED;
    readonly MORE_LABEL = TRANSLATION_MORE_LABEL;

    canEdit$: Observable<boolean>;

    dueDate: DateObject;
    dueTime: string;
    minDueDate = DateTime.now().toObject();
    startDueDate = DateTime.now().plus({ days: 1 }).toObject();
    maxDueDate = DateTime.now().plus({ years: 1 }).toObject();
    entityId: string;

    constructor(
        private permissionsService: PermissionsService,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        this.route.params
            .pipe(takeUntil(this.destroyed$))
            .subscribe((params) => {
                this.entityId = params?.entityId;
            });
    }

    setDate(): void {
        const dueDate = this.activity.due || this.activity.dateCreated;
        this.startDueDate = DateTime.fromISO(String(dueDate)).toObject();
        this.dueDate = DateTime.fromISO(String(dueDate)).toObject();
        this.dueTime = DateTime.fromISO(String(dueDate)).toLocaleString(
            DateTime.TIME_24_SIMPLE
        );
    }

    updatePermissions(): void {
        this.canEdit$ = this.permissionsService.canEdit$(
            SystemEntity.Activity,
            this.activity.id
        );
    }

    onBackClicked(event: Event): void {
        this.backClicked.emit(event);
    }

    onDueDateClicked(dueDatePicker: NgbInputDatepicker): void {
        dueDatePicker.open();
    }

    onDueDatePickerSelected(date: NgbDate): void {
        this.dueDateSelected.emit(
            DateTime.fromObject({
                ...date,
                ...this.getTime(),
            }).toJSDate()
        );
    }

    onDueTimeSelected(): void {
        this.dueDateSelected.emit(
            DateTime.fromObject({
                ...this.dueDate,
                ...this.getTime(),
            }).toJSDate()
        );
    }

    getTime(): { hour: number; minute: number } {
        const [hour, minute] = this.dueTime
            .split(':')
            .map((unit) => parseInt(unit));
        return {
            hour,
            minute,
        };
    }

    shouldShowTimePicker(activity: Activity) {
        return (
            activity.activityType !== ActivityType.Task &&
            activity.activityType !== ActivityType.Email
        );
    }
}
