import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PushModule } from '@ngrx/component';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeInfoCardSearchModule } from '../../../components/molecules/molecule-info-card-search/molecule-info-card-search.module';
import { MoleculeInfoCardModule } from '../../../components/molecules/molecule-info-card/molecule-info-card.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { ImportFileUploaderModule } from '../../../shared/features/import-file-uploader';
import { HybridSelectMenuComponent } from './hybrid-select-menu.component';

@NgModule({
    declarations: [HybridSelectMenuComponent],
    exports: [HybridSelectMenuComponent],
    imports: [
        CommonModule,
        MoleculeActionButtonModule,
        ImportFileUploaderModule,
        AtomModalWrapperModule,
        MoleculeInfoCardSearchModule,
        MoleculeInfoCardModule,
        OrganismModalModule,
        PushModule,
    ],
})
export class HybridSelectMenuModule {}
