import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomIconButtonModule } from '../../atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeAvatarGroupModule } from '../molecule-avatar-group/molecule-avatar-group.module';
import { MoleculeInfoCardModule } from '../molecule-info-card/molecule-info-card.module';
import { MoleculeTileMiniModule } from '../molecule-tile-mini/molecule-tile-mini.module';
import { MoleculeInfoCardExtendedComponent } from './molecule-info-card-extended.component';
import { WdxToggleButtonComponent } from '@wdx/shared/components/wdx-toggle-button';

@NgModule({
    imports: [
        AtomIconButtonModule,
        WdxToggleButtonComponent,
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeAvatarGroupModule,
        MoleculeInfoCardModule,
        MoleculeTileMiniModule,
        PipesModule,
        WdxIconModule,
    ],
    declarations: [MoleculeInfoCardExtendedComponent],
    exports: [MoleculeInfoCardExtendedComponent],
})
export class MoleculeInfoCardExtendedModule {}
