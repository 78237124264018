<ng-container *ngIf="{ open: isQueryBuilderVisible$ | ngrxPush } as builder">
    <clmi-simple-view
        [config]="config"
        (formFiltersEvent)="formFiltersEvent.emit($event)"
        *ngIf="!builder.open"
    ></clmi-simple-view>
    <clmi-query-builder
        [config]="config"
        (formFiltersEvent)="formFiltersEvent.emit($event)"
        *ngIf="builder.open"
    ></clmi-query-builder>
</ng-container>
