<wdx-icon-button
    [class.has-issue]="hasIssue"
    [size]="size"
    [overflowVisible]="count > 0 ? true : false"
    [positionRelative]="count > 0 ? true : false"
    [attr.data-cy]="cySelector"
    (wdxOnClick)="onClick($event)"
>
    <ng-container *ngIf="count > 0; else noComments">
        <wdx-icon
            [icon]="hasIssue ? ICON_COMMENT_ISSUE : ICON_COMMENT"
        ></wdx-icon>
        <wdx-badge class="count">
            {{ count }}
        </wdx-badge>
    </ng-container>
</wdx-icon-button>

<ng-template #noComments>
    <wdx-icon icon="comment-plus"> </wdx-icon>
</ng-template>
