import { createAction, props } from '@ngrx/store';
import {
    ApiFunctionTriggerPayload,
    FormCompletionSummary,
    FormDataResult,
    FormDefinition,
    FormFunctionResult,
    FormTemplate,
    FormTemplateInstance,
    KeyValueObject,
    SystemEntity,
} from '@wdx/shared/utils';

export const getFormDefinitions = createAction(
    '[Form Definitions] Get Form Definitions '
);

export const getFormDefinitionsSuccess = createAction(
    '[Form Definitions] Get Form Definitions Success',
    props<{
        formDefinitions: FormDefinition[];
    }>()
);

export const getFormDefinitionsFailure = createAction(
    '[Form Definitions] Get Form Definitions Failure',
    props<{
        error: Error;
    }>()
);

export const getFormDefinition = createAction(
    '[Dynamic Forms] Get Form Definition',
    props<{
        formId: string;
    }>()
);

export const getFormDefinitionSuccess = createAction(
    '[Dynamic Forms] Get Form Definition Success',
    props<{
        formId: string;
        formDefinition: FormDefinition;
    }>()
);

export const getFormDefinitionFailure = createAction(
    '[Dynamic Forms] Get Form Definition Failure',
    props<{
        formId: string;
        error: Error;
    }>()
);

export const resetFormDefinitions = createAction(
    '[Form Definitions] Reset Form Definitions '
);

export const resetFormData = createAction(
    '[Dynamic Forms] Reset Form Data',
    props<{
        formId: string;
        formTemplateEntityId?: string;
    }>()
);

export const getFormData = createAction(
    '[Dynamic Forms] Get Form Data',
    props<{
        formId: string;
        entityId: string;
        queryParams?: KeyValueObject;
        endpointPath?: string;
    }>()
);

export const setFormData = createAction(
    '[Dynamic Forms] Set Form Data',
    props<{
        formId: string;
        entityId: string;
        formData: FormDataResult;
    }>()
);

export const getFormDataSuccess = createAction(
    '[Dynamic Forms] Get Form Data Success',
    props<{
        formId: string;
        entityId: string;
        formData: FormDataResult;
    }>()
);

export const getFormDataFailure = createAction(
    '[Dynamic Forms] Get Form Data Failure',
    props<{
        formId: string;
        entityId: string;
        error: Error;
    }>()
);

export const getFormInitialisationData = createAction(
    '[Dynamic Forms] Get Form Initialisation Data',
    props<{
        formId: string;
        initialisationParams?: KeyValueObject;
    }>()
);

export const getFormInitialisationDataSuccess = createAction(
    '[Dynamic Forms] Get Form Initialisation Data Success',
    props<{
        formId: string;
        formData: FormDataResult;
    }>()
);

export const getFormInitialisationDataFailure = createAction(
    '[Dynamic Forms] Get Form Initialisation Data Failure',
    props<{
        formId: string;
        error: Error;
    }>()
);

export const getFormFunctionResult = createAction(
    '[Dynamic Forms] Get Form Function Result',
    props<{
        formId: string;
        apiFunctionTriggerPayload: ApiFunctionTriggerPayload;
    }>()
);

export const getFormFunctionResultSuccess = createAction(
    '[Dynamic Forms] Get Form Function Result Success',
    props<{
        formId: string;
        apiFunctionTriggerPayload: ApiFunctionTriggerPayload;
        formFunctionResult: FormFunctionResult;
    }>()
);

export const getFormFunctionResultFailure = createAction(
    '[Dynamic Forms] Get Form Function Result Failure',
    props<{
        formId: string;
        error: Error;
    }>()
);

export const getFormTemplates = createAction(
    '[Dynamic Forms] Get Form Templates',
    props<{
        entityType: SystemEntity;
        entityId: string;
    }>()
);

export const getFormTemplatesSuccess = createAction(
    '[Dynamic Forms] Get Form Templates Success',
    props<{
        entityId: string;
        formTemplates: FormTemplate[];
    }>()
);

export const getFormTemplatesFailure = createAction(
    '[Dynamic Forms] Get Form Templates Failure',
    props<{
        entityId: string;
        error: Error;
    }>()
);

export const getFormTemplateInstance = createAction(
    '[Dynamic Forms] Get Form Template Instance',
    props<{
        formTemplateId: string;
        entityType: SystemEntity;
        entityId: string;
    }>()
);

export const getFormTemplateInstanceSuccess = createAction(
    '[Dynamic Forms] Get Form Template Instance Success',
    props<{
        entityId: string;
        formTemplateInstance: FormTemplateInstance;
    }>()
);

export const getFormTemplateInstanceFailure = createAction(
    '[Dynamic Forms] Get Form Template Instance Failure',
    props<{
        entityId: string;
        error: Error;
    }>()
);

export const createNonStandardFormData = createAction(
    '[Dynamic Forms] Create Non Standard Form Data',
    props<{
        formId: string;
        formData: any;
        endpointPath: string;
    }>()
);

export const createNonStandardFormDataSuccess = createAction(
    '[Dynamic Forms] Create Non Standard Form Data Success',
    props<{
        formId: string;
        formDataInstance: FormDataResult;
    }>()
);

export const createNonStandardFormDataFailure = createAction(
    '[Dynamic Forms] Create Non Standard Form Data Failure',
    props<{
        formId: string;
        error: Error;
    }>()
);

export const updateNonStandardFormData = createAction(
    '[Dynamic Forms] Update Non Standard Form Data',
    props<{
        formId: string;
        entityId: string;
        formData: any;
        endpointPath: string;
    }>()
);

export const updateNonStandardFormDataSuccess = createAction(
    '[Dynamic Forms] Update Non Standard Form Data Success',
    props<{
        formId: string;
        response: FormDataResult;
    }>()
);

export const updateNonStandardFormDataFailure = createAction(
    '[Dynamic Forms] Update Non Standard Form Data Failure',
    props<{
        formId: string;
        error: Error;
    }>()
);

export const updateStandardFormData = createAction(
    '[Dynamic Forms] Update Standard Form Data',
    props<{
        formId: string;
        entityId: string;
        formData: any;
        publish: boolean;
        ignoreDuplicates?: boolean;
        saveMode?: string[];
        queryParams?: string;
    }>()
);

export const updateStandardFormDataSuccess = createAction(
    '[Dynamic Forms] Update Standard Form Data Success',
    props<{
        formId: string;
        response: FormDataResult;
        saveMode?: string[];
    }>()
);

export const updateStandardFormDataFailure = createAction(
    '[Dynamic Forms] Update Standard Form Data Failure',
    props<{
        formId: string;
        error: Error;
    }>()
);

export const emitFormData = createAction(
    '[Dynamic Forms] Emit Form Data',
    props<{
        data: any;
        value?: KeyValueObject;
        isDelete?: boolean;
    }>()
);

export const saveCompletionSummary = createAction(
    '[Dynamic Forms] Save Completion Summary',
    props<{
        formId: string;
        entityId: string;
        completionSummary: FormCompletionSummary;
    }>()
);

export const saveCompletionSummarySuccess = createAction(
    '[Dynamic Forms] Save Completion Summary Success',
    props<{
        formId: string;
        entityId: string;
        completionSummary: FormCompletionSummary;
    }>()
);

export const saveCompletionSummaryFailure = createAction(
    '[Dynamic Forms] Save Completion Summary Failure',
    props<{
        formId: string;
        entityId: string;
        error: Error;
    }>()
);

export const putCustomEndpointFormData = createAction(
    '[Dynamic Forms] Put Custom Form Data',
    props<{
        formId: string;
        formData: any;
        customEndpoint: string;
    }>()
);

export const putCustomEndpointFormDataSuccess = createAction(
    '[Dynamic Forms] Put Custom Form Data Success',
    props<{
        formId: string;
        response: FormDataResult;
    }>()
);

export const putCustomEndpointFormDataFailure = createAction(
    '[Dynamic Forms]  Put Custom Form Data Failure',
    props<{
        formId: string;
        error: Error;
    }>()
);

export const bulkJobCompleted = createAction(
    '[Dynamic Forms] Bulk Job Completed'
);

export const formDataCreateAndUpdateSuccessActions = [
    createNonStandardFormDataSuccess,
    updateNonStandardFormDataSuccess,
    updateStandardFormDataSuccess,
    emitFormData,
    putCustomEndpointFormDataSuccess,
];

export const formDataCreateAndUpdateFailureActions = [
    createNonStandardFormDataFailure,
    updateNonStandardFormDataFailure,
    updateStandardFormDataFailure,
    putCustomEndpointFormDataFailure,
];
