import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MoleculeInfoCardExtendedModule } from '../../../components/molecules/molecule-info-card-extended/molecule-info-card-extended.module';
import { MoleculeInfoCardGroupModule } from '../../../components/molecules/molecule-info-card-group/molecule-info-card-group.module';
import { MoleculeInputLookupModule } from '../../../components/molecules/molecule-input-lookup/molecule-input-lookup.module';
import { MoleculeSearchAndFilterBarModule } from '../../../components/molecules/molecule-search-and-filter-bar/molecule-search-and-filter-bar.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomAddButtonModule } from '../../atoms/atom-add-button/atom-add-button.module';
import { AtomAsyncDataModule } from '../../atoms/atom-async-data/atom-async-data.module';
import { AtomIconButtonModule } from '../../atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeActionButtonModule } from '../../molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeContextMenuDropdownModule } from '../../molecules/molecule-context-menu-dropdown/molecule-context-menu-dropdown.module';
import { MoleculeContextMenuModule } from '../../molecules/molecule-context-menu/molecule-context-menu.module';
import { OrganismExtendedInfoCardListComponent } from './organism-extended-info-card-list.component';

@NgModule({
    imports: [
        AtomAddButtonModule,
        AtomAsyncDataModule,
        AtomIconButtonModule,
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeContextMenuDropdownModule,
        MoleculeContextMenuModule,
        MoleculeInputLookupModule,
        MoleculeInfoCardGroupModule,
        MoleculeSearchAndFilterBarModule,
        PipesModule,
        RouterModule,
        MoleculeInfoCardExtendedModule,
    ],
    declarations: [OrganismExtendedInfoCardListComponent],
    exports: [OrganismExtendedInfoCardListComponent],
})
export class OrganismExtendedInfoCardListModule {}
