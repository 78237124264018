<clmi-comment-card
    [stylingConfig]="{ inverted: commentsFacade.invertedStyling, reply }"
>
    <ng-container>
        <molecule-avatar
            class="me-2"
            size="md"
            [avatar]="comment.party | partyToAvatar"
        ></molecule-avatar>

        <h6 class="h6">{{ comment.party?.name }}</h6>
    </ng-container>

    <span #commentContainer *ngFor="let item of commentFragments" comments>
        <span [ngClass]="MENTION" *ngIf="item.partyType; else text">
            <button
                class="d-none d-xl-inline-block btn btn-link"
                container="body"
                [ngbPopover]="avatarInfoPopover"
                (click)="$event.stopPropagation()"
            >
                {{ item.text }}
            </button>

            <button
                class="d-inline-block d-xl-none btn btn-link"
                (click)="openAvatarModal(item)"
            >
                {{ item.text }}
            </button>

            <ng-template #avatarInfoPopover>
                <molecule-avatar-info [avatar]="item"></molecule-avatar-info>
            </ng-template>
        </span>

        <ng-template #text>
            <a
                *ngIf="item?.link"
                class="link pointer"
                [innerHTML]="item.text"
                [routerLink]="item.link"
            ></a>
            <a
                *ngIf="item?.callback"
                class="link pointer"
                [innerHTML]="item.text"
                href="javascript:void(0)"
                (click)="item.callback()"
            ></a>

            <span
                *ngIf="!item?.link && !item?.callback"
                [ngClass]="COMMENT"
                [innerHTML]="item.text | safeHtml"
            ></span>
        </ng-template>
    </span>

    <ng-container meta>
        <p
            class="small text-muted"
            [class.mb-2]="comment.isIssue || comment.resolvedOn"
        >
            {{
                comment.timestamp
                    | dateTime : { format: WdxDateFormat.RelativeDateTime }
            }}
        </p>

        <div class="d-flex">
            <wdx-badge
                *ngIf="comment.isIssue || comment.resolvedOn"
                data-cy="comment-issue-badge"
                [themeColor]="comment.isIssue ? 'danger' : 'success'"
            >
                <div class="d-flex align-items-center">
                    <wdx-icon
                        class="me-2"
                        [scale]="1"
                        [icon]="comment.isIssue ? ICON_ISSUE : ICON_RESOLVED"
                        [noContainer]="true"
                    ></wdx-icon>
                    <span data-cy="comment-status">{{
                        !comment.isIssue || comment.resolvedOn
                            ? 'Issue resolved'
                            : 'Issue reported'
                    }}</span>
                    <span *ngIf="comment.resolvedOn">
                        &nbsp;by {{ comment.resolvedBy?.name }}
                        {{
                            comment.resolvedOn
                                | dateTime
                                    : {
                                          format: WdxDateFormat.RelativeDateTime
                                      }
                        }}
                    </span>
                </div>
            </wdx-badge>
            <ng-container *ngIf="comment.isIssue && !readonly">
                <wdx-checkbox
                    class="ms-2"
                    data-cy="comment-chk-resolve-issue"
                    label="Resolve Issue"
                    [disabled]="resolveIssueId && resolveIssueId !== comment.id"
                    [checked]="resolveIssueId === comment.id"
                    (isChecked)="resolveIssue($event)"
                ></wdx-checkbox>
            </ng-container>
        </div>
    </ng-container>
</clmi-comment-card>
