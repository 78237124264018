<molecule-input-upload-dumb
    [formInputData]="formInputData"
    [formDisplayMode]="formDisplayMode"
    [formSummaryMode]="formSummaryMode"
    [inputOnly]="inputOnly"
    [type]="type"
    [fileUploadStatus]="fileUploadStatus$ | ngrxPush"
    [fileUploadProgress]="fileUploadProgress$ | ngrxPush"
    [fileIndex]="(fileIndex$ | ngrxPush) || valueFileIndex"
    [previewFileUrl]="(filePreview$ | ngrxPush)?.fileUrl"
    (fileAdded)="onFileAdded($event)"
    (removeFile)="onRemoveFile($event)"
    (downloadFile)="onDownloadFile($event)"
></molecule-input-upload-dumb>
