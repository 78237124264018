import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as selectsActions from './select-options.actions';
import { SelectApiService } from '@wdx/clmi/api-services/services';

@Injectable()
export class SelectOptionsEffects {
    private actions$ = inject(Actions);
    private selectApiService = inject(SelectApiService);

    getSelectOptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectsActions.getSelectOptions),
            mergeMap((action) =>
                this.selectApiService.getSelect(action.id).pipe(
                    map((results) => {
                        return selectsActions.getSelectOptionsSuccess({
                            id: action.id,
                            results,
                        });
                    }),
                    catchError((error) =>
                        of(
                            selectsActions.getSelectOptionsFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getContextualSelect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectsActions.getContextualSelect),
            mergeMap((action) =>
                this.selectApiService
                    .getContextualSelect(
                        action.source,
                        action.lookups,
                        action.context
                    )
                    .pipe(
                        map((results) => {
                            return selectsActions.getContextualSelectOptionsSuccess(
                                {
                                    id: action.id,
                                    results,
                                }
                            );
                        }),
                        catchError((error) =>
                            of(
                                selectsActions.getContextualSelectOptionsFailure(
                                    {
                                        id: action.id,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
