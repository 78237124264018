import { FormInputData } from '@wdx/shared/infrastructure/form-framework';
import { LookupSourceType } from '@wdx/clmi/api-models';

export const ASSIGN_TO_LOOKUP_DATA: FormInputData = {
    label: 'Assign to',
    id: 'assignLookup',
    lookupSources: [{ type: LookupSourceType.Assignee }],
};

export const ASSIGN_TO_LOOKUP_DATA_USER_TEAM: FormInputData = {
    ...ASSIGN_TO_LOOKUP_DATA,
    lookupSources: [
        { type: LookupSourceType.User },
        { type: LookupSourceType.Team },
    ],
};

export const ASSIGN_TO_USER_LOOKUP_DATA: FormInputData = {
    ...ASSIGN_TO_LOOKUP_DATA,
    lookupSources: [{ type: LookupSourceType.User }],
};
