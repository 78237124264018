import { Pipe, PipeTransform } from '@angular/core';

/**
 * Example: '{field}: from {original} to {target}' | replace : [{ value: 'Name', target: '{field}' }, { value: 'Cherry', target: '{original}'}, { value: 'Oak', target: '{target}'}]
 */
@Pipe({
    name: 'replace',
    standalone: true,
})
export class WdxReplacePipe implements PipeTransform {
    /**
     *
     * @param {String} value: This is the string that needs updating
     * @param {{ target: string; value: string }[]} options: The is an array of objects.
     *         An object has an target which is the part that needs replacing.
     *         It has a value which is what the target would be replaced with
     * @returns {String}
     */
    transform(
        value: string | undefined,
        options: { target: string; value: string | null | undefined }[]
    ): string {
        if (!value || !options) {
            return '';
        }

        let text = value;

        options.forEach((option) => {
            const VAL = option?.value ? option.value : '';
            text = text.replaceAll(option.target, VAL);
        });

        return text;
    }
}
