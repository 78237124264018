import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: `
        div[wdxListGroupItem],
        li[wdxListGroupItem],
        button[wdxListGroupAction],
        a[wdxListGroupAction]
    `,
})
export class ListGroupItemDirective {
    private _listGroupAction = false;
    get listGroupAction(): boolean {
        return this._listGroupAction;
    }
    @Input() set wdxListGroupAction(listGroupAction: boolean) {
        this._listGroupAction = listGroupAction !== undefined;
    }

    @HostBinding('class') hostClasses = 'list-group-item';
    @HostBinding('class.list-group-item-action') get isActionClass() {
        return this.listGroupAction;
    }
}
