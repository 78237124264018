import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[wdxDropdownItem]',
})
export class WdxDropdownItemDirective {
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.renderer.addClass(this.elementRef.nativeElement, 'dropdown-item');

        this.renderer.addClass(
            this.elementRef.nativeElement,
            'wdx-dropdown-item'
        );
    }
}
