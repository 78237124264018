import { Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { BaseDiDependantClass } from './base-di-dependant.class';

@Directive()
export class BaseModalQueryComponentClass extends BaseDiDependantClass {
    cachedModalQueryParams: any;

    modalQueryParameter(): Observable<any> {
        if (this.hasMissingDependencies('modalQueryParameter', 'route')) {
            return;
        }

        return this.route.queryParams.pipe(
            takeUntil(this.destroyed$),
            filter((params) => Boolean(params.modal)),
            filter(
                (params) =>
                    btoa(params.modal) !== btoa(this.cachedModalQueryParams)
            ),
            map((params) => {
                this.cachedModalQueryParams = params.modal;
                return JSON.parse(atob(params.modal));
            })
        );
    }
}
