import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { DisplayDateOptions } from '../../models/display-date-options.model';

@Pipe({ name: 'displayDate', standalone: true })
export class DisplayDatePipe implements PipeTransform {
    transform(
        value: Date | string | undefined,
        options?: DisplayDateOptions
    ): string {
        if (!value || new Date(value).toString() === 'Invalid Date') {
            return '';
        }

        const completeOptions: DisplayDateOptions = {
            relative: false,
            relativeStyle: 'long',
            format: `${!options?.timeOnly ? 'dd LLL yyyy' : ''} ${
                options?.timeOnly || options?.includeTime ? 'hh:mma' : ''
            }`,
            locale: options?.locale,
            ...options,
        };

        const luxonDate = DateTime.fromJSDate(new Date(value)).setLocale(
            completeOptions.locale as string
        );

        return this.getResult(luxonDate, completeOptions)?.trim() || '';
    }

    private getResult(_date: DateTime, options: DisplayDateOptions) {
        const date =
            options.relative && this.isOlderThanHours(_date, 24)
                ? DateTime.local(_date.year, _date.month, _date.day).setLocale(
                      options.locale as string
                  )
                : _date;
        const dateIsToday = this.isToday(date);
        const dateIsYesterday = this.isYesterday(date);

        if (options.todayAsString && (dateIsToday || dateIsYesterday)) {
            const toRelativeCalendar =
                dateIsYesterday && date.toRelativeCalendar() === 'last month'
                    ? 'yesterday'
                    : date.toRelativeCalendar();
            return [
                new TitleCasePipe().transform(toRelativeCalendar),
                ...(options.includeTime ? [date.toFormat('hh:mma')] : []),
            ].join(' ');
        }

        if (options.relative) {
            return date.toRelative({
                style: options.relativeStyle,
                unit: options.unit,
                locale: options.locale,
            });
        }
        return date.toFormat(options.format as string);
    }

    private isOlderThanHours(date: DateTime, hours: number): boolean {
        return date < DateTime.local().minus({ hours });
    }

    private isToday(date: DateTime): boolean {
        return date.toISODate() === DateTime.local().toISODate();
    }

    private isYesterday(date: DateTime): boolean {
        return (
            date.toISODate() === DateTime.local().minus({ days: 1 }).toISODate()
        );
    }
}
