import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Dashboard, SystemEntity } from '@wdx/clmi/api-models';
import { CrudStatus } from '@wdx/shared/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as dashboardsActions from './dashboards.actions';
import { State } from './dashboards.reducer';
import * as dashboardsSelectors from './dashboards.selectors';

@Injectable({
    providedIn: 'root',
})
export class DashboardsFacadeService {
    dashboardUpdated$ = new BehaviorSubject<Dashboard | undefined>(undefined);
    hasDashboards!: boolean;

    private store$ = inject(Store<State>);

    getDashboards$(): Observable<Dashboard[] | undefined> {
        return this.store$.select(dashboardsSelectors.getDashboards).pipe(
            tap(() => {
                if (!this.hasDashboards) {
                    this.loadDashboards();
                    this.hasDashboards = true;
                }
            }),
            filter((res) => Boolean(res))
        );
    }

    getDashboardById$(dashboardId: string): Observable<Dashboard | undefined> {
        return this.getDashboards$().pipe(
            switchMap(() => {
                return this.store$.select(
                    dashboardsSelectors.getDashboardById,
                    {
                        id: dashboardId,
                    }
                );
            })
        );
    }

    getDashboardsConfigurationStatus$(): Observable<CrudStatus | undefined> {
        return this.store$.select(
            dashboardsSelectors.getDashboardsConfigurationStatus
        );
    }

    getDashboardsConfiguration$(): Observable<Record<string, any> | undefined> {
        return this.store$
            .select(dashboardsSelectors.getDashboardsConfiguration)
            .pipe(
                withLatestFrom(this.getDashboardsConfigurationStatus$()),
                tap(([configuration, status]) => {
                    if (
                        !configuration &&
                        status !== CrudStatus.Loading &&
                        status !== CrudStatus.Error
                    ) {
                        this.loadDashboardsConfiguration();
                    }
                }),
                map(([configuration]) => configuration)
            );
    }

    getSelectedDashboard$(): Observable<Dashboard | undefined> {
        return this.store$.select(dashboardsSelectors.getSelectedDashboard);
    }

    getDashboardsIsLoading$(): Observable<boolean | undefined> {
        return this.store$.select(dashboardsSelectors.getDashboardsIsLoading);
    }

    getDashboardsIsUpdating$(): Observable<boolean | undefined> {
        return this.store$.select(dashboardsSelectors.getDashboardsIsUpdating);
    }

    getMenuHidden$(): Observable<boolean | undefined> {
        return this.store$.select(dashboardsSelectors.getMenuHidden);
    }

    createDashboard(body: any): void {
        this.store$.dispatch(dashboardsActions.createDashboard({ body }));
    }

    updateDashboard(dashboardId: string, body: any): void {
        this.store$.dispatch(
            dashboardsActions.updateDashboard({ dashboardId, body })
        );
    }

    shareDashboard(dashboardId: string, contactId: string): void {
        this.store$.dispatch(
            dashboardsActions.shareDashboard({ dashboardId, contactId })
        );
    }

    unshareDashboard(
        dashboardId: string,
        contactId: string,
        meIsOwner = false
    ): void {
        this.store$.dispatch(
            dashboardsActions.unshareDashboard({
                dashboardId,
                contactId,
                meIsOwner,
            })
        );
    }

    deleteDashboard(dashboardId: string, entityType: SystemEntity): void {
        this.store$.dispatch(
            dashboardsActions.deleteDashboard({ dashboardId, entityType })
        );
    }

    loadDashboards(): void {
        this.store$.dispatch(dashboardsActions.getDashboards());
    }

    loadDashboardsConfiguration(): void {
        this.store$.dispatch(dashboardsActions.getDashboardsConfiguration());
    }

    selectDashboard(dashboard?: Dashboard): void {
        this.store$.dispatch(dashboardsActions.selectDashboard({ dashboard }));
    }

    setMenuHidden(hidden = true): void {
        this.store$.dispatch(dashboardsActions.hideMenu({ hidden }));
    }

    toggleMenuHidden(): void {
        this.store$.dispatch(dashboardsActions.toggleMenu());
    }
}
