import { Action, createReducer, on } from '@ngrx/store';
import { Document, Envelope, Signatory } from '@wdx/clmi/api-models';
import {
    CrudState,
    CrudStateObject,
    KeyValueObject,
    WdxReducerClass,
    WdxUtilitiesService,
} from '@wdx/shared/utils';
import * as envelopeActions from './envelope.actions';

export interface State {
    envelopes?: CrudState<Envelope>;
    envelopesForEntity?: KeyValueObject<CrudStateObject<Envelope>>;
    envelope?: CrudStateObject<Envelope>;
    documents?: CrudStateObject<Document>;
    signatories?: CrudStateObject<Signatory>;
    activeEnvelopeId?: string;
}

export const initialState: State = {
    envelopes: {},
    envelopesForEntity: {},
    envelope: {},
    documents: {},
    signatories: {},
    activeEnvelopeId: undefined,
};

export class EnvelopeReducer extends WdxReducerClass<State> {
    reducerSetup = createReducer(
        initialState,

        on(
            envelopeActions.getAll,
            (state): State => ({
                ...state,
                envelopes: this.pageLoading(state.envelopes),
            })
        ),
        on(
            envelopeActions.getAllSuccess,
            (state, props): State => ({
                ...state,
                envelopes: this.pageSuccess(state.envelopes, props.envelopes),
                envelope: {
                    ...this.setCrudStateObject(state.envelope),
                    ...this.arrayToCrudStateObject(props.envelopes.results),
                },
            })
        ),
        on(
            envelopeActions.getAllFailure,
            (state): State => ({
                ...state,
                envelopes: this.pageFailure(state.envelopes),
            })
        ),

        on(
            envelopeActions.getForEntityId,
            (state, props): State => ({
                ...state,
                envelopesForEntity: {
                    ...(state.envelopesForEntity ||
                        ({} as KeyValueObject<CrudStateObject<Envelope>>)),
                    [props.entity]: {
                        ...this.setCrudStateObject(
                            state.envelopesForEntity[props.entity]
                        ),
                        [props.entityId]: this.listLoading(
                            state.envelopesForEntity[props.entity]?.[
                                props.entityId
                            ]
                        ),
                    },
                },
            })
        ),
        on(
            envelopeActions.getForEntityIdSuccess,
            (state, props): State => ({
                ...state,
                envelopesForEntity: {
                    ...(state.envelopesForEntity ||
                        ({} as KeyValueObject<CrudStateObject<Envelope>>)),
                    [props.entity]: {
                        ...this.setCrudStateObject(
                            state.envelopesForEntity[props.entity]
                        ),
                        [props.entityId]: this.listSuccess(
                            state.envelopesForEntity[props.entity]?.[
                                props.entityId
                            ],
                            props.envelopes
                        ),
                    },
                },
                envelope: {
                    ...this.setCrudStateObject(state.envelope),
                    ...this.arrayToCrudStateObject(props.envelopes),
                },
            })
        ),
        on(
            envelopeActions.getForEntityIdFailure,
            (state, props): State => ({
                ...state,
                envelopesForEntity: {
                    ...(state.envelopesForEntity ||
                        ({} as KeyValueObject<CrudStateObject<Envelope>>)),
                    [props.entity]: {
                        ...this.setCrudStateObject(
                            state.envelopesForEntity[props.entity]
                        ),
                        [props.entityId]: this.listFailure(
                            state.envelopesForEntity[props.entity]?.[
                                props.entityId
                            ]
                        ),
                    },
                },
            })
        ),

        on(
            envelopeActions.getForId,
            (state, props): State => ({
                ...state,
                envelopes: this.updating(state.envelopes),
                envelope: this.singleForIdLoading(state.envelope, props.id),
                activeEnvelopeId: props.id,
            })
        ),
        on(
            envelopeActions.getForIdSuccess,
            (state, props): State => ({
                ...state,
                envelopes: {
                    ...this.updatingSuccess(state.envelopes),
                    page: {
                        ...state.envelopes?.page,
                        results: this.utilitiesService.updateObjectInArray(
                            state.envelopes?.page?.results,
                            props.envelope
                        ),
                    },
                },
                envelope: this.singleForIdSuccess(
                    state.envelope,
                    props.id,
                    props.envelope
                ),
            })
        ),
        on(
            envelopeActions.getForIdFailure,
            (state, props): State => ({
                ...state,
                envelopes: this.updatingFailure(state.envelopes),
                envelope: this.singleForIdFailure(state.envelope, props.id),
            })
        ),

        on(
            envelopeActions.clearActiveEnvelope,
            (state): State => ({
                ...state,
                activeEnvelopeId: null,
            })
        ),

        on(
            envelopeActions.sendForId,
            (state, props): State => ({
                ...state,
                envelopes: this.updating(state.envelopes),
                envelopesForEntity: this.updateEnvelopesForEntity(
                    state.envelopesForEntity,
                    props.id,
                    'loading'
                ),
                envelope: this.updatingForId(state.envelope, props.id),
            })
        ),
        on(
            envelopeActions.sendForIdSuccess,
            (state, props): State => ({
                ...state,
                envelopes: this.updatingSuccess(state.envelopes),
                envelopesForEntity: this.updateEnvelopesForEntity(
                    state.envelopesForEntity,
                    props.id,
                    'success',
                    false,
                    props.envelope
                ),
                envelope: this.updatingForIdSuccess(
                    state.envelope,
                    props.id,
                    'single',
                    props.envelope
                ),
            })
        ),
        on(
            envelopeActions.sendForIdFailure,
            (state, props): State => ({
                ...state,
                envelopes: this.updatingFailure(state.envelopes),
                envelopesForEntity: this.updateEnvelopesForEntity(
                    state.envelopesForEntity,
                    props.id,
                    'failure'
                ),
                envelope: this.updatingForIdFailure(state.envelope, props.id),
            })
        ),

        on(
            envelopeActions.resendForId,
            (state, props): State => ({
                ...state,
                envelopes: this.updating(state.envelopes),
                envelopesForEntity: this.updateEnvelopesForEntity(
                    state.envelopesForEntity,
                    props.id,
                    'loading'
                ),
                envelope: this.updatingForId(state.envelope, props.id),
            })
        ),
        on(
            envelopeActions.resendForIdSuccess,
            (state, props): State => ({
                ...state,
                envelopes: {
                    ...this.updatingSuccess(state.envelopes),
                    page: {
                        ...state.envelopes?.page,
                        results: this.utilitiesService.updateObjectInArray(
                            state.envelopes?.page?.results,
                            props.envelope
                        ),
                    },
                },
                envelopesForEntity: this.updateEnvelopesForEntity(
                    state.envelopesForEntity,
                    props.id,
                    'success',
                    false,
                    props.envelope
                ),
                envelope: this.updatingForIdSuccess(
                    state.envelope,
                    props.id,
                    'single',
                    props.envelope
                ),
            })
        ),
        on(
            envelopeActions.resendForIdFailure,
            (state, props): State => ({
                ...state,
                envelopes: this.updatingFailure(state.envelopes),
                envelopesForEntity: this.updateEnvelopesForEntity(
                    state.envelopesForEntity,
                    props.id,
                    'failure'
                ),
                envelope: this.updatingForIdFailure(state.envelope, props.id),
            })
        ),

        on(
            envelopeActions.deleteForId,
            (state, props): State => ({
                ...state,
                envelopes: this.deleting(state.envelopes),
                envelopesForEntity: this.updateEnvelopesForEntity(
                    state.envelopesForEntity,
                    props.id,
                    'loading',
                    true
                ),
                envelope: this.deletingForId(state.envelope, props.id),
            })
        ),
        on(
            envelopeActions.deleteForIdSuccess,
            (state, props): State => ({
                ...state,
                envelopes: this.deletingSuccess(
                    state.envelopes,
                    props.id,
                    'list'
                ),
                envelopesForEntity: this.updateEnvelopesForEntity(
                    state.envelopesForEntity,
                    props.id,
                    'success',
                    true
                ),
                envelope: this.deletingForIdSuccess(
                    state.envelope,
                    props.id,
                    props.id,
                    'single'
                ),
            })
        ),
        on(
            envelopeActions.deleteForIdFailure,
            (state, props): State => ({
                ...state,
                envelopes: this.deletingFailure(state.envelopes),
                envelopesForEntity: this.updateEnvelopesForEntity(
                    state.envelopesForEntity,
                    props.id,
                    'failure',
                    true
                ),
                envelope: this.deletingForIdFailure(state.envelope, props.id),
            })
        ),

        on(
            envelopeActions.getDocumentsForId,
            (state, props): State => ({
                ...state,
                documents: this.listForIdLoading(state.documents, props.id),
            })
        ),
        on(
            envelopeActions.getDocumentsForIdSuccess,
            (state, props): State => ({
                ...state,
                documents: this.listForIdSuccess(
                    state.documents,
                    props.id,
                    props.documents
                ),
            })
        ),
        on(
            envelopeActions.getDocumentsForIdFailure,
            (state, props): State => ({
                ...state,
                documents: this.listForIdFailure(state.documents, props.id),
            })
        ),

        on(
            envelopeActions.getSignatoriesForId,
            (state, props): State => ({
                ...state,
                signatories: this.listForIdLoading(state.signatories, props.id),
            })
        ),
        on(
            envelopeActions.getSignatoriesForIdSuccess,
            (state, props): State => ({
                ...state,
                signatories: this.listForIdSuccess(
                    state.signatories,
                    props.id,
                    props.signatories
                ),
            })
        ),
        on(
            envelopeActions.getSignatoriesForIdFailure,
            (state, props): State => ({
                ...state,
                signatories: this.listForIdFailure(state.signatories, props.id),
            })
        ),

        on(
            envelopeActions.addDocumentForId,
            (state, props): State => ({
                ...state,
                envelope: this.updatingForId(state.envelope, props.envelopeId),
                documents: this.updatingForId(
                    state.documents,
                    props.envelopeId
                ),
            })
        ),
        on(
            envelopeActions.addDocumentForIdSuccess,
            (state, props): State => ({
                ...state,
                envelope: this.updatingForIdSuccess(
                    state.envelope,
                    props.envelopeId
                ),
                documents: this.updatingForIdSuccess(
                    state.documents,
                    props.envelopeId,
                    'list',
                    props.document
                ),
            })
        ),
        on(
            envelopeActions.addDocumentForIdFailure,
            (state, props): State => ({
                ...state,
                envelope: this.updatingForIdFailure(
                    state.envelope,
                    props.envelopeId
                ),
                documents: this.updatingForIdFailure(
                    state.documents,
                    props.envelopeId
                ),
            })
        ),

        on(
            envelopeActions.addSignatoryForId,
            (state, props): State => ({
                ...state,
                envelope: this.updatingForId(state.envelope, props.envelopeId),
                signatories: this.updatingForId(
                    state.signatories,
                    props.envelopeId
                ),
            })
        ),
        on(envelopeActions.addSignatoryForIdSuccess, (state, props) => ({
            ...state,
            envelope: this.updatingForIdSuccess(
                state.envelope,
                props.envelopeId
            ),
            signatories: this.updatingForIdSuccess(
                state.signatories,
                props.envelopeId,
                'list',
                props.signatory
            ),
        })),
        on(envelopeActions.addSignatoryForIdFailure, (state, props) => ({
            ...state,
            envelope: this.updatingForIdFailure(
                state.envelope,
                props.envelopeId
            ),
            signatories: this.updatingForIdFailure(
                state.signatories,
                props.envelopeId
            ),
        })),

        on(envelopeActions.deleteDocumentForId, (state, props) => ({
            ...state,
            documents: this.deletingForId(state.documents, props.envelopeId),
        })),
        on(envelopeActions.deleteDocumentForIdSuccess, (state, props) => ({
            ...state,
            documents: this.deletingForIdSuccess(
                state.documents,
                props.envelopeId,
                props.documentId,
                'list'
            ),
        })),
        on(envelopeActions.deleteDocumentForIdFailure, (state, props) => ({
            ...state,
            documents: this.deletingForIdFailure(
                state.documents,
                props.envelopeId
            ),
        }))
    );

    updateEnvelopesForEntity(
        state: KeyValueObject<CrudStateObject<Envelope>>,
        envelopeId: string,
        type: 'loading' | 'success' | 'failure',
        deleting?: boolean,
        updateWith?: Envelope
    ): KeyValueObject<CrudStateObject<Envelope>> {
        let _state: KeyValueObject<CrudStateObject<Envelope>> = {
            ...state,
        };
        let updatedFlags: CrudState<Envelope>;
        switch (type) {
            case 'loading':
                updatedFlags = {
                    ...(deleting
                        ? { isDeleting: true, hasDeletingError: false }
                        : { isUpdating: true, hasUpdatingError: false }),
                };
                break;
            case 'success':
                updatedFlags = {
                    ...(deleting
                        ? { isDeleting: false, hasDeletingError: false }
                        : { isUpdating: false, hasUpdatingError: false }),
                };
                break;
            case 'failure':
                updatedFlags = {
                    ...(deleting
                        ? { isDeleting: false, hasDeletingError: true }
                        : { isUpdating: false, hasUpdatingError: true }),
                };
                break;
        }
        Object.keys(state).forEach((entity) => {
            const envelopesForEntity = state[entity];
            Object.keys(envelopesForEntity).forEach((entityId) => {
                const envelopes = state[entity][entityId];
                const list = [...envelopes.list];
                if (type === 'success') {
                    const envelopeIndex = envelopes.list.findIndex(
                        (envelope) => envelope.id === envelopeId
                    );
                    if (envelopeIndex > -1) {
                        if (deleting) {
                            list.splice(envelopeIndex, 1);
                        } else {
                            list.splice(envelopeIndex, 1, updateWith);
                        }
                    } else if (updateWith && !deleting) {
                        list.push(updateWith);
                    }
                }
                _state = {
                    ..._state,
                    [entity]: {
                        ...state[entity],
                        [entityId]: {
                            ...state[entity][entityId],
                            ...updatedFlags,
                            list,
                        },
                    },
                };
            });
        });
        return _state;
    }

    constructor(private utilitiesService: WdxUtilitiesService) {
        super();
    }
}

export function reducer(state: State | undefined, action: Action) {
    return new EnvelopeReducer(new WdxUtilitiesService()).reducerSetup(
        state,
        action
    );
}
