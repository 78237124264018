import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@wdx/clmi/api-models';
import {
    ICON_DUE,
    ICON_SCHEDULED,
} from '../../../../../constants/icons.constants';
import { ToDoService } from '../../services/to-do/to-do.service';
import { Icon } from '@wdx/shared/utils';

/**
 * Returns the relevant icon associated with an activity date
 */

@Pipe({
    name: 'activityDateIcon',
})
export class ActivityDateIconPipe implements PipeTransform {
    constructor(private toDoService: ToDoService) {}

    transform(activity: Activity): Icon {
        if (this.toDoService.isTaskOrWorkItem(activity)) {
            return ICON_DUE.icon;
        }

        return ICON_SCHEDULED.icon;
    }
}
