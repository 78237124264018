import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomIconButtonModule } from '../../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { AtomModalWrapperModule } from '../../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { OrganismModalModule } from '../../../../components/organisms/organism-modal/organism-modal.module';
import { PushModule } from '@ngrx/component';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PowerbiPortfolioComponent } from './powerbi-portfolio.component';

@NgModule({
    declarations: [PowerbiPortfolioComponent],
    imports: [
        AtomIconButtonModule,
        AtomModalWrapperModule,
        CommonModule,
        OrganismModalModule,
        PowerBIEmbedModule,
        PushModule,
        WdxSpinnerModule,
    ],
    exports: [PowerbiPortfolioComponent],
})
export class PowerbiPortfolioModule {}
