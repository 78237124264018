import { createAction, props } from '@ngrx/store';
import { FileIndex } from '@wdx/shared/utils';

export const uploadFile = createAction(
    '[Files] Upload File',
    props<{
        id: string;
        file: File;
        customEndpoint?: string;
    }>()
);

export const uploadFileCancel = createAction(
    '[Files] Upload File Cancel',
    props<{
        id: string;
    }>()
);

export const uploadFileReset = createAction(
    '[Files] Upload File Reset',
    props<{
        id: string;
    }>()
);

export const uploadFileStarted = createAction(
    '[Files] Upload File Started',
    props<{
        id: string;
    }>()
);

export const uploadFileProgress = createAction(
    '[Files] Upload File Progress',
    props<{
        id: string;
        progress: number;
    }>()
);

export const uploadFileDownloading = createAction(
    '[Files] Upload File Downloading',
    props<{
        id: string;
    }>()
);

export const uploadFileComplete = createAction(
    '[Files] Upload File Complete',
    props<{
        id: string;
        fileIndex: FileIndex;
    }>()
);

export const uploadFileFailure = createAction(
    '[Files] Upload File Failure',
    props<{
        id: string;
        error: string;
    }>()
);

export const noop = createAction('[Files] No Operations');
