import { Action, createReducer, on } from '@ngrx/store';
import { Event, KeyStatResult, Opportunity } from '@wdx/clmi/api-models';
import { CrudStatus } from '@wdx/shared/utils';
import * as eventActions from './event.actions';

export const EventStore = 'event';

export interface State {
    eventData: {
        event: Event | null;
        status: CrudStatus;
        id?: string;
    };
    opportunitiesData: {
        opportunities: Opportunity[] | null;
        status: CrudStatus;
        id?: string;
    };
    keyStatsData: {
        keyStats: KeyStatResult[] | null;
        status: CrudStatus;
        id?: string;
    };
}

export const initialState: State = {
    eventData: {
        event: null,
        status: CrudStatus.Initial,
    },
    opportunitiesData: {
        opportunities: null,
        status: CrudStatus.Initial,
    },
    keyStatsData: {
        keyStats: null,
        status: CrudStatus.Initial,
    },
};

export const reducerSetup = createReducer(
    initialState,

    on(
        eventActions.getEventById,
        (state, props): State => ({
            ...state,
            eventData: {
                event: null,
                id: props.eventId,
                status: CrudStatus.Loading,
            },
        })
    ),

    on(
        eventActions.getEventByIdSuccess,
        (state, props): State => ({
            ...state,
            eventData: {
                event: props.event,
                id: props.eventId,
                status: CrudStatus.Success,
            },
        })
    ),

    on(
        eventActions.getEventByIdFailure,
        (state, props): State => ({
            ...state,
            eventData: {
                event: null,
                id: props.eventId,
                status: CrudStatus.Error,
            },
        })
    ),

    on(
        eventActions.getOpportunitiesById,
        (state, props): State => ({
            ...state,
            opportunitiesData: {
                opportunities: null,
                id: props.eventId,
                status: CrudStatus.Loading,
            },
        })
    ),

    on(
        eventActions.getOpportunitiesByIdSuccess,
        (state, props): State => ({
            ...state,
            opportunitiesData: {
                opportunities: props.opportunities,
                id: props.eventId,
                status: CrudStatus.Success,
            },
        })
    ),

    on(
        eventActions.getOpportunitiesByIdFailure,
        (state, props): State => ({
            ...state,
            opportunitiesData: {
                opportunities: null,
                id: props.eventId,
                status: CrudStatus.Error,
            },
        })
    ),

    on(
        eventActions.getKeyStats,
        (state, props): State => ({
            ...state,
            keyStatsData: {
                keyStats: null,
                id: props.eventId,
                status: CrudStatus.Loading,
            },
        })
    ),

    on(
        eventActions.getKeyStatsSuccess,
        (state, props): State => ({
            ...state,
            keyStatsData: {
                keyStats: props.keyStats,
                id: props.eventId,
                status: CrudStatus.Success,
            },
        })
    ),

    on(
        eventActions.getKeyStatsFailure,
        (state, props): State => ({
            ...state,
            keyStatsData: {
                keyStats: null,
                id: props.eventId,
                status: CrudStatus.Error,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
