import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as amlActions from './aml.actions';
import { AmlService } from './aml.service';

@Injectable()
export class AmlEffects {
    private amlService = inject(AmlService);
    private actions$ = inject(Actions);

    getAllForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(amlActions.getAllForId),
            switchMap((action) =>
                this.amlService.getAllForId(action.id).pipe(
                    map((reports) =>
                        amlActions.getAllForIdSuccess({
                            id: action.id,
                            reports,
                        })
                    ),
                    catchError((error) =>
                        of(
                            amlActions.getAllForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getLatestForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(amlActions.getLatestForId),
            switchMap((action) =>
                this.amlService.getLatestForId(action.id).pipe(
                    map((report) =>
                        amlActions.getLatestForIdSuccess({
                            id: action.id,
                            report,
                        })
                    ),
                    catchError((error) =>
                        of(
                            amlActions.getLatestForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    patchNewCheckForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(amlActions.patchNewCheckForId),
            switchMap((action) =>
                this.amlService.patchNewCheckForId(action.id).pipe(
                    map(() =>
                        amlActions.patchNewCheckForIdSuccess({
                            id: action.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            amlActions.patchNewCheckForIdFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
