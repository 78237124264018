import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxInfoCardComponent } from './wdx-info-card/wdx-info-card.component';

@NgModule({
    declarations: [WdxInfoCardComponent],
    imports: [CommonModule],
    exports: [WdxInfoCardComponent],
})
export class WdxInfoCardModule {}
