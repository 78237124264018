<div class="welcome-container">
    <div *ngIf="loading" class="throbber throbber--welcome absolute-center">
        <div></div>
        <div></div>
    </div>
    <img
        hidden="true"
        [src]="'assets/images/login/' + imageNum + '-min.jpg'"
        (load)="onImageLoaded()"
    />
    <ng-container>
        <div
            *ngIf="!loading"
            class="welcome welcome--{{ imageNum }}"
            [@fadein]
        ></div>
        <div
            class="welcome__logo-and-button"
            [@fadeinlogo]="loading"
            [hidden]="loading"
        >
            <img
                class="welcome__logo"
                src="assets/images/logos/CLMi_pos_rgb+strap.png"
                alt="CLMi logo with strap line"
                (load)="onImageLoaded()"
            />
            <ng-container *ngIf="error" class="mb-4">
                <div class="mb-4 text-center">
                    <p class="fw-bold">There was an error accessing CLMi</p>
                    <p class="fw-bold">
                        Please contact your system administrator
                    </p>
                    <div
                        *ngFor="let subMessage of error"
                        class="fw-normal mb-1"
                    >
                        {{ subMessage }}
                    </div>
                </div>
                <button
                    *ngIf="!loading"
                    class="welcome__btn btn btn-primary d-flex align-items-center justify-content-between btn-lg"
                    data-cy="action-login"
                    [@fadeinbutton]
                    (click)="logout()"
                >
                    <div>Log Out</div>
                    <i class="fal fa-arrow-right"></i></button
            ></ng-container>
            <button
                *ngIf="!loading && !error"
                class="welcome__btn btn btn-primary d-flex align-items-center justify-content-between btn-lg"
                data-cy="action-login"
                [@fadeinbutton]
                (click)="login()"
            >
                <div>Log In</div>
                <i class="fal fa-arrow-right"></i>
            </button>
        </div>
        <img
            class="welcome__logo--wdx"
            src="assets/images/logos/wealth-dynamix-logo-rgb.png"
            alt="Wealth Dynamix logo"
            [@fadeinlogo]="loading"
            [hidden]="loading"
            (load)="onImageLoaded()"
        />
    </ng-container>
</div>
