import { Organisation, OrganisationCreate } from '@wdx/clmi/api-models';
import { createAction, props } from '@ngrx/store';

export const getForParty = createAction(
    '[Organisations] Get For Party',
    props<{
        partyId: string;
    }>()
);

export const getForPartySuccess = createAction(
    '[Organisations] Get For Party Success',
    props<{
        partyId: string;
        organisation: Organisation;
    }>()
);

export const getForPartyFailure = createAction(
    '[Organisations] Get For Party Failure',
    props<{
        partyId: string;
        error: Error;
    }>()
);

export const create = createAction(
    '[Organisations] Create',
    props<{
        organisationCreateData: OrganisationCreate;
    }>()
);

export const createSuccess = createAction(
    '[Organisations] Create Success',
    props<{
        organisation: OrganisationCreate;
    }>()
);

export const createFailure = createAction(
    '[Organisations] Create Failure',
    props<{
        error: Error;
    }>()
);
