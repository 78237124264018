import { Component, Input } from '@angular/core';
import { TileMini } from '../../../classes/tile.class';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-tile-mini',
    templateUrl: './molecule-tile-mini.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'tile tile--mini' },
})
export class MoleculeTileMiniComponent {
    @Input() tile: TileMini;
}
