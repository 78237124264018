export enum CubeInterval {
    Decade = 'Decade',
    Year = 'Year',
    Quarter = 'Quarter',
    Month = 'Month',
    Week = 'Week',
    Tens = '10',
    Twenties = '20',
    Fifties = '50',
    Hundreds = '100',
    Thousands = '1000',
    Millions = '1000000',
}
