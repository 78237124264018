import { Injectable } from '@angular/core';
import { EntityOperations, SystemEntity } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';

@Injectable({
    providedIn: 'root',
})
export class OperationsService extends BaseApiService {
    getOperationsForEntity(
        entityType: SystemEntity
    ): Observable<EntityOperations> {
        return this.get<EntityOperations>(
            `${entityType?.toLowerCase()}/operation`
        );
    }

    getOperationsForId(
        entityType: SystemEntity,
        entityId: string
    ): Observable<EntityOperations> {
        return this.get<EntityOperations>(
            `${entityType?.toLowerCase()}/${entityId}/operation`
        );
    }

    patchActionWithURL(
        entityType: SystemEntity,
        entityId: string,
        url: string
    ): Observable<any> {
        return this.patch(
            `${entityType?.toLowerCase()}/${entityId}/action/${url}`,
            null
        );
    }
}
