<div
    class="search-bar navbar bg-body text-body"
    [attr.data-cy]="'search-bar-type-' + searchType"
    [class.search-bar]="showSearchBar"
>
    <div class="container-fluid">
        <ng-content select="[prefixActionButtons]"></ng-content>
        <div
            *ngIf="searchType"
            class="search-bar__prefix d-flex align-items-center me-3"
        >
            <h5 class="search-bar__count mb-0 no-wrap">
                <span data-cy="lbl-search-count">{{
                    totalRecordCount | fn : searchCountLabel : isLoading
                }}</span
                ><span class="d-none d-lg-inline fw-normal"
                    >&nbsp;{{
                        searchType
                            | pluralString : totalRecordCount : searchTypePlural
                    }}</span
                >
            </h5>
            <ng-content select=".legend"></ng-content>
        </div>
        <div class="d-flex align-items-center position-relative flex-grow-1">
            <div
                class="search-bar__search-input flex-grow-1"
                [class.show-on-mobile]="showMobileSearch"
            >
                <ng-container *ngIf="showSearchBar">
                    <molecule-input-search
                        class="search-bar__search-input__input"
                        cySelector="input-search-bar"
                        [formInputData]="searchFormInputData"
                        [initialValue]="inputSearchText"
                        (valueChanged)="onSearch($event)"
                    ></molecule-input-search>
                    <atom-close
                        class="search-bar__search-input__close ms-2"
                        btnClass="btn"
                        (click)="toggleMobileSearch()"
                    ></atom-close>
                </ng-container>
            </div>

            <div class="divider-start d-block d-lg-none"></div>
            <div class="action-buttons-wrapper">
                <div
                    class="action-buttons d-flex align-items-center"
                    [class.hide-on-mobile]="showMobileSearch"
                >
                    <div class="search-bar__search-button">
                        <atom-icon-button
                            btnClass="btn-ghost"
                            [icon]="
                                inputSearchText
                                    ? ICON_SEARCH_ACTIVE
                                    : ICON_SEARCH
                            "
                            (click)="toggleMobileSearch()"
                        ></atom-icon-button>
                    </div>
                    <div
                        *ngIf="sortMenu?.length"
                        class="search-bar__sort-button"
                    >
                        <molecule-action-button
                            btnClass="btn-ghost"
                            [actionButton]="
                                filterHandler | fn : getSortButton : sortMenu
                            "
                            [dropdownPlacement]="
                                ACTION_BUTTON_DROPDOWN_PLACEMENT
                            "
                            (selectMenuItem)="onSort($event)"
                        ></molecule-action-button>
                    </div>
                    <div
                        *ngIf="
                            (filterHandler && filterFormId) || hasMobileFilter
                        "
                        class="search-bar__filter-button ms-2"
                        [class.always-show]="alwaysShowFilters"
                    >
                        <atom-icon-button
                            btnClass="btn"
                            [icon]="
                                filterHandler.isDirty
                                    ? ICON_FILTER_ACTIVE
                                    : ICON_FILTER
                            "
                            [modalId]="filterModalId"
                        ></atom-icon-button>
                    </div>
                    <ng-content select="[actionButtons]"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>
