import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as importEntitiesReducer from './import-entities.reducer';

export const selectImportEntitiesSlice =
    createFeatureSelector<importEntitiesReducer.State>(
        importEntitiesReducer.ImportEntitiesStore
    );

export const getImportEntitiesSlice = createSelector(
    selectImportEntitiesSlice,
    (state) => state
);

export const getImportEntitiesIsLoading = () =>
    createSelector(
        getImportEntitiesSlice,
        (state: importEntitiesReducer.State) => state?.imports?.isLoadingList
    );

export const getImportEntitiesHasLoadingError = () =>
    createSelector(
        getImportEntitiesSlice,
        (state: importEntitiesReducer.State) =>
            state?.imports?.hasLoadingListError
    );

export const getImportEntities = () =>
    createSelector(
        getImportEntitiesSlice,
        (state: importEntitiesReducer.State) => state?.imports?.list
    );
