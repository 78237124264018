import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ControlContainer,
    FormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

export interface WdxMultiSelectOptions {
    label: string;
    value: any;
}

@Component({
    selector: 'wdx-multi-select',
    templateUrl: './wdx-multi-select.component.html',
    styleUrls: ['./wdx-multi-select.component.scss'],
    standalone: true,
    imports: [CommonModule, NgSelectModule, ReactiveFormsModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxMultiSelectComponent {
    @Input() formControlNameOrIndex?: string | number;
    @Input() multiple = false;
    @Input() options: WdxMultiSelectOptions[] = [];
    @Input() placeholder = 'Select';
    @Input() searchable = true;

    public controlContainer: { control: FormGroup<any> };

    constructor(_controlContainer: ControlContainer) {
        this.controlContainer = _controlContainer as any;
    }
}
