import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { Nudge, NudgeCreate } from '@wdx/clmi/api-models';

@Injectable()
export class NudgesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    create(nudgeCreateData: NudgeCreate): Observable<NudgeCreate> {
        return this.http.post<NudgeCreate>(
            `${this.config.getConfiguration().API_BASE}/nudge`,
            nudgeCreateData
        );
    }

    delete(nudgeId: string): Observable<any> {
        return this.http.delete<any>(
            `${this.config.getConfiguration().API_BASE}/nudge/${nudgeId}`
        );
    }

    dismiss(nudgeId: string): Observable<Nudge> {
        return this.http.patch<Nudge>(
            `${
                this.config.getConfiguration().API_BASE
            }/nudge/${nudgeId}/dismiss`,
            {}
        );
    }
}
