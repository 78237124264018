import { Action, createReducer, on } from '@ngrx/store';
import { MatchedDuplicate } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as duplicatesActions from './duplicates.actions';

export interface State {
    duplicates?: CrudStateObject<MatchedDuplicate>;
    duplicateChecks?: CrudStateObject<MatchedDuplicate>;
}

export const initialState: State = {
    duplicates: {},
    duplicateChecks: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        duplicatesActions.getDuplicates,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.entity]: {
                    ...(state.duplicates[props.entity] ||
                        ({} as CrudState<MatchedDuplicate>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.duplicates[props.entity]?.infinity,
                },
            },
        })
    ),

    on(
        duplicatesActions.getDuplicatesSuccess,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.entity]: {
                    ...state.duplicates[props.entity],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.duplicates.paging,
                        combinedList: [
                            ...(state.duplicates[props.entity].infinity
                                ?.combinedList || []),
                            ...props.duplicates.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        duplicatesActions.getDuplicatesFailure,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.entity]: {
                    ...state.duplicates[props.entity],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        duplicatesActions.runDuplicateCheck,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.entity]: {
                    ...(state.duplicates[props.entity] ||
                        ({} as CrudState<MatchedDuplicate>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        duplicatesActions.runDuplicateCheckSuccess,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.entity]: {
                    ...state.duplicates[props.entity],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        duplicatesActions.runDuplicateCheckFailure,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.entity]: {
                    ...state.duplicates[props.entity],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        duplicatesActions.markNotDuplicates,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.duplicateId]: {
                    ...(state.duplicates[props.duplicateId] ||
                        ({} as CrudState<MatchedDuplicate>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        })
    ),

    on(
        duplicatesActions.markNotDuplicatesSuccess,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.duplicateId]: {
                    ...state.duplicates[props.duplicateId],
                    isDeleting: false,
                    hasDeletingError: false,
                },
            },
        })
    ),

    on(
        duplicatesActions.markNotDuplicatesFailure,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.duplicateId]: {
                    ...state.duplicates[props.duplicateId],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        })
    ),

    on(
        duplicatesActions.mergeDuplicates,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.duplicateId]: {
                    ...(state.duplicates[props.duplicateId] ||
                        ({} as CrudState<MatchedDuplicate>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        duplicatesActions.mergeDuplicatesSuccess,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.duplicateId]: {
                    ...state.duplicates[props.duplicateId],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        duplicatesActions.mergeDuplicatesFailure,
        (state, props): State => ({
            ...state,
            duplicates: {
                ...state.duplicates,
                [props.duplicateId]: {
                    ...state.duplicates[props.duplicateId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
