import { createSelector } from '@ngrx/store';
import * as duplicatesReducer from './duplicates.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    duplicates: duplicatesReducer.State;
}): duplicatesReducer.State => state.duplicates;

export const getDuplicatesIsLoadingPage = createSelector(
    getState,
    (state: duplicatesReducer.State, props: any) =>
        state.duplicates[props.entity]?.isLoadingPage
);
export const getDuplicatesHasLoadingPageError = createSelector(
    getState,
    (state: duplicatesReducer.State, props: any) =>
        state.duplicates[props.entity]?.hasLoadingPageError
);
export const getDuplicatesInfinityPaging = createSelector(
    getState,
    (state: duplicatesReducer.State, props: any) =>
        state.duplicates[props.entity]?.infinity?.paging
);
export const getDuplicatesInfinityCombinedList = createSelector(
    getState,
    (state: duplicatesReducer.State, props: any) =>
        state.duplicates[props.entity]?.infinity?.combinedList
);

export const getDuplicateIsRunningCheck = createSelector(
    getState,
    (state: duplicatesReducer.State, props: any) =>
        state.duplicates[props.entity]?.isLoadingSingle
);
export const getDuplicateHasRunningCheckError = createSelector(
    getState,
    (state: duplicatesReducer.State, props: any) =>
        state.duplicates[props.entity]?.hasLoadingSingleError
);

export const getDuplicateIsMarkingNotDuplicate = createSelector(
    getState,
    (state: duplicatesReducer.State) =>
        Object.keys(state.duplicates).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.duplicates[id].isDeleting,
            }),
            {}
        )
);

export const getDuplicateHasMarkingNotDuplicateError = createSelector(
    getState,
    (state: duplicatesReducer.State) =>
        Object.keys(state.duplicates).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.duplicates[id].hasDeletingError,
            }),
            {}
        )
);

export const getDuplicateIsMergingDuplicates = createSelector(
    getState,
    (state: duplicatesReducer.State) =>
        Object.keys(state.duplicates).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.duplicates[id].isUpdating,
            }),
            {}
        )
);

export const getDuplicateHasMergingDuplicatesError = createSelector(
    getState,
    (state: duplicatesReducer.State) =>
        Object.keys(state.duplicates).reduce(
            (accumulator: { [key: string]: boolean }, id: string) => ({
                ...accumulator,
                [id]: state.duplicates[id].hasUpdatingError,
            }),
            {}
        )
);

/**
 * Reducer property selectors
 */
