<button
    class="btn d-flex align-items-center {{ btnClass }}"
    triggers="mouseenter:mouseleave"
    container="body"
    popoverClass="popover-icon-button"
    [attr.data-cy]="cySelector"
    [openDelay]="POPOVER_OPEN_DELAY"
    [placement]="['top', 'bottom', 'left', 'right']"
    [disabled]="disabled"
    [ngbPopover]="altText"
    [disablePopover]="!altText"
>
    <ng-content></ng-content>
    <i *ngIf="!hideDropdownChevron" class="fal fa-chevron-down"></i>
</button>
