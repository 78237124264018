<molecule-engagements-dumb
    [links]="links"
    [likes]="likes"
    [showText]="showText"
    [hasInfoModal]="hasInfoModal"
    [infoModalTitle]="infoModalTitle"
    [linkedActivities]="linkedActivities$ | ngrxPush"
    [linkedActivitiesIsLoading]="linkedActivitiesIsLoading$ | ngrxPush"
    [linkedActivitiesHasError]="linkedActivitiesHasError$ | ngrxPush"
    [linkedCases]="linkedCases$ | ngrxPush"
    [linkedCasesIsLoading]="linkedCasesIsLoading$ | ngrxPush"
    [linkedCasesHasError]="linkedCasesHasError$ | ngrxPush"
    [likedBy]="likedBy$ | ngrxPush"
    [likedByIsLoading]="likedByIsLoading$ | ngrxPush"
    [likedByHasError]="likedByHasError$ | ngrxPush"
    (getLinksAndLikes)="onGetLinksAndLikes()"
></molecule-engagements-dumb>
