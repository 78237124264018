import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormDataHistory, IGetConfig } from '@wdx/shared/utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FormHistoryService {
    constructor(private http: HttpClient, private config: IGetConfig) {}

    getHistory(
        formId: string,
        entityId: string
    ): Observable<FormDataHistory[]> {
        return this.http.get<FormDataHistory[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/${formId}/${entityId}/history`
        );
    }
}
