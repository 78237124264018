<ng-container *ngIf="listOnly; else searchList">
    <ng-container *ngTemplateOutlet="infoCardList"></ng-container>
</ng-container>

<ng-template #searchList>
    <div class="d-flex flex-column overflow-hidden h-100">
        <molecule-search-and-filter-bar
            *ngIf="showSearchBar"
            class="flex-shrink-0"
            [isLoading]="isLoading"
            [searchType]="searchType"
            [searchTypePlural]="searchTypePlural"
            [totalRecordCount]="totalRecordCount"
            [inputSearchText]="inputSearchText"
            (search)="onSearch($event)"
        >
            <ng-container actionButtons *ngIf="createNewActionButton">
                <molecule-action-button
                    btnClass="btn-primary"
                    [actionButton]="createNewActionButton"
                ></molecule-action-button>
            </ng-container>
        </molecule-search-and-filter-bar>

        <ng-content select=".extended-info-legend"></ng-content>

        <div
            class="container-fluid flex-grow-1 overflow-auto"
            clmiInfiniteScroll
            (scrolled)="onInfinityScrollFired()"
        >
            <ng-container *ngTemplateOutlet="infoCardList"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #infoCardList>
    <div class="list-group list-group-flush">
        <button
            *ngFor="let extendedInfoCard of extendedInfoCards"
            class="list-group-item list-group-item-action"
            type="button"
        >
            <molecule-info-card-extended
                class="d-block"
                [extendedInfoCard]="extendedInfoCard"
                [showButtons]="showButtons"
                [buttons]="buttons"
                [icon]="icon"
                [routerLink]="extendedInfoCard.infoCard?.routerLink"
                (toggleFavourite)="
                    onToggleFavourite($event, extendedInfoCard.infoCard.data.id)
                "
            ></molecule-info-card-extended>
        </button>
    </div>
</ng-template>
