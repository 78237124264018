<div class="related-records grid">
    <clmi-related-cases
        *ngIf="showCases"
        class="g-col-12"
        [isInactiveRecord]="isInactiveRecord"
        [entityId]="entityId"
        [entityType]="entityType"
        [showChildCases]="showChildCases"
        [showRelatedCases]="showRelatedCases"
        [showSuggestedCases]="showSuggestedCases"
        [cardsPerRow]="cardsPerRow"
    ></clmi-related-cases>

    <clmi-related-opportunities
        *ngIf="showOpportunities"
        class="g-col-12"
        [entityId]="entityId"
        [entityType]="entityType"
        [cardsPerRow]="cardsPerRow"
        [isInactiveRecord]="isInactiveRecord"
    ></clmi-related-opportunities>

    <clmi-related-articles
        *ngIf="(FeatureFlag.Articles | hasFeature) && showArticles"
        class="g-col-12"
        [entityId]="entityId"
        [entityType]="entityType"
        [cardsPerRow]="cardsPerRow"
        [isInactiveRecord]="isInactiveRecord"
    ></clmi-related-articles>

    <clmi-related-activities
        *ngIf="showActivities"
        class="g-col-12"
        [entityId]="entityId"
        [entityType]="entityType"
        [cardsPerRow]="cardsPerRow"
        [isInactiveRecord]="isInactiveRecord"
    ></clmi-related-activities>

    <ng-content></ng-content>
</div>
