import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: Error) {
        // eslint-disable-next-line no-console
        console.error(error);
        Sentry.captureException(error);

        // reload page when failure to load a chunk (due to new deployment)
        // https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error?.message)) {
            window.location.reload();
        }
    }
}
