import { createSelector } from '@ngrx/store';
import * as fileUploadReducer from './avatar-profile-file-upload.reducer';

/**
 * Reducer state selector
 */
// eslint-disable-next-line max-len
export const getState = (state: {
    avatarProfileFileUpload: fileUploadReducer.State;
}): fileUploadReducer.State => state.avatarProfileFileUpload;

/**
 * Reducer property selectors
 */
export const getFileUploadStatus = createSelector(
    getState,
    (state: fileUploadReducer.State, props: any) =>
        state.avatarProfileUploads[props.id]?.uploadStatus
);
export const getFileUploadProgress = createSelector(
    getState,
    (state: fileUploadReducer.State, props: any) =>
        state.avatarProfileUploads[props.id]?.progress
);
export const getFileUploadFileIndex = createSelector(
    getState,
    (state: fileUploadReducer.State, props: any) =>
        state.avatarProfileUploads[props.id]?.fileIndex
);
export const getFileUploadError = createSelector(
    getState,
    (state: fileUploadReducer.State, props: any) =>
        state.avatarProfileUploads[props.id]?.error
);
