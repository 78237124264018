import { createSelector } from '@ngrx/store';
import * as globalReducer from './global.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    global: globalReducer.State;
}): globalReducer.State => state.global;

export const getAppIsInitialised = createSelector(
    getState,
    (state: globalReducer.State) => state.appIsInitialised
);
