import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FilterQueryService } from '../../../shared/features/form-filters';

@Component({
    selector: 'clmi-view-shell',
    templateUrl: './view-shell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewShellComponent {
    @Input() isWidgetView = false;

    constructor(public filterQueryService: FilterQueryService) {}
}
