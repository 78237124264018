import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { UTILS_ICON_FAVOURITE } from '@wdx/shared/utils';
import { CommonModule } from '@angular/common';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import {
    WdxIconButtonModule,
    WdxIconButtonSize,
} from '@wdx/shared/components/wdx-icon-button';

@Component({
    selector: 'wdx-toggle-button',
    templateUrl: './wdx-toggle-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, WdxIconButtonModule, WdxIconModule],
})
export class WdxToggleButtonComponent {
    @Input() toggle?: boolean;
    @Input() iconOn: string = UTILS_ICON_FAVOURITE;
    @Input() iconOff: string = UTILS_ICON_FAVOURITE;
    @Input() btnClassOn = 'btn-primary';
    @Input() btnClassOff = 'btn-outline-primary';
    @Input() altTextOn?: string;
    @Input() altTextOff?: string;
    @Input() size: WdxIconButtonSize = 'md';
    @Input() isDisabled = false;
    @Input() isLocked = false;
    @Input() set resetButtonsFlag(value: boolean) {
        if (value) {
            this.toggle = false;
        }
    }
    @Input() cySelector?: string;

    @Output() wdxOnChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    onToggle($event: MouseEvent): void {
        $event.stopPropagation();
        if (!this.isLocked) {
            this.toggle = !this.toggle;
            this.wdxOnChange.emit(this.toggle);
        }
    }
}
