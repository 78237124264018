import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EventService } from '@wdx/clmi/api-services/services';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import * as eventActions from './event.actions';

@Injectable()
export class EventEffects {
    private actions$ = inject(Actions);
    private eventService = inject(EventService);

    getEventById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(eventActions.getEventById),
            mergeMap((action) =>
                this.eventService.getEventById(action.eventId).pipe(
                    map((event) =>
                        eventActions.getEventByIdSuccess({
                            event: event,
                            eventId: action.eventId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            eventActions.getEventByIdFailure({
                                eventId: action.eventId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getOpportunitiesById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(eventActions.getOpportunitiesById),
            switchMap((action) =>
                this.eventService.getOpportunitiesById(action.eventId).pipe(
                    map((opportunities) =>
                        eventActions.getOpportunitiesByIdSuccess({
                            eventId: action.eventId,
                            opportunities,
                        })
                    ),
                    catchError((error) =>
                        of(
                            eventActions.getOpportunitiesByIdFailure({
                                eventId: action.eventId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getKeyStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(eventActions.getKeyStats),
            switchMap((action) =>
                this.eventService.getKeyStats(action.eventId).pipe(
                    map((keyStats) =>
                        eventActions.getKeyStatsSuccess({
                            eventId: action.eventId,
                            keyStats,
                        })
                    ),
                    catchError((error) =>
                        of(
                            eventActions.getKeyStatsFailure({
                                eventId: action.eventId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
