export function compareOptionValue(optionValue: any, fieldValue: any): boolean {
    if (
        optionValue != null &&
        typeof optionValue.valueOf() === 'string' &&
        typeof fieldValue === 'string'
    ) {
        return optionValue.toLowerCase() === fieldValue.toLowerCase();
    }
    return optionValue === fieldValue;
}
