import { SystemEntity } from '@wdx/clmi/api-models';

export const CHARTS_ENTITY_ICON_MAP: Record<string, string> = {
    [SystemEntity.Activity]: 'tasks',
    [SystemEntity.Case]: 'suitcase',
    [SystemEntity.Campaign]: 'megaphone',
    [SystemEntity.CampaignResponse]: 'megaphone',
    [SystemEntity.Client]: 'user',
    [SystemEntity.ClientProduct]: 'box-open',
    [SystemEntity.Feed]: 'rss',
    [SystemEntity.Lead]: 'funnel-dollar',
    [SystemEntity.Nudge]: 'rss',
    [SystemEntity.Opportunity]: 'funnel-dollar',
    [SystemEntity.Organisation]: 'building',
    [SystemEntity.Person]: 'user',
    [SystemEntity.Task]: 'tasks',
    [SystemEntity.WorkItem]: 'tasks',
};
