import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { EntityActionOption } from '@wdx/clmi/api-models';
import { WdxButtonStyle } from '@wdx/shared/components/wdx-button';
import { ModalManagementService } from '../../../../../services/modal-management.service';
import { MoleculeActionButtonService } from '../services/molecule-action-button.service';

@Component({
    selector: 'clmi-actions-modal',
    templateUrl: './actions-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsModalComponent {
    public moleculeActionButtonService = inject(MoleculeActionButtonService);
    public modalManagementService = inject(ModalManagementService);

    selectedItem: EntityActionOption;

    readonly WDX_BUTTON_STYLE = WdxButtonStyle;

    optionSelected(): void {
        const info = this.moleculeActionButtonService.actions$.value;

        this.moleculeActionButtonService.dispatchActionWithUrl(
            info.operationsSetup,
            info.action,
            this.selectedItem.code
        );
    }

    updateSelectedItem(entityActionOption: EntityActionOption): void {
        this.selectedItem = entityActionOption;
    }
}
