import { Injectable, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormFieldType, Party, PartyStub } from '@wdx/clmi/api-models';
import {
    IWrappedAvatarComponentConfig,
    IWrappedFormComponentConfig,
    IWrappedFormComponentProvider,
    ReactiveFormLayoutAndDefinition,
} from '@wdx/shared/infrastructure/form-framework';
import { Observable, map, of } from 'rxjs';
import { MoleculeAvatarComponent } from '../../../../components/molecules/molecule-avatar/molecule-avatar.component';
import { MoleculeReactiveInputLookupComponent } from '../../../../components/molecules/molecule-reactive-controls/_molecule-input-lookup/molecule-reactive-input-lookup.component';
import { MoleculeReactiveInputTagsComponent } from '../../../../components/molecules/molecule-reactive-controls/_molecule-input-tags/molecule-reactive-input-tags.component';
import { ReactiveFormDynamicDataService } from '../../../../shared/services';

@Injectable()
export class WrappedFormControlService
    implements IWrappedFormComponentProvider
{
    public readonly hideErrorMessages = true;

    private dynamicDataService = inject(ReactiveFormDynamicDataService);

    getComponent$(
        formElement: ReactiveFormLayoutAndDefinition,
        formContext: any,
        formData: UntypedFormGroup
    ): Observable<IWrappedFormComponentConfig> {
        if (formElement.tagCategory) {
            return this.dynamicDataService
                .getTagsForCategory(formElement.tagCategory)
                .pipe(
                    map((tags) => ({
                        component: MoleculeReactiveInputTagsComponent,
                        inputs: {
                            isMulti:
                                formElement.fieldType ===
                                FormFieldType.TagListMulti,
                            formInputData: formElement,
                            tags,
                        },
                    }))
                );
        }

        return of({
            component: MoleculeReactiveInputLookupComponent,
            inputs: {
                isMulti: formElement.fieldType === FormFieldType.LookupMulti,
                formInputData: formElement,
                formData,
                formContext: formContext,
            },
        });
    }

    getAvatarComponent$(
        data: Party | PartyStub
    ): Observable<IWrappedAvatarComponentConfig> {
        return of({
            component: MoleculeAvatarComponent,
            inputs: {
                avatar: {
                    text: data.name,
                    avatarUrl: data.avatarUrl,
                    partyId: data.id,
                    partyType: data.partyType,
                    isAvatarInfoEnabled: true,
                },
            },
        });
    }
}
