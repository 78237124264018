import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslatePipe } from '@wdx/shared/utils';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeWidgetContainerModule } from '../../../components/molecules/molecule-widget-container/molecule-widget-container.module';
import { OrganismCardsListModule } from '../../../components/organisms/organism-cards-list/organism-cards-list.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { OrganismOpportunitiesListModule } from '../../../components/organisms/organism-opportunities-list/organism-opportunities-list.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { WidgetOpportunitiesListComponent } from './widget-opportunities-list.component';

@NgModule({
    imports: [
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        MoleculeWidgetContainerModule,
        OrganismCardsListModule,
        OrganismModalModule,
        OrganismOpportunitiesListModule,
        PipesModule,
        TranslatePipe,
    ],
    declarations: [WidgetOpportunitiesListComponent],
    exports: [WidgetOpportunitiesListComponent],
})
export class WidgetOpportunitiesListModule {}
