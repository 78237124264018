import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as rootReducer from '../../state/_setup/reducers';
import * as leadsActions from './leads.actions';
import { LeadsService } from './leads.service';

@Injectable()
export class LeadsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private leadsService = inject(LeadsService);

    getLead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(leadsActions.getLead),
            switchMap((action) =>
                this.leadsService.getLead(action.leadId).pipe(
                    map((lead) => leadsActions.getLeadSuccess({ lead })),
                    catchError((error) =>
                        of(
                            leadsActions.getLeadFailure({
                                leadId: action.leadId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getFavourites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(leadsActions.getFavourites),
            switchMap(() =>
                this.leadsService.getFavourites().pipe(
                    map((leads) =>
                        leadsActions.getFavouritesSuccess({ favourites: leads })
                    ),
                    catchError((error) =>
                        of(leadsActions.getFavouritesFailure({ error }))
                    )
                )
            )
        )
    );
}
