import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    QueryExpression,
    SystemEntity,
    View,
    ViewType,
} from '@wdx/clmi/api-models';
import { LocalStorageKey, LocalStorageService } from '@wdx/shared/utils';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import * as rootReducer from '../../../../../../state/_setup/reducers';
import * as viewsActions from '../../../../../../state/views/views.actions';
import * as viewsSelectors from '../../../../../../state/views/views.selectors';

@Injectable({
    providedIn: 'root',
})
export class FilterViewFacadeService {
    viewsSystem$ = new BehaviorSubject<View[]>(null);
    viewsPersonal$ = new BehaviorSubject<View[]>(null);
    views$ = new BehaviorSubject<View[]>(null);
    viewsIsLoading$: Observable<boolean>;
    viewsHasError$: Observable<boolean>;

    constructor(
        private store$: Store<rootReducer.State>,
        private localStorageService: LocalStorageService
    ) {}

    getViewsForEntityType$(
        viewType: SystemEntity,
        contextual?: QueryExpression[]
    ): Observable<View[]> {
        return this.store$
            .select(viewsSelectors.getList, {
                entityType: viewType,
            })
            .pipe(
                tap((res) => {
                    if (!res) {
                        this.queryViewForEntityType(viewType);
                        this.queryViewIsLoading(viewType);
                        this.queryViewHasError(viewType);
                    }
                }),
                filter((res) => Boolean(res)),
                map((data) => {
                    const UPDATED_DATA = this.setTypeOfViews(data, contextual);
                    this.views$.next(UPDATED_DATA);

                    return UPDATED_DATA;
                })
            );
    }

    setTypeOfViews(data: View[], contextual?: QueryExpression[]): View[] {
        const views = data?.length ? [...data] : [{} as View];
        const system = [];
        const personal = [];
        const VIEWS = [];
        const CONTEXTUAL = Array.isArray(contextual) ? contextual : [];

        views?.map((view) => {
            const FILTER = { ...view?.filter };
            const EXPRESSIONS = Array.isArray(FILTER?.expressions)
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  [...FILTER?.expressions]
                : [];
            const UPDATED_VIEW = {
                ...view,
                filter: {
                    ...FILTER,
                    expressions: [...EXPRESSIONS, ...CONTEXTUAL],
                },
            };

            VIEWS.push(UPDATED_VIEW);

            if (view.type === ViewType.System) {
                system.push(UPDATED_VIEW);
            }

            if (view.type === ViewType.Personal) {
                personal.push(UPDATED_VIEW);
            }
        });

        this.viewsSystem$.next(system);
        this.viewsPersonal$.next(personal);

        return VIEWS;
    }

    queryViewForEntityType(entityType: SystemEntity): void {
        this.store$.dispatch(viewsActions.getForEntityType({ entityType }));
    }

    queryViewIsLoading(entityType: SystemEntity): void {
        this.viewsIsLoading$ = this.store$.select(
            viewsSelectors.getIsLoadingList,
            { entityType }
        );
    }

    queryViewHasError(entityType: SystemEntity): void {
        this.viewsHasError$ = this.store$.select(
            viewsSelectors.getHasLoadingListError,
            { entityType }
        );
    }

    saveView(viewType: SystemEntity, view: View): void {
        if (!view.id) {
            this.store$.dispatch(
                viewsActions.createByEntityType({
                    entityType: viewType,
                    view,
                })
            );
        }

        if (view.id) {
            this.store$.dispatch(
                viewsActions.updateByEntityType({
                    entityType: viewType,
                    viewId: view.id,
                    view,
                })
            );
        }
    }

    getSavedOrUpdatedView$(): Observable<View> {
        return this.store$.select(viewsSelectors.getCurrentView);
    }

    getSelectedView$(
        views: View[],
        data: any,
        queryParam?: string
    ): Observable<View> {
        let view = views[0];

        const QUERY_PARAM = queryParam;

        let selected = this.localStorageService.getStringKey(
            `${LocalStorageKey.DefaultViewId}-${data.viewType}`
        );

        if (QUERY_PARAM) {
            selected = QUERY_PARAM;
            this.setLocalStorage(data.viewType, QUERY_PARAM);
        }

        if (!selected && view) {
            this.setLocalStorage(data.viewType, view.id);

            return of(view);
        }

        if (!view && data) {
            console.log(data);
        }

        from(views).subscribe((res: View) => {
            if (res.id === selected) {
                view = res;
            }
        });

        return of(view);
    }

    setLocalStorage(viewType: string, id: string) {
        this.localStorageService.setStringKey(
            `${LocalStorageKey.DefaultViewId}-${viewType}`,
            id
        );
    }
}
