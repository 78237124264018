import { Directive } from '@angular/core';
import { ClientLifecycleStage } from '@wdx/clmi/api-models';
import { Avatar } from '@wdx/clmi/utils/models';
import { Icon, WdxThemeColor } from '@wdx/shared/utils';
import { RoutingLink } from '../models/routing-link.model';
import {
    ITile,
    ITileAvatar,
    ITileInfo,
    ITileMini,
    ITileValue,
    TileType,
} from '../models/tile.model';

@Directive()
export class Tile {
    type = TileType.Default;
    value: string;
    icon: Icon | string;
    data: any;
    routingLink?: RoutingLink;

    constructor(tile: ITile) {
        this.value = tile.value;
        this.icon = tile.icon;
        this.data = tile.data;
        this.routingLink = tile.routingLink;
    }
}

export class TileMini {
    type = TileType.Mini;
    value?: string;
    icon?: Icon | string;
    label: string;

    constructor(tile: ITileMini) {
        this.value = tile.value;
        this.icon = tile.icon;
        this.label = tile.label;
    }
}

export class TileAvatar {
    type = TileType.Avatar;
    value: string;
    avatar: Avatar;
    date: Date;
    routingLink?: RoutingLink;

    constructor(tile: ITileAvatar) {
        this.value = tile.value;
        this.avatar = tile.avatar;
        this.date = tile.date;
        this.routingLink = tile.routingLink;
    }
}

export class TileValue {
    type = TileType.Value;
    value?: number;
    isPrimary?: boolean;
    appreciation?: number;
    label: string;

    constructor(tile: ITileValue) {
        this.value = tile.value;
        this.isPrimary = tile.isPrimary;
        this.appreciation = tile.appreciation;
        this.label = tile.label;
    }
}

export class TileInfo {
    type = TileType.Info;
    value?: string | number;
    icon?: Icon | string;
    label: string;
    // severity: Severity;
    themeColor: WdxThemeColor;
    lifecycleStage: ClientLifecycleStage;

    constructor(tile: ITileInfo) {
        this.value = tile.value;
        this.icon = tile.icon;
        this.label = tile.label;
        this.themeColor = tile.themeColor;
        // this.severity = tile.severity;
        this.lifecycleStage = tile.lifecycleStage;
    }
}
