<div
    class="d-flex align-items-center rounded-circle"
    [class.text-strong]="commentStatus.count"
    [class.text-muted]="!commentStatus.count"
>
    <wdx-icon
        class="me-1 icon-shift-up"
        iconClass="text-inherit"
        [scale]="1"
        [class.text-danger]="commentStatus.hasIssue"
        icon="comment"
        iconStyle="fas"
    ></wdx-icon>
    <h6 *ngIf="longFormat; else shortFormat">
        {{ commentStatus.count }} Comment{{
            commentStatus.count === 1 ? '' : 's'
        }}
    </h6>
    <ng-template #shortFormat>
        <div class="small" data-cy="lbl-comment-count">
            {{ commentStatus.count }}
        </div>
    </ng-template>
</div>
