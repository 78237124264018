import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';

@Component({
    selector: 'wdx-info-card',
    templateUrl: './wdx-info-card.component.html',
    styleUrls: ['./wdx-info-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxInfoCardComponent {
    @Input() bodyClasses!: string;
    @Input() set flush(flush: boolean) {
        this.flushClass = flush;
    }
    @HostBinding('class.card') hostCardClass = true;
    @HostBinding('class.flush') flushClass = false;
}
