import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as personalConnectionsActions from './personal-connections.actions';
import { PersonalConnectionsService } from './personal-connections.service';

@Injectable()
export class PersonalConnectionsEffects {
    private actions$ = inject(Actions);
    private personalConnectionsService = inject(PersonalConnectionsService);

    getForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(personalConnectionsActions.getForParty),
            mergeMap((action) =>
                this.personalConnectionsService
                    .getForParty(action.partyId)
                    .pipe(
                        map((relationships) =>
                            personalConnectionsActions.getForPartySuccess({
                                partyId: action.partyId,
                                relationships,
                            })
                        ),
                        catchError((error) =>
                            of(
                                personalConnectionsActions.getForPartyFailure({
                                    partyId: action.partyId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
