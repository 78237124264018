import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as rootReducer from '../../state/_setup/reducers';
import * as peopleActions from './people.actions';
import { PeopleService } from './people.service';

@Injectable()
export class PeopleEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private peopleService = inject(PeopleService);

    getForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(peopleActions.getForParty),
            mergeMap((action) =>
                this.peopleService.getForParty(action.partyId).pipe(
                    map((person) =>
                        peopleActions.getForPartySuccess({
                            partyId: action.partyId,
                            person,
                        })
                    ),
                    catchError((error) =>
                        of(
                            peopleActions.getForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getFavourites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(peopleActions.getFavourites),
            switchMap(() =>
                this.peopleService.getFavourites().pipe(
                    map((people) =>
                        peopleActions.getFavouritesSuccess({
                            favourites: people,
                        })
                    ),
                    catchError((error) =>
                        of(peopleActions.getFavouritesFailure({ error }))
                    )
                )
            )
        )
    );
}
