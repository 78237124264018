import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxRadioComponent } from './wdx-radio.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxRadioComponent],
    exports: [WdxRadioComponent],
})
export class WdxRadioModule {}
