import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxBadgeComponent } from './wdx-badge.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxBadgeComponent],
    exports: [WdxBadgeComponent],
})
export class WdxBadgeModule {}
