import { Action, createReducer, on } from '@ngrx/store';
import * as selectsActions from './select-options.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { CrudStatus } from '@wdx/shared/utils';
import { SelectFieldResult } from '@wdx/clmi/api-models';

export const SELECT_OPTIONS_FEATURE_KEY = 'selectOptions';

export interface SelectOptionsData {
    id: string;
    status: CrudStatus;
    statusString: string;
    data: SelectFieldResult[];
    error?: any;
}

export type SelectOptionsState = EntityState<SelectOptionsData>;

export interface SelectOptionsStoreSlice {
    readonly [SELECT_OPTIONS_FEATURE_KEY]: SelectOptionsState;
}

export const selectsAdapter: EntityAdapter<SelectOptionsData> =
    createEntityAdapter<SelectOptionsData>();

export const initialSelectOptionsState: SelectOptionsState =
    selectsAdapter.getInitialState({});

const reducerSetup = createReducer(
    initialSelectOptionsState,
    on(
        selectsActions.resetSelectOptions,
        selectsActions.resetContextualSelect,
        (state, { id }) =>
            selectsAdapter.upsertOne(
                {
                    id,
                    data: [],
                    status: CrudStatus.Initial,
                    statusString: CrudStatus[CrudStatus.Initial],
                },
                state
            )
    ),
    on(
        selectsActions.getSelectOptions,
        selectsActions.getContextualSelect,
        (state, { id }) =>
            selectsAdapter.upsertOne(
                {
                    id,
                    data: [],
                    status: CrudStatus.Loading,
                    statusString: CrudStatus[CrudStatus.Loading],
                },
                state
            )
    ),
    on(
        selectsActions.getSelectOptionsSuccess,
        selectsActions.getContextualSelectOptionsSuccess,
        (state, { results, id }) =>
            selectsAdapter.upsertOne(
                {
                    id,
                    data: results,
                    status: CrudStatus.Success,
                    statusString: CrudStatus[CrudStatus.Success],
                },
                state
            )
    ),
    on(
        selectsActions.getSelectOptionsFailure,
        selectsActions.getContextualSelectOptionsFailure,
        (state, { error, id }) =>
            selectsAdapter.upsertOne(
                {
                    id,
                    error,
                    data: [],
                    status: CrudStatus.Error,
                    statusString: CrudStatus[CrudStatus.Error],
                },
                state
            )
    )
);

export function reducer(state: SelectOptionsState | undefined, action: Action) {
    return reducerSetup(state, action);
}
