import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IFormDynamicData } from '../../../../interfaces';

@Injectable()
export class AddressFormatService {
    private dynamicDataService = inject(IFormDynamicData);

    /**
     * Returns format friendly address
     * @param fieldValue
     */
    getAddress$(fieldValue: any, useAddressLine = false): Observable<string[]> {
        if (!fieldValue) {
            return of([]);
        }

        const addressLine =
            fieldValue['addressLine'] && useAddressLine
                ? ['addressLine']
                : ['buildingName', 'streetBuildingIdentification', 'street'];
        const addressFields = [
            'department',
            'subDepartment',
            'floor',
            'postOfficeBox',
            'room',
            'townLocationName',
            'districtName',
            'city',
            'region',
            'postCode',
            'countryCode',
        ];

        return this.dynamicDataService.getCountries().pipe(
            take(1),
            map((countries) => {
                const addressString = addressLine
                    .map((field) => fieldValue[field])
                    .filter((field) => Boolean(field))
                    .join(', ');

                let countryName;

                const formattedAddressFields = addressFields
                    .map((field) => {
                        if (field === 'countryCode') {
                            countryName = countries.find(
                                (country) =>
                                    country.isoCode === fieldValue[field]
                            )?.name;
                            return countryName;
                        } else {
                            return fieldValue[field];
                        }
                    })
                    .filter((field) => Boolean(field));

                const generatedValue = this.generateSingleLineFormatted(
                    fieldValue,
                    useAddressLine,
                    countryName
                );

                if (
                    generatedValue !== fieldValue['singleLineFormatted'] &&
                    fieldValue['singleLineFormatted']
                )
                    return [fieldValue['singleLineFormatted']];
                return [addressString, ...formattedAddressFields];
            })
        );
    }

    private generateSingleLineFormatted(
        fieldValue: any,
        useAddressLine = false,
        countryName?: string
    ): string {
        const result: string[] = [];
        if (fieldValue['addressLine'] && useAddressLine) {
            result.push(fieldValue['addressLine']);
        } else if (
            fieldValue['streetBuildingIdentification'] &&
            fieldValue['buildingName'] &&
            fieldValue['street']
        ) {
            result.push(
                `${fieldValue['buildingName']}, ${fieldValue['streetBuildingIdentification']} ${fieldValue['street']}`
            );
        } else if (
            fieldValue['streetBuildingIdentification'] &&
            fieldValue['street']
        ) {
            result.push(
                `${fieldValue['streetBuildingIdentification']} ${fieldValue['street']}`
            );
        } else if (fieldValue['streetBuildingIdentification']) {
            result.push(`${fieldValue['streetBuildingIdentification']}`);
        } else {
            result.push(`${fieldValue['street']}`);
        }

        if (fieldValue['city']) result.push(fieldValue['city']);
        if (fieldValue['region']) result.push(fieldValue['region']);
        if (fieldValue['postCode']) result.push(fieldValue['postCode']);

        if (countryName) result.push(countryName);

        return result.join(', ');
    }
}
