<div
    *ngIf="
        !isDisabled ||
        !PENDING_CHANGES_LOCK_FIELD.includes(
            $any(formElement.pendingChange?.status)
        )
    "
    class="position-absolute end-0"
>
    <button
        class="btn btn-link me-2"
        (click)="
            isHierarchyLookup
                ? onSwitchToIdLookup()
                : onSwitchToHierarchyLookup()
        "
    >
        {{ isHierarchyLookup ? 'ID Lookup' : 'Hierarchy Lookup' }}
    </button>
</div>
<ng-container [formGroup]="$any(controlContainer?.control)">
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [label]="formElement?.label | translateTokenisedString"
        [guidanceText]="formElement?.guidanceText | translateTokenisedString"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="inputId"
    >
        <ng-select
            *ngIf="isHierarchyLookup"
            #hierarchySelect
            [formControlName]="$any(formElement.name)"
            bindLabel="label"
            bindValue="value"
            [placeholder]="
                hierarchySelect.isOpen ? '' : hierarchyLookupPlaceholder
            "
            [closeOnSelect]="false"
            [attr.data-cy]="formElement.name"
            [readonly]="
                isDisabled ||
                PENDING_CHANGES_LOCK_FIELD.includes(
                    $any(formElement.pendingChange?.status)
                )
            "
            [items]="$any(options$ | ngrxPush)"
            (change)="onChangeHierarchy($event)"
            (clear)="onClearHierarchy()"
        >
            <ng-template ng-header-tmp>
                <div class="position-relative">
                    <div class="position-absolute top-0 end-0">
                        <wdx-icon-button
                            *ngIf="activeHierarchy?.length"
                            btnClass="btn"
                            (wdxOnClick)="onClearHierarchy()"
                            ><wdx-icon icon="times-circle"></wdx-icon
                        ></wdx-icon-button>
                    </div>

                    <div *ngIf="activeHierarchy?.length === 0" class="py-1">
                        {{ hierarchyLookupPlaceholder }}
                    </div>
                    <div
                        *ngFor="
                            let item of activeHierarchy;
                            let first = first;
                            let last = last;
                            let index = index
                        "
                    >
                        <div
                            class="d-flex align-items-center p-1"
                            [ngClass]="{
                                h6: item.level === 0,
                                'ms-3': item.level === 1,
                                'ms-5': item.level === 2
                            }"
                        >
                            <wdx-icon
                                *ngIf="
                                    index < $any(activeHierarchy?.length) - 1
                                "
                                class="d-block me-2"
                                [scale]="1"
                                [icon]="ICON_CHEVRON_DOWN"
                            ></wdx-icon>

                            <wdx-icon
                                *ngIf="last"
                                class="d-block me-2"
                                [scale]="1"
                                [icon]="ICON_CHEVRON_RIGHT"
                            ></wdx-icon>

                            <ng-container *ngIf="last">
                                <span class="ml-1 me-5">{{ item.label }}</span>
                            </ng-container>

                            <ng-container *ngIf="!last">
                                <button
                                    class="btn btn-link text-start ms-1 me-5"
                                    (click)="onActiveHierarchyLevelSelect(item)"
                                >
                                    {{ item.label }}
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
                <div class="d-flex align-items-center w-100 text-truncate">
                    <span class="ng-option-label flex-grow-1 text-truncate">
                        <ng-container *ngIf="item.level === finalLevel"
                            >{{ item.value }} - </ng-container
                        >{{ item.label }}</span
                    ><wdx-icon
                        *ngIf="item.level !== finalLevel"
                        class="d-block flex-shrink-0 ms-2"
                        [scale]="1"
                        [icon]="ICON_CHEVRON_RIGHT"
                    ></wdx-icon>
                </div>
            </ng-template>
        </ng-select>

        <ng-select
            *ngIf="!isHierarchyLookup"
            [formControlName]="$any(formElement.name)"
            bindLabel="label"
            bindValue="value"
            groupBy="category"
            [placeholder]="idLookupPlaceholder"
            [attr.data-cy]="formElement.name"
            [readonly]="
                isDisabled ||
                PENDING_CHANGES_LOCK_FIELD.includes(
                    $any(formElement.pendingChange?.status)
                )
            "
            [typeahead]="idLookup$"
            [items]="$any(options$ | ngrxPush)"
        >
            <ng-template ng-label-tmp let-item="item">{{
                formControl?.value
                    | formSummaryValue : fieldDefinition
                    | ngrxPush
            }}</ng-template>

            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <span
                    class="ng-option-label"
                    [innerHTML]="
                        item.value + ' - ' + item.label | highlight : search
                    "
                ></span>
            </ng-template>
        </ng-select>
    </wdx-form-field>
</ng-container>
