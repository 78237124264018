<molecule-info-card-dumb
    tabindex="-1"
    [cySelector]="cySelector"
    [cyValue]="cyValue"
    [primaryInfo]="primaryInfo"
    [primaryInfoIcon]="primaryInfoIcon"
    [avatars]="avatars"
    [icon]="icon"
    [iconClass]="iconClass"
    [avatarIconDivider]="avatarIconDivider"
    [severity]="severity"
    [size]="size"
    [avatarIconDivider]="avatarIconDivider"
    [secondaryInfo]="secondaryInfo"
    [tertiaryInfo]="tertiaryInfo"
    [idList]="idList"
    [textColor]="textColor"
    [routerLinkData]="routerLinkData"
    [openInNewTab]="openInNewTab"
    [entityId]="entityId"
    [entityType]="entityType"
    [queryParamsData]="queryParamsData"
    [subInfoCards]="subInfoCards"
    [canDelete]="canDelete"
    [canSelect]="canSelect"
    [isSelected]="isSelected"
    [article]="article"
    [modalData]="modalData"
    [showEditForm]="showEditForm"
    [formSetup]="formSetup"
    [hasLinkClickedObservers]="linkClicked.observers.length > 0"
    [ignoreLinkClickedObservers]="ignoreLinkClickedObservers"
    [compact]="compact"
    [fillHorizontalSpace]="fillHorizontalSpace"
    [linkWholeCard]="linkWholeCard"
    [externalLink]="data?.externalUrl ? data : null"
    (linkClicked)="onLinkClicked()"
    (subInfoCardLinkClicked)="onSubInfoCardLinkClicked($event)"
    (edit)="onEdit()"
    (delete)="onDelete()"
    (selected)="onSelected()"
    (deselected)="onDeselected()"
>
    <ng-container class="custom-actions">
        <ng-content select=".custom-actions"></ng-content>
    </ng-container>
</molecule-info-card-dumb>
