import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { NudgeType } from '@wdx/clmi/api-models';

@Injectable()
export class NudgeTypesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getAll(): Observable<NudgeType[]> {
        return this.http.get<NudgeType[]>(
            `${this.config.getConfiguration().API_BASE}/nudgetype`,
            {}
        );
    }
}
