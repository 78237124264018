<div class="tile-carousel h-100 w-100">
    <div
        #scrollContainer
        class="tile-carousel__tiles h-100"
        (scroll)="updateOffset($event)"
    >
        <div #tilesContainer class="tile-carousel__tiles__container h-100" data-cy="itemCard">
            <ng-content></ng-content>
        </div>
    </div>
    <button
        class="carousel-control-prev"
        cySelector="btn-left"
        [class.hide]="scrollMinReached"
        (click)="left()"
    >
        <span aria-hidden="true" class="carousel-control-prev-icon"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button
        class="carousel-control-next"
        cySelector="btn-right"
        [class.hide]="scrollMaxReached"
        (click)="right()"
    >
        <span aria-hidden="true" class="carousel-control-next-icon"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>
