import { Action, createReducer, on } from '@ngrx/store';
import { CrudStatus, setCrudStatus } from '@wdx/shared/utils';
import { personalSettingsActions } from '.';

export const PERSONAL_SETTINGS_FEATURE_KEY = 'personalSettings';

export interface State {
    status: CrudStatus;
    statusString: string;
    personalSettings: Record<string, any>;
}

export const initialState: State = {
    ...setCrudStatus(CrudStatus.Initial),
    personalSettings: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        personalSettingsActions.getPersonalSettings,
        (state): State => ({
            ...state,
            ...setCrudStatus(CrudStatus.Loading),
        })
    ),

    on(
        personalSettingsActions.getPersonalSettingsSuccess,
        (state, props): State => ({
            ...state,
            ...setCrudStatus(CrudStatus.Success),
            personalSettings: {
                ...state.personalSettings,
                ...props.settings,
            },
        })
    ),

    on(
        personalSettingsActions.getPersonalSettingsFailure,
        (state): State => ({
            ...state,
            ...setCrudStatus(CrudStatus.Error),
        })
    ),

    on(
        personalSettingsActions.putPersonalSettings,
        (state, props): State => ({
            ...state,
            ...setCrudStatus(CrudStatus.Updating),
            personalSettings: {
                ...state.personalSettings,
                ...props.settings,
            },
        })
    ),

    on(
        personalSettingsActions.putPersonalSettingsSuccess,
        (state): State => ({
            ...state,
            ...setCrudStatus(CrudStatus.Success),
        })
    ),

    on(
        personalSettingsActions.putPersonalSettingsFailure,
        (state): State => ({
            ...state,
            ...setCrudStatus(CrudStatus.Error),
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
