import { Action, createReducer, on } from '@ngrx/store';
import { WebHook } from '@wdx/clmi/api-models';
import { CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as webHooksActions from './web-hooks.actions';

export const webHooksFeatureKey = 'webHooks';

export interface State {
    webHooks: CrudStateObject<WebHook>;
}

export const initialState: State = {
    webHooks: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        webHooksActions.LoadWebHooks,
        (state, props): State => ({
            ...state,
            webHooks: {
                ...state.webHooks,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.webHooks[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.webHooks[GLOBAL_STATE_INDEX_ID]?.infinity,
                },
            },
        })
    ),

    on(
        webHooksActions.LoadWebHooksSuccess,
        (state, props): State => ({
            ...state,
            webHooks: {
                ...state.webHooks,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.webHooks[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.webHooks.paging,
                        combinedList: [
                            ...(state.webHooks[GLOBAL_STATE_INDEX_ID].infinity
                                ?.combinedList || []),
                            ...props.webHooks.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        webHooksActions.LoadWebHooksFailure,
        (state): State => ({
            ...state,
            webHooks: {
                ...state.webHooks,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.webHooks[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
