<div class="card">
    <div class="align-self-end">
        <button
            title="fullscreen"
            [wdxButton]="WDX_BUTTON_STYLE.None"
            [wdxIconButton]="true"
            [wdxButtonSize]="WDX_BUTTON_SIZE.Sm"
            (click)="fullscreen()"
        >
            <wdx-icon-container size="sm">
                <wdx-icon icon="expand"></wdx-icon>
            </wdx-icon-container>
        </button>
    </div>

    <div class="card-body m-0 p-0">
        <div class="json-editor__container" #jsonEditorWrapper>
            <div
                class="flex position-relative w-100 h-100"
                #jsonEditorContainer
            ></div>
        </div>
    </div>
</div>
