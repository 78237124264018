import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Person } from '@wdx/clmi/api-models';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class PeopleService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getForParty(partyId: string): Observable<Person> {
        return this.http.get<Person>(
            `${this.config.getConfiguration().API_BASE}/person/${partyId}`
        );
    }

    getFavourites(): Observable<Person[]> {
        return this.http.get<Person[]>(
            `${this.config.getConfiguration().API_BASE}/person/favourite`
        );
    }
}
