import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AtomAddButtonComponent } from './atom-add-button.component';
import { AtomIconButtonModule } from '../atom-icon-button/atom-icon-button.module';

@NgModule({
    imports: [AtomIconButtonModule, CommonModule, RouterModule],
    declarations: [AtomAddButtonComponent],
    exports: [AtomAddButtonComponent],
})
export class AtomAddButtonModule {}
