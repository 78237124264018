import { Pipe, PipeTransform } from '@angular/core';
import { AppStatus } from '@wdx/clmi/api-models';
import { KeyValueObject } from '@wdx/shared/utils';

@Pipe({ name: 'appUrl' })
export class AppUrlPipe implements PipeTransform {
    transform(app: AppStatus, entityId: string): KeyValueObject {
        const params = {
            data: {
                appId: app.id,
                entityId,
            },
            tabId: `app${app.type}`,
            label: app.name,
            partyIds: app.party && [app.party.id],
        };

        const result = {
            tab: btoa(JSON.stringify(params)),
        };

        return result;
    }
}
