<wdx-icon-button
    [size]="size"
    [disabled]="isDisabled"
    [attr.data-cy]="cySelector"
    (wdxOnClick)="onToggle($event)"
    class="rounded-circle"
    [wdxStyle]="toggle ? 'primary' : 'outline-primary'"
>
    <wdx-icon
        [icon]="toggle ? iconOn : iconOff"
        [iconStyle]="toggle ? 'fas' : 'fal'"
        [attr.alt]="toggle ? altTextOn : altTextOff"
    ></wdx-icon>
</wdx-icon-button>
