import { KeyValueObject } from '@wdx/shared/utils';
import { BootstrapColumns } from '../models/bootstrap-columns.model';

export const BOOTSTRAP_COLUMNS_MAP: KeyValueObject<BootstrapColumns> = {
    1: 12,
    2: 6,
    3: 4,
    4: 3,
    6: 2,
    12: 1,
};
