import { Action, createReducer, on } from '@ngrx/store';
import { AmlHitUpdate, AmlReport } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as amlReportsActions from './aml-reports.actions';

export interface State {
    appStatusAmlReports?: CrudStateObject<AmlReport>;
    appStatusAmlHits?: CrudStateObject<AmlHitUpdate>;
}

export const initialState: State = {
    appStatusAmlReports: {},
    appStatusAmlHits: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        amlReportsActions.getAmlReportForAppStatus,
        (state, props): State => ({
            ...state,
            appStatusAmlReports: {
                ...state.appStatusAmlReports,
                [props.appStatusId]: {
                    ...(state.appStatusAmlReports[props.appStatusId] ||
                        ({} as CrudState<AmlReport>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        amlReportsActions.getAmlReportForAppStatusSuccess,
        (state, props): State => ({
            ...state,
            appStatusAmlReports: {
                ...state.appStatusAmlReports,
                [props.appStatusId]: {
                    ...state.appStatusAmlReports[props.appStatusId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.amlReport,
                },
            },
        })
    ),

    on(
        amlReportsActions.getAmlReportForAppStatusFailure,
        (state, props): State => ({
            ...state,
            appStatusAmlReports: {
                ...state.appStatusAmlReports,
                [props.appStatusId]: {
                    ...state.appStatusAmlReports[props.appStatusId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        amlReportsActions.runAmlReportForAppStatus,
        (state, props): State => ({
            ...state,
            appStatusAmlReports: {
                ...state.appStatusAmlReports,
                [props.appStatusId]: {
                    ...(state.appStatusAmlReports[props.appStatusId] ||
                        ({} as CrudState<AmlReport>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        amlReportsActions.runAmlReportForAppStatusSuccess,
        (state, props): State => ({
            ...state,
            appStatusAmlReports: {
                ...state.appStatusAmlReports,
                [props.appStatusId]: {
                    ...state.appStatusAmlReports[props.appStatusId],
                    isUpdating: false,
                    hasUpdatingError: false,
                    single: props.amlReport,
                },
            },
        })
    ),

    on(
        amlReportsActions.runAmlReportForAppStatusFailure,
        (state, props): State => ({
            ...state,
            appStatusAmlReports: {
                ...state.appStatusAmlReports,
                [props.appStatusId]: {
                    ...state.appStatusAmlReports[props.appStatusId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        amlReportsActions.updateAmlHitForAppStatus,
        (state, props): State => ({
            ...state,
            appStatusAmlHits: {
                ...state.appStatusAmlHits,
                [props.amlHit.amlHitId]: {
                    ...(state.appStatusAmlHits[props.amlHit.amlHitId] ||
                        ({} as CrudState<AmlHitUpdate>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        amlReportsActions.updateAmlHitForAppStatusSuccess,
        (state, props): State => ({
            ...state,
            appStatusAmlHits: {
                ...state.appStatusAmlHits,
                [props.amlHit.amlHitId]: {
                    ...state.appStatusAmlHits[props.amlHit.amlHitId],
                    isUpdating: false,
                    hasUpdatingError: false,
                    single: props.amlHit,
                },
            },
        })
    ),

    on(
        amlReportsActions.updateAmlHitForAppStatusFailure,
        (state, props): State => ({
            ...state,
            appStatusAmlHits: {
                ...state.appStatusAmlHits,
                [props.amlHit.amlHitId]: {
                    ...state.appStatusAmlHits[props.amlHit.amlHitId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
