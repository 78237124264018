import { Component, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-highlight-text',
    templateUrl: './atom-highlight-text.component.html',
})
export class AtomHighlightTextComponent {
    @Input() highlight: string;
    @Input() shade = '500';
    @Input() size: 'lg' | 'sm' = 'lg';
}
