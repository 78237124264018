import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import {
    FormDefinition,
    FormInitialisationMode,
    FormLayout,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { Subject } from 'rxjs';
import {
    IFormDataResult,
    IFormPendingChange,
} from '../../constants/control-types';

@Injectable()
export class FormStaticService extends WdxDestroyClass {
    formDefinition!: FormDefinition;
    childFormDefinitions!: Record<string, FormDefinition>;
    formLayout!: FormLayout;
    initialisationMode!: FormInitialisationMode;
    form!: UntypedFormGroup;
    formId!: string;
    formData!: IFormDataResult;
    submitAttempted$ = new Subject<boolean>();
    fieldsToNullCheck: string[] = [];
    pendingChangesSubForm: {
        [key: string]: {
            [key: string]: {
                [key: string]: IFormPendingChange;
            };
        };
    } = {};
    isReadonlyMode = false;
}
