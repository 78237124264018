import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomHighlightCircleComponent } from './atom-highlight-circle.component';

@NgModule({
    imports: [CommonModule],
    declarations: [AtomHighlightCircleComponent],
    exports: [AtomHighlightCircleComponent],
})
export class AtomHighlightCircleModule {}
