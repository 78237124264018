import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxRadioTileComponent } from './wdx-radio-tile.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxRadioTileComponent],
    exports: [WdxRadioTileComponent],
})
export class WdxRadioTileModule {}
