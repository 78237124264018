import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActivityDateIconPipe } from './pipes/activity-date-icon/activity-date-icon.pipe';
import { ActivityDateValuePipe } from './pipes/activity-date-value/activity-date-value.pipe';

@NgModule({
    declarations: [ActivityDateIconPipe, ActivityDateValuePipe],
    imports: [CommonModule],
    exports: [ActivityDateIconPipe, ActivityDateValuePipe],
})
export class ToDoModule {}
