import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeInputSearchModule } from '../../../components/molecules/molecule-input-search/molecule-input-search.module';
import { ClientSideSearchComponent } from './client-side-search.component';

@NgModule({
    imports: [CommonModule, MoleculeInputSearchModule],
    declarations: [ClientSideSearchComponent],
    exports: [ClientSideSearchComponent],
})
export class ClientSideSearchModule {}
