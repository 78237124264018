import { SystemEntity } from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { KeyValueObject } from '@wdx/shared/utils';
import {
    ICON_DOWNLOAD,
    ICON_EXPORTS,
    ICON_FILE_IMPORT,
    ICON_LIST,
} from '../../../constants/icons.constants';

export const EXPORT_VAL = 'export';
export const IMPORT_VAL = 'import';
export const DOWNLOAD_VAL = 'download';
export const ADD_TO_LIST_VAL = 'add_to_list';

export const IMPORT_EXPORT_ENTITY_FEATURE_FLAGS_MAP: KeyValueObject<FeatureFlag> =
    {
        [SystemEntity.Adviser]: FeatureFlag.ImportAdviser,
        [SystemEntity.Intermediary]: FeatureFlag.ImportIntermediary,
        [SystemEntity.Lead]: FeatureFlag.ImportLead,
        [SystemEntity.ProductProvider]: FeatureFlag.ImportProductProvider,
        [SystemEntity.Opportunity]: FeatureFlag.ImportOpportunity,
    };

export const ADD_OPERATIONS_TO_MENU = [
    SystemEntity.Client,
    SystemEntity.Lead,
    SystemEntity.Opportunity,
    SystemEntity.Case,
    SystemEntity.ProductProvider,
    SystemEntity.Adviser,
    SystemEntity.Intermediary,
    SystemEntity.Person,
];

export const EXPORT = {
    icon: ICON_EXPORTS.icon,
    label: 'Export',
    translationKey: 'MENU_EXPORT',
    value: EXPORT_VAL,
};

export const IMPORT = [
    {
        icon: ICON_FILE_IMPORT.icon,
        label: 'Import',
        translationKey: 'LABEL_IMPORT',
        value: IMPORT_VAL,
    },
    {
        icon: ICON_DOWNLOAD.icon,
        label: 'Download Template',
        translationKey: 'LABEL_DOWNLOAD_IMPORT_TEMPLATE',
        value: DOWNLOAD_VAL,
    },
];

export const ADD_TO_LIST = {
    icon: ICON_LIST.icon,
    label: 'Add to list...',
    translationKey: 'MENU_ADDTOLIST',
    value: ADD_TO_LIST_VAL,
};

export const ADD_TO_LIST_ENTITIES = [
    SystemEntity.Client,
    SystemEntity.Lead,
    SystemEntity.Person,
];
