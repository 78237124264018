<ul class="list-group list-group-flush h-100 overflow-hidden flex-column">
    <li
        class="list-group-item d-flex justify-content-between align-items-center bg-muted"
    >
        <h6 class="me-2">{{ ENTITY_SAVEDFILTER_PLURAL | translate }}</h6>
        <atom-add-button
            *ngIf="FEATURE_FLAG_CUSTOM_VIEWS | hasFeature"
            type="button"
            data-cy="btn-create-new-filter"
            (click)="toggleQueryBuilder()"
        >
            {{ CREATE_NEW | translate }}
        </atom-add-button>
    </li>

    <section class="h-100 overflow-hidden">
        <div class="h-100 overflow-auto">
            <clmi-filter-view-list
                [config]="config"
                icon="browser"
                [title]="FILTER_SYSTEM_VIEW | translate"
                [views]="filterViewFacadeService?.viewsSystem$ | ngrxPush"
                (formFiltersEvent)="formFiltersEvent.emit($event)"
            ></clmi-filter-view-list>

            <clmi-filter-view-list
                [config]="config"
                [title]="FILTER_PERSONAL_VIEW | translate"
                viewsType="personal"
                [icon]="ICON_USER_COG"
                data-cy="personal-view"
                [views]="filterViewFacadeService?.viewsPersonal$ | ngrxPush"
                (formFiltersEvent)="formFiltersEvent.emit($event)"
            ></clmi-filter-view-list>
        </div>
    </section>
</ul>
