import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CrudStatus, FormDataResult, setCrudStatus } from '@wdx/shared/utils';
import * as formHistoryInstanceActions from './form-history-instance.actions';

export const FORM_HISTORY_INSTANCE_FEATURE_KEY = 'formHistoryInstance';

export interface FormHistoryInstance {
    formId: string;
    entityId: string;
    instanceId: string;
    formHistoryInstance: FormDataResult | null;
    status: CrudStatus;
    statusString: string;
    error?: Error;
}

export type FormHistoryInstanceState = EntityState<FormHistoryInstance>;

export interface FormHistoryInstancePartialState {
    readonly [FORM_HISTORY_INSTANCE_FEATURE_KEY]: FormHistoryInstanceState;
}

export const selectFormHistoryInstanceId = (props: {
    formId: string;
    entityId: string;
    instanceId: string;
}) => `${props.formId}-${props.entityId}-${props.instanceId}`;

export const formHistoryInstanceAdapter: EntityAdapter<FormHistoryInstance> =
    createEntityAdapter<FormHistoryInstance>({
        selectId: selectFormHistoryInstanceId,
    });

export const initialFormHistoryInstanceState: FormHistoryInstanceState =
    formHistoryInstanceAdapter.getInitialState();

const reducer = createReducer(
    initialFormHistoryInstanceState,
    on(
        formHistoryInstanceActions.loadFormHistoryInstance,
        (state, { formId, entityId, instanceId }) =>
            formHistoryInstanceAdapter.upsertOne(
                {
                    formId,
                    entityId,
                    instanceId,
                    formHistoryInstance: null,
                    ...setCrudStatus(CrudStatus.Loading),
                },
                { ...state }
            )
    ),
    on(
        formHistoryInstanceActions.loadFormHistoryInstanceSuccess,
        (state, { formId, entityId, instanceId, formHistoryInstance }) =>
            formHistoryInstanceAdapter.upsertOne(
                {
                    formId,
                    entityId,
                    instanceId,
                    formHistoryInstance,
                    ...setCrudStatus(CrudStatus.Success),
                },
                { ...state }
            )
    ),
    on(
        formHistoryInstanceActions.loadFormHistoryInstanceFailure,
        (state, { formId, entityId, instanceId, error }) =>
            formHistoryInstanceAdapter.upsertOne(
                {
                    formId,
                    entityId,
                    instanceId,
                    formHistoryInstance: null,
                    error,
                    ...setCrudStatus(CrudStatus.Error),
                },
                { ...state }
            )
    )
);

export function formHistoryInstanceReducer(
    state: FormHistoryInstanceState | undefined,
    action: Action
) {
    return reducer(state, action);
}
