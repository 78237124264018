import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ThemeComponentClass } from '@wdx/shared/components/abstract-classes';

@Component({
    selector: 'wdx-badge',
    templateUrl: './wdx-badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxBadgeComponent extends ThemeComponentClass {
    override themeClassPrefix = 'badge';
}
