<wdx-list [vertical]="rows">
    <li wdxListItem *ngFor="let listItem of idList">
        {{ listItem.id }}
        <div *ngIf="listItem.name" wdxListItemSub>({{ listItem.name }})</div>
        <wdx-icon
            *ngIf="listItem.isLocked"
            wdxListItemIcon
            [icon]="ICON_LOCK"
        ></wdx-icon>
    </li>
</wdx-list>
