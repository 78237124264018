import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    Severity,
    TRANSLATION_TOAST_MESSAGE_ACTION_COMPLETED,
    TranslationsService,
} from '@wdx/shared/utils';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as httpActions from './http.actions';
import { HttpService } from './http.service';

@Injectable()
export class HttpEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);
    private toastService = inject(WdxToastService);
    private translationsService = inject(TranslationsService);

    patch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(httpActions.patch),
            mergeMap((action) =>
                this.httpService.patch(action.slug).pipe(
                    map(() => httpActions.patchSuccess({ slug: action.slug })),
                    catchError((error) =>
                        of(httpActions.patchFailure({ error }))
                    )
                )
            )
        )
    );

    patchSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(httpActions.patchSuccess),
                tap(() => {
                    this.toastService.show({
                        body: [
                            this.translationsService.getTranslationByKey(
                                TRANSLATION_TOAST_MESSAGE_ACTION_COMPLETED
                            ),
                        ],
                        severity: Severity.Success,
                    });
                })
            ),
        { dispatch: false }
    );
}
