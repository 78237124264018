import { SystemEntity } from '@wdx/clmi/api-models';
import {
    TRANSLATION_TABLE_HEADER_AUM,
    TRANSLATION_TABLE_HEADER_LAST_CONTACTED,
    TRANSLATION_TABLE_HEADER_LAST_REVIEW,
    TRANSLATION_TABLE_HEADER_LIFECYCLE_STAGE,
    TRANSLATION_TABLE_HEADER_NAME,
    TRANSLATION_TABLE_HEADER_NEXT_ACTIVITY,
    TRANSLATION_TABLE_HEADER_NEXT_REVIEW,
    TRANSLATION_TABLE_HEADER_OPEN_CASES,
    TRANSLATION_TABLE_HEADER_PIPELINE,
    TRANSLATION_TABLE_HEADER_SUB_TYPE,
    TRANSLATION_TABLE_HEADER_TYPE,
} from '@wdx/shared/utils';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { Privilege } from '../../../models/privilege.model';
import { SortMenuSortBy } from '../../../models/sort-menu.model';

export const CLIENT_RELATIONSHIP_TABLE_HEADER = [
    {
        value: 'Name',
        translationKey: TRANSLATION_TABLE_HEADER_NAME,
        sortByFieldName: SortMenuSortBy.Name,
    },
    {
        value: 'Lifecycle Stage',
        translationKey: TRANSLATION_TABLE_HEADER_LIFECYCLE_STAGE,
    },
    {
        value: 'Type',
        translationKey: TRANSLATION_TABLE_HEADER_TYPE,
    },
    {
        value: 'Sub-Type',
        translationKey: TRANSLATION_TABLE_HEADER_SUB_TYPE,
    },
    {
        value: 'Last Contacted',
        translationKey: TRANSLATION_TABLE_HEADER_LAST_CONTACTED,

        sortByFieldName: SortMenuSortBy.LastContacted,
    },
    {
        value: 'Next Activity',
        translationKey: TRANSLATION_TABLE_HEADER_NEXT_ACTIVITY,
        sortByFieldName: SortMenuSortBy.NextMeeting,
    },
    {
        value: 'Open Cases',
        translationKey: TRANSLATION_TABLE_HEADER_OPEN_CASES,
    },
    {
        value: 'Pipeline (#)',
        translationKey: TRANSLATION_TABLE_HEADER_PIPELINE,
        sortByFieldName: SortMenuSortBy.Pipeline,
    },
    {
        value: 'AUM',
        translationKey: TRANSLATION_TABLE_HEADER_AUM,

        sortByFieldName: SortMenuSortBy.AUM,
    },
    {
        value: 'Last Review',
        translationKey: TRANSLATION_TABLE_HEADER_LAST_REVIEW,
        sortByFieldName: SortMenuSortBy.DateLastReview,
    },
    {
        value: 'Next Review',
        translationKey: TRANSLATION_TABLE_HEADER_NEXT_REVIEW,
        sortByFieldName: SortMenuSortBy.DateNextReview,
    },
    {
        value: null,
    },
];

export const CLIENTS_ACTION_BTN = [
    {
        mode: ActionButtonMode.FavouriteButton,
        cySelector: 'btn-favorite-client',
    },
];

export const CLIENTS_ROUTE_DATA = {
    viewType: SystemEntity.Client,
    label: 'Client Relationships',
    translationKey: 'AREA_CONTACTS_CLIENTS',
    dropdownMenuPrivileges: {
        export: Privilege.ExportClient,
        import: Privilege.ImportClient,
    },
};
