import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'clmi-filter-table-info-card-template',
    templateUrl: './info-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTableInfoCardTemplateComponent {
    @Input() data: any;
}
