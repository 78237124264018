import { Component, Input } from '@angular/core';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';

import { ACTIVITY_ICON_MAP } from '@wdx/clmi/utils/constants';
import { Highlight } from '@wdx/shared/utils';
import { ToDoItem } from '../../../models/to-do-item.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-activity-card',
    templateUrl: './molecule-activity-card.component.html',
})
export class MoleculeActivityCardComponent {
    @Input() activityCard: ToDoItem;

    readonly ACTIVITY_ICON_MAP = ACTIVITY_ICON_MAP;

    activityCardActionButton = {
        mode: ActionButtonMode.DropdownButtonMenu,
        dropdownMenu: [
            {
                label: 'Assign to',
                value: 'assignTo',
            },
            {
                label: 'File',
                value: 'file',
            },
            {
                label: 'Cancel',
                value: 'cancel',
            },
            {
                label: 'Delete',
                value: 'delete',
            },
        ],
    };

    readonly HIGHLIGHT = Highlight;
}
