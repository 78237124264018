import { Injectable } from '@angular/core';
import { filter, map, Observable, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as dynamicFormsActions from './dynamic-forms.actions';
import * as dynamicFormsSelectors from './dynamic-forms.selectors';
import * as dynamicFormsReducer from './dynamic-forms.reducer';
import { FormDefinition } from '@wdx/shared/utils';

@Injectable()
export class DynamicFormsFacade {
    constructor(
        private store$: Store<{
            [dynamicFormsReducer.FEATURE_KEY]: dynamicFormsReducer.State;
        }>
    ) {}

    getFormDefinition$(formId: string): Observable<FormDefinition> {
        return this.store$.pipe(
            select(dynamicFormsSelectors.getFormDefinitionCrudObject, {
                id: formId,
            }),
            tap((crudObj) => {
                if (!crudObj?.single && !crudObj?.isLoadingSingle) {
                    this.store$.dispatch(
                        dynamicFormsActions.getFormDefinition({
                            formId,
                        })
                    );
                }
            }),
            map((crudObj) => crudObj?.single),
            filter(Boolean)
        );
    }

    getIsUpdatingFormData$(formId: string): Observable<boolean> {
        return this.store$.pipe(
            select(dynamicFormsSelectors.getIsUpdatingFormData, { id: formId }),
            map(Boolean)
        );
    }
}
