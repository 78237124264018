import { Component, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-input-none-selected',
    templateUrl: './atom-input-none-selected.component.html',
})
export class AtomInputNoneSelectedComponent {
    @Input() modalId: string;
    @Input() disabled: boolean;
}
