import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DynamicScriptService {
    private readonly _loadedScripts: {
        [url: string]: ReplaySubject<void>;
    } = {};

    constructor(
        @Inject(DOCUMENT)
        private readonly document: any
    ) {}

    load(scripts: string[]): Observable<any> {
        const loadedScripts = scripts.map((script: string) =>
            this.loadScript(script)
        );
        return forkJoin(loadedScripts);
    }

    private loadScript(src: string): Observable<any> {
        this._loadedScripts[src] = new ReplaySubject();
        const script = this.document.createElement('script');
        script.async = true;
        script.src = src;
        script.onload = () => {
            this._loadedScripts[src].next();
            this._loadedScripts[src].complete();
        };

        this.document.body.appendChild(script);
        return this._loadedScripts[src].asObservable();
    }
}
