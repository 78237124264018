import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { AtomGuidanceTextIconComponent } from './atom-guidance-text-icon.component';

@NgModule({
    imports: [CommonModule, NgbPopoverModule, WdxIconModule],
    declarations: [AtomGuidanceTextIconComponent],
    exports: [AtomGuidanceTextIconComponent],
})
export class AtomGuidanceTextIconModule {}
