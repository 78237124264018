import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Opportunity, SystemEntity } from '@wdx/clmi/api-models';
import {
    OPPORTUNITY_FILTER_FORM_ID,
    OPPORTUNITY_FORM_ID,
    TRANSLATION_ENTITY_OPPORTUNITY_PLURAL,
    WdxSize,
} from '@wdx/shared/utils';
import { FilterHandler } from '../../../classes/filter-handler.class';
import { ICON_ADD } from '../../../constants/icons.constants';
import { RAG_STATUS_SEVERITY_MAP } from '../../../constants/rag.constants';
import { SORT_MENU_OPPORTUNITIES } from '../../../constants/sort-menu.constants';
import { ActionButton } from '../../../models/action-button.model';
import { ActionButtonMode } from '../../../models/action-buttons-mode.model';
import { CardType } from '../../../models/card-type.model';
import { OpportunityCardItem } from '../../../models/opportunity-card-item.model';
import { Privilege } from '../../../models/privilege.model';
import { ScrollMode } from '../../../models/scroll-mode.model';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-opportunities-list',
    templateUrl: './organism-opportunities-list.component.html',
})
export class OrganismOpportunitiesListComponent implements OnInit {
    @Input() opportunities: Opportunity[];
    @Input() opportunitiesActionButton: ActionButton;
    @Input() partyId: string;
    @Input() partyRoleId: string;
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() filterHandler: FilterHandler;
    @Input() opportunitiesPerRow: number;
    @Input() showFilters: boolean;
    @Input() showFilterBar = true;
    @Input() showSearchBar = true;
    @Input() showFavourite = true;
    @Input() showNoDataSVG = true;
    @Input() noDataSVGSize: WdxSize;
    @Input() noDataMessage: string;
    @Input() scrollMode: ScrollMode;
    @Input() limit: number;
    @Input() totalRecordCount: number;
    @Input() isSuggestedCardList: boolean;
    @Input() showDeleteLinkMenuItem: boolean;
    @Input() linkToEntityType: SystemEntity;
    @Input() linkToEntityId: string;

    @Output() infinityScrollFired = new EventEmitter();
    @Output() toggleFavourite = new EventEmitter<{
        id: string;
        isFavourite: boolean;
    }>();

    readonly OPPORTUNITY_FILTER_FORM = OPPORTUNITY_FILTER_FORM_ID;
    readonly SORT_MENU_OPPORTUNITIES = SORT_MENU_OPPORTUNITIES;

    instanceId: string;

    cardType: CardType = CardType.Opportunity;
    filtersModalId = 'opportunity-filter';
    searchType = 'Opportunity';
    searchTypePlural = TRANSLATION_ENTITY_OPPORTUNITY_PLURAL;

    ngOnInit() {
        this.instanceId = new RandomStringPipe().transform();
        this.filtersModalId = new RandomStringPipe().transform();
    }

    opportunityCreateActionButton(
        partyId: string,
        partyRoleId: string
    ): ActionButton {
        return {
            mode: ActionButtonMode.IconButton,
            icon: ICON_ADD.icon,
            privileges: [Privilege.CreateOpportunity],
            formSetup: {
                formId: OPPORTUNITY_FORM_ID,
                initialisationParams: {
                    ...(partyId ? { partyId } : {}),
                    ...(partyRoleId ? { partyRoleId } : {}),
                },
            },
        };
    }

    onSearch(value: string): void {
        this.filterHandler.setSearchText(value);
    }

    onToggleFavourite(event: any) {
        this.toggleFavourite.emit(event);
    }

    onInfinityScrollFired(): void {
        this.infinityScrollFired.emit();
    }

    opportunitiesAsOpportunityCardItems(
        opportunities: Opportunity[],
        linkToEntityType: SystemEntity,
        linkToEntityId: string,
        isSuggestedCardList: boolean,
        showDeleteLinkMenuItem: boolean,
        partyId: string,
        limit: number
    ): OpportunityCardItem[] {
        const opportunityCards =
            opportunities?.length && limit > 0
                ? opportunities.slice(0, limit + 1)
                : opportunities;
        return opportunityCards?.map((opportunity) => ({
            id: opportunity.id,
            name: opportunity.name,
            client: opportunity.client,
            primaryContact: opportunity.primaryContact,
            organisation: opportunity.organisation,
            estimatedValue: opportunity.estimatedValue,
            estimatedCloseDate: opportunity.estimatedCloseDate,
            stageName: opportunity.stageName,
            stageCode: opportunity.stageCode,
            stageIndex: opportunity.stageIndex,
            stageSeverity: RAG_STATUS_SEVERITY_MAP[opportunity.stageRagStatus],
            product: opportunity.product,
            priority: opportunity.priority,
            internalIdentifier: opportunity.internalIdentifier,
            isSuggestedCardsList: isSuggestedCardList,
            showDeleteLinkMenuItem: showDeleteLinkMenuItem,
            currentPartyId: partyId,
            linkToEntityType,
            linkToEntityId,
        }));
    }
}
