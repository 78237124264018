import { Pipe, PipeTransform } from '@angular/core';
import { AddressValue } from '../models/address-value.model';

@Pipe({ name: 'addressValueString' })
export class AddressValueStringPipe implements PipeTransform {
    transform(value: AddressValue, delimiter = ', '): string {
        if (!value) {
            return '';
        }

        const result = [
            `${value.streetNumber} ${value.street}`,
            ...(value.townCity ? [value.townCity] : []),
            ...(value.county ? [value.county] : []),
            ...(value.postCode ? [value.postCode] : []),
            ...(value.countryDisplayName ? [value.countryDisplayName] : []),
        ];

        return result.join(delimiter).trim();
    }
}
