<label class="input-switch d-flex" [for]="instanceId">
    <span
        *ngIf="label"
        class="fw-bold text-primary me-1 pointer"
        [attr.data-cy]="'switch-label'"
        >{{ label }}</span
    >
    <input
        class="switch-checkbox"
        type="checkbox"
        [id]="instanceId"
        [checked]="toggled"
        [disabled]="disabled"
        (change)="toggleSwitch()"
    />
    <div class="switch"></div>
</label>
