import { createAction, props } from '@ngrx/store';

export const getPersonalSettings = createAction(
    '[Personal Settings] Get Personal Settings'
);
export const getPersonalSettingsSuccess = createAction(
    '[Personal Settings] Get Personal Settings Success',
    props<{ settings: Record<string, any> }>()
);
export const getPersonalSettingsFailure = createAction(
    '[Personal Settings] Get Personal Settings Failure',
    props<{ error: Error }>()
);

export const putPersonalSettings = createAction(
    '[Personal Settings] Put Personal Settings',
    props<{ settings: Record<string, any> }>()
);
export const putPersonalSettingsSuccess = createAction(
    '[Personal Settings] Put Personal Settings Success'
);
export const putPersonalSettingsFailure = createAction(
    '[Personal Settings] Put Personal Settings Failure',
    props<{ error: Error }>()
);
