import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    Renderer2,
} from '@angular/core';

@Component({
    selector: 'wdx-input-group',
    templateUrl: './wdx-input-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxInputGroupComponent {
    /**
     * This is required to enable the prefix.
     * The prefix value uses ng-content with the attribute name [prefix]
     */
    @Input() prefix?: boolean;

    /**
     * This is required to enable the suffix.
     * The suffix value uses ng-content with the attribute name [suffix]
     */
    @Input() suffix?: boolean;

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {
        this.renderer.addClass(this.elementRef.nativeElement, 'input-group');
    }
}
