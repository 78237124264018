<div class="feature-svg-container d-flex flex-column align-items-center">
    <div class="position-relative">
        <svg
            data-cy="wdx-feature-svg"
            class="feature-svg feature-svg--{{ size }}"
        >
            <ng-container [ngSwitch]="svg">
                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.AccessDenied"
                    xlink:href="assets/images/svg/access-denied.svg#access-denied"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.AppNoGoals"
                    xlink:href="assets/images/svg/app-no-goals.svg#app-no-goals"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.AppNoFamily"
                    xlink:href="assets/images/svg/app-no-family.svg#app-no-family"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.AppNoProducts"
                    xlink:href="assets/images/svg/app-no-products.svg#app-no-products"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.AppNoSourceOfWealth"
                    xlink:href="assets/images/svg/app-no-source-of-wealth.svg#app-no-source-of-wealth"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.ClientCharity"
                    xlink:href="assets/images/svg/client-charity.svg#client-charity"
                ></svg:use>

                <svg:use
                    *ngSwitchCase="FEATURE_SVG.ClientCorporate"
                    xlink:href="assets/images/svg/client-corporate.svg#client-corporate"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.ClientIndividual"
                    xlink:href="assets/images/svg/client-individual.svg#client-individual"
                ></svg:use>
                <svg:use
                    *ngSwitchCase="FEATURE_SVG.ClientJoint"
                    xlink:href="assets/images/svg/client-joint.svg#client-joint"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.ClientTrust"
                    xlink:href="assets/images/svg/client-trust.svg#client-trust"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.DashboardNoAccess"
                    xlink:href="assets/images/svg/dashboard-no-access.svg#dashboard-no-access"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.EmptyFolder"
                    xlink:href="assets/images/svg/empty-folder.svg#empty-folder"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.InboxEmpty"
                    xlink:href="assets/images/svg/inbox-empty.svg#inbox-empty"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.NoChatData"
                    xlink:href="assets/images/svg/no-chat-data.svg#no-chat-data"
                ></svg:use>
                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.NoData"
                    xlink:href="assets/images/svg/no-data.svg#no-data"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.NoListData"
                    xlink:href="assets/images/svg/no-list-data.svg#no-list-data"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.PageNotFound"
                    xlink:href="assets/images/svg/page-not-found.svg#page-not-found"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.SelectDataFromMenu"
                    xlink:href="assets/images/svg/select-data-from-menu.svg#select-data-from-menu"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.Workspace"
                    xlink:href="assets/images/svg/workspace.svg#workspace"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.Comments"
                    xlink:href="assets/images/svg/comments.svg#comments"
                ></svg:use>

                <svg:use
                    data-cy="wdx-feature-svg-use"
                    *ngSwitchCase="FEATURE_SVG.ThumbsUp"
                    xlink:href="assets/images/svg/thumbs-up.svg#thumbs-up"
                ></svg:use>
            </ng-container>
        </svg>
    </div>

    <h6
        class="mt-3 text-center"
        data-cy="wdx-feature-svg-label"
        [textContent]="label"
    ></h6>
</div>
