import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileIndex } from '@wdx/clmi/api-models';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { BaseInputClass } from '../../../../../classes/base-input-class';
import { IMAGE_FILE_TYPES } from '../../../../../constants/image-file-types.constants';
import { ExtendedFileIndex } from '../../../../../models/file-details.model';
import { FileExtensions } from '../../../../../models/file-extension.model';
import { UploaderType } from '../../../../../models/uploader-type.model';
import { RandomStringPipe } from '../../../../../pipes/random-string.pipe';
import {
    FileUploadPackage,
    FileUploadStatus,
} from '@wdx/shared/infrastructure/file-io';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-input-upload-dumb',
    templateUrl: './molecule-input-upload-dumb.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'input-upload' },
})
export class MoleculeInputUploadDumbComponent
    extends BaseInputClass
    implements OnInit
{
    @Input() type: UploaderType;
    @Input() fileUploadStatus: FileUploadStatus;
    @Input() fileUploadProgress: number;
    @Input() fileIndex: FileIndex;
    @Input() fileUrl: string;
    @Input() previewFileUrl: string;

    @Output() fileAdded = new EventEmitter<FileUploadPackage>();
    @Output() removeFile = new EventEmitter<FileUploadPackage>();
    @Output() downloadFile = new EventEmitter<FileIndex>();

    fileUploadPackage: FileUploadPackage;
    acceptedFileTypes = '*';
    isAcceptedFileFormat = true;
    rejectedFileTypeMessage = '';

    readonly UPLOADER_TYPE = UploaderType;
    readonly FILE_UPLOAD_STATUS = FileUploadStatus;

    constructor(elementRef: ElementRef, public sanitizer: DomSanitizer) {
        super();

        this.patchInjectedItems({ elementRef });
    }

    ngOnInit() {
        this.restrictAccepted();
    }

    restrictAccepted() {
        if (
            this.formInputData.formatsAccepted &&
            this.formInputData.formatsAccepted !== ''
        ) {
            this.acceptedFileTypes = this.fileExtensionToFileTypeMapping(
                this.formInputData.formatsAccepted
            );
        } else {
            if (this.type === UploaderType.Image) {
                this.acceptedFileTypes =
                    'image/jpeg,image/jpg,image/png,image/gif';
            }
        }
    }

    fileExtensionToFileTypeMapping(fileExtension: string): string {
        switch (fileExtension) {
            case FileExtensions.docx:
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            default:
                '*';
        }
    }

    sanitize(url: string, sanitizer: DomSanitizer): SafeUrl {
        return sanitizer.bypassSecurityTrustUrl(url);
    }

    statusStyling(status: FileUploadStatus): string {
        return {
            [FileUploadStatus.Ready]: 'success',
            [FileUploadStatus.Requested]: 'success',
            [FileUploadStatus.Started]: 'success',
            [FileUploadStatus.Failed]: 'danger',
            [FileUploadStatus.Completed]: 'success',
        }[status];
    }

    fileIsImage(fileIndex: ExtendedFileIndex): boolean {
        return IMAGE_FILE_TYPES.some((type) => {
            return (
                fileIndex?.contentType === 'image/' + type ||
                fileIndex?.url?.match(/\.(gif|jpe?g|png)/gi)
            );
        });
    }

    previewUrl(
        fileIndex: FileIndex,
        fileUrl: string,
        previewFileUrl: string
    ): string {
        return fileIndex && (fileUrl || previewFileUrl);
    }

    onAddFiles(event: NgxDropzoneChangeEvent) {
        if (event.rejectedFiles.length) {
            this.isAcceptedFileFormat = false;
            this.rejectedFileTypeMessage = `
                File upload failed due to file
                ${(event.rejectedFiles[0] as any)?.reason}
            `;
        } else {
            this.onRemoveFile();

            this.fileUploadPackage = {
                id: new RandomStringPipe().transform(),
                file: event.addedFiles[0],
            };

            this.fileAdded.emit(this.fileUploadPackage);
            this.isAcceptedFileFormat = true;
        }
    }

    onRemoveFile() {
        this.removeFile.emit(this.fileUploadPackage);
        this.fileUploadPackage = undefined;
    }

    onDownloadFile() {
        this.downloadFile.emit(this.fileIndex);
    }
}
