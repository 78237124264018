import { createAction, props } from '@ngrx/store';
import {
    Client,
    HeatMap,
    KeyStatResult,
    PartyRoleRelationship,
    ProductType,
    RelationshipTypeSimple,
} from '@wdx/clmi/api-models';

export const getClient = createAction(
    '[Clients] Get Client',
    props<{
        clientId: string;
    }>()
);

export const getClientSuccess = createAction(
    '[Clients] Get Client Success',
    props<{
        client: Client;
    }>()
);

export const getClientFailure = createAction(
    '[Clients] Get Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);

export const getFavourites = createAction('[Clients] Get Favourites');

export const getFavouritesSuccess = createAction(
    '[Clients] Get Favourites Success',
    props<{
        favourites: Client[];
    }>()
);

export const getFavouritesFailure = createAction(
    '[Clients] Get Favourites Failure',
    props<{
        error: Error;
    }>()
);

export const getAccountHoldersForClient = createAction(
    '[Clients] Get Account Holders For Client',
    props<{
        clientId: string;
    }>()
);

export const getAccountHoldersForClientSuccess = createAction(
    '[Clients] Get Account Holders For Client Success',
    props<{
        accountHoldersForClient: PartyRoleRelationship[];
        clientId: string;
    }>()
);

export const getAccountHoldersForClientFailure = createAction(
    '[Clients] Get Account Holders For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);

export const getAuthorisedPartiesForClient = createAction(
    '[Clients] Get Authorised Parties For Client',
    props<{
        clientId: string;
    }>()
);

export const getAuthorisedPartiesForClientSuccess = createAction(
    '[Clients] Get Authorised Parties For Client Success',
    props<{
        authorisedPartiesForClient: PartyRoleRelationship[];
        clientId: string;
    }>()
);

export const getAuthorisedPartiesForClientFailure = createAction(
    '[Clients] Get Authorised Parties For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);

export const getOtherPartiesForClient = createAction(
    '[Clients] Get Other Parties For Client',
    props<{
        clientId: string;
    }>()
);

export const getOtherPartiesForClientSuccess = createAction(
    '[Clients] Get Other Parties For Client Success',
    props<{
        otherPartiesForClient: PartyRoleRelationship[];
        clientId: string;
    }>()
);

export const getOtherPartiesForClientFailure = createAction(
    '[Clients] Get Other Parties For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);

export const getApplicableRolesForClient = createAction(
    '[Clients] Get Applicable Roles',
    props<{
        clientId: string;
    }>()
);

export const getApplicableRolesForClientSuccess = createAction(
    '[Clients] Get Applicable Roles Success',
    props<{
        applicableRoles: RelationshipTypeSimple[];
        clientId: string;
    }>()
);

export const getApplicableRolesForClientFailure = createAction(
    '[Clients] Get Applicable Roles Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);

export const getPartiesForClient = createAction(
    '[Clients] Get Parties For Client',
    props<{
        clientId: string;
    }>()
);

export const getPartiesForClientSuccess = createAction(
    '[Clients] Get Parties For Client Success',
    props<{
        clientParties: PartyRoleRelationship[];
        clientId: string;
    }>()
);

export const getPartiesForClientFailure = createAction(
    '[Clients] Get Parties For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);

export const getKeyStatsForClient = createAction(
    '[Clients] Get Key Stats for Party',
    props<{
        clientId: string;
    }>()
);

export const getKeyStatsForClientSuccess = createAction(
    '[Clients] Get Key Stats for Party Success',
    props<{
        keyStatsForClient: KeyStatResult[];
        clientId: string;
    }>()
);

export const getKeyStatsForClientFailure = createAction(
    '[Clients] Get Key Stats for Party Failure',
    props<{
        error: Error;
        clientId: string;
    }>()
);

export const getHeatMapForClient = createAction(
    '[Clients] Get HeatMap For Client',
    props<{
        clientId: string;
    }>()
);

export const getHeatMapForClientSuccess = createAction(
    '[Clients] Get HeatMap For Client Success',
    props<{
        clientId: string;
        heatMap: HeatMap;
    }>()
);

export const getHeatMapForClientFailure = createAction(
    '[Clients] Get HeatMap For Client Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);

export const deleteRelationshipForId = createAction(
    '[Clients] Delete Relationship For Id',
    props<{
        clientId: string;
        relationshipId: string;
        role: PartyRoleRelationship;
    }>()
);

export const deleteRelationshipForIdSuccess = createAction(
    '[Clients] Delete Relationship For Id Success',
    props<{
        clientId: string;
        relationshipId: string;
        role: PartyRoleRelationship;
    }>()
);

export const deleteRelationshipForIdFailure = createAction(
    '[Clients] Delete Relationship For Id Failure',
    props<{
        clientId: string;
        relationshipId: string;
        role: PartyRoleRelationship;
        error: Error;
    }>()
);

export const getClientProductType = createAction(
    '[Clients] Get Product Type',
    props<{
        clientId: string;
    }>()
);

export const getClientProductTypeSuccess = createAction(
    '[Clients] Get Product Type Success',
    props<{
        clientId: string;
        productTypes: ProductType[];
    }>()
);

export const getClientProductTypeFailure = createAction(
    '[Clients] Get Product Type Failure',
    props<{
        clientId: string;
        error: Error;
    }>()
);
