import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomModalWrapperModule } from '../../../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeWidgetContainerModule } from '../../../../../components/molecules/molecule-widget-container/molecule-widget-container.module';
import { OrganismModalModule } from '../../../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../../../directives/directives.module';
import { MoleculeArticleCardModule } from '../../../../../features/articles/shared/components/molecule-article-card/molecule-article-card.module';
import { OrganismArticlesListModule } from '../../../../../features/articles/shared/components/organism-articles-list/organism-articles-list.module';
import { WidgetArticlesListComponent } from './widget-articles-list.component';

@NgModule({
    imports: [
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        MoleculeArticleCardModule,
        MoleculeWidgetContainerModule,
        OrganismArticlesListModule,
        OrganismModalModule,
    ],
    declarations: [WidgetArticlesListComponent],
    exports: [WidgetArticlesListComponent],
})
export class WidgetArticlesListModule {}
