import { Pipe, PipeTransform } from '@angular/core';
import { Feed } from '@wdx/clmi/api-models';

@Pipe({ name: 'clientFeedsAsFirstFeedCount' })
export class ClientFeedsAsFirstFeedCountPipe implements PipeTransform {
    transform(clientFeeds: Feed[]): number {
        if (clientFeeds && clientFeeds.length > 0 && clientFeeds[0].nudges) {
            const feedFiltered = clientFeeds[0].nudges;
            return feedFiltered.length;
        }
        return 0;
    }
}
