import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileIndex } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class ExtractPdfService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    extractPdf(
        file: FileIndex[],
        entityId: string,
        formName: string
    ): Observable<any> {
        return this.http.put<any>(
            `${this.config.getConfiguration().API_BASE}/formdata/extractPdf/${
                file[0].fileIndex
            }/${formName}/${entityId}`,
            {}
        );
    }
}
