import { CrudStatus } from '../../state';

export function setCrudStatus(status: CrudStatus): {
    status: CrudStatus;
    statusString: string;
} {
    return {
        status,
        statusString: CrudStatus[status],
    };
}
