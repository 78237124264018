import { Action, createReducer, on } from '@ngrx/store';
import { ActivityComment } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { DateTime } from 'luxon';
import * as commentsActions from './comments.actions';

export interface State {
    comments: CrudStateObject<ActivityComment>;
}

export const initialState: State = {
    comments: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        commentsActions.create,
        (state, props): State => ({
            ...state,
            comments: {
                ...state.comments,
                [props.activityId]: {
                    ...(state.comments[props.activityId] ||
                        ({} as CrudState<ActivityComment>)),
                    isCreating: true,
                    hasCreatingError: false,
                },
            },
        })
    ),

    on(commentsActions.createSuccess, (state, props): State => {
        let existingComments = [
            ...(state.comments[props.activityId]?.infinity?.combinedList || []),
        ];

        if (props.resolveIssueId) {
            existingComments = existingComments.map((comment) => {
                if (comment.id === props.resolveIssueId) {
                    return {
                        ...comment,
                        isIssue: false,
                        resolvingChat: {
                            id: props.comment.id,
                        },
                        resolvedBy: props.comment.party,
                        resolvedOn: props.comment.dateOccurred,
                    };
                }
                return comment;
            });
        }

        return {
            ...state,
            comments: {
                ...state.comments,
                [props.activityId]: {
                    isCreating: false,
                    hasCreatingError: false,
                    infinity: {
                        paging: {
                            ...state.comments[props.activityId]?.infinity
                                ?.paging,
                            totalRecords:
                                (state.comments[props.activityId]?.infinity
                                    ?.paging.totalRecords || 0) + 1,
                        },
                        combinedList: [...[props.comment], ...existingComments],
                    },
                },
            },
        };
    }),

    on(
        commentsActions.createFailure,
        (state, props): State => ({
            ...state,
            comments: {
                ...state.comments,
                [props.activityId]: {
                    ...(state.comments[props.activityId] ||
                        ({} as CrudState<ActivityComment>)),
                    isCreating: false,
                    hasCreatingError: true,
                },
            },
        })
    ),

    on(
        commentsActions.getPage,
        (state, props): State => ({
            ...state,
            comments: {
                ...state.comments,
                [props.activityId]: {
                    ...(state.comments[props.activityId] ||
                        ({} as CrudState<ActivityComment>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.comments[props.activityId]?.infinity,
                },
            },
        })
    ),

    on(
        commentsActions.getPageSuccess,
        (state, props): State => ({
            ...state,
            comments: {
                ...state.comments,
                [props.activityId]: {
                    ...(state.comments[props.activityId] ||
                        ({} as CrudState<ActivityComment>)),
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.comments.paging,
                        combinedList: [
                            ...(props.reset
                                ? []
                                : state.comments[props.activityId].infinity
                                      ?.combinedList || []),
                            ...props.comments.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        commentsActions.getPageFailure,
        (state, props): State => ({
            ...state,
            comments: {
                ...state.comments,
                [props.activityId]: {
                    ...(state.comments[props.activityId] ||
                        ({} as CrudState<ActivityComment>)),
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        commentsActions.getCommentsSilentlySuccess,
        (state, props): State => ({
            ...state,
            comments: {
                ...state.comments,
                [props.activityId]: {
                    ...(state.comments[props.activityId] ||
                        ({} as CrudState<ActivityComment>)),
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.comments.paging,
                        combinedList: [...props.comments.results],
                    },
                },
            },
        })
    ),

    on(commentsActions.updateCommentData, (state, props): State => {
        const UPDATED_RESOLVE = [
            ...state.comments[props.activityId].infinity.combinedList.map(
                (comment) =>
                    comment.isIssue
                        ? {
                              ...comment,
                              isIssue: false,
                              resolvedBy: props.me,
                              resolvedOn: DateTime.now().toUTC().toISO(),
                          }
                        : comment
            ),
        ] as ActivityComment[];

        return {
            ...state,
            comments: {
                ...state.comments,
                [props.activityId]: {
                    ...state.comments[props.activityId],
                    infinity: {
                        paging: {
                            ...state.comments[props.activityId].infinity.paging,
                        },
                        combinedList: [...UPDATED_RESOLVE],
                    },
                },
            },
        };
    })
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
