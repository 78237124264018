import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

/**
 * This DOWNLOAD helper will take a blob response as download it as a file.
 *
 * @param {string} name - name of the file that is going to be downloaded
 * @param {HttpResponse<Blob>} response - the request response that contains a blob as its response
 */
export const DOWNLOAD = (name: string, response: HttpResponse<Blob>) => {
    const blob = new Blob([response.body as Blob], {
        type: response.body?.type,
    });

    saveAs(blob, name);
};
