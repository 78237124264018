import { SystemEntity } from '@wdx/clmi/api-models';
import { createAction, props } from '@ngrx/store';

export const openDuplicatesModal = createAction(
    '[Duplication Check] Open Duplication Check Modal',
    props<{
        formId?: string;
        entityId?: string;
        entityType?: SystemEntity;
        formData?: any;
        payload: any[];
    }>()
);
