import { createAction, props } from '@ngrx/store';

import { FormDataHistory, Locale, User, UserKpi } from '@wdx/clmi/api-models';

export const getMeAction = createAction('[User] Get Me');

export const getMeSuccessAction = createAction(
    '[User] Get Me Success',
    props<{
        user: User;
    }>()
);

export const getMeFailureAction = createAction(
    '[User] Get Me Failure',
    props<{
        error: Error;
    }>()
);

export const getMeLocaleAction = createAction('[User] Get Me Locale');

export const getMeLocaleSuccessAction = createAction(
    '[User] Get Me Locale Success',
    props<{
        meLocale: Locale;
    }>()
);

export const getMeLocaleFailureAction = createAction(
    '[User] Get Me Locale Failure',
    props<{
        error: Error;
    }>()
);

export const changeTenantAction = createAction(
    '[User] Change Tenant',
    props<{
        tenantCode: string;
    }>()
);

export const changeTenantSuccessAction = createAction(
    '[User] Change Tenant Success',
    props<{
        tenantCode: string;
    }>()
);

export const changeTenantFailureAction = createAction(
    '[User] Change Tenant Failure',
    props<{
        error: Error;
    }>()
);

export const getForIdAction = createAction(
    '[User] Get For ID',
    props<{
        userId: string;
    }>()
);

export const getForIdSuccessAction = createAction(
    '[User] Get For ID Success',
    props<{
        user: User;
    }>()
);

export const getForIdFailureAction = createAction(
    '[User] Get For ID Failure',
    props<{
        userId: string;
        error: Error;
    }>()
);

export const getHistoryForIdAction = createAction(
    '[User] Get History For ID',
    props<{
        userId: string;
    }>()
);

export const getHistoryForIdSuccessAction = createAction(
    '[User] Get History For ID Success',
    props<{
        userId: string;
        history: FormDataHistory[];
    }>()
);

export const getHistoryForIdFailureAction = createAction(
    '[User] Get History For ID Failure',
    props<{
        userId: string;
        error: Error;
    }>()
);

export const getKPIsForIdAction = createAction(
    '[User] Get KPIs For ID',
    props<{
        userId: string;
    }>()
);

export const getKPIsForIdSuccessAction = createAction(
    '[User] Get KPIs For ID Success',
    props<{
        userId: string;
        kpi: UserKpi;
    }>()
);

export const getKPIsForIdFailureAction = createAction(
    '[User] Get KPIs For ID Failure',
    props<{
        userId: string;
        error: Error;
    }>()
);

export const changePasswordAction = createAction(
    '[User] Change Password',
    props<{
        oldPassword: string;
        newPassword: string;
    }>()
);

export const changePasswordSuccessAction = createAction(
    '[User] Change Password Success'
);

export const changePasswordErrorAction = createAction(
    '[User] Change Password Error',
    props<{
        error: Error;
    }>()
);

export const changeAppLanguageAction = createAction(
    '[User] Change Application Language',
    props<{ languageCode: string }>()
);

export const changeAppLanguageSuccessAction = createAction(
    '[User] Change Application Language success'
);

export const changeAppLanguageFailureAction = createAction(
    '[User] Change Application Language Failure',
    props<{ error: Error }>()
);

export const changeAppCurrencyAction = createAction(
    '[User] Change Application Currency',
    props<{ isoCode: string }>()
);

export const changeAppCurrencySuccessAction = createAction(
    '[User] Change Application Currency success'
);

export const changeAppCurrencyFailureAction = createAction(
    '[User] Change Application Currency Failure',
    props<{ error: Error }>()
);
