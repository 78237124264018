import { createAction, props } from '@ngrx/store';
import { Campaign, CampaignCreate } from '@wdx/clmi/api-models';

export const getCampaigns = createAction('[Marketing] Get Campaigns');
export const getCampaignsSuccess = createAction(
    '[Marketing] Get Campaigns Success',
    props<{
        campaigns: Campaign[];
    }>()
);
export const getCampaignsFailure = createAction(
    '[Marketing] Get Campaigns Failure',
    props<{
        error: Error;
    }>()
);

export const createCampaign = createAction(
    '[Marketing] Create Campaign',
    props<{
        create: CampaignCreate;
    }>()
);
export const createCampaignSuccess = createAction(
    '[Marketing] Create Campaign Success',
    props<{
        campaign: Campaign;
    }>()
);
export const createCampaignFailure = createAction(
    '[Marketing] Create Campaign Failure',
    props<{
        error: Error;
    }>()
);

export const getSingleCampaign = createAction(
    '[Marketing] Get Single Campaign',
    props<{
        campaignId: string;
    }>()
);

export const getSingleCampaignSuccess = createAction(
    '[Marketing] Get Single Campaign Success',
    props<{
        campaignId: string;
        campaign: Campaign;
    }>()
);

export const getSingleCampaignFailure = createAction(
    '[Marketing] Get Single Campaign Failure',
    props<{
        campaignId: string;
        error: Error;
    }>()
);

