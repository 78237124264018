import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FormDataHistory, Team, TeamMember, User } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class TeamService {
    private http = inject(HttpClient);
    private config = inject(ConfigService);

    getForId(teamId: string): Observable<Team> {
        return this.http.get<Team>(
            `${this.config.getConfiguration().API_BASE}/team/${teamId}`
        );
    }

    getTeamMembersForId(teamId: string): Observable<TeamMember[]> {
        return this.http.get<User[]>(
            `${this.config.getConfiguration().API_BASE}/team/${teamId}/member`
        );
    }

    deleteTeamMember(teamId: string, memberId: string): Observable<any> {
        return this.http.delete(
            `${
                this.config.getConfiguration().API_BASE
            }/team/${teamId}/member/${memberId}`
        );
    }

    getHistoryForId(teamId: string): Observable<FormDataHistory[]> {
        return this.http.get<FormDataHistory[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/formdata/teamform/${teamId}/history`
        );
    }

    addTeamMember(teamId: string, userId: string): Observable<TeamMember> {
        return this.http.post<TeamMember>(
            `${
                this.config.getConfiguration().API_BASE
            }/team/${teamId}/member/${userId}`,
            {}
        );
    }
}
