<div class="rounded" data-cy="comment-card" [ngClass]="classes">
    <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="d-flex align-items-center">
            <ng-content></ng-content>
        </div>

        <ng-content select="[action]"></ng-content>
    </div>

    <div class="mb-2" data-cy="comment-card-content">
        <ng-content select="[comments]"></ng-content>
    </div>

    <ng-content select="[meta]"></ng-content>
</div>
