import { RAG } from '@wdx/clmi/api-models';
import { WdxThemeColor } from '@wdx/shared/utils';

import { KeyValueObject, Severity } from '@wdx/shared/utils';
import { NULL, UNDEFINED } from './null.constants';

export const RAG_STATUS_SEVERITY_MAP = {
    [NULL]: Severity.None,
    [UNDEFINED]: Severity.None,
    [RAG.Red]: Severity.Danger,
    [RAG.Amber]: Severity.Warning,
    [RAG.Green]: Severity.Success,
};

export const SEVERITY_RAG_STATUS_MAP = {
    [Severity.Danger]: RAG.Red,
    [Severity.Warning]: RAG.Amber,
    [Severity.Success]: RAG.Green,
};

export const RAG_THEME_COLOR_MAP: KeyValueObject<WdxThemeColor> = {
    [RAG.Red]: 'danger',
    [RAG.Amber]: 'warning',
    [RAG.Green]: 'success',
};
