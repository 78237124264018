import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
    DOCUMENT_FORM_ID,
    Document,
    FormSetup,
    KeyValueObject,
    LookupSourceType,
    UTILS_ICON_ADD,
} from '@wdx/shared/utils';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseWdxFormControlClass } from '../../../abstract-classes/base-form-control.class';
import {
    CreatedDocumentInfo,
    IFormDocumentHandler,
    IFormDynamicData,
} from '../../../interfaces';
import { FormContextualDataService } from '../../../services';

@Component({
    selector: 'wdx-ff-document-lookup-control',
    templateUrl: './form-document-lookup-control.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDocumentLookupControlComponent
    extends BaseWdxFormControlClass
    implements OnInit
{
    addDocumentFormSetup$ = new BehaviorSubject<FormSetup>(null as any);
    UTILS_ICON_ADD = UTILS_ICON_ADD;

    readonly DOCUMENT_FORM_ID = DOCUMENT_FORM_ID;

    get documents(): Document[] {
        return this.formControl?.value || [];
    }

    get label() {
        return `${this.formElement?.label} (${
            this.formControl?.value?.length || 0
        })`;
    }

    constructor(
        public override controlContainer: ControlContainer,
        public override dynamicDataService: IFormDynamicData,
        private cdf: ChangeDetectorRef,
        private formContextualDataService: FormContextualDataService,
        private documentHandler: IFormDocumentHandler
    ) {
        super(controlContainer, dynamicDataService);
    }

    ngOnInit() {
        this.addDocumentFormSetup$.next(this.getAddDocumentFormSetup());

        this.controlContainer.control?.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() =>
                this.addDocumentFormSetup$.next(this.getAddDocumentFormSetup())
            );

        this.documentHandler
            .createDocumentSuccess$()
            .subscribe((info: CreatedDocumentInfo) => this.updateForm(info));

        this.documentHandler
            .deleteDocumentSuccess$()
            .subscribe((id) => this.removeDocumentFromForm(id));
    }

    getAddDocumentFormSetup(): FormSetup {
        return {
            isQuickCreate: true,
            preserveActiveModal: true,
            formId: DOCUMENT_FORM_ID,
            initialisationParams: {
                ...this.getContextualInitialisationParams(),
                ...this.getSchemaInitialisationParams(),
            },
        };
    }

    getSchemaInitialisationParams(): KeyValueObject {
        const documentType = this.formElement?.documentType?.code;
        const documentCategory = this.formElement?.documentCategory;
        return {
            ...(documentType && { documentType }),
            ...(documentCategory && { documentCategory }),
        };
    }

    getContextualInitialisationParams(): KeyValueObject {
        return this.formElement.contextualValues
            ?.filter((contextualValue) =>
                this.formContextualDataService.useFormData(contextualValue)
            )
            .reduce((current, contextualValue) => {
                const propName =
                    this.formContextualDataService.getPropName(contextualValue);
                const propValue =
                    this.formStaticService.form.getRawValue()[propName];

                if (Array.isArray(propValue) && propValue?.length) {
                    return {
                        ...current,
                        [`${propName}Id`]: propValue
                            .map((prop) => prop.id)
                            .join(','),
                    };
                }

                if (propValue?.id) {
                    return {
                        ...current,
                        [`${propName}Id`]: propValue?.id,
                    };
                }
                return current;
            }, {}) as KeyValueObject;
    }

    updateForm(docInfo: CreatedDocumentInfo): void {
        const { id, name, fileIndex } = docInfo;
        const updateIndex = this.documents.findIndex((val) => val.id === id);
        const updateValue = {
            id,
            type: LookupSourceType.Document,
            name,
            fileIndex,
        };
        if (updateIndex > -1) {
            this.formControl?.setValue(
                this.documents.map((val) => {
                    if (val.id === id) {
                        return updateValue;
                    }
                    return val;
                })
            );
        } else {
            this.formControl?.setValue([...this.documents, ...[updateValue]]);
        }
    }

    removeDocumentFromForm(id: string): void {
        this.formControl?.setValue(
            this.documents.filter((document) => document.id !== id)
        );
        this.cdf.detectChanges();
    }

    editDocument(formSetup: FormSetup) {
        this.documentHandler.editDocument(formSetup);
    }

    addDocument(formSetup: FormSetup) {
        this.documentHandler.addDocument(formSetup);
    }

    onDeleteDocument(document: Document): void {
        this.documentHandler.deleteDocument(
            document.id as string,
            document.name as string
        );
    }
}
