import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as rootReducer from '../../state/_setup/reducers';
import * as messageBatchActions from './batch-message.actions';
import { BatchMessageService } from './batch-message.service';
@Injectable()
export class BatchMessageEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private batchMessageService = inject(BatchMessageService);

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(messageBatchActions.getAll),
            switchMap(() =>
                this.batchMessageService.getBatchMessage().pipe(
                    map((batchMessageList) =>
                        messageBatchActions.getAllSuccess({
                            batchMessageList,
                        })
                    ),
                    catchError((error) =>
                        of(
                            messageBatchActions.getAllFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getMessageById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(messageBatchActions.getBatchMessageById),
            switchMap((action) =>
                this.batchMessageService
                    .getBatchMessageByBatchId(action.batchMessageId)
                    .pipe(
                        map((messageBatch) =>
                            messageBatchActions.getBatchMessageByIdSuccess({
                                batchMessageId: action.batchMessageId,
                                batchMessage: messageBatch,
                            })
                        ),
                        catchError((error) =>
                            of(
                                messageBatchActions.getBatchMessageByIdFailure({
                                    batchMessageId: action.batchMessageId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    deleteBatchMessageRow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(messageBatchActions.deleteBatchMessageRow),
            mergeMap((action) =>
                this.batchMessageService.deleteBatchMessageRow(action.id).pipe(
                    map(() =>
                        messageBatchActions.deleteBatchMessageRowSuccess({
                            id: action.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            messageBatchActions.deleteBatchMessageRowFailure({
                                id: action.id,
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );

    getSingleBatchMessageRow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(messageBatchActions.getSingleBatchMessage),
            mergeMap((action) =>
                this.batchMessageService.getSingleBatchMessage(action.id).pipe(
                    map((msg) =>
                        messageBatchActions.getSingleBatchMessageSuccess({
                            id: action.id,
                            batchMessage: msg,
                        })
                    ),
                    catchError((error) =>
                        of(
                            messageBatchActions.getSingleBatchMessageFailure({
                                id: action.id,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    replay$ = createEffect(() =>
        this.actions$.pipe(
            ofType(messageBatchActions.replay),
            mergeMap((action) =>
                this.batchMessageService.replayMessage(action.id).pipe(
                    map((msg) =>
                        messageBatchActions.replaySuccess({
                            id: action.id,
                            batchMessage: msg,
                        })
                    ),
                    catchError((error) =>
                        of(
                            messageBatchActions.replayFailure({
                                error,
                                id: action.id,
                            })
                        )
                    )
                )
            )
        )
    );

    replayMultiple$ = createEffect(() =>
        this.actions$.pipe(
            ofType(messageBatchActions.replayMultiple),
            mergeMap((action) =>
                this.batchMessageService.replayMultipleMessage(action.ids).pipe(
                    map(() =>
                        messageBatchActions.replayMultipleSuccess({
                            ids: action.ids,
                        })
                    ),
                    catchError((error) =>
                        of(
                            messageBatchActions.replayMultipleFailure({
                                error,
                                ids: action.ids,
                            })
                        )
                    )
                )
            )
        )
    );

    deleteMultiple$ = createEffect(() =>
        this.actions$.pipe(
            ofType(messageBatchActions.deleteMultipleMessage),
            mergeMap((action) =>
                this.batchMessageService
                    .deleteMultipleBatchMessage(action.ids)
                    .pipe(
                        map(() =>
                            messageBatchActions.deleteMultipleMessageSuccess({
                                ids: action.ids,
                            })
                        ),
                        catchError((error) =>
                            of(
                                messageBatchActions.deleteMultipleMessageFailure(
                                    {
                                        error,
                                        ids: action.ids,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
