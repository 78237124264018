import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxFormFrameworkModule } from '@wdx/shared/infrastructure/form-framework';
import { TranslateTokenisedStringPipe } from '@wdx/shared/utils';
import { AtomAsyncDataModule } from '../../../components/atoms/atom-async-data/atom-async-data.module';
import { AtomFeatureSvgModule } from '../../../components/atoms/atom-feature-svg/atom-feature-svg.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { CLMiReactiveFormsModule } from '../../../features/reactive-forms/reactive-forms.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { OrganismDynamicFormModalComponent } from './organism-dynamic-form-modal.component';

@NgModule({
    imports: [
        AtomAsyncDataModule,
        AtomModalWrapperModule,
        AtomFeatureSvgModule,
        CLMiReactiveFormsModule,
        CommonModule,
        OrganismModalModule,
        PipesModule,
        WdxFormFrameworkModule,
        TranslateTokenisedStringPipe,
    ],
    declarations: [OrganismDynamicFormModalComponent],
    exports: [OrganismDynamicFormModalComponent],
})
export class OrganismDynamicFormModalModule {}
