import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import {
    FormDisplayMode,
    NavItem,
    TRANSLATION_LEGEND_ITEM_DUE,
    TRANSLATION_LEGEND_ITEM_ISSUE_REPORTED,
    TRANSLATION_LEGEND_ITEM_NO_SLA_DATE_SET,
    TRANSLATION_LEGEND_ITEM_ON_TIME,
    WdxSize,
} from '@wdx/shared/utils';
import { takeUntil } from 'rxjs/operators';
import { BaseDiDependantClass } from '../../../classes/base-di-dependant.class';
import { FilterHandler } from '../../../classes/filter-handler.class';
import { USER_FEEDBACK_NO_RESULTS } from '../../../constants/user-feedback.constants';
import { CardType } from '../../../models/card-type.model';
import { CaseCardItem } from '../../../models/case-list-item.model';
import { DropdownButtonStyle } from '../../../models/dropdown-button-style.model';
import { LegendItem } from '../../../models/legend-item.model';
import { OpportunityCardItem } from '../../../models/opportunity-card-item.model';
import { ScrollMode } from '../../../models/scroll-mode.model';
import { FeatureSvg } from '../../../models/svg.model';
import { ToDoItem } from '../../../models/to-do-item.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-cards-list',
    templateUrl: './organism-cards-list.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'overflow-hidden' },
})
export class OrganismCardsListComponent
    extends BaseDiDependantClass
    implements OnInit
{
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() cardType: CardType;
    @Input() caseCards: CaseCardItem[];
    @Input() opportunityCards: OpportunityCardItem[];
    @Input() activityCards: ToDoItem[];
    @Input() totalRecordCount: number;
    @Input() cardsPerRow: number;
    @Input() filterHandler: FilterHandler;
    @Input() filtersModalId: string;
    @Input() formFilterName: string;
    @Input() hasFilterSearch = false;
    @Input() sortMenu: NavItem[];
    @Input() showFilters: boolean;
    @Input() showFilterBar = true;
    @Input() showSearchBar = true;
    @Input() showFavourite = true;
    @Input() showLegend = true;
    @Input() showNoDataSVG: boolean;
    @Input() noDataSVGSize: WdxSize;
    @Input() noDataMessage = USER_FEEDBACK_NO_RESULTS;
    @Input() scrollMode = ScrollMode.Default;
    @Input() limit: number;
    @Input() searchType: string;
    @Input() searchTypePlural: string;
    @Input() searchPlaceholder: string;
    @Input() addWidgetContainerPadding: boolean;
    @Input() isSuggestedCardList: boolean;
    @Input() useSortToggleMenu: boolean;
    @Input() cySelector: string;

    @Output() search = new EventEmitter<string>();
    @Output() infinityScrollFired = new EventEmitter();
    @Output() cardActionButtonClicked = new EventEmitter<
        CaseCardItem | OpportunityCardItem
    >();
    @Output() deleteRelatedRecordLink = new EventEmitter<string>();
    @Output() toggleFavourite = new EventEmitter<{
        id: string;
        isFavourite: boolean;
    }>();

    @Output() closeActiveModal = new EventEmitter();

    @ViewChildren('tileCarouselCard', { read: ElementRef })
    tileCarouselCards: QueryList<ElementRef>;

    dropdownButtonStyle = DropdownButtonStyle.Label;
    favouriteFilterToggle = false;

    legendItems: LegendItem[] = [
        {
            label: 'On time',
            translationKey: TRANSLATION_LEGEND_ITEM_ON_TIME,
            themeColor: 'success',
        },
        {
            label: 'Due',
            translationKey: TRANSLATION_LEGEND_ITEM_DUE,
            themeColor: 'warning',
        },
        {
            label: 'Issue reported, overdue',
            translationKey: TRANSLATION_LEGEND_ITEM_ISSUE_REPORTED,
            themeColor: 'danger',
        },
        {
            label: 'No SLA date set',
            translationKey: TRANSLATION_LEGEND_ITEM_NO_SLA_DATE_SET,
            themeColor: 'info',
        },
    ];

    readonly CARD_TYPE = CardType;
    readonly FORM_DISPLAY_MODE = FormDisplayMode;
    readonly FEATURE_SVG = FeatureSvg;
    readonly SCROLL_MODE = ScrollMode;

    ngOnInit() {
        if (this.filterHandler) {
            this.filterHandler?.valuesChanged
                .pipe(takeUntil(this.destroyed$))
                .subscribe((payload: any) => {
                    this.favouriteFilterToggle = payload?.isFavourite;
                });
        }
    }

    getCards(
        caseCards: CaseCardItem[],
        opportunityCards: OpportunityCardItem[],
        activityCards: ToDoItem[],
        cardType: CardType
    ): CaseCardItem[] | OpportunityCardItem[] | ToDoItem[] {
        if (cardType === CardType.Opportunity) {
            return opportunityCards;
        }
        if (cardType === CardType.Case) {
            return caseCards;
        }
        if (cardType === CardType.Activity) {
            return activityCards;
        }
    }

    onSearch(value: string): void {
        this.search.emit(value);
    }

    onToggleFavourite(id: string, isFavourite: boolean): void {
        this.toggleFavourite.emit({
            id,
            isFavourite,
        });
    }

    onInfinityScrollFired(): void {
        this.infinityScrollFired.emit();
    }

    onCardActionButtonClicked(card: CaseCardItem | OpportunityCardItem): void {
        this.cardActionButtonClicked.emit(card);
    }

    onDeleteRelatedRecordLinkClicked(card: OpportunityCardItem): void {
        this.deleteRelatedRecordLink.emit(card.id);
    }

    trackByCardId(
        index: number,
        card: CaseCardItem | OpportunityCardItem | ToDoItem
    ): string {
        return card.id;
    }

    onNavigateCloseActiveModal(): void {
        this.closeActiveModal.emit();
    }
}
