import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
} from '@angular/core';
import { IconLetter } from '../../../models/icon-letter.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-icon-letter',
    templateUrl: './atom-icon-letter.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'text-highlight-padding rounded font-weight-bold' },
})
export class AtomIconLetterComponent implements OnChanges {
    @Input() iconLetter: IconLetter;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnChanges() {
        if (this.iconLetter) {
            this.renderer.addClass(
                this.elementRef.nativeElement,
                `${this.iconLetter.highlight}-bold`
            );
        }
    }
}
