import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PersonalSettingsService } from '@wdx/clmi/api-services/services';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { personalSettingsActions, personalSettingsSelectors } from '.';

@Injectable()
export class PersonalSettingsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store);
    private personalSettingsService = inject(PersonalSettingsService);

    getPersonalSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(personalSettingsActions.getPersonalSettings),
            mergeMap(() =>
                this.personalSettingsService.getSettings().pipe(
                    map((settings) =>
                        personalSettingsActions.getPersonalSettingsSuccess({
                            settings,
                        })
                    ),
                    catchError((error) =>
                        of(
                            personalSettingsActions.getPersonalSettingsFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    putPersonalSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(personalSettingsActions.putPersonalSettings),
            withLatestFrom(
                this.store$.select(personalSettingsSelectors.personalSettings)
            ),
            mergeMap(([{ settings }, personalSettings]) =>
                this.personalSettingsService
                    .putSettings({
                        ...personalSettings,
                        ...settings,
                    })
                    .pipe(
                        map(() =>
                            personalSettingsActions.putPersonalSettingsSuccess()
                        ),
                        catchError((error) =>
                            of(
                                personalSettingsActions.putPersonalSettingsFailure(
                                    { error }
                                )
                            )
                        )
                    )
            )
        )
    );
}
