import { Component, Input } from '@angular/core';
import { ClientLifecycleStage } from '@wdx/clmi/api-models';
import { Icon } from '@wdx/shared/utils';
import { LIFECYCLE_STAGE_ICON_MAP } from '../../../constants/lifecycle-stage.constants';
import { POPOVER_OPEN_DELAY } from '../../../constants/popover.constants';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-lifecycle-stage-tag',
    templateUrl: './molecule-lifecycle-stage-tag.component.html',
})
export class MoleculeLifecycleStageTagComponent {
    private _lifecycleStage: ClientLifecycleStage;
    icon: Icon;

    lifecycleStageTranslation: string;

    @Input() popoverText: string;

    @Input() set lifecycleStage(stage: ClientLifecycleStage) {
        this._lifecycleStage = stage;
        this.icon = LIFECYCLE_STAGE_ICON_MAP[stage];
        this.lifecycleStageTranslation = `ENUM_CLIENTLIFECYCLESTAGE_${stage?.toUpperCase()}`;
    }
    get lifecycleStage(): ClientLifecycleStage {
        return this._lifecycleStage;
    }

    readonly POPOVER_OPEN_DELAY = POPOVER_OPEN_DELAY;
}
