import { Directive, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { WdxBaseInputControlsValueAccessorClass } from '@wdx/shared/components/abstract-classes';
import { DateTime } from 'luxon';

@Directive({
    selector: 'input[wdxInputTime]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref no-use-before-declare
            useExisting: forwardRef(() => WdxInputTimeDirective),
            multi: true,
        },
    ],
    /* eslint-disable @angular-eslint/no-host-metadata-property */
    host: {
        '(input)': 'onInput($event.target.value)',
    },
})
export class WdxInputTimeDirective extends WdxBaseInputControlsValueAccessorClass {
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        super();
    }

    onInput(value: any): void {
        this.val = value;
        this.onChange(this.val);
    }

    override writeValue(value: any): void {
        const TIME = 'HH:mm';

        if (value) {
            this.renderer.setAttribute(
                this.elementRef.nativeElement,
                'value',
                DateTime.fromJSDate(new Date(value)).toFormat(TIME)
            );
        }
        this.val = value;
    }
}
