import { createSelector } from '@ngrx/store';
import * as partyRoleReducer from './party-role.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    partyRole: partyRoleReducer.State;
}): partyRoleReducer.State => state.partyRole;

export const getForId = (id: string) =>
    createSelector(
        getState,
        (state: partyRoleReducer.State) => state.partyRoles[id]?.single
    );

export const getForIdIsLoading = (id: string) =>
    createSelector(
        getState,
        (state: partyRoleReducer.State) => state.partyRoles[id]?.isLoadingSingle
    );

export const getForIdIsLoadingError = (id: string) =>
    createSelector(
        getState,
        (state: partyRoleReducer.State) =>
            state.partyRoles[id]?.hasLoadingSingleError
    );

export const getVisibilityForIdIsLoadingList = createSelector(
    getState,
    (state: partyRoleReducer.State, props: any) =>
        state.visibility[props.id]?.isLoadingList
);
export const getVisibilityForIdHasLoadingListError = createSelector(
    getState,
    (state: partyRoleReducer.State, props: any) =>
        state.visibility[props.id]?.hasLoadingListError
);
export const getVisibilityForId = createSelector(
    getState,
    (state: partyRoleReducer.State, props: any) =>
        state.visibility[props.id]?.list
);
export const getTasksForPartyRoleInfinityPaging = createSelector(
    getState,
    (state: partyRoleReducer.State, props: any) =>
        state.tasks[props.partyRoleId]?.infinity?.paging
);
