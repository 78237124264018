import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2,
} from '@angular/core';
import { FormDefinition, FormLayout } from '@wdx/clmi/api-models';
import { FormFrameworkEvent } from '@wdx/shared/infrastructure/form-framework';
import { FormDisplayMode, FormSetup, WdxDestroyClass } from '@wdx/shared/utils';
import { takeUntil } from 'rxjs';
import { DynamicFormsManagementService } from '../services/dynamic-forms-management.service';

@Directive({
    selector: '[dynamicFormId] , [dynamicFormSetup]',
})
export class DynamicFormDirective extends WdxDestroyClass {
    @Input() dynamicFormId: string;
    @Input() dynamicFormSetup: FormSetup;
    @Input() entityId: string;
    @Input() formDisplayMode: FormDisplayMode;
    @Input() hardCodedFormDefinition: FormDefinition;
    @Input() hardCodedFormLayout: FormLayout;

    @Output() dynamicFormEvent = new EventEmitter<FormFrameworkEvent>();

    @HostListener('click') onClick() {
        if (!this.dynamicFormId && !this.dynamicFormSetup) {
            return;
        }

        const formSetup: FormSetup = {
            ...(this.dynamicFormId
                ? { formId: this.dynamicFormId || this.dynamicFormSetup.formId }
                : {}),
            ...(this.entityId ? { entityId: this.entityId } : {}),
            ...(this.formDisplayMode
                ? { formDisplayMode: this.formDisplayMode }
                : {}),
            ...(this.hardCodedFormDefinition
                ? { hardCodedFormDefinition: this.hardCodedFormDefinition }
                : {}),
            ...(this.hardCodedFormLayout
                ? { hardCodedFormLayout: this.hardCodedFormLayout }
                : {}),
            ...(this.dynamicFormSetup?.initialisationMode
                ? {
                      initialisationMode:
                          this.dynamicFormSetup.initialisationMode,
                  }
                : {}),
            ...(this.dynamicFormSetup || {}),
        };

        this.dynamicFormsManagementService
            .openDynamicFormModal(formSetup)
            .closed.pipe(takeUntil(this.destroyed$))
            .subscribe((event) => this.dynamicFormEvent.emit(event));
    }

    constructor(
        elementRef: ElementRef,
        renderer: Renderer2,
        private dynamicFormsManagementService: DynamicFormsManagementService
    ) {
        super();
        renderer.setAttribute(
            elementRef.nativeElement,
            'href',
            'javascript:void(0)'
        );
    }
}
