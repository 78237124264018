import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as prospectsActions from './prospects.actions';
import { ProspectsService } from './prospects.service';

@Injectable()
export class ProspectsEffects {
    private actions$ = inject(Actions);
    private prospectsService = inject(ProspectsService);

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(prospectsActions.create),
            switchMap((action) =>
                this.prospectsService.create(action.prospectCreateData).pipe(
                    map((prospect) =>
                        prospectsActions.createSuccess({ prospect })
                    ),
                    catchError((error) =>
                        of(prospectsActions.createFailure({ error }))
                    )
                )
            )
        )
    );
}
