import { Action, createReducer, on } from '@ngrx/store';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import { BatchMessage } from '../../models/batch.model';
import * as batchMessageTypeAction from './batch-message.actions';

export interface State {
    batchMessages: CrudStateObject<BatchMessage>;
    batchMessageId: string;
}

export const initialState: State = {
    batchMessages: {},
    batchMessageId: '',
};

const reducerSetup = createReducer(
    initialState,
    on(
        batchMessageTypeAction.getAll,
        (state): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.batchMessages[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<BatchMessage>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),
    on(
        batchMessageTypeAction.getAllSuccess,
        (state, props): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.batchMessages[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    infinity: {
                        paging: props.batchMessageList.paging,
                        combinedList: [
                            ...(state.batchMessages[GLOBAL_STATE_INDEX_ID]
                                .infinity?.combinedList || []),
                            ...props.batchMessageList.results,
                        ],
                    },
                },
            },
        })
    ),
    on(
        batchMessageTypeAction.getAllFailure,
        (state): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.batchMessages[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),
    on(
        batchMessageTypeAction.getBatchMessageById,
        (state, props): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [props.batchMessageId]: {
                    ...(state.batchMessages[props.batchMessageId] ||
                        ({} as CrudStateObject<BatchMessage>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        batchMessageTypeAction.getBatchMessageByIdSuccess,
        (state, props): State => {
            return {
                ...state,
                batchMessages: {
                    ...state.batchMessages,
                    [props.batchMessageId]: {
                        ...state.batchMessages[props.batchMessageId],
                        isLoadingList: false,
                        hasLoadingListError: false,
                        infinity: {
                            paging: props.batchMessage.paging,
                            combinedList: [
                                ...(state.batchMessages[props.batchMessageId]
                                    .infinity?.combinedList || []),
                                ...props.batchMessage.results,
                            ],
                        },
                    },
                },
            };
        }
    ),

    on(
        batchMessageTypeAction.getBatchMessageByIdFailure,
        (state, props): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [props.batchMessageId]: {
                    ...state.batchMessages[props.batchMessageId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),
    on(
        batchMessageTypeAction.emitMessageBatchId,
        (state, props): State => ({
            ...state,
            batchMessageId: props.id,
        })
    ),
    on(
        batchMessageTypeAction.getSingleBatchMessage,
        (state, props): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [props.id]: {
                    ...(state.batchMessages[props.id] ||
                        ({} as CrudStateObject<BatchMessage>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),
    on(
        batchMessageTypeAction.getSingleBatchMessageSuccess,
        (state, props): State => {
            return {
                ...state,
                batchMessages: {
                    ...state.batchMessages,
                    [props.id]: {
                        ...state.batchMessages[props.id],
                        isLoadingSingle: false,
                        hasLoadingSingleError: false,
                        single: props.batchMessage,
                    },
                },
            };
        }
    ),
    on(
        batchMessageTypeAction.getSingleBatchMessageFailure,
        (state, props): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [props.id]: {
                    ...state.batchMessages[props.id],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),
    on(
        batchMessageTypeAction.replay,
        (state, props): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [props.id]: {
                    ...(state.batchMessages[props.id] ||
                        ({} as CrudState<BatchMessage>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        batchMessageTypeAction.replaySuccess,
        (state, props): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [props.id]: {
                    ...state.batchMessages[props.id],
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        batchMessageTypeAction.replayFailure,
        (state, props): State => ({
            ...state,
            batchMessages: {
                ...state.batchMessages,
                [props.id]: {
                    ...state.batchMessages[props.id],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
