import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxCheckboxTileGroupComponent } from './wdx-checkbox-tile-group.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxCheckboxTileGroupComponent],
    exports: [WdxCheckboxTileGroupComponent],
})
export class WdxCheckboxTileGroupModule {}
