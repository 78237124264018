import { createAction, props } from '@ngrx/store';
import { NudgeType } from '@wdx/clmi/api-models';

export const getAll = createAction('[Nudge Types] Get All');

export const getAllSuccess = createAction(
    '[Nudge Types] Get All Success',
    props<{
        nudgeTypes: NudgeType[];
    }>()
);

export const getAllFailure = createAction(
    '[Nudge Types] Get All Failure',
    props<{
        error: Error;
    }>()
);
