import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import {
    PartyRoleRelationship,
    RelationshipTypeSimple,
} from '@wdx/clmi/api-models';
import { RelationshipWrapper } from '../../models/relationship-wrapper.model';

@Injectable()
export class RelationshipsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getForPartyRole(partyRoleId: string): Observable<RelationshipWrapper> {
        return this.http.get<RelationshipWrapper>(
            `${
                this.config.getConfiguration().API_BASE
            }/partyrole/${partyRoleId}/relationshipmap`
        );
    }

    getFamilyForClient(clientId: string): Observable<PartyRoleRelationship[]> {
        return this.http.get<PartyRoleRelationship[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/family`
        );
    }

    getApplicableFamilyRoles(
        clientId: string
    ): Observable<RelationshipTypeSimple[]> {
        return this.http.get<RelationshipTypeSimple[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/client/${clientId}/applicableFamilyRoles`
        );
    }
}
