import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxInputDateDirective } from './wdx-input-date.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxInputDateDirective],
    exports: [WdxInputDateDirective],
})
export class WdxInputDateModule {}
