import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'wdx-radio-tile-group',
    templateUrl: './wdx-radio-tile-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    /* eslint-disable @angular-eslint/no-host-metadata-property */
    host: {
        class: 'input-radio-tiles',
    },
})
export class WdxRadioTileGroupComponent {}
