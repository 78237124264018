import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as notesActions from './notes.actions';
import { NotesService } from './notes.service';

@Injectable()
export class NotesEffects {
    private actions$ = inject(Actions);
    private notesService = inject(NotesService);

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notesActions.deleteNote),
            mergeMap((action) =>
                this.notesService.delete(action.noteId).pipe(
                    map(() =>
                        notesActions.deleteSuccess({
                            noteId: action.noteId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            notesActions.deleteFailure({
                                noteId: action.noteId,
                                error: error,
                            })
                        )
                    )
                )
            )
        )
    );
}
