import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Locale } from '@wdx/clmi/api-models';
import { BaseApiService } from '../base-api.service';
import { LocaleUrlSegment } from './locale-url-segment';

@Injectable({
    providedIn: 'root',
})
export class LocaleApiService extends BaseApiService {
    getLocales$(): Observable<Locale[]> {
        return this.get(`${LocaleUrlSegment.Locale}`);
    }
}
