import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import {
    FieldDefinitionOption,
    FormDefinition,
    SummaryLevel,
} from '@wdx/shared/utils';
import { FormSummaryService } from '../form-summary/form-summary.service';

@Injectable()
export class FormSummaryLayoutService {
    summaryInfoCards$ = new BehaviorSubject<FieldDefinitionOption[]>([]);

    constructor(private formSummaryService: FormSummaryService) {}

    /**
     * Returns summary info cards from a given form definition schema and data
     */
    getSummaryInfoCards(definition: FormDefinition, formData: any): void {
        // This is to reset to observable data
        this.summaryInfoCards$.next([]);

        const infoCards = definition.schema
            ?.filter((item) => item.summaryLevel === SummaryLevel.InfoCard)
            .map((summaryField) => {
                const summaryLayoutItem =
                    definition.layout?.sectionLayoutDefinitions
                        ?.find((section) =>
                            section.elementLayoutDefinitions?.find(
                                (element) => element.name === summaryField.name
                            )
                        )
                        ?.elementLayoutDefinitions?.find(
                            (element) => element.name === summaryField.name
                        );
                return {
                    label: summaryLayoutItem?.label,
                    value: formData[summaryField.name as any],
                    icon: summaryField?.summaryIcon,
                    summaryField,
                };
            })
            .filter((infoCard) => Boolean(infoCard.value));

        if (infoCards?.length) {
            infoCards.forEach((infoCard) => {
                this.formSummaryService
                    .getStringValue(
                        formData[infoCard.summaryField.name as string],
                        infoCard.summaryField,
                        null as any,
                        null,
                        definition?.schema
                    )
                    .pipe(
                        map((getStringValue) => {
                            this.summaryInfoCards$.next([
                                ...this.summaryInfoCards$.value,
                                {
                                    label: infoCard?.label,
                                    value: getStringValue,
                                    icon: infoCard.summaryField?.summaryIcon,
                                },
                            ]);
                        })
                    )
                    .subscribe();
            });
        }
    }
}
