import { Action, createReducer, on } from '@ngrx/store';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as importEntitiesActionsActions from './import-entities.actions';

export const ImportEntitiesStore = 'importEntities';

export interface State {
    imports?: CrudState<string>;
}

export const initialState: State = {};

const reducerSetup = createReducer(
    initialState,

    on(
        importEntitiesActionsActions.getImportEntities,
        (state): State => ({
            ...state,
            imports: {
                ...(state.imports || ({} as CrudState<string>)),
                isLoadingList: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        importEntitiesActionsActions.getImportEntitiesSuccess,
        (state, props): State => ({
            ...state,
            imports: {
                ...(state.imports || ({} as CrudState<string>)),
                isLoadingList: false,
                hasLoadingListError: false,
                list: props.imports,
            },
        })
    ),
    on(
        importEntitiesActionsActions.getImportEntitiesFailure,
        (state): State => ({
            ...state,
            imports: {
                ...(state.imports || ({} as CrudState<string>)),
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
