import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserTenant } from '../../models';
import { LocalStorageService } from './../local-storage/local-storage.service';

export let ROUTE_PREFIX: string[] = ['/app'];

@Injectable({
    providedIn: 'root',
})
export class TenantsService {
    static TENANT_KEY = 'Tenant';
    public TENANT_CODE: string[] = [''];

    private tenants: UserTenant[] | undefined;
    private _tenantCode = '';

    get tenantCode() {
        return this._tenantCode;
    }

    private get localStorageTenantCode(): string | null {
        return this.localStorageService.getStringKey(TenantsService.TENANT_KEY);
    }

    private localStorageService = inject(LocalStorageService);
    private router = inject(Router);

    set(tenants: UserTenant[]) {
        this.tenants = tenants;
    }

    get(): UserTenant[] | undefined {
        return this.tenants;
    }

    updateTenant(
        userDefaultTenant: string,
        selectedTenant?: string | null
    ): string | null {
        if (!selectedTenant) {
            selectedTenant = this.localStorageTenantCode;
        }

        if (selectedTenant === 'app' || !selectedTenant) {
            this.router.navigate([`/${userDefaultTenant}`]);
            return userDefaultTenant;
        }

        if (!this.checkIfUserHasTenant(selectedTenant)) {
            this.clearChangeTenantCode();
            ROUTE_PREFIX = [''];
            this.TENANT_CODE = [''];
            this._tenantCode = '';
            this.router.navigate(['/tenant-access']);
            return null;
        }

        ROUTE_PREFIX = [`/${selectedTenant}`];
        this.TENANT_CODE = [`/${selectedTenant}`];
        this._tenantCode = selectedTenant as string;
        this.setChangeTenantCode(selectedTenant as string);
        return selectedTenant;
    }

    setChangeTenantCode(tenantCode: string) {
        this.localStorageService.setStringKey(
            TenantsService.TENANT_KEY,
            tenantCode
        );
    }

    clearChangeTenantCode() {
        this.localStorageService.setStringKey(TenantsService.TENANT_KEY, null);
    }

    private checkIfUserHasTenant(code: string): boolean {
        return Boolean(
            this.tenants?.some((tenant) => tenant.tenantCode === code)
        );
    }
}
