<div class="d-flex flex-column flex-sm-row">
    <div
        *ngIf="article && article.images"
        class="article-preview__image-container"
    >
        <img
            class="article-preview__image"
            [src]="article.images[0].imageUrl"
            [alt]="article.name || 'Article image'"
        />
    </div>
    <div class="article-preview__content sub-small text-justify">
        {{ content | truncateText: maxContentCharacters }}
    </div>
</div>
