import {
    LookupFieldResult,
    LookupSourceType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { createAction, props } from '@ngrx/store';

export const getLookup = createAction(
    '[Lookups] Get Lookup',
    props<{
        lookupId: string;
        lookupSource: LookupSourceType;
        searchText: string;
        entityId?: string;
        entityType?: SystemEntity;
    }>()
);

export const getLookupSuccess = createAction(
    '[Lookups] Get Lookup Success',
    props<{
        lookupId: string;
        results: LookupFieldResult[];
    }>()
);

export const getLookupFailure = createAction(
    '[Lookups] Get Lookup Failure',
    props<{
        lookupId: string;
        error: Error;
    }>()
);

export const clearLookup = createAction(
    '[Lookups] Clear Lookup',
    props<{
        lookupId: string;
    }>()
);

export const setCurrentLookupId = createAction(
    '[Lookups] Set Current Lookup Id',
    props<{
        lookupId: string;
    }>()
);

export const getContextualLookup = createAction(
    '[Lookups] Get Contextual Lookup',
    props<{
        lookupId: string;
        lookupSource: LookupSourceType;
        lookups: any[];
        searchText?: string;
    }>()
);

export const getContextualLookupSuccess = createAction(
    '[Lookups] Get Contextual Lookup Success',
    props<{
        lookupId: string;
        results: LookupFieldResult[];
    }>()
);

export const getContextualLookupFailure = createAction(
    '[Lookups] Get Contextual Lookup Failure',
    props<{
        lookupId: string;
        error: Error;
    }>()
);
