import { Pipe, PipeTransform } from '@angular/core';
import { ExtendedFieldDefinitionOption } from '../models/extended-field-definition-option.model';
import { Icon } from '@wdx/shared/utils';

@Pipe({ name: 'iconFromFieldOption' })
export class IconFromFieldOptionPipe implements PipeTransform {
    transform(option: ExtendedFieldDefinitionOption): any {
        let icon;

        if (option.iconStack) {
            icon = { stack: option.iconStack } as Icon;
        } else if (option.icon) {
            icon = option.icon;
        }
        return icon;
    }
}
