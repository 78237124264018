import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxFormFieldModule } from '@wdx/shared/components/wdx-form-field';
import { TranslatePipe } from '@wdx/shared/utils';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AtomAsyncButtonModule } from '../../../components/atoms/atom-async-button/atom-async-button.module';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { ImportFileUploaderComponent } from './import-file-uploader.component';

@NgModule({
    declarations: [ImportFileUploaderComponent],
    imports: [
        CommonModule,
        AtomModalWrapperModule,
        OrganismModalModule,
        NgxDropzoneModule,
        AtomIconButtonModule,
        AtomAsyncButtonModule,
        WdxFormFieldModule,
        TranslatePipe,
    ],
    exports: [ImportFileUploaderComponent],
})
export class ImportFileUploaderModule {}
