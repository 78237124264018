import { Component, Input } from '@angular/core';
import { PENDING_CHANGES_TEXT } from '@wdx/shared/infrastructure/form-framework';
import {
    ICON_REQUIRED,
    ICON_SENSITIVE,
} from '../../../constants/icons.constants';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-summary-field',
    templateUrl: './molecule-summary-field.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'd-block' },
})
export class MoleculeSummaryFieldComponent {
    @Input() label: string;
    @Input() isRequired: boolean;
    @Input() isSensitive: boolean;
    @Input() isPending: boolean;
    @Input() cySelector: string;

    readonly ICON_SENSITIVE = ICON_SENSITIVE.icon;
    readonly ICON_REQUIRED = ICON_REQUIRED.icon;
    readonly PENDING_CHANGES_TEXT = PENDING_CHANGES_TEXT;
}
