import { Pipe, PipeTransform } from '@angular/core';
import { FeaturesService } from '@wdx/shared/utils';
import { FeatureFlag } from '@wdx/clmi/api-services/services';

@Pipe({ name: 'hasFeature' })
export class HasFeaturePipe implements PipeTransform {
    constructor(private featuresService: FeaturesService) {}
    transform(value: FeatureFlag): boolean {
        return this.featuresService.hasFeature(value);
    }
}
