import { createSelector } from '@ngrx/store';
import * as articlesReducer from './articles.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    articles: articlesReducer.State;
}): articlesReducer.State => state.articles;

/**
 * Reducer property selectors
 */
export const getIsCreating = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.articles[props.id]?.isCreating
);
export const getHasCreatingError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.articles[props.id]?.hasCreatingError
);
export const getIsUpdating = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.articles[props.id]?.isUpdating
);
export const getHasUpdatingError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.articles[props.id]?.hasUpdatingError
);
export const getIsParsing = createSelector(
    getState,
    (state: articlesReducer.State) => state.articles.parsed?.isCreating
);
export const getHasParsingError = createSelector(
    getState,
    (state: articlesReducer.State) => state.articles.parsed?.hasCreatingError
);
export const getParsed = createSelector(
    getState,
    (state: articlesReducer.State) => state.articles.parsed.single
);
export const getIsLoadingSingle = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.articles[props.id]?.isLoadingSingle
);
export const getHasLoadingSingleError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.articles[props.id]?.hasLoadingSingleError
);
export const getSingle = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.articles[props.id]?.single
);

export const getHistoryForIdIsLoadingPage = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.history[props.id]?.isLoadingPage
);
export const getHistoryForIdHasLoadingPageError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.history[props.id]?.hasLoadingPageError
);
export const getHistoryForIdInfinityPaging = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.history[props.id]?.infinity?.paging
);
export const getHistoryForIdInfinityCombinedList = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.history[props.id]?.infinity?.combinedList
);

export const getIsLoadingTargetted = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.targetted[props.id]?.isLoadingList
);
export const getHasLoadingTargettedError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.targetted[props.id]?.hasLoadingListError
);
export const getTargetted = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.targetted[props.id]?.list
);

export const getIsLoadingViewed = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.viewed[props.id]?.isLoadingList
);
export const getHasLoadingViewedError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.viewed[props.id]?.hasLoadingListError
);
export const getViewed = createSelector(
    getState,
    (state: articlesReducer.State, props: any) => state.viewed[props.id]?.list
);

export const getRecentArticlesIsLoadingList = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.recent[props.id]?.isLoadingList
);
export const getRecentArticlesHasLoadingListError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.recent[props.id]?.hasLoadingListError
);
export const getRecentArticles = createSelector(
    getState,
    (state: articlesReducer.State, props: any) => state.recent[props.id]?.list
);

export const getIsLoadingFeedClients = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.feedClients[props.id]?.isLoadingList
);
export const getHasLoadingFeedClientsError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.feedClients[props.id]?.hasLoadingListError
);
export const getFeedClients = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.feedClients[props.id]?.list
);

export const getIsLoadingComments = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.comments[props.id]?.isLoadingList
);
export const getHasLoadingCommentsError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.comments[props.id]?.hasLoadingListError
);
export const getComments = createSelector(
    getState,
    (state: articlesReducer.State, props: any) => state.comments[props.id]?.list
);

export const getIsLoadingLinkedRecords = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.linkedRecords[props.id]?.isLoadingList
);
export const getHasLoadingLinkedRecordsError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.linkedRecords[props.id]?.hasLoadingListError
);
export const getLinkedRecords = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.linkedRecords[props.id]?.list
);

export const getIsLoadingLinkedActivities = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.linkedActivities[props.id]?.isLoadingList
);
export const getHasLoadingLinkedActivitiesError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.linkedActivities[props.id]?.hasLoadingListError
);
export const getLinkedActivities = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.linkedActivities[props.id]?.list
);

export const getIsLoadingLinkedCases = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.linkedCases[props.id]?.isLoadingList
);
export const getHasLoadingLinkedCasesError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.linkedCases[props.id]?.hasLoadingListError
);
export const getLinkedCases = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.linkedCases[props.id]?.list
);

export const getFavouritedIsLoadingPage = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.favourited[props.id]?.isLoadingPage
);
export const getFavouritedHasLoadingPageError = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.favourited[props.id]?.hasLoadingPageError
);
export const getFavouritedInfinityPaging = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.favourited[props.id]?.infinity?.paging
);
export const getFavouritedInfinityCombinedList = createSelector(
    getState,
    (state: articlesReducer.State, props: any) =>
        state.favourited[props.id]?.infinity?.combinedList
);
