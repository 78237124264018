import { Component, EventEmitter, Input, Output, inject } from '@angular/core';

import {
    BehaviorSubject,
    Observable,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
} from 'rxjs';

import {
    ActivityComment,
    ActivityType,
    Comment,
    SystemEntity,
} from '@wdx/clmi/api-models';

import { Paging } from '@wdx/clmi/api-services/models';
import { CrudStatus, WdxDestroyClass } from '@wdx/shared/utils';
import { FeatureSvg } from '../../../models/svg.model';
import { CommentsFacade } from './shared/services/comments.facade';

export type ActivityCommentWithResolves = ActivityComment & {
    resolves?: string;
};

export interface CommentsInfo {
    count: number;
    hasIssues: boolean;
}

@Component({
    selector: 'clmi-comments',
    templateUrl: './comments.component.html',
})
export class CommentsComponent extends WdxDestroyClass {
    private facade = inject(CommentsFacade);

    get commentActivityType(): ActivityType {
        return this.facade.activityType;
    }

    activityType = ActivityType;
    private showIssuesOnly$ = new BehaviorSubject<boolean>(false);

    @Input() title?: string;
    @Input() set entity(value: { id: string; type: SystemEntity }) {
        if (!value) {
            this.hasConfig = false;
            return;
        }
        this.hasConfig = true;
        // default to WorkItem type for backwards compatibilty
        if (!value.type || value.type === SystemEntity.Activity) {
            this.loadActivityComments(value.id);
            return;
        }
        this.loadComments(value.id, value.type);
    }
    @Input() allowNewComments = true;
    @Input() dynamicHeight = false;
    @Input() readonly = false;
    @Input() appMentionClickCallback?: (appId: string) => void;
    @Input() set invertedStyling(invertedStyling: boolean) {
        this.facade.invertedStyling = Boolean(invertedStyling);
    }
    @Output() commentsInfo = new EventEmitter<CommentsInfo>();

    closeCommentTimestamp = null;
    hasConfig = false;
    resolveIssueId = null;
    issuesCount;
    comments$: Observable<ActivityCommentWithResolves[]>;
    commentsPaging$: Observable<Paging>;
    commentsIsLoading$: Observable<boolean>;
    commentsHasError$: Observable<boolean>;
    readonly FEATURE_SVG = FeatureSvg;

    commentIsCreating$: Observable<boolean>;
    commentHasCreatingError$: Observable<boolean>;

    loadComments(entityId: string, entityType: SystemEntity): void {
        this.comments$ = this.facade.getChat(entityId, entityType).pipe(
            map((chat) => chat.comments),
            switchMap((comments) => this.prepareCommentsForDisplay(comments))
        );

        this.commentsPaging$ = this.facade
            .getChat(entityId, entityType)
            .pipe(
                map(
                    (chat) => ({ totalRecords: chat.comments.length } as Paging)
                )
            );
        this.commentsIsLoading$ = this.facade
            .getChat(entityId, entityType)
            .pipe(map((chat) => chat.status <= CrudStatus.Loading));
        this.commentsHasError$ = this.facade
            .getChat(entityId, entityType)
            .pipe(map((chat) => chat.status === CrudStatus.Error));
        this.commentIsCreating$ = this.facade
            .getChat(entityId, entityType)
            .pipe(map((chat) => chat.status === CrudStatus.Updating));
        // this.commentHasCreatingError$ = this.facade.hasCreatingError$;
    }

    loadActivityComments(id: string): void {
        this.facade.getActivityComments(id);

        this.comments$ = this.facade.comments$.pipe(
            switchMap((comments) => this.prepareCommentsForDisplay(comments))
        );
        this.commentsPaging$ = this.facade.paging$;
        this.commentsIsLoading$ = this.facade.isLoading$;
        this.commentsHasError$ = this.facade.hasError$;
        this.commentIsCreating$ = this.facade.isCreating$;
        this.commentHasCreatingError$ = this.facade.hasCreatingError$;
    }

    trackByComment(_: number, comment: ActivityComment): string {
        return comment.id;
    }

    resolveIssueChecked(value: { checked: boolean; commentId: string }) {
        const { checked, commentId } = value;
        this.resolveIssueId = checked ? commentId : null;
    }

    showIssuesOnly(value: boolean) {
        this.showIssuesOnly$.next(value);
    }

    /**
     * Sort comments first by descending timestamp order and then moving any resolution
     * comments to be after the comment they resolve
     * @param {Comment[]} comments
     * @returns {ActivityCommentWithResolves[]}
     */
    sortResolvingComments(comments: Comment[]): ActivityCommentWithResolves[] {
        const sortedTimestamps = comments
            .map((x) => x.timestamp)
            .sort()
            .reverse();
        const timeOrderedComments = sortedTimestamps.map((ts) =>
            comments.find((c) => c.timestamp === ts)
        );
        const resolvingComments = timeOrderedComments
            .filter((comment) => comment.resolution)
            .map((parent) => ({
                ...comments.find(
                    (comment) => comment.id === parent.resolution.id
                ),
                resolves: parent.id,
            }));
        let normalComments = timeOrderedComments.filter((comment) =>
            resolvingComments.every((rc) => rc.id !== comment.id)
        );
        resolvingComments.forEach((resolvingComment) => {
            const parent = normalComments.findIndex(
                (comment) =>
                    comment?.resolution?.id &&
                    comment.resolution.id === resolvingComment.id
            );
            if (parent > -1) {
                normalComments = [
                    ...normalComments.slice(0, parent + 1),
                    resolvingComment,
                    ...normalComments.slice(parent + 1),
                ];
            }
        });

        return normalComments;
    }

    prepareCommentsForDisplay(
        comments: Comment[]
    ): Observable<ActivityCommentWithResolves[]> {
        this.issuesCount = comments?.filter(
            (comment) => comment.isIssue
        ).length;
        this.commentsInfo.emit({
            count: comments?.length,
            hasIssues: !!this.issuesCount,
        });
        return this.showIssuesOnly$.pipe(
            map((issuesOnly) => {
                // sort comments to place resolving comments before the issue comments
                const sortedComments = comments?.length
                    ? this.sortResolvingComments(comments)
                    : [];

                if (issuesOnly) {
                    return sortedComments.filter((comment) => comment.isIssue);
                }
                return sortedComments;
            })
        );
    }

    createCommentClicked() {
        this.resolveIssueId = null;
        this.commentIsCreating$
            .pipe(
                filter((isCreating) => !isCreating),
                take(1),
                takeUntil(this.destroyed$)
            )
            .subscribe(() => {
                this.closeCommentTimestamp = Date.now();
            });
    }
}
