import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { TranslateTokenisedStringPipe } from '@wdx/shared/utils';
import { AtomGuidanceTextIconModule } from '../../atoms/atom-guidance-text-icon/atom-guidance-text-icon.module';
import { MoleculeInputWrapperComponent } from './molecule-input-wrapper.component';

@NgModule({
    imports: [
        AtomGuidanceTextIconModule,
        CommonModule,
        WdxIconModule,
        TranslateTokenisedStringPipe,
    ],
    declarations: [MoleculeInputWrapperComponent],
    exports: [MoleculeInputWrapperComponent],
})
export class MoleculeInputWrapperModule {}
