import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxListComponent } from './wdx-list/wdx-list.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxListComponent],
    exports: [WdxListComponent],
})
export class WdxListModule {}
