import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormDisplayMode, NavItem } from '@wdx/shared/utils';
import { FilterHandler } from '../../../classes/filter-handler.class';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-data-list-wrapper',
    templateUrl: './molecule-data-list-wrapper.component.html',
})
export class MoleculeDataListWrapperComponent {
    @Input() isLoading: boolean;
    @Input() hasError: boolean;
    @Input() isForInfinityScroll: boolean;
    @Input() limit = 4;

    @Input() filterHandler: FilterHandler;
    @Input() sortMenu: NavItem[];
    @Input() useSortToggleMenu: boolean;
    @Input() searchType: string;
    @Input() searchTypePlural: string;
    @Input() searchPlaceholder: string;
    @Input() totalRecordCount: number;
    @Input() filtersModalId: string;
    @Input() hasFilterSearch: boolean;
    @Input() hasMobileFilter: boolean;

    @Input() showFilters: boolean;
    @Input() showFilterBar: boolean;
    @Input() showSearchBar: boolean;
    @Input() showFavourite: boolean;
    @Input() responsiveScroll: boolean;
    @Input() favouriteFilterToggle: boolean;
    @Input() cySelector: string;

    @Output() infinityScrollFired = new EventEmitter();
    @Output() toggleFavourite = new EventEmitter<{
        id: string;
        isFavourite: boolean;
    }>();

    readonly FORM_DISPLAY_MODE = FormDisplayMode;

    onSearch(value: string): void {
        this.filterHandler.setSearchText(value);
    }

    onToggleFavourite(id: string, isFavourite: boolean) {
        this.toggleFavourite.emit({
            id,
            isFavourite,
        });
    }

    onInfinityScrollFired(): void {
        this.infinityScrollFired.emit();
    }
}
