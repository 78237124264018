<button
    class="d-flex align-items-center position-relative"
    [attr.data-cy]="cySelector"
    [disabled]="disabled || isLoading"
    [ngClass]="btnClass"
    (click)="onAsyncButtonClicked()"
>
    <wdx-spinner
        *ngIf="isLoading"
        class="me-1"
        themeColor="none"
        [small]="true"
    ></wdx-spinner>
    <div>
        <ng-content></ng-content>
    </div>
</button>
