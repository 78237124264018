import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { MAX_DATE, MIN_DATE } from '../constants/date.constants';

@Pipe({ name: 'dateCompare' })
export class DateComparePipe implements PipeTransform {
    transform(values: any[], searchFor: 'earliest' | 'latest'): Date {
        if (!values) {
            return;
        }

        const dates = values
            .filter((value) => Boolean(value))
            .map((value) => new Date(value));

        const baseDate = searchFor === 'earliest' ? MAX_DATE : MIN_DATE;

        const result = dates.reduce((accumulator, date) => {
            return searchFor === 'earliest'
                ? DateTime.fromJSDate(date) < DateTime.fromJSDate(accumulator)
                    ? date
                    : accumulator
                : DateTime.fromJSDate(date) > DateTime.fromJSDate(accumulator)
                ? date
                : accumulator;
        }, baseDate);

        if ([MIN_DATE, MAX_DATE].includes(result)) {
            return;
        }

        return result;
    }
}
