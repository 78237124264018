import { Directive, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { WdxBaseInputControlsValueAccessorClass } from '@wdx/shared/components/abstract-classes';
import { DateTime } from 'luxon';
import { FULL_DAY, TIME } from './wdx-input-date.static';

@Directive({
    selector: 'input[wdxInputDate]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref no-use-before-declare
            useExisting: forwardRef(() => WdxInputDateDirective),
            multi: true,
        },
    ],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '(input)': 'onInput($event.target.value)',
    },
})
export class WdxInputDateDirective extends WdxBaseInputControlsValueAccessorClass {
    inputType?: 'date' | 'datetime-local';
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        super();
    }

    onInput(value: any): void {
        this.setInputType();
        let val = value;

        if (this.inputType === 'date') {
            val = value;
        }

        if (this.inputType === 'datetime-local') {
            const DATE = DateTime.fromJSDate(new Date(value));

            val = DATE.toISO();
        }

        this.val = val;
        this.onChange(val);
    }

    override writeValue(value: any): void {
        this.setInputType();
        let val = value;

        if (val) {
            const DATE = DateTime.fromJSDate(new Date(value));
            val = DATE.toUTC()
                .set({ second: 0, millisecond: 0 })
                .toFormat(FULL_DAY);

            if (this.inputType === 'datetime-local') {
                val = `${DATE.toFormat(FULL_DAY)}T${DATE.toFormat(TIME)}`;
            }

            this.renderer.setProperty(
                this.elementRef.nativeElement,
                'value',
                val
            );
        }

        this.val = val;
    }

    private setInputType() {
        if (!this.inputType) {
            this.inputType = this.elementRef.nativeElement.type;
        }
    }
}
