<molecule-tabs-dumb
    class="h-100 d-flex flex-column"
    [activeTabIndex]="activeTabIndex$ | ngrxPush"
    [tabs]="tabs"
    [hasBottomBorder]="hasBottomBorder"
    (activateTab)="activateTab($event)"
    [attr.data-cy]="cySelector"
>
    <ng-content class="action-buttons" select=".action-buttons"></ng-content>
    <ng-content></ng-content>
</molecule-tabs-dumb>
