import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ActivityCommentCreate, AppStatus } from '@wdx/clmi/api-models';

import { CasesFacadeService } from '../../../../../../features/cases/shared/services/cases-facade.service';
import { replaceString } from '../../../../../../shared/helpers/string/replace/replace.helper';
import { FILTER_ARRAY_BY_SEARCH_TERM } from '../../../../../../shared/helpers/string/search/search.helper';
import { IAppMention, TriggerChar } from '../../constants/mention.constant';

@Injectable({
    providedIn: 'any',
})
export class AppMentionsService {
    appMentions: IAppMention[] = [];
    appMentionConfig = {
        items: [],
        triggerChar: TriggerChar.Hash,
        mentionSelect: this.mentionSelect.bind(this),
        labelKey: 'name',
        disableSearch: true,
        dropUp: false,
        returnTrigger: true,
    };
    apps: AppStatus[] = [];
    workItemAppsResults: IAppMention[] = [];
    workItemAppsResults$ = new BehaviorSubject<IAppMention[]>([]);

    constructor(private casesFacadeService: CasesFacadeService) {}

    mentionSelect(appMention: IAppMention): string {
        const name = `${appMention.displayName.replace(/<[^>]*>?/gm, '')}`;
        this.appMentions.push(appMention);

        return `${TriggerChar.Hash}${name}`;
    }

    onOpen(): void {
        this.resetConfig();

        this.casesFacadeService
            .getApprovalModeApps(this.casesFacadeService.caseId)
            .subscribe({
                next: ({ allApps }) => {
                    this.workItemAppsResults = allApps;
                },
            });
    }

    resetConfig(): void {
        this.apps = [];
        this.workItemAppsResults = [];
    }

    mentionTriggerChar(searchText: string): void {
        const FILTERED_DATA = FILTER_ARRAY_BY_SEARCH_TERM<IAppMention>(
            this.workItemAppsResults,
            (item: IAppMention) => item.app.name,
            searchText
        );

        this.workItemAppsResults$.next(FILTERED_DATA);
    }

    updateHashMentionsStringDataOnPost(
        commentCreateData: ActivityCommentCreate
    ): ActivityCommentCreate {
        const COMMENT_CREATE_DATA = { ...commentCreateData };

        if (this.appMentions.length > 0) {
            const dedupedAppMentions = this.appMentions.reduce((prev, curr) => {
                if (
                    (prev as IAppMention[]).find(
                        (a) => a.app.id === curr.app.id
                    )
                ) {
                    return prev;
                }
                return [...prev, curr];
            }, []);
            dedupedAppMentions.forEach((mention: IAppMention) => {
                const regex = replaceString(
                    mention.displayName,
                    TriggerChar.Hash
                );

                if (COMMENT_CREATE_DATA.content.match(regex)) {
                    COMMENT_CREATE_DATA.content =
                        COMMENT_CREATE_DATA.content.replace(
                            regex,
                            this.updateMentionReplaceForSaving(mention)
                        );
                }
            });
        }

        return COMMENT_CREATE_DATA;
    }

    updateMentionReplaceForSaving(mention: IAppMention): string {
        return `#${TriggerChar.Hash}${mention.displayName}:${mention.caseId}:${mention.workItemId}:${mention.app.id}#`;
    }
}
