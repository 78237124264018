import { Component } from '@angular/core';
import { ThemeComponentClass } from '@wdx/shared/components/abstract-classes';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-highlight-circle',
    templateUrl: './atom-highlight-circle.component.html',
})
export class AtomHighlightCircleComponent extends ThemeComponentClass {
    themeClassPrefix = 'badge';
}
