import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomModalWrapperComponent } from './atom-modal-wrapper.component';

@NgModule({
    imports: [CommonModule],
    declarations: [AtomModalWrapperComponent],
    exports: [AtomModalWrapperComponent],
})
export class AtomModalWrapperModule {}
