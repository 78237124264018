import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { MoleculePopoverGridComponent } from './molecule-popover-grid.component';

@NgModule({
    imports: [CommonModule, NgbPopoverModule, WdxIconModule],
    declarations: [MoleculePopoverGridComponent],
    exports: [MoleculePopoverGridComponent],
})
export class MoleculePopoverGridModule {}
