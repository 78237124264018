import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as remindersActions from './reminders.actions';
import { RemindersService } from './reminders.service';

@Injectable()
export class RemindersEffects {
    private actions$ = inject(Actions);
    private remindersService = inject(RemindersService);

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(remindersActions.create),
            switchMap((action) =>
                this.remindersService.create(action.reminderCreateData).pipe(
                    map((reminder) =>
                        remindersActions.createSuccess({ reminder })
                    ),
                    catchError((error) =>
                        of(remindersActions.createFailure({ error }))
                    )
                )
            )
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(remindersActions.deleteReminder),
            switchMap((action) =>
                this.remindersService.delete(action.reminderId).pipe(
                    map((reminderId) =>
                        remindersActions.deleteSuccess({ reminderId })
                    ),
                    catchError((error) =>
                        of(remindersActions.deleteFailure({ error }))
                    )
                )
            )
        )
    );
}
