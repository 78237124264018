<div
    *ngIf="hasConfig"
    [wdxIsLoading]="commentsIsLoading$ | ngrxPush"
    [hasError]="commentsHasError$ | ngrxPush"
>
    <h5 *ngIf="title">{{ title }}</h5>

    <div class="h-100 d-flex flex-column">
        <clmi-comment-input
            *ngIf="allowNewComments"
            [wdxIsLoading]="commentIsCreating$ | ngrxPush"
            class="flex-shrink-0"
            [dynamicHeight]="dynamicHeight"
            [count]="(commentsPaging$ | ngrxPush)?.totalRecords"
            [issueCount]="issuesCount"
            [resolveIssueId]="resolveIssueId"
            [closeComment]="closeCommentTimestamp"
            (showIssuesOnly)="showIssuesOnly($event)"
            (createCommentClicked)="createCommentClicked()"
        ></clmi-comment-input>

        <div
            #commentsContainer
            class="flex-grow-1 overflow-auto"
            data-cy="comment-list"
        >
            <ng-container *ngIf="comments$ | ngrxPush as comments">
                <ng-container
                    *ngFor="
                        let comment of comments;
                        let i = index;
                        let last = last;
                        trackBy: trackByComment
                    "
                >
                    <clmi-comment
                        class="d-block"
                        [class.mb-2]="!last"
                        [reply]="comment.resolves"
                        [comment]="comment"
                        [appMentionClickCallback]="appMentionClickCallback"
                        [resolveIssueId]="resolveIssueId"
                        [readonly]="readonly"
                        (resolveIssueChecked)="resolveIssueChecked($event)"
                    ></clmi-comment>
                </ng-container>

                <atom-feature-svg
                    *ngIf="
                        (!allowNewComments && !comments?.length) ||
                        !comments?.length
                    "
                    [svg]="FEATURE_SVG.Comments"
                >
                    Be the first to add a comment <br />
                    @mention someone
                    <ng-container *ngIf="commentActivityType === activityType">
                        or reference an #app
                    </ng-container>
                </atom-feature-svg>
            </ng-container>
        </div>
    </div>
</div>
