import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConditionalMessage } from '@wdx/shared/utils';

@Injectable({
    providedIn: 'any',
})
export class FormFieldsDataService {
    messageDefinitionToShow: { [key: string]: ConditionalMessage } = {};
    messageDefinitionToObject: { [key: string]: ConditionalMessage } = {};

    /**
     * Parses form data and flattens formgroups with empty data to null (if required)
     * - eg. if a FormGroup has a value of {a: null, b:null} but backend expects a null value returned
     * - if no parsed fields are defined, then simply return the raw form value
     */
    parseFormData(fieldsToNullCheck: string[], formGroup: FormGroup) {
        if (fieldsToNullCheck.length) {
            return Object.entries(formGroup.getRawValue()).reduce(
                (previous, [key, value]) => {
                    const parsedValue =
                        value &&
                        fieldsToNullCheck.includes(key) &&
                        typeof value === 'object' &&
                        Object.values(value).every((val) => {
                            // check for null, undefined or empty string
                            return (
                                val === undefined ||
                                val === null ||
                                val?.length === 0
                            );
                        })
                            ? null
                            : value;
                    return { ...previous, [key]: parsedValue };
                },
                {}
            );
        }

        return formGroup.getRawValue();
    }

    /**
     * Add a field name to list of fields to null check
     */
    addFieldsToNullCheck(fieldsToNullCheck: string[], fieldName: string) {
        if (!fieldsToNullCheck.includes(fieldName)) {
            fieldsToNullCheck.push(fieldName);
        }
    }
}
