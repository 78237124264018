import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Tag } from '@wdx/clmi/api-models';
import { BaseReactiveFormAdapterClass } from '../../../../classes/base-reactive-form-adapter.class';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-reactive-input-tags',
    templateUrl: './molecule-reactive-input-tags.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MoleculeReactiveInputTagsComponent),
            multi: true,
        },
    ],
})
export class MoleculeReactiveInputTagsComponent extends BaseReactiveFormAdapterClass {
    @Input() tags: Tag[];
}
