import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as investmentAccountsActions from './investment-accounts.actions';
import { InvestmentAccountsService } from './investment-accounts.service';

@Injectable()
export class InvestmentAccountsEffects {
    private actions$ = inject(Actions);
    private investmentAccountsService = inject(InvestmentAccountsService);

    getForId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(investmentAccountsActions.getForId),
            mergeMap((action) =>
                this.investmentAccountsService
                    .getForId(action.investmentAccountId)
                    .pipe(
                        map((investmentAccount) =>
                            investmentAccountsActions.getForIdSuccess({
                                investmentAccountId: action.investmentAccountId,
                                investmentAccount,
                            })
                        ),
                        catchError((error) =>
                            of(
                                investmentAccountsActions.getForIdFailure({
                                    investmentAccountId:
                                        action.investmentAccountId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
