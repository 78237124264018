import { Injectable } from '@angular/core';
import { ExtendedFieldDefinitionOption } from '../../../../../../models/extended-field-definition-option.model';
import {
    Answer,
    FilterFieldDefinition,
    FilterFieldType,
    PossibleValue,
} from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { FilterDataItem, IFilterDataProvider } from '../../interfaces';
import { FilterAnswerDataService } from './answer/filter-answer-data.service';
import { FilterDynamicDataService } from './dynamic/filter-dynamic-data.service';
import { FilterPossibleValuesDataService } from './possible-values/filter-possible-values-data.service';
import { FilterTagsDataService } from './tags/filter-tags-data.service';

@Injectable()
export class FilterDataProviderService {
    constructor(
        private readonly filterTagsDataService: FilterTagsDataService,
        private readonly filterAnswerDataService: FilterAnswerDataService,
        private readonly FilterPossibleValuesDataService: FilterPossibleValuesDataService,
        private readonly filterDynamicDataService: FilterDynamicDataService
    ) {}

    public getProvider(definition: FilterFieldDefinition): IFilterDataProvider {
        if (definition.type === FilterFieldType.Answer) {
            return this.filterAnswerDataService;
        }

        if (definition?.selectSource && !definition?.tagCategories?.length) {
            return this.filterDynamicDataService;
        }

        if (definition?.tagCategories?.length) {
            return this.filterTagsDataService;
        }

        return this.FilterPossibleValuesDataService;
    }

    public getData$(
        definition: FilterFieldDefinition,
        options?: PossibleValue[]
    ): Observable<
        FilterDataItem[] | ExtendedFieldDefinitionOption[] | Answer[]
    > {
        return this.getProvider(definition).getData$(definition, options);
    }
}
