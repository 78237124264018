import { createSelector } from '@ngrx/store';
import * as commentsReducer from './comments.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    comments: commentsReducer.State;
}): commentsReducer.State => state.comments;

/**
 * Reducer property selectors
 */
export const getIsCreating = createSelector(
    getState,
    (state: commentsReducer.State, props: any) =>
        state.comments[props.id]?.isCreating
);
export const getHasCreatingError = createSelector(
    getState,
    (state: commentsReducer.State, props: any) =>
        state.comments[props.id]?.hasCreatingError
);

export const getIsLoadingPage = createSelector(
    getState,
    (state: commentsReducer.State, props: any) =>
        state.comments[props.id]?.isLoadingPage
);
export const getHasLoadingPageError = createSelector(
    getState,
    (state: commentsReducer.State, props: any) =>
        state.comments[props.id]?.hasLoadingPageError
);
export const getInfinityPaging = createSelector(
    getState,
    (state: commentsReducer.State, props: any) =>
        state.comments[props.id]?.infinity?.paging
);
export const getInfinityCombinedList = createSelector(
    getState,
    (state: commentsReducer.State, props: any) =>
        state.comments[props.id]?.infinity?.combinedList
);

export const getCommentById = createSelector(
    getState,
    (state: commentsReducer.State, activityId: string) =>
        state.comments[activityId]?.infinity
);
