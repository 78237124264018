import { createAction, props } from '@ngrx/store';
import { InvestmentAccount } from '@wdx/clmi/api-models';

export const getForId = createAction(
    '[Investment Accounts] Get For ID',
    props<{
        investmentAccountId: string;
    }>()
);

export const getForIdSuccess = createAction(
    '[Investment Accounts] Get For ID Success',
    props<{
        investmentAccountId: string;
        investmentAccount: InvestmentAccount;
    }>()
);

export const getForIdFailure = createAction(
    '[Investment Accounts] Get For ID Failure',
    props<{
        investmentAccountId: string;
        error: Error;
    }>()
);
