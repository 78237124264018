import { Component, Input } from '@angular/core';
import { InfoCardSearchMode } from '../../../../../models/info-card-search-mode.model';
import { FormTemplate, SystemEntity } from '@wdx/clmi/api-models';
import { InfoCardData } from '../../../../../models/info-card-data.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-activity-template-search-dumb',
    templateUrl: './organism-activity-template-search-dumb.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OrganismActivityTemplateSearchComponentDumb {
    @Input() entityId: string;
    @Input() entityType: SystemEntity;

    @Input() formTemplates: FormTemplate[];
    @Input() formTemplatesIsLoading: boolean;
    @Input() formTemplatesHasError: boolean;

    readonly INFO_CARD_SEARCH_MODE = InfoCardSearchMode;

    formTemplatesAsInfoCardData(
        formTemplates: FormTemplate[],
        entityId: string,
        entityType: SystemEntity
    ): InfoCardData[] {
        return formTemplates?.map((formTemplate) => ({
            primaryInfo: formTemplate.name,
            id: formTemplate.code,
            formSetup: {
                formTemplateId: formTemplate.code,
                formTemplateEntityId: entityId,
                formTemplateEntityType: entityType,
                formId: formTemplate.formName,
            },
            data: formTemplate,
        }));
    }
}
