<molecule-info-card
    size="lg"
    [primaryInfo]="data?.primaryInfo"
    [routerLinkData]="data?.routerLinkData"
    [secondaryInfo]="data?.secondaryInfo"
    [tertiaryInfo]="data?.tertiaryInfo"
    [idList]="data?.idList"
    [avatars]="data?.avatars"
    [avatarIconDivider]="true"
></molecule-info-card>
