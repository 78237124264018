import { Pipe, PipeTransform } from '@angular/core';
import { WdxThemeColor, WdxThemeColorSuffix } from '@wdx/shared/utils';

@Pipe({ name: 'themeClass' })
export class ThemeClassPipe implements PipeTransform {
    transform(
        themeColor: WdxThemeColor,
        prefix: string | string[],
        suffix?: WdxThemeColorSuffix | WdxThemeColorSuffix[]
    ): string {
        if (!themeColor) {
            return;
        }
        const _prefix = Array.isArray(prefix) ? prefix : [prefix];
        const _suffix = Array.isArray(suffix) ? suffix : [suffix];
        return _prefix
            .map(
                (pre, i) =>
                    `${pre}-${themeColor}${_suffix[i] ? '-' + _suffix[i] : ''}`
            )
            .join(' ');
    }
}
