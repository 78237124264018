<molecule-widget-container
    [widgetTitle]="widgetTitle"
    [widgetSize]="widgetSize"
    [spokeButton]="spokeButton"
    [isLoading]="isLoading"
    [hasError]="hasError"
    [count]="totalRecordCount"
    [hideCount]="hideCount"
    [numberToCompareToLimit]="activities?.length"
    [limit]="limit"
    [showNoDataMessage]="!activities?.length"
    [views]="views"
    (viewSelected)="onViewSelected($event)"
    (spokeButtonClicked)="onSpokeButtonClicked()"
>
    <div
        *ngIf="showActivitiesListActionsButtons"
        class="widget-header-action-buttons"
    >
        <ng-content select.activities-list-action-buttons></ng-content>
    </div>
    <organism-activities-list
        [activities]="activities"
        [activitiesPerRow]="activitiesPerRow"
        [isLoading]="isLoading"
        [showFilterBar]="false"
        [showSearchBar]="false"
        [limit]="limit"
        [scrollMode]="scrollMode"
        [totalRecordCount]="totalRecordCount"
    ></organism-activities-list>
</molecule-widget-container>

<atom-modal-wrapper
    size="xl"
    [modalId]="modalId"
    [modalTemplate]="activitiesModal"
>
    <ng-template #activitiesModal>
        <organism-modal
            [modalTitle]="allActivitiesModalTitle"
            (modalClosed)="onModalClosed()"
        >
            <organism-activities-list
                class="d-block h-100"
                searchType="Task"
                searchTypePlural="Tasks"
                [activities]="activities"
                [activitiesPaging]="activitiesPaging"
                [activityCreateActionButton]="activityCreateActionButton"
                [isLoading]="isLoading"
                [showFilters]="false"
                [showSearchBar]="false"
                [filterHandler]="filterHandler"
                [addWidgetContainerPadding]="true"
                [totalRecordCount]="totalRecordCount"
                (infinityScrollFired)="onInfinityScrollFired()"
            ></organism-activities-list>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
