import { createAction, props } from '@ngrx/store';
import { SystemEvent, SystemSignalrEvent } from './system-events.reducer';

export const newSystemEvent = createAction(
    '[System Event] New Event',
    props<{
        event: SystemEvent;
    }>()
);

export const newSystemSignalrEvent = createAction(
    '[System Event] New SignalR Event',
    props<{
        event: SystemSignalrEvent;
    }>()
);
