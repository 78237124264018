import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as nudgesActions from './nudges.actions';
import { NudgesService } from './nudges.service';

@Injectable()
export class NudgesEffects {
    private actions$ = inject(Actions);
    private nudgesService = inject(NudgesService);

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(nudgesActions.create),
            switchMap((action) =>
                this.nudgesService.create(action.nudgeCreateData).pipe(
                    map((nudge) => nudgesActions.createSuccess({ nudge })),
                    catchError((error) =>
                        of(nudgesActions.createFailure({ error }))
                    )
                )
            )
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(nudgesActions.deleteNudge),
            switchMap((action) =>
                this.nudgesService.delete(action.nudgeId).pipe(
                    map((nudgeId) => nudgesActions.deleteSuccess({ nudgeId })),
                    catchError((error) =>
                        of(nudgesActions.deleteFailure({ error }))
                    )
                )
            )
        )
    );

    dismiss$ = createEffect(() =>
        this.actions$.pipe(
            ofType(nudgesActions.dismissNudge),
            switchMap((action) =>
                this.nudgesService.dismiss(action.nudgeId).pipe(
                    map((nudge) => nudgesActions.dismissSuccess({ nudge })),
                    catchError((error) =>
                        of(nudgesActions.dismissFailure({ error }))
                    )
                )
            )
        )
    );
}
