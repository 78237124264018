import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    FormDataHistory,
    Locale,
    User,
    UserKpi,
    UserTenant,
} from '@wdx/clmi/api-models';
import { BaseApiService } from '../base-api.service';
import { PasswordChangeRequest } from './password-change-request';
import { UserUrlSegment } from './user-url-segment';
import { ApiConfigService } from '../api-config.service';

@Injectable({ providedIn: 'root' })
export class UserApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getMe(): Observable<User> {
        return this.get<User>(`${UserUrlSegment.USER}/${UserUrlSegment.ME}`);
    }

    getMeLocale(): Observable<Locale> {
        return this.get<Locale>(
            `${UserUrlSegment.USER}/${UserUrlSegment.ME}/${UserUrlSegment.LOCALE}`
        );
    }

    getForId(userId: string): Observable<User> {
        return this.get<User>(`${UserUrlSegment.USER}/${userId}`);
    }

    getKPIsForId(userId: string): Observable<UserKpi> {
        return this.get<UserKpi>(
            `${UserUrlSegment.USER}/${userId}/${UserUrlSegment.KPI}`
        );
    }

    getHistoryForId(userId: string): Observable<FormDataHistory[]> {
        return this.get<FormDataHistory[]>(
            `${UserUrlSegment.FORMDATA}/${UserUrlSegment.USERFORM}/${userId}/${UserUrlSegment.HISTORY}`
        );
    }

    changePassword(oldPassword: string, newPassword: string): Observable<User> {
        return this.post<User, PasswordChangeRequest>(
            `${UserUrlSegment.USER}/${UserUrlSegment.ME}/${UserUrlSegment.PASSWORD}`,
            {
                oldPassword,
                newPassword,
            }
        );
    }

    getTenants(): Observable<UserTenant[]> {
        return this.get<UserTenant[]>(
            `${UserUrlSegment.USER}/${UserUrlSegment.ME}/${UserUrlSegment.TENANT}`
        );
    }

    changeTenant(tenantCode: string): Observable<UserTenant> {
        return this.put<UserTenant>(
            `${UserUrlSegment.USER}/${UserUrlSegment.ME}/${UserUrlSegment.TENANT}/${tenantCode}/${UserUrlSegment.DEFAULT}`,
            {}
        );
    }

    changeAppLanguage(languageCode: string): Observable<User> {
        return this.put<User>(
            `${UserUrlSegment.USER}/${UserUrlSegment.ME}/${UserUrlSegment.LANGUAGE}/${languageCode}`,
            {}
        );
    }

    changeAppCurrency(isoCode: string): Observable<User> {
        return this.put<User>(
            `${UserUrlSegment.USER}/${UserUrlSegment.ME}/${UserUrlSegment.CURRENCY}/${isoCode}`,
            {}
        );
    }
}
