import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Answer, AnswerSet } from '@wdx/clmi/api-models';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class AnswersService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getAnswerSetList(): Observable<AnswerSet[]> {
        return this.http.get<AnswerSet[]>(
            `${this.config.getConfiguration().API_BASE}/answer/set`
        );
    }

    getAnswers(code: string): Observable<Answer[]> {
        return this.http.get<Answer[]>(
            `${this.config.getConfiguration().API_BASE}/answer/set/${code}`
        );
    }

    disableAnswer(id: string, isDisabled: boolean): Observable<Answer> {
        return this.http.patch<Answer>(
            `${
                this.config.getConfiguration().API_BASE
            }/answer/${id}/disabled/${isDisabled}`,
            {}
        );
    }

    reorderAnswers(code: string, values: string[]): Observable<null> {
        return this.http.patch<null>(
            `${
                this.config.getConfiguration().API_BASE
            }/answer/set/${code}/reorder`,
            values
        );
    }
}
