import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as productTypesActions from './product-types.actions';
import { ProductTypesService } from './product-types.service';

@Injectable()
export class ProductTypesEffects {
    private actions$ = inject(Actions);
    private productTypesService = inject(ProductTypesService);

    getProductTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(productTypesActions.getProductTypes),
            switchMap((action) =>
                this.productTypesService
                    .getProductTypes(action?.availableOnly)
                    .pipe(
                        map((productTypes) =>
                            productTypesActions.getProductTypesSuccess({
                                productTypes,
                            })
                        ),
                        catchError((error) =>
                            of(
                                productTypesActions.getProductTypesFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getProducs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(productTypesActions.getProducts),
            switchMap((action) =>
                this.productTypesService.getProducts(action.code).pipe(
                    map((products) =>
                        productTypesActions.getProductsSuccess({
                            code: action.code,
                            products,
                        })
                    ),
                    catchError(() =>
                        of(
                            productTypesActions.getProductsFailure({
                                code: action.code,
                            })
                        )
                    )
                )
            )
        )
    );

    disableProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(productTypesActions.disableProduct),
            switchMap((action) =>
                this.productTypesService
                    .disableProduct(action.id, action.isDisabled)
                    .pipe(
                        map(() =>
                            productTypesActions.disableProductSuccess({
                                id: action.id,
                                code: action.code,
                                isDisabled: action.isDisabled,
                            })
                        ),
                        catchError(() =>
                            of(
                                productTypesActions.disableProductFailure({
                                    id: action.id,
                                    code: action.code,
                                })
                            )
                        )
                    )
            )
        )
    );
}
