import { Component, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-guidance-text-icon',
    templateUrl: './atom-guidance-text-icon.component.html',
})
export class AtomGuidanceTextIconComponent {
    @Input() guidanceText: string;
}
