import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxMarkdownToHtmlPipe, WdxSafeHtmlPipe } from '@wdx/shared/utils';
import { WdxLabelComponent } from './wdx-label.component';

@NgModule({
    imports: [
        CommonModule,
        WdxIconModule,
        WdxSafeHtmlPipe,
        WdxMarkdownToHtmlPipe,
    ],
    declarations: [WdxLabelComponent],
    exports: [WdxLabelComponent],
})
export class WdxLabelModule {}
