import { Component, Input } from '@angular/core';

import { Severity } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-severity-indicators',
    templateUrl: './atom-severity-indicators.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'severity-indicators d-flex align-items-center justify-content-end',
    },
})
export class AtomSeverityIndicatorsComponent {
    @Input() indicators: Severity[];

    readonly SEVERITY = Severity;

    hasSeverityIndicator(indicators: Severity[], severity: Severity): boolean {
        return indicators.indexOf(severity) > -1;
    }
}
