import { createAction, props } from '@ngrx/store';

export const initialiseApp = createAction(
    '[Global] Initialise App',
    props<{
        isAuthenticated: boolean;
    }>()
);

export const appInitialised = createAction('[Global] App Initialised');

export const listenToUiReloadSuccess = createAction(
    '[Global] Listen To UI Reload Success'
);
