import { Action, createReducer, on } from '@ngrx/store';
import { Party } from '@wdx/clmi/api-models';
import { EngagementState } from '../../models/engagement-state.model';
import * as engagementBarActions from './engagement-bar.actions';

export interface State {
    isVisible: boolean;
    activityId?: string;
    party?: Party;
    engagementState?: EngagementState;
}

export const initialState: State = {
    isVisible: false,
};

const reducerSetup = createReducer(
    initialState,

    on(
        engagementBarActions.setVisibility,
        (state: State, props): State => ({
            ...state,
            isVisible: props.isVisible,
        })
    ),

    on(
        engagementBarActions.setActivityId,
        (state: State, props): State => ({
            ...state,
            activityId: props.activityId,
        })
    ),

    on(
        engagementBarActions.setParty,
        (state: State, props): State => ({
            ...state,
            party: props.party,
        })
    ),

    on(
        engagementBarActions.setEngagementState,
        (state: State, props): State => ({
            ...state,
            engagementState: props.engagementState,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
