import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DocumentRequirementType,
    SortDirection,
    StatusType,
} from '@wdx/clmi/api-models';
import { PaginatedApiResponse, Paging } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class DocumentRequirementTypesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getAll(): Observable<DocumentRequirementType[]> {
        return this.http.get<DocumentRequirementType[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirementtype/all`
        );
    }

    searchDocumentRequirementTypesClient(
        paginationOptions: PaginationOptions,
        filter: any
    ): Observable<PaginatedApiResponse<DocumentRequirementType>> {
        paginationOptions.pageSize = paginationOptions.pageSize || 9999;

        const getAll$ = this.http.get<DocumentRequirementType[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirementtype/all`
        );

        return getAll$.pipe(
            map((documentTypes) => {
                let searches = documentTypes.filter(
                    (m) =>
                        m.code
                            ?.toLowerCase()
                            .includes((filter.search || '').toLowerCase()) ||
                        m.description
                            ?.toLowerCase()
                            .includes((filter.search || '').toLowerCase()) ||
                        m.fulfilmentType
                            ?.toLowerCase()
                            .includes((filter.search || '').toLowerCase()) ||
                        m.entityType
                            ?.toLowerCase()
                            .includes((filter.search || '').toLowerCase()) ||
                        m.status
                            ?.toLowerCase()
                            .includes((filter.search || '').toLowerCase())
                );

                switch (filter.sortBy) {
                    case 'code':
                        searches = searches.sort((a, b) =>
                            this.getSearchSort(
                                filter.sortDirection,
                                a.code,
                                b.code
                            )
                        );
                        break;
                    case 'description':
                        searches = searches.sort((a, b) =>
                            this.getSearchSort(
                                filter.sortDirection,
                                a.description,
                                b.description
                            )
                        );
                        break;
                    case 'fulfilmentType':
                        searches = searches.sort((a, b) =>
                            this.getSearchSort(
                                filter.sortDirection,
                                a.fulfilmentType,
                                b.fulfilmentType
                            )
                        );
                        break;
                    case 'entityType':
                        searches = searches.sort((a, b) =>
                            this.getSearchSort(
                                filter.sortDirection,
                                a.entityType,
                                b.entityType
                            )
                        );
                        break;
                    case 'numberRequired':
                        searches = searches.sort((a, b) =>
                            this.getSearchSort(
                                filter.sortDirection,
                                a.numberRequired,
                                b.numberRequired
                            )
                        );
                        break;
                    case 'status':
                        searches = searches.sort((a, b) =>
                            this.getSearchSort(
                                filter.sortDirection,
                                a.status,
                                b.status
                            )
                        );
                        break;
                    default:
                        break;
                }

                const resultPaging: PaginatedApiResponse<DocumentRequirementType> =
                    {
                        paging: {
                            page: paginationOptions.pageNumber,
                            pageSize: paginationOptions.pageSize || 9999,
                            totalPages: Math.ceil(
                                searches.length / paginationOptions.pageSize
                            ),
                            totalRecords: searches.length,
                        } as Paging,
                        results: searches,
                    };
                return resultPaging;
            })
        );
    }

    setDocumentStatus(
        id: string,
        status: StatusType
    ): Observable<DocumentRequirementType> {
        return this.http.patch<DocumentRequirementType>(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirementtype/${id}/status/${status}`,
            {}
        );
    }

    deleteDocumentRequirementTypes(id: string): Observable<any> {
        return this.http.delete<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/documentrequirementtype/${id}`
        );
    }

    getSearchSort(sortDirection, a, b) {
        return sortDirection === SortDirection.Ascending
            ? a > b
                ? 1
                : -1
            : a > b
            ? -1
            : 1;
    }
}
