import { Pipe, PipeTransform } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/api-models';

import { SavedViewQueryParam } from '../models/saved-view-query-param';

@Pipe({ name: 'savedViewQueryParams' })
export class SavedViewQueryParamsPipe implements PipeTransform {
    transform(filterType: SystemEntity, viewId: string): SavedViewQueryParam {
        const params = {
            viewId,
        };

        switch (filterType) {
            case SystemEntity.Client:
                params['tab-contacts'] = 0;
                break;

            case SystemEntity.Lead:
                params['tab-contacts'] = 1;
                break;

            case SystemEntity.Person:
                params['tab-contacts'] = 2;
                break;

            case SystemEntity.Organisation:
                params['tab-contacts'] = 3;
                break;

            case SystemEntity.Intermediary:
                params['tab-contacts'] = 4;
                break;

            case SystemEntity.Adviser:
                params['tab-contacts'] = 5;
                break;

            default:
                break;
        }

        return params;
    }
}
