export const stringHighlightHelper = (
    resultsText: string,
    searchText: string
): string => {
    if (!resultsText && !searchText) {
        return '';
    }

    if (!searchText) {
        return resultsText;
    }

    const reg = new RegExp(searchText, 'gi');

    return resultsText.replace(reg, function (str) {
        return `<strong>${str}</strong>`;
    });
};
