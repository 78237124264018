import { Action, createReducer, on } from '@ngrx/store';
import { Currency } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as currenciesActions from './currencies.actions';

export interface State {
    currencies: CrudStateObject<Currency>;
}

export const initialState: State = {
    currencies: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        currenciesActions.getCurrencies,
        (state): State => ({
            ...state,
            currencies: {
                ...state.currencies,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.currencies[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<Currency>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        currenciesActions.getCurrenciesSuccess,
        (state, props): State => ({
            ...state,
            currencies: {
                ...state.currencies,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.currencies[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.currencies,
                },
            },
        })
    ),

    on(
        currenciesActions.getCurrenciesFailure,
        (state): State => ({
            ...state,
            currencies: {
                ...state.currencies,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.currencies[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
