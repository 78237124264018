import { Pipe, PipeTransform } from '@angular/core';
import { DisplayDatePipe } from '@wdx/shared/utils';

@Pipe({ name: 'dateDeadline' })
export class DateDeadlinePipe implements PipeTransform {
    transform(value: Date | string, soonThresholdDays?: number): string {
        if (!value) {
            return 'Anytime';
        }

        const valueDate = new Date(value);
        const now = new Date();
        const passedDeadline = valueDate <= now;
        let passedThreshold: boolean;
        const relative = new DisplayDatePipe().transform(value, {
            relative: true,
            relativeStyle: 'long',
        });

        if (soonThresholdDays !== undefined || null) {
            const thresholdDate = new Date(
                now.getTime() + 1000 * 60 * 60 * 24 * soonThresholdDays
            );
            passedThreshold = thresholdDate >= valueDate;
        }

        const threshold = passedDeadline
            ? 'Overdue'
            : passedThreshold
            ? 'Due soon'
            : 'On time';

        return `${threshold}${
            passedThreshold || passedDeadline ? ' - ' + relative : ''
        }`;
    }
}
