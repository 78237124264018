import { SystemEntity } from '@wdx/clmi/api-models';
import {
    URL_ARTICLES,
    URL_CASES,
    URL_CONTACTS,
    URL_CONTACTS_CLIENT_RELATIONSHIPS,
    URL_CONTACTS_LEADS,
    URL_CONTACTS_ORGANISATIONS,
    URL_CONTACTS_PEOPLE,
    URL_ED_MESSAGE_BATCH,
    URL_EXPORTS,
    URL_FEED,
    URL_IMPORTS,
    URL_INBOX,
    URL_LISTS,
    URL_MARKETING_CAMPAIGNS,
    URL_NOTIFICATIONS,
    URL_PIPELINE,
    URL_PRODUCTS,
    URL_QUEUES,
    URL_TASKS,
    URL_UNIMPLEMENTED,
} from '@wdx/shared/utils';

export const ENTITY_SEARCH_ROUTE_MAP = {
    [SystemEntity.Activity]: URL_INBOX,
    [SystemEntity.ActivityParty]: URL_UNIMPLEMENTED,
    [SystemEntity.Adviser]: URL_CONTACTS,
    [SystemEntity.AmlReport]: URL_UNIMPLEMENTED,
    [SystemEntity.Appointment]: URL_INBOX,
    [SystemEntity.AppStatus]: URL_UNIMPLEMENTED,
    [SystemEntity.Article]: URL_ARTICLES,
    [SystemEntity.ArticleDistribution]: URL_UNIMPLEMENTED,
    [SystemEntity.BankAccount]: URL_UNIMPLEMENTED,
    [SystemEntity.Benchmark]: URL_UNIMPLEMENTED,
    [SystemEntity.Campaign]: URL_MARKETING_CAMPAIGNS,
    [SystemEntity.Case]: URL_CASES,
    [SystemEntity.Client]: URL_CONTACTS_CLIENT_RELATIONSHIPS,
    [SystemEntity.ClientProduct]: URL_PRODUCTS,
    [SystemEntity.ClientProductRelationship]: URL_UNIMPLEMENTED,
    [SystemEntity.ContactPoint]: URL_UNIMPLEMENTED,
    [SystemEntity.DataLock]: URL_UNIMPLEMENTED,
    [SystemEntity.EdsMessageBatch]: URL_ED_MESSAGE_BATCH,
    [SystemEntity.ElectronicAddress]: URL_UNIMPLEMENTED,
    [SystemEntity.Email]: URL_INBOX,
    [SystemEntity.Engagement]: URL_INBOX,
    [SystemEntity.Export]: URL_EXPORTS,
    [SystemEntity.Feed]: URL_FEED,
    [SystemEntity.FeedNudge]: URL_UNIMPLEMENTED,
    [SystemEntity.FeeStructure]: URL_UNIMPLEMENTED,
    [SystemEntity.GoalPoint]: URL_UNIMPLEMENTED,
    [SystemEntity.Import]: URL_IMPORTS,
    [SystemEntity.InsuranceAccount]: URL_UNIMPLEMENTED,
    [SystemEntity.Intermediary]: URL_CONTACTS,
    [SystemEntity.InvestmentAccount]: URL_UNIMPLEMENTED,
    [SystemEntity.Lead]: URL_CONTACTS_LEADS,
    [SystemEntity.LendingAccount]: URL_UNIMPLEMENTED,
    [SystemEntity.List]: URL_LISTS,
    [SystemEntity.Mandate]: URL_UNIMPLEMENTED,
    [SystemEntity.Message]: URL_INBOX,
    [SystemEntity.AmlRisk]: URL_UNIMPLEMENTED,
    [SystemEntity.Note]: URL_UNIMPLEMENTED,
    [SystemEntity.Notification]: URL_NOTIFICATIONS,
    [SystemEntity.Nudge]: URL_UNIMPLEMENTED,
    [SystemEntity.NudgeLog]: URL_UNIMPLEMENTED,
    [SystemEntity.Objective]: URL_UNIMPLEMENTED,
    [SystemEntity.Opportunity]: URL_PIPELINE,
    [SystemEntity.Organisation]: URL_CONTACTS_ORGANISATIONS,
    [SystemEntity.Party]: URL_CONTACTS,
    [SystemEntity.PartyRole]: URL_CONTACTS,
    [SystemEntity.PartyRoleAccess]: URL_UNIMPLEMENTED,
    [SystemEntity.Person]: URL_CONTACTS_PEOPLE,
    [SystemEntity.PhoneAddress]: URL_UNIMPLEMENTED,
    [SystemEntity.PhoneCall]: URL_INBOX,
    [SystemEntity.PostalAddress]: URL_UNIMPLEMENTED,
    [SystemEntity.PotentialDuplicate]: URL_UNIMPLEMENTED,
    [SystemEntity.Process]: URL_UNIMPLEMENTED,
    [SystemEntity.Product]: URL_UNIMPLEMENTED,
    [SystemEntity.ProductGroup]: URL_UNIMPLEMENTED,
    [SystemEntity.ProductGroupMember]: URL_UNIMPLEMENTED,
    [SystemEntity.QueueItem]: URL_QUEUES,
    [SystemEntity.Relationship]: URL_UNIMPLEMENTED,
    [SystemEntity.Reminder]: URL_UNIMPLEMENTED,
    [SystemEntity.RiskLevel]: URL_UNIMPLEMENTED,
    [SystemEntity.SocialAddress]: URL_UNIMPLEMENTED,
    [SystemEntity.Tag]: URL_UNIMPLEMENTED,
    [SystemEntity.Task]: URL_TASKS,
    [SystemEntity.Team]: URL_CONTACTS,
    [SystemEntity.TimeBooking]: URL_UNIMPLEMENTED,
    [SystemEntity.User]: URL_CONTACTS,
    [SystemEntity.WebHook]: URL_UNIMPLEMENTED,
    [SystemEntity.WorkItem]: URL_CASES,
};
