import { Injectable } from '@angular/core';
import { FeatureFlagValue, Features } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class FeaturesService {
    private features: Features = {};

    /**
     * Sets the features data from api (primarily from app guard)
     */
    set(features: Features) {
        this.features = features;
    }

    /**
     * Returns the features object
     */
    get() {
        return this.features;
    }

    /**
     * Returns true if the features match a given FeatureFlag
     * @param featureFlag
     */
    hasFeature(featureFlag: FeatureFlagValue) {
        return Boolean(this.features[featureFlag]);
    }
}
