import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';
// eslint-disable-next-line max-len
import {
    Activity,
    ActivityChat,
    Article,
    ArticleCreate,
    ArticleLinkedRecord,
    ArticleStatusType,
    Case,
    Client,
    FileIndex,
    HistoryEntry,
    PartyStub,
    Person,
} from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '../../services/api-pagination.service';

@Injectable()
export class ArticlesService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    create(articleCreateData: ArticleCreate): Observable<Article> {
        return this.http.post<Article>(
            `${this.config.getConfiguration().API_BASE}/article`,
            articleCreateData
        );
    }

    update(articleUpdateData: Article): Observable<Article> {
        return this.http.put<Article>(
            `${this.config.getConfiguration().API_BASE}/article/${
                articleUpdateData.id
            }`,
            articleUpdateData
        );
    }

    parse(articleParseData: any): Observable<Article> {
        return this.http.post<Article>(
            `${this.config.getConfiguration().API_BASE}/article/fromurl`,
            articleParseData
        );
    }

    getFavourites(): Observable<Article[]> {
        return this.http.get<Article[]>(
            `${this.config.getConfiguration().API_BASE}/article/favourite`
        );
    }

    getForId(articleId: string): Observable<Article> {
        return this.http.get<Article>(
            `${this.config.getConfiguration().API_BASE}/article/${articleId}`
        );
    }

    getHistoryForId(
        paginationOptions: PaginationOptions,
        articleId: string
    ): Observable<PaginatedApiResponse<HistoryEntry>> {
        return this.http.get<PaginatedApiResponse<HistoryEntry>>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/history`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    getTargettedForId(articleId: string): Observable<Person[]> {
        return this.http.get<Person[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/targetted`
        );
    }

    getViewedForId(articleId: string): Observable<Person[]> {
        return this.http.get<Person[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/viewed`
        );
    }

    getRecentArticles(): Observable<Article[]> {
        return this.http.get<Article[]>(
            `${this.config.getConfiguration().API_BASE}/article/recent`
        );
    }

    getFeedClientsForId(articleId: string): Observable<Client[]> {
        return this.http.get<Client[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/feedclients`
        );
    }

    getCommentsForId(articleId: string): Observable<ActivityChat[]> {
        return this.http.get<ActivityChat[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/comments`
        );
    }

    linkImages(articleId: string, fileIndexes: FileIndex[]): Observable<any> {
        return forkJoin(
            fileIndexes.map((fileIndex) =>
                this.http.post<Article>(
                    `${
                        this.config.getConfiguration().API_BASE
                    }/article/${articleId}/image/${fileIndex.fileIndex}`,
                    {}
                )
            )
        );
    }

    linkFiles(articleId: string, fileIndexes: FileIndex[]): Observable<any> {
        return forkJoin(
            fileIndexes.map((fileIndex) =>
                this.http.post<Article>(
                    `${
                        this.config.getConfiguration().API_BASE
                    }/article/${articleId}/file/${fileIndex.fileIndex}`,
                    {}
                )
            )
        );
    }

    linkedRecords(articleId: string): Observable<ArticleLinkedRecord[]> {
        return this.http.get<ArticleLinkedRecord[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/linkedrecord`
        );
    }

    linkedActivities(articleId: string): Observable<Activity[]> {
        return this.http.get<Activity[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/linkedactivities`
        );
    }

    linkedCases(articleId: string): Observable<Case[]> {
        return this.http.get<Case[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/linkedcases`
        );
    }

    favourited(
        paginationOptions: PaginationOptions,
        articleId: string
    ): Observable<PaginatedApiResponse<PartyStub>> {
        return this.http.get<PaginatedApiResponse<PartyStub>>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/favourited`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    setStatus(
        articleId: string,
        articleStatus: ArticleStatusType
    ): Observable<Article> {
        return this.http.post<Article>(
            `${
                this.config.getConfiguration().API_BASE
            }/article/${articleId}/status/${articleStatus}`,
            {}
        );
    }
}
