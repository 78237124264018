import { Injectable, inject } from '@angular/core';
import { Country, PhoneType } from '@wdx/shared/utils';
import { Observable, map } from 'rxjs';
import { IFormDynamicData } from '../../interfaces';

@Injectable()
export class TelephoneService {
    private dynamicDataService = inject(IFormDynamicData);

    fieldValueToTelephonePattern$(
        fieldValue: any,
        asPatternMask = false
    ): Observable<string> {
        const regexChar = '0';
        return this.dynamicDataService.getCountries().pipe(
            map((countries) => {
                const country = countries.find(
                    (country) => country.isoCode === fieldValue.country
                );
                const dialCodePattern = `+${Array.from(
                    { length: country?.dialCode?.length || 0 },
                    () => regexChar
                ).join('')}`;
                const numberPattern =
                    this.getCountryPattern(
                        fieldValue.type,
                        country as Country
                    ) ||
                    Array.from(
                        { length: fieldValue.number.length },
                        () => regexChar
                    ).join('');
                const fullPattern = [...dialCodePattern, ' ', ...numberPattern];
                if (!asPatternMask) {
                    const fullNumber = country?.dialCode + fieldValue.number;
                    const indexMap = fullPattern.reduce(
                        (accumulator, char, i) => {
                            if (char === regexChar) {
                                accumulator = [...accumulator, i];
                            }
                            return accumulator;
                        },
                        [] as number[]
                    );
                    [...fullNumber].forEach((num, i) =>
                        fullPattern.splice(indexMap[i], 1, num)
                    );
                }
                return fullPattern.join('');
            })
        );
    }

    getCountryPattern(type: PhoneType, country: Country): string {
        const isMobile = [
            PhoneType.MobilePersonal,
            PhoneType.MobileOther,
            PhoneType.MobileWork,
        ].includes(type);
        return (isMobile ? country.mobileRegex : country.phoneRegex) as string;
    }
}
