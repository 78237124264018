import { Action, createReducer, on } from '@ngrx/store';
import {
    AccessVisibility,
    Activity,
    ActivityFile,
    HistoryEntry,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import {
    FileUploadState,
    FileUploadStatus,
} from '@wdx/shared/infrastructure/file-io';
import * as activitiesActions from './activities.actions';

export interface State {
    attachments?: CrudStateObject<ActivityFile>;
    documents?: CrudStateObject<Document>;
    lastActivity?: CrudStateObject<Activity>;
    nextActivity?: CrudStateObject<Activity>;
    activities?: CrudStateObject<Activity>;
    clientActivities?: CrudStateObject<Activity>;
    activityHistory?: CrudStateObject<HistoryEntry>;
    attachment?: { [key: string]: FileUploadState };
    visibility?: CrudStateObject<AccessVisibility>;
}

export const initialState: State = {
    attachments: {},
    documents: {},
    lastActivity: {},
    nextActivity: {},
    activities: {},
    clientActivities: {},
    activityHistory: {},
    attachment: {},
    visibility: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        activitiesActions.getAttachmentsForId,
        (state, props): State => ({
            ...state,
            attachments: {
                ...state.attachments,
                [props.activityId]: {
                    ...(state.attachments[props.activityId] ||
                        ({} as CrudState<ActivityFile>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.getAttachmentsForIdSuccess,
        (state, props): State => ({
            ...state,
            attachments: {
                ...state.attachments,
                [props.activityId]: {
                    ...state.attachments[props.activityId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.attachments,
                },
            },
        })
    ),

    on(
        activitiesActions.getAttachmentsForIdFailure,
        (state, props): State => ({
            ...state,
            attachments: {
                ...state.attachments,
                [props.activityId]: {
                    ...state.attachments[props.activityId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        activitiesActions.getDocumentsForId,
        (state, props): State => ({
            ...state,
            documents: {
                ...state.documents,
                [props.activityId]: {
                    ...(state.documents[props.activityId] ||
                        ({} as CrudState<Document>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.documents[props.activityId]?.infinity,
                },
            },
        })
    ),

    on(
        activitiesActions.getDocumentsForIdSuccess,
        (state, props): State => ({
            ...state,
            documents: {
                ...state.documents,
                [props.activityId]: {
                    ...state.documents[props.activityId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.documents.paging,
                        combinedList: [
                            ...(state.documents[props.activityId].infinity
                                ?.combinedList || []),
                            ...props.documents.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        activitiesActions.getDocumentsForIdFailure,
        (state, props): State => ({
            ...state,
            documents: {
                ...state.documents,
                [props.activityId]: {
                    ...state.documents[props.activityId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        activitiesActions.getLastActivityForParty,
        (state, props): State => ({
            ...state,
            lastActivity: {
                ...state.lastActivity,
                [props.partyId]: {
                    ...(state.lastActivity[props.partyId] ||
                        ({} as CrudState<Activity>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.getLastActivityForPartySuccess,
        (state, props): State => ({
            ...state,
            lastActivity: {
                ...state.lastActivity,
                [props.partyId]: {
                    ...state.lastActivity[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.activity,
                },
            },
        })
    ),

    on(
        activitiesActions.getLastActivityForPartyFailure,
        (state, props): State => ({
            ...state,
            lastActivity: {
                ...state.lastActivity,
                [props.partyId]: {
                    ...state.lastActivity[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        activitiesActions.getNextActivityForParty,
        (state, props): State => ({
            ...state,
            nextActivity: {
                ...state.nextActivity,
                [props.partyId]: {
                    ...(state.nextActivity[props.partyId] ||
                        ({} as CrudState<Activity>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.getNextActivityForPartySuccess,
        (state, props): State => ({
            ...state,
            nextActivity: {
                ...state.nextActivity,
                [props.partyId]: {
                    ...state.nextActivity[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.activity,
                },
            },
        })
    ),

    on(
        activitiesActions.getNextActivityForPartyFailure,
        (state, props): State => ({
            ...state,
            nextActivity: {
                ...state.nextActivity,
                [props.partyId]: {
                    ...state.nextActivity[props.partyId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        activitiesActions.getForClientProduct,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.clientProductId]: {
                    ...(state.activities[props.clientProductId] ||
                        ({} as CrudState<Activity>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.activities[props.clientProductId]?.infinity,
                },
            },
        })
    ),

    on(
        activitiesActions.getForClientProductSuccess,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.clientProductId]: {
                    ...state.activities[props.clientProductId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.activities.paging,
                        combinedList: [
                            ...(state.activities[props.clientProductId].infinity
                                ?.combinedList || []),
                            ...props.activities.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        activitiesActions.getForClientProductFailure,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.clientProductId]: {
                    ...state.activities[props.clientProductId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        activitiesActions.getForClient,
        (state, props): State => ({
            ...state,
            clientActivities: {
                ...state.clientActivities,
                [props.clientId]: {
                    ...(state.clientActivities[props.clientId] ||
                        ({} as CrudState<Activity>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.clientActivities[props.clientId]?.infinity,
                },
            },
        })
    ),

    on(
        activitiesActions.getForClientSuccess,
        (state, props): State => ({
            ...state,
            clientActivities: {
                ...state.clientActivities,
                [props.clientId]: {
                    ...state.clientActivities[props.clientId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.clientActivities.paging,
                        combinedList: [
                            ...(state.clientActivities[props.clientId].infinity
                                ?.combinedList || []),
                            ...props.clientActivities.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        activitiesActions.getForClientFailure,
        (state, props): State => ({
            ...state,
            clientActivities: {
                ...state.clientActivities,
                [props.clientId]: {
                    ...state.clientActivities[props.clientId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        activitiesActions.getForId,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activityId]: {
                    ...(state.activities[props.activityId] ||
                        ({} as CrudState<Activity>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.getForIdSuccess,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activityId]: {
                    ...state.activities[props.activityId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.activity,
                },
            },
        })
    ),

    on(
        activitiesActions.getForIdFailure,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activityId]: {
                    ...state.activities[props.activityId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        activitiesActions.setStatusForId,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activityId]: {
                    ...(state.activities[props.activityId] ||
                        ({} as CrudState<Activity>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.setStatusForIdSuccess,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activity?.id]: {
                    single: props.activity,
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.setStatusForIdFailure,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activityId]: {
                    ...state.activities[props.activityId],
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        activitiesActions.cancelActivity,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activityId]: {
                    ...state.activities[props.activityId],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.cancelActivitySuccess,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activity?.id]: {
                    single: props.activity,
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.setDueDate,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activityId]: {
                    ...state.activities[props.activityId],
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.setDueDateSuccess,
        (state, props): State => ({
            ...state,
            activities: {
                ...state.activities,
                [props.activity?.id]: {
                    single: props.activity,
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.getHistoryForId,
        (state, props): State => ({
            ...state,
            activityHistory: {
                ...state.activityHistory,
                [props.activityId]: {
                    ...(state.activityHistory[props.activityId] ||
                        ({} as CrudState<HistoryEntry>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.getHistoryForIdSuccess,
        (state, props): State => ({
            ...state,
            activityHistory: {
                ...state.activityHistory,
                [props.activityId]: {
                    ...state.activityHistory[props.activityId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.activityHistory,
                },
            },
        })
    ),

    on(
        activitiesActions.getHistoryForIdFailure,
        (state, props): State => ({
            ...state,
            activityHistory: {
                ...state.activityHistory,
                [props.activityId]: {
                    ...state.activityHistory[props.activityId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        activitiesActions.uploadAttachment,
        (state, props): State => ({
            ...state,
            attachment: {
                ...state.attachment,
                [props.activityId]: {
                    ...(state.attachment[props.activityId] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Ready,
                    progress: 0,
                },
            },
        })
    ),

    on(
        activitiesActions.uploadAttachmentCancel,
        (state, props): State => ({
            ...state,
            attachment: {
                ...state.attachment,
                [props.activityId]: {
                    ...(state.attachment[props.activityId] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Ready,
                    progress: 0,
                },
            },
        })
    ),

    on(
        activitiesActions.uploadAttachmentReset,
        (state, props): State => ({
            ...state,
            attachment: {
                ...state.attachment,
                [props.activityId]: {
                    ...(state.attachment[props.activityId] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Ready,
                    progress: 0,
                    fileIndex: undefined,
                },
            },
        })
    ),

    on(
        activitiesActions.uploadAttachmentStarted,
        (state, props): State => ({
            ...state,
            attachment: {
                ...state.attachment,
                [props.activityId]: {
                    ...(state.attachment[props.activityId] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Started,
                },
            },
        })
    ),

    on(
        activitiesActions.uploadAttachmentProgress,
        (state, props): State => ({
            ...state,
            attachment: {
                ...state.attachment,
                [props.activityId]: {
                    ...(state.attachment[props.activityId] ||
                        ({} as FileUploadState)),
                    progress: props.progress,
                },
            },
        })
    ),

    on(
        activitiesActions.uploadAttachmentComplete,
        (state, props): State => ({
            ...state,
            attachment: {
                ...state.attachment,
                [props.activityId]: {
                    ...(state.attachment[props.activityId] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Completed,
                    progress: 100,
                    fileIndex: props.fileIndex,
                },
            },
        })
    ),

    on(
        activitiesActions.uploadAttachmentFailure,
        (state, props): State => ({
            ...state,
            attachment: {
                ...state.attachment,
                [props.activityId]: {
                    ...(state.attachment[props.activityId] ||
                        ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Failed,
                    error: props.error,
                },
            },
        })
    ),

    on(
        activitiesActions.getVisibilityForId,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...(state.visibility[props.id] || {}),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        activitiesActions.getVisibilityForIdSuccess,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.visibility,
                },
            },
        })
    ),

    on(
        activitiesActions.getVisibilityForIdFailure,
        (state, props): State => ({
            ...state,
            visibility: {
                ...state.visibility,
                [props.id]: {
                    ...state.visibility[props.id],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
