import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class AvatarProfileFileUploadService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    uploadFile(file: File): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append(file.name, file);

        return this.http.request(
            new HttpRequest(
                'PUT',
                `${this.config.getConfiguration().API_BASE}/user/me/avatar`,
                formData,
                {
                    reportProgress: true,
                }
            )
        );
    }
}
