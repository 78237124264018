<div
    class="d-flex"
    [class.flex-column]="isVertical"
    [class.align-items-center]="!isVertical"
>
    <h6 *ngIf="label" class="me-3 d-none d-sm-block">
        {{ label }}
    </h6>
    <ul
        *ngIf="items?.length"
        class="legend d-flex mb-0"
        [class.legend--vertical]="isVertical"
        [ngClass]="{ alert: hasContainer }"
    >
        <li
            *ngFor="
                let item of items;
                let i = index;
                let first = first;
                let last = last
            "
            class="legend__item d-flex align-items-center"
            [ngClass]="{
                'mx-1 mx-sm-2': !isVertical && !first && !last,
                'me-1 me-sm-2': !isVertical && first && !last,
                'ms-1 ms-sm-2': !isVertical && last && !first,
                'mt-1': i > 0 && isVertical,
                'm-0': first && last && !hasContainer
            }"
        >
            <div
                class="d-flex align-items-center"
                [class.flex-column-on-small-screen]="item.iconLetter"
            >
                <div class="d-flex align-items-center me-1">
                    <wdx-icon
                        *ngIf="item.icon"
                        [iconClass]="
                            'text-' + (item.themeColor || item.systemColor)
                        "
                        [icon]="item.icon"
                        [fixedWidth]="true"
                    ></wdx-icon>
                    <atom-icon-letter
                        *ngIf="item.iconLetter"
                        class="d-none d-sm-block"
                        [iconLetter]="item.iconLetter"
                    ></atom-icon-letter>
                    <atom-highlight-circle
                        *ngIf="
                            item.iconLetter ||
                            item.highlight ||
                            ((item.themeColor || item.systemColor) &&
                                !item.icon)
                        "
                        class="d-block"
                        [class.d-sm-none]="item.iconLetter"
                        [themeColor]="
                            item.iconLetter?.highlight ||
                            item.highlight ||
                            item.themeColor
                        "
                        [systemColor]="item.systemColor"
                    ></atom-highlight-circle>
                </div>
                <div
                    class="legend__label"
                    [class.overline-on-sm-screen]="item.iconLetter"
                >
                    {{
                        item.translationKey
                            ? (item.translationKey | translate)
                            : item.label
                    }}
                </div>
            </div>
        </li>
    </ul>
</div>
