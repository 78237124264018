<span
    class="js-label"
    *ngIf="!isLoading && !hasError"
    [innerHtml]="count"
></span>
<wdx-spinner
    [small]="true"
    *ngIf="isLoading || hasError"
    [themeColor]="hasError ? 'danger' : 'primary'"
></wdx-spinner>
