import { Injectable, Type, inject } from '@angular/core';
import { ISubformCustomHandler } from '@wdx/shared/infrastructure/form-framework';
import { MoleculeAvatarGroupComponent } from '../../../../components/molecules/molecule-avatar-group/molecule-avatar-group/molecule-avatar-group.component';
import { LookupAvatarsPipe } from '../../../../shared/features/form-summary/pipes/lookup-avatars/lookup-avatars.pipe';
import { Party } from '@wdx/clmi/api-models';
import { Avatar } from '@wdx/shared/components/wdx-avatar';

@Injectable()
export class SubformCustomHandlerService implements ISubformCustomHandler {
    private avatarLookup = inject(LookupAvatarsPipe);

    public getAvatarData(value: any): Avatar[] | null {
        const avatars = this.avatarLookup.transform(value as Party);
        return avatars.length ? avatars : null;
    }
    public getAvatarComponent(): {
        component: Type<any>;
        inputs: Record<string, unknown>;
        avatarInput: string;
    } {
        return {
            component: MoleculeAvatarGroupComponent,
            inputs: {},
            avatarInput: 'avatars',
        };
    }
}
