import { Injectable } from '@angular/core';

import { QuickLink } from '@wdx/clmi/api-models';

import { NavItem } from '@wdx/shared/utils';
import { QUICK_LINKS_CLIENTS } from '../../../constants/quick-links.constants';
import { InfoCardData } from '../../../models/info-card-data.model';
import { InfoCardGroupData } from '../../../models/info-card-group-data.model';

@Injectable({
    providedIn: 'root',
})
export class QuickLinksService {
    quickLinksInfoCardGroupData: InfoCardGroupData[] = [];
    quickLinksMenuItem: NavItem[];

    setQuickLinks(quickLinks: QuickLink[]): InfoCardGroupData[] {
        if (!quickLinks || !Array.isArray(quickLinks)) {
            return [];
        }

        const INFO_CARDS = [];
        const MENU_ITEMS = [];

        quickLinks.forEach((quickLink: QuickLink) => {
            if (
                QUICK_LINKS_CLIENTS[quickLink.tabType] ||
                quickLink?.formName ||
                quickLink?.appType ||
                quickLink?.url
            ) {
                const COUNT = ` (${quickLink?.count})`;
                const NAME_VAL = quickLink?.displayName?.value;
                const NAME = `${NAME_VAL}${quickLink?.count ? COUNT : ''}`;
                const NAME_LOWERCASE = NAME_VAL?.toLowerCase();
                const QUICK_LINK_KEY = quickLink?.tabType;

                const MODAL_ID = QUICK_LINKS_CLIENTS[quickLink.tabType]?.id;
                const MODAL = MODAL_ID
                    ? {
                          data: {
                              id: MODAL_ID,
                              type: 'modal',
                          },
                      }
                    : {};

                const FORM = quickLink?.formName
                    ? {
                          data: {
                              app: {
                                  formName: quickLink?.formName,
                                  //Add this as it is legacy. Please use entityId prop
                                  formDataEntityId: quickLink?.entityId,
                                  entityId: quickLink?.entityId,
                                  entityType: quickLink?.entityType,
                              },
                              type: 'formFramework',
                          },
                      }
                    : {};

                const APP = quickLink?.appType
                    ? {
                          data: {
                              id: quickLink?.appId,
                              name: NAME_VAL,
                              type: quickLink?.appType,
                          },
                      }
                    : {};

                const EXTERNAL_LINKS = quickLink?.url
                    ? {
                          data: {
                              externalUrl: quickLink?.url,
                              target: '_blank',
                              type: 'externalUrl',
                          },
                      }
                    : {};

                const INFO_CARD: InfoCardData = {
                    primaryInfo: NAME,
                    cySelector: `btn-quick-link-${NAME_LOWERCASE}`,
                    cyValue: quickLink?.count ? quickLink.count : 0,
                    icon: quickLink.icon,
                    query: quickLink?.query,
                    ...MODAL,
                    ...FORM,
                    ...APP,
                    ...QUICK_LINKS_CLIENTS[quickLink.tabType],
                    ...EXTERNAL_LINKS,
                };

                INFO_CARD.data.quickLinkKey = QUICK_LINK_KEY;

                const MENU_ITEM: NavItem = {
                    label: NAME,
                    cySelector: `option-${NAME_LOWERCASE}`,
                    value: quickLink.tabType,
                    icon: quickLink.icon,
                    query: quickLink?.query,
                    ...MODAL,
                    ...FORM,
                    ...APP,
                    ...QUICK_LINKS_CLIENTS[quickLink.tabType],
                    ...EXTERNAL_LINKS,
                };

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, ...INFO_CARD_REST } = INFO_CARD;

                if (typeof quickLink.count === 'number') {
                    MENU_ITEM.data.count = quickLink.count;
                }

                INFO_CARDS.push(INFO_CARD_REST);
                MENU_ITEMS.push(MENU_ITEM);
            }
        });

        this.quickLinksInfoCardGroupData = [
            {
                infoCards: INFO_CARDS,
            },
        ];

        this.quickLinksMenuItem = MENU_ITEMS;

        return this.quickLinksInfoCardGroupData;
    }
}
