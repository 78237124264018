import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { TranslatePipe } from '@wdx/shared/utils';
import { PipesModule } from '../../../../../pipes/pipes.module';
import { ActivityStatusTagComponent } from './activity-status-tag.component';

@NgModule({
    imports: [CommonModule, PipesModule, WdxBadgeModule, TranslatePipe],
    declarations: [ActivityStatusTagComponent],
    exports: [ActivityStatusTagComponent],
})
export class ActivityStatusTagModule {}
