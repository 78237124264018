import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeWidgetContainerModule } from '../../../components/molecules/molecule-widget-container/molecule-widget-container.module';
import { OrganismActivitiesListModule } from '../../../components/organisms/organism-activities-list/organism-activities-list.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { WidgetActivitiesListComponent } from './widget-activities-list.component';

@NgModule({
    imports: [
        AtomModalWrapperModule,
        CommonModule,
        MoleculeWidgetContainerModule,
        OrganismActivitiesListModule,
        OrganismModalModule,
    ],
    declarations: [WidgetActivitiesListComponent],
    exports: [WidgetActivitiesListComponent],
})
export class WidgetActivitiesListModule {}
