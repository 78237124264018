import { createSelector } from '@ngrx/store';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as documentTypesReducer from './document-types.reducer';

/**
 * Reducer state selector
 */
// eslint-disable-next-line max-len
export const getState = (state: {
    documentTypes: documentTypesReducer.State;
}): documentTypesReducer.State => state.documentTypes;

/**
 * Reducer property selectors
 */
export const selectActiveDocumentTypeState = createSelector(
    getState,
    (state: documentTypesReducer.State) => state.activeDocumentTypeState
);

export const getAllIsLoadingPage = createSelector(
    getState,
    (state: documentTypesReducer.State) =>
        state.documentTypes[GLOBAL_STATE_INDEX_ID]?.isLoadingPage
);
export const getAllHasLoadingPageError = createSelector(
    getState,
    (state: documentTypesReducer.State) =>
        state.documentTypes[GLOBAL_STATE_INDEX_ID]?.hasLoadingPageError
);
export const getAll = createSelector(
    getState,
    (state: documentTypesReducer.State) =>
        state.documentTypes[GLOBAL_STATE_INDEX_ID]?.infinity
);
export const getQueryIsLoadingPage = createSelector(
    getState,
    (state: documentTypesReducer.State) => state.query?.isLoadingPage
);
export const getQueryHasLoadingPageError = createSelector(
    getState,
    (state: documentTypesReducer.State) => state.query?.hasLoadingPageError
);
export const getQueryPaging = createSelector(
    getState,
    (state: documentTypesReducer.State) => state.query?.infinity?.paging
);
export const getQuery = createSelector(
    getState,
    (state: documentTypesReducer.State) => state.query?.infinity?.combinedList
);
export const getAllOrQueryIsLoadingPage = createSelector(
    getState,
    (state: documentTypesReducer.State) =>
        state.query?.isLoadingPage ||
        state.documentTypes[GLOBAL_STATE_INDEX_ID].isLoadingPage
);
export const getAllOrQueryHasLoadingPageError = createSelector(
    getState,
    (state: documentTypesReducer.State) =>
        state.query?.hasLoadingPageError ||
        state.documentTypes[GLOBAL_STATE_INDEX_ID].hasLoadingPageError
);
export const getAllOrQueryPaging = createSelector(
    getState,
    (state: documentTypesReducer.State) =>
        state.query?.infinity?.paging ??
        state.documentTypes[GLOBAL_STATE_INDEX_ID]?.infinity?.paging
);
export const getAllOrQueryPage = createSelector(
    getState,
    (state: documentTypesReducer.State) =>
        state.query?.page?.results ??
        state.documentTypes[GLOBAL_STATE_INDEX_ID]?.page?.results
);
export const getAllOrQuery = createSelector(
    getState,
    (state: documentTypesReducer.State) =>
        state.query?.infinity?.combinedList ??
        state.documentTypes[GLOBAL_STATE_INDEX_ID]?.infinity?.combinedList
);
export const getDocumentTypeById = (props: { documentTypeCode: string }) =>
    createSelector(
        getState,
        (state: documentTypesReducer.State) =>
            state.documentTypes[props.documentTypeCode]?.single
    );
export const getDocumentTypeByIdIsLoading = (props: {
    documentTypeCode: string;
}) =>
    createSelector(
        getState,
        (state: documentTypesReducer.State) =>
            state.documentTypes[props.documentTypeCode]?.isLoadingSingle
    );
export const getDocumentTypeByIdHasLoadingError = (props: {
    documentTypeCode: string;
}) =>
    createSelector(
        getState,
        (state: documentTypesReducer.State) =>
            state.documentTypes[props.documentTypeCode]?.hasLoadingSingleError
    );
export const getDocumentTypesInfinityCombinedList = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentTypesReducer.State) =>
            state.documentTypes[props.id]?.infinity?.combinedList
    );
export const getDocumentTypesInfinityPaging = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentTypesReducer.State) =>
            state.documentTypes[props.id]?.infinity?.paging
    );
export const getDocumentTypesIsLoadingPage = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentTypesReducer.State) =>
            state.documentTypes[props.id]?.isLoadingPage
    );
export const getDocumentTypesHasLoadingPageError = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentTypesReducer.State) =>
            state.documentTypes[props.id]?.hasLoadingPageError
    );

export const getAllActiveDocumentTypeStatus = createSelector(
    getState,
    (state) => state.activeDocumentTypeState.status
);

export const getAllActiveDocumentType = createSelector(
    selectActiveDocumentTypeState,
    documentTypesReducer.selectAllActiveDocumentTypes
);
