import { Action, createReducer, on } from '@ngrx/store';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { CombinedContactPoint } from '../../models/combined-contact-point.model';
import * as contactPointsActions from './contact-points.actions';

export interface State {
    contactPoints: CrudStateObject<CombinedContactPoint>;
}

export const initialState: State = {
    contactPoints: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        contactPointsActions.getForParty,
        (state, props): State => ({
            ...state,
            contactPoints: {
                ...state.contactPoints,
                [props.partyId]: {
                    ...(state.contactPoints[props.partyId] ||
                        ({} as CrudState<CombinedContactPoint>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        contactPointsActions.getForPartySuccess,
        (state, props): State => ({
            ...state,
            contactPoints: {
                ...state.contactPoints,
                [props.partyId]: {
                    ...state.contactPoints[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.contactPoints,
                },
            },
        })
    ),

    on(
        contactPointsActions.getForPartyFailure,
        (state, props): State => ({
            ...state,
            contactPoints: {
                ...state.contactPoints,
                [props.partyId]: {
                    ...state.contactPoints[props.partyId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
