import { Pipe, PipeTransform, Renderer2 } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Pipe({
    name: 'highlighter',
})
export class HighlighterPipe implements PipeTransform {
    constructor(private renderer: Renderer2) {}
    transform(
        value: any,
        searchTerm: string,
        element: HTMLDivElement
    ): unknown {
        if (!searchTerm) {
            this.renderer.setProperty(
                element,
                'innerHTML',
                value.changingThisBreaksApplicationSecurity
            );
            return value;
        }

        if (element) {
            const re = new RegExp(searchTerm, 'igm');
            const newContent =
                value.changingThisBreaksApplicationSecurity.replace(
                    re,
                    (matched) => {
                        return `<span id="id-${uuidv4()}" tabindex="0" class="highlighted-text">${matched}</span>`;
                    }
                );
            this.renderer.setProperty(element, 'innerHTML', newContent);
        }

        return value;
    }
}
