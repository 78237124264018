import { Action, createReducer, on } from '@ngrx/store';
import { Person } from '@wdx/clmi/api-models';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as prospectsActions from './prospects.actions';

export type State = CrudState<Person>;

export const initialState: State = {};

const reducerSetup = createReducer(
    initialState,

    on(
        prospectsActions.create,
        (state): State => ({
            ...state,
            isCreating: true,
            hasCreatingError: false,
        })
    ),

    on(
        prospectsActions.createSuccess,
        (state): State => ({
            ...state,
            isCreating: false,
        })
    ),

    on(
        prospectsActions.createFailure,
        (state): State => ({
            ...state,
            isCreating: false,
            hasCreatingError: true,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
