import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxIconComponent } from './wdx-icon.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxIconComponent],
    exports: [WdxIconComponent],
})
export class WdxIconModule {}
