import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createReconnectEffect } from 'ngrx-signalr-core';

@Injectable()
export class SignalRGlobalEffects {
    private actions$ = inject(Actions);

    whenSignalRDisconnected$ = createReconnectEffect(this.actions$);
}
