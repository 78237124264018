import { Action, createReducer, on } from '@ngrx/store';
import { Document, SystemEntity } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import {
    FileUploadState,
    FileUploadStatus,
} from '@wdx/shared/infrastructure/file-io';
import * as documentsActions from './documents.actions';

export interface State {
    documents?: CrudStateObject<Document>;
    document?: any; // todo refactor for proper type
    clientProductDocuments?: CrudStateObject<Document>;
    [SystemEntity.Party]: DocumentsState;
    [SystemEntity.PartyRole]: DocumentsState;
    documentsForEntity?: CrudStateObject<CrudStateObject<Document>>;
}

export interface DocumentsState {
    [entityId: string]: {
        [dMSProvider: string]: CrudStateObject<Document>;
    };
}

export const initialState: State = {
    documents: {},
    document: {},
    clientProductDocuments: {},
    [SystemEntity.Party]: {},
    [SystemEntity.PartyRole]: {},
    documentsForEntity: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        documentsActions.getDocumentsForClientProduct,
        (state, props): State => ({
            ...state,
            clientProductDocuments: {
                ...state.clientProductDocuments,
                [props.clientProductId]: {
                    ...(state.clientProductDocuments[props.clientProductId] ||
                        ({} as CrudState<Document>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.clientProductDocuments[props.clientProductId]
                              ?.infinity,
                },
            },
        })
    ),

    on(
        documentsActions.getDocumentsForClientProductSuccess,
        (state, props): State => ({
            ...state,
            clientProductDocuments: {
                ...state.clientProductDocuments,
                [props.clientProductId]: {
                    ...state.clientProductDocuments[props.clientProductId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.clientProductDocuments.paging,
                        combinedList: [
                            ...(state.clientProductDocuments[
                                props.clientProductId
                            ].infinity?.combinedList || []),
                            ...props.clientProductDocuments.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        documentsActions.getDocumentsForClientProductFailure,
        (state, props): State => ({
            ...state,
            clientProductDocuments: {
                ...state.clientProductDocuments,
                [props.clientProductId]: {
                    ...state.clientProductDocuments[props.clientProductId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        documentsActions.getDocumentsForEntityId,
        (state, props): State => ({
            ...state,
            documentsForEntity: {
                ...state.documentsForEntity,
                [props.entityType]: {
                    ...(state.documentsForEntity[props.entityType] ||
                        ({} as CrudStateObject<Document>)),
                    [props.entityId]: {
                        ...((state.documentsForEntity[props.entityType] &&
                            state.documentsForEntity[props.entityType][
                                props.entityId
                            ]) ||
                            ({} as CrudState<Document>)),
                        isLoadingList: true,
                        hasLoadingListError: false,
                    },
                },
            },
        })
    ),

    on(
        documentsActions.getDocumentsForEntityIdSuccess,
        (state, props): State => ({
            ...state,
            documentsForEntity: {
                ...state.documentsForEntity,
                [props.entityType]: {
                    ...state.documentsForEntity[props.entityType],
                    [props.entityId]: {
                        isLoadingList: false,
                        hasLoadingListError: false,
                        list: props.documents,
                    },
                },
            },
        })
    ),

    on(
        documentsActions.getDocumentsForEntityIdFailure,
        (state, props): State => ({
            ...state,
            documentsForEntity: {
                ...state.documentsForEntity,
                [props.entityType]: {
                    ...state.documentsForEntity[props.entityType],
                    [props.entityId]: {
                        ...state.documentsForEntity[props.entityType][
                            props.entityId
                        ],
                        isLoadingList: false,
                        hasLoadingListError: true,
                    },
                },
            },
        })
    ),

    on(
        documentsActions.dissociateDocument,
        (state, props): State => ({
            ...state,
            documents: {
                ...state.documents,
                [props.id]: {
                    ...(state.documents[props.id] ||
                        ({} as CrudState<Document>)),
                    isDeleting: true,
                    hasDeletingError: false,
                },
            },
        })
    ),

    on(
        documentsActions.dissociateDocumentSuccess,
        (state, props): State => ({
            ...state,
            documents: {
                ...state.documents,
                [props.id]: {
                    ...state.documents[props.id],
                    isDeleting: false,
                    hasDeletingError: false,
                },
            },
        })
    ),

    on(
        documentsActions.dissociateDocumentFailure,
        (state, props): State => ({
            ...state,
            documents: {
                ...state.documents,
                [props.id]: {
                    ...state.documents[props.id],
                    isDeleting: false,
                    hasDeletingError: true,
                },
            },
        })
    ),

    on(
        documentsActions.uploadDocument,
        (state, props): State => ({
            ...state,
            document: {
                ...state.document,
                [props.entityId]: {
                    ...(state.document[props.entityId] ||
                        ({} as FileUploadState)),
                    [props.id]: {
                        ...((state.document[props.entityId] &&
                            state.document[props.entityId][props.id]) ||
                            ({} as FileUploadState)),
                        uploadStatus: FileUploadStatus.Ready,
                        progress: 0,
                    },
                },
            },
        })
    ),

    on(
        documentsActions.uploadDocumentComplete,
        (state, props): State => ({
            ...state,
            document: {
                ...state.document,
                [props.entityId]: {
                    ...(state.document[props.entityId] ||
                        ({} as FileUploadState)),
                    [props.id]: {
                        ...((state.document[props.entityId] &&
                            state.document[props.entityId][props.id]) ||
                            ({} as FileUploadState)),
                        uploadStatus: FileUploadStatus.Completed,
                        fileIndex: props.fileIndex,
                    },
                },
            },
        })
    ),

    on(
        documentsActions.uploadDocumentFailure,
        (state, props): State => ({
            ...state,
            document: {
                ...state.document,
                [props.entityId]: {
                    ...state.document[props.entityId],
                    [props.id]: {
                        ...(state.document[props.entityId] &&
                            state.document[props.entityId][props.id]),
                        uploadStatus: FileUploadStatus.Failed,
                        error: props.error,
                    },
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
