import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxInputDateRangeDirective } from './wdx-input-date-range.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxInputDateRangeDirective],
    exports: [WdxInputDateRangeDirective],
})
export class WdxInputDateRangeModule {}
