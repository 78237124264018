import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'wdx-checkbox',
    templateUrl: './wdx-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WdxCheckboxComponent),
            multi: true,
        },
    ],
})
export class WdxCheckboxComponent implements OnInit, ControlValueAccessor {
    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    @Input() label = '';
    @Input() value?: string;
    @Input() invertedStyle?: boolean;

    @Input()
    get checked(): boolean {
        return this._checked;
    }
    set checked(value: boolean) {
        if (value !== this.checked) {
            this._checked = value;
            this.changeDetectorRef.markForCheck();
        }
    }
    private _checked = false;

    @Input()
    get indeterminate(): boolean {
        return this._indeterminate;
    }
    set indeterminate(value: boolean) {
        if (value !== this.indeterminate) {
            this._indeterminate = value;
            this.changeDetectorRef.markForCheck();
        }
    }
    private _indeterminate = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        if (value !== this.disabled) {
            this._disabled = value;
            this.changeDetectorRef.markForCheck();
        }
    }
    private _disabled = false;

    @Output() isChecked = new EventEmitter<boolean>();

    inputId?: string;
    onChange: (...args: any[]) => any = () => undefined;
    onTouch: (...args: any[]) => any = () => undefined;

    ngOnInit(): void {
        this.inputId = uuidv4();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: any) {
        this.checked = value || this.checked;
    }

    onToggle(event: Event) {
        event.stopPropagation();
        if (!this.disabled) {
            this.checked = !this.checked;
            this.onChange(this.checked);
            this.onTouch();
            this.isChecked.emit(this.checked);
        }
    }
}
