import { Component, Input } from '@angular/core';
import { Avatar } from '@wdx/clmi/utils/models';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-avatar-info-modal',
    templateUrl: './organism-avatar-info-modal.component.html',
})
export class OrganismAvatarInfoModalComponent {
    @Input() avatar: Avatar;
}
