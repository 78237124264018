import { createAction, props } from '@ngrx/store';
import { SystemEntityActionProps } from '../../models/system-entity-action-props.model';

export const assign = createAction(
    '[Queues] Assign',
    props<
        SystemEntityActionProps & {
            partyId: string;
        }
    >()
);

export const assignSuccess = createAction(
    '[Queues] Assign Success',
    props<SystemEntityActionProps>()
);

export const assignFailure = createAction(
    '[Queues] Assign Failure',
    props<
        SystemEntityActionProps & {
            error: Error;
        }
    >()
);
