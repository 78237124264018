<ng-container [ngSwitch]="type">
    <atom-icon-button
        *ngSwitchCase="'icon'"
        icon="plus"
        [size]="size"
        [disabled]="disabled"
    ></atom-icon-button>
    <button
        *ngSwitchCase="'button'"
        class="btn"
        [attr.data-cy]="cySelector"
        [disabled]="disabled"
        (click)="onClicked($event)"
    >
        <i class="fal fa-plus me-1"></i><ng-content></ng-content>
    </button>
</ng-container>
