import { createSelector } from '@ngrx/store';
import * as clientProductsReducer from './client-products.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    clientProducts: clientProductsReducer.State;
}): clientProductsReducer.State => state.clientProducts;

/**
 * Reducer property selectors
 */
export const getIsLoadingListForPartyRole = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.clientProductsForPartyRole[props.id]?.isLoadingList
);
export const getHasLoadingListErrorForPartyRole = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.clientProductsForPartyRole[props.id]?.hasLoadingListError
);
export const getListForPartyRole = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.clientProductsForPartyRole[props.id]?.list
);
export const getIsLoadingSingleForPartyRole = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.clientProductsForPartyRole[props.id]?.isLoadingSingle
);
export const getHasLoadingSingleErrorForPartyRole = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.clientProductsForPartyRole[props.id]?.hasLoadingSingleError
);
export const getIsDeletingForPartyRole = (id: string) =>
    createSelector(
        getState,
        (state: clientProductsReducer.State) =>
            state.clientProductsForPartyRole[id]?.isDeleting
    );
export const getHasDeletingErrorForPartyRole = (id: string) =>
    createSelector(
        getState,
        (state: clientProductsReducer.State) =>
            state.clientProductsForPartyRole[id]?.hasDeletingError
    );
export const getSingleForPartyRole = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.clientProductsForPartyRole[props.id]?.single
);

export const getIsLoadingListForParty = (partyId: string) =>
    createSelector(
        getState,
        (state: clientProductsReducer.State) =>
            state.clientProductsForParty[partyId]?.isLoadingList
    );
export const getHasLoadingListErrorForParty = (partyId: string) =>
    createSelector(
        getState,
        (state: clientProductsReducer.State) =>
            state.clientProductsForParty[partyId]?.hasLoadingListError
    );
export const getListForParty = (partyId) =>
    createSelector(
        getState,
        (state: clientProductsReducer.State) =>
            state.clientProductsForParty[partyId]?.list
    );

export const getIsLoadingCasesForId = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.cases[props.id]?.isLoadingList
);
export const getHasLoadingCasesForIdError = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.cases[props.id]?.hasLoadingListError
);
export const getCasesForId = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.cases[props.id]?.list
);

/**
 * Derived selectors
 */
export const getIsLoadingListForPartyRoleIds = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        props.ids.some(
            (id) => state.clientProductsForPartyRole[id]?.isLoadingList
        )
);

export const getHasLoadingListErrorForPartyRoleIds = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        props.ids.some(
            (id) => state.clientProductsForPartyRole[id]?.hasLoadingListError
        )
);

export const getListForPartyRoleIds = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        props.ids.reduce(
            (accumulator, id) => ({
                ...accumulator,
                [id]: state.clientProductsForPartyRole[id]?.list || [],
            }),
            {}
        )
);

export const getVisibilityForIdIsLoadingList = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.visibility[props.id]?.isLoadingList
);

export const getVisibilityForIdHasLoadingListError = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.visibility[props.id]?.hasLoadingListError
);

export const getVisibilityForId = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.visibility[props.id]?.list
);

export const getMembersForIdIsLoadingList = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.members[props.id]?.isLoadingList
);

export const getMembersForIdHasLoadingListError = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.members[props.id]?.hasLoadingListError
);

export const getMembersForId = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.members[props.id]?.list
);

export const getKeyStatsForIdIsLoadingList = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.keyStats[props.id]?.isLoadingList
);

export const getKeyStatsForIdHasLoadingListError = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.keyStats[props.id]?.hasLoadingListError
);

export const getKeyStatsForId = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.keyStats[props.id]?.list
);

export const getOperationForIdIsLoadingSingle = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.operations[props.id]?.isLoadingList
);
export const getOperationForIdHasLoadingSingleError = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.operations[props.id]?.hasLoadingListError
);

export const getOperationForId = createSelector(
    getState,
    (state: clientProductsReducer.State, props: any) =>
        state.operations[props.id]?.single
);

export const getOperationsLinksForId = (props: any) =>
    createSelector(
        getState,
        (state: clientProductsReducer.State) =>
            state.operations[props.id]?.single?.links
    );

export const getOperationsActionsForId = (props: any) =>
    createSelector(
        getState,
        (state: clientProductsReducer.State) =>
            state.operations[props.id]?.single?.actions
    );
