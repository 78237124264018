import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WdxRadioTileGroupComponent } from './wdx-radio-tile-group.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WdxRadioTileGroupComponent],
    exports: [WdxRadioTileGroupComponent],
})
export class WdxRadioTileGroupModule {}
