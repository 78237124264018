import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeatureSvg, size } from './wdx-feature-svg.interface';

@Component({
    selector: 'wdx-feature-svg',
    templateUrl: './wdx-feature-svg.component.html',
    styleUrls: ['./wdx-feature-svg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxFeatureSvgComponent {
    @Input() svg: FeatureSvg = FeatureSvg.NoData;
    @Input() size: size = 'sm';
    @Input() label = '';

    readonly FEATURE_SVG = FeatureSvg;
}
