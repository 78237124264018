import { SystemEntity } from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import {
    GLIA_FORM_ID,
    LIST_FORM_ID,
    MEETING_FORM_ID,
    MenuItemAction,
    NavItem,
    PHONECALL_FORM_ID,
    TRANSLATION_ACTION_DOCUMENTTYPE_DEACTIVATE,
    UTILS_ICON_AUDIT,
    UTILS_ICON_CROSS,
} from '@wdx/shared/utils';
import { BaseMenuItemClass } from '../classes/base-menu-item.class';
import {
    ICON_ADD,
    ICON_ASSIGN,
    ICON_ASSIGN_TO_ME,
    ICON_CABINET_FILING_LIGHT,
    ICON_CANCEL,
    ICON_CHECK,
    ICON_CONTEXT_MENU,
    ICON_DELETE,
    ICON_EDIT,
    ICON_EXTERNAL_LINK,
    ICON_EYE,
    ICON_FILE,
    ICON_FILE_EXPORT,
    ICON_GENERATE_DOCUMENT,
    ICON_KANBAN,
    ICON_LIST,
    ICON_MEETING,
    ICON_MERGE_PARTY,
    ICON_MESSAGE,
    ICON_PERSON_BASE,
    ICON_PHONECALL,
    ICON_PREVIEW,
    ICON_REMOVE,
    ICON_SAVE,
    ICON_SELECT,
    ICON_SEND_INVITE,
    ICON_SUCCESS,
    ICON_UNLINK,
    ICON_USER_PLUS,
    ICON_VISIBILITY,
} from './icons.constants';

export const ADD_TO_LIST_MENU_ITEM: NavItem = {
    label: 'Add to List',
    translationKey: 'MENU_ADDTOLIST',
    cySelector: 'option-add-to-list',
    icon: ICON_USER_PLUS.icon,
    value: MenuItemAction.AddToList,
    feature: FeatureFlag.Lists,
};

export const APPROVE_MENU_ITEM: NavItem = {
    label: 'Approve',
    translationKey: 'MENU_APPROVE',
    cySelector: 'option-approve',
    icon: ICON_CHECK.icon,
    value: MenuItemAction.Approve,
};

export const ASSIGN_TO_MENU_ITEM: NavItem = {
    label: 'Assign to...',
    translationKey: 'MENU_ASSIGNTO',
    value: MenuItemAction.AssignTo,
    cySelector: 'option-assignTo',
    icon: ICON_ASSIGN.icon,
};

export const ASSIGN_TO_ME_MENU_ITEM: NavItem = {
    label: 'Assign to Me',
    translationKey: 'MENU_ASSIGNTOME',
    value: MenuItemAction.AssignToMe,
    cySelector: 'option-assign-to-me',
    icon: ICON_ASSIGN_TO_ME.icon,
};

export const CANCEL_MENU_ITEM: NavItem = {
    label: 'Cancel',
    translationKey: 'MENU_CANCEL',
    value: MenuItemAction.Cancel,
    cySelector: 'option-cancel',
    icon: ICON_CANCEL.icon,
};

export class CHAT_MENU_ITEM extends BaseMenuItemClass {
    constructor(partyId?: string) {
        super({
            label: 'Chat',
            translationKey: 'MENU_CHAT',
            cySelector: 'option-chat',
            value: MenuItemAction.Chat,
            icon: ICON_MESSAGE.icon,
            formSetup: {
                formId: GLIA_FORM_ID,
                initialisationParams: { partyId },
            },
        });
    }
}

export const COMPLETE_CASE_MENU_ITEM: NavItem = {
    label: 'Complete Case',
    translationKey: 'MENU_COMPLETECASE',
    value: MenuItemAction.Complete,
    cySelector: 'option-camplete-case',
    icon: ICON_SUCCESS.icon,
};

export const CREATE_CASE_MENU_ITEM: NavItem = {
    label: 'Create Case',
    translationKey: 'MENU_CREATECASE',
    cySelector: 'option-createCase',
    value: MenuItemAction.CreateCase,
    icon: ICON_ADD.icon,
};

export class CREATE_LIST_MENU_ITEM extends BaseMenuItemClass {
    constructor(
        type: SystemEntity.Party | SystemEntity.PartyRole,
        partyId?: string
    ) {
        const PARAM = type === SystemEntity.Party ? 'partyId' : 'clientId';
        super({
            label: 'Create List',
            translationKey: 'MENU_CREATELIST',
            value: MenuItemAction.CreateList,
            icon: ICON_LIST.icon,
            formSetup: {
                formId: LIST_FORM_ID,
                initialisationParams: { [PARAM]: partyId },
            },
        });
    }
}

export const DELETE_MENU_ITEM: NavItem = {
    label: 'Delete',
    translationKey: 'MENU_DELETE',
    cySelector: 'option-delete',
    icon: ICON_DELETE.icon,
    value: MenuItemAction.Delete,
};

export const DOWNLOAD_MENU_ITEM: NavItem = {
    label: 'Download',
    translationKey: 'MENU_DOWNLOAD',
    value: MenuItemAction.Cancel,
    cySelector: 'option-download',
    icon: 'download',
};

export const VIEW_MENU_ITEM: NavItem = {
    label: 'View',
    translationKey: 'MENU_VIEW',
    cySelector: 'option-view',
    icon: ICON_EYE.icon,
};

export const EDIT_MENU_ITEM: NavItem = {
    label: 'Edit',
    translationKey: 'MENU_EDIT',
    cySelector: 'option-edit',
    icon: ICON_EDIT.icon,
};

export const HISTORY_MENU_ITEM: NavItem = {
    label: 'History',
    cySelector: 'option-history',
    icon: UTILS_ICON_AUDIT,
};

export const ENGAGEMENT_MENU_ITEM: NavItem = {
    label: 'Engagement Bar',
    translationKey: 'MENU_ENGAGEMENTBAR',
    cySelector: 'option-engagement-menu',
    icon: 'wand-magic-sparkles',
};

export const FILE_UNDER_MENU_ITEM: NavItem = {
    label: 'File Under',
    translationKey: 'MENU_FILEUNDER',
    cySelector: 'option-fileUnder',
    icon: ICON_CABINET_FILING_LIGHT.icon,
};

export const FILE_MENU_ITEM: NavItem = {
    label: 'File',
    translationKey: 'MENU_FILE',
    value: MenuItemAction.Cancel,
    cySelector: 'option-file',
    icon: ICON_FILE.icon,
};

export const FILE_DOCUMENT_ITEM: NavItem = {
    label: 'File Document',
    translationKey: 'MENU_FILEDOCUMENT',
    cySelector: 'option-fileDocument',
    icon: ICON_CABINET_FILING_LIGHT.icon,
};

export const GENERATE_DOCUMENT_MENU_ITEM: NavItem = {
    label: 'Generate Document',
    translationKey: 'MENU_GENERATEDOCUMENT',
    value: MenuItemAction.GenerateDocument,
    cySelector: 'option-generateDoc',
    icon: ICON_GENERATE_DOCUMENT.icon,
};

export const OPEN_IN_UNBLU: NavItem = {
    label: 'Open in Unblu',
    translationKey: 'MENU_OPENINUNBLU',
    cySelector: 'option-unblu',
    icon: ICON_EXTERNAL_LINK.icon,
};

export class MEETING_MENU_ITEM extends BaseMenuItemClass {
    constructor(partyId?: string, fileUnder = {}) {
        super({
            label: 'Meeting',
            translationKey: 'MENU_MEETING',
            cySelector: 'option-meeting',
            value: MenuItemAction.Meeting,
            icon: ICON_MEETING.icon,
            formSetup: {
                formId: MEETING_FORM_ID,
                initialisationParams: { partyId, ...fileUnder },
            },
        });
    }
}

export const MOVE_TO_STAGE_MENU_ITEM: NavItem = {
    label: 'Move to stage',
    translationKey: 'MENU_MOVETOSTAGE',
    cySelector: 'option-moveToStage',
    value: MenuItemAction.MoveToStage,
    icon: ICON_ASSIGN_TO_ME.icon,
};

export const OPEN_IN_NEW_TAB_MENU_ITEM: NavItem = {
    label: 'Open in New Tab',
    translationKey: 'MENU_OPENINNEWTAB',
    cySelector: 'option-open-new-tab',
    value: MenuItemAction.OpenInNewTab,
    icon: ICON_EXTERNAL_LINK.icon,
};

export class PHONECALL_MENU_ITEM extends BaseMenuItemClass {
    constructor(partyId?: string, fileUnder = {}) {
        super({
            label: 'Phone Call',
            translationKey: 'MENU_PHONECALL',
            cySelector: 'option-phonecall',
            value: MenuItemAction.PhoneCall,
            icon: ICON_PHONECALL.icon,
            formSetup: {
                formId: PHONECALL_FORM_ID,
                initialisationParams: { partyId, ...fileUnder },
            },
        });
    }
}

export const PREVIEW_MENU_ITEM: NavItem = {
    label: 'Preview',
    translationKey: 'MENU_PREVIEW',
    cySelector: 'option-preview',
    value: MenuItemAction.Preview,
    icon: ICON_PREVIEW.icon,
};

export const REJECT_MENU_ITEM: NavItem = {
    label: 'Reject',
    translationKey: 'MENU_REJECT',
    cySelector: 'option-reject',
    icon: ICON_CANCEL.icon,
    value: MenuItemAction.Reject,
};

export const REMOVE_FROM_LIST_MENU_ITEM: NavItem = {
    label: 'Remove from List',
    translationKey: 'MENU_REMOVEFROMLIST',
    cySelector: 'option-remove-from-list',
    icon: ICON_REMOVE.icon,
    value: MenuItemAction.RemoveFromList,
};

export const REMOVE_MENU_ITEM: NavItem = {
    label: 'Remove',
    translationKey: 'MENU_REMOVE',
    cySelector: 'option-unlink',
    value: MenuItemAction.UnLink,
    icon: ICON_UNLINK.icon,
};

export const REOPEN_MENU_ITEM: NavItem = {
    label: 'Reopen',
    translationKey: 'MENU_REOPEN',
    cySelector: 'option-reopen',
    icon: ICON_FILE.icon,
};

export const SAVE_MENU_ITEM: NavItem = {
    label: 'Save',
    translationKey: 'MENU_SAVE',
    cySelector: 'option-save',
    icon: ICON_SAVE.icon,
    value: MenuItemAction.Save,
};

export const SAVE_AS_MENU_ITEM: NavItem = {
    label: 'Save as',
    translationKey: 'MENU_SAVEAS',
    cySelector: 'option-save-as',
    icon: ICON_SAVE.icon,
    value: MenuItemAction.SaveAs,
};

export const SEE_MORE_MENU_ITEM: NavItem = {
    label: 'See more',
    translationKey: 'MENU_SEEMORE',
    cySelector: 'option-see-more',
    icon: ICON_CONTEXT_MENU.icon,
    value: MenuItemAction.SeeMore,
};

export const SEND_INVITE_MENU_ITEM: NavItem = {
    label: 'Send Invite',
    translationKey: 'MENU_SENDINVITE',
    cySelector: 'option-send-invite',
    icon: ICON_SEND_INVITE.icon,
    value: MenuItemAction.SendInvite,
};

export const SELECT_MENU_ITEM: NavItem = {
    label: 'Select',
    translationKey: 'MENU_SELECT',
    cySelector: 'option-select',
    icon: ICON_SELECT.icon,
    value: MenuItemAction.Select,
};

export const UNLINK_MENU_ITEM: NavItem = {
    label: 'Unlink',
    translationKey: 'MENU_UNLINK',
    cySelector: 'option-unlink',
    value: MenuItemAction.UnLink,
    icon: ICON_UNLINK.icon,
};

export const VIEW_CONTACT_MENU_ITEM: NavItem = {
    label: 'View contact',
    translationKey: 'MENU_VIEWCONTACT',
    cySelector: 'option-viewContact',
    value: MenuItemAction.ViewContact,
    icon: ICON_PERSON_BASE.icon,
};

export const VIEW_IN_KANBAN_MENU_ITEM: NavItem = {
    label: 'View in Kanban',
    translationKey: 'MENU_VIEWINKANBAN',
    value: MenuItemAction.ViewInKanban,
    cySelector: 'option-viewInKanban',
    icon: ICON_KANBAN.icon,
};

export const VISIBILITY_MENU_ITEM: NavItem = {
    label: 'Visibility',
    translationKey: 'MENU_VISIBILITY',
    value: MenuItemAction.Visibility,
    cySelector: 'option-visibility',
    icon: ICON_VISIBILITY.icon,
};

export const EXPORT_MENU_ITEM = {
    label: 'Export',
    translationKey: 'MENU_EXPORT',
    cySelector: 'export',
    icon: ICON_FILE_EXPORT.icon,
    value: MenuItemAction.ExportDetail,
};

export const MERGE_ORGANISATION_MENU_ITEM: NavItem = {
    label: 'Merge Organisation',
    value: MenuItemAction.MergeOrganisation,
    cySelector: 'option-mergeParty',
    icon: ICON_MERGE_PARTY.icon,
};

export const MERGE_PERSON_MENU_ITEM: NavItem = {
    label: 'Merge Person',
    value: MenuItemAction.MergePerson,
    cySelector: 'option-mergeParty',
    icon: ICON_MERGE_PARTY.icon,
};

export const DEACTIVATE_MENU_ITEM: NavItem = {
    label: 'Deactivate',
    translationKey: TRANSLATION_ACTION_DOCUMENTTYPE_DEACTIVATE,
    value: MenuItemAction.Deactivate,
    cySelector: 'option-mergeParty',
    icon: UTILS_ICON_CROSS,
};

export const CONTEXT_MENU_OPEN_IN_NEW_TAB = 'OPEN_IN_NEW_TAB';
export const CONTEXT_MENU_SELECT = 'SELECT';
export const CONTEXT_MENU_ASSIGN_TO = 'ASSIGN_TO';
export const CONTEXT_MENU_ASSIGN_TO_ME = 'ASSIGN_TO_ME';
export const CONTEXT_MENU_REPLAY = 'REPLAY';
export const CONTEXT_MENU_DELETE = 'DELETE';
