<molecule-action-button-dumb
    [actionButton]="actionButton"
    [btnClass]="btnClass"
    [size]="size"
    [parentContainer]="parentContainer"
    [hideDropdownChevron]="hideDropdownChevron"
    [dropdownButtonStyle]="dropdownButtonStyle"
    [dropdownPlacement]="dropdownPlacement"
    [dropdownMenuSize]="dropdownMenuSize"
    [dropdownAutoClose]="dropdownAutoClose"
    [dropdownTemplateId]="dropdownTemplateId"
    [dropdownMenu]="dropdownMenu$ | ngrxPush"
    [isDisabled]="isDisabled"
    [isVisible]="isVisible"
    [isLoading]="isLoading$ | ngrxPush"
    [resetButtonsFlag]="resetButtonsFlag"
    [highlightSelectedMenuItem]="highlightSelectedMenuItem"
    [attr.data-cy]="cySelector"
    (toggled)="onDropdownToggled($event)"
    (onClick)="onActionButtonClicked($event)"
    (fileSelected)="onFileSelected($event)"
    (selectMenuItem)="onMenuItemSelected($event)"
>
    <ng-content
        select=".action-button-add"
        ngProjectAs="action-button-add"
    ></ng-content>
    <ng-content
        select=".action-button-standard"
        ngProjectAs="action-button-standard"
    ></ng-content>
    <ng-content select=".action-button-dropdown-add"></ng-content>
</molecule-action-button-dumb>
