import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'wdx-audit-row',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './wdx-audit-row.component.html',
    styleUrls: ['./wdx-audit-row.component.scss'],
})
export class WdxAuditRowComponent {
    @Input() header = false;
    @Input() dataChanged = false;

    @HostBinding('class') class = 'd-block';
}
