import { Directive, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { DateTime } from 'luxon';
import { takeUntil } from 'rxjs/operators';
import { ADD_MINUTES_TIME } from './wdx-input-date-range.static';

@Directive({
    selector: '[wdxInputDateRange]',
})
export class WdxInputDateRangeDirective
    extends WdxDestroyClass
    implements OnInit
{
    constructor(private form: ControlContainer) {
        super();
    }

    @Input() wdxInputDateRange?: { hideEnd: boolean };

    ngOnInit(): void {
        const START_CONTROL = this.form.control?.get('start');

        /**
         * Watching it the user changes the start time and updates the end time
         */
        if (!this.wdxInputDateRange || !this.wdxInputDateRange?.hideEnd) {
            START_CONTROL?.valueChanges
                .pipe(takeUntil(this.destroyed$))
                .subscribe((date) => {
                    const START_TIME = DateTime.fromISO(date);
                    this.patchEndControl(START_TIME);
                });
        }
        if (!START_CONTROL?.value) {
            const NOW = this.calculateDifference();
            const DATE = NOW.toISO();

            START_CONTROL?.patchValue(DATE, {
                onlySelf: false,
                emitEvent: false,
            });

            if (!this.wdxInputDateRange || !this.wdxInputDateRange?.hideEnd) {
                this.patchEndControl(NOW);
            }
        }
    }

    /**
     * This takes the start time and adds 30(ADD_MINUTES_TIME) minutes
     *
     * @param endDate: Date string is used to update the angular form object
     */
    patchEndControl(startTime: DateTime): void {
        const NOW_END = startTime.plus({ minute: ADD_MINUTES_TIME });
        const END_DATE = NOW_END.toISO();

        this.form.control?.get('end')?.patchValue(END_DATE, {
            onlySelf: false,
            emitEvent: false,
        });
    }

    /**
     * This takes the current time and works out how long to the next half hour.
     * It takes the current time minutes and minus 30 (ADD_MINUTES_TIME). If the number
     * is positive, then it's before XX:30, if 0 or less it's XX:30 or after. Then it take 60 minus
     * the current number in minutes.
     *
     * The remainder is added to the current it to get the next whole half hour
     *
     * @returns Luxon DateTime
     */
    calculateDifference(): DateTime {
        const NOW = DateTime.now();
        let timeToStart = ADD_MINUTES_TIME - NOW.minute;
        let start = NOW;

        if (0 >= timeToStart) {
            timeToStart = 60 - NOW.minute;
        }

        start = NOW.plus({ minute: timeToStart }).set({
            second: 0,
            millisecond: 0,
        });

        return start;
    }
}
