import { createAction, props } from '@ngrx/store';

export const getEmailContentForActivity = createAction(
    '[Email] Get Email Content for Activity',
    props<{
        activityId: string;
    }>()
);

export const getEmailContentForActivitySuccess = createAction(
    '[Email] Get Email Content for Activity Success',
    props<{
        activityId: string;
        emailContent: string;
    }>()
);

export const getEmailContentForActivityFailure = createAction(
    '[Email] Get Email Content for Activity Failure',
    props<{
        activityId: string;
        error: Error;
    }>()
);
