import { createAction, props } from '@ngrx/store';
import { AmlHitUpdate, AmlReport, InvitationType } from '@wdx/clmi/api-models';

export const getAmlReportForAppStatus = createAction(
    '[Aml Reports] Get Aml Report For App Status',
    props<{
        appStatusId: string;
    }>()
);

export const getAmlReportForAppStatusSuccess = createAction(
    '[Aml Reports] Get Aml Report For App Status Success',
    props<{
        appStatusId: string;
        amlReport: AmlReport;
    }>()
);

export const getAmlReportForAppStatusFailure = createAction(
    '[Aml Reports] Get Aml Report For App Status Failure',
    props<{
        appStatusId: string;
        error: Error;
    }>()
);

export const runAmlReportForAppStatus = createAction(
    '[Aml Reports] Run Aml Report For App Status',
    props<{
        appStatusId: string;
        invitationType?: InvitationType;
    }>()
);

export const runAmlReportForAppStatusSuccess = createAction(
    '[Aml Reports] Run Aml Report For App Status Success',
    props<{
        appStatusId: string;
        amlReport: AmlReport;
    }>()
);

export const runAmlReportForAppStatusFailure = createAction(
    '[Aml Reports] Run Aml Report For App Status Failure',
    props<{
        appStatusId: string;
        error: Error;
    }>()
);

export const updateAmlHitForAppStatus = createAction(
    '[Aml Reports] Update Aml Hit For App Status',
    props<{
        amlHit: AmlHitUpdate;
    }>()
);

export const updateAmlHitForAppStatusSuccess = createAction(
    '[Aml Reports] Update Aml Hit For App Status Success',
    props<{
        amlHit: AmlHitUpdate;
    }>()
);

export const updateAmlHitForAppStatusFailure = createAction(
    '[Aml Reports] Update Aml Hit For App Status Failure',
    props<{
        amlHit: AmlHitUpdate;
        error: Error;
    }>()
);
