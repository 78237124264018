import { Action, createReducer, on } from '@ngrx/store';
import { DocumentRequirementType } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { GLOBAL_STATE_INDEX_ID } from '../../constants/state.constants';
import * as getDocumentRequirementTypesActions from './document-requirement-types.actions';

export interface State {
    documentRequirementTypes: CrudStateObject<DocumentRequirementType>;
    availableData: CrudStateObject<any>;
}

export const initialState: State = {
    documentRequirementTypes: {},
    availableData: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        getDocumentRequirementTypesActions.getAll,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentRequirementType>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.getAllSuccess,
        (state, props): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.documentRequirementTypes,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.getAllFailure,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.searchDocumentRequirementTypes,
        (state, props): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentRequirementType>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID]
                              ?.infinity,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.searchDocumentRequirementTypesSuccess,
        (state, props): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.documentRequirementTypes.paging,
                        combinedList: [
                            ...(state.documentRequirementTypes[
                                GLOBAL_STATE_INDEX_ID
                            ].infinity?.combinedList || []),
                            ...props.documentRequirementTypes.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.searchDocumentRequirementTypesFailure,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.activateDocumentRequirementType,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentRequirementType>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.activateDocumentRequirementTypeSuccess,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentRequirementType>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.activateDocumentRequirementTypeFailure,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentRequirementType>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.inactivateDocumentRequirementType,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentRequirementType>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.inactivateDocumentRequirementTypeSuccess,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentRequirementType>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        getDocumentRequirementTypesActions.inactivateDocumentRequirementTypeFailure,
        (state): State => ({
            ...state,
            documentRequirementTypes: {
                ...state.documentRequirementTypes,
                [GLOBAL_STATE_INDEX_ID]: {
                    ...(state.documentRequirementTypes[GLOBAL_STATE_INDEX_ID] ||
                        ({} as CrudState<DocumentRequirementType>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
