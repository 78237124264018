import { createSelector } from '@ngrx/store';
import * as dynamicFormsReducer from './dynamic-forms.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    [dynamicFormsReducer.FEATURE_KEY]: dynamicFormsReducer.State;
}): dynamicFormsReducer.State => state[dynamicFormsReducer.FEATURE_KEY];

export const getFormDefinitionsIsLoadingList = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formDefinitions?.[props.id]?.isLoadingList
);
export const getFormDefinitionsHasLoadingListError = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formDefinitions?.[props.id]?.hasLoadingListError
);
export const getFormDefinitions = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formDefinitions?.[props.id]?.list
);

export const getIsLoadingFormDefinition = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formDefinitions?.[props.id]?.isLoadingSingle
);
export const getHasLoadingFormDefinitionError = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formDefinitions?.[props.id]?.hasLoadingSingleError
);
export const getFormDefinition = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formDefinitions?.[props.id]?.single
);
export const getFormDefinitionCrudObject = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formDefinitions?.[props.id]
);

export const getIsLoadingFormData = createSelector(
    getState,
    (
        state: dynamicFormsReducer.State,
        props: { formId: string; entityId: string }
    ) =>
        state.formData?.[props.formId]
            ? (state.formData[props.formId] as any)[props.entityId]
                  .isLoadingSingle
            : false
);
export const getHasLoadingFormDataError = createSelector(
    getState,
    (
        state: dynamicFormsReducer.State,
        props: { formId: string; entityId: string }
    ) =>
        state.formData?.[props.formId]
            ? (state.formData[props.formId] as any)?.[props.entityId]
                  ?.hasLoadingSingleError
            : false
);
export const getFormData = createSelector(
    getState,
    (
        state: dynamicFormsReducer.State,
        props: { formId: string; entityId: string }
    ) =>
        state.formData?.[props.formId]
            ? (state.formData[props.formId] as any)?.[props.entityId]?.single
            : undefined
);

export const getIsLoadingFormFunctionResult = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formFunctionResults?.[props.id]?.isLoadingSingle
);
export const getHasLoadingFormFunctionResultError = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formFunctionResults?.[props.id]?.hasLoadingSingleError
);
export const getFormFunctionResult = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formFunctionResults?.[props.id]?.single
);

export const getFormTemplatesIsLoadingList = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formTemplates?.[props.id]?.isLoadingList
);
export const getFormTemplatesHasLoadingListError = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formTemplates?.[props.id]?.hasLoadingListError
);
export const getFormTemplates = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formTemplates?.[props.id]?.list
);

export const getIsLoadingFormTemplateInstance = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formTemplateInstances?.[props.id]?.isLoadingSingle
);
export const getHasLoadingFormTemplateInstanceError = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formTemplateInstances?.[props.id]?.hasLoadingSingleError
);
export const getFormTemplateInstance = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formTemplateInstances?.[props.id]?.single
);

export const getIsCreatingFormData = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formData?.[props.id]?.isCreating
);
export const getHasCreatingFormDataError = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formData?.[props.id]?.hasCreatingError
);

export const getIsUpdatingFormData = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formData?.[props.id]?.isUpdating
);
export const getHasUpdatingFormDataError = createSelector(
    getState,
    (state: dynamicFormsReducer.State, props: any) =>
        state.formData?.[props.id]?.hasUpdatingError
);
