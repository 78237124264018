import { Injectable, OnDestroy } from '@angular/core';

import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { Config, LocalStorageService } from '@wdx/shared/utils';
import { LOCAL_STORAGE_TOKEN } from '../constants/auth0.constants';
import { Auth0ServiceE2e } from './authentication0.e2e.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationServiceE2e implements OnDestroy {
    config: Config = null;
    isAuthenticated$: Observable<boolean> = null;
    auth0Service: Auth0ServiceE2e;

    // Create subject and public observable of user profile data
    private userProfileSubject$ = new BehaviorSubject<any>(null);
    userProfile$ = this.userProfileSubject$.asObservable();

    // Create subject and public observable of user profile data
    private authenticatingSubject$ = new BehaviorSubject<boolean>(null);
    isAuthenticating$ = this.authenticatingSubject$.asObservable();

    // Create a local property for login status
    loggedIn: boolean = null;

    notifyTimeoutModal$ = new Subject<number>();
    destroyed$ = new Subject<boolean>();
    interval: NodeJS.Timeout;

    constructor(private localStorageService: LocalStorageService) {}

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    login() {
        // Take user to Auth0 to authenticate
        this.auth0Service.isAuthenticated$;
    }

    setSessionExpiryTime(): void {
        this.localStorageService.setStringKey(
            LOCAL_STORAGE_TOKEN,
            this.getToken()
        );
    }

    isAuthenticated(): boolean {
        return Boolean(this.getToken());
    }

    logout() {
        this.localStorageService.setStringKey(LOCAL_STORAGE_TOKEN, '');
        // Ensure Auth0 client instance exists
    }

    getTokenSilently$(
        options?
    ): Observable<string | GetTokenSilentlyVerboseResponse> {
        return of(options ? this.getToken() : this.getToken());
    }

    isLoggedIn() {
        return this.loggedIn;
    }

    initSessionCountDown() {
        this.localStorageService.getStringKey(LOCAL_STORAGE_TOKEN);
    }

    getToken(): string {
        return this.localStorageService.getStringKey(LOCAL_STORAGE_TOKEN);
    }

    getAppState$() {
        return of({ returnToUrl: '' });
    }
}
