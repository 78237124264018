<organism-cards-list
    [cardType]="cardType"
    [searchType]="searchType"
    [searchTypePlural]="searchTypePlural | translate"
    [opportunityCards]="
        opportunities
            | fn
                : opportunitiesAsOpportunityCardItems
                : linkToEntityType
                : linkToEntityId
                : isSuggestedCardList
                : showDeleteLinkMenuItem
                : partyId
                : limit
    "
    [totalRecordCount]="totalRecordCount"
    [cardsPerRow]="opportunitiesPerRow"
    [filterHandler]="filterHandler"
    [filtersModalId]="filtersModalId"
    [formFilterName]="OPPORTUNITY_FILTER_FORM"
    [sortMenu]="SORT_MENU_OPPORTUNITIES"
    [useSortToggleMenu]="true"
    [showFilters]="showFilters"
    [showFilterBar]="showFilterBar"
    [showSearchBar]="showSearchBar"
    [showFavourite]="showFavourite"
    [showNoDataSVG]="showNoDataSVG"
    [noDataSVGSize]="noDataSVGSize"
    [noDataMessage]="noDataMessage"
    [isLoading]="isLoading"
    [isSuggestedCardList]="isSuggestedCardList"
    [limit]="limit"
    [scrollMode]="scrollMode"
    data-cy="opportunities-list"
    (search)="onSearch($event)"
    (infinityScrollFired)="onInfinityScrollFired()"
    (toggleFavourite)="onToggleFavourite($event)"
>
    <ng-content prefixActionButtons select="[prefixActionButtons]"></ng-content>
    <div class="organism-cards-list-action-button d-flex">
        <molecule-action-button
            class="ms-2"
            size="md"
            btnClass="btn-primary"
            [actionButton]="
                partyId | fn : opportunityCreateActionButton : partyRoleId
            "
        >
        </molecule-action-button>
    </div>
</organism-cards-list>
