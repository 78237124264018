import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organisation, OrganisationCreate } from '@wdx/clmi/api-models';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class OrganisationsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getForParty(partyId: string): Observable<Organisation> {
        return this.http.get<Organisation>(
            `${this.config.getConfiguration().API_BASE}/organisation/${partyId}`
        );
    }

    create(
        prospectCreateData: OrganisationCreate
    ): Observable<OrganisationCreate> {
        return this.http.post<OrganisationCreate>(
            `${this.config.getConfiguration().API_BASE}/organisation`,
            prospectCreateData
        );
    }
}
