import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { WdxCounterComponent } from './wdx-counter.component';

@NgModule({
    imports: [CommonModule, WdxSpinnerModule],
    declarations: [WdxCounterComponent],
    exports: [WdxCounterComponent],
})
export class WdxCounterModule {}
