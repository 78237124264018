import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class OutcomesService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    setOutcomeForWorkItem(
        outcomeId: string,
        workItemId: string,
        comment?: string
    ): Observable<any> {
        const COMMENT = comment ? { comment } : {};
        return this.http.patch<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/workitem/${workItemId}/outcome/${outcomeId}`,
            COMMENT
        );
    }
}
