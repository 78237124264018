import { Injectable } from '@angular/core';
import { SystemEntity } from '../../models/shared-api-models';
import { EnumTranslationTypes } from './translations.constants';

export type Translations = Record<string, string>;
export type DisableTranslationType = 'key' | 'asterisk' | undefined;
export const DISABLE_TRANSLATIONS_QUERY_PARAM = 'disableTranslations';

@Injectable({
    providedIn: 'root',
})
export class TranslationsService {
    private translations!: Translations;

    private disableTranslations: 'key' | 'asterisk' | undefined;

    /**
     * Sets the translation data from api (primarily from app guard)
     */
    set(
        translations: Translations,
        disableTranslations?: DisableTranslationType
    ) {
        this.disableTranslations = disableTranslations;
        this.translations = translations;
    }

    /**
     * Returns the entire translations object
     */
    get() {
        return this.translations;
    }

    /**
     * Returns a specific translation by key
     * @param key The translation key
     * @param replacerValue Replacer value if the key contains a pattern
     */
    getTranslationByKey(key: string, replacerValue?: string): string {
        if (this.disableTranslations) {
            switch (this.disableTranslations) {
                case 'key':
                    return key;
                    break;
                case 'asterisk':
                    return '****';
                    break;
                default:
                    break;
            }
        }
        const label = this.translations ? this.translations[key] : key;
        return replacerValue ? label.replace(/{value}/g, replacerValue) : label;
    }

    /**
     * Returns the tranlsation of a given entity type
     * @param entityType
     * @param plural
     */

    getTranslatedEntityType(entityType: SystemEntity, plural = false) {
        const translationKey = plural
            ? `ENTITY_${entityType?.toUpperCase()}_PLURAL`
            : `ENTITY_${entityType?.toUpperCase()}_SINGULAR`;

        return translationKey
            ? this.getTranslationByKey(translationKey)
            : entityType;
    }

    /**
     * Returns the tranlsation of a given entity type
     * @param value
     * @param type
     */

    getTranslatedEnumType(value: string, type: EnumTranslationTypes) {
        const translationKey = `ENUM_${type}_${value?.toUpperCase()}`;

        return translationKey
            ? this.getTranslationByKey(translationKey)
            : value;
    }

    translateTokenisedString(
        value: string,
        params?: { [key: string]: string }
    ): string {
        if (!value || this.disableTranslations === 'key') {
            return value;
        }

        // handle no tokens embedded in value - eiter this is a key only or it's text that does not need translating
        if (!/{(.*?)}/.test(value)) {
            // expects keys to be only uppercase, numbers or underscores only (if this changes update below regex)
            const translatedValue = /^([A-Z_\d])*$/.test(value)
                ? this.getTranslationByKey(value) || value
                : value;

            return params && /{(.*?)}/.test(translatedValue)
                ? this.replaceTokensInTranslation(params, translatedValue)
                : translatedValue;
        }

        const translatedString = value.replace(
            /{(.*?)}/g,
            (match, translationKey) =>
                this.getTranslationByKey(translationKey) || match
        );

        return params
            ? this.replaceTokensInTranslation(params, translatedString)
            : translatedString;
    }

    private replaceTokensInTranslation(
        params: { [key: string]: string },
        translatedString: string
    ): string {
        // Replace tokens with provided parameters
        Object.keys(params).forEach((key) => {
            translatedString = translatedString.replace(
                `{${key}}`,
                params[key]
            );
        });

        return translatedString;
    }
}
