<div *ngIf="activityCard" class="card d-flex flex-column">
    <div class="d-flex align-items-center px-2 pt-2">
        <wdx-icon
            class="flex-shrink-0 me-1"
            [scale]="1"
            [icon]="ACTIVITY_ICON_MAP[activityCard?.activityType]"
        ></wdx-icon>
        <molecule-info-card
            class="flex-grow-1 text-truncate"
            [primaryInfo]="activityCard.primaryInfo"
            [routerLinkData]="activityCard.routingLink?.routerLink"
        ></molecule-info-card>
    </div>
    <div class="text-truncate sub-3 mb-2 px-2">
        {{ activityCard.content }} &nbsp;
    </div>
    <div class="d-flex mb-2 px-2">
        &nbsp;
        <div class="sub-3 me-1">{{ activityCard.dueDate | displayDate }}</div>
        <div class="sub-3">
            {{ activityCard.dueDate | displayDate : { timeOnly: true } }}
        </div>
    </div>

    <molecule-card-footer class="d-block mt-auto">
        <div class="card-footer-left">
            <molecule-avatar-group
                size="sm"
                [avatars]="activityCard.avatars"
            ></molecule-avatar-group>
        </div>
        <div class="card-footer-center d-flex">
            <wdx-icon class="me-1" icon="paperclip" [scale]="1"></wdx-icon>
            <span class="overline">{{
                activityCard.attachments ? activityCard.attachments : 0
            }}</span>
        </div>
        <div class="card-footer-right d-flex align-items-center">
            <atom-highlight-text
                class="sub-small"
                shade="100"
                [highlight]="
                    activityCard.filedUnder?.length
                        ? HIGHLIGHT.Success
                        : HIGHLIGHT.Danger
                "
                >{{
                    activityCard.filedUnder?.length ? 'Filed' : 'Not Filed'
                }}</atom-highlight-text
            >
        </div>
    </molecule-card-footer>
</div>
