import { createSelector } from '@ngrx/store';
import * as tagsReducer from './tags.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    tags: tagsReducer.State;
}): tagsReducer.State => state.tags;

/**
 * Reducer property selectors
 */
export const getIsLoadingList = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.tags[props.id]?.isLoadingList
);
export const getHasLoadingListError = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.tags[props.id]?.hasLoadingListError
);
export const getList = createSelector(
    getState,
    (state: tagsReducer.State, props: any) => state.tags[props.id]?.list
);
export const getIsCreating = createSelector(
    getState,
    (state: tagsReducer.State, props: any) => state.tags[props.id]?.isCreating
);
export const getHasCreatingError = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.tags[props.id]?.hasCreatingError
);
export const getIsDeleting = createSelector(
    getState,
    (state: tagsReducer.State, props: any) => state.tags[props.id]?.isDeleting
);
export const getHasDeletingError = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.tags[props.id]?.hasDeletingError
);
export const getCategoriesForSystemEntityIsLoadingList = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.categories[props.systemEntity]?.isLoadingList
);
export const getCategoriesForSystemEntityHasLoadingListError = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.categories[props.systemEntity]?.hasLoadingListError
);
export const getCategoriesForSystemEntityList = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.categories[props.systemEntity]?.list
);

export const getCategoriesMenu = createSelector(
    getState,
    (state: tagsReducer.State) => state.categoriesMenu?.list
);

export const getAdmin = createSelector(
    getState,
    (state: tagsReducer.State) => state.admin?.list
);

export const getAllTagsIsLoading = createSelector(
    getState,
    (state: tagsReducer.State) => state.all?.isLoadingList
);

/**
 * Derived selectors
 */
export const getIsLoadingListForIds = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        props.ids.some((id) => state.tags[id]?.isLoadingList)
);

export const getHasLoadingListErrorForIds = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        props.ids.some((id) => state.tags[id]?.hasLoadingListError)
);

export const getListForIds = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        props.ids.reduce(
            (accumulator, id) => ({
                ...accumulator,
                [id]: state.tags[id]?.list,
            }),
            {}
        )
);

export const getIsCreatingForRecord = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.tags[`${props.entity}-${props.id}`]?.isCreating
);
export const getHasCreatingForRecordError = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.tags[`${props.entity}-${props.id}`]?.hasCreatingError
);
export const getIsDeletingForRecord = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.tags[`${props.entity}-${props.id}`]?.isDeleting
);
export const getHasDeletingForRecordError = createSelector(
    getState,
    (state: tagsReducer.State, props: any) =>
        state.tags[`${props.entity}-${props.id}`]?.hasDeletingError
);
