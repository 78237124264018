<i
    class="
        {{ iconStyle }}
        fa-{{ icon }}
    "
    data-cy="wdx-icon"
    [class.icon-color]="iconColour"
    [class.fa-fw]="fixedWidth"
    [style.top.%]="offsetTop"
    [style.left.%]="offsetLeft"
    [style.font-size.em]="scale"
    [style.transform]="rotate ? 'rotate(' + rotate + 'deg)' : ''"
    [class.text-success]="severity === 'success'"
    [class.text-warning]="severity === 'warning'"
    [class.text-danger]="severity === 'danger'"
    [class.text-info]="severity === 'info'"
    [class.text-default]="!severity"
></i>
