<wdx-ff-form
    *ngIf="!isLoadingData; else loadingTmpl"
    [formId]="formId"
    [attr.data-cy]="formId"
    [entityId]="entityId"
    [appId]="appId"
    [formDefinition]="formDefinition"
    [formLayout]="formLayout"
    [formData]="formData"
    [isQuickCreate]="isQuickCreate"
    [modalInstanceId]="modalInstanceId"
    [isLoading]="isLoadingData"
    [hasError]="
        $any(
            (formPresentationService.formDefinitionHasError$ | ngrxPush) ||
                (formDataHasError$ | ngrxPush)
        )
    "
    [isSaving]="$any(formDataIsSaving$ | ngrxPush)"
    [stylingOptions]="formStylingOptions"
    [readonlyMode]="readonlyMode"
    [auditMode]="auditMode"
    [triggerSave]="triggerSave"
    (formTitleReceived)="onFormTitleReceived($event)"
    (cancelClicked)="onCancelClick()"
    (saveClicked)="onSaveClick($event)"
    (saveDraftClicked)="onSaveDraftClicked($event)"
    (completionSummaryChanged)="onCompletionSummaryChanged($event)"
    (formEvent)="formEventHandler($event)"
></wdx-ff-form>

<ng-template #loadingTmpl>
    <div
        class="d-flex h-100 align-items-center justify-content-center text-center"
    >
        <wdx-spinner></wdx-spinner>
    </div>
</ng-template>
