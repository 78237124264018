import { createAction, props } from '@ngrx/store';
import { AppStatus } from '@wdx/clmi/api-models';

export const getAppStatus = createAction(
    '[Apps] Get App Status',
    props<{
        appId: string;
    }>()
);

export const getAppStatusSuccess = createAction(
    '[Apps] Get App Status Success',
    props<{
        appId: string;
        app: AppStatus;
    }>()
);

export const getAppStatusFailure = createAction(
    '[Apps] Get App Status Failure',
    props<{
        appId: string;
        error: Error;
    }>()
);

export const confirm = createAction(
    '[Apps] Confirm',
    props<{
        appId: string;
    }>()
);

export const confirmSuccess = createAction(
    '[Apps] Confirm Success',
    props<{
        appId: string;
    }>()
);

export const confirmFailure = createAction(
    '[Apps] Confirm Failure',
    props<{
        appId: string;
        error: Error;
    }>()
);
