import { Action, createReducer, on } from '@ngrx/store';
import {
    CashMovement,
    CashSummary,
    Performance,
    SecurityPosition,
    ValuationHistory,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import { KeyValuePoint } from '../../models/investment-data.model';
import * as investmentDataActions from './investment-data.actions';

export interface State {
    valuationHistory?: CrudStateObject<ValuationHistory>;
    performance?: CrudStateObject<Performance>;
    assetAllocationByCountry?: CrudStateObject<KeyValuePoint>;
    assetAllocationByGeographic?: CrudStateObject<KeyValuePoint>;
    assetAllocationBySector?: CrudStateObject<KeyValuePoint>;
    assetAllocationByAssetClass?: CrudStateObject<KeyValuePoint>;
    movements?: CrudStateObject<CashMovement>;
    cashSummaries?: CrudStateObject<CashSummary>;
    topMovers?: CrudStateObject<SecurityPosition>;
}

export const initialState: State = {
    valuationHistory: {},
    performance: {},
    assetAllocationByCountry: {},
    assetAllocationByGeographic: {},
    assetAllocationBySector: {},
    assetAllocationByAssetClass: {},
    movements: {},
    cashSummaries: {},
    topMovers: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        investmentDataActions.getValuationHistoryForClientProduct,
        (state, props): State => ({
            ...state,
            valuationHistory: {
                ...state.valuationHistory,
                [props.clientProductId]: {
                    ...(state.valuationHistory[props.clientProductId] ||
                        ({} as CrudState<ValuationHistory>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        investmentDataActions.getValuationHistoryForClientProductSuccess,
        (state, props): State => ({
            ...state,
            valuationHistory: {
                ...state.valuationHistory,
                [props.clientProductId]: {
                    ...state.valuationHistory[props.clientProductId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.valuationHistory,
                },
            },
        })
    ),

    on(
        investmentDataActions.getValuationHistoryForClientProductFailure,
        (state, props): State => ({
            ...state,
            valuationHistory: {
                ...state.valuationHistory,
                [props.clientProductId]: {
                    ...state.valuationHistory[props.clientProductId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        investmentDataActions.getPerformanceForClientProduct,
        (state, props): State => ({
            ...state,
            performance: {
                ...state.performance,
                [props.clientProductId]: {
                    ...(state.performance[props.clientProductId] ||
                        ({} as CrudState<Performance>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        investmentDataActions.getPerformanceForClientProductSuccess,
        (state, props): State => ({
            ...state,
            performance: {
                ...state.performance,
                [props.clientProductId]: {
                    ...state.performance[props.clientProductId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.performance,
                },
            },
        })
    ),

    on(
        investmentDataActions.getPerformanceForClientProductFailure,
        (state, props): State => ({
            ...state,
            performance: {
                ...state.performance,
                [props.clientProductId]: {
                    ...state.performance[props.clientProductId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationByCountryForClientProduct,
        (state, props): State => ({
            ...state,
            assetAllocationByCountry: {
                ...state.assetAllocationByCountry,
                [props.clientProductId]: {
                    ...(state.assetAllocationByCountry[props.clientProductId] ||
                        ({} as CrudState<KeyValuePoint>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationByCountryForClientProductSuccess,
        (state, props): State => ({
            ...state,
            assetAllocationByCountry: {
                ...state.assetAllocationByCountry,
                [props.clientProductId]: {
                    ...state.assetAllocationByCountry[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.allocation,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationByCountryForClientProductFailure,
        (state, props): State => ({
            ...state,
            assetAllocationByCountry: {
                ...state.assetAllocationByCountry,
                [props.clientProductId]: {
                    ...state.assetAllocationByCountry[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationByGeographicForClientProduct,
        (state, props): State => ({
            ...state,
            assetAllocationByGeographic: {
                ...state.assetAllocationByGeographic,
                [props.clientProductId]: {
                    ...(state.assetAllocationByGeographic[
                        props.clientProductId
                    ] || ({} as CrudState<KeyValuePoint>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationByGeographicForClientProductSuccess,
        (state, props): State => ({
            ...state,
            assetAllocationByGeographic: {
                ...state.assetAllocationByGeographic,
                [props.clientProductId]: {
                    ...state.assetAllocationByGeographic[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.allocation,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationByGeographicForClientProductFailure,
        (state, props): State => ({
            ...state,
            assetAllocationByGeographic: {
                ...state.assetAllocationByGeographic,
                [props.clientProductId]: {
                    ...state.assetAllocationByGeographic[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationBySectorForClientProduct,
        (state, props): State => ({
            ...state,
            assetAllocationBySector: {
                ...state.assetAllocationBySector,
                [props.clientProductId]: {
                    ...(state.assetAllocationBySector[props.clientProductId] ||
                        ({} as CrudState<KeyValuePoint>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationBySectorForClientProductSuccess,
        (state, props): State => ({
            ...state,
            assetAllocationBySector: {
                ...state.assetAllocationBySector,
                [props.clientProductId]: {
                    ...state.assetAllocationBySector[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.allocation,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationBySectorForClientProductFailure,
        (state, props): State => ({
            ...state,
            assetAllocationBySector: {
                ...state.assetAllocationBySector,
                [props.clientProductId]: {
                    ...state.assetAllocationBySector[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationByAssetClassForClientProduct,
        (state, props): State => ({
            ...state,
            assetAllocationByAssetClass: {
                ...state.assetAllocationByAssetClass,
                [props.clientProductId]: {
                    ...(state.assetAllocationByAssetClass[
                        props.clientProductId
                    ] || ({} as CrudState<KeyValuePoint>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationByAssetClassForClientProductSuccess,
        (state, props): State => ({
            ...state,
            assetAllocationByAssetClass: {
                ...state.assetAllocationByAssetClass,
                [props.clientProductId]: {
                    ...state.assetAllocationByAssetClass[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.allocation,
                },
            },
        })
    ),

    on(
        investmentDataActions.getAssetAllocationByAssetClassForClientProductFailure,
        (state, props): State => ({
            ...state,
            assetAllocationByAssetClass: {
                ...state.assetAllocationByAssetClass,
                [props.clientProductId]: {
                    ...state.assetAllocationByAssetClass[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        investmentDataActions.getMovements,
        (state, props): State => ({
            ...state,
            movements: {
                ...state.movements,
                [props.valuationEntityId]: {
                    ...(state.movements[props.valuationEntityId] ||
                        ({} as CrudState<CashMovement>)),
                    isLoadingPage: true,
                    hasLoadingPageError: false,
                    infinity: props.reset
                        ? undefined
                        : state.movements[props.valuationEntityId]?.infinity,
                },
            },
        })
    ),

    on(
        investmentDataActions.getMovementsSuccess,
        (state, props): State => ({
            ...state,
            movements: {
                ...state.movements,
                [props.valuationEntityId]: {
                    ...state.movements[props.valuationEntityId],
                    isLoadingPage: false,
                    hasLoadingPageError: false,
                    infinity: {
                        paging: props.movements.paging,
                        combinedList: [
                            ...(state.movements[props.valuationEntityId]
                                .infinity?.combinedList || []),
                            ...props.movements.results,
                        ],
                    },
                },
            },
        })
    ),

    on(
        investmentDataActions.getMovementsFailure,
        (state, props): State => ({
            ...state,
            movements: {
                ...state.movements,
                [props.valuationEntityId]: {
                    ...state.movements[props.valuationEntityId],
                    isLoadingPage: false,
                    hasLoadingPageError: true,
                },
            },
        })
    ),

    on(
        investmentDataActions.getCashSummary,
        (state, props): State => ({
            ...state,
            cashSummaries: {
                ...state.cashSummaries,
                [props.valuationEntityId]: {
                    ...(state.cashSummaries[props.valuationEntityId] ||
                        ({} as CrudState<CashSummary>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        investmentDataActions.getCashSummarySuccess,
        (state, props): State => ({
            ...state,
            cashSummaries: {
                ...state.cashSummaries,
                [props.valuationEntityId]: {
                    ...state.cashSummaries[props.valuationEntityId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.cashSummaries,
                },
            },
        })
    ),

    on(
        investmentDataActions.getCashSummaryFailure,
        (state, props): State => ({
            ...state,
            cashSummaries: {
                ...state.cashSummaries,
                [props.valuationEntityId]: {
                    ...state.cashSummaries[props.valuationEntityId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        investmentDataActions.getTopMovers,
        (state, props): State => ({
            ...state,
            topMovers: {
                ...state.topMovers,
                [props.clientProductId]: {
                    ...state.topMovers[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        investmentDataActions.getTopMoversSuccess,
        (state, props): State => ({
            ...state,
            topMovers: {
                ...state.topMovers,
                [props.clientProductId]: {
                    ...state.topMovers[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.movements,
                },
            },
        })
    ),

    on(
        investmentDataActions.getTopMoversFailure,
        (state, props): State => ({
            ...state,
            topMovers: {
                ...state.topMovers,
                [props.clientProductId]: {
                    ...state.topMovers[props.clientProductId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                    list: null,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
