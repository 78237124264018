import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { userSelectors } from '@wdx/clmi/api-services/services';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Privilege } from '../../../models/privilege.model';
import * as rootReducer from '../../../state/_setup/reducers';

@Injectable({
    providedIn: 'root',
})
export class PrivilegesService extends WdxDestroyClass {
    constructor(private store$: Store<rootReducer.State>) {
        super();
    }

    hasPrivilege$(...privilege: Privilege[]): Observable<boolean> {
        return this.store$.select(userSelectors.getPrivilegesSelector).pipe(
            takeUntil(this.destroyed$),
            filter((privileges) => !!privileges),
            map((privileges) => {
                if (privileges.includes(Privilege.Administrator)) {
                    return true;
                }
                return privilege.some((priv) => privileges.includes(priv));
            })
        );
    }
}
