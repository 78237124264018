import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityOperations, SystemEntity } from '@wdx/clmi/api-models';
import { Observable, map, tap, withLatestFrom } from 'rxjs';
import * as operationsActions from './operations.actions';
import { State } from './operations.reducer';
import * as operationsSelectors from './operations.selectors';

@Injectable({
    providedIn: 'root',
})
export class OperationsFacade {
    store$ = inject(Store<State>);

    getOperationsForIdIsLoading$(
        entityType: SystemEntity,
        entityId: string
    ): Observable<boolean | undefined> {
        return this.store$.select(
            operationsSelectors.operationsForIdIsLoadingSingle,
            { entityType, entityId }
        );
    }

    getOperationsForIdHasError$(
        entityType: SystemEntity,
        entityId: string
    ): Observable<boolean | undefined> {
        return this.store$.select(
            operationsSelectors.operationsForIdHasLoadingSingleError,
            { entityType, entityId }
        );
    }

    getOperationsForId$(
        entityType: SystemEntity,
        entityId: string
    ): Observable<EntityOperations | undefined> {
        return this.store$
            .select(operationsSelectors.getOperationsForId, {
                entityType,
                entityId,
            })
            .pipe(
                withLatestFrom(
                    this.getOperationsForIdIsLoading$(entityType, entityId),
                    this.getOperationsForIdHasError$(entityType, entityId)
                ),
                tap(([operations, isLoading, hasError]) => {
                    if (!operations && !isLoading && !hasError) {
                        this.store$.dispatch(
                            operationsActions.getOperationsForId({
                                entityType,
                                entityId,
                            })
                        );
                    }
                }),
                map(([operations]) => operations)
            );
    }

    getOperationsForEntity$(
        entityType: SystemEntity
    ): Observable<EntityOperations | undefined> {
        return this.store$
            .select(operationsSelectors.getOperationsForEntity, {
                entityType,
            })
            .pipe(
                tap((operation) => !operation && this.loadOperation(entityType))
            );
    }

    getOperationsForEntityIsLoading$(
        entityType: SystemEntity
    ): Observable<boolean | undefined> {
        return this.store$.select(
            operationsSelectors.operationsForEntityIsLoadingSingle,
            { entityType }
        );
    }

    getOperationsHasError$(
        entityType: SystemEntity
    ): Observable<boolean | undefined> {
        return this.store$.select(
            operationsSelectors.operationsForEntityHasLoadingSingleError,
            { entityType }
        );
    }
    loadOperation(entityType: SystemEntity): void {
        this.store$.dispatch(
            operationsActions.getOperationsForEntity({
                entityType,
            })
        );
    }
}
