import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AvatarInfoService } from '@wdx/clmi/api-services/services';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as avatarInfoActions from './avatar-info.actions';

@Injectable()
export class AvatarInfoEffects {
    private actions$ = inject(Actions);
    private avatarInfoService = inject(AvatarInfoService);

    getForEntityId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(avatarInfoActions.getForEntityId),
            mergeMap((action) =>
                this.avatarInfoService
                    .getForEntityId(action.entityType, action.entityId)
                    .pipe(
                        map((avatarInfo) =>
                            avatarInfoActions.getForEntityIdSuccess({
                                entityType: action.entityType,
                                entityId: action.entityId,
                                avatarInfo,
                            })
                        ),
                        catchError((error) =>
                            of(
                                avatarInfoActions.getForEntityIdFailure({
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
