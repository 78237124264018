import { Action, createReducer, on } from '@ngrx/store';
import { SelectFieldResult } from '@wdx/clmi/api-models';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as organisationClientTypesActions from './organisation-client-types.actions';

export type State = CrudState<SelectFieldResult>;

export const initialState: State = {};

const reducerSetup = createReducer(
    initialState,

    on(
        organisationClientTypesActions.getAll,
        (state): State => ({
            ...state,
            isLoadingList: true,
            hasLoadingListError: false,
        })
    ),

    on(
        organisationClientTypesActions.getAllSuccess,
        (state, props): State => ({
            ...state,
            isLoadingList: false,
            list: props.organisationClientTypes,
        })
    ),

    on(
        organisationClientTypesActions.getAllFailure,
        (state): State => ({
            ...state,
            isLoadingList: false,
            hasLoadingListError: true,
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
