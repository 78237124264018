import { createAction, props } from '@ngrx/store';
import { FormDataResult } from '@wdx/shared/utils';

export const loadFormHistoryInstance = createAction(
    '[Form History Instance] Load Form History Instance',
    props<{ formId: string; entityId: string; instanceId: string }>()
);
export const loadFormHistoryInstanceSuccess = createAction(
    '[Form History Instance] Load Form History Instance Success',
    props<{
        formId: string;
        entityId: string;
        instanceId: string;
        formHistoryInstance: FormDataResult;
    }>()
);
export const loadFormHistoryInstanceFailure = createAction(
    '[Form History Instance] Load Form History Instance Failure',
    props<{
        formId: string;
        entityId: string;
        instanceId: string;
        error: Error;
    }>()
);
