import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { WdxIconButtonComponent } from './wdx-icon-button.component';

@NgModule({
    imports: [CommonModule, WdxIconContainerModule],
    declarations: [WdxIconButtonComponent],
    exports: [WdxIconButtonComponent],
})
export class WdxIconButtonModule {}
