import { Action, createReducer, on } from '@ngrx/store';
import { FileUploadState } from '../../models/file-upload-state.model';
import { FileUploadStatus } from '../../models/file-upload-status.model';
import * as filesActions from './files.actions';

export const FEATURE_KEY = 'files';

export type FileStoreSlice = {
    [FEATURE_KEY]: State;
};

export interface State {
    uploads: { [key: string]: FileUploadState };
}

export const initialState: State = {
    uploads: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        filesActions.uploadFile,
        (state, props): State => ({
            ...state,
            uploads: {
                ...state.uploads,
                [props.id]: {
                    ...(state.uploads[props.id] || ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Ready,
                    progress: 0,
                },
            },
        })
    ),

    on(
        filesActions.uploadFileCancel,
        (state, props): State => ({
            ...state,
            uploads: {
                ...state.uploads,
                [props.id]: {
                    ...(state.uploads[props.id] || ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Ready,
                    progress: 0,
                },
            },
        })
    ),

    on(
        filesActions.uploadFileReset,
        (state, props): State => ({
            ...state,
            uploads: {
                ...state.uploads,
                [props.id]: {
                    ...(state.uploads[props.id] || ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Ready,
                    progress: 0,
                    fileIndex: undefined,
                },
            },
        })
    ),

    on(
        filesActions.uploadFileStarted,
        (state, props): State => ({
            ...state,
            uploads: {
                ...state.uploads,
                [props.id]: {
                    ...(state.uploads[props.id] || ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Started,
                },
            },
        })
    ),

    on(
        filesActions.uploadFileProgress,
        (state, props): State => ({
            ...state,
            uploads: {
                ...state.uploads,
                [props.id]: {
                    ...(state.uploads[props.id] || ({} as FileUploadState)),
                    progress: props.progress,
                },
            },
        })
    ),

    on(
        filesActions.uploadFileComplete,
        (state, props): State => ({
            ...state,
            uploads: {
                ...state.uploads,
                [props.id]: {
                    ...(state.uploads[props.id] || ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Completed,
                    progress: 100,
                    fileIndex: props.fileIndex,
                },
            },
        })
    ),

    on(
        filesActions.uploadFileFailure,
        (state, props): State => ({
            ...state,
            uploads: {
                ...state.uploads,
                [props.id]: {
                    ...(state.uploads[props.id] || ({} as FileUploadState)),
                    uploadStatus: FileUploadStatus.Failed,
                    error: props.error,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
