<wdx-spinner
    *ngIf="!(hasLoadedChart$ | ngrxPush)"
    class="absolute-center"
></wdx-spinner>

<section class="h-100 d-flex flex-column overflow-hidden">
    <section class="align-self-end {{ iconButtonClass }} mb-1">
        <atom-icon-button
            size="sm"
            icon="expand-arrows-alt"
            (onClick)="openModalFullScreen()"
        ></atom-icon-button>
    </section>

    <powerbi-report
        class="w-100 flex-grow-1"
        *ngIf="hasLoadedChart$ | ngrxPush"
        [cssClassName]="reportClass"
        [embedConfig]="reportConfig$ | ngrxPush"
        [phasedEmbedding]="phasedEmbeddingFlag"
    ></powerbi-report>
</section>

<atom-modal-wrapper
    size="full"
    modalId="powerBiModal"
    [modalTemplate]="powerBiModal"
>
    <ng-template #powerBiModal>
        <organism-modal modalTitle="Portfolio 360">
            <powerbi-report
                class="w-100 h-100"
                *ngIf="hasLoadedChart$ | ngrxPush"
                [cssClassName]="reportClass"
                [embedConfig]="reportConfig$ | ngrxPush"
                [phasedEmbedding]="phasedEmbeddingFlag"
            ></powerbi-report>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
