import { Action, createReducer, on } from '@ngrx/store';
import {
    AppLanguage,
    FormDataHistory,
    Locale,
    User,
    UserKpi,
} from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as userActions from './user.actions';

export interface State {
    me?: CrudState<User>;
    meLocale?: CrudState<Locale>;
    users?: CrudStateObject<User>;
    history?: CrudStateObject<FormDataHistory>;
    kpi?: CrudStateObject<UserKpi>;
    appLanguage?: CrudState<AppLanguage>;
}

export const initialState: State = {
    me: {},
    meLocale: undefined,
    users: {},
    history: {},
    kpi: {},
    appLanguage: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        userActions.getMeAction,
        (state): State => ({
            ...state,
            me: {
                ...state.me,
                isLoadingSingle: true,
                hasLoadingSingleError: false,
            },
        })
    ),

    on(
        userActions.getMeSuccessAction,
        (state, props): State => ({
            ...state,
            me: {
                ...state.me,
                isLoadingSingle: false,
                hasLoadingSingleError: false,
                single: props.user,
            },
        })
    ),

    on(
        userActions.getMeFailureAction,
        (state): State => ({
            ...state,
            me: {
                ...state.me,
                isLoadingSingle: false,
                hasLoadingSingleError: true,
            },
        })
    ),

    on(
        userActions.getMeLocaleAction,
        (state): State => ({
            ...state,
            meLocale: {
                ...state.meLocale,
                isLoadingSingle: true,
                hasLoadingSingleError: false,
            },
        })
    ),

    on(
        userActions.getMeLocaleSuccessAction,
        (state, props): State => ({
            ...state,
            meLocale: {
                ...state.meLocale,
                isLoadingSingle: false,
                hasLoadingSingleError: false,
                single: props.meLocale,
            },
        })
    ),

    on(
        userActions.getMeLocaleFailureAction,
        (state): State => ({
            ...state,
            meLocale: {
                ...state.meLocale,
                isLoadingSingle: false,
                hasLoadingSingleError: true,
            },
        })
    ),

    on(
        userActions.getForIdAction,
        (state, props): State => ({
            ...state,
            users: {
                ...state.users,
                [props.userId]: {
                    ...(state.users?.[props.userId] || ({} as CrudState<User>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        userActions.getForIdSuccessAction,
        (state, props): State => ({
            ...state,
            users: {
                ...state.users,
                [props.user.id as string]: {
                    ...state.users?.[props.user.id as string],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.user,
                },
            },
        })
    ),

    on(
        userActions.getForIdFailureAction,
        (state, props): State => ({
            ...state,
            users: {
                ...state.users,
                [props.userId]: {
                    ...state.users?.[props.userId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        userActions.getHistoryForIdAction,
        (state, props): State => ({
            ...state,
            history: {
                ...state.history,
                [props.userId]: {
                    ...(state.history?.[props.userId] ||
                        ({} as CrudState<FormDataHistory>)),
                    isLoadingList: true,
                    hasLoadingListError: false,
                },
            },
        })
    ),

    on(
        userActions.getHistoryForIdSuccessAction,
        (state, props): State => ({
            ...state,
            history: {
                ...state.history,
                [props.userId]: {
                    ...state.history?.[props.userId],
                    isLoadingList: false,
                    hasLoadingListError: false,
                    list: props.history,
                },
            },
        })
    ),

    on(
        userActions.getHistoryForIdFailureAction,
        (state, props): State => ({
            ...state,
            history: {
                ...state.history,
                [props.userId]: {
                    ...state.history?.[props.userId],
                    isLoadingList: false,
                    hasLoadingListError: true,
                },
            },
        })
    ),

    on(
        userActions.getKPIsForIdAction,
        (state, props): State => ({
            ...state,
            kpi: {
                ...state.kpi,
                [props.userId]: {
                    ...(state.kpi?.[props.userId] ||
                        ({} as CrudState<UserKpi>)),
                    isLoadingSingle: true,
                    hasLoadingSingleError: false,
                },
            },
        })
    ),

    on(
        userActions.getKPIsForIdSuccessAction,
        (state, props): State => ({
            ...state,
            kpi: {
                ...state.kpi,
                [props.userId]: {
                    ...state.kpi?.[props.userId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: false,
                    single: props.kpi,
                },
            },
        })
    ),

    on(
        userActions.getKPIsForIdFailureAction,
        (state, props): State => ({
            ...state,
            kpi: {
                ...state.kpi,
                [props.userId]: {
                    ...state.kpi?.[props.userId],
                    isLoadingSingle: false,
                    hasLoadingSingleError: true,
                },
            },
        })
    ),

    on(
        userActions.changeAppLanguageAction,
        (state): State => ({
            ...state,
            appLanguage: {
                ...state.appLanguage,
                isLoadingList: true,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        userActions.changeAppLanguageSuccessAction,
        (state): State => ({
            ...state,
            appLanguage: {
                ...state.appLanguage,
                isLoadingList: false,
                hasLoadingListError: false,
            },
        })
    ),

    on(
        userActions.changeAppLanguageFailureAction,
        (state): State => ({
            ...state,
            appLanguage: {
                ...state.appLanguage,
                isLoadingList: false,
                hasLoadingListError: true,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
