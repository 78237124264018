import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/api-models';
import { RandomStringPipe } from '../../../../pipes/random-string.pipe';
import { ModalManagementService } from '../../../../services/modal-management.service';

@Component({
    selector: 'clmi-comments-modal',
    templateUrl: './comments-modal.component.html',
    styleUrls: ['./comments-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsModalComponent {
    modalId: string;
    set modalTitle(title: string) {
        this._modalTitle = title;
    }
    get modalTitle(): string {
        return this._modalTitle ? `${this._modalTitle} Comments` : 'Comments';
    }
    _modalTitle: string;
    entityId: string;
    entityType: SystemEntity = SystemEntity.Activity;

    constructor(private modalManagementService: ModalManagementService) {
        this.modalId = new RandomStringPipe().transform();
    }

    open(): void {
        this.modalManagementService.openModalWithId(this.modalId);
    }

    close(): void {
        this.modalManagementService.closeActiveModal();
    }
}
