import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import {
    Activity,
    EntityPermissionType,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { dynamicFormsActions } from '@wdx/shared/infrastructure/form-framework';
import {
    MEETING_FORM_ID,
    PHONECALL_FORM_ID,
    TASK_FORM_ID,
} from '@wdx/shared/utils';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    ICON_MEETING,
    ICON_PHONECALL,
    ICON_TASK,
} from '../../../../constants/icons.constants';
import { ActionButton } from '../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../models/action-buttons-mode.model';
import { ScrollMode } from '../../../../models/scroll-mode.model';
import { RelatedRecordsFacadeService } from '../related-records-facade.service';

@Component({
    selector: 'clmi-related-activities',
    templateUrl: './related-activities.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedActivitiesComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input() entityId: string;
    @Input() entityType: SystemEntity;
    @Input() cardsPerRow: number;
    @Input() isInactiveRecord: boolean;

    relatedActivities$: Observable<Activity[]>;
    relatedActivitiesIsLoading$: Observable<boolean>;
    relatedActivitiesHasError$: Observable<boolean>;

    destroyed$ = new Subject<boolean>();

    createActivityActionButton: ActionButton;

    readonly SCROLL_MODE = ScrollMode;

    constructor(
        private relatedRecordsFacadeService: RelatedRecordsFacadeService,
        private actionsSubject$: ActionsSubject
    ) {}

    ngOnInit(): void {
        this.actionsSubject$
            .pipe(
                ofType(
                    ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions
                ),
                takeUntil(this.destroyed$)
            )
            .subscribe((action: any) => {
                if (
                    [MEETING_FORM_ID, PHONECALL_FORM_ID, TASK_FORM_ID].includes(
                        action.formId
                    )
                ) {
                    const props = {
                        entityId: this.entityId,
                        entityType: this.entityType,
                    };

                    this.relatedRecordsFacadeService.loadRelatedActivities(
                        props
                    );
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const entityId = this.entityId ?? changes.entityId.currentValue;
        const entityType = this.entityType ?? changes.entityType.currentValue;

        if (entityId && entityType) {
            const props = {
                entityId,
                entityType,
            };

            this.relatedActivities$ =
                this.relatedRecordsFacadeService.getRelatedActivities$(props);

            this.relatedActivitiesIsLoading$ =
                this.relatedRecordsFacadeService.getRelatedActivitesIsLoading$(
                    props
                );

            this.relatedActivitiesHasError$ =
                this.relatedRecordsFacadeService.getRelatedActivitiesHasError$(
                    props
                );

            this.createActivityActionButton = {
                mode: ActionButtonMode.DropdownButtonIcon,
                cySelector: 'action-add-activities',
                icon: 'plus',
                operationsSetup: props,
                ...(!this.isInactiveRecord
                    ? { permission: EntityPermissionType.Edit }
                    : {}),
                dropdownMenu: [
                    {
                        label: 'Phone Call',
                        value: 'phonecall',
                        translationKey: 'MENU_PHONECALL',
                        formSetup: {
                            formId: PHONECALL_FORM_ID,
                            initialisationParams: {
                                activityId: props.entityId,
                            },
                        },
                        icon: ICON_PHONECALL.icon,
                    },
                    {
                        label: 'Meeting',
                        value: 'meeting',
                        translationKey: 'MENU_MEETING',
                        formSetup: {
                            formId: MEETING_FORM_ID,
                            initialisationParams: {
                                activityId: props.entityId,
                            },
                        },
                        icon: ICON_MEETING.icon,
                    },
                    {
                        label: 'Task',
                        value: 'task',
                        formSetup: {
                            formId: TASK_FORM_ID,
                            initialisationParams: { activityId: entityId },
                        },
                        icon: ICON_TASK.icon,
                    },
                ],
            };
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
