import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityChat } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { Observable } from 'rxjs';
import { SearchActivityChat } from '../../models/chat-message.model';
import { PaginationOptions } from '../../models/pagination-options';
import { ApiPaginationService } from '../../services/api-pagination.service';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class ChatsService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private apiPaginationService: ApiPaginationService
    ) {}

    getForActivity(
        activityId: string,
        paginationOptions: PaginationOptions
    ): Observable<PaginatedApiResponse<ActivityChat>> {
        return this.http.get<PaginatedApiResponse<ActivityChat>>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/chat`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
            }
        );
    }

    searchChat(
        activityId: string,
        paginationOptions: PaginationOptions,
        search: string
    ): Observable<PaginatedApiResponse<SearchActivityChat>> {
        return this.http.get<PaginatedApiResponse<SearchActivityChat>>(
            `${
                this.config.getConfiguration().API_BASE
            }/activity/${activityId}/chat/search`,
            {
                headers:
                    this.apiPaginationService.mapPaginationOptions(
                        paginationOptions
                    ),
                params: {
                    search,
                },
            }
        );
    }
}
