import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FormSummaryHistoricService {
    formUuid!: string | null;
    loadHistoricData!: boolean | null;

    resetData(): void {
        this.formUuid = null;
        this.loadHistoricData = null;
    }
}
