import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MailingInstruction, SystemEntity } from '@wdx/clmi/api-models';
import { MailingInstructionsApiService } from '@wdx/clmi/api-services/services';
import { dynamicFormsActions } from '@wdx/shared/infrastructure/form-framework';
import { MAILING_INSTRUCTION_ID } from '@wdx/shared/utils';
import { Observable, catchError, filter, map, of, switchMap } from 'rxjs';
import * as mailingInstructionReducers from '../state/mailing-instructions.reducer';
import * as mailingInstructionSelectors from '../state/mailing-instructions.selectors';
import * as mailingInstructionActions from './mailing-instructions.actions';

@Injectable()
export class MailingInstructionsEffects {
    private actions$ = inject(Actions);
    private featureStore$ = inject(Store<mailingInstructionReducers.State>);
    private mailingInstructionsApiService = inject(
        MailingInstructionsApiService
    );

    getForElementId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                mailingInstructionActions.getForEntityId,
                mailingInstructionActions.generateForClientIdSuccess
            ),
            switchMap((action) => {
                let apiFunction: (
                    id: string
                ) => Observable<MailingInstruction[]>;
                switch (action.entityType) {
                    case SystemEntity.Client:
                        apiFunction =
                            this.mailingInstructionsApiService.getForClientId.bind(
                                this.mailingInstructionsApiService
                            );
                        break;
                    case SystemEntity.Party:
                        apiFunction =
                            this.mailingInstructionsApiService.getForPartyId.bind(
                                this.mailingInstructionsApiService
                            );
                        break;
                    case SystemEntity.PartyRole:
                        apiFunction =
                            this.mailingInstructionsApiService.getForPartyRoleId.bind(
                                this.mailingInstructionsApiService
                            );
                        break;
                    default:
                        break;
                }
                return apiFunction(action.entityId).pipe(
                    map((mailingInstructions) =>
                        mailingInstructionActions.getForEntityIdSuccess({
                            entityType: SystemEntity.Client,
                            entityId: action.entityId,
                            mailingInstructions,
                        })
                    ),
                    catchError((error) =>
                        of(
                            mailingInstructionActions.getForEntityIdFailure({
                                entityType: SystemEntity.Client,
                                entityId: action.entityId,
                                error,
                            })
                        )
                    )
                );
            })
        )
    );

    generateMailingInstructions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mailingInstructionActions.generateForClientId),
            switchMap(({ clientId }) =>
                this.mailingInstructionsApiService
                    .generateForClientId(clientId)
                    .pipe(
                        map((mailingInstructions) => ({
                            mailingInstructions,
                            clientId,
                        }))
                    )
            ),
            map(({ clientId }) => {
                return mailingInstructionActions.generateForClientIdSuccess({
                    entityType: SystemEntity.Client,
                    entityId: clientId,
                });
            }),
            catchError((error) =>
                of(
                    mailingInstructionActions.generateForClientIdFailure({
                        error,
                    })
                )
            )
        )
    );

    updateMailingInstructionsStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mailingInstructionActions.updateMailingInstructionsStatus),
            switchMap(({ ids, status }) =>
                this.mailingInstructionsApiService.bulkUpdateMailingInstructionStatus(
                    ids,
                    status
                )
            ),
            map((mailingInstructions) => {
                return mailingInstructionActions.updateMailingInstructionsStatusSuccess(
                    {
                        mailingInstructions,
                    }
                );
            }),
            catchError((error) =>
                of(
                    mailingInstructionActions.updateMailingInstructionsStatusFailure(
                        {
                            error,
                        }
                    )
                )
            )
        )
    );

    duplicateMailingInstructions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mailingInstructionActions.duplicateMailingInstructions),
            switchMap((action) => {
                return this.mailingInstructionsApiService
                    .cloneMailingInstructions(action.ids)
                    .pipe(
                        map((mailingInstructions) => {
                            return mailingInstructionActions.duplicateMailingInstructionSuccess(
                                { mailingInstructions }
                            );
                        }),
                        catchError((error) =>
                            of(
                                mailingInstructionActions.duplicateMailingInstructionFailure(
                                    {
                                        error,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    refreshMailingInstructions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.updateStandardFormDataSuccess),
            filter((action) => action.formId === MAILING_INSTRUCTION_ID),
            concatLatestFrom(() =>
                this.featureStore$.select(
                    mailingInstructionSelectors.selectMailingInstructionsState
                )
            ),
            map(([, { entityId, entityType }]) => {
                return mailingInstructionActions.getForEntityId({
                    entityId,
                    entityType,
                });
            })
        )
    );
}
