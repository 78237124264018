import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { Observable } from 'rxjs';
import { PartyRoleRelationship } from '@wdx/clmi/api-models';

@Injectable()
export class PersonalConnectionsService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getForParty(partyId: string): Observable<PartyRoleRelationship[]> {
        return this.http.get<PartyRoleRelationship[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/party/${partyId}/personalconnections`
        );
    }
}
