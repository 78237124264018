import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as goalTypesActions from './goal-types.actions';
import { GoalTypesService } from './goal-types.service';

@Injectable()
export class GoalTypesEffects {
    private actions$ = inject(Actions);
    private goalTypesService = inject(GoalTypesService);

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(goalTypesActions.getAll),
            switchMap(() =>
                this.goalTypesService.getAll().pipe(
                    map((goalTypes) =>
                        goalTypesActions.getAllSuccess({ goalTypes })
                    ),
                    catchError((error) =>
                        of(goalTypesActions.getAllFailure({ error }))
                    )
                )
            )
        )
    );
}
