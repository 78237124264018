import { Pipe, PipeTransform } from '@angular/core';
import {
    UTILS_ICON_DOCUMENT,
    UTILS_ICON_FILE_CODE,
    UTILS_ICON_FILE_EXCEL,
    UTILS_ICON_FILE_CSV,
    UTILS_ICON_FILE_PDF,
    UTILS_ICON_FILE_WORD,
    UTILS_ICON_FILE_IMAGE,
    UTILS_ICON_FILE_POWERPOINT,
} from '../../constants';
import { FileType, MIMETypeToFileFormat } from '../../models';

@Pipe({ name: 'fileTypeAsIcon', standalone: true })
export class FileTypeAsIconPipe implements PipeTransform {
    transform(
        contentOrFileType: string,
        isFileType = false
    ): string | { fileType: string; icon: string } {
        if (!contentOrFileType) {
            return UTILS_ICON_DOCUMENT;
        }

        let fileType = '';
        if (isFileType) {
            fileType = contentOrFileType;
        } else {
            Object.keys(MIMETypeToFileFormat).forEach((key) => {
                if (key === contentOrFileType?.toLowerCase()) {
                    fileType = (MIMETypeToFileFormat as Record<string, string>)[
                        key
                    ];
                }
            });
        }

        switch (fileType?.toLowerCase()) {
            case FileType.csv:
                return { fileType: 'csv', icon: UTILS_ICON_FILE_CSV };
            case FileType.excel:
                return { fileType: 'excel', icon: UTILS_ICON_FILE_EXCEL };
            case FileType.html:
                return { fileType: 'html', icon: UTILS_ICON_FILE_CODE };
            case FileType.xml:
                return { fileType: 'xml', icon: UTILS_ICON_FILE_CODE };
            case FileType.image:
                return { fileType: 'image', icon: UTILS_ICON_FILE_IMAGE };
            case FileType.json:
                return { fileType: 'json', icon: UTILS_ICON_FILE_CODE };
            case FileType.pdf:
                return { fileType: 'pdf', icon: UTILS_ICON_FILE_PDF };
            case FileType.powerpoint:
                return {
                    fileType: 'powerpoint',
                    icon: UTILS_ICON_FILE_POWERPOINT,
                };
            case FileType.word:
                return { fileType: 'word', icon: UTILS_ICON_FILE_WORD };
            default:
                return { fileType: '', icon: UTILS_ICON_DOCUMENT };
        }
    }
}
