import { Injectable } from '@angular/core';
import { AssignToEvent, BulkAssignToEvent } from '../../models/assign.model';
import * as assignActions from '../../state/assign/assign.actions';
import * as rootReducer from '../../state/_setup/reducers';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class AssignFacadeService {
    constructor(private store$: Store<rootReducer.State>) {}

    assignToEvent(assignToEvent: AssignToEvent): void {
        this.store$.dispatch(assignActions.assignTo(assignToEvent));
    }

    bulkAssignToEvent(bulkAssignToEvent: BulkAssignToEvent): void {
        this.store$.dispatch(assignActions.bulkAssignTo(bulkAssignToEvent));
    }
}
