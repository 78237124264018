import { createSelector } from '@ngrx/store';
import { OpportunityContextualTypes } from '../../models/opportunities.model';
import * as opportunitiesReducer from './opportunities.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    opportunities: opportunitiesReducer.State;
}): opportunitiesReducer.State => state.opportunities;

/**
 * Reducer property selectors
 */
export const getIsUpdating = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id].isUpdating
);
export const getHasUpdatingError = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id].isUpdating
);
export const getIsCreating = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id].isCreating
);
export const getHasCreatingError = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id].isCreating
);
export const getIsLoadingSingle = (opportunityId: string) =>
    createSelector(
        getState,
        (state: opportunitiesReducer.State) =>
            state.opportunities[opportunityId]?.isLoadingSingle
    );
export const getHasLoadingSingleError = (opportunityId: string) =>
    createSelector(
        getState,
        (state: opportunitiesReducer.State) =>
            state.opportunities[opportunityId]?.hasLoadingSingleError
    );
export const getSingle = (opportunityId: string) =>
    createSelector(
        getState,
        (state: opportunitiesReducer.State) =>
            state.opportunities[opportunityId]?.single
    );

export const getActiveKanbanStage = createSelector(
    getState,
    (state: opportunitiesReducer.State) => state.activeKanbanStage
);

export const getActiveMoveToStage = createSelector(
    getState,
    (state: opportunitiesReducer.State) => state.activeMoveToStage
);

export const getPipelineSummary = createSelector(
    getState,
    (state: opportunitiesReducer.State) => state.pipelineSummary.single
);

export const getPipelineSummaryIsLoading = createSelector(
    getState,
    (state: opportunitiesReducer.State) => state.pipelineSummary.isLoadingSingle
);

export const getPipelineSummaryHasError = createSelector(
    getState,
    (state: opportunitiesReducer.State) =>
        state.pipelineSummary.hasLoadingSingleError
);

export const getIsLoadingPage = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id]?.isLoadingPage
);

export const getHasLoadingPageError = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id]?.hasLoadingPageError
);

export const getInfinityPaging = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id]?.infinity?.paging
);

export const getInfinityCombinedList = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id]?.infinity?.combinedList
);

export const getAssignedTeamMembersForOpps = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id]?.list
);
export const getAssignedTeamMembersForOppsIsLoadingPage = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id]?.isLoadingPage
);
export const getAssignedTeamMembersForOppsHasLoadingPageError = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.opportunities[props.id]?.hasLoadingPageError
);

export const getVisibilityForIdIsLoadingList = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.visibility[props.id]?.isLoadingList
);
export const getVisibilityForIdHasLoadingListError = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.visibility[props.id]?.hasLoadingListError
);
export const getVisibilityForId = createSelector(
    getState,
    (state: opportunitiesReducer.State, props: any) =>
        state.visibility[props.id]?.list
);

export const getContextualOpportunity = createSelector(
    getState,
    (
        state: opportunitiesReducer.State,
        props: {
            contextualType: OpportunityContextualTypes;
            contextualId: string;
        }
    ) =>
        state.contextual[props?.contextualType]
            ? state.contextual[props?.contextualType][props?.contextualId]?.list
            : null
);
export const getContextualOpportunityIsLoadingPage = createSelector(
    getState,
    (
        state: opportunitiesReducer.State,
        props: {
            contextualType: OpportunityContextualTypes;
            contextualId: string;
        }
    ) =>
        state.contextual[props?.contextualType]
            ? state.contextual[props?.contextualType][props?.contextualId]
                  ?.isLoadingSingle
            : true
);
export const getContextualOpportunityHasLoadingPageError = createSelector(
    getState,
    (
        state: opportunitiesReducer.State,
        props: {
            contextualType: OpportunityContextualTypes;
            contextualId: string;
        }
    ) =>
        state.contextual[props?.contextualType]
            ? state.contextual[props?.contextualType][props?.contextualId]
                  ?.hasLoadingSingleError
            : null
);
