import { createAction, props } from '@ngrx/store';
import { DocumentType } from '@wdx/clmi/api-models';
import { PaginatedApiResponse } from '@wdx/shared/utils';
import { PaginationOptions } from '../../models/pagination-options';

export const getAll = createAction(
    '[Document Types] Get All',
    props<{ reset?: boolean }>()
);

export const getAllSuccess = createAction(
    '[Document Types] Get All Success',
    props<{
        documentTypes: PaginatedApiResponse<DocumentType>;
    }>()
);

export const getAllFailure = createAction(
    '[Document Types] Get All Failure',
    props<{
        error: Error;
    }>()
);

export const updateDocumentTypes = createAction(
    '[Document Types] Update Document Types',
    props<{
        paginationOptions: PaginationOptions;
    }>()
);

export const updateDocumentTypesSuccess = createAction(
    '[Document Types] Update Document Types Success',
    props<{
        payload: PaginatedApiResponse<DocumentType>;
    }>()
);

export const updateDocumentTypesFailure = createAction(
    '[Document Types] Update Document Types Failure',
    props<{
        error: Error;
    }>()
);

export const queryDocumentTypes = createAction(
    '[Document Types] Query Document Types',
    props<{
        query: any;
    }>()
);

export const queryDocumentTypesSuccess = createAction(
    '[Document Types] Query Document Types Success',
    props<{
        documentTypes: PaginatedApiResponse<DocumentType>;
    }>()
);

export const queryDocumentTypesFailure = createAction(
    '[Document Types] Query Document Types Failure',
    props<{
        error: Error;
    }>()
);

export const clearQuery = createAction('[Document Types] Clear Query');

export const disableDocumentType = createAction(
    '[Document Types] Disable Document Type',
    props<{
        code: string;
        isDisabled: boolean;
    }>()
);

export const disableDocumentTypeSuccess = createAction(
    '[Document Types] Disable Document Type Success',
    props<{
        code: string;
        isDisabled: boolean;
        updatedDocType: DocumentType;
    }>()
);

export const disableDocumentTypeFailure = createAction(
    '[Document Types] Disable Document Type Failure',
    props<{
        code: string;
    }>()
);

export const getDocumentTypeById = createAction(
    '[Document Types] Get Document Type By Id',
    props<{
        documentTypeCode: string;
    }>()
);

export const getDocumentTypeByIdSuccess = createAction(
    '[Document Types] Get Document Type By Id Success',
    props<{
        documentTypeCode: string;
        document: DocumentType;
    }>()
);

export const getDocumentTypeByIdFailure = createAction(
    '[Document Types] Get Document Type By Id Failure',
    props<{
        documentTypeCode: string;
        error: Error;
    }>()
);

export const getAllActiveDocumentTypes = createAction(
    '[Document Types] Get All Active Document Types'
);

export const getAllActiveDocumentTypesSuccess = createAction(
    '[Document Types] Get All Active Document Types Success',
    props<{
        activeDocumentTypes: DocumentType[];
    }>()
);

export const getAllActiveDocumentTypesFailure = createAction(
    '[Document Types] Get All Active Document Types Failure',
    props<{
        error: Error;
    }>()
);

export const deleteDocumentTypes = createAction(
    '[Document Types] Delete  Document Types',
    props<{
        code: string;
    }>()
);

export const deleteDocumentTypesSuccess = createAction(
    '[Document Types] Delete  Document Types Success',
    props<{
        code: string;
    }>()
);

export const deleteDocumentTypesFailure = createAction(
    '[Document Types] Delete  Document Types Failure',
    props<{
        code: string;
        error: Error;
    }>()
);
