import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'textToHex' })
export class TextToHexPipe implements PipeTransform {
    transform(value: string): string {
        if (!value && value !== '') {
            return;
        }

        const firstThreeCharactersArray = [
            value.charAt(0) || 'A',
            value.charAt(1) || 'A',
            value.charAt(2) || 'A',
        ].map((character) => character.toUpperCase());

        const charactersAsHex = firstThreeCharactersArray.map((character) => {
            const charCode = character.charCodeAt(0);
            const decimalValue = Math.round(((charCode - 65) / 26) * 256);
            const hexValue = decimalValue
                .toString(16)
                .padStart(2, '0')
                .toUpperCase();

            return hexValue;
        });

        const result = `#${charactersAsHex.join('')}`;

        return result;
    }
}
