<ng-container *ngIf="indicators?.length">
    <ng-container
        *ngTemplateOutlet="
            severityIndicatorIcon;
            context: {
                indicators: indicators,
                severity: SEVERITY.Success
            }
        "
    ></ng-container>
    <ng-container
        *ngTemplateOutlet="
            severityIndicatorIcon;
            context: {
                indicators: indicators,
                severity: SEVERITY.Info
            }
        "
    ></ng-container>
    <ng-container
        *ngTemplateOutlet="
            severityIndicatorIcon;
            context: {
                indicators: indicators,
                severity: SEVERITY.Warning
            }
        "
    ></ng-container>
    <ng-container
        *ngTemplateOutlet="
            severityIndicatorIcon;
            context: {
                indicators: indicators,
                severity: SEVERITY.Danger
            }
        "
    ></ng-container>
</ng-container>

<ng-template
    #severityIndicatorIcon
    let-indicators="indicators"
    let-severity="severity"
>
    <atom-highlight-circle
        *ngIf="indicators | fn: hasSeverityIndicator:severity"
        class="d-block ml-1"
        [themeColor]="severity"
    ></atom-highlight-circle>
</ng-template>
