import { ModalOptions, ModalSide, ModalSize } from '../models/modal.model';
import { ActivityType } from '@wdx/clmi/api-models';

export const MAIN_NAVIGATION_MODAL_ID = 'main-navigation-modal';

export const MODAL_QUERY_PARAMETER = 'modal';

export const ACTIVITY_MODAL_MAP = {
    [ActivityType.Appointment]: 'appointment',
    [ActivityType.PhoneCall]: 'phone-call',
    [ActivityType.Task]: 'task',
    [ActivityType.WorkItem]: 'work-item',
};

export const DUPLICATION_DETECTION_MODAL_ID = 'duplication-detection-modal';

export const DEFAULT_MODAL_OPTIONS: ModalOptions = {
    size: ModalSize.Lg,
    side: ModalSide.Right,
};

export const FULLSCREEN_MODAL_OPTIONS = {
    backdropClass: 'modal-backdrop--ngb',
    windowClass: 'modal-window--ngb',
    modalDialogClass: 'modal-dialog-fullscreen',
    animation: false,
    fullscreen: true,
};

export const FULLSCREEN_MODAL_XL_OPTIONS = {
    backdropClass: 'modal-backdrop--ngb',
    windowClass: 'modal-window--ngb',
    modalDialogClass: 'modal-dialog-xl-fullscreen',
    animation: false,
    fullscreen: true,
};

export const CENTERED_MODAL_OPTIONS = {
    backdropClass: 'modal-backdrop--ngb',
    windowClass: 'modal-window--ngb',
    animation: false,
    centered: true,
};
