import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxIconContainerModule } from '@wdx/shared/components/wdx-icon-container';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeInputUploadModule } from '../../../components/molecules/molecule-input-upload/molecule-input-upload.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { AtomIconButtonModule } from '../atom-icon-button/atom-icon-button.module';
import { AtomAvatarComponent } from './atom-avatar.component';
import { FunctionPipe, TextToHslPipe } from '@wdx/shared/utils';

@NgModule({
    imports: [
        AtomIconButtonModule,
        CommonModule,
        PipesModule,
        OrganismModalModule,
        AtomModalWrapperModule,
        MoleculeInputUploadModule,
        DirectivesModule,
        WdxIconModule,
        WdxIconContainerModule,
        TextToHslPipe,
        FunctionPipe,
    ],
    declarations: [AtomAvatarComponent],
    exports: [AtomAvatarComponent],
})
export class AtomAvatarModule {}
