import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Message,
    MessageDetails,
} from '../../../../../shared/features/message/models/message.model';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../../../../state/_setup/reducers';
import * as messageActions from '../../../../../state/message/message.actions';
import * as messageSelectors from '../../../../../state/message/message.selectors';

@Injectable({
    providedIn: 'root',
})
export class MessageFacade {
    messages$: Observable<Message[]>;

    constructor(private store$: Store<rootReducer.State>) {
        this.messages$ = this.store$.select(messageSelectors.getMessages);
    }

    addErrorMessage(message: MessageDetails): void {
        this.store$.dispatch(messageActions.addErrorMessage({ message }));
    }

    addSuccessMessage(message: MessageDetails): void {
        this.store$.dispatch(messageActions.addSuccessMessage({ message }));
    }

    addWarningMessage(message: MessageDetails): void {
        this.store$.dispatch(messageActions.addWarningMessage({ message }));
    }

    addInfoMessage(message: MessageDetails): void {
        this.store$.dispatch(messageActions.addInfoMessage({ message }));
    }

    removeMessage(message: Message): void {
        this.store$.dispatch(messageActions.removeMessage({ message }));
    }

    removeAllMessages(): void {
        this.store$.dispatch(messageActions.removeAllMessages());
    }
}
