import { Component, Input, OnInit } from '@angular/core';
import { PartyType } from '@wdx/clmi/api-models';
import { Avatar } from '@wdx/clmi/utils/models';
import { Highlight, WdxSize } from '@wdx/shared/utils';
import { OrganismAvatarInfoModalComponent } from '../../../components/organisms/organism-avatar-info-modal/organism-avatar-info-modal.component';
import { RandomStringPipe } from '../../../pipes/random-string.pipe';
import { ModalManagementService } from '../../../services/modal-management.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-avatar',
    templateUrl: './molecule-avatar.component.html',
})
export class MoleculeAvatarComponent implements OnInit {
    @Input() avatar: Avatar;
    @Input() size: WdxSize = 'md';
    @Input() highlightBorder: Highlight;
    @Input() isAvatarInfoEnabled = true;
    @Input() editModalId: string;

    instanceId: string;

    readonly PARTY_TYPE = PartyType;

    constructor(private modalManagementService: ModalManagementService) {}

    ngOnInit() {
        this.instanceId = new RandomStringPipe().transform();
    }

    openAvatarModal() {
        const avatarModal = this.modalManagementService.openModalWithComponent(
            OrganismAvatarInfoModalComponent
        );
        avatarModal.componentInstance.avatar = this.avatar;
    }
}
