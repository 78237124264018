import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as currenciesActions from './currencies.actions';
import { CurrenciesService } from './currencies.service';

@Injectable()
export class CurrenciesEffects {
    private actions$ = inject(Actions);
    private currenciesService = inject(CurrenciesService);

    getCurrencies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(currenciesActions.getCurrencies),
            switchMap(() =>
                this.currenciesService.getCurrencies().pipe(
                    map((currencies) =>
                        currenciesActions.getCurrenciesSuccess({ currencies })
                    ),
                    catchError((error) =>
                        of(
                            currenciesActions.getCurrenciesFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
