import { createAction, props } from '@ngrx/store';
import { GoalType } from '@wdx/clmi/api-models';

export const getAll = createAction('[Goal Types] Get All');

export const getAllSuccess = createAction(
    '[Goal Types] Get All Success',
    props<{
        goalTypes: GoalType[];
    }>()
);

export const getAllFailure = createAction(
    '[Goal Types] Get All Failure',
    props<{
        error: Error;
    }>()
);
