import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeOpportunityContextMenuComponent } from './molecule-opportunity-context-menu.component';

@NgModule({
    imports: [
        CommonModule,
        MoleculeActionButtonModule,
        PipesModule,
        RouterModule,
    ],
    declarations: [MoleculeOpportunityContextMenuComponent],
    exports: [MoleculeOpportunityContextMenuComponent],
})
export class MoleculeOpportunityContextMenuModule {}
