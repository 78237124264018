import { Injectable } from '@angular/core';
import { MoleculeDialogComponent } from '../components/molecules/molecule-dialog/molecule-dialog.component';
import {
    DialogComponentOptionsData,
    DialogOptions,
} from '../models/dialog.model';
import * as rootReducer from '../state/_setup/reducers';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(
        private store$: Store<rootReducer.State>,
        private modalService: NgbModal
    ) {}

    open(options: DialogOptions) {
        const { dialogMode, confirmAction, data, callback } = options;
        const dialogRef = this.openDialog(MoleculeDialogComponent);

        dialogRef.componentInstance.dialogMode = dialogMode;

        if (data) {
            dialogRef.componentInstance.data = data;
        }

        if (confirmAction) {
            dialogRef.closed.subscribe(() =>
                this.store$.dispatch(confirmAction)
            );
        }

        if (callback) {
            dialogRef.closed.subscribe(() => callback());
        }
    }

    openComponent(
        component: any,
        data: DialogComponentOptionsData
    ): NgbModalRef {
        const dialogRef = this.openDialog(component);

        dialogRef.componentInstance.data = data;

        return dialogRef;
    }

    private openDialog(component: any): NgbModalRef {
        return this.modalService.open(component, {
            backdropClass: 'modal-backdrop--ngb',
            windowClass: 'modal-window--ngb',
            centered: true,
            modalDialogClass: 'modal-dialog-centred-xs',
        });
    }
}
