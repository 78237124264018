import {
    ADVISER_FORM_ID,
    INTERMEDIARY_FORM_ID,
    LEAD_FORM_ID,
    ORGANISATION_FORM_ID,
    PERSON_FORM_ID,
    PRODUCT_PROVIDER_FORM_ID,
} from './form-ids.static';

export const CONTACT_FORM_IDS = [
    ADVISER_FORM_ID,
    INTERMEDIARY_FORM_ID,
    LEAD_FORM_ID,
    ORGANISATION_FORM_ID,
    PERSON_FORM_ID,
    PRODUCT_PROVIDER_FORM_ID,
];
