import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../../pipes/pipes.module';

import { WdxButtonModule } from '@wdx/shared/components/wdx-button';
import { TranslateTokenisedStringPipe } from '@wdx/shared/utils';
import { InterpolationDisplayComponent } from './interpolation-display.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PipesModule,
        WdxButtonModule,
        TranslateTokenisedStringPipe,
    ],
    declarations: [InterpolationDisplayComponent],
    exports: [InterpolationDisplayComponent],
})
export class InterpolationDisplayModule {}
