import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxInputGroupComponent } from './wdx-input-group.component';

@NgModule({
    declarations: [WdxInputGroupComponent],
    imports: [CommonModule],
    exports: [WdxInputGroupComponent],
})
export class WdxInputGroupModule {}
