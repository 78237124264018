import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxRadioButtonGroupComponent } from './wdx-radio-button-group.component';
import { WdxRadioButtonModule } from '@wdx/shared/components/wdx-radio-button';

@NgModule({
    imports: [CommonModule, WdxRadioButtonModule],
    declarations: [WdxRadioButtonGroupComponent],
    exports: [WdxRadioButtonGroupComponent],
})
export class WdxRadioButtonGroupModule {}
