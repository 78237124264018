import { Pipe, PipeTransform } from '@angular/core';
import { SystemEntity } from '@wdx/clmi/api-models';
import { ROUTE_PREFIX } from '@wdx/shared/utils';
import { ENTITY_SEARCH_ROUTE_MAP } from '../constants/search-route.constants';

@Pipe({ name: 'entitySearchRoute' })
export class EntitySearchRoutePipe implements PipeTransform {
    transform(entityType: SystemEntity, entityId?: string | number): string[] {
        return [
            ...ROUTE_PREFIX,
            ...ENTITY_SEARCH_ROUTE_MAP[entityType],
            ...(entityId ? [String(entityId)] : []),
        ];
    }
}
