<div class="card-footer">
    <ng-content></ng-content>
    <div class="d-flex">
        <div class="custom-card__footer__value divider-end divider--short">
            <ng-content select=".card-footer-left"></ng-content>
        </div>
        <div
            class="d-flex align-items-center divider-end divider--short flex-grow-1 justify-content-center"
        >
            <ng-content select=".card-footer-center"></ng-content>
        </div>
        <div class="custom-card__footer__priority d-flex justify-content-end">
            <ng-content select=".card-footer-right"></ng-content>
            <ng-container *ngIf="priority">
                <div
                    class="priority-icon badge d-flex align-items-center px-1"
                    [class.badge-danger]="
                        priority === OPPORTUNITY_PRIORITY_TYPE.High
                    "
                    [class.badge-warning]="
                        priority === OPPORTUNITY_PRIORITY_TYPE.Medium
                    "
                    [class.badge-success]="
                        priority === OPPORTUNITY_PRIORITY_TYPE.Low
                    "
                >
                    <i
                        *ngIf="
                            priority === OPPORTUNITY_PRIORITY_TYPE.High ||
                            priority === OPPORTUNITY_PRIORITY_TYPE.Medium
                        "
                        class="fa fa-fw fa-arrow-up"
                    ></i>
                    <i
                        *ngIf="priority === OPPORTUNITY_PRIORITY_TYPE.High"
                        class="fa fa-fw fa-arrow-up"
                    ></i>
                    <i
                        *ngIf="priority === OPPORTUNITY_PRIORITY_TYPE.Low"
                        class="fa fa-fw fa-arrow-down"
                    ></i>
                    <i *ngIf="!priority" class="fa fa-fw fa-minus"></i>
                </div>
            </ng-container>
        </div>
    </div>
</div>
