import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as professionalConnectionsActions from './professional-connections.actions';
import { ProfessionalConnectionsService } from './professional-connections.service';

@Injectable()
export class ProfessionalConnectionsEffects {
    private actions$ = inject(Actions);
    private professionalConnectionsService = inject(
        ProfessionalConnectionsService
    );

    getForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(professionalConnectionsActions.getForParty),
            mergeMap((action) =>
                this.professionalConnectionsService
                    .getForParty(action.partyId)
                    .pipe(
                        map((relationships) =>
                            professionalConnectionsActions.getForPartySuccess({
                                partyId: action.partyId,
                                relationships,
                            })
                        ),
                        catchError((error) =>
                            of(
                                professionalConnectionsActions.getForPartyFailure(
                                    {
                                        partyId: action.partyId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
