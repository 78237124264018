import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { NavItem } from '@wdx/shared/utils';
import { FilterHandler } from './filter-handler.class';

@Directive()
export class BaseSearchBarClass {
    @Input() isLoading: boolean;
    @Input() searchType: string;
    @Input() searchTypePlural: string;
    @Input() totalRecordCount: number;
    @Input() inputSearchText: string;
    @Input() placeholder: string;
    @Input() sortMenu: NavItem[];
    @Input() alwaysShowFilters: boolean;
    @Input() showSearchBar = true;
    @Input() filterHandler: FilterHandler;
    @Input() filterFormId: string;
    @Input() hasMobileFilter: boolean;
    @Input() hasFilterSearch: boolean;

    @Output() search = new EventEmitter<string>();

    onSearch(value: string): void {
        this.search.emit(value);
    }
}
