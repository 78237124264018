import { Lead } from '@wdx/clmi/api-models';
import { createAction, props } from '@ngrx/store';

export const getLead = createAction(
    '[Leads] Get Lead',
    props<{
        leadId: string;
    }>()
);

export const getLeadSuccess = createAction(
    '[Leads] Get Lead Success',
    props<{
        lead: Lead;
    }>()
);

export const getLeadFailure = createAction(
    '[Leads] Get Lead Failure',
    props<{
        leadId: string;
        error: Error;
    }>()
);

export const getFavourites = createAction('[Leads] Get Favourites');

export const getFavouritesSuccess = createAction(
    '[Leads] Get Favourites Success',
    props<{
        favourites: Lead[];
    }>()
);

export const getFavouritesFailure = createAction(
    '[Leads] Get Favourites Failure',
    props<{
        error: Error;
    }>()
);
