import { Directive, Input, inject } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    FieldDefinitionOption,
    FormElementStyle,
    FormElementType,
    FormFieldType,
    PendingChangeStatusType,
    RandomStringPipe,
    SelectApiSource,
    TranslationsService,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { PENDING_CHANGES_LOCK_FIELD, PENDING_CHANGES_TEXT } from '../constants';
import { IFormDynamicData } from '../interfaces';
import {
    ContextualDataContext,
    FormFrameworkSelectOptions,
    ReactiveFormLayoutAndDefinition,
} from '../models';
import { FormStaticService } from '../services';

@Directive()
export class BaseWdxFormControlClass extends WdxDestroyClass {
    _formElement?: ReactiveFormLayoutAndDefinition;

    get formElement(): ReactiveFormLayoutAndDefinition {
        return this._formElement as ReactiveFormLayoutAndDefinition;
    }

    @Input() set formElement(formElement: ReactiveFormLayoutAndDefinition) {
        this._formElement = formElement;

        this.formControl = this.controlContainer?.control?.get(
            formElement.name as string
        ) as AbstractControl;

        if (this.formElement.options) {
            this.options$.next(
                this.filterInActiveOptions(this.formElement.options)
            );
        }

        if (this.formElement.selectSource) {
            this.setOptionsForSelectSource();
        }

        if (formElement.pendingChange) {
            this.warningText = PENDING_CHANGES_TEXT;
        }
    }

    inputId = new RandomStringPipe().transform();

    options$ = new BehaviorSubject<FormFrameworkSelectOptions | undefined>([]);

    readonly FORM_ELEMENT_STYLE = FormElementStyle;
    readonly FORM_ELEMENT_TYPE = FormElementType;
    readonly FORM_FIELD_TYPE = FormFieldType;
    readonly PENDING_CHANGE_STATUS_TYPE = PendingChangeStatusType;
    readonly PENDING_CHANGES_LOCK_FIELD = PENDING_CHANGES_LOCK_FIELD;
    warningText: string | null = null;

    formControl?: AbstractControl;

    get isDisabled(): boolean | undefined {
        return (
            this.formStaticService.isReadonlyMode ||
            this.formElement?.isDisabled ||
            this.formElement?.isReadOnly
        );
    }

    protected formStaticService = inject(FormStaticService);
    private translationsService = inject(TranslationsService);

    constructor(
        public controlContainer: ControlContainer,
        public dynamicDataService: IFormDynamicData
    ) {
        super();
    }

    setOptionsForSelectSource() {
        this.getOptionsForSelectSource$()
            .pipe(take(1))
            .subscribe((options) => this.options$.next(options));
    }

    getOptionsForContextSelectSource$(
        lookups: any[],
        context?: ContextualDataContext[]
    ) {
        return this.dynamicDataService
            .getContextSelectApiSourceOptions(
                this.formElement.selectSource as SelectApiSource,
                lookups,
                context
            )
            .pipe(map((options) => this.filterInActiveOptions(options)));
    }

    getOptionsForSelectSource$(params = {}) {
        return this.dynamicDataService
            .getSelectApiSourceOptions(
                this.formElement.selectSource as SelectApiSource,
                params
            )
            .pipe(map((options) => this.filterInActiveOptions(options)));
    }

    filterInActiveOptions(options: FormFrameworkSelectOptions) {
        if (typeof options?.[0] === 'string') {
            return;
        }
        const filteredOptions = (options as FieldDefinitionOption[]).filter(
            (option) =>
                option['value'] === this.formControl?.value ||
                option.active !== false
        );

        return (filteredOptions as FieldDefinitionOption[]).map((option) => {
            const translatedOption = {
                ...option,
                label: this.translationsService.translateTokenisedString(
                    option.label as string
                ),
            };
            return translatedOption;
        });
    }
}
