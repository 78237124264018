export const DEFAULT_HIGHCHARTS_OPTIONS: Highcharts.Options = {
    // Set styledMode so all styles are driven via css
    chart: {
        styledMode: true,
    },

    // Turn off credits
    credits: {
        enabled: false,
    },

    // Defines the menu items to be displayed in the exports menu (off by default)
    exporting: {
        enabled: false,
        buttons: {
            contextButton: {
                menuItems: [
                    'viewFullscreen',
                    'printChart',
                    'downloadPNG',
                    'downloadPDF',
                    'downloadSVG',
                    'downloadCSV',
                    'downloadXLS',
                ],
            },
        },
    },
};
