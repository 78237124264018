import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import {
    LookupContextMode,
    LookupFieldResult,
    LookupResultsMode,
    LookupSourceDefinition,
    LookupSourceType,
} from '@wdx/clmi/api-models';
import {
    FormContextualDataService,
    FormStaticService,
    dynamicFormsActions,
} from '@wdx/shared/infrastructure/form-framework';
import { LookupMode, TranslationsService } from '@wdx/shared/utils';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BaseInputClass } from '../../../../../classes/base-input-class';
import { LookupSearchEvent } from '../../../../../models/lookup-options.model';
import { ModalManagementService } from '../../../../../services/modal-management.service';
import * as rootReducer from '../../../../../state/_setup/reducers';
import * as lookupsActions from '../../../../../state/lookups/lookups.actions';
import * as lookupsSelectors from '../../../../../state/lookups/lookups.selectors';

@Component({
    selector: 'molecule-input-lookup',
    templateUrl: './molecule-input-lookup.component.html',
})
export class MoleculeInputLookupComponent
    extends BaseInputClass
    implements OnInit
{
    @Input() isMulti = false;
    @Input() showReadout = true;
    @Input() lookupMode: string = LookupMode.Standard;
    @Input() formData: UntypedFormGroup;
    @Input() formContext: any;
    @Input() showNoResultsMessage = false;

    @Output() modalRegistered = new EventEmitter<string>();
    @Output() generateDocument = new EventEmitter<string>();
    @Output() previewAvailableDocumentData =
        new EventEmitter<LookupFieldResult>();
    @Output() closed = new EventEmitter();
    @Output() deleteItem = new EventEmitter();

    lookupId: string;
    lookupIsLoading$: Observable<boolean>;
    lookupHasError$: Observable<boolean>;
    lookupResults$: Observable<LookupFieldResult[]>;
    quickCreateSubscription: Subscription;
    showSuggestion = false;
    placeholder: string;
    contextualResults: any[];
    contextualValues: any[];
    multipleLookupTypes: boolean;
    hasQuickCreate: boolean;
    activeLookupSourceDefinition: LookupSourceDefinition;

    private translationsService = inject(TranslationsService);

    constructor(
        elementRef: ElementRef,
        store$: Store<rootReducer.State>,
        actions$: Actions,
        modalManagementService: ModalManagementService,
        private actionSubject$: ActionsSubject,
        private formContextualDataService: FormContextualDataService,
        @Optional() private formStaticService: FormStaticService
    ) {
        super();

        this.patchInjectedItems({
            elementRef,
            store$,
            actions$,
            modalManagementService,
        });
    }

    ngOnInit() {
        this.lookupId = this.formInputData.name || this.formInputData.id;

        this.lookupIsLoading$ = this.store$.select(
            lookupsSelectors.getIsLoadingList,
            {
                lookupId: this.lookupId,
            }
        );
        this.lookupHasError$ = this.store$.select(
            lookupsSelectors.getHasLoadingListError,
            {
                lookupId: this.lookupId,
            }
        );
        this.lookupResults$ = this.store$.select(lookupsSelectors.getList, {
            lookupId: this.lookupId,
        });
        this.contextualValues = this.formInputData?.contextualValues;

        this.multipleLookupTypes =
            this.formInputData?.lookupSources?.length > 1;

        this.hasQuickCreate = this.formInputData.lookupSources.some(
            (lookupSource) => Boolean(lookupSource.quickCreateFormName)
        );
    }

    onModalRegistered(modalId: string) {
        this.modalRegistered.emit(modalId);
    }

    onOpen(): void {
        this.store$.dispatch(
            lookupsActions.clearLookup({ lookupId: this.lookupId })
        );

        if (this.hasQuickCreate) {
            this.quickCreateSubscription = this.listenTo(
                ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions
            )
                .pipe(
                    filter(
                        (action: any) =>
                            action?.formId ===
                            this.activeLookupSourceDefinition
                                .quickCreateFormName
                    ),
                    take(1)
                )
                .subscribe((action: any) => {
                    const formDataInstance =
                        action?.response || action?.formDataInstance;
                    const value = {
                        id: formDataInstance.entityId,
                        name:
                            formDataInstance.data?.name ||
                            `${formDataInstance.data?.firstName} ${formDataInstance.data?.lastName}`,
                        code: formDataInstance.data?.code,
                        lookupType: this.activeLookupSourceDefinition.type,
                    };
                    this.updateValue(
                        this.isMulti
                            ? [
                                  ...(this.value || []).filter(
                                      (element) => element.id !== value.id
                                  ),
                                  value,
                              ]
                            : value
                    );
                });
        }

        this.store$.dispatch(
            lookupsActions.setCurrentLookupId({ lookupId: this.lookupId })
        );
    }

    onClose(): void {
        if (this.quickCreateSubscription) {
            this.quickCreateSubscription.unsubscribe();
        }
        this.store$.dispatch(
            lookupsActions.clearLookup({ lookupId: this.lookupId })
        );
        this.resetContext();
        this.closed.emit();
    }

    getContextualData() {
        return this.formContextualDataService.getContextualData(
            this.contextualValues,
            this.formData,
            this.formStaticService?.form.getRawValue(),
            this.formStaticService?.formDefinition?.schema
        );
    }

    onSuggestedSearch(searchEvent: LookupSearchEvent): void {
        this.dispatchContextualLookup(searchEvent);

        this.actionSubject$
            .pipe(
                ofType(lookupsActions.getContextualLookupSuccess),
                take(1),
                filter((res) => Boolean(res.results.length))
            )
            .subscribe((_) => {
                const translatedSourceTypes =
                    this.formInputData.lookupSources.map((source) =>
                        this.translationsService.getTranslationByKey(
                            `ENUM_LOOKUPSOURCETYPE_${source.type.toUpperCase()}`
                        )
                    );
                this.placeholder =
                    this.formInputData.lookupContextMode ===
                    LookupContextMode.SearchPreservesContext
                        ? `Search ${translatedSourceTypes.join(', ')}`
                        : 'Search CLMi';
                this.showSuggestion = true;
            });
    }

    dispatchContextualLookup(searchEvent: LookupSearchEvent): void {
        this.store$.dispatch(
            lookupsActions.getContextualLookup({
                lookupId: this.lookupId,
                lookupSource: searchEvent.lookupSourceDefinition.type,
                lookups: this.contextualResults,
                searchText: searchEvent.searchText,
            })
        );
    }

    dispatchGetLook(searchEvent: LookupSearchEvent): void {
        this.store$.dispatch(
            lookupsActions.getLookup({
                lookupId: this.lookupId,
                lookupSource: searchEvent.lookupSourceDefinition.type,
                searchText: searchEvent.searchText,
                entityType: this.formInputData.entityType,
                entityId: this.formInputData.entityId,
            })
        );
    }

    onSearch(searchEvent: LookupSearchEvent, allowEmptySearch = false): void {
        this.activeLookupSourceDefinition = searchEvent.lookupSourceDefinition;

        if (this.formData) {
            const DATA = this.getContextualData();
            this.contextualResults =
                this.formContextualDataService.getContextualResults(DATA);
        }

        this.resetContext();

        if (
            this.formInputData.lookupResultsMode ===
                LookupResultsMode.Immediate &&
            !searchEvent.searchText
        ) {
            if (this.contextualResults?.length) {
                this.onSuggestedSearch(searchEvent);
                return;
            }
            this.dispatchGetLook(searchEvent);
            return;
        }

        if (
            !allowEmptySearch &&
            !searchEvent.searchText &&
            searchEvent.lookupSourceDefinition.type !==
                LookupSourceType.Assignee
        ) {
            return;
        }

        if (
            this.formInputData?.lookupContextMode ===
                LookupContextMode.SearchPreservesContext &&
            this.contextualResults?.length
        ) {
            this.dispatchContextualLookup(searchEvent);
        } else {
            this.dispatchGetLook(searchEvent);
        }
    }

    onGenerateAndDownloadDocument(entityId: string): void {
        this.generateDocument.emit(entityId);
    }

    onPreviewAvailableDocumentData(lookup: LookupFieldResult): void {
        this.previewAvailableDocumentData.emit(lookup);
    }

    resetContext(): void {
        this.showSuggestion = undefined;
        this.placeholder = undefined;
    }

    onDeleteItem(item: any): void {
        this.deleteItem.emit(item);
    }
}
