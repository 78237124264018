import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as portfolioActions from './portfolio.actions';
import { PortfolioService } from './services/portfolio.service';

@Injectable()
export class PortfolioEffects {
    private actions$ = inject(Actions);
    private portfolioService = inject(PortfolioService);

    getPortfolio$ = createEffect(() =>
        this.actions$.pipe(
            ofType(portfolioActions.getPortfolio),
            switchMap((action) =>
                this.portfolioService.getPortfolio(action.portfolioId).pipe(
                    map((portfolio) =>
                        portfolioActions.getPortfolioSuccess({
                            portfolio,
                            portfolioId: action.portfolioId,
                        })
                    ),
                    catchError((error) =>
                        of(
                            portfolioActions.getPortfolioError({
                                error,
                                portfolioId: action.portfolioId,
                            })
                        )
                    )
                )
            )
        )
    );
}
