import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ArticleStatusType,
    FilterOperator,
    Query,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { Paging } from '@wdx/clmi/api-services/models';
import {
    ARTICLE_EXTERNAL_FORM_ID,
    ARTICLE_FILTER_FORM_ID,
    ARTICLE_INTERNAL_FORM_ID,
    ARTICLE_KNOWLEDGE_FORM_ID,
    FormDisplayMode,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FilterHandler } from '../../../../../classes/filter-handler.class';
import { ICON_ADD } from '../../../../../constants/icons.constants';
import { SORT_MENU_ARTICLES } from '../../../../../constants/sort-menu.constants';
import { ActionButton } from '../../../../../models/action-button.model';
import { ActionButtonMode } from '../../../../../models/action-buttons-mode.model';
import { ListStyle } from '../../../../../models/list.model';
import { Privilege } from '../../../../../models/privilege.model';
import { FeatureSvg } from '../../../../../models/svg.model';
import * as rootReducer from '../../../../../state/_setup/reducers';
import * as relatedRecordsSelectors from '../../../../../state/related-records/related-records.selectors';
import { FilterQueryFacadeService } from '@wdx/clmi/api-services/state';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-articles-list',
    templateUrl: './organism-articles-list.component.html',
})
export class OrganismArticlesListComponent
    extends WdxDestroyClass
    implements OnInit, OnDestroy
{
    @Input() articlesPerRow: number;
    @Input() isForInfinityScroll: boolean;
    @Input() showFilters: boolean;
    @Input() showFilterBar = true;
    @Input() showSearchBar = true;
    @Input() showLinkButton = false;
    @Input() responsiveScroll: boolean;
    @Input() limit: number;
    @Input() listStyle: ListStyle;
    @Input() showArticleImage = true;
    @Input() isSuggestedCardList: boolean;
    @Input() resetFilters = false;
    @Input() linkToEntityId: string;
    @Input() linkToEntityType: SystemEntity;

    isLoading$: Observable<boolean>;
    hasError$: Observable<boolean>;

    articles$ = new BehaviorSubject<string>(null);
    loading$: Observable<boolean>;

    paging: Paging;
    query: Query;

    filterHandler = new FilterHandler();

    readonly LIST_STYLE = ListStyle;
    readonly ARTICLE_FILTER_FORM = ARTICLE_FILTER_FORM_ID;
    readonly FORM_DISPLAY_MODE = FormDisplayMode;
    readonly FILTER_TYPE = SystemEntity;

    createArticleActionButton: ActionButton = {
        mode: ActionButtonMode.DropdownButtonIcon,
        icon: ICON_ADD.icon,
        privileges: [Privilege.CreateArticle],
        dropdownMenu: [
            {
                label: 'Article from Website',
                value: 'ArticleFromWebsite',
                formSetup: {
                    formId: ARTICLE_EXTERNAL_FORM_ID,
                },
            },
            {
                label: 'Knowledge Base Article',
                value: 'KnowledgeBaseArticle',
                formSetup: {
                    formId: ARTICLE_KNOWLEDGE_FORM_ID,
                },
            },
            {
                label: 'Internal Article',
                value: 'InternalArticle',
                formSetup: {
                    formId: ARTICLE_INTERNAL_FORM_ID,
                },
            },
        ],
    };

    readonly SORT_MENU_ARTICLES = SORT_MENU_ARTICLES;
    readonly FEATURE_SVG = FeatureSvg;

    constructor(
        private store$: Store<rootReducer.State>,
        private filterQueryFacadeService: FilterQueryFacadeService
    ) {
        super();
    }

    ngOnInit(): void {
        combineLatest([
            this.filterQueryFacadeService.selectFilterResultsSuccess$(
                SystemEntity.Article
            ),
            this.store$.select(relatedRecordsSelectors.getArticles, {
                entityType: this.linkToEntityType,
                entityId: this.linkToEntityId,
            }),
        ])
            .pipe(
                takeUntil(this.destroyed$),
                filter(([articles]) => Boolean(articles)),
                map(([articles, relatedArticles]) => {
                    if (this.showLinkButton) {
                        const linkedIds = relatedArticles?.map(
                            (relatedArticle) => relatedArticle.id
                        );
                        return articles?.map((article) => ({
                            ...article,
                            isLinked: linkedIds?.includes(article.id),
                        }));
                    }
                    return articles;
                })
            )
            .subscribe((articles) => this.articles$.next(articles));

        this.loading$ = this.filterQueryFacadeService.selectFilterLoading$(
            SystemEntity.Article
        );

        this.hasError$ = this.filterQueryFacadeService.selectFilterError$(
            SystemEntity.Article
        );

        this.filterQueryFacadeService
            .getFilterPaging$(SystemEntity.Article)
            .subscribe((paging) => (this.paging = paging));

        this.filterHandler.valuesChanged
            .pipe(takeUntil(this.destroyed$))
            .subscribe((query) => {
                this.setQuery(query);
                this.loadArticles();
            });

        this.setQuery();
        this.loadArticles();
    }

    setQuery(filters: any = {}) {
        this.query = {
            ...filters,
            expressions: [
                {
                    field: 'Search',
                    operator: FilterOperator.Matches,
                    values: [filters.search || ''],
                },
                {
                    field: 'Status',
                    operator: FilterOperator.Equals,
                    values: [ArticleStatusType.Published],
                },
            ],
        };
    }

    loadArticles(): void {
        this.filterQueryFacadeService.getResults(
            SystemEntity.Article,
            this.query,
            {},
            true
        );
    }

    onInfinityScrollFired(): void {
        if (this.paging.totalPages > this.paging.page) {
            this.filterQueryFacadeService.updateResults(
                SystemEntity.Article,
                this.paging,
                this.query
            );
        }
    }
}
