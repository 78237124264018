<wdx-icon
    class="guidance-icon text-muted"
    icon="question-circle"
    scale="1"
    container="body"
    popoverClass="popover-info"
    [class.invisible]="!guidanceText"
    [class.pointer]="guidanceText"
    [ngbPopover]="guidanceText"
></wdx-icon>
