import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxListModule } from '@wdx/shared/components/wdx-list';
import { MoleculePopoverGridModule } from '../../../components/molecules/molecule-popover-grid/molecule-popover-grid.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MoleculeIdentifierListComponent } from './molecule-identifier-list.component';

@NgModule({
    imports: [
        CommonModule,
        MoleculePopoverGridModule,
        PipesModule,
        WdxIconModule,
        WdxListModule,
    ],
    declarations: [MoleculeIdentifierListComponent],
    exports: [MoleculeIdentifierListComponent],
})
export class MoleculeIdentifierListModule {}
