import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedApiResponse } from '@wdx/clmi/api-services/models';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { BatchMessage } from '../../models/batch.model';
import { ConfigService } from '../../services/config.service';
@Injectable()
export class BatchMessageService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    getBatchMessage(): Observable<PaginatedApiResponse<BatchMessage>> {
        return this.http.get<PaginatedApiResponse<BatchMessage>>(
            `${this.config.getConfiguration().API_BASE}/message`
        );
    }

    getBatchMessageByBatchId(
        batchId: string
    ): Observable<PaginatedApiResponse<BatchMessage>> {
        return this.http.get<PaginatedApiResponse<BatchMessage>>(
            `${
                this.config.getConfiguration().API_BASE
            }/messagebatch/${batchId}/message`
        );
    }

    deleteBatchMessageRow(id: string): Observable<BatchMessage> {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/MessageEDS/${id}`
        );
    }

    deleteMultipleBatchMessage(ids: string[]) {
        return this.http.delete(
            `${this.config.getConfiguration().API_BASE}/MessageEDS/delete`,
            {
                body: ids,
            }
        );
    }

    getSingleBatchMessage(id: string): Observable<BatchMessage> {
        return this.http.get(
            `${this.config.getConfiguration().API_BASE}/MessageEDS/${id}`
        );
    }

    replayMessage(id: string): Observable<BatchMessage> {
        return this.http.patch<BatchMessage>(
            `${
                this.config.getConfiguration().API_BASE
            }/MessageEDS/${id}/replay`,
            {}
        );
    }

    replayMultipleMessage(ids: string[]) {
        return this.http.patch<any>(
            `${this.config.getConfiguration().API_BASE}/MessageEDS/replay`,
            ids
        );
    }

    getFile(batchId: string): Observable<HttpResponse<Blob>> {
        return this.http.get<Blob>(
            `${
                this.config.getConfiguration().API_BASE
            }/messagebatch/${batchId}/failure/csv`,
            {
                observe: 'response',
                responseType: 'blob' as 'json',
            }
        );
    }

    downloadFile(name: string, response: HttpResponse<Blob>) {
        const blob = new Blob([response.body], { type: response.body.type });
        saveAs(blob, name);
    }
}
