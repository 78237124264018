import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxListModule } from '@wdx/shared/components/wdx-list';
import { WdxSwitchModule } from '@wdx/shared/components/wdx-switch';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { OrganismAddInfoCardsModule } from '../../organisms/organism-add-info-cards/organism-add-info-cards.module';
import { MoleculeInfoCardModule } from '../molecule-info-card/molecule-info-card.module';
import { AtomHighlightTextModule } from './../../atoms/atom-highlight-text/atom-highlight-text.module';
import { MoleculeInfoCardGroupComponent } from './molecule-info-card-group.component';

@NgModule({
    imports: [
        AtomHighlightTextModule,
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeInfoCardModule,
        NgbPopoverModule,
        OrganismAddInfoCardsModule,
        RouterModule,
        WdxListModule,
        WdxSwitchModule,
        WdxIconModule,
    ],
    declarations: [MoleculeInfoCardGroupComponent],
    exports: [MoleculeInfoCardGroupComponent],
})
export class MoleculeInfoCardGroupModule {}
