<atom-async-data
    [isLoading]="isPreLoading || !(data$ | ngrxPush)"
    [overlayContent]="isPreLoading"
>
    <ng-container *ngIf="formDetails$ | ngrxPush as formDetails">
        <div *ngIf="formDetails?.lock">
            <wdx-ff-lock-summary [formData]="formDetails">
            </wdx-ff-lock-summary>
        </div>

        <ng-container
            *ngIf="summaryInfoCards$ | ngrxPush; let summaryInfoCards"
        >
            <div *ngIf="summaryInfoCards?.length" class="mt-2">
                <wdx-ff-summary-info-cards
                    [infoCards]="summaryInfoCards"
                ></wdx-ff-summary-info-cards>
            </div>
        </ng-container>

        <wdx-ff-pending-changes
            *ngIf="formDetails?.pendingChanges?.length"
            [showButton]="!caseId"
            [pendingChanges]="formSummaryPendingChangesService?.coc"
            [pendingChangesSubForm]="
                formSummaryPendingChangesService?.pendingChangesSubFormCounter
            "
            (wdxOnClick)="onClickEvent(FORM_SUMMARY_CHANGE_OF_CIRCUMSTANCE)"
        ></wdx-ff-pending-changes>

        <ng-container *ngIf="layoutDefinition$ | ngrxPush as layoutDefinition">
            <organism-form-summary-content
                *ngIf="layoutDefinition"
                [entityId]="entityId"
                [layoutDefinition]="layoutDefinition"
                [definition]="definition$ | ngrxPush"
                [data]="data$ | ngrxPush"
                [formDetails]="formDetails"
                [subFormData]="subFormData$ | ngrxPush"
            >
            </organism-form-summary-content>
        </ng-container>
    </ng-container>
</atom-async-data>
