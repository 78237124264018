import { Component, Input } from '@angular/core';
import { OpportunityPriorityType } from '@wdx/clmi/api-models';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-card-footer',
    templateUrl: './molecule-card-footer.component.html',
})
export class MoleculeCardFooterComponent {
    @Input() priority: OpportunityPriorityType;

    now: Date = new Date();

    readonly OPPORTUNITY_PRIORITY_TYPE = OpportunityPriorityType;
}
