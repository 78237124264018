import { createAction, props } from '@ngrx/store';
import { AdminRole } from '@wdx/clmi/api-models';

export const searchRoles = createAction(
    '[Admin Roles] Search Admin Roles',
    props<{
        reset?: boolean;
        filter: any;
    }>()
);

export const searchRolesSuccess = createAction(
    '[Admin Roles] Search Admin Roles Success',
    props<{
        roles: AdminRole[];
    }>()
);

export const searchRolesFailure = createAction(
    '[Admin Roles] Search Admin Roles Failure',
    props<{ error: Error }>()
);
