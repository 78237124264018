import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AtomFeatureSvgModule } from '../../../components/atoms/atom-feature-svg/atom-feature-svg.module';
import { FeatureSvg } from '../../../models/svg.model';

@Component({
    selector: 'clmi-no-record',
    templateUrl: './no-record.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, AtomFeatureSvgModule],
})
export class NoRecordComponent {
    readonly FEATURE_SVG = FeatureSvg;
}
