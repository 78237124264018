<div
    class="card card-body card-clickable rounded-0 border-top-0 border-start-0 border-end-0 border d-flex flex-row align-items-stretch"
    role="button"
    [routerLink]="data.infoCard?.routerLinkData"
>
    <div class="flex-grow-1 d-flex align-items-center text-truncate">
        <molecule-avatar
            class="avatar-single pointer avatar-group-border-transparent"
            size="lg"
            [avatar]="data.infoCard?.avatars[0]"
            [isAvatarInfoEnabled]="
                data.infoCard?.avatars[0]?.isAvatarInfoEnabled
            "
        ></molecule-avatar>
        <div class="divider-start text-truncate">
            <molecule-info-card
                class="d-block mb-1"
                [primaryInfo]="data.infoCard.primaryInfo"
                [routerLinkData]="data.infoCard.routerLinkData"
                [secondaryInfo]="data.infoCard.secondaryInfo"
                [idList]="data.infoCard?.idList"
            ></molecule-info-card>
            <ng-container *ngIf="data.clientLifecycle">
                <clmi-filter-table-lifecycle-stage-template
                    [data]="data.clientLifecycle"
                ></clmi-filter-table-lifecycle-stage-template
            ></ng-container>
        </div>
    </div>
    <div class="d-flex flex-column justify-content-between flex-shrink-0">
        <wdx-toggle-button
            size="sm"
            [toggle]="data.actionButton?.isFavourite"
            (wdxOnChange)="onToggleChange($event)"
        ></wdx-toggle-button>

        <molecule-action-button
            [actionButton]="data.actionButton?.contextMenu"
            (selectMenuItem)="onMenuItemSelected($event)"
        ></molecule-action-button>
    </div>
</div>
