import {
    ICON_COVERAGE,
    ICON_EXTERNAL_LINK,
    ICON_MESSAGE,
} from '../../../../../constants/icons.constants';
import { Privilege } from '../../../../../models/privilege.model';

export const LEGEND = [
    {
        label: 'My Coverage',
        icon: ICON_COVERAGE.icon,
    },
];

export const CHAT_MENU_ITEM = 'Chat';
export const MEETING_MENU_ITEM = 'Meeting';
export const PHONE_CALL_MENU_ITEM = 'Phone Call';
export const OPEN_NEW_TAB_MENU_ITEM = 'Open in New Tab';

export const ADD_TO_MENU_ITEM = [
    {
        id: null,
        label: OPEN_NEW_TAB_MENU_ITEM,
        icon: ICON_EXTERNAL_LINK.icon,
        data: null,
        cySelector: 'btn-open-new-tab-menu-item',
    },
];

export const ADD_TO_MENU_ITEM_FEATURE = [
    {
        label: CHAT_MENU_ITEM,
        icon: ICON_MESSAGE.icon,
        privilege: Privilege.CreateActivity,
        // eslint-disable-next-line max-len
        data: 'https://web.qwil.io/web/organisations/00360f11-6a72-4855-88f6-8c15533f7e3b/0/chats/003608bb-3b73-4e96-b81b-ce245a9b1d23?chatListType=1',
    },
];

export const INVITE_ACTION = 'INVITE';
