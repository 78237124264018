import { KeyValueObject, SystemEntity } from '../../models';
import {
    ADVISER_FORM_ID,
    CAMPAIGN_FORM_ID,
    EVENT_FORM_ID,
    EVENT_PARTICIPATION_FORM_ID,
    INTERMEDIARY_FORM_ID,
    LIST_FORM_ID,
    MEETING_FORM_ID,
    OPPORTUNITY_FORM_ID,
    ORGANISATION_FORM_ID,
    PERSON_FORM_ID,
    PHONECALL_FORM_ID,
    TASK_FORM_ID,
} from './form-ids.static';

export const FORM_ID_ENTITY_MAP: KeyValueObject<SystemEntity> = {
    [MEETING_FORM_ID]: SystemEntity.Activity,
    [PHONECALL_FORM_ID]: SystemEntity.Activity,
    [OPPORTUNITY_FORM_ID]: SystemEntity.OpportunityTeamMember,
    [PERSON_FORM_ID]: SystemEntity.Party,
    [ORGANISATION_FORM_ID]: SystemEntity.Party,
    [TASK_FORM_ID]: SystemEntity.Task,
    [LIST_FORM_ID]: SystemEntity.List,
    [CAMPAIGN_FORM_ID]: SystemEntity.Campaign,
    [EVENT_FORM_ID]: SystemEntity.Event,
    [INTERMEDIARY_FORM_ID]: SystemEntity.Intermediary,
    [ADVISER_FORM_ID]: SystemEntity.Adviser,
    [EVENT_PARTICIPATION_FORM_ID]: SystemEntity.EventParticipation,
};
