import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'clmi-comment-card',
    templateUrl: './comment-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentCardComponent implements OnInit {
    @Input() stylingConfig: {
        inverted: boolean;
        inputCard?: boolean;
        reply?: boolean;
    };
    public classes = '';

    ngOnInit(): void {
        const {
            inverted = false,
            inputCard = false,
            reply = false,
        } = this.stylingConfig || {};
        let classes = '';
        classes += !inputCard || (inputCard && inverted) ? 'p-2 ' : '';
        classes += inputCard ? 'py-2 ' : '';
        classes += reply ? 'ms-2 ' : '';
        let background = 'bg-muted';
        if (!inputCard) {
            if (reply) {
                background = inverted ? 'bg-secondary-light' : 'bg-strong';
            } else {
                background = inverted ? 'bg-muted' : 'bg-body';
            }
        }
        this.classes = classes + background;
    }
}
