import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemeComponentClass } from '@wdx/shared/components/abstract-classes';

@Component({
    selector: 'wdx-spinner',
    templateUrl: './wdx-spinner.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxSpinnerComponent extends ThemeComponentClass {
    override themeClassPrefix = 'text';

    @Input() small?: boolean;
}
