import { Injectable } from '@angular/core';
import { Region, StatusType } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';

@Injectable()
export class RegionsService extends BaseApiService {
    getRegions(): Observable<Region[]> {
        return this.get<Region[]>('region');
    }

    disableRegion(id: string, status: StatusType): Observable<Region> {
        return this.patch<Region>(`region/${id}/status/${status}`, {});
    }
}
