import { SystemEntity } from '@wdx/clmi/api-models';

export const QUERY_PARAM_UNIMPLEMENTED = {};

export const ENTITY_QUERY_PARAM_MAP = {
    [SystemEntity.ActivityParty]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Activity]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Adviser]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.AmlReport]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.AppStatus]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Appointment]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.ArticleDistribution]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Article]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.BankAccount]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Benchmark]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Case]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.ClientProductRelationship]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.ClientProduct]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Client]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.ContactPoint]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.DataLock]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.ElectronicAddress]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Email]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Engagement]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.ExternalTask]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.FeeStructure]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.FeedNudge]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.GoalPoint]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.InsuranceAccount]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Intermediary]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.InvestmentAccount]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.LendingAccount]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Mandate]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.AmlRisk]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Message]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Note]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Notification]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.NudgeLog]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Nudge]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Objective]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Opportunity]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Organisation]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.PartyRoleAccess]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.PartyRole]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Party]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Person]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.PhoneAddress]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.PhoneCall]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.PostalAddress]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.PotentialDuplicate]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Process]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.ProductGroupMember]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.ProductGroup]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Product]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.QueueItem]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Relationship]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Reminder]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.RiskLevel]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.SocialAddress]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Tag]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Task]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.Team]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.TimeBooking]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.User]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.WebHook]: QUERY_PARAM_UNIMPLEMENTED,
    [SystemEntity.WorkItem]: QUERY_PARAM_UNIMPLEMENTED,
};
