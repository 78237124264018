import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { userSelectors } from '@wdx/clmi/api-services/services';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleTypeEnum } from '../../../models/role-type.model';
import * as rootReducer from '../../../state/_setup/reducers';
import { RolesService } from '../roles/roles.service';

@Injectable({
    providedIn: 'root',
})
export class AdministratorService {
    constructor(
        private store$: Store<rootReducer.State>,
        private roleService: RolesService
    ) {}

    isTenantAdminstrator$(): Observable<boolean> {
        return combineLatest([
            this.store$.select(userSelectors.isSystemAdminSelector),
            this.roleService.hasRole$(RoleTypeEnum.TenantAdministrator),
        ]).pipe(map(([isSystemAdmin, hasRole]) => isSystemAdmin || hasRole));
    }
}
