import { Pipe, PipeTransform } from '@angular/core';
import { DocumentType, EntityPermissionType } from '@wdx/clmi/api-models';
import { DOCUMENT_TYPE_FORM_ID } from '@wdx/shared/utils';
import { ICON_UPLOAD } from '../constants/icons.constants';
import { ActionButton } from '../models/action-button.model';
import { ActionButtonMode } from '../models/action-buttons-mode.model';

@Pipe({ name: 'documentTypesAsActionButton' })
export class DocumentTypesAsActionButtonPipe implements PipeTransform {
    transform(
        documentTypes: DocumentType[],
        partyRoleId: string
    ): ActionButton {
        return (
            documentTypes?.length && {
                mode: ActionButtonMode.DropdownButtonIcon,
                icon: ICON_UPLOAD.icon,
                permission: EntityPermissionType.Edit,
                dropdownMenu: documentTypes
                    ? documentTypes.map((type) => {
                          return {
                              label: type.name,
                              value: DOCUMENT_TYPE_FORM_ID,
                              formSetup: {
                                  formId: DOCUMENT_TYPE_FORM_ID,
                                  initialisationParams: {
                                      ...(partyRoleId ? { partyRoleId } : {}),
                                      ...{ documentType: type.code },
                                  },
                              },
                          };
                      })
                    : [],
            }
        );
    }
}
