import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import {
    TranslateTokenisedStringPipe,
    WdxDestroyClass,
    WdxMarkdownToHtmlPipe,
    WdxSafeHtmlPipe,
} from '@wdx/shared/utils';

import { WdxIconButtonModule } from '@wdx/shared/components/wdx-icon-button';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { WdxToastService } from './wdx-toast.service';

@Component({
    selector: 'wdx-toast',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        WdxIconModule,
        NgbToastModule,
        WdxMarkdownToHtmlPipe,
        WdxSafeHtmlPipe,
        WdxSpinnerModule,
        WdxIconButtonModule,
        WdxIconModule,
        TranslateTokenisedStringPipe,
    ],
    templateUrl: './wdx-toast.component.html',
    styleUrls: ['./wdx-toast.component.scss'],
})
export class WdxToastComponent extends WdxDestroyClass {
    public wdxToastService = inject(WdxToastService);

    @HostBinding('class') class =
        'toast-container d-block position-fixed top-0 start-50 translate-middle-x z-index-999 mt-4';
}
