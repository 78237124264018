import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TRANSLATION_SOMETHING_WENT_WRONG } from '@wdx/shared/utils';
import { SPINNER_CONTAINER_MIN_HEIGHT } from '../../constants/wdx-is-loading.static';
import { WdxLoadingType } from '../../types/wdx-loading-type.type';

@Component({
    templateUrl: './wdx-is-loading.component.html',
    styleUrls: ['./wdx-is-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxIsLoadingComponent {
    @Input() hasError?: boolean;
    @Input() type: WdxLoadingType = 'spinner';
    @Input() overlay?: boolean;

    readonly MIN_HEIGHT = SPINNER_CONTAINER_MIN_HEIGHT;
    readonly SOMETHING_WENT_WRONG = TRANSLATION_SOMETHING_WENT_WRONG;
}
