import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { WdxListDelimiter } from '../models/wdx-list-delimiter.type';

@Component({
    selector: 'wdx-list',
    templateUrl: './wdx-list.component.html',
    styleUrls: ['./wdx-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxListComponent {
    @Input() set vertical(isVertical: boolean) {
        this.horizontalClass = !isVertical;
        this.verticalClass = isVertical;
    }
    @Input() set delimiter(delimiter: WdxListDelimiter) {
        this.pipeDelimiterClass = delimiter === 'pipe';
    }

    @HostBinding('attr.role') role = 'ul';
    @HostBinding('class.wdx-list--horizontal') horizontalClass = true;
    @HostBinding('class.wdx-list--vertical') verticalClass = false;
    @HostBinding('class.wdx-list--pipe') pipeDelimiterClass = false;
}
