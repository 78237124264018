import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { userSelectors } from '@wdx/clmi/api-services/services';
import { take } from 'rxjs/operators';
import { BaseComponentClass } from '../../../classes/base-component.class';
import { Privilege } from '../../../models/privilege.model';
import * as rootReducer from '../../../state/_setup/reducers';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-async-button',
    templateUrl: './atom-async-button.component.html',
})
export class AtomAsyncButtonComponent
    extends BaseComponentClass
    implements OnInit
{
    @Input() isLoading = false;
    @Input() disabled: boolean;
    @Input() btnClass = 'btn';
    @Input() cySelector?: string;
    @Input() privilege?: Privilege;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClick = new EventEmitter();

    constructor(store$: Store<rootReducer.State>) {
        super();
        this.patchInjectedItems({
            store$,
        });
    }

    ngOnInit(): void {
        if (this.privilege) {
            this.checkHavePrivilege();
        }
    }

    checkHavePrivilege(): void {
        this.disabled = true;
        this.store$
            .select(userSelectors.getPrivilegesSelector)
            .pipe(take(1))
            .subscribe(
                (privileges) =>
                    (this.disabled =
                        !privileges.includes(Privilege.Administrator) ||
                        !privileges.includes(this.privilege))
            );
    }

    onAsyncButtonClicked() {
        if (!this.disabled) {
            this.onClick.emit();
        }
    }
}
