import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'abbreviateBytes' })
export class AbbreviateBytesPipe implements PipeTransform {
    transform(value: number): string {
        const workingValue = value || 0;

        const oneKb = 1024;
        const oneMb = oneKb * 1024;
        const oneGb = oneMb * 1024;

        let reducedValue = '';
        let units = '';

        switch (true) {
            case workingValue > oneGb:
                reducedValue = (workingValue / oneGb).toFixed(2);
                units = 'GB';
                break;
            case workingValue > oneMb:
                reducedValue = (workingValue / oneMb).toFixed(2);
                units = 'MB';
                break;
            case workingValue > oneKb:
                reducedValue = (workingValue / oneKb).toFixed(2);
                units = 'KB';
                break;

            default:
                reducedValue = String(workingValue);
                units = 'B';
                break;
        }

        return `${reducedValue}${units}`;
    }
}
