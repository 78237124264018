import * as webHooksReducer from './web-hooks.reducer';
import { createSelector } from '@ngrx/store';

/**
 * Reducer state selector
 */
export const getState = (state: {
    webHooks: webHooksReducer.State;
}): webHooksReducer.State => state.webHooks;

export const getWebHooksIsLoadingPage = createSelector(
    getState,
    (state: webHooksReducer.State, props: any) =>
        state.webHooks[props.id]?.isLoadingPage
);
export const getWebHooksHasLoadingPageError = createSelector(
    getState,
    (state: webHooksReducer.State, props: any) =>
        state.webHooks[props.id]?.hasLoadingPageError
);
export const getWebHooksInfinityPaging = createSelector(
    getState,
    (state: webHooksReducer.State, props: any) =>
        state.webHooks[props.id]?.infinity?.paging
);
export const getWebHooksInfinityCombinedList = createSelector(
    getState,
    (state: webHooksReducer.State, props: any) =>
        state.webHooks[props.id]?.infinity?.combinedList
);
