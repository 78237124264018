import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormFiltersModule } from '../../../shared/features/form-filters/form-filters.module';
import { PushModule } from '@ngrx/component';
import { ViewShellComponent } from './view-shell.component';

@NgModule({
    imports: [CommonModule, FormFiltersModule, PushModule],
    declarations: [ViewShellComponent],
    exports: [ViewShellComponent],
})
export class ViewShellModule {}
