import { createSelector } from '@ngrx/store';
import * as dashboardsReducer from './dashboards.reducer';
import { DASHBOARDS_FEATURE_KEY } from './dashboards.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    [DASHBOARDS_FEATURE_KEY]: dashboardsReducer.State;
}): dashboardsReducer.State => state[DASHBOARDS_FEATURE_KEY];

export const getDashboards = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.dashboards?.list
);

export const getDashboardById = createSelector(
    getState,
    (state: dashboardsReducer.State, props: { id: string }) =>
        state.dashboards?.list?.find((dashboard) => dashboard.id === props.id)
);

export const getDashboardsIsUpdating = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.dashboards?.isUpdating
);

export const getDashboardsIsLoading = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.dashboards?.isLoadingList
);

export const getDashboardsConfiguration = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.configuration?.configuration
);

export const getDashboardsConfigurationStatus = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.configuration?.status
);

export const getSelectedDashboard = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.selected
);

export const getMenuHidden = createSelector(
    getState,
    (state: dashboardsReducer.State) => state.menuHidden
);
