export enum IdentityVerificationUrlSegment {
    IdentityCheck = 'identityCheck',
    identityCheckReview = 'identityCheckReview',
    keystats = 'keystats',
    party = 'party',
    latest = 'latest',
    review = 'review',
    additional = 'additional',
    history = 'history',
    reject = 'reject',
    approve = 'approve',
}
