<molecule-data-list-wrapper
    [hasMobileFilter]="false"
    [hasFilterSearch]="false"
    searchType="Article"
    searchTypePlural="Articles"
    searchPlaceholder="Search articles"
    [filterHandler]="filterHandler"
    [isLoading]="loading$ | ngrxPush"
    [hasError]="hasError$ | ngrxPush"
    [totalRecordCount]="paging?.totalRecords"
    [sortMenu]="SORT_MENU_ARTICLES"
    [useSortToggleMenu]="true"
    [showFilters]="false"
    [showFilterBar]="true"
    [showSearchBar]="true"
    [showFavourite]="false"
    (infinityScrollFired)="onInfinityScrollFired()"
>
    <div
        class="data-list-content h-100"
        [class.responsive-horizontal-scroll]="responsiveScroll"
    >
        <div class="data-list-content__article-row row align-items-stretch">
            <ng-container
                *ngFor="
                    let articleListItem of articles$ | ngrxPush;
                    let last = last
                "
            >
                <molecule-article-card
                    class="data-list-content__card-container"
                    [responsiveColumns]="{ staticColumns: articlesPerRow }"
                    [disableResponsiveColumns]="listStyle !== LIST_STYLE.Grid"
                    [ngClass]="{
                        'p-1': listStyle === LIST_STYLE.Grid,
                        'col-12 py-1': listStyle === LIST_STYLE.List,
                        'suggested-article-list-item': isSuggestedCardList
                    }"
                    [article]="articleListItem"
                    [showArticleImage]="showArticleImage"
                    [showLinkButton]="showLinkButton"
                    [isLinked]="articleListItem.isLinked"
                    [linkToEntityType]="linkToEntityType"
                    [linkToEntityId]="linkToEntityId"
                    [listStyle]="listStyle"
                ></molecule-article-card>
            </ng-container>
            <div
                *ngIf="!articles$ || (articles$ | ngrxPush)?.length === 0"
                class="d-flex align-items-center justify-content-center w-100 h-100"
            >
                <atom-feature-svg class="bg-body" [svg]="FEATURE_SVG.NoData">
                    No records found
                </atom-feature-svg>
            </div>
        </div>
    </div>
</molecule-data-list-wrapper>
