import { SortDirection } from '@wdx/clmi/api-models';
import {
    NavItem,
    TRANSLATION_SORT_DUE,
    TRANSLATION_SORT_MENU_ALPHABETICAL,
    TRANSLATION_SORT_MENU_ALPHABETICAL_A_Z,
    TRANSLATION_SORT_MENU_ALPHABETICAL_Z_A,
    TRANSLATION_SORT_MENU_CLIENT_VALUE,
    TRANSLATION_SORT_MENU_CREATED_ON,
    TRANSLATION_SORT_MENU_DATE_PUBLISHED,
    TRANSLATION_SORT_MENU_ESTIMATED_CLOSE,
    TRANSLATION_SORT_MENU_NAME,
    TRANSLATION_SORT_MENU_NEXT_ACTION_DUE,
    TRANSLATION_SORT_MENU_RECENTLY_ADDED,
    TRANSLATION_SORT_MENU_RECENTLY_UPLOADED,
    TRANSLATION_SORT_MENU_URGENCY,
    TRANSLATION_SORT_MENU_VALUE,
} from '@wdx/shared/utils';
import { SortMenuSortBy, SortMenuValue } from '../models/sort-menu.model';

export enum FeedSortByType {
    Value = 'Value',
    Urgency = 'Urgency',
    ManageClient = 'ManageClient',
    StayConnected = 'StayConnected',
    StayCompliant = 'StayCompliant',
    GrowBusiness = 'GrowBusiness',
}

export const SORT_MENU_ARTICLES: NavItem<SortMenuValue>[] = [
    {
        translationKey: TRANSLATION_SORT_MENU_ALPHABETICAL,
        value: { sortBy: SortMenuSortBy.Name },
    },
    {
        translationKey: TRANSLATION_SORT_MENU_DATE_PUBLISHED,
        value: { sortBy: SortMenuSortBy.PublishedOn },
    },
];

export const SORT_MENU_CASES: NavItem<SortMenuValue>[] = [
    {
        translationKey: TRANSLATION_SORT_MENU_CREATED_ON,
        cySelector: 'span-created-on',
        value: {
            sortBy: SortMenuSortBy.CreatedOn,
        },
    },

    {
        translationKey: TRANSLATION_SORT_DUE,
        cySelector: 'span-date-due',
        value: {
            sortBy: SortMenuSortBy.DueDate,
        },
    },
    {
        translationKey: TRANSLATION_SORT_MENU_NEXT_ACTION_DUE,
        cySelector: 'span-next-action',
        value: {
            sortBy: SortMenuSortBy.NextActionDue,
        },
    },
];

export const SORT_MENU_DOCUMENTS: NavItem[] = [
    {
        value: 'DocumentListSortByType.Uploaded',
        translationKey: TRANSLATION_SORT_MENU_RECENTLY_UPLOADED,
    },
    {
        value: 'DocumentListSortByType.Alphabetical',
        translationKey: TRANSLATION_SORT_MENU_ALPHABETICAL_A_Z,
    },
    {
        value: 'DocumentListSortByType.AlphabeticalDesc',
        translationKey: TRANSLATION_SORT_MENU_ALPHABETICAL_Z_A,
    },
];

export const SORT_MENU_FEED: NavItem<FeedSortByType>[] = [
    {
        translationKey: TRANSLATION_SORT_MENU_CLIENT_VALUE,
        value: FeedSortByType.Value,
    },
    {
        translationKey: TRANSLATION_SORT_MENU_URGENCY,
        value: FeedSortByType.Urgency,
    },
];

export const SORT_MENU_NOTES: NavItem[] = [
    {
        value: 'dateCreated',
        translationKey: TRANSLATION_SORT_MENU_RECENTLY_ADDED,
    },
];

export const SORT_MENU_OPPORTUNITIES: NavItem<SortMenuValue>[] = [
    {
        translationKey: TRANSLATION_SORT_MENU_ESTIMATED_CLOSE,
        value: {
            sortBy: SortMenuSortBy.EstimatedCloseDate,
            sortDirection: SortDirection.Descending,
        },
    },
    {
        translationKey: TRANSLATION_SORT_MENU_VALUE,
        value: {
            sortBy: SortMenuSortBy.Pipeline,
            sortDirection: SortDirection.Descending,
        },
    },
    {
        translationKey: TRANSLATION_SORT_MENU_NAME,
        value: {
            sortBy: SortMenuSortBy.Name,
            sortDirection: SortDirection.Ascending,
        },
    },
];
