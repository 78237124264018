import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { DirectivesModule } from '../../../directives/directives.module';
import { AtomAddButtonModule } from '../../atoms/atom-add-button/atom-add-button.module';
import { MoleculeAddInfoCardComponent } from './molecule-add-info-card.component';

@NgModule({
    imports: [
        AtomAddButtonModule,
        CommonModule,
        DirectivesModule,
        NgbPopoverModule,
        WdxIconModule,
    ],
    declarations: [MoleculeAddInfoCardComponent],
    exports: [MoleculeAddInfoCardComponent],
})
export class MoleculeAddInfoCardModule {}
