<a
    class="nav-link pointer"
    [attr.data-cy]="cySelector"
    [class.active]="active"
    [class.disabled]="disabled"
    [attr.data-cy-value]="cyValue"
>
    <div class="d-flex align-items-center">
        <wdx-icon *ngIf="icon" [icon]="icon"></wdx-icon>
        <div>{{ label }}</div>
    </div>
</a>
