import { createSelector } from '@ngrx/store';
import * as documentTemplatesReducer from './document-templates.reducer';

/**
 * Reducer state selector
 */
// eslint-disable-next-line max-len
export const getState = (state: {
    documentTemplates: documentTemplatesReducer.State;
}): documentTemplatesReducer.State => state.documentTemplates;

export const getDocumentTemplatesList = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentTemplatesReducer.State) =>
            state.documentTemplates[props.id]?.list
    );
export const getDocumentTemplatesListIsLoading = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentTemplatesReducer.State) =>
            state.documentTemplates[props.id]?.isLoadingList
    );
export const getDocumentTemplatesLisHasError = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentTemplatesReducer.State) =>
            state.documentTemplates[props.id]?.hasLoadingListError
    );

/**
 * Reducer property selectors
 */
