import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { userSelectors } from '@wdx/clmi/api-services/services';
import * as rootReducer from '../../../state/_setup/reducers';
import * as authenticationActions from '../../../state/authentication/authentication.actions';

@Injectable({
    providedIn: 'root',
})
export class SessionFacade {
    privileges$: Observable<string[]>;

    constructor(private store$: Store<rootReducer.State>) {
        this.privileges$ = this.store$.select(
            userSelectors.getPrivilegesSelector
        );
    }

    logout() {
        this.store$.dispatch(authenticationActions.logout());
    }
}
