import { createSelector } from '@ngrx/store';
import * as filesReducer from './files.reducer';

export const getState = (
    state: filesReducer.FileStoreSlice
): filesReducer.State => state[filesReducer.FEATURE_KEY];

export const getFileUploadStatus = createSelector(
    getState,
    (state: filesReducer.State, props: any) =>
        state.uploads[props.id]?.uploadStatus
);
export const getFileUploadProgress = createSelector(
    getState,
    (state: filesReducer.State, props: any) => state.uploads[props.id]?.progress
);
export const getFileUploadFileIndex = createSelector(
    getState,
    (state: filesReducer.State, props: any) =>
        state.uploads[props.id]?.fileIndex
);
export const getFileUploadError = createSelector(
    getState,
    (state: filesReducer.State, props: any) => state.uploads[props.id]?.error
);
