export enum UserUrlSegment {
    USER = 'user',
    ME = 'me',
    LOCALE = 'locale',
    KPI = 'kpi',
    FORMDATA = 'formdata',
    USERFORM = 'userform',
    HISTORY = 'history',
    PASSWORD = 'password',
    TENANT = 'tenant',
    DEFAULT = 'default',
    LANGUAGE = 'language',
    CURRENCY = 'currency',
}
