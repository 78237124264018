import { createSelector } from '@ngrx/store';
import * as countriesReducer from './countries.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    countries: countriesReducer.State;
}): countriesReducer.State => state.countries;

/**
 * Reducer property selectors
 */
export const getIsLoadingList = createSelector(
    getState,
    (state: countriesReducer.State, props: any) =>
        state.countries[props.id]?.isLoadingList
);
export const getHasLoadingListError = createSelector(
    getState,
    (state: countriesReducer.State, props: any) =>
        state.countries[props.id]?.hasLoadingListError
);
export const getList = createSelector(
    getState,
    (state: countriesReducer.State, props: any) =>
        state.countries[props.id]?.list
);
