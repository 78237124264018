import { Component, Input, TemplateRef } from '@angular/core';
import { Icon } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-tab',
    templateUrl: './atom-tab.component.html',
})
export class AtomTabComponent {
    @Input() active: boolean;
    @Input() label: string;
    @Input() link: string;
    @Input() icon: Icon | string;
    @Input() disabled: boolean;
    @Input() cySelector?: string;
    @Input() content: TemplateRef<any>;
    @Input() cyValue?: string;
}
