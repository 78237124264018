import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { PushModule } from '@ngrx/component';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { MessageComponent } from '../../../shared/features/message/message.component';

@NgModule({
    declarations: [MessageComponent],
    imports: [
        CommonModule,
        NgbAlertModule,
        PushModule,
        RouterModule,
        WdxIconModule,
    ],
    exports: [MessageComponent],
})
export class MessageModule {}
