import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as relationshipsActions from './relationships.actions';
import { RelationshipsService } from './relationships.service';

@Injectable()
export class RelationshipsEffects {
    private actions$ = inject(Actions);
    private relationshipsService = inject(RelationshipsService);

    getForPartyRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relationshipsActions.getForPartyRole),
            mergeMap((action) =>
                this.relationshipsService
                    .getForPartyRole(action.partyRoleId)
                    .pipe(
                        map((relationshipWrapper) =>
                            relationshipsActions.getForPartyRoleSuccess({
                                partyRoleId: action.partyRoleId,
                                relationshipWrapper,
                            })
                        ),
                        catchError((error) =>
                            of(
                                relationshipsActions.getForPartyRoleFailure({
                                    partyRoleId: action.partyRoleId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getFamilyForClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relationshipsActions.getFamilyForClient),
            switchMap((action) =>
                this.relationshipsService
                    .getFamilyForClient(action.clientId)
                    .pipe(
                        map((familyRelationships) =>
                            relationshipsActions.getFamilyForClientSuccess({
                                familyRelationships,
                                clientId: action.clientId,
                            })
                        ),
                        catchError((error) =>
                            of(
                                relationshipsActions.getFamilyForClientFailure({
                                    clientId: action.clientId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getApplicableFamilyRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relationshipsActions.getApplicableFamilyRoles),
            switchMap((action) =>
                this.relationshipsService
                    .getApplicableFamilyRoles(action.clientId)
                    .pipe(
                        map((applicableFamilyRoles) =>
                            relationshipsActions.getApplicableFamilyRolesSuccess(
                                {
                                    applicableFamilyRoles,
                                    clientId: action.clientId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relationshipsActions.getApplicableFamilyRolesFailure(
                                    {
                                        clientId: action.clientId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
