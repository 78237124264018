import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    Severity,
    TenantsService,
    TRANSLATION_TOAST_MESSAGE_ACCESS_GRANTED,
    TRANSLATION_TOAST_MESSAGE_CONSTENT_ERROR,
    TranslationsService,
} from '@wdx/shared/utils';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfigService } from '../../../services/config.service';

const CONSENT_WINDOW_KEY = 'consent-window';

@Injectable({ providedIn: 'root' })
export class ConsentService {
    private openedWindow: Window | null = null;

    private translationsService = inject(TranslationsService);

    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private wdxToastService: WdxToastService,
        private authenticationService: AuthenticationService,
        private tenantsService: TenantsService
    ) {}

    setConsent(
        params: Params,
        token: string | GetTokenSilentlyVerboseResponse
    ): Observable<string> {
        return this.http.post<string>(
            `${this.config.getConfiguration().API_BASE}/consent`,
            params,
            {
                headers: {
                    'x-clmi-tenant': this.tenantsService.tenantCode,
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }

    setConsentWindow(window: Window): void {
        this.openedWindow = window;
        const checkConsentInterval = setInterval(() => {
            const consentParams = JSON.parse(
                localStorage.getItem(CONSENT_WINDOW_KEY)
            );
            if (consentParams) {
                localStorage.removeItem(CONSENT_WINDOW_KEY);
                clearInterval(checkConsentInterval);
                this.sendConsentParams(consentParams);
            }
        }, 1000);
    }

    closeConsentWindow(): void {
        if (this.openedWindow) {
            this.openedWindow.close();
            this.openedWindow = null;
        }
    }

    sendConsentParams(params: Params): void {
        const severity = params?.error ? Severity.Danger : Severity.Success;
        const message = params?.error
            ? `${this.translationsService.getTranslationByKey(
                  TRANSLATION_TOAST_MESSAGE_CONSTENT_ERROR
              )} ${params?.error.toLowerCase()} ${params?.error_subcode?.toLowerCase()}`
            : this.translationsService.getTranslationByKey(
                  TRANSLATION_TOAST_MESSAGE_ACCESS_GRANTED
              );
        this.wdxToastService.show({
            body: [message],
            severity,
        });
        this.authenticationService
            .getTokenSilently$()
            .pipe(
                switchMap((token) =>
                    this.setConsent(params, token).pipe(
                        tap(() => {
                            this.closeConsentWindow();
                        }),
                        catchError(() => {
                            this.closeConsentWindow();
                            return of(true);
                        }),
                        take(1)
                    )
                )
            )
            .subscribe();
    }

    getConsentParams(): Promise<void> {
        return new Promise((resolve) => {
            if (window.location.href.includes('/consent')) {
                const queryParams = new URLSearchParams(window.location.search);
                const params: { [key: string]: string } = {};
                queryParams.forEach((value, key) => {
                    params[key] = value;
                });
                localStorage.setItem(
                    CONSENT_WINDOW_KEY,
                    JSON.stringify(params)
                );
                resolve();
            } else {
                resolve();
            }
        });
    }
}
