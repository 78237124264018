import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import {
    FormDataStatus,
    FormSaveMode,
    Severity,
    SignalrEvent,
} from '@wdx/shared/utils';
import { mergeMapHubToAction, signalrConnected } from 'ngrx-signalr-core';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as dynamicFormsActions from './dynamic-forms.actions';
import { DynamicFormsService } from './dynamic-forms.service';

@Injectable()
export class DynamicFormsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store);
    private dynamicFormsService = inject(DynamicFormsService);
    private toastService = inject(WdxToastService);

    getFormDefinitions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.getFormDefinitions),
            mergeMap(() =>
                this.dynamicFormsService.getFormDefinitions().pipe(
                    map((formDefinitions) =>
                        dynamicFormsActions.getFormDefinitionsSuccess({
                            formDefinitions,
                        })
                    ),
                    catchError((error) =>
                        of(
                            dynamicFormsActions.getFormDefinitionsFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getFormDefinition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.getFormDefinition),
            mergeMap((action) =>
                this.dynamicFormsService.getFormDefinition(action.formId).pipe(
                    map((formDefinition) =>
                        dynamicFormsActions.getFormDefinitionSuccess({
                            formId: action.formId,
                            formDefinition,
                        })
                    ),
                    catchError((error) =>
                        of(
                            dynamicFormsActions.getFormDefinitionFailure({
                                formId: action.formId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getFormData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.getFormData),
            mergeMap((action) =>
                this.dynamicFormsService
                    .getFormData(
                        action.formId,
                        action.entityId,
                        action.endpointPath,
                        action.queryParams
                    )
                    .pipe(
                        map((formData) =>
                            dynamicFormsActions.getFormDataSuccess({
                                formId: action.formId,
                                entityId: action.entityId,
                                formData,
                            })
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.getFormDataFailure({
                                    formId: action.formId,
                                    entityId: action.entityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getFormInitialisationData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.getFormInitialisationData),
            mergeMap((action) =>
                this.dynamicFormsService
                    .getFormInitialisationData(
                        action.formId,
                        action.initialisationParams || {}
                    )
                    .pipe(
                        map((formData) =>
                            dynamicFormsActions.getFormInitialisationDataSuccess(
                                {
                                    formId: action.formId,
                                    formData,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.getFormInitialisationDataFailure(
                                    {
                                        formId: action.formId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getFormFunctionResult$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.getFormFunctionResult),
            mergeMap((action) =>
                this.dynamicFormsService
                    .getFormFunctionResult(
                        action.formId,
                        action.apiFunctionTriggerPayload
                    )
                    .pipe(
                        map((formFunctionResult) =>
                            dynamicFormsActions.getFormFunctionResultSuccess({
                                formId: action.formId,
                                apiFunctionTriggerPayload:
                                    action.apiFunctionTriggerPayload,
                                formFunctionResult,
                            })
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.getFormFunctionResultFailure(
                                    {
                                        formId: action.formId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getFormTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.getFormTemplates),
            mergeMap((action) =>
                this.dynamicFormsService
                    .getFormTemplates(action.entityType, action.entityId)
                    .pipe(
                        map((formTemplates) =>
                            dynamicFormsActions.getFormTemplatesSuccess({
                                entityId: action.entityId,
                                formTemplates,
                            })
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.getFormTemplatesFailure({
                                    entityId: action.entityId,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getFormTemplateInstance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.getFormTemplateInstance),
            mergeMap((action) =>
                this.dynamicFormsService
                    .getFormTemplateInstance(
                        action.formTemplateId,
                        action.entityType,
                        action.entityId
                    )
                    .pipe(
                        map((formTemplateInstance) =>
                            dynamicFormsActions.getFormTemplateInstanceSuccess({
                                entityId: action.entityId,
                                formTemplateInstance,
                            })
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.getFormTemplateInstanceFailure(
                                    {
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    saveCompletionSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.saveCompletionSummary),
            mergeMap((action) =>
                this.dynamicFormsService
                    .saveCompletionSummary(
                        action.formId,
                        action.entityId,
                        action.completionSummary
                    )
                    .pipe(
                        map(() =>
                            dynamicFormsActions.saveCompletionSummarySuccess({
                                formId: action.formId,
                                entityId: action.entityId,
                                completionSummary: action.completionSummary,
                            })
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.saveCompletionSummaryFailure(
                                    {
                                        formId: action.formId,
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    createNonStandardFormData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.createNonStandardFormData),
            mergeMap((action) =>
                this.dynamicFormsService
                    .createNonStandardFormData(
                        action.formData,
                        action.endpointPath
                    )
                    .pipe(
                        map((formDataInstance) =>
                            dynamicFormsActions.createNonStandardFormDataSuccess(
                                {
                                    formId: action.formId,
                                    formDataInstance,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.createNonStandardFormDataFailure(
                                    {
                                        formId: action.formId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    putCustomEndpointFormData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.putCustomEndpointFormData),
            mergeMap((action) =>
                this.dynamicFormsService
                    .putCustomEndpointFormData(
                        action.formData,
                        action.customEndpoint
                    )
                    .pipe(
                        map((response) =>
                            dynamicFormsActions.putCustomEndpointFormDataSuccess(
                                {
                                    formId: action.formId,
                                    response,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.putCustomEndpointFormDataFailure(
                                    {
                                        formId: action.formId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    updateNonStandardFormData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.updateNonStandardFormData),
            mergeMap((action) =>
                this.dynamicFormsService
                    .updateNonStandardFormData(
                        action.entityId,
                        action.formData,
                        action.endpointPath
                    )
                    .pipe(
                        map((response) =>
                            dynamicFormsActions.updateNonStandardFormDataSuccess(
                                {
                                    formId: action.formId,
                                    response,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.updateNonStandardFormDataFailure(
                                    {
                                        formId: action.formId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    updateStandardFormData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dynamicFormsActions.updateStandardFormData),
            switchMap((action) =>
                this.dynamicFormsService
                    .updateStandardFormData(
                        action.formId,
                        action.entityId,
                        action.formData,
                        action.publish,
                        action.ignoreDuplicates,
                        action.queryParams
                    )
                    .pipe(
                        map((response) =>
                            dynamicFormsActions.updateStandardFormDataSuccess({
                                formId: action.formId,
                                response,
                                saveMode: action.saveMode,
                            })
                        ),
                        catchError((error) =>
                            of(
                                dynamicFormsActions.updateStandardFormDataFailure(
                                    {
                                        formId: action.formId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    updateStandardFormDataSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(dynamicFormsActions.updateStandardFormDataSuccess),
                tap((action) => {
                    const { saveMode, response } = action;
                    if (
                        saveMode?.includes(FormSaveMode.Draft) &&
                        response?.status === FormDataStatus.Draft
                    ) {
                        this.toastService.show({
                            body: ['Draft saved successfully'],
                            severity: Severity.Success,
                        });
                    }
                })
            ),
        { dispatch: false }
    );

    listenToEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signalrConnected),
            mergeMapHubToAction(({ hub }) =>
                merge(
                    hub
                        .on(SignalrEvent.BulkJobCompleted)
                        .pipe(map(() => dynamicFormsActions.bulkJobCompleted()))
                )
            )
        )
    );
}
