import { Injectable } from '@angular/core';
import {
    DataChangeActionType,
    PendingChangeStatusType,
} from '@wdx/shared/utils';
import { IFormPendingChange, IFormPendingChangeSubForm } from '../../constants';
import {
    IExtendedFieldDefinition,
    ReactiveFormElement,
    ReactiveFormLayoutAndDefinition,
} from '../../models';

import {
    PENDING_CHANGE_REG,
    PENDING_CHANGE_SPLIT,
    UPDATE_PENDING_CHANGE_COUNTER,
} from './form-pending-changes.helper';
import { PENDING_CHANGE_LOCK_ALL } from './form-pending-changes.interface';

@Injectable()
export class FormPendingChangesService {
    subFormPendingChanges: {
        [key: string]: {
            [key: string]: any;
        };
    } = {};
    pendingChangesSubFormCounter: IFormPendingChangeSubForm = {};
    coc: IFormPendingChange[] = [];

    setUpPendingChanges(
        layoutAndDefinition: ReactiveFormElement[],
        formBuilderFirstLevel: any,
        pendingChanges: IFormPendingChange[] | undefined
    ): void {
        this.coc = Array.isArray(pendingChanges) ? [...pendingChanges] : [];
        this.updateSubFormPendingChanges();

        if (pendingChanges && layoutAndDefinition.length) {
            layoutAndDefinition?.forEach((layoutAndDefinition) => {
                layoutAndDefinition?.layoutAndDefinition?.forEach(
                    (layoutAndDefinition) => {
                        const HAS_SUB_FORM_PENDING_CHANGE =
                            !!this.subFormPendingChanges[
                                layoutAndDefinition?.name as string
                            ];

                        if (HAS_SUB_FORM_PENDING_CHANGE) {
                            this.updateSubFormPendingChangeRows(
                                layoutAndDefinition,
                                formBuilderFirstLevel
                            );
                        }

                        if (!HAS_SUB_FORM_PENDING_CHANGE) {
                            this.updatePendingChange(layoutAndDefinition);
                        }
                    }
                );
            });
        }

        this.coc = this.coc.filter((change) => change.name);
    }

    updateSubFormPendingChanges(): void {
        const removeItems: any[] = [];
        const PENDING_CHANGES = this.coc;

        PENDING_CHANGES?.forEach((pendingChange, index) => {
            // Check if sub form field is been updated
            if (pendingChange.field?.match(PENDING_CHANGE_REG)) {
                removeItems.push(index);
                const PENDING = PENDING_CHANGE_SPLIT(pendingChange.field);

                this.addDataToSubFormPendingChanges(
                    PENDING[0],
                    parseInt(PENDING[1], 10),
                    {
                        ...pendingChange,
                        field: PENDING[2],
                        name: PENDING[2],
                        ...(PENDING?.[3] ? { subItems: PENDING?.[3] } : {}),
                    }
                );
            }
        });

        removeItems.reverse().forEach((index) => {
            this.coc?.splice(index, 1);
        });
    }

    addDataToSubFormPendingChanges(
        arrayName: string,
        index: number,
        field?: any
    ): void {
        const CHANGE_ENTIRE_ROW = [
            DataChangeActionType.Add,
            DataChangeActionType.Remove,
        ].includes(field.action);

        // For these the entire row is been changed
        if (CHANGE_ENTIRE_ROW) {
            this.subFormPendingChanges = {
                ...this.subFormPendingChanges,
                [arrayName]: {
                    ...this.subFormPendingChanges[arrayName],
                    [index]: field,
                },
            };
            this.updatePendingChangeCounter(arrayName, field.action, index);
        }

        // These are fields that are been updated in the row
        if (field.name) {
            this.subFormPendingChanges = {
                ...this.subFormPendingChanges,
                [arrayName]: {
                    ...this.subFormPendingChanges[arrayName],
                    [index]: {
                        ...field,
                        ...{
                            subItems: [
                                ...(this.subFormPendingChanges[arrayName]?.[
                                    index
                                ]?.subItems
                                    ? this.subFormPendingChanges[arrayName][
                                          index
                                      ].subItems
                                    : []),
                                field.subItems,
                            ],
                        },
                    },
                },
            };

            this.updatePendingChangeCounter(
                arrayName,
                DataChangeActionType.Update,
                index
            );
        }
    }

    updateSubFormPendingChangeRows(
        layoutAndDefinition: ReactiveFormLayoutAndDefinition,
        formBuilderFirstLevel: any
    ): void {
        const SUB_FORM_ROW =
            this.subFormPendingChanges[layoutAndDefinition?.name as string];

        Object.keys(SUB_FORM_ROW).forEach((key: any) => {
            const SUB_FORM_ROW_FIELD_DEFINITIONS = layoutAndDefinition
                ?.children?.[0]?.layoutAndDefinition?.[
                key as any
            ] as unknown as IExtendedFieldDefinition[];

            const INDEX_OBJ =
                this.subFormPendingChanges[layoutAndDefinition?.name as string][
                    key
                ];

            const UPDATE_ALL = PENDING_CHANGE_LOCK_ALL.includes(
                INDEX_OBJ.action
            );

            this.updatePendingChangeCounter(
                layoutAndDefinition?.name as string,
                null as any,
                key as number,
                layoutAndDefinition.label
            );

            SUB_FORM_ROW_FIELD_DEFINITIONS.forEach((field: any) => {
                formBuilderFirstLevel[
                    layoutAndDefinition?.name as string
                ].pendingChanges[key].action = INDEX_OBJ.action;

                if (UPDATE_ALL) {
                    field.pendingChange = {
                        name: field.label,
                        // this status has been set to Submitted to lock the form fields
                        status: PendingChangeStatusType.Submitted,
                    };
                }

                if (!UPDATE_ALL && field.name === INDEX_OBJ?.name) {
                    field.pendingChange = {
                        ...INDEX_OBJ,
                        name: field.label,
                        index: key,
                        subItems: INDEX_OBJ?.subItems,
                    };
                }
            });
        });
    }

    updatePendingChange(
        layoutAndDefinition: ReactiveFormLayoutAndDefinition
    ): void {
        this.coc?.forEach((pendingChange) => {
            if (pendingChange.field === layoutAndDefinition?.name) {
                pendingChange.name = layoutAndDefinition.label;
                layoutAndDefinition.pendingChange = pendingChange;
            }
        });
    }

    updatePendingChangeCounter(
        name: string,
        type: DataChangeActionType,
        index: number,
        label?: string
    ): void {
        this.pendingChangesSubFormCounter = UPDATE_PENDING_CHANGE_COUNTER(
            this.pendingChangesSubFormCounter,
            name,
            type,
            label,
            index
        );
    }
}
