export enum ModalSize {
    Sm = 'sm',
    Md = 'md',
    Lg = 'lg',
    Xl = 'xl',
}

export enum ModalSide {
    Left = 'left',
    Right = 'right',
}

export interface Modal {
    id: string;
    parameters?: { [key: string]: string };
}

export interface ModalOptions {
    size?: ModalSize;
    side?: ModalSide;
}
