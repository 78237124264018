import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomIconButtonModule } from '../../../components/atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeContextMenuDropdownModule } from '../../../components/molecules/molecule-context-menu-dropdown/molecule-context-menu-dropdown.module';
import { MoleculeSortMenuComponent } from './molecule-sort-menu.component';

@NgModule({
    imports: [
        AtomIconButtonModule,
        CommonModule,
        MoleculeContextMenuDropdownModule,
    ],
    declarations: [MoleculeSortMenuComponent],
    exports: [MoleculeSortMenuComponent],
})
export class MoleculeSortMenuModule {}
