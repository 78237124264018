import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogicResult, SystemEntity } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class AssignService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    assignTo(
        entityType: SystemEntity,
        entityId: string,
        partyId: string
    ): Observable<any> {
        return this.http.patch<any>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/${entityId}/assign/${partyId}`,
            {}
        );
    }

    bulkAssignTo(
        entityType: SystemEntity,
        entityIds: string[],
        partyId: string
    ): Observable<LogicResult[]> {
        return this.http.post<LogicResult[]>(
            `${
                this.config.getConfiguration().API_BASE
            }/${entityType.toLowerCase()}/assign/${partyId}`,
            entityIds
        );
    }
}
