import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OxfordRiskApiService } from '@wdx/clmi/api-services/services';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import * as oxfordRiskActions from './oxford-risk.actions';

@Injectable()
export class OxfordRiskEffects {
    private actions$ = inject(Actions);
    private oxfordRiskService = inject(OxfordRiskApiService);

    createRiskAssessmentClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(oxfordRiskActions.createRiskAssessmentClient),
            switchMap(({ partyId }) =>
                this.oxfordRiskService.createRiskAssessmentClient(partyId).pipe(
                    map((riskAssessmentClient) =>
                        oxfordRiskActions.createRiskAssessmentClientSuccess({
                            partyId,
                            riskAssessmentClient,
                        })
                    ),
                    catchError((error) =>
                        of(
                            oxfordRiskActions.createRiskAssessmentClientFailure(
                                {
                                    partyId,
                                    error,
                                }
                            )
                        )
                    )
                )
            )
        )
    );

    getRiskAssessmentClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(oxfordRiskActions.getRiskAssessmentClient),
            switchMap(({ partyId }) =>
                this.oxfordRiskService.getRiskAssessmentClient(partyId).pipe(
                    map((riskAssessmentClient) =>
                        oxfordRiskActions.getRiskAssessmentClientSuccess({
                            partyId,
                            riskAssessmentClient,
                        })
                    ),
                    catchError((error) => {
                        if (error?.status === 404) {
                            return of(
                                oxfordRiskActions.getRiskAssessmentClientNotFound(
                                    { partyId }
                                )
                            );
                        }
                        return of(
                            oxfordRiskActions.getRiskAssessmentClientFailure({
                                partyId,
                                error,
                            })
                        );
                    })
                )
            )
        )
    );

    getRiskAssessmentAdvisers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(oxfordRiskActions.getRiskAssessmentAdvisers),
            switchMap(() =>
                this.oxfordRiskService.getRiskAssessmentAdvisers().pipe(
                    map((riskAssessmentAdvisers) =>
                        oxfordRiskActions.getRiskAssessmentAdvisersSuccess({
                            riskAssessmentAdvisers,
                        })
                    ),
                    catchError((error) =>
                        of(
                            oxfordRiskActions.getRiskAssessmentAdvisersFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getLatestAssessmentForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(oxfordRiskActions.getLatestAssessmentForParty),
            switchMap(({ partyId }) =>
                this.oxfordRiskService
                    .getLatestAssessmentForParty(partyId)
                    .pipe(
                        map((riskAssessment) =>
                            oxfordRiskActions.getLatestAssessmentForPartySuccess(
                                { partyId, riskAssessment }
                            )
                        ),
                        catchError((error) =>
                            of(
                                oxfordRiskActions.getLatestAssessmentForPartyFailure(
                                    { partyId, error }
                                )
                            )
                        )
                    )
            )
        )
    );

    getLatestReportForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(oxfordRiskActions.getLatestReportForParty),
            switchMap(({ partyId, reportType, snapshotId }) =>
                this.oxfordRiskService
                    .getLatestReportForParty(partyId, reportType, snapshotId)
                    .pipe(
                        map((report) =>
                            oxfordRiskActions.getLatestReportForPartySuccess({
                                partyId,
                                reportType,
                                snapshotId,
                                report,
                            })
                        ),
                        catchError((error) =>
                            of(
                                oxfordRiskActions.getLatestReportForPartyFailure(
                                    { partyId, reportType, snapshotId, error }
                                )
                            )
                        )
                    )
            )
        )
    );

    setRiskAssessmentClientRiskLevelForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(oxfordRiskActions.setRiskAssessmentClientRiskLevelForParty),
            mergeMap(({ partyId, riskLevel }) =>
                this.oxfordRiskService
                    .setRiskAssessmentClientRiskLevelForParty(
                        partyId,
                        riskLevel
                    )
                    .pipe(
                        map(() =>
                            oxfordRiskActions.setRiskAssessmentClientRiskLevelForPartySuccess(
                                { partyId, riskLevel }
                            )
                        ),
                        catchError((error) =>
                            of(
                                oxfordRiskActions.setRiskAssessmentClientRiskLevelForPartyFailure(
                                    { partyId, error }
                                )
                            )
                        )
                    )
            )
        )
    );
}
