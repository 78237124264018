import { createAction, props } from '@ngrx/store';
import { Region, StatusType } from '@wdx/clmi/api-models';

export const getRegions = createAction(
    '[Regions] Get Regions',
    props<{
        reset?: boolean;
    }>()
);

export const getRegionsSuccess = createAction(
    '[Regions] Get Regions Success',
    props<{
        regions: Region[];
    }>()
);

export const getRegionsFailure = createAction(
    '[Regions] Get Regions Failure',
    props<{ error: Error }>()
);


export const disableRegion = createAction(
    '[Regions] Disable Region',
    props<{
        id: string;
        status: StatusType;
    }>()
);

export const disableRegionSuccess = createAction(
    '[Regions] Disable Region Success',
    props<{
        id: string;
        status: StatusType;
        updatedRegion: Region;
    }>()
);

export const disableRegionFailure = createAction(
    '[Regions] Disable Region Failure',
    props<{
        id: string;
    }>()
);

