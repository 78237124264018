import { Location } from '@angular/common';
import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SystemEntity, UserActionEntity } from '@wdx/clmi/api-models';
import { FilterQueryFacadeService } from '@wdx/clmi/api-services/state';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import { NavItem, PagingType, TranslationsService } from '@wdx/shared/utils';
import {
    ADD_TO_LIST_MENU_ITEM,
    VISIBILITY_MENU_ITEM,
} from '../../../constants/menu.constants';
import {
    CHAT_MENU_ITEM,
    OPEN_NEW_TAB_MENU_ITEM,
} from '../../../features/contacts/shared';
import { EntityRouteOpenNewTabPipe } from '../../../pipes/entity-route-open-new-tab.pipe';
import * as rootReducer from '../../../state/_setup/reducers';
import * as favouritesActions from '../../../state/favourites/favourites.actions';
import { RouteDataService } from '../../services';
import { EntityOperationsService } from '../../services/entity-operations';
import { AccessVisibilityService } from '../access-visibility/shared/services/access-visibility.service';
import { FilterQueryService } from '../form-filters';
import { FilterTableClass } from '../form-filters/shared/abstract-classes/filter-table/filter-table.class';

@Directive()
export abstract class FilterTableComponentClass extends FilterTableClass {
    @Input() isWidgetView: boolean;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        protected accessVisibilityService: AccessVisibilityService,
        protected store$: Store<rootReducer.State>,
        protected filterQueryService: FilterQueryService,
        protected filterQueryFacadeService: FilterQueryFacadeService,
        protected entityOperationsService: EntityOperationsService,
        protected cd: ChangeDetectorRef,
        protected routeDataService: RouteDataService,
        protected translationsService: TranslationsService,
        protected entityRouteService: EntityRouteService
    ) {
        super(
            filterQueryFacadeService,
            filterQueryService,
            entityOperationsService,
            cd,
            store$,
            routeDataService,
            translationsService
        );
        this.table.pagingType = PagingType.ServerPagination;
    }

    onToggleFavourite(isFavourite: boolean, { id, contactType }): void {
        this.store$.dispatch(
            favouritesActions.toggleFavourite({
                id: id,
                entity: <UserActionEntity>contactType,
                newValue: isFavourite,
            })
        );
    }

    onActionButtonMenuItemSelected(
        navItem: NavItem,
        { id, partyId, entityType }
    ): void {
        switch (navItem.label) {
            case CHAT_MENU_ITEM:
                navItem.id =
                    entityType === UserActionEntity.Lead ? partyId : id;
                entityType && this.openLinkInNewTab(entityType);
                break;
            case OPEN_NEW_TAB_MENU_ITEM:
                this.openLinkInNewTab(entityType, id);
                break;
            case ADD_TO_LIST_MENU_ITEM.label:
                // to do
                // this.openListModal(party);
                break;
            case VISIBILITY_MENU_ITEM.label:
                this.accessVisibilityService.open(
                    id,
                    entityType as SystemEntity
                );
                break;
            default:
                break;
        }
    }

    openLinkInNewTab(entity: string, id?: string): void {
        new EntityRouteOpenNewTabPipe(this.entityRouteService).transform(
            SystemEntity[entity] || UserActionEntity[entity],
            this.router,
            this.route,
            this.location,
            id
        );
    }
}
