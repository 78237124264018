import { NavItem } from '@wdx/shared/utils';

export class BaseMenuItemClass {
    get menuItem(): NavItem {
        return this.menuItem_;
    }
    private menuItem_: NavItem;
    constructor(menuItem: NavItem) {
        this.menuItem_ = menuItem;
    }
}
