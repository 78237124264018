import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WdxSize } from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-add-button',
    templateUrl: './atom-add-button.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'rounded-circle' },
})
export class AtomAddButtonComponent {
    @Input() type = 'icon';
    @Input() size: WdxSize = 'md';
    @Input() disabled: boolean;
    @Input() cySelector?: string;

    @Output() btnClicked = new EventEmitter<MouseEvent>();

    onClicked($event: MouseEvent): void {
        this.btnClicked.emit($event);
    }
}
