import { FormFieldType } from '@wdx/shared/utils';

export const ARRAY_SUMMARY_TYPES = [FormFieldType.Array];
export const DECIMAL_SUMMARY_TYPES = [FormFieldType.Decimal];
export const INTEGER_SUMMARY_TYPES = [FormFieldType.Integer];
export const CURRENCY_SUMMARY_TYPES = [FormFieldType.Currency];
export const PHONE_SUMMARY_TYPES = [FormFieldType.PhoneNumber];
export const ADDRESS_SUMMARY_TYPES = [FormFieldType.Address];
export const TEXT_TYPES = [FormFieldType.Text];
export const RICH_TEXT_SUMMARY_TYPES = [FormFieldType.RichText];

export const DATE_SUMMARY_TYPES = [FormFieldType.Date, FormFieldType.DateTime];
export const DATE_RANGE_SUMMARY_TYPES = [
    FormFieldType.DateRange,
    FormFieldType.DateTimeRange,
];

export const LOOKUP_SUMMARY_TYPES = [
    FormFieldType.LookupMulti,
    FormFieldType.LookupSingle,
];

export const OPTIONS_SUMMARY_TYPES = [
    FormFieldType.TextDropdown,
    FormFieldType.Select,
    FormFieldType.Multiselect,
    FormFieldType.Hierarchical,
];

export const TAGS_SUMMARY_TYPES = [
    FormFieldType.TagListMulti,
    FormFieldType.TagListSingle,
];

export const FILE_SUMMARY_TYPES = [
    FormFieldType.ImageUpload,
    FormFieldType.DocumentUpload,
];

export const SELECT_SUMMARY_TYPES = [FormFieldType.Select];

export const CUSTOM_SUMMARY_TYPES = [
    ...ARRAY_SUMMARY_TYPES,
    ...TAGS_SUMMARY_TYPES,
    ...PHONE_SUMMARY_TYPES,
    ...LOOKUP_SUMMARY_TYPES,
    ...FILE_SUMMARY_TYPES,
    ...ADDRESS_SUMMARY_TYPES,
    ...RICH_TEXT_SUMMARY_TYPES,
    ...OPTIONS_SUMMARY_TYPES,
    FormFieldType.DocumentLookup,
];
