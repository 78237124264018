import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as organisationsActions from './organisations.actions';
import { OrganisationsService } from './organisations.service';

@Injectable()
export class OrganisationsEffects {
    private actions$ = inject(Actions);
    private organisationsService = inject(OrganisationsService);

    getForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(organisationsActions.getForParty),
            mergeMap((action) =>
                this.organisationsService.getForParty(action.partyId).pipe(
                    map((organisation) =>
                        organisationsActions.getForPartySuccess({
                            partyId: action.partyId,
                            organisation,
                        })
                    ),
                    catchError((error) =>
                        of(
                            organisationsActions.getForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(organisationsActions.create),
            switchMap((action) =>
                this.organisationsService
                    .create(action.organisationCreateData)
                    .pipe(
                        map((organisation) =>
                            organisationsActions.createSuccess({ organisation })
                        ),
                        catchError((error) =>
                            of(organisationsActions.createFailure({ error }))
                        )
                    )
            )
        )
    );
}
