import { createSelector } from '@ngrx/store';

import * as userReducer from './user.reducer';

/**
 * Reducer state selector
 */
export const getState = (state: {
    user: userReducer.State;
}): userReducer.State => state.user;

/**
 * Reducer property selectors
 */
export const getIsLoadingMeSelector = createSelector(
    getState,
    (state: userReducer.State) => state.me?.isLoadingSingle
);

export const getHasLoadingMeErrorSelector = createSelector(
    getState,
    (state: userReducer.State) => state.me?.hasLoadingSingleError
);

export const getMeSelector = createSelector(
    getState,
    (state: userReducer.State) => state.me?.single
);

export const getMePrimaryPartySelector = createSelector(
    getState,
    (state: userReducer.State) =>
        state.me?.single?.partyRoles?.find((partyRole) => partyRole?.isPrimary)
            ?.party
);

export const getIsLoadingMeLocaleSelector = createSelector(
    getState,
    (state: userReducer.State) => state.meLocale?.isLoadingSingle
);

export const getHasLoadingMeLocaleErrorSelector = createSelector(
    getState,
    (state: userReducer.State) => state.meLocale?.hasLoadingSingleError
);

export const getMeLocaleSelector = createSelector(
    getState,
    (state: userReducer.State) => state.meLocale?.single
);

export const getForIdIsLoadingSelector = createSelector(
    getState,
    (state: userReducer.State, props: any) =>
        state.users?.[props.id]?.isLoadingSingle
);
export const getForIdHasLoadingErrorSelector = createSelector(
    getState,
    (state: userReducer.State, props: any) =>
        state.users?.[props.id]?.hasLoadingSingleError
);
export const getForId = createSelector(
    getState,
    (state: userReducer.State, props: any) => state.users?.[props.id]?.single
);

export const getHistoryForIdIsLoadingSelector = createSelector(
    getState,
    (state: userReducer.State, props: any) =>
        state.history?.[props.id]?.isLoadingList
);
export const getHistoryForIdHasLoadingErrorSelector = createSelector(
    getState,
    (state: userReducer.State, props: any) =>
        state.history?.[props.id]?.hasLoadingListError
);
export const getHistoryForIdSelector = createSelector(
    getState,
    (state: userReducer.State, props: any) => state.history?.[props.id]?.list
);

export const getKPIsForIdIsLoadingSelector = createSelector(
    getState,
    (state: userReducer.State, props: any) =>
        state.kpi?.[props.id]?.isLoadingSingle
);
export const getKPIsForIdHasLoadingErrorSelector = createSelector(
    getState,
    (state: userReducer.State, props: any) =>
        state.kpi?.[props.id]?.hasLoadingSingleError
);
export const getKPIsForIdSelector = createSelector(
    getState,
    (state: userReducer.State, props: any) => state.kpi?.[props.id]?.single
);

export const getPrivilegesSelector = createSelector(
    getState,
    (state: userReducer.State) => state.me?.single?.privileges
);

export const getRolesSelector = createSelector(
    getState,
    (state: userReducer.State) => state.me?.single?.partyRoles
);

export const isSystemAdminSelector = createSelector(
    getState,
    (state: userReducer.State) =>
        state.me?.single?.privileges?.some(
            (privilege) => privilege === ':Administrator'
        )
);
