import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AtomDropdownButtonComponent } from './atom-dropdown-button.component';

@NgModule({
    imports: [CommonModule, NgbPopoverModule, RouterModule],
    declarations: [AtomDropdownButtonComponent],
    exports: [AtomDropdownButtonComponent],
})
export class AtomDropdownButtonModule {}
