import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SystemEntity, UserActionEntity } from '@wdx/clmi/api-models';

import { EntityRouteService } from '@wdx/clmi/utils/services';
import { RoutableSystemEntity } from '../../../models/system-entity-action-props.model';
import { EntityRouteOpenNewTabPipe } from '../../../pipes/entity-route-open-new-tab.pipe';

@Injectable()
export class OpenInNewTabService {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private entityRouteService: EntityRouteService
    ) {}

    openInNewTab(
        entity: SystemEntity | UserActionEntity,
        id: string | number,
        regardingId?: string | number,
        subEntityType?: RoutableSystemEntity,
        subEntityId?: string | number
    ): void {
        new EntityRouteOpenNewTabPipe(this.entityRouteService).transform(
            entity,
            this.router,
            this.route,
            this.location,
            id,
            regardingId,
            null,
            subEntityType,
            subEntityId
        );
    }

    openURLInNewTab(url: string, target = '_blank'): void {
        window.open(url, target);
    }
}
