import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as accessActions from './access.actions';
import { AccessService } from './access.service';

@Injectable()
export class AccessEffects {
    private actions$ = inject(Actions);

    getForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(accessActions.getForParty),
            mergeMap((action) =>
                this.accessService.getForParty(action.partyId).pipe(
                    map((access) =>
                        accessActions.getForPartySuccess({
                            partyId: action.partyId,
                            access,
                        })
                    ),
                    catchError((error) =>
                        of(
                            accessActions.getForPartyFailure({
                                partyId: action.partyId,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(private accessService: AccessService) {}
}
