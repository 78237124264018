import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as adminRolesActions from './admin-roles.actions';
import { AdminRolesService } from './admin-roles.service';

@Injectable()
export class AdminRolesEffects {
    private actions$ = inject(Actions);
    private adminRolesService = inject(AdminRolesService);

    searchAdminRolesClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(adminRolesActions.searchRoles),
            switchMap((action) =>
                this.adminRolesService
                    .searchAdminRolesClient(action.filter)
                    .pipe(
                        map((roles) => {
                            return adminRolesActions.searchRolesSuccess({
                                roles,
                            });
                        }),
                        catchError((error) =>
                            of(
                                adminRolesActions.searchRolesFailure({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
