import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as globalSearchActions from './global-search.actions';
import { GlobalSearchService } from './global-search.service';

@Injectable()
export class GlobalSearchEffects {
    private actions$ = inject(Actions);
    private globalSearchService = inject(GlobalSearchService);

    getPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(globalSearchActions.getPage),
            switchMap((action) =>
                this.globalSearchService
                    .getPage(action.searchRequest, action.paginationOptions)
                    .pipe(
                        map((searchResults) =>
                            globalSearchActions.getPageSuccess({
                                searchResults: searchResults,
                                pageNumber: action.paginationOptions.pageNumber,
                            })
                        ),
                        catchError((error) =>
                            of(globalSearchActions.getPageFailure({ error }))
                        )
                    )
            )
        )
    );

    getEntityFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(globalSearchActions.getEntityFilters),
            switchMap(() =>
                this.globalSearchService.getEntityFilters().pipe(
                    map((filters) =>
                        globalSearchActions.getEntityFiltersSuccess({
                            entityFilters: filters,
                        })
                    ),
                    catchError((error) =>
                        of(
                            globalSearchActions.getEntityFiltersFailure({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
