import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { MoleculeWidgetContainerModule } from '../../../components/molecules/molecule-widget-container/molecule-widget-container.module';
import { OrganismCasesListModule } from '../../../components/organisms/organism-cases-list/organism-cases-list.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { WidgetCasesListComponent } from './widget-cases-list.component';

@NgModule({
    imports: [
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        MoleculeActionButtonModule,
        MoleculeWidgetContainerModule,
        OrganismCasesListModule,
        OrganismModalModule,
        PipesModule,
    ],
    declarations: [WidgetCasesListComponent],
    exports: [WidgetCasesListComponent],
})
export class WidgetCasesListModule {}
