import {
    AvatarInfoService,
    EnvelopeService,
    EventService,
    IdentityVerificationService,
    ImportEntitiesService,
    LicenseService,
    MailingInstructionsApiService,
    OperationsService,
    OxfordRiskApiService,
    RegionsService,
    SystemEntityApiService,
} from '@wdx/clmi/api-services/services';
import { DynamicFormsService } from '@wdx/shared/infrastructure/form-framework';
import { AccessService } from '../../state/access/access.service';
import { ActivitiesService } from '../../state/activities/activities.service';
import { AdminRolesService } from '../../state/admin-roles/admin-roles.service';
import { AmlReportsService } from '../../state/aml-reports/aml-reports.service';
import { AnswersService } from '../../state/answers/answers.service';
import { AppsService } from '../../state/apps/apps.service';
import { ArticlesService } from '../../state/articles/articles.service';
import { AssignService } from '../../state/assign/assign.service';
import { AuditsService } from '../../state/audits/audits.service';
import { AvatarFileUploadService } from '../../state/avatar-file-upload/avatar-file-upload.service';
import { AvatarProfileFileUploadService } from '../../state/avatar-profile-file-upload/avatar-profile-file-upload.service';
import { BatchListService } from '../../state/batch-list/batch-list.service';
import { BatchMessageService } from '../../state/batch-message/batch-message.service';
import { CaseTypesService } from '../../state/case-types/case-types.service';
import { CasesService } from '../../state/cases/cases.service';
import { ChatsService } from '../../state/chats/chats.service';
import { ClientProductsService } from '../../state/client-products/client-products.service';
import { ClientsService } from '../../state/clients/clients.service';
import { CommentsService } from '../../state/comments/comments.service';
import { ConnectionsService } from '../../state/connections/connections.service';
import { ContactPointsService } from '../../state/contact-points/contact-points.service';
import { CountriesService } from '../../state/countries/countries.service';
import { CurrenciesService } from '../../state/currencies/currencies.service';
import { DocumentChecklistService } from '../../state/document-checklist/document-checklist.service';
import { DocumentRequirementTypesService } from '../../state/document-requirement-types/document-requirement-types.service';
import { DocumentTemplatesService } from '../../state/document-templates/document-templates.service';
import { DocumentTypesApiService } from '../../state/document-types/document-types.service';
import { DocumentsService } from '../../state/documents/documents.service';
import { DuplicatesService } from '../../state/duplicates/duplicates.service';
import { EmailService } from '../../state/email/email.service';
import { ExportsService } from '../../state/exports/exports.service';
import { ExtractPdfService } from '../../state/extract-pdf/extract-pdf.service';
import { FavouritesService } from '../../state/favourites/favourites.service';
import { FeedsService } from '../../state/feeds/feeds.service';
import { GlobalSearchService } from '../../state/global-search/global-search.service';
import { GoalTypesService } from '../../state/goal-types/goal-types.service';
import { HttpService } from '../../state/http/http.service';
import { InvestmentAccountsService } from '../../state/investment-accounts/investment-accounts.service';
import { InvestmentDataService } from '../../state/investment-data/investment-data.service';
import { KanbanStagesService } from '../../state/kanban-stages/kanban-stages.service';
import { LeadsService } from '../../state/leads/leads.service';
import { ListsService } from '../../state/lists/lists.service';
import { LookupsService } from '../../state/lookups/lookups.service';
import { NotesService } from '../../state/notes/notes.service';
import { NotificationsService } from '../../state/notifications/notifications.service';
import { NudgeTypesService } from '../../state/nudge-types/nudge-types.service';
import { NudgesService } from '../../state/nudges/nudges.service';
import { OpportunitiesService } from '../../state/opportunities/opportunities.service';
import { OrganisationClientTypesService } from '../../state/organisation-client-types/organisation-client-types.service';
import { OrganisationsService } from '../../state/organisations/organisations.service';
import { OutcomesService } from '../../state/outcomes/outcomes.service';
import { PartiesService } from '../../state/parties/parties.service';
import { PartyRoleService } from '../../state/party-role/party-role.service';
import { PeopleService } from '../../state/people/people.service';
import { PersonalConnectionsService } from '../../state/personal-connections/personal-connections.service';
import { PortfolioService } from '../../state/portfolio/services/portfolio.service';
import { ProcessTypesService } from '../../state/process-types/process-types.service';
import { ProcessesService } from '../../state/processes/processes.service';
import { ProductTypesService } from '../../state/product-types/product-types.service';
import { ProfessionalConnectionsService } from '../../state/professional-connections/professional-connections.service';
import { ProspectsService } from '../../state/prospects/prospects.service';
import { QueuesService } from '../../state/queues/queues.service';
import { RecentRecordsService } from '../../state/recent-records/recent-records.service';
import { RelatedClientsService } from '../../state/related-clients/related-clients.service';
import { RelatedRecordsService } from '../../state/related-records/related-records.service';
import { RelationshipsService } from '../../state/relationships/relationships.service';
import { RemindersService } from '../../state/reminders/reminders.service';
import { RolesService } from '../../state/roles/roles.service';
import { SuggestedPartiesService } from '../../state/suggested-parties/suggested-parties.service';
import { TagsService } from '../../state/tags/tags.service';
import { TeamService } from '../../state/team/team.service';
import { WebhooksService } from '../../state/web-hooks/webhooks.service';
import { WorkItemsService } from '../../state/work-items/work-items.service';
import { AmlService } from '../aml/aml.service';
import { AnswersFacadeService } from '../answers/answers-facade/answers-facade.service';
import { MarketingService } from '../marketing/marketing.service';
import { ViewsFacadeService } from '../views/views-facade.service';
import { ViewTileService } from '../viewtile/viewtile.service';

export const rootServices = [
    AccessService,
    ActivitiesService,
    AdminRolesService,
    AmlReportsService,
    AmlService,
    AnswersFacadeService,
    AnswersService,
    AppsService,
    ArticlesService,
    AssignService,
    AuditsService,
    AvatarFileUploadService,
    AvatarInfoService,
    AvatarProfileFileUploadService,
    BatchListService,
    BatchMessageService,
    CasesService,
    CaseTypesService,
    ChatsService,
    ClientProductsService,
    ClientsService,
    CommentsService,
    ConnectionsService,
    ContactPointsService,
    CountriesService,
    CurrenciesService,
    DocumentChecklistService,
    DocumentRequirementTypesService,
    DocumentsService,
    DocumentTemplatesService,
    DocumentTypesApiService,
    DuplicatesService,
    DynamicFormsService,
    EmailService,
    EnvelopeService,
    ExportsService,
    ExtractPdfService,
    FavouritesService,
    FeedsService,
    GlobalSearchService,
    GoalTypesService,
    HttpService,
    IdentityVerificationService,
    InvestmentAccountsService,
    InvestmentDataService,
    KanbanStagesService,
    LeadsService,
    ListsService,
    LookupsService,
    MailingInstructionsApiService,
    MarketingService,
    NotesService,
    NotificationsService,
    NudgesService,
    NudgeTypesService,
    OperationsService,
    OpportunitiesService,
    OrganisationClientTypesService,
    OrganisationsService,
    OutcomesService,
    OxfordRiskApiService,
    PartiesService,
    PartyRoleService,
    PeopleService,
    PersonalConnectionsService,
    PortfolioService,
    ProcessesService,
    ProcessTypesService,
    ProductTypesService,
    ProfessionalConnectionsService,
    ProspectsService,
    QueuesService,
    RecentRecordsService,
    RelatedClientsService,
    RelatedRecordsService,
    RelationshipsService,
    RemindersService,
    RolesService,
    SuggestedPartiesService,
    TagsService,
    TeamService,
    ViewsFacadeService,
    ViewTileService,
    WebhooksService,
    WorkItemsService,
    SystemEntityApiService,
    RegionsService,
    ImportEntitiesService,
    LicenseService,
    EventService,
];
