export const enum INFINITY_SCROLL_CONTENT {
    Articles,
    Activities,
    Cases,
    ClientFeed,
    Documents,
    Duplicates,
    Feed,
    FormPipeDocuments,
    Notes,
    Notifications,
    Opportunities,
    Users,
}

export enum SCROLL_DIRECTION {
    UP = 'up',
    DOWN = 'down',
    UP_DOWN = 'up_down',
}
