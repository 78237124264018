import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    WdxButtonSize,
    WdxButtonStyle,
} from '@wdx/shared/components/wdx-button';
import { WdxRadioButtonGroupModule } from '../wdx-radio-button-group.module';
import { WdxRadioButtonGroupConfig } from '../wdx-radio-button-group.component';
import { CommonModule } from '@angular/common';
import { WdxRadioButtonModule } from '@wdx/shared/components/wdx-radio-button';

@Component({
    selector: 'wdx-radio-group-form-control',
    templateUrl: './wdx-radio-group-form-control.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: WdxRadioGroupFormControlComponent,
            multi: true,
        },
    ],
    imports: [
        CommonModule,
        WdxRadioButtonGroupModule,
        ReactiveFormsModule,
        WdxRadioButtonModule,
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxRadioGroupFormControlComponent implements ControlValueAccessor {
    @Input() buttonsConfig: WdxRadioButtonGroupConfig[] = [];
    @Input() size: WdxButtonSize = WdxButtonSize.Normal;

    public readonly WdxButtonStyle = WdxButtonStyle;

    public value?: string;

    public touched = false;

    public disabled = false;

    public onChange = (value: string) => value;

    public onTouched = () => undefined;

    public writeValue(value: string): void {
        this.value = value;
    }
    public registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    public selected(value: string) {
        this.markAsTouched();
        this.value = value;
        this.onChange(value);
    }

    private markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
}
