import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as rootReducer from '../_setup/reducers';
import * as relatedRecordsActions from './related-records.actions';
import { RelatedRecordsService } from './related-records.service';

@Injectable()
export class RelatedRecordsEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<rootReducer.State>);
    private relatedRecordsService = inject(RelatedRecordsService);

    getRelatedActivitiesForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.getRelatedActivitiesForEntity),
            mergeMap((action) =>
                this.relatedRecordsService
                    .getRelatedActivitiesForEntity(
                        action.entityType,
                        action.entityId
                    )
                    .pipe(
                        map((activities) =>
                            relatedRecordsActions.getRelatedActivitiesForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    activities,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.getRelatedActivitiesForEntityFailure(
                                    {
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getRelatedArticlesForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.getRelatedArticlesForEntity),
            mergeMap((action) =>
                this.relatedRecordsService
                    .getRelatedArticlesForEntity(
                        action.entityType,
                        action.entityId
                    )
                    .pipe(
                        map((articles) =>
                            relatedRecordsActions.getRelatedArticlesForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    articles,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.getRelatedArticlesForEntityFailure(
                                    {
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getRelatedCasesForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.getRelatedCasesForEntity),
            mergeMap((action) =>
                this.relatedRecordsService
                    .getRelatedCasesForEntity(
                        action.entityType,
                        action.entityId
                    )
                    .pipe(
                        map((cases) =>
                            relatedRecordsActions.getRelatedCasesForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    cases,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.getRelatedCasesForEntityFailure(
                                    {
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getChildCasesForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.getChildCasesForEntity),
            mergeMap((action) =>
                this.relatedRecordsService
                    .getChildCasesForEntity(action.entityType, action.entityId)
                    .pipe(
                        map((cases) =>
                            relatedRecordsActions.getChildCasesForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    cases,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.getChildCasesForEntityFailure(
                                    {
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getRelatedOpportunitiesForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.getRelatedOpportunitiesForEntity),
            mergeMap((action) =>
                this.relatedRecordsService
                    .getRelatedOpportunitiesForEntity(
                        action.entityType,
                        action.entityId
                    )
                    .pipe(
                        map((opportunities) =>
                            relatedRecordsActions.getRelatedOpportunitiesForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    opportunities,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.getRelatedOpportunitiesForEntityFailure(
                                    {
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getSuggestedRelatedArticlesForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.getSuggestedRelatedArticlesForEntity),
            mergeMap((action) =>
                this.relatedRecordsService
                    .getSuggestedRelatedArticlesForEntity(
                        action.entityType,
                        action.entityId
                    )
                    .pipe(
                        map((articles) =>
                            relatedRecordsActions.getSuggestedRelatedArticlesForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    articles,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.getSuggestedRelatedArticlesForEntityFailure(
                                    {
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getSuggestedRelatedCasesForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.getSuggestedRelatedCasesForEntity),
            mergeMap((action) =>
                this.relatedRecordsService
                    .getSuggestedRelatedCasesForEntity(
                        action.entityType,
                        action.entityId
                    )
                    .pipe(
                        map((cases) =>
                            relatedRecordsActions.getSuggestedRelatedCasesForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    cases,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.getSuggestedRelatedCasesForEntityFailure(
                                    {
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    getSuggestedRelatedOpportunitiesForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                relatedRecordsActions.getSuggestedRelatedOpportunitiesForEntity
            ),
            mergeMap((action) =>
                this.relatedRecordsService
                    .getSuggestedRelatedOpportunitiesForEntity(
                        action.entityType,
                        action.entityId
                    )
                    .pipe(
                        map((opportunities) =>
                            relatedRecordsActions.getSuggestedRelatedOpportunitiesForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    opportunities,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.getSuggestedRelatedOpportunitiesForEntityFailure(
                                    {
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    createLinkToArticleForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.createLinkToArticleForEntity),
            switchMap((action) =>
                this.relatedRecordsService
                    .createLinkToArticleForEntity(
                        action.entityType,
                        action.entityId,
                        action.articleId
                    )
                    .pipe(
                        map(() =>
                            relatedRecordsActions.createLinkToArticleForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    articleId: action.articleId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.createLinkToArticleForEntityFailure(
                                    {
                                        error,
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        articleId: action.articleId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    createLinkToCaseForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.createLinkToCaseForEntity),
            switchMap((action) =>
                this.relatedRecordsService
                    .createLinkToCaseForEntity(
                        action.entityType,
                        action.entityId,
                        action.caseId
                    )
                    .pipe(
                        map(() =>
                            relatedRecordsActions.createLinkToCaseForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    caseId: action.caseId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.createLinkToCaseForEntityFailure(
                                    {
                                        error,
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        caseId: action.caseId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    createLinkToOpportunityForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.createLinkToOpportunityForEntity),
            switchMap((action) =>
                this.relatedRecordsService
                    .createLinkToOpportunityForEntity(
                        action.entityType,
                        action.entityId,
                        action.opportunityId
                    )
                    .pipe(
                        map(() =>
                            relatedRecordsActions.createLinkToOpportunityForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    opportunityId: action.opportunityId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.createLinkToOpportunityForEntityFailure(
                                    {
                                        error,
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        opportunityId: action.opportunityId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteLinkToArticleForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.deleteLinkToArticleForEntity),
            switchMap((action) =>
                this.relatedRecordsService
                    .deleteLinkToArticleForEntity(
                        action.entityType,
                        action.entityId,
                        action.articleId
                    )
                    .pipe(
                        map(() =>
                            relatedRecordsActions.deleteLinkToArticleForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    articleId: action.articleId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.deleteLinkToArticleForEntityFailure(
                                    {
                                        error,
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        articleId: action.articleId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteLinkToCaseForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.deleteLinkToCaseForEntity),
            switchMap((action) =>
                this.relatedRecordsService
                    .deleteLinkToCaseForEntity(
                        action.entityType,
                        action.entityId,
                        action.caseId
                    )
                    .pipe(
                        map(() =>
                            relatedRecordsActions.deleteLinkToCaseForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    caseId: action.caseId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.deleteLinkToCaseForEntityFailure(
                                    {
                                        error,
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        caseId: action.caseId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    deleteLinkToOpportunityForEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(relatedRecordsActions.deleteLinkToOpportunityForEntity),
            switchMap((action) =>
                this.relatedRecordsService
                    .deleteLinkToOpportunityForEntity(
                        action.entityType,
                        action.entityId,
                        action.opportunityId
                    )
                    .pipe(
                        map(() =>
                            relatedRecordsActions.deleteLinkToOpportunityForEntitySuccess(
                                {
                                    entityType: action.entityType,
                                    entityId: action.entityId,
                                    opportunityId: action.opportunityId,
                                }
                            )
                        ),
                        catchError((error) =>
                            of(
                                relatedRecordsActions.deleteLinkToOpportunityForEntityFailure(
                                    {
                                        error,
                                        entityType: action.entityType,
                                        entityId: action.entityId,
                                        opportunityId: action.opportunityId,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
