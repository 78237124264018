import { Component, Input } from '@angular/core';
import { WdxSize } from '@wdx/shared/utils';
import { FeatureSvg } from '../../../models/svg.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'atom-feature-svg',
    templateUrl: './atom-feature-svg.component.html',
    styleUrls: ['./atom-feature-svg.component.scss'],
})
export class AtomFeatureSvgComponent {
    @Input() svg: FeatureSvg;
    @Input() size: WdxSize = 'sm';

    readonly FEATURE_SVG = FeatureSvg;
}
