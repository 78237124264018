import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoleculeWidgetContainerModule } from '../../molecules/molecule-widget-container/molecule-widget-container.module';
import { OrganismTableModule } from '../../organisms/organism-table/organism-table.module';
import { WidgetTableComponent } from './widget-table.component';

@NgModule({
    imports: [CommonModule, MoleculeWidgetContainerModule, OrganismTableModule],
    declarations: [WidgetTableComponent],
    exports: [WidgetTableComponent],
})
export class WidgetTableModule {}
