<molecule-input-wrapper
    [formInputData]="formInputData"
    [validationErrorMessage]="validationErrorMessage"
    [hideGuidanceText]="hideGuidanceText"
    [formDisplayMode]="formDisplayMode"
    [formSummaryMode]="formSummaryMode"
    [inputOnly]="inputOnly"
    [value]="value | fn : valueAsArray | fn : valueAsArrayToString"
>
    <div class="action-buttons">
        <atom-add-button
            type="button"
            [attr.data-cy]="'btn-add-tag-' + formInputData?.name"
            [modalId]="modalId"
            [disabled]="formInputData.isDisabled"
            >Add</atom-add-button
        >
    </div>
    <atom-input-none-selected
        *ngIf="!value; else results"
        [modalId]="modalId"
        [disabled]="formInputData.isDisabled"
    ></atom-input-none-selected>
    <ng-template #results>
        <div class="info-card-container">
            <wdx-info-card
                *ngFor="let tag of value | fn : valueAsArray"
                [flush]="true"
            >
                <div wdxInfoCardLabel>{{ tag }}</div>
                <div wdxInfoCardTrailing>
                    <atom-close
                        size="sm"
                        (click)="deselectTag(tag)"
                        [attr.data-cy]="'btn-close-tag-' + tag"
                    ></atom-close>
                </div>
            </wdx-info-card>
        </div>
    </ng-template>
</molecule-input-wrapper>

<atom-modal-wrapper [modalId]="modalId" [modalTemplate]="tagsModal">
    <ng-template #tagsModal>
        <organism-modal
            [modalTitle]="formInputData.label | translateTokenisedString"
        >
            <molecule-info-card-search
                [formInputData]="formInputData"
                [mode]="
                    isMulti
                        ? INFO_CARD_SEARCH_MODE.MultiSelect
                        : INFO_CARD_SEARCH_MODE.Select
                "
                [infoCards]="
                    tags
                        | fn : filteredTags : searchText
                        | fn : tagsAsAsInfoCardData
                "
                [isLoading]="tagsIsLoading"
                [hasError]="tagsHasError"
                [initialValue]="value"
                (search)="onSearch($event)"
                (confirm)="onConfirmClicked()"
                (valueChanged)="onChange($event)"
            ></molecule-info-card-search>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
