import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WdxDropdownItemDirective } from './wdx-dropdown-item/wdx-dropdown-item.directive';
import { WdxDropdownMenuComponent } from './wdx-dropdown-menu/wdx-dropdown-menu.component';
import { WdxDropdownComponent } from './wdx-dropdown.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        WdxDropdownComponent,
        WdxDropdownMenuComponent,
        WdxDropdownItemDirective,
    ],
    exports: [
        WdxDropdownComponent,
        WdxDropdownMenuComponent,
        WdxDropdownItemDirective,
    ],
})
export class WdxDropdownModule {}
