<div class="d-flex row align-items-center flex-nowrap">
    <molecule-avatar-group-container
        *ngIf="extendedInfoCard.avatars?.length"
        class="divider-end"
        [size]="size"
        [avatars]="extendedInfoCard.avatars"
        [fixedWidth]="fixedWidthAvatarsAndIcons"
        [forceGroup]="false"
    ></molecule-avatar-group-container>
    <div
        *ngIf="extendedInfoCard.icon && !extendedInfoCard.avatars?.length"
        class="avatar-group avatar-group--{{ size }} divider-end"
        [class.avatar-group--fixed-width]="fixedWidthAvatarsAndIcons"
    >
        <wdx-icon
            class="icon-container d-flex justify-content-center align-items-center rounded-circle bg-icon icon-container-size-lg"
            [icon]="extendedInfoCard.icon"
            [scale]="1.25"
        ></wdx-icon>
    </div>
    <molecule-info-card
        class="flex-grow-1 text-truncate"
        data-cy="info-card"
        [primaryInfo]="extendedInfoCard.infoCard?.primaryInfo"
        [secondaryInfo]="extendedInfoCard.infoCard?.secondaryInfo"
        [tertiaryInfo]="extendedInfoCard.infoCard?.tertiaryInfo"
        [routerLinkData]="extendedInfoCard.infoCard?.routerLink"
        [openInNewTab]="extendedInfoCard.infoCard?.openInNewTab"
        [entityType]="extendedInfoCard.infoCard?.entityType"
        [entityId]="extendedInfoCard.infoCard?.entityId"
        [formSetup]="extendedInfoCard.infoCard?.formSetup"
        [showEditForm]="extendedInfoCard.infoCard?.showEditForm"
        (linkClicked)="onLinkClicked()"
    ></molecule-info-card>

    <molecule-avatar-group-container
        *ngIf="extendedInfoCard.secondaryAvatars?.length"
        [size]="'md'"
        [avatars]="extendedInfoCard.secondaryAvatars"
        [fixedWidth]="fixedWidthAvatarsAndIcons"
        [forceGroup]="false"
    ></molecule-avatar-group-container>

    <div
        *ngFor="let tile of extendedInfoCard.tiles; let i = index"
        class="flex-shrink-0 d-none justify-content-center col-2 d-sm-flex"
        [ngClass]="{ 'divider-end': i < extendedInfoCard.tiles.length - 1 }"
    >
        <molecule-tile-mini [tile]="tile"></molecule-tile-mini>
    </div>

    <div *ngIf="showButtons" class="d-flex ms-2 align-items-center">
        <div class="d-none d-sm-flex" *ngIf="icon">
            <wdx-icon
                class="me-2"
                [icon]="icon"
                [hidden]="!extendedInfoCard.isMine"
            ></wdx-icon>
        </div>
        <div class="d-none d-sm-flex" *ngFor="let button of buttons">
            <wdx-toggle-button
                *ngIf="button.mode === ACTION_BUTTONS_MODE.FavouriteButton"
                class="me-2"
                size="sm"
                [toggle]="extendedInfoCard.isFavourite"
                (wdxOnChange)="onToggleFavourite($event)"
            ></wdx-toggle-button>
        </div>

        <molecule-action-button
            *ngIf="extendedInfoCard?.actionButton"
            [actionButton]="extendedInfoCard.actionButton"
            size="sm"
            [hideDropdownChevron]="true"
            (selectMenuItem)="onMenuItemSelected($event)"
        >
        </molecule-action-button>
    </div>

    <ng-content></ng-content>
</div>
