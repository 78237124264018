import { createSelector } from '@ngrx/store';
import * as documentRequirementTypesReducer from './document-requirement-types.reducer';

/**
 * Reducer state selector
 */
// eslint-disable-next-line max-len
export const getState = (state: {
    documentRequirementTypes: documentRequirementTypesReducer.State;
}): documentRequirementTypesReducer.State => state.documentRequirementTypes;
export const getDocumentRequirementTypesInfinityCombinedList = (props: {
    id: string;
}) =>
    createSelector(
        getState,
        (state: documentRequirementTypesReducer.State) =>
            state.documentRequirementTypes[props.id]?.infinity?.combinedList
    );
export const getDocumentRequirementTypesInfinityPaging = (props: {
    id: string;
}) =>
    createSelector(
        getState,
        (state: documentRequirementTypesReducer.State) =>
            state.documentRequirementTypes[props.id]?.infinity?.paging
    );
export const getDocumentRequirementTypesIsLoadingPage = (props: {
    id: string;
}) =>
    createSelector(
        getState,
        (state: documentRequirementTypesReducer.State) =>
            state.documentRequirementTypes[props.id]?.isLoadingPage
    );
export const getDocumentRequirementTypesHasLoadingPageError = (props: {
    id: string;
}) =>
    createSelector(
        getState,
        (state: documentRequirementTypesReducer.State) =>
            state.documentRequirementTypes[props.id]?.hasLoadingPageError
    );
export const getAllIsLoadingList = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentRequirementTypesReducer.State) =>
            state.documentRequirementTypes[props.id]?.isLoadingList
    );
export const getAllHasLoadingListError = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentRequirementTypesReducer.State) =>
            state.documentRequirementTypes[props.id]?.hasLoadingListError
    );
export const getAll = (props: { id: string }) =>
    createSelector(
        getState,
        (state: documentRequirementTypesReducer.State) =>
            state.documentRequirementTypes[props.id]?.list
    );
/**
 * Reducer property selectors
 */
