import { Action, createReducer, on } from '@ngrx/store';
import { CrudState } from '@wdx/clmi/api-services/models';
import * as httpActions from '../../state/http/http.actions';

export interface State {
    http?: CrudState<any>;
}

export const initialState: State = {
    http: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        httpActions.patch,
        (state): State => ({
            ...state,
            http: {
                ...state.http,
                isLoadingSingle: true,
                hasLoadingSingleError: false,
            },
        })
    ),

    on(
        httpActions.patchSuccess,
        (state): State => ({
            ...state,
            http: {
                ...state.http,
                isLoadingSingle: false,
                hasLoadingSingleError: false,
            },
        })
    ),

    on(
        httpActions.patchFailure,
        (state): State => ({
            ...state,
            http: {
                ...state.http,
                isLoadingSingle: false,
                hasLoadingSingleError: true,
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
