import { Action, createReducer, on } from '@ngrx/store';
import { UserActionEntity } from '@wdx/clmi/api-models';
import { CrudState, CrudStateObject } from '@wdx/clmi/api-services/models';
import * as favouritesActions from './favourites.actions';

export interface State {
    favourites?: CrudStateObject<UserActionEntity>;
}

export const initialState: State = {
    favourites: {},
};

const reducerSetup = createReducer(
    initialState,

    on(
        favouritesActions.favourite,
        (state, props): State => ({
            ...state,
            favourites: {
                ...state.favourites,
                [props.id]: {
                    ...(state.favourites[props.id] ||
                        ({} as CrudState<UserActionEntity>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        favouritesActions.favouriteSuccess,
        (state, props): State => ({
            ...state,
            favourites: {
                ...state.favourites,
                [props.id]: {
                    ...(state.favourites[props.id] ||
                        ({} as CrudState<UserActionEntity>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        favouritesActions.favouriteFailure,
        (state, props): State => ({
            ...state,
            favourites: {
                ...state.favourites,
                [props.id]: {
                    ...(state.favourites[props.id] ||
                        ({} as CrudState<UserActionEntity>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    ),

    on(
        favouritesActions.unfavourite,
        (state, props): State => ({
            ...state,
            favourites: {
                ...state.favourites,
                [props.id]: {
                    ...(state.favourites[props.id] ||
                        ({} as CrudState<UserActionEntity>)),
                    isUpdating: true,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        favouritesActions.unfavouriteSuccess,
        (state, props): State => ({
            ...state,
            favourites: {
                ...state.favourites,
                [props.id]: {
                    ...(state.favourites[props.id] ||
                        ({} as CrudState<UserActionEntity>)),
                    isUpdating: false,
                    hasUpdatingError: false,
                },
            },
        })
    ),

    on(
        favouritesActions.unfavouriteFailure,
        (state, props): State => ({
            ...state,
            favourites: {
                ...state.favourites,
                [props.id]: {
                    ...(state.favourites[props.id] ||
                        ({} as CrudState<UserActionEntity>)),
                    isUpdating: false,
                    hasUpdatingError: true,
                },
            },
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return reducerSetup(state, action);
}
