import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterAnswerDataService } from './answer/filter-answer-data.service';
import { FilterDynamicDataService } from './dynamic/filter-dynamic-data.service';
import { FilterDataProviderService } from './filter-data-provider.service';
import { FilterPossibleValuesDataService } from './possible-values/filter-possible-values-data.service';
import { FilterTagsDataService } from './tags/filter-tags-data.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        FilterAnswerDataService,
        FilterDataProviderService,
        FilterDynamicDataService,
        FilterPossibleValuesDataService,
        FilterTagsDataService,
    ],
})
export class FilterDataProviderModule {}
